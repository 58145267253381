export const projectType = [
  { value: 'Hydrogen, EV', label: 'Hydrogen, EV' },
  { value: 'Power Turbine', label: 'Power Turbine' },
  { value: 'Solar Turbine', label: 'Solar Turbine' }
];

export const colourOptions = [
  { value: 'superuser', label: 'Superuser' },
  { value: 'consumer', label: 'Consumer' },
  { value: 'collaborator', label: 'Collaborator' }
];

export const typeOfAnalysisOptions = [
  { value: 'GEOPHIRES', label: 'GEOPHIRES' },
  { value: 'HIP (HEAT IN PLACE)', label: 'HIP (HEAT IN PLACE)', isDisabled: true }
];

export const outputListOption = [
  { value: 'Average Net Electricity Production', label: 'Average Net Electricity Production' },
  { value: 'Average Production Temperature', label: 'Average Production Temperature' },
  { value: 'Average Annual Total Electricity Generation', label: 'Average Annual Total Electricity Generation' },
  { value: 'Electricity breakeven price', label: 'Electricity breakeven price' },
  { value: 'Project NPV', label: 'Project NPV' },
  { value: 'Total capital costs', label: 'Total capital costs' },
  { value: 'Reservoir hydrostatic pressure', label: 'Reservoir hydrostatic pressure' }
];

export const defaultUserTypes = {
  admin: 'admin', user: 'user'
};

export const defaultDays = {
  today: 'Today', yesterday: 'Yesterday'
};

export const dateFormateText = 'MMM D, YYYY';
