import { changeSpinnerTitle, startFullLoading, stopFullLoading } from '../redux/SpinnerSlice';
import { getSimulationPayload, getWorkbenchPayload } from '../utils';
import { useSaveWorkbenchDataMutation } from '../redux/services/workbenchApis';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { toast } from 'react-toastify';
import { MESSAGES } from '../constants';
import { updateSavedNodes, updateUnsavedChange } from '../redux/workbenchSlice';
import { scenarioApis } from '../redux/services/scenarioApis';
import { useGetSimulationStatusMutation, useSaveSimulationDataMutation } from '../redux/services/simulationApi';
import { useLazyGetSettingsDataQuery } from '../redux/services/settingsApis';
import { updateSimulationMetricsDatas } from '../redux/slices/simulationMetricsDataSlice';
import { Node } from 'reactflow';

export const useSaveWorkbench = () => {
  const [saveWorkbenchData] = useSaveWorkbenchDataMutation();
  const [saveSimulationData] = useSaveSimulationDataMutation();
  const [getSimulationDataStatus] = useGetSimulationStatusMutation();

  const dispatch = useAppDispatch();
  const workbenchData = useAppSelector(state => state.workbench);
  const [getSettings] = useLazyGetSettingsDataQuery();

  const saveWorkbench = (showLoader: boolean, callback?: any) => {
    showLoader && dispatch(startFullLoading());
    const payload = getWorkbenchPayload(workbenchData);
    saveWorkbenchData(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.WORKBENCH_SAVED);
        dispatch(updateUnsavedChange(false));
        dispatch(updateSavedNodes(workbenchData.nodes));
        getSettings(workbenchData?.scenarioDetails?._id);
        scenarioApis.util.invalidateTags(['GetScenarioDetails']);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
      dispatch(stopFullLoading());
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  const saveWorkbenchAsDuplicate = (showLoader: boolean, duplicateScenarioDetails?: any, callback?: any) => {
    showLoader && dispatch(startFullLoading());
    const payload = getWorkbenchPayload(workbenchData);
    payload.id = duplicateScenarioDetails?.id;
    payload.name = duplicateScenarioDetails?.name;
    saveWorkbenchData(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.EV_COPY_SIMULATION_CREATED);
        scenarioApis.util.invalidateTags(['GetScenarioDetails']);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
      dispatch(stopFullLoading());
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  const saveSimulation = (isDashboard: boolean, callback?: (e: any) => void, updatedNodeData?: Node[]) => {
    dispatch(changeSpinnerTitle(MESSAGES.SIMULATION_STARTED));
    const data: any = {
      ...workbenchData,
      savedNodes: updatedNodeData ?? workbenchData.savedNodes
    };
    const payload = getSimulationPayload(isDashboard, data);
    saveSimulationData(payload).then((res) => {
      if ('data' in res) {
        if (callback && typeof callback === 'function') {
          callback(res.data);
        }
      } else {
        dispatch(stopFullLoading());
      }
    });
  };

  const getSimulationStatus = (taskId: string, callback?: (e: any) => void, interval?: any) => {
    dispatch(startFullLoading());
    const payload = {
      task_id: taskId
    };
    getSimulationDataStatus(payload).then((resp) => {
      if ('data' in resp) {
        const res = resp.data;
        if (res.status === 'completed') { // when simulation is completed
          clearInterval(interval);
          dispatch(updateSimulationMetricsDatas(res.results));
          dispatch(stopFullLoading());
          toast.success(MESSAGES.SIMULATION_IS_COMPLETED);
          callback?.(res);
        } else if (res.status === 'failed') { // when simulation is failed
          clearInterval(interval);
          dispatch(stopFullLoading());
          toast.error(res.error_message);
          callback?.(res);
        } else { // when simulation is running
          dispatch(changeSpinnerTitle(res.message));
        }
      } else {
        dispatch(stopFullLoading());
      }
    });
  };

  return {
    saveWorkbench,
    saveSimulation,
    getSimulationStatus,
    saveWorkbenchAsDuplicate
  };
};


