import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { createArrayWithLength, deleteRedLogo } from '../../utils';
import { Edge, Node, useReactFlow } from 'reactflow';
import { IObjectList, MAX_LENGTH, NUMBER } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateNodes } from '../../redux/workbenchSlice';

interface IChangeInputOutputNodeProps {
  showModal: boolean
  closeModalHandler: () => void
  component: Node | any
  updateWorkbenchNodes: (nodes: Node[]) => void
}

function ChangeInputOutputNode(props: IChangeInputOutputNodeProps) {
  const { showModal, closeModalHandler, component, updateWorkbenchNodes } = props;
  const { setEdges } = useReactFlow();
  const [data, setData] = useState(component.data as IObjectList);
  const [inputTopVariance, setInputTopVariance] = useState(NUMBER.N100 / (data.no_of_inputs + NUMBER.N1));
  const [outputTopVariance, setOutputTopVariance] = useState(NUMBER.N100 / (data.no_of_outputs + NUMBER.N1));
  const dispatch = useAppDispatch();
  const workbenchData = useAppSelector(state => state.workbench);
  const [defaultInputCount, setDefaultInputCount] = useState(NUMBER.N0);
  const [defaultOutputCount, setDefaultOutputCount] = useState(NUMBER.N0);
  const [deletedHandleId, setDeletedHandleId] = useState<string[]>([]);

  const updateDefaultInputOutputCount = useCallback(() => {
    const countValues: Record<string, { input: number, output: number }> = {
      'input': { input: NUMBER.N1, output: NUMBER.N0 },
      'output': { input: NUMBER.N0, output: NUMBER.N1 },
      'default': { input: NUMBER.N1, output: NUMBER.N1 }
    };

    const { input, output } = countValues[data.defaultType];
    setDefaultInputCount(input);
    setDefaultOutputCount(output);
    if (!data.input_node_arr?.length && !data.output_node_arr?.length) {
      const obj = { ...data, input_node_arr: createArrayWithLength(data.no_of_inputs), output_node_arr: createArrayWithLength(data.no_of_outputs) };
      setData(obj);
    }
  }, []);

  useEffect(() => {
    updateDefaultInputOutputCount();
  }, []);

  const addInputNode = (num: number, index?: number) => {
    const inputNodes = data.no_of_inputs + num;
    let nodeArr = [...data.input_node_arr];
    nodeArr = getUpdatedNodeArr(num, nodeArr, index, 'input');
    const obj = { ...data, input_node_arr: nodeArr, no_of_inputs: inputNodes };
    setData(obj);
    setInputTopVariance(NUMBER.N100 / (inputNodes + NUMBER.N1));
  };

  const addOutputNode = (num: number, index?: number) => {
    const outputNodes = data.no_of_outputs + num;
    let nodeArr = [...data.output_node_arr];
    nodeArr = getUpdatedNodeArr(num, nodeArr, index, 'output');
    const obj = { ...data, output_node_arr: nodeArr, no_of_outputs: outputNodes };
    setData(obj);
    setOutputTopVariance(NUMBER.N100 / (outputNodes + NUMBER.N1));
  };

  const getUpdatedNodeArr = (num: number, nodeArr: any, index: any, type: string) => {
    if (num === NUMBER.N1) {
      const lastIndexValue = nodeArr.length ? nodeArr[nodeArr.length - NUMBER.N1] : NUMBER.N0;
      nodeArr.push(lastIndexValue + NUMBER.N1);
    } else {
      const deletedArray = [...deletedHandleId];
      deletedArray.push(`${type}-${nodeArr[index]}-${component.id}`);
      setDeletedHandleId(deletedArray);
      nodeArr.splice(index, NUMBER.N1);
    }
    return nodeArr;
  };

  const updateComponent = () => {
    const updatedNode = {
      ...component,
      data: {
        ...data
      }
    };
    const updatedWorkbenchNodes = workbenchData.nodes.map((item: Node) => {
      if (item.id === updatedNode.id) {
        return updatedNode;
      }
      return item;
    });
    dispatch(updateNodes(updatedWorkbenchNodes));
    setEdges((edges: Edge[]) => edges.filter((edge: Edge) => !(deletedHandleId?.includes(edge.sourceHandle as string) || deletedHandleId?.includes(edge.targetHandle as string))));
    updateWorkbenchNodes(updatedWorkbenchNodes);
    closeModalHandler();
  };

  return (

    <Modal
      show={showModal}
      onHide={() => closeModalHandler()}
      dialogClassName="modal-418 top-right-modal input-output-modal"
      className="forget-modal setting-modal input-output-dailog"
    >
      <Modal.Header closeButton className="" onClick={() => closeModalHandler()}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dsider-tab-wrapper input-output-main">
          <div className="input-output-inner">
            <div className="input-img-wrap">
              <div className="input-img-border">
                <div className="input-node-wrap">
                  {data.input_node_arr?.map((val, index) => (
                    <span
                      className='input-node'
                      key={val}
                      style={{ top: `${((index + NUMBER.N1) * inputTopVariance)}%` }}
                    ></span>
                  ))}
                </div>
                <div className="output-node-wrap">
                  {data.output_node_arr?.map((val, index) => (
                    <span
                      className='output-node'
                      key={val}
                      style={{ top: `${((index + NUMBER.N1) * outputTopVariance)}%` }}
                    ></span>
                  ))}
                </div>
                <span className='input-node-img'>
                  <img height="60px" src={data.imageS3url} alt="img logo" />
                </span>
              </div>
              <p className='input-img-des'>{data.componentDisplayName}</p>
            </div>
          </div>
          <div className="operational-main-wrapper">
            <div className="input-output-inner-wrap">
              <div className="operational">
                <div className="supply">
                  <p className="supply-head">Input Nodes</p>
                  {data.input_node_arr?.map((val, index) => (
                    <div className="operational-box" key={val}>
                      <span className="operational-box-des">Input Node {index + NUMBER.N1}</span>
                      <button className='btn btn-no-outline delete-btn'
                        disabled={data.no_of_inputs <= defaultInputCount}
                        onClick={() => addInputNode(-NUMBER.N1, index)}
                      >
                        <img src={deleteRedLogo} alt="logo img" />
                      </button>
                    </div>
                  ))}
                  <div className={`operational-box cursor-pointer ${data.no_of_inputs >= MAX_LENGTH.input_output_node ? 'disabled' : ''}`}
                    onClick={() => addInputNode(NUMBER.N1)}
                  >
                    <button className='btn btn-no-outline'>
                      +Add more
                    </button>
                  </div>
                </div>
              </div>
              <div className="operational operational-right">
                <div className="demand">
                  <p className="supply-head">Output Nodes</p>
                  {data.output_node_arr?.map((val, index) => (
                    <div className="operational-box" key={val}>
                      <span className="operational-box-des">Output Node {index + NUMBER.N1}</span>
                      <button className='btn btn-no-outline delete-btn'
                        disabled={data.no_of_outputs <= defaultOutputCount}
                        onClick={() => addOutputNode(-NUMBER.N1, index)}
                      >
                        <img src={deleteRedLogo} alt="logo img" />
                      </button>
                    </div>
                  ))}
                  <div className={`operational-box cursor-pointer ${data.no_of_outputs >= MAX_LENGTH.input_output_node ? 'disabled' : ''}`}
                    onClick={() => addOutputNode(NUMBER.N1)}
                  >
                    <button className='btn btn-no-outline'>
                      +Add more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-no-outline" onClick={() => closeModalHandler()}>
          Cancel
        </Button>
        <Button className="primary" onClick={updateComponent}>Save Changes</Button>
      </Modal.Footer>

    </Modal>

  );
}

export default ChangeInputOutputNode;
