/* eslint-disable complexity */
import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { capitalizeFirstLetter, fileInputImg } from '../../../utils';
import Select from 'react-select';
import { MESSAGES, NUMBER } from '../../../constants';
import RenderFormFields from './RenderFormFields';

const AddNewObjectForm = ({
  formikRef,
  intialObjects,
  validateInitialObjects,
  handleFormSubmit,
  intialValidation,
  selectImage,
  showError,
  setShowObjectImage,
  projectTypeLoading,
  openGoogleMap,
  objectLibraryLoading,
  setIsPermissionChanged,
  formError,
  objectLibrary,
  setFocusedProperty,
  objectTypeLoading,
  setSelectObjectLibrary,
  objectType,
  isEditObject,
  setObjectName,
  setSelectObjectType,
  objectName,
  objectNameStatusLoading,
  objectNameError,
  selectedObject,
  setShowObjectIcon,
  setShowAddParameter,
  handleEditParameter,
  selectObjectLibrary,
  selectObjectType,
  focusedProperty,
  showConfirmationAlert,
  projectType,
  singleObject,
  setSelectedObject,
  intialObject
}: any) => {
  const isReferenceDisable = !selectObjectLibrary || !selectObjectType || isEditObject;
  const isDisabledClass = isEditObject ? 'disabled' : '';
  return (
    <Formik
      innerRef={formikRef}
      initialValues={intialObjects}
      enableReinitialize={true}
      validate={validateInitialObjects}
      onSubmit={handleFormSubmit()}
      validateOnChange={intialValidation}
      validateOnBlur={true}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className='add-object-inner'>
            <div className="create-modal-left">
              <Form.Group
                controlId="formFile"
                className="form-group file-input error-field mb-0"
              >
                <div className="file-input-wrapper">
                  <div className="file-upload-img">
                    {selectImage?.imageUrl
                      ? <>
                        <img
                          src={selectImage.imageUrl}
                          alt="img upload"
                          className="file-main-img upload-main-img blue"
                          onClick={() => setShowObjectImage(true)}
                        />
                      </>
                      : <img
                        src={fileInputImg}
                        alt="img main cursor-pointer"
                        className="file-main-img empty-img"
                        onClick={() => setShowObjectImage(true)}
                      />}
                    {showError && <span className="error-msg">
                      Object Icon is required.
                    </span>
                    }
                  </div>
                  <div className="file-input-text-wrapper" onClick={() => setShowObjectImage(true)}>
                    <div className="file-attachment-wrapper">
                      <span className="file-input-attachment">
                        {isEditObject ? 'Update' : '+ Add Object'} Icon<sup>*</sup>
                      </span>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className={`add-object-form-wrap ${singleObject.locked && 'disabled'}`}>
              <div className="row">
                <div className="col-md-12">
                  <div className={`custom-select-main select-radio-grey grey-border-select ${isDisabledClass}`}>
                    <Form.Label>Project Type<sup>*</sup></Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      isDisabled={isEditObject}
                      isLoading={projectTypeLoading}
                      isClearable={false}
                      name="projectType"
                      placeholder="Select Project Type"
                      // @ts-expect-error ignore this
                      value={isEditObject && projectType?.find((o) => o.value === values.projectType)}
                      // @ts-expect-error ignore this
                      options={!projectTypeLoading && projectType?.filter(data => data.value !== 'ev')}
                      hideSelectedOptions={false}
                      onFocus={() => setFocusedProperty('')}
                      onChange={(e: any) => {
                        setFieldValue('projectType', e?.value);
                        setIsPermissionChanged(true);
                      }}
                      formatOptionLabel={({ label }) =>
                        <span className='checkbox-label-power'>{label}</span>
                      }

                    />
                    <ErrorMessage className="error-msg" name="projectType" component='span' />
                    <span className="error-msg">{`${formError?.projectType ?? ''}`}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className={`custom-select-main select-radio-grey grey-border-select ${isDisabledClass}`}>

                    <Form.Label>Object Library<sup>*</sup></Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      isDisabled={isEditObject}
                      isLoading={objectLibraryLoading}
                      isClearable={false}
                      name="object_library"
                      placeholder="Select Object Library"
                      options={objectLibrary}
                      hideSelectedOptions={false}
                      value={values.object_library && { value: values.object_library, label: values.object_library }}
                      onFocus={() => setFocusedProperty('')}
                      onChange={(e: any) => {
                        setSelectObjectLibrary(e?.value);
                        setFieldValue('object_library', e?.value);
                        setIsPermissionChanged(true);
                        setSelectObjectType(null);
                        setFieldValue('objectType', '');
                        setFieldValue('reference_object', '');
                        setSelectedObject(intialObject);
                        values.properties = [];
                      }}
                      formatOptionLabel={({ label }) =>
                        <span className='checkbox-label-power'>{label}</span>
                      }
                    />
                    <span className="error-msg">{`${formError?.object_library ?? ''}`}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className={`custom-select-main select-radio-grey grey-border-select ${isDisabledClass}`}>
                    <Form.Label>Object Type<sup>*</sup></Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      isDisabled={isEditObject || !selectObjectLibrary}
                      isLoading={objectTypeLoading}
                      isClearable={false}
                      name="objectType"
                      placeholder="Select Object Type"
                      options={objectType}
                      hideSelectedOptions={false}
                      onFocus={() => setFocusedProperty('')}
                      value={values.objectType && { value: values.objectType, label: capitalizeFirstLetter(values.objectType) }}
                      onChange={(e: any) => {
                        setSelectObjectType(e?.value);
                        setFieldValue('objectType', e?.value);
                        setIsPermissionChanged(true);
                        setFieldValue('reference_object', '');
                        setSelectedObject(intialObject);
                        values.properties = [];
                      }
                      }
                      formatOptionLabel={({ label }) =>
                        <span className='checkbox-label-power'>{label}</span>
                      }
                    />
                    <span className="error-msg">{`${formError?.objectType ?? ''}`}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main select-radio-grey grey-border-select error-field">
                    <Form.Label>Object Name<sup>*</sup></Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete='off'
                      onChange={(e: any) => {
                        handleChange(e);
                        setObjectName(e.target.value);
                        setIsPermissionChanged(true);
                      }}
                      onFocus={() => setFocusedProperty('')}
                      placeholder="Enter Object Name"
                      name="component_name"
                      value={values.component_name}
                      maxLength={NUMBER.N25}
                    />
                    <span className="error-msg">{`${formError?.component_name ?? ''}`}</span>
                    {objectName && !objectNameStatusLoading && <>{objectNameError && isEditObject && values.component_name === singleObject.component ? <></> : objectNameError
                      ? <span className="error-msg">{MESSAGES.OBJECT_NAME_EXISTS}</span>
                      : <></>}</>}
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Group className={`form-group form-group-object ${isReferenceDisable && 'disabled'}`}>
                    <Form.Label>Select Reference Object<sup>*</sup></Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete='off'
                      placeholder="Select Reference Object"
                      onClick={() => setShowObjectIcon(true)}
                      value={selectedObject.componentDisplayName}
                      disabled={isReferenceDisable}
                      name="reference_object"
                      onFocus={() => setFocusedProperty('')}
                      onChange={(e: any) => {
                        setFieldValue('reference_object', e?.value);
                        setIsPermissionChanged(true);
                      }
                      }
                    />
                    <span className="error-msg">{`${formError?.reference_object ?? ''}`}</span>
                  </Form.Group>
                </div>
                <div className='add-parameter-main'>
                  <h4 className='form-group-subhead'>ADD PARAMETERS</h4>
                  {values.properties.map((property: any) => <RenderFormFields property={property}
                    key={property.formulaTitle}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    values={values}
                    setShowGoogleMaps={openGoogleMap}
                    handleEditParameter={handleEditParameter}
                    focusedProperty={focusedProperty}
                    setFocusedProperty={setFocusedProperty}
                    isEditObject={isEditObject}
                  />)}
                </div>
                <div className="col-md-12">
                  <Button className='btn-no-outline new-parameter-btn' onClick={() => setShowAddParameter(true)}>+add new parameter</Button>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button className="btn-no-outline" onClick={showConfirmationAlert}>
              Cancel
            </Button>
            {isEditObject
              ? <Button className="primary" type="submit">Update Object</Button>
              : <Button className="primary" type="submit">Save Object</Button>
            }
          </Modal.Footer>

        </Form>
      )}
    </Formik>
  );
};

export default AddNewObjectForm;
