/* eslint-disable react/react-in-jsx-scope */

import { Button, ButtonGroup, Dropdown, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import SearchAndFilter from '../../components/shared/SearchAndFilter';
import { adminDsiderLogo, compnayDummyLogo, crossRedLogo, falkensteinLogo, fileInputImg, hydrogenLogoSmall, infoInputLogo, mainBgImg, powerSmallLogo, projectBox1, threeDotHorizontal, turbineSmallLogo, userLogo1, versionLogo } from '../../utils';

import React, { useState } from 'react';
import Select from 'react-select';
import DsiderPagination from './DsiderPagination';
import DsiderTab from './DsiderTab';
import { Link } from 'react-router-dom';




const AdminDashboard = () => {
  const handleSearch = (val: string) => {
  };
  const [ShowCompanyModal, setShowCompanyModal] = useState(false);
  const [ShowAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [ShowUserModal, setShowUserModal] = useState(false);



  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const projectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Hydrogen, EV', label: 'Hydrogen, EV' },
    { value: 'Power Turbine', label: 'Power Turbine' },
    { value: 'Solar Turbine', label: 'Solar Turbine' }

  ];

  return (
    <>
      <div className='admin-sidebar'>
        <div className='dsider-logo-wrap'><img src={adminDsiderLogo} alt="logo img" /></div>
        <Nav variant="tabs" className='admin-sidebar-wrap' defaultActiveKey="/home">
          <Nav.Item>
            <Nav.Link href="/usermanagement">User Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/home">Company Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="objectmanagement">Object Management</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
      <div className='admin-section'>
        <div className='admin-body'>
          <div className='admin-body-header'>
            <div className='admin-header-wrap'>
              <h2 className='admin-section-title'>Companies (52)</h2>
              <div className='admim-profile-wrap'>
                <Dropdown className="profile-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="user-icon">
                      r
                      {/* <img className="user-img" src={userIcon} alt="user" /> */}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-wrapper">
                      <span className="user-icon">A</span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">Andy Smith</h3>
                        <span className="dropdown-email">
                          andysmith038@email.com
                        </span>
                        <div className="dropdown-footer">
                          <Button className="btn-outline small">
                            Edit Profile
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="account-setting">
                      <Link to="#" className="account-item">
                        Switch to Dsider Dashboard
                      </Link>
                      <Link to="#" className="account-item">
                        Change Password
                      </Link>
                      <Link to="#" className="account-item sign-out">
                        Sign Out
                      </Link>
                    </div>
                    <div className="version-sec">
                      <img src={versionLogo} alt="logo" />
                      <span className="version-text">Version v2.0.3</span>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='add-user-wrap'>
              <SearchAndFilter
                placeHolder="Search by name or email"
                onSearch={handleSearch}
                className="scenario-list-search"
              />
              <Button onClick={() => setShowAddCompanyModal(true)}>+ New Company</Button>
            </div>
          </div>
          <div className="col-md-12">
            {/* table */}
            <div className='table-wrapper'>
              <div className="table-container contest-table admin-table-container company-management-table">
                <div className='table-inner-wrap'>
                  <div className="table-head">
                    <div className="table-row">
                      <div className="th">company name</div>
                      <div className="th">email</div>
                      <div className="th">no. of users</div>
                      <div className="th">projects</div>
                      <div className="th">scenarios </div>
                      <div className="th">status</div>
                      <div className="th">action</div>
                    </div>
                  </div>
                  <div className="table-body">
                    <div className="table-row">
                      <div className="td" data-label="Model" onClick={() => setShowCompanyModal(true)}>
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              <img
                                src={userLogo1}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Amy McGrawl
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="120" onClick={() => setShowUserModal(true)}><span className='td-text user-count'>120</span></div>
                      <div className="td" data-label="03"><span className='td-text'>03</span></div>
                      <div className="td" data-label="15"><span className='td-text'>15</span></div>
                      <div className="td" data-label="active"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row inactive">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="80"><span className='td-text user-count'>80</span></div>
                      <div className="td" data-label="04"><span className='td-text'>04</span></div>
                      <div className="td" data-label="24"><span className='td-text'>24</span></div>
                      <div className="td" data-label="Inactive"><span className='td-text status inactive'>Inactive </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="56"><span className='td-text user-count'>56</span></div>
                      <div className="td" data-label="10"><span className='td-text'>10</span></div>
                      <div className="td" data-label="50"><span className='td-text'>50</span></div>
                      <div className="td" data-label="Active"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="56"><span className='td-text user-count'>56</span></div>
                      <div className="td" data-label="10"><span className='td-text'>10</span></div>
                      <div className="td" data-label="50"><span className='td-text'>50</span></div>
                      <div className="td" data-label="Active"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="56"><span className='td-text user-count'>56</span></div>
                      <div className="td" data-label="10"><span className='td-text'>10</span></div>
                      <div className="td" data-label="50"><span className='td-text'>50</span></div>
                      <div className="td" data-label="Active"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="56"><span className='td-text user-count'>56</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>10</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>50</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text'>56</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>10</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>50</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Company Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate Company
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete Company
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <DsiderPagination />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* companay modal start here */}
      <Modal
        show={ShowCompanyModal}
        onHide={() => setShowCompanyModal(false)}
        dialogClassName="modal-800 top-right-modal add-user-modal modal-dialog-centered"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowCompanyModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='company-name-wrap'>
            <div className='compnay-logo-wrap'>
              <img src={falkensteinLogo} alt="logo" className='company-logo-img' />
            </div>
            <div className='company-des-wrap'>
              <h4 className="modal-head modal-head-medium">Angel Ecosystems</h4>
              <p className='modal-head-des'>AngelEcosystems.co</p>
            </div>
          </div>
          <div className='user-card-main-wrap'>
            <div className='user-info-card'>
              <h3 className='user-info-card-title'>Users Onboard</h3>
              <div className='user-card-body'>
                <div className='user-card-active'>
                  <h2 className='user-card-sec-head'>24</h2>
                  <span className='user-status-des'>Active</span>
                </div>
                <div className='user-card-active user-card-inactive'>
                  <h2 className='user-card-sec-head'>02</h2>
                  <span className='user-status-des'>Inactive</span>
                </div>

              </div>
            </div>
            <div className='user-info-card user-activity-card'>
              <h3 className='user-info-card-title'>Users Activity</h3>
              <div className='user-card-body'>
                <div className='user-card-active'>
                  <h2 className='user-card-sec-head'>08</h2>
                  <span className='user-status-des'>Projects</span>
                </div>
                <div className='user-card-active'>
                  <h2 className='user-card-sec-head'>21</h2>
                  <span className='user-status-des'>Scenario</span>
                </div>
                <div className='user-card-active user-card-inactive'>
                  <h2 className='user-card-sec-head'>12</h2>
                  <span className='user-status-des'>Simulations</span>
                </div>

              </div>
            </div>
          </div>
          {/* Tab start here */}
          <div className="dsider-tab-wrapper admin-tab-wrap">

            <div className="container">

              <Tabs
                defaultActiveKey="consumers"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="consumers" title="Consumers (08)">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="collaborators" title="Collaborators (40)">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">

                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>

                    </div>
                  </div>
                </Tab>
                <Tab eventKey="super user" title="Super User (2)">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>

                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="delete-compnay-btn-wrap">
            <div className="delete-company-btn">
              <Button className='btn-no-outline delete-comp'>Delete company</Button>
              <Button className='btn-no-outline '>Deactivate Company</Button>
            </div>
            <div className="invite-btn-wrap">
              <Button className='send-invite-btn'>Update</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* add company modal start here */}
      <Modal
        show={ShowAddCompanyModal}
        onHide={() => setShowAddCompanyModal(false)}
        dialogClassName="modal-800 top-right-modal add-user-modal modal-dialog-centered"
        className="forget-modal setting-modal object-parameters-modal add-company-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowAddCompanyModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New Company</h4>
          <p className='modal-head-des'>Fill in the details of the user you
        want to add to your organisation. A request will be sent to
        the added user. The user will be onboarded when they accept the invitation & confirm their profile.</p>
          <div className="add-company-form-wrap">
            <div className="create-modal-left">
              <Form.Group
                controlId="formFile"
                className="form-group file-input error-field mb-0"
              >
                <Form.Label>
                  Company Logo
                </Form.Label>
                <div className="file-input-wrapper">
                  <div className="file-upload-img">
                    <img
                      src={compnayDummyLogo}
                      alt="img upload"
                      className="file-main-img upload-main-img"
                    />
                    <img
                      src={fileInputImg}
                      alt="img main"
                      className="file-main-img empty-img"
                    />
                    <img
                      src={crossRedLogo}
                      alt="img cross logo"
                      className="cross-logo"
                    />
                    <span className="error-msg d-done">
                      Company logo is required.
                    </span>
                  </div>
                  <div className="file-input-text-wrapper">
                    <div className="file-attachment-wrapper">
                      <span className="file-input-attachment">
                        + Upload Logo
                      </span>
                      <span className="file-input-des">
                        (jpg, png, jpeg of max 10 mb)
                      </span>
                    </div>
                    <Form.Control type="file" name="upload" />
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="create-modal-right">
              <Form className="full-width-form">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="form-group" controlId="latitude">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="number"
                        // value={locationLat}
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group" controlId="longitude">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                      // value={locationLng}
                      // autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        Company Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <div className="custom-select-main mb-0">
                      <Form.Label>Access to Project type</Form.Label>
                      <Select
                        className="custom-select-wrp"
                        classNamePrefix="select"
                        defaultValue={projectType[1]}
                        // isDisabled={isDisabled}
                        isLoading={isLoading}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={projectType}
                        placeholder="User Role"
                        menuIsOpen={true}
                      />

                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>


          {/* Tab start here */}
          <div className="dsider-tab-wrapper admin-tab-wrap">
            <div className="container">
              <Form.Label>Select Permissions:</Form.Label>
              <Tabs
                defaultActiveKey="consumers"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="consumers" title="Consumers">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="collaborators" title="Collaborators">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>

                    </div>
                  </div>
                </Tab>
                <Tab eventKey="super user" title="Super User">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <Form>
                        <div className="checkbox-wrapper checkbox-grey-wrap">
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Select All"
                                name="companycheckbox"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox1"
                                label="Create Scenarios"
                                name="companycheckbox2"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox2"
                                label="Change Parameters"
                                name="companycheckbox3"
                              />

                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import JSON file for models"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Import Data for the parameters"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data with Default Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Simulate Data by Adding Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Download Data"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Approve Scenarios"
                                name="companycheckbox4"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox3"
                                label="Add Comments on the Scenarios"
                                name="companycheckbox4"
                              />
                            </div>

                          </div>

                        </div>

                      </Form>

                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="delete-compnay-btn-wrap justify-content-around">
            <div className="invite-btn-wrap">
              <Button className='send-invite-btn'>Send Invite</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* user modal start here */}
      <Modal
        show={ShowUserModal}
        onHide={() => setShowUserModal(false)}
        dialogClassName="modal-800 top-right-modal add-user-modal modal-dialog-centered"
        className="forget-modal setting-modal object-parameters-modal add-company-modal users-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowUserModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium mb-3 0">Users (120)</h4>
          {/* Tab start here */}
          <div className="dsider-tab-wrapper admin-tab-wrap">
            <div className="container1">
              <Tabs
                defaultActiveKey="consumers"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="consumers" title="Consumers">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            <img
                              src={userLogo1}
                              alt="logo"
                              className="user-img"
                            />
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>
                    <div className="tab-main-wrapper">
                      <div className="tab-left-part">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon small orange">
                            AM
                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Amy McGrawl
                            </h3>
                            <p className="dropdown-des">
                              amymcgrawl038@email.com
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-right-part">
                        <Button className='btn-no-outline success'>Active</Button>
                      </div>
                    </div>

                  </div>
                </Tab>
                <Tab eventKey="collaborators" title="Collaborators">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      Please use consumers data here!
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="super user" title="Super User">
                  <div className="tab-main-container">
                    <div className="tab-main-wrapper">
                      Please use consumers data here!
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      </Modal>



    </>
  );
};

export default AdminDashboard;
