import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import {
  isActive,
  manageDeleteText,
  manageRoleText,
  manageStatusText,
  seeDetailsText,
  shortingLogo,
  threeDotHorizontal,
  turbineSmallLogo
} from '../../utils';
import Paginations from './Paginations';
import Avatar from '../admin/shared/Avatar';
import { itemsPerPage } from '../../constants/admin.static';
import { NUMBER } from '../../constants';

interface TableProps {
  data: any[]
  sortableFields: Record<string, boolean>
  columns: string[]
  setShowUserCompany?: (show: boolean) => void
  handleUserData?: (item: any) => void
  handleUserStatus?: () => void
  handleDeleteUser?: () => void
  showConfirmationAlert?: () => void
  showStatusConfirmationAlert?: () => void
  tableClass: string
  userSearch?: string
  tableType: string
  showCompanyUserModel?: () => void
  noAction?: boolean
}

const Table: React.FC<TableProps> = ({
  data,
  sortableFields,
  columns,
  setShowUserCompany,
  handleUserData,
  showConfirmationAlert,
  showStatusConfirmationAlert,
  tableClass,
  userSearch,
  tableType,
  showCompanyUserModel,
  noAction
}) => {
  const { N1, N0 } = NUMBER;
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState<number>(N1);
  const [activeSortField, setActiveSortField] = useState<string | null>(null);
  const startIndex = (currentPage - N1) * itemsPerPage;
  const itemLastSession = 'last Session';
  const companyNoOfUsers = 'no. of users';

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleSort = (field: string) => {
    if (sortableFields[field]) {
      handlePageChange(N1);
      setActiveSortField(field);
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    }
  };

  useEffect(() => {
    handleSort(sortBy as string);
  }, [sortBy]);

  const sortedData = data?.slice().sort((a: any, b: any) => {
    if (sortBy) {
      const aValue = typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
      const bValue = typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];
      if (sortOrder === 'asc') {
        return aValue < bValue ? -N1 : N1;
      } else {
        return aValue > bValue ? -N1 : N1;
      }
    } else {
      return N0;
    }
  });

  const paginatedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    userSearch ? setCurrentPage(N1) : setCurrentPage(N1);
  }, [userSearch]);

  const handleColumnSort = (field: string) => {
    setSortBy(field === itemLastSession ? 'lastSession' : field);
    handleSort(field === itemLastSession ? 'lastSession' : field);
  };

  const handleNoOfUsers = (item: any) => {
    showCompanyUserModel?.();
    handleUserData?.(item);
  };

  const keyDisable = (newObject: any) => {
    return !newObject && tableType === 'objects';
  };

  return (
    <div className="table-wrapper">
      <div className={`table-container contest-table ${tableClass}`}>
        <div className="table-inner-wrap">
          <div className="table-head">
            <div className="table-row">
              {columns.map((field, index) => (
                <div
                  key={index}
                  className={`th ${activeSortField === field ? 'active_sort' : ''} ${sortableFields[field] ? 'sortable' : ''}
                `}
                  onClick={() => {
                    sortableFields[field] && handleColumnSort(field);
                  }}
                >
                  {field}
                  {sortableFields[field] && (
                    <Button className="btn-no-outline shorting-btn">
                      <img src={shortingLogo} alt="shorting logo" className="shorting-logo" />
                    </Button>
                  )}
                </div>
              ))}
              <div className={`th ${noAction && 'd-none'}`}>Action</div>
            </div>
          </div>
          <div className="table-body">
            {paginatedData.length === 0 && !data.length ? <div className="table-row no-record">No {tableType} found</div> : <></>}
            {paginatedData.map((item: any, index) => (
              <div key={item.email || index} className={`table-row ${item.status || 'active'}`}>
                {columns.map((field, index) => (
                  <React.Fragment key={index}>
                    {field === 'name' || field === 'COMPANY NAME' ? (
                      <Avatar
                        name={item[field]}
                        setShowUserCompany={setShowUserCompany}
                        dateAdded={item.dateAdded}
                        type={field}
                        data={item}
                        imageUrl={item.imageUrl || item.imageS3url}
                        handleUserData={handleUserData}
                      />
                    ) : field === 'status' ? (
                      <div className="td" data-label="active">
                        <div className="tooltip-container">
                          <span className={`td-text status ${item.status}`}>{item[field]} </span>
                          <div className="tooltip-text">{item[field]}</div>
                        </div>
                      </div>
                    ) : field === companyNoOfUsers ? (
                      <div
                        className="td"
                        onClick={() => {
                          item[field] !== '-' && handleNoOfUsers(item);
                        }}
                      >
                        <div className="tooltip-container">
                          <div key={index} className={`td-text ${item[field] === '-' ? '' : 'user-count'}`} data-label={field}>
                            {item[field]}
                          </div>
                          <div className="tooltip-text">{item[field]}</div>
                        </div>
                      </div>
                    ) : field === 'name' ? (
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img src={turbineSmallLogo} alt="logo" className="user-img" />
                            </span>
                            <div className="right-sec">
                              <div className="tooltip-container">
                                <h3 className="dropdown-head">{item[field]}</h3>
                                <div className="tooltip-text">{item[field]}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="td">
                        <div className="tooltip-container">
                          <div key={index} className="td-text" data-label={field}>
                            {item[field]}
                          </div>
                          <div className="tooltip-text">{item[field]}</div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <div className={`td ${noAction && 'd-none'}`} data-label="dropdown">
                  <div className="tab-right-part">
                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          {seeDetailsText(tableType) && <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setShowUserCompany?.(true);
                              handleUserData?.(item);
                            }}
                          >
                            {seeDetailsText(tableType)}
                          </Dropdown.Item>
                          }
                          {(isActive(item.status) || tableType === 'objects') && (
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setShowUserCompany?.(true);
                                handleUserData?.(item);
                              }}
                            >
                              {manageRoleText(tableType)}
                            </Dropdown.Item>
                          )}
                          {manageStatusText(tableType, item.status) && <Dropdown.Item
                            as="button"
                            className={isActive(item.status) ? 'text-danger' : ''}
                            onClick={() => {
                              showStatusConfirmationAlert?.();
                              handleUserData?.(item);
                            }}
                          >
                            {manageStatusText(tableType, item.status)}
                          </Dropdown.Item>}
                          <Dropdown.Item
                            as="button"
                            className="text-danger"
                            onClick={() => {
                              showConfirmationAlert?.();
                              handleUserData?.(item);
                            }}
                            disabled={keyDisable(item.newObject)}
                          >
                            {manageDeleteText(tableType)}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {data.length ? (
          <Paginations
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            activePage={currentPage}
            setActivePage={setCurrentPage}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Table;
