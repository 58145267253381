/* eslint-disable @typescript-eslint/promise-function-async */
import React, { FC, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsStock from 'highcharts/modules/stock';
import CompareLegends from './CompareLegends';
import { globalObject, rangeSelectorObjEv } from '../../constants/graph.constant';
import { COMPARE_CHART_CONSTANTS, MESSAGES, NON_EV_GRAPH_COLORS, NUMBER } from '../../constants';
import CompareMericsRow from './CompareMericsRow';
import { toast } from 'react-toastify';
import FullPageLoader from '../../components/shared/FullPageLoader';
import { dataGroupingEVObj, handleDataGrouping, tooltipObj } from '../../components/shared/Graph/GraphUtils';
HighchartsBoost(Highcharts);
HighchartsExporting(Highcharts);
HighchartsStock(Highcharts);

interface Props {
  selectedScenarioList: any[]
  comparedScenarios: any[]
}

const CompareEVScenerio: FC<Props> = ({ selectedScenarioList, comparedScenarios }) => {
  const [formattedData, setFormattedData] = useState<any[]>();
  const [graphLoading, setGraphLoading] = useState(true);
  const [noSimulation, setNoSimulation] = useState(false);
  const [date, setDate] = useState<string>('-');
  const columnSpacing = NUMBER.N12 / selectedScenarioList.length;
  const [graphOptions, setGraphOptions] = useState<any>();
  const [scenerioNames, setScenerioNames] = useState<string[]>([]);

  const getSeries = (doubleArray: any[]) => {
    const series: any[] = [];
    doubleArray.map((array: any[]) => array.map((object: any) => series.push(object)));
    return series;
  };
  const getScenarioName = (id: string | null) => {
    if (id) {
      const scenarioObj = selectedScenarioList.find(scenerio => scenerio.value === id);
      return scenarioObj.label;
    } else {
      return '';
    };
  };
  const getGraphOptions = (data: any) => {
    return {
      global: globalObject,
      chart: COMPARE_CHART_CONSTANTS.chart,
      title: { text: '' },
      tooltip: tooltipObj,
      xAxis: {
        type: COMPARE_CHART_CONSTANTS.type,
        labels: {
          formatter: (value: any) => {
            return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
          }
        },
        crosshair: true
      },
      yAxis: {
        title: { text: 'Demand Total (kWh)' }
      },
      legend: {
        enabled: false
      },
      series: getSeries(data.map((simulation: any, i: number) => [{
        name: `${COMPARE_CHART_CONSTANTS.DEMAND_TOTAL_OF} ${scenerioNames[i]}`,
        color: NON_EV_GRAPH_COLORS[i],
        data: simulation[Object.keys(simulation)[NUMBER.N0]]?.load_profiles.map((profile: any) => [new Date(profile.date_ + 'Z').getTime(), profile.total_demand]),
        dataGrouping: dataGroupingEVObj
      }]
      )),
      rangeSelector: rangeSelectorObjEv,
      navigator: {
        enabled: true
      }
    };
  };

  const getMinMaxYear = (formattedData: any) => {
    let minYear = Infinity;
    let maxYear = -Infinity;
    if (formattedData) {
      (formattedData as Array<Record<string, { timeframe: string }>>)
        .forEach((item: Record<string, { timeframe: string }>) => {
          const timeframe = Object.values(item)[NUMBER.N0].timeframe;
          const [start, end] = timeframe.split('-').map(Number);
          if (start < minYear) {
            minYear = start;
          }
          if (end > maxYear) {
            maxYear = end;
          }
        });
      return { minYear, maxYear };
    }
    return { minYear: null, maxYear: null };
  };

  useEffect(() => {
    formattedData && setGraphOptions(getGraphOptions(formattedData));
    setGraphLoading(false);
    if (formattedData) {
      const { minYear, maxYear } = getMinMaxYear(formattedData);
      const minMaxYear = `${minYear}-${maxYear}`;
      setDate(minMaxYear);
    }
  }, [formattedData]);

  useEffect(() => {
    if (comparedScenarios) {
      setGraphLoading(true);
      setNoSimulation(false);
      Promise.all(
        comparedScenarios.map(simulation =>
          fetch(simulation[Object.keys(simulation)[NUMBER.N0]])
            .then(jsonRes => jsonRes.json())
            .then(res => {
              if (!res) {
                toast.error(MESSAGES.NO_SIMULATION_DATA, { toastId: 'error' });
                setNoSimulation(true);
                return null;
              }
              const scenarioName = getScenarioName(res.subProjectID ?? null);
              return {
                [Object.keys(simulation)[0]]: { ...res, scenarioName }
              };
            })
        )
      ).then(dataArray => {
        const JSONData = dataArray.filter(data => data !== null) as Array<Record<string, { timeframe: string, scenarioName: string }>>;
        const { minYear, maxYear } = getMinMaxYear(JSONData);
        if (maxYear !== Infinity && minYear !== Infinity) {
          const maxYearData = JSONData.find((item: Record<string, { timeframe: string }>) => {
            const timeframe = Object.values(item)[NUMBER.N0].timeframe;
            const [start, end] = timeframe.split('-').map(Number);
            return end === maxYear;
          });

          if (maxYearData) {
            const index = JSONData.indexOf(maxYearData);
            if (index > -NUMBER.N1 && index !== NUMBER.N0) {
              JSONData.splice(index, 1);
              JSONData.unshift(maxYearData);
            }
          }
        }
        const scenarioNamesArray: string[] = [];
        JSONData.map(item => {
          const dataValue = Object.values(item)[NUMBER.N0];
          const name = dataValue?.scenarioName;
          scenarioNamesArray.push(name);
        });
        setScenerioNames(scenarioNamesArray);
        setFormattedData(JSONData);
        setGraphLoading(false);
      });
    }
  }, [comparedScenarios]);

  const handleChartRender = (chart: any) => {
    const series = chart?.series[0];
    if (chart?.rangeSelector?.buttons?.length) {
      handleDataGrouping(chart, series);
      // Add event listener to the range selector buttons
      chart.rangeSelector.buttons.forEach((button: any) => {
        button.element.addEventListener('click', () => {
          handleDataGrouping(chart, series);
        });
      });
    }
  };

  return (graphLoading ? <FullPageLoader isLoading={graphLoading} />
    : formattedData && !noSimulation && <>
      <div className='ev-bp-table-sec'>
        <div className='ev-bp-table-inner '>
          <div className='row'>
            {formattedData?.map((simulation, i) => <CompareMericsRow key={i}
              columnSpacing={columnSpacing} title={simulation[Object.keys(simulation)[NUMBER.N0]]?.scenarioName}
              zipCode={simulation[Object.keys(simulation)[NUMBER.N0]]?.zipcode}
              metricsData={simulation[Object.keys(simulation)[NUMBER.N0]]?.metrics} />)}
          </div>
        </div>
      </div>
      <div className='simulation-sec-main-wrap ev-simulation-sec-main-wrap'>
        <div className="simulation-chart-wrapper" >
          <div className="chart-left">
            <h2 className="chart-title">Simulation Overview ({date})</h2>
            <div className='power-graph-wrap'>
              <HighchartsReact highcharts={Highcharts} options={graphOptions} callback={(chart: any) => {
                handleChartRender(chart);
              }} />
            </div>
          </div>
          <CompareLegends scenerioNames={scenerioNames} />
          <FullPageLoader isLoading={graphLoading} />
        </div>
      </div>
    </>
  );
};

export default CompareEVScenerio;
