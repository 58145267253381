import React, { useRef } from 'react';
import { searchSmallLogo } from '../../utils';
import { Form } from 'react-bootstrap';

interface IObjectSearch {
  searchVal: string
  onSearchType: (e: any) => void
  clearSearch: () => void
  showSearchInput: boolean
  setShowSearchInput: any
}

function ObjectSearch({ searchVal, onSearchType, clearSearch, showSearchInput, setShowSearchInput }: IObjectSearch) {
  const searchInput = useRef(null);

  const onSearchClick = () => {
    setShowSearchInput(true);
    setTimeout(() => {
      if (searchInput.current) {
        (searchInput?.current as HTMLElement).focus();
      }
    });
  };

  return (
    <>
      {!showSearchInput && <span className="custom-search" onClick={onSearchClick} title='Search Objects'>
        <img src={searchSmallLogo} alt="logo" className="search-logo" />
      </span>}

      {showSearchInput && <Form.Group
        className={'form-group search-object-form-group'}
      >
        <Form.Control
          value={searchVal}
          type="text"
          placeholder={'Search object'}
          onChange={(e) => onSearchType(e.target.value)}
          autoComplete='off'
          id="searchInput"
          ref={searchInput}
        />
        <span className="icon-close-grey-icon cursor-pointer" onClick={clearSearch}></span>
      </Form.Group>}
    </>
  );
}

export default ObjectSearch;
