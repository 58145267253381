import { createSlice } from '@reduxjs/toolkit';
import { ISimulationMatricsData, NUMBER } from '../../constants';

const initialState: ISimulationMatricsData = {
  Battery_Intensity: NUMBER.N0,
  Capex: NUMBER.N0,
  'Carbon_Abatement_(MT)': NUMBER.N0,
  'Carbon_Intensity_(CO2e)': NUMBER.N0,
  Grid_Intensity: NUMBER.N0,
  NPV: NUMBER.N0,
  Opex: NUMBER.N0,
  Period_Value: NUMBER.N0,
  S3_URI: '',
  S3_URI_JSON: '',
  S3_URI_ONEYEAR_JSON: '',
  S3_URI_ONEYEAR: '',
  Solar_Intensity: NUMBER.N0,
  Total_Energy_Consumed: NUMBER.N0,
  Total_Energy_Produced: NUMBER.N0,
  Turbine_Intensity: NUMBER.N0,
  Period_value: NUMBER.N0,
  IRR: NUMBER.N0,
  LCOE_Total: NUMBER.N0,
  LCOE_Turbine: NUMBER.N0,
  LCOE_Battery: NUMBER.N0,
  LCOH: NUMBER.N0,
  LCOH_Mobility: NUMBER.N0,
  LCOH_Industry: NUMBER.N0,
  Local_Tax_Credits: NUMBER.N0,
  Carbon_Tax: '',
  Renewable_Power: NUMBER.N0,
  Low_Carbon_H2: NUMBER.N0,
  Industry_H2: NUMBER.N0,
  Green_Store_H2: NUMBER.N0,
  Mobility_H2: NUMBER.N0,
  Grey_H2: NUMBER.N0,
  details_graph_series: {},
  total_gaseous_co2_sold: NUMBER.N0,
  total_gaseous_co2_shortfall: NUMBER.N0,
  avg_gaseous_co2_shortfall_per_year: NUMBER.N0
};

const simulationMetricsData = createSlice({
  name: 'simulationMetricsData',
  initialState,
  reducers: {
    updateSimulationMetricsDatas: (state, payload) => {
      return { ...payload.payload };
    },
    resetSimulationMetricsDatas: (state) => {
      return { ...initialState };
    }
  }
});

export const { updateSimulationMetricsDatas, resetSimulationMetricsDatas } = simulationMetricsData.actions;

export default simulationMetricsData.reducer;
