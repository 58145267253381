import React from 'react';

const ActivitySec = () => {
  return (
    <>

      <div className="activity-sec">
        <div className="dropdown-wrapper">
          <span className="user-icon">R</span>
          <div className="right-sec">

            <p className="dropdown-des">
              Frank Richeu shared a model ‘Water Automation’
              with you.
            </p>
            <span className="duration-status">Just now</span>
          </div>
        </div>
      </div>

    </>
  );
};

export default ActivitySec;
