import { useState } from 'react';
export const usePasswordToggler = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [fieldType, setType] = useState('password');
  const handlePasswordVisibility = () => {
    if (fieldType === 'password') {
      setType('text');
      setPasswordVisibility(!passwordVisibility);
    } else if (fieldType === 'text') {
      setType('password');
      setPasswordVisibility(!passwordVisibility);
    }
  };
  return {
    fieldType,
    passwordVisibility,
    handlePasswordVisibility
  };
};
