import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import CustomSelect from '../admin/shared/CustomSelect';
import { outputListOption, typeOfAnalysisOptions } from '../../utils/defaultData';
import { MESSAGES, NUMBER } from '../../constants';
import Slider from 'rc-slider';
import { useAppSelector } from '../../redux/hooks';
import MonteSettingForm from './MonteSettingForm';
import { MONTE_CARLO_FORM_SCHEMA, showKeysAndCount } from '../../utils';
import { useGeoThermalSimulation } from '../../hooks/useGeoThermalSimulation';
import { generateFileContent } from '../../utils/file_helper';
import { toast } from 'react-toastify';
import Select from 'react-select';

const MonteCarloForm = ({ setFile, setParamFile }: any) => {
  const [ShowInputVariables, setShowInputVariables] = useState(false);
  const { runMonteCarloSimulation, runMontiCarloApi } = useGeoThermalSimulation();
  const [outputValueTrack, setOutputValueTrack] = useState<any>([]);
  const [settingForm, setSettingForm] = useState({});
  const [updatedSetVal, setUpdatedSetVal] = useState({});
  const formikRef = useRef<FormikProps<any> | null>(null);
  const monteCarloSettingField = useAppSelector(state => state.workbench.monteCarloSettingField);
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';

  const initialFormValues = {
    type_of_analysis: { value: 'GEOPHIRES', label: 'GEOPHIRES' },
    settingForm,
    output_values_to_track: outputValueTrack,
    'ITERATIONS': 500
  };

  const handleFormSubmit = (values: any) => {
    const inputFields = Object.keys(updatedSetVal).map((key) => {
      // @ts-expect-error Expected Error
      const field = settingForm[key];
      return `INPUT, ${key}, ${field.distribution}, ${field.param1}, ${field.param3 ? `${field.param3}, ${field.param2}` : `${field.param2}`}`;
    });

    const outputFields = outputValueTrack.map((key: any) => {
      return `OUTPUT, ${key.label}`;
    });

    const formattedValues = `
      ${inputFields.join('\n')}
      ${outputFields.join('\n')}
      ITERATIONS, ${values.ITERATIONS}
    `.replace(/^\s+/gm, '').trim();
    const parameterData = generateFileContent(runMonteCarloSimulation());

    const blob = new Blob([formattedValues], { type: 'text/plain' });
    const parameterBlob = new Blob([parameterData], { type: 'text/plain' });
    const parameterFile = new File([parameterBlob], 'params_file.txt', { type: 'text/plain' });
    const newFile = new File([blob], 'settings_file.txt', { type: 'text/plain' });

    setFile(newFile);
    setParamFile(parameterFile);

    const formData = new FormData();
    formData.append('settings_file', newFile);
    formData.append('params_file', parameterFile);
    runMontiCarloApi(formData, scenarioId, (res) => {
      toast.success(MESSAGES.MONTE_CARLO_SIM_STARTED);
    });
  };

  const keyValues = showKeysAndCount(updatedSetVal ?? {});

  useEffect(() => {
    const settingValue = Object.keys(settingForm || {}).reduce((acc, key) => {
      // @ts-expect-error Expected Error
      if (settingForm[key].checked) {
        // @ts-expect-error Expected Error
        acc[key] = settingForm[key];
      }
      return acc;
    }, {});
    setUpdatedSetVal(settingValue);
  }, [settingForm]);

  const onOutputTrackChangeHandler = (event: any) => {
    if (event.length <= NUMBER.N2) {
      setOutputValueTrack(event);
    } else {
      toast.error(MESSAGES.OUTPUT_LIMIT);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
        validationSchema={MONTE_CARLO_FORM_SCHEMA}
      >
        {({ values, handleSubmit, setFieldValue, handleChange }) => (
          <Form className={'simulation-form'} onSubmit={handleSubmit}>
            <Form.Group className={'monte_form_group'}>
              <CustomSelect
                options={typeOfAnalysisOptions}
                label={'Type of Analysis'}
                name={'type_of_analysis'}
                value={values.type_of_analysis}
                onChange={handleChange}
              />
              <ErrorMessage name={'type_of_analysis'} component="span" className='error-msg' />
            </Form.Group>
            <Form.Group className={'monte_form_group'}>
              <div className="monte-form-group" onClick={() => setShowInputVariables(true)}>
                <span className='form-label'>Configure Input Variables</span>
                <div className="monte-select-button">
                  {Object.keys(updatedSetVal).length > 0 ? (
                    Object.keys(updatedSetVal).length === 1 ? (
                      <p className='monte-modal-value'>
                        {keyValues.key1}
                      </p>
                    ) : Object.keys(updatedSetVal).length === 2 ? (
                      <p className='monte-modal-value'>
                        {keyValues.key1}, {keyValues.key2}
                      </p>
                    ) : (
                      <p className='monte-modal-value'>
                        {keyValues.key1}, {keyValues.key2}
                        {keyValues.remainingCount && <span className='value-numbers'> +{keyValues.remainingCount}</span>}
                      </p>
                    )
                  ) : (
                    <span className='disable-placeholder'>Select...</span>
                  )}
                </div>
              </div>
              <ErrorMessage name={'settingForm'} component="span" className='error-msg' />
            </Form.Group>

            <Form.Group className={'monte_form_group'}>
              <div className="custom-select-main grey-border-select output-select">
                <Form.Label>Select Output values to track</Form.Label>
                <Select
                  className="custom-select-wrp"
                  classNamePrefix="select"
                  isDisabled={false}
                  isClearable={false}
                  isSearchable={false}
                  onChange={(event) => onOutputTrackChangeHandler(event)}
                  name="output_values_to_track"
                  options={outputListOption}
                  placeholder="Select Output values to track"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={outputValueTrack}
                />
              </div>
              <ErrorMessage name={'output_values_to_track'} component="span" className='error-msg' />
            </Form.Group>


            <span className='demand-slider-value form-label'>No. Iteration*</span>
            <div className="slider-wrap-inner">
              <div className='schedule-slider-progress-value-wrap'>
                <div className="star-progress-bar">
                  <Slider
                    min={NUMBER.N500}
                    max={NUMBER.N1000}
                    value={values.ITERATIONS}
                    step={NUMBER.N10}
                    onChange={async (e) => await setFieldValue('ITERATIONS', e)}
                  />
                </div>
              </div>
              <Button className='btn-outline calculate-btn'>{values.ITERATIONS}</Button>
            </div>

            <div className="simulate-btn-wrap">
              <Button className="primary" type="submit">Calculate</Button>
            </div>
          </Form>
        )}
      </Formik>
      <MonteSettingForm
        ShowInputVariables={ShowInputVariables}
        setShowInputVariables={setShowInputVariables}
        monteCarloSettingField={monteCarloSettingField}
        setSettingForm={setSettingForm}
        settingForm={settingForm}
      />
    </div>
  );
};

export default MonteCarloForm;
