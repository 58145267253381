import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { crossObservation } from '../../utils';

interface FileUploadProps {
  onFileUploaded: (file: File) => void
  imgToShow: any
  removeImg: () => void
};

const FileUpload: React.FC<FileUploadProps> = ({ onFileUploaded, imgToShow, removeImg }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const validateFile = (file: File) => {
    if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
      setErrorMessage('File type must be JPG or PNG.');
      return false;
    }
    if (file.size > 4 * 1024 * 1024) {
      setErrorMessage('File size exceeds 4MB.');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (validateFile(file)) {
        onFileUploaded(file);
        toast.success('File uploaded successfully');
      }
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const { errors } = fileRejections[0];
    if (errors[0].code === 'file-invalid-type') {
      setErrorMessage('File type must be JPG or PNG.');
    } else if (errors[0].code === 'file-too-large') {
      setErrorMessage('File size exceeds 4MB.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg']
    },
    maxSize: 4 * 1024 * 1024 // 4MB in bytes
  });

  return (
    <>
      {!imgToShow ? <>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag and drop an image file here, or click to select one</p>
        </div>
      </>
        : <div className=''>
          <div className='uploaded-file-observation'>
            <div className='observation-img-wrap'>
              <img src={crossObservation} alt="cross logo" className='observationi-cross-btn' onClick={removeImg} />
              <img src={URL.createObjectURL(imgToShow)} alt="Uploaded" className='uploaded-img-observation' />
            </div>
          </div>
        </div>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </>
  );
};

export default FileUpload;
