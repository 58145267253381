import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { FORM_VALIDATION_SCHEMA, hasFieldError, infoInputLogo } from '../../../utils';
import { colourOptions } from '../../../utils/defaultData';
import { Formik, FormikHelpers } from 'formik';
import InputElement from '../../shared/InputElement';
import { useLazyGetAllCompanyQuery } from '../../../redux/services/admin/companyManagementApis';
import { IFormValues, MESSAGES, NUMBER } from '../../../constants';
import { useSaveUserMutation } from '../../../redux/services/admin/userManagementApis';
import { toast } from 'react-toastify';
import ModalHoc from '../shared/ModalHoc';
import PermissionModal from '../company/PermissionModal';

const AddNewUser = ({ ShowObjectModal, setShowObjectModal }: any) => {
  const [getAllCompany, { data: selectCompany, isLoading }] = useLazyGetAllCompanyQuery();
  const [saveUser] = useSaveUserMutation();
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState('');
  const [showModal, setShowModal] = useState(false);

  const initialFormValues: IFormValues = {
    firstName: '',
    lastName: '',
    userName: '',
    role: '',
    companyID: ''
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (ShowObjectModal && !selectCompany?.length) {
      getAllCompany({});
    }
  }, [ShowObjectModal]);

  const handleFormSubmit = () => {
    return (values: IFormValues, { resetForm }: FormikHelpers<IFormValues>) => {
      values.userName = values.userName.toLowerCase();
      saveUser(values)
        .unwrap()
        .then((res: any) => {
          resetForm();
          setShowObjectModal(false);
          toast.success(MESSAGES.USER_ADDED_SUCCESS);
        }).catch(() => {
          toast.error(MESSAGES.USER_ADD_ERROR);
        });
    };
  };

  return (
    <Modal
      show={ShowObjectModal}
      onHide={() => setShowObjectModal(false)}
      dialogClassName="modal-701 top-right-modal add-user-modal modal-dialog-centered"
      className="forget-modal setting-modal object-parameters-modal"
    >
      <Modal.Header closeButton className="" onClick={() => setShowObjectModal(false)}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">Add New User</h4>
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          onSubmit={handleFormSubmit()}
          validationSchema={FORM_VALIDATION_SCHEMA}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            setFieldError,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form className="object-parameter-form add-uder-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <InputElement
                    label="First Name"
                    placeholder="Enter First Name"
                    required={true}
                    type="text"
                    value={values?.firstName}
                    name="firstName"
                    data-testid="firstName"
                    onChange={handleChange}
                    resetError={setFieldError}
                    onBlur={handleBlur}
                    hasError={hasFieldError(errors, touched, 'firstName')}
                    maxLength={NUMBER.N25}
                  />
                </div>
                <div className="col-md-6">
                  <InputElement
                    label="Last Name"
                    placeholder="Enter Last Name"
                    required={true}
                    type="text"
                    value={values?.lastName}
                    name="lastName"
                    data-testid="lastName"
                    onChange={handleChange}
                    resetError={setFieldError}
                    onBlur={handleBlur}
                    hasError={hasFieldError(errors, touched, 'lastName')}
                    maxLength={NUMBER.N25}
                  />
                </div>
                <div className="col-md-6">
                  <InputElement
                    label="Email"
                    placeholder="Enter Email"
                    required={true}
                    type="text"
                    value={values?.userName}
                    name="userName"
                    data-testid="userName"
                    onChange={handleChange}
                    resetError={setFieldError}
                    onBlur={handleBlur}
                    hasError={hasFieldError(errors, touched, 'userName')}
                  />
                </div>
                <div className="col-md-6">
                  <div className="custom-select-main mb-0">
                    <Form.Label>
                      User Role<sup>*</sup>
                    </Form.Label>
                    <Select
                      className={`custom-select-wrp ${
                        hasFieldError(errors, touched, 'role') ? 'error-field-select' : ''
                      }`}
                      classNamePrefix="select"
                      // @ts-expect-error company value
                      value={values?.role?.value}
                      name="role"
                      options={colourOptions}
                      placeholder="Select User Role"
                      onChange={(selectedOption) => {
                        setFieldValue('role', selectedOption?.value);
                        setRoles(selectedOption.value);
                      }}
                      onBlur={async () => await setFieldTouched('role', true)}
                    />
                    {touched.role && errors.role && <span className="error-msg">{errors.role}</span>}
                    <div className="tooltip-container">
                      <Button className="btn-no-outline info-btn" disabled={!values.role || !values.companyID} onClick={openModal}>
                        <img src={infoInputLogo} alt="log" />
                      </Button>
                      <div className="tooltip-text">See Permission</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main">
                    <Form.Label>
                      Company<sup>*</sup>
                    </Form.Label>
                    <Select
                      className={`custom-select-wrp ${
                        hasFieldError(errors, touched, 'companyID') ? 'error-field-select' : ''
                      }`}
                      classNamePrefix="select"
                      // @ts-expect-error company value
                      value={values?.companyID?.value}
                      isLoading={isLoading}
                      name="companyID"
                      options={selectCompany?.filter((company: any) => company.status === 'active') || []}
                      placeholder="Select Company"
                      onChange={(selectedOption) => {
                        setFieldValue('companyID', selectedOption?.value);
                        setPermissions(selectedOption?.role_permissions);
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onBlur={async () => await setFieldTouched('companyID', true)}
                    />
                    {touched.companyID && errors.companyID && <span className="error-msg">{errors.companyID}</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <Button className="send-invite-btn" type="submit">
                    Send Invite
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ModalHoc showModal={showModal} setShowModal={hideModal} permiDialogClass='permission-list-modal'>
          <PermissionModal permission={permissions[roles as any] || []} handleCross={hideModal} />
        </ModalHoc>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewUser;
