import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalProps } from '../../../constants';

const ModalHoc = ({ children, showModal, setShowModal, modalClass, modalTitle, permiDialogClass = 'modal-503', closeBtnClass = 'd-none' }: ModalProps) => {
  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      dialogClassName={`modal-dialog-centered top-right-modal ${permiDialogClass}`}
      className={`forget-modal add-to-baseline-modal share-modal approval-modal ${modalClass}`}
      style={{ zIndex: 1080 }}
    >
      <Modal.Header
        closeButton
        className={closeBtnClass}
        onClick={setShowModal}
      >
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default ModalHoc;
