import { toast } from 'react-toastify';
import {
  EXCEL_EXPORT_TYPE, EXPORT_TYPE, IComponentProperties, IExportJSON, IObjectList,
  IScenarioDetails, ISheets, MESSAGES, NUMBER, PROPERTY_FIELD_TYPE, IDsiderNode
} from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { addImportJsonData, updateUnsavedChange, updateWorkbenchData, updateNodes, updateEdges } from '../redux/workbenchSlice';
import * as XLSX from 'xlsx';
import { createArrayWithLengthAndValues, getFormatedNodes, getNodeDataFromExcel, getUniqueArrayList, rand } from '../utils';
import { Node } from 'reactflow';
import { startFullLoading, stopFullLoading } from '../redux/SpinnerSlice';

export const useExportJson = () => {
  const workbenchData = useAppSelector(state => state.workbench);
  const workbenchSettings = useAppSelector(state => state.WorkbenchSettings);
  const objectList: IObjectList[] = useAppSelector(state => state.objectList.list);
  const { currentLayerID, breadCrumb } = useAppSelector(state => state.layersData);
  const dispatch = useAppDispatch();

  /**
  * @description Function to get data from redux store and export the JSON data of Workbench
  */
  const exportJsonData = (scemarioDetails?: IScenarioDetails) => {
    const scenarioData = scemarioDetails?.exportJSON ?? workbenchData;
    if (!scenarioData.nodes.length) {
      toast.error(MESSAGES.NO_EXPORT_DATA);
      return;
    }
    const settings = { ...workbenchSettings };
    settings.importJson = true;
    const exportData = {
      nodes: resetComments(scenarioData.nodes),
      edges: scenarioData.edges,
      viewPort: scenarioData.viewPort,
      theme: scemarioDetails?.theme ?? workbenchData.theme,
      settings: settings
    };
    const data = JSON.stringify(exportData);
    const blob = new Blob([data], { type: EXPORT_TYPE });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${scemarioDetails?.name ?? workbenchData.scenarioDetails.name}.json`;
    a.click();

    URL.revokeObjectURL(url);
    toast.success(MESSAGES.EXPORT_SUCCESS);
  };

  /**
  * @description Function to Import the JSON data to Workbench and set data to redux store
  */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, callback: any) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file && file.type === EXPORT_TYPE) {
      dispatch(startFullLoading());
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const parsedData: IExportJSON = JSON.parse(e.target.result);
          const hasAccesstoAllObject = await checkComponentAccess(parsedData.nodes as IDsiderNode[]) as any[];
          if (!hasAccesstoAllObject?.length) {
            const layeredNodeData = await setCurrentLayerID(parsedData);
            dispatch(addImportJsonData(layeredNodeData));
            dispatch(updateUnsavedChange(true));
            toast.success(MESSAGES.IMPORT_SUCCESS);
            callback(layeredNodeData.settings);
            dispatch(stopFullLoading());
          } else {
            const msg = `The JSON contains ${(hasAccesstoAllObject as any[]).join(', ')} for which access permissions are not granted. Please import different JSON file.`;
            toast.error(msg);
            dispatch(stopFullLoading());
          }
        } catch (error: any) {
          toast.error(error);
          dispatch(stopFullLoading());
        }
      };
      reader.readAsText(file);
    } else {
      toast.error(MESSAGES.JSON_INVALID);
    }
  };

  /**
   * @param data import JSON data...
   * @returns Funtion to add imported data into current layer....
   */
  const setCurrentLayerID = async (data: IExportJSON) => {
    const updatedNodes = workbenchData.nodes.filter(node => {
      if (node.data.parentNode === currentLayerID) {
        return false; // Exclude the current layer nodes
      }
      return true;
    });
    const updatedEdges = workbenchData.edges.filter(edge => {
      if (edge.data.parentNode === currentLayerID) {
        return false; // Exclude the current layer edges
      }
      return true;
    });
    dispatch(updateNodes(updatedNodes));
    dispatch(updateEdges(updatedEdges));

    // added currentLayerID and updated new ids
    const updatedNodeIds: Record<string, string> = {};
    data.nodes.forEach(n => {
      const newId = `${n.id}_${rand(NUMBER.N10000)}`;
      updatedNodeIds[n.id] = newId;
      n.id = newId;
      n.data.parentNode = !n.data.parentNode ? currentLayerID ?? '' : updatedNodeIds[n.data.parentNode] ?? '';
    });
    data.edges.forEach(e => {
      e.sourceHandle = e.sourceHandle?.replace(e.source, updatedNodeIds[e.source]);
      e.targetHandle = e.targetHandle?.replace(e.target, updatedNodeIds[e.target]);
      e.source = updatedNodeIds[e.source];
      e.target = updatedNodeIds[e.target];
      e.id = `reactflow__edge-${e.source}${e.sourceHandle}-${e.target}${e.targetHandle}`;
      e.data = e.data ?? {};
      e.data.parentNode = !e.data.parentNode ? currentLayerID ?? '' : updatedNodeIds[e.data.parentNode] ?? '';
    });
    return data;
  };

  /**
   * @param node imported Nodes from JSON
   * @returns Function to return whether the user can access all the imported objects or not...
   */
  const checkComponentAccess = async (node: IDsiderNode[]) => {
    const unAccessedObject = [];
    for (let i = NUMBER.N0; i < node.length; i++) {
      const nodeInObject = objectList.find(ol => ol.component === node[i].data?.class);
      if (!nodeInObject) {
        unAccessedObject.push(node[i].name);
      }
    }
    return unAccessedObject;
  };

  /**
  * @description Function to get data of Workbench from redux store and export the JSON data to Excel Format
  */
  const exportExcelData = () => {
    const yearsCount = workbenchSettings.noOfYears ? +workbenchSettings.noOfYears : NUMBER.N1;
    const startYear = workbenchSettings.startYear ?? new Date().getFullYear();
    if (!workbenchData.nodes.length) {
      toast.error(MESSAGES.NO_EXPORT_DATA);
      return;
    }
    const currentLayerNodes = workbenchData.nodes.filter(node => node.data.parentNode === currentLayerID);
    const formatedNodeData = currentLayerNodes.map((node) => {
      const nodeData = node.data;
      const propertiesWithValue: IComponentProperties = {};
      // Iterate through properties and get values from data using formula titles
      for (const [propertyName, formulaTitle] of Object.entries(nodeData.formulaTitle as IComponentProperties)) {
        if (formulaTitle !== 'abatement_location' && formulaTitle !== 'location') {
          propertiesWithValue[propertyName] = nodeData[formulaTitle];
        }
      }

      return {
        'Component Name': nodeData.componentDisplayName,
        ...propertiesWithValue,
        'id': node.id
      };
    });
    const jsonData = getFormatedNodes(formatedNodeData, yearsCount, +startYear);
    const workBook: ISheets = {
      Sheets: {},
      SheetNames: []
    };
    const colWidth = createArrayWithLengthAndValues(yearsCount, NUMBER.N15);
    jsonData.map((item, index) => {
      const isAdded = Object.hasOwnProperty.call(workBook.Sheets, item.category);
      const category = isAdded ? `${item.category}-${index}` : item.category;
      const nodes = XLSX.utils.aoa_to_sheet(item.data);
      nodes['!cols'] = colWidth;
      workBook.Sheets[category] = nodes;
      workBook.SheetNames.push(category);
      return workBook;
    });

    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = getExcelFileName();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    toast.success(MESSAGES.EXCEL_EXPORT_SUCCESS);
  };

  const getExcelFileName = () => {
    if (currentLayerID) {
      const layer = breadCrumb.find(bc => bc.id === currentLayerID);
      return `${workbenchData.scenarioDetails.name}-${layer?.name}.xlsx`;
    } else {
      return `${workbenchData.scenarioDetails.name}.xlsx`;
    }
  };

  const importExcelData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }
    const file = event.target.files[0];
    // Validate file type
    const allowedFileTypes = EXCEL_EXPORT_TYPE;
    if (!allowedFileTypes?.includes(file.type)) {
      toast.error(MESSAGES.EXCEL_INVALID);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      try {
        const nodeData = getNodeDataFromExcel(workbook); // Convert the worksheet to an array of objects
        setExcelWorkbenchDataToStore(nodeData); // set workbench data to store
      } catch (error) {
        toast.error(MESSAGES.EXCEL_DATA_MISMATCH);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const setExcelWorkbenchDataToStore = (nodesPropertyValues: IComponentProperties[]) => {
    let nodesMisMatch = true;
    const propertiesTypeError: string[] = [];
    const workbenchNodes = workbenchData.nodes.map((node) => {
      const data = node.data;
      const excelPropertyValue = nodesPropertyValues.find(n => {
        if (Array.isArray(n.id)) {
          return n.id[0] === node.id;
        } else {
          return n.id === node.id;
        }
      });
      // check if component is there in the imported excel...
      if (excelPropertyValue) {
        nodesMisMatch = false; // if any node data matches, do not show node mismatch data...
        const formulaTitlesWithValues: any = {};
        for (const key in excelPropertyValue) {
          // get the values from excelPropertyValue and keys from formulaTitle and merge in new object...
          const newKey = data.formulaTitle[key] || key;
          const value = excelPropertyValue[key];
          const valueType: string = getFieldValueType(value); // string or number...
          const propertyType = data?.propertiesType[newKey] as string;
          // if property field type does not match with excel field value type then show toast message
          if (propertyType && value && valueType !== PROPERTY_FIELD_TYPE[propertyType]) {
            propertiesTypeError.push(key);
          }
          formulaTitlesWithValues[newKey] = getPropertiesValues(value);
        }
        return {
          ...node,
          data: {
            ...data,
            ...formulaTitlesWithValues
          }
        };
      } else {
        return node;
      }
    });
    if (nodesMisMatch || !workbenchData.nodes.length) {
      toast.error(MESSAGES.COMPONENT_MISMATCH);
    } else if (propertiesTypeError.length) {
      toast.error(`${getUniqueArrayList(propertiesTypeError).join(', ')}:
        ${propertiesTypeError.length === NUMBER.N1 ? MESSAGES.EXCEL_VALUE_NOT_COMPATIBLE : MESSAGES.EXCEL_VALUES_NOT_COMPATIBLE}`);
    } else {
      dispatch(updateWorkbenchData({
        nodes: workbenchNodes || [],
        importCount: workbenchData.importCount + NUMBER.N1
      }));
      dispatch(updateUnsavedChange(true));
      toast.success(MESSAGES.EXCEL_IMPORT_SUCCESS);
    }
  };

  const getPropertiesValues = (values: any) => {
    const startYear = workbenchSettings.startYear ?? new Date().getFullYear();
    let val: any = '';
    if (Array.isArray(values)) {
      val = values.map((v, index) => {
        return {
          [+startYear + index]: v
        };
      });
    } else {
      val = values;
    }
    return val;
  };

  const getFieldValueType = (value: any) => {
    let valueType = '';
    if (Array.isArray(value)) {
      const valTypes: any[] = [];
      // Use a set to store unique types present in the array
      value.forEach((val) => {
        const type = typeof val;
        if (type && !valTypes?.includes(type)) {
          valTypes.push(type);
        }
      });

      let arrayValueTypes: string;

      if (valTypes.length === NUMBER.N1) {
        arrayValueTypes = valTypes[NUMBER.N0];
      } else {
        arrayValueTypes = 'mixed';
      }
      valueType = arrayValueTypes;
    } else {
      valueType = typeof value;
    }
    return valueType;
  };

  const resetComments = (nodes: Node[]) => {
    return nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          comments: []
        }
      };
    });
  };

  return {
    exportJsonData,
    handleFileChange,
    exportExcelData,
    importExcelData
  };
};
