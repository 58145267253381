import React, { useState, useEffect } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import {
  CCUS, DEFAULT_LOCATION,
  G_THERM, HYDROGEN, MESSAGES, POWER, SHARED_SCENARIO_PERMISSION, defaultGeoThermalValues
} from '../../constants';
import { useGetSettingsDataQuery, useSaveEVSettingsMutation, useSaveLocationSettingsMutation, useSaveSettingsMutation } from '../../redux/services/settingsApis';
import DraggableNodes from '../shared/DragDropNodes/priorityDraggableNodes';
import { toast } from 'react-toastify';
import CarbonSettings from './CarbonSettings';
import FinancialSettings from './FinancialSettings';
import LocationSettingsTab from './LocationSettings';
import EVSettings from './EVSettings';
import { useGetScenarioDetailsQuery } from '../../redux/services/scenarioApis';
import { useScenario } from '../../hooks/useScenario';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetWorkbenchSettings, updateWorkbenchSettings } from '../../redux/slices/settingsSlice';

interface WorkBenchSettingProps {
  showSettingModal: boolean
  hideSettingModal: () => void
}

const OperationalRulesScenarioTypes = [POWER, HYDROGEN, CCUS, G_THERM];

const WorkBenchSettings = (props: WorkBenchSettingProps) => {
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const scenarioType = searchParams.get('type') ?? '';
  const { location } = useAppSelector(state => state.scenarioDetails);
  const hasOperationalRules = OperationalRulesScenarioTypes.includes(scenarioType);
  const { data, isSuccess, isError } = useGetSettingsDataQuery(scenarioId);
  const [locLatitude, setLatitude] = useState<string>(DEFAULT_LOCATION.lat);
  const [locLongitude, setLongitude] = useState<string>(DEFAULT_LOCATION.lng);
  const [disableClass, setDisableClass] = useState('');
  const [discountRate, setDiscountRate] = useState<string>();
  const [timeSteps, setTimeSteps] = useState<string>();
  const [ccTax, setCcTax] = useState<string>();
  const [terminalValue, setTerminalValue] = useState<string>();
  const [noOfYears, setNoOfYears] = useState<string>();
  const [startYear, setStartYear] = useState<string>();
  const [prioritySupply, setPrioritySupply] = useState([]);
  const [priorityDemand, setPriorityDemand] = useState([]);
  const [is_pw_and_a, setIsPwa] = useState<boolean>(false);
  const [is_dac, setIsDac] = useState<boolean>(false);
  const [sequestration_type, setSequestrationType] = useState<string>('tertiary');
  const [is_domestic_content, setIsDomesticContent] = useState<boolean>(true);
  const [pc_scheme, setPCScheme] = useState<string>('ptc');
  const [is_ira, setIsIra] = useState<boolean>(true);
  const [is_energy_community, setEnergyCommunity] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { updateWorkbech } = useScenario();
  const { data: scenarioDetails, isFetching, refetch } = useGetScenarioDetailsQuery(scenarioId);
  const [saveLocation, { isLoading: saveLocationLoading }] = useSaveLocationSettingsMutation();
  const [saveSettings, { isLoading: saveSettingsLoading }] = useSaveSettingsMutation();
  const [saveEvSettings, { isLoading: saveEVSettingsLoading }] = useSaveEVSettingsMutation();
  const [countryName, setCountry] = useState<string>('');

  useEffect(() => {
    if (data) {
      setLatitude(data.latitude !== 'undefined' ? data.latitude : DEFAULT_LOCATION.lat);
      setLongitude(data.longitude !== 'undefined' ? data.longitude : DEFAULT_LOCATION.lng);
      setDiscountRate(data.discountRate);
      setTimeSteps(data.timeSteps);
      setCcTax(data.ccTax);
      setTerminalValue(data.terminalValue);
      setNoOfYears(data.noOfYears);
      setStartYear(data.startYear);
      setPrioritySupply(data.prioritySupplier);
      setPriorityDemand(data.priorityDemander);
      setIsPwa(data?.is_pw_and_a);
      setIsDac(data?.is_dac);
      setSequestrationType(data.sequestration_type);
      setPCScheme(data?.pc_scheme);
      setIsDomesticContent(data?.is_domestic_content);
      setIsIra(data?.is_ira);
      setEnergyCommunity(data?.is_energy_community);
      dispatch(updateWorkbenchSettings(data));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      dispatch(resetWorkbenchSettings());
    }
  }, [isError]);

  useEffect(() => {
    if (!isFetching && scenarioDetails) {
      updateWorkbech(scenarioDetails);
      setDisableClass(() => scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR ? 'disabled' : '');
    }
  }, [isFetching]);


  useEffect(() => {
    if (typeof location === 'string') {
      setCountry(location);
    }
  }, [location]);

  const saveLocSettings = () => {
    if (locLatitude && locLongitude) {
      saveLocation({
        scenarioId,
        latitude: Number(locLatitude),
        longitude: Number(locLongitude),
        abatement_latitude: Number(locLatitude),
        abatement_longitude: Number(locLongitude)
      })
        .then((res: any) => {
          handleResponse(res, MESSAGES.LOCATION_SETTINGS);
          refetch();
          dispatch(updateWorkbenchSettings({ latitude: locLatitude, longitude: locLongitude }));
        });
    }
  };

  const saveEVSettings = () => {
    const payload = {
      noOfYears,
      startYear,
      subProjectID: scenarioId
    };
    saveEvSettings(payload)
      .then((res: any) => {
        handleResponse(res, MESSAGES.SETTINGS_UDPATED);
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };

  const handleResponse = (res: any, message: string) => {
    if (res.data) {
      toast.success(message);
    } else {
      toast.error(res?.error?.message ?? MESSAGES.PROJECT_INVALID_NAME);
    }
  };

  const handleFormSubmit = (values: any) => {
    const payload = {
      latitude: locLatitude.toString(),
      longitude: locLongitude.toString(),
      discountRate: discountRate?.toString(),
      timeSteps: values.timeSteps || 'hourly',
      ccTax,
      terminalValue,
      noOfYears: noOfYears?.toString(),
      startYear,
      subProjectID: scenarioId,
      prioritySupplier: values.prioritySupplier || prioritySupply,
      priorityDemander: values.priorityDemander || priorityDemand,
      is_pw_and_a: values.is_pw_and_a,
      is_dac: values.is_dac,
      sequestration_type: values.sequestration_type,
      is_domestic_content: values.is_domestic_content,
      is_ira: values.is_ira,
      pc_scheme: values.pc_scheme,
      is_energy_community: values.is_energy_community,
      // for geo thermal only
      economic_model: (values.economic_model || defaultGeoThermalValues.economic_model)?.toString(),
      inflation_rate_during_construction: (values.inflation_rate_during_construction || defaultGeoThermalValues.inflation_rate_during_construction)?.toString(),
      fixed_charge_rate: (values.fixed_charge_rate || defaultGeoThermalValues.fixed_charge_rate)?.toString(),
      // for gtherm only
      LicenseFees: values.LicenseFees?.toString(),
      PermittingCosts: values.PermittingCosts?.toString(),
      TaxCredits: values.TaxCredits?.toString(),
      TaxCredit45Q: values.TaxCredit45Q?.toString(),
      DebtInterestPayment: values.DebtInterestPayment?.toString(),
      DirectorExpenses: values.DirectorExpenses?.toString(),
      TotalCOGS: values.TotalCOGS?.toString(),
      DirectExpenses: values.DirectExpenses?.toString()
    };
    saveSettings(payload)
      .then((res: any) => {
        handleResponse(res, MESSAGES.SETTINGS_UDPATED);
        dispatch(updateWorkbenchSettings(payload));
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };

  const onFieldChange = (value: any, ele: string) => {
    switch (ele) {
      case 'discountRate':
        setDiscountRate(value);
        break;
      case 'timeSteps':
        setTimeSteps(value);
        break;
      case 'ccTax':
        setCcTax(value);
        break;
      case 'terminalValue':
        setTerminalValue(value);
        break;
      case 'noOfYears':
        setNoOfYears(value);
        break;
      case 'startYear':
        setStartYear(value);
        break;
      default:
        break;
    }
  };

  const resetLocation = () => {
    if (data !== undefined) {
      setLatitude(data.latitude !== 'undefined' ? data.latitude : DEFAULT_LOCATION.lat);
      setLongitude(data.longitude !== 'undefined' ? data.longitude : DEFAULT_LOCATION.lng);
    }
    props.hideSettingModal();
  };

  return (
    <Modal
      show={props.showSettingModal}
      onHide={() => props.hideSettingModal()}
      dialogClassName={'modal-701 top-right-modal'}
      className={'forget-modal setting-modal'}
    >
      <Modal.Header
        closeButton
        className=""
        onClick={resetLocation}
      >
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">Settings</h4>
        <div className="dsider-tab-wrapper">
          <Tabs
            defaultActiveKey={hasOperationalRules ? 'operational' : 'financial'}
            id="uncontrolled-tab-example"
            className=""
          >
            {hasOperationalRules && <Tab eventKey="operational" title="Operational Rules">
              <div className={`tab-main-container ${disableClass}`}>
                <div className="operational-main-wrapper">
                  <DraggableNodes
                    data={data}
                    hideModal={() => props.hideSettingModal()}
                    handleForm={handleFormSubmit}
                    loading={saveSettingsLoading}
                  />
                </div>
              </div>
            </Tab>}
            <Tab eventKey="financial" title="Financials">
              <div className={`${disableClass}`}>
                <FinancialSettings
                  handleForm={handleFormSubmit}
                  loading={saveSettingsLoading}
                  formData={data}
                  fieldChange={onFieldChange}
                  hideModal={() => props.hideSettingModal()}
                />
              </div>
            </Tab>
            {countryName === 'United States' &&
            <Tab eventKey="carbon" title="Carbon">
              <div className={`${disableClass}`}>
                <CarbonSettings
                  handleForm={handleFormSubmit}
                  loading={saveSettingsLoading}
                  formData={data}
                  fieldChange={onFieldChange}
                  hideModal={() => props.hideSettingModal()}
                />
              </div>
            </Tab>}
            <Tab eventKey="location" title="Location">
              <div className={`${disableClass}`}>
                <LocationSettingsTab
                  latitude={locLatitude}
                  longitude={locLongitude}
                  loading={saveLocationLoading}
                  setLatitude={(value) => setLatitude(value)}
                  setLongitude={(value) => setLongitude(value)}
                  saveLocSettings={saveLocSettings}
                  hideModal={resetLocation}
                />
              </div>
            </Tab>
          </Tabs>
        </div>

        {/* {projectDetails.project_type === 'ev' && (
          <EVSettings
            handleForm={saveEVSettings}
            formData={data}
            fieldChange={onFieldChange}
            hideModal={() => props.hideSettingModal()}
          />
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default WorkBenchSettings;
