/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Header from '../../components/layout/Header';
import { useGetAllProjectsQuery, useGetProjectTypesQuery, useLazyGetProjectAndSharedScenariosQuery } from '../../redux/services/projectApis';
import {
  useCompareScenarioMutation, useCompareEVScenarioMutation,
  useCompareScenarioGraphPlottingMutation
} from '../../redux/services/scenarioApis';
import { toast } from 'react-toastify';
import { EV, GEO_THERMAL, MESSAGES, NUMBER, PROJECT_TYPE } from '../../constants';
import { Button } from 'react-bootstrap';
import CompareEVScenerio from './CompareEVScenerio';
import FullPageLoader from '../../components/shared/FullPageLoader';
import { GRAPH_COLORS_ARRAY, SCENARIO_TYPE_LABELS, SCENARIO_TYPE_KEYS } from '../../constants/graph.constant';
import { customNoOptionsMessage, getDateTime } from '../../utils';
import CompareScenerio from './ComparePowerScenerio';
import CompareNewGeoScenario from './CompareNewGeoScenario';

const CompareScenerioHOC = () => {
  const { data, isSuccess, isLoading } = useGetAllProjectsQuery();
  const { data: projectTypeData, isSuccess: projectTypeSuccess } = useGetProjectTypesQuery();
  const [projects, setProjects] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectData, setSelectedProjectData] = useState('');
  const [scenarioList, setScenarioList] = useState([]);
  const [isSearchable] = useState(true);
  const [isRtl] = useState(false);
  const [noSimulation, setNoSimulation] = useState(false);
  const [selectedScenarioList, setSelectedScenarioList] = useState<any>([]);
  const [usedKeys, setUsedKeys] = useState<any>([]);
  const [isScrolled, setIsScrolled] = useState<boolean | null>(null);
  const compareDivRef = useRef<HTMLDivElement>(null);
  const [compareEVScenario] = useCompareEVScenarioMutation();
  const [comparedScenarios, setCompareScenarios] = useState<any>();
  const [compareScenariosFinancials, setCompareScenariosFinancials] = useState<any>();
  const [compareScenarioGraphData, setCompareScenarioGraphData] = useState<any>();
  const [isPowerLoading, setIsPowerLoading] = useState<boolean>(false);
  const [selectedProjectType, setSelectedProjectType] = useState<string>('');
  const [compareScenario] = useCompareScenarioMutation();
  const [compareScenarioGraph] = useCompareScenarioGraphPlottingMutation();
  const [getProjectAndSharedScenarios, { isFetching }] = useLazyGetProjectAndSharedScenariosQuery();
  const [loadEvCompare, setLoadEvCompare] = useState<boolean>(false);
  const [loadPowerCompare, setLoadPowerCompare] = useState<boolean>(false);
  const [projectTypeWithLabel, setProjectTypeWithLabel] = useState<any>();

  const manipulateProjectTypeData = () => {
    const projecTypesArr: any = [];
    projectTypeData?.project_types?.forEach((element: any) => {
      projecTypesArr.push({
        value: element,
        label: PROJECT_TYPE[element]
      });
    });
    projecTypesArr.unshift({
      value: null,
      label: 'Filter by Type',
      isDisabled: true
    });
    return projecTypesArr;
  };

  const onProjectTypeHandler = (event: any) => {
    setSelectedProjectData('');
    setSelectedScenarioList([]);
    setCompareScenariosFinancials([]);
    setUsedKeys([]);
    setScenarioList([]);
    setCompareScenarios(null);
    setSelectedProject('');
    setSelectedProjectType(event.value);
    setProjectTypeWithLabel(event);
    const filteredProjects = data?.filter(p => p.project_type === event.value);
    setProjects(manipulateDropdownData(filteredProjects));
  };

  const clearAllData = () => {
    setSelectedProject('');
    setSelectedScenarioList([]);
    setSelectedProjectData('');
    setSelectedProjectType(() => '');
    setLoadEvCompare(false);
    setProjectTypeWithLabel('');
    setLoadPowerCompare(false);
  };

  const manipulateDropdownData = (value: any) => {
    const tempArray: any = [];
    value.forEach((element: any) => {
      tempArray.push({
        value: null,
        label: PROJECT_TYPE[element.project_type],
        isDisabled: true
      });
      tempArray.push({
        value: element._id,
        label: element.name,
        type: element.project_type
      });
    });
    tempArray.unshift({
      value: null,
      label: tempArray.length ? 'Select Project' : 'No Projects found!',
      isDisabled: true
    });
    return tempArray;
  };
  const manipulateSecondDropdownData = (value: any) => {
    const tempArray: any = [];
    value.forEach((element: any) => {
      element.name && tempArray.push({
        value: element._id ?? element.id,
        label: element.name,
        type: element.subProject_Type,
        isShared: element.isSharedScenario,
        createdOn: getDateTime(element)
      });
    });
    return tempArray;
  };

  const onProjectSelectHandler = (event: any) => {
    setSelectedScenarioList([]);
    setCompareScenarios(null);
    setSelectedProjectData(event);
    setSelectedProject(event.value);
    setSelectedProjectType(event.type);
  };

  const onScenarioChangeHandler = (event: any) => {
    setCompareScenarios(null);
    if (event.length <= NUMBER.N15) {
      setSelectedScenarioList(event);
    }
  };

  const onCompareScenarioHandler = () => {
    setIsPowerLoading(true);
    setNoSimulation(false);
    const params = { scenario_ids: selectedScenarioList.map((item: any) => item.value) };
    if (selectedProjectType === EV) {
      compareEVScenario(params).then((res: any) => {
        setCompareScenarios(res.data);
        setIsPowerLoading(false);
        setLoadEvCompare(true);
      }).catch((err: any) => {
        toast.error(err.message);
        setIsPowerLoading(false);
      });
    } else {
      const powerData: any = [];
      setLoadPowerCompare(true);
      compareEVScenario(params).then(res => {
        if ('error' in res) {
          setIsPowerLoading(false);
          return;
        }
        res.data?.map((link: any, i: number) => {
          const jsonURL = link[Object.keys(link)[NUMBER.N0]];
          if (jsonURL) {
            fetch(jsonURL)
              .then(resp => resp.json())
              .then((resp) => {
                resp.id = Object.keys(link)[NUMBER.N0];
                powerData.push(resp);
              })
              .finally(() => {
                if (powerData.length === selectedScenarioList.length) {
                  const sortedArray = powerData.sort((a: any, b: any) => {
                    const idA = a.id;
                    const idB = b.id;

                    // Find the index of each id in sortOrderArray
                    const indexA = selectedScenarioList.findIndex((item: any) => item.value === idA);
                    const indexB = selectedScenarioList.findIndex((item: any) => item.value === idB);

                    // Compare the indices to determine the sorting order
                    return indexA - indexB;
                  });
                  setCompareScenarios(getMergedData(sortedArray));
                } else {
                  setCompareScenarios(getMergedData(powerData));
                }
              });
          } else {
            toast.error(MESSAGES.COMPARE_NO_SIM_DATA);
            setNoSimulation(true);
            setIsPowerLoading(false);
          }
        });
      });
      // finance api
      getCompareStatistics(params);
      getCompareGraph(params);
    }
  };

  const getCompareStatistics = (params: any) => {
    compareScenario(params).then((res: any) => {
      setCompareScenariosFinancials(res.data);
      setIsPowerLoading(false);
    }).catch((err: any) => {
      toast.error(err.message);
    });
  };

  useEffect(() => {
    if (selectedProjectType === GEO_THERMAL) {
      const data: any = [];
      compareScenariosFinancials.map((ele: any) => {
        const dataPoints = ele['POWER GENERATION PROFILE'];
        if (ele.subProjectID) {
          const paramName = dataPoints[0];
          data.push({
            [ele.subProjectID]: {
              [paramName[1]]: {
                2024: dataPoints[1][1],
                2025: dataPoints[2][1],
                2026: dataPoints[3][1],
                2027: dataPoints[4][1],
                2028: dataPoints[5][1],
                2029: dataPoints[6][1],
                2030: dataPoints[7][1],
                2031: dataPoints[8][1],
                2032: dataPoints[9][1],
                2033: dataPoints[10][1]
              },
              [paramName[2]]: {
                2024: dataPoints[1][2],
                2025: dataPoints[2][2],
                2026: dataPoints[3][2],
                2027: dataPoints[4][2],
                2028: dataPoints[5][2],
                2029: dataPoints[6][2],
                2030: dataPoints[7][2],
                2031: dataPoints[8][2],
                2032: dataPoints[9][2],
                2033: dataPoints[10][2]
              },
              [paramName[3]]: {
                2024: dataPoints[1][3],
                2025: dataPoints[2][3],
                2026: dataPoints[3][3],
                2027: dataPoints[4][3],
                2028: dataPoints[5][3],
                2029: dataPoints[6][3],
                2030: dataPoints[7][3],
                2031: dataPoints[8][3],
                2032: dataPoints[9][3],
                2033: dataPoints[10][3]
              },
              [paramName[4]]: {
                2024: dataPoints[1][4],
                2025: dataPoints[2][4],
                2026: dataPoints[3][4],
                2027: dataPoints[4][4],
                2028: dataPoints[5][4],
                2029: dataPoints[6][4],
                2030: dataPoints[7][4],
                2031: dataPoints[8][4],
                2032: dataPoints[9][4],
                2033: dataPoints[10][4]
              },
              [paramName[5]]: {
                2024: dataPoints[1][5],
                2025: dataPoints[2][5],
                2026: dataPoints[3][5],
                2027: dataPoints[4][5],
                2028: dataPoints[5][5],
                2029: dataPoints[6][5],
                2030: dataPoints[7][5],
                2031: dataPoints[8][5],
                2032: dataPoints[9][5],
                2033: dataPoints[10][5]
              }
            }
          });
        } else {
          data.push(ele);
        }
      });
      setCompareScenarioGraphData(data);
    }
  }, [compareScenariosFinancials]);

  const getCompareGraph = (params: any) => {
    compareScenarioGraph(params).then((res: any) => {
      setCompareScenarioGraphData(res.data);
      setIsPowerLoading(false);
    }).catch((err: any) => {
      toast.error(err.message);
    });
  };

  const getIndexOf = (key: string, array: string[]) => array.indexOf(key);

  let mappedKeys: any = [];
  const getMergedData = (gData: any) => {
    const mergedData: any = [];
    const legendData: any = [];
    let arrayLength: number = NUMBER.N0;
    const uniqueSet = new Set(mappedKeys);
    const labelList = SCENARIO_TYPE_LABELS[selectedProjectType] as any;
    const keyList = SCENARIO_TYPE_KEYS[selectedProjectType];
    gData.map((scenerio: any) => {
      keyList.map(keyName => {
        getIndexOf(keyName, scenerio.columns) >= NUMBER.N0 && uniqueSet.add(keyName);
        if (arrayLength < scenerio.data.length) {
          arrayLength = scenerio.data.length;
        }
      });
    });
    mappedKeys = Array.from(uniqueSet);
    let colorIndex = NUMBER.N0;
    selectedScenarioList.map((scenerio: any, scenerioIndex: number) => {
      if (gData[scenerioIndex]) {
        mappedKeys.map((keyName: string, keyIndex: number) => {
          if (gData[scenerioIndex].data[NUMBER.N0][getIndexOf(keyName, gData[scenerioIndex].columns)] !== undefined) {
            legendData.push({
              label: labelList[keyName],
              color: GRAPH_COLORS_ARRAY[colorIndex],
              scenerioName: scenerio.label
            });
            colorIndex++;
          }
        });
      }
    });
    for (let dataIndex = NUMBER.N0; dataIndex < arrayLength; dataIndex++) {
      const dataObject: any = {};
      dataObject.TimeUTC = gData[NUMBER.N0].data[dataIndex][getIndexOf('TimeUTC', gData[NUMBER.N0].columns)];
      gData.map((scenerio: any, scenerioIndex: number) => {
        mappedKeys.map((keyName: string, keyIndex: number) => {
          const keyLabel = labelList[keyName];
          if (scenerio.data?.[dataIndex]?.[getIndexOf(keyName, gData?.[scenerioIndex]?.columns)] !== undefined) {
            dataObject[`${keyLabel} ${selectedScenarioList[scenerioIndex].label}`] =
              scenerio.data[dataIndex][getIndexOf(keyName, gData[scenerioIndex].columns)] || NUMBER.N0;
          }
        });
      });
      mergedData.push(dataObject);
    }
    setUsedKeys(legendData);
    return mergedData;
  };

  useEffect(() => {
    if (isSuccess) {
      setProjects(manipulateDropdownData(data));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (projectTypeSuccess) {
      setProjectTypes(manipulateProjectTypeData());
    }
  }, [projectTypeSuccess]);

  useEffect(() => {
    if (selectedProject || projects?.length <= NUMBER.N1) {
      getProjectAndSharedScenarios({ type: selectedProjectType, id: selectedProject }).then((res: any) => {
        if (res.data) {
          setScenarioList(manipulateSecondDropdownData(res.data));
        }
      }).catch((err: any) => {
        toast.error(err.message);
      });
    }
  }, [selectedProject, projects]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > NUMBER.N50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(null);
      }
    };

    if (compareDivRef.current) {
      compareDivRef.current.addEventListener('scroll', handleScroll);
    }
    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      if (compareDivRef.current) {
        compareDivRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const disableDropdownOption = (selectedScenario: any) => {
    const existingScenario = selectedScenarioList?.includes(selectedScenario, 0);
    return !existingScenario && selectedScenarioList.length > NUMBER.N9;
  };

  const renderCompareView = () => {
    if (!isPowerLoading) {
      if (selectedProjectType === EV && loadEvCompare) {
        return (
          <CompareEVScenerio
            selectedScenarioList={selectedScenarioList} comparedScenarios={comparedScenarios} />
        );
      } else if (loadPowerCompare && selectedProjectType !== GEO_THERMAL) {
        return (
          <CompareScenerio financialData={compareScenariosFinancials} selectedScenarioList={selectedScenarioList}
            subProjectType={selectedProjectType} aggregatedData={compareScenarioGraphData} clearAll={clearAllData} />
        );
      } else if (loadPowerCompare && selectedProjectType === GEO_THERMAL) {
        return (<CompareNewGeoScenario financialData={compareScenariosFinancials} selectedScenarioList={selectedScenarioList}
          subProjectType={selectedProjectType} aggregatedData={compareScenarioGraphData} clearAll={clearAllData} />
        );
      }
      // else if (loadPowerCompare && selectedProjectType === GEO_THERMAL) {
      //   return (<CompareGeothermalScenario financialData={compareScenariosFinancials} selectedScenarioList={selectedScenarioList}
      //     comparedScenarios={comparedScenarios} mappedKeys={usedKeys} subProjectType={selectedProjectType} clearAll={clearAllData} />
      //   );
      // }
    }
    return null;
  };

  return (<div className="main-login dashboard-main compare-modal" ref={compareDivRef}>
    <Header divScrolled={isScrolled} />
    <div className="container compare-modal-container">
      <div className="main-login-wrapper">
        <div className="dashboard-main-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-left">
                <div className="dashboard-header">
                  <h1>Compare Simulation Statistics</h1>
                  <p className='dashboard-header-des'>Search a project and add 2 or more scenarios to compare.</p>
                </div>
                <div className="multiselect-wrapper zindex-4">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="custom-select-main">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={projectTypes}
                          placeholder="Project type"
                          onChange={(event) => onProjectTypeHandler(event)}
                          value={projectTypeWithLabel}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="custom-select-main">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          isDisabled={false}
                          value={selectedProjectData}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={projects}
                          placeholder="Select a project"
                          onChange={(event) => onProjectSelectHandler(event)}
                          noOptionsMessage={() => customNoOptionsMessage('No projects found')}
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="multiselect-wrapper-inner checkbox-select">
                        <div className="custom-select-main">
                          <Select
                            className="custom-select-wrp"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={isFetching}
                            isClearable={false}
                            isRtl={isRtl}
                            onChange={(event) => onScenarioChangeHandler(event)}
                            value={selectedScenarioList}
                            isSearchable={isSearchable}
                            name="color"
                            options={scenarioList}
                            placeholder="Select atleast 2 scenarios to compare"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isOptionDisabled={(event) => disableDropdownOption(event)}
                            formatOptionLabel={({ label, createdOn, isShared }) => <>
                              <span className='checkbox-label-power'>{label}</span>
                              <span className='shared-type'>{isShared ? 'shared' : ''}</span>
                              <span className='label-text-des'>{createdOn}</span>
                            </>}
                          />
                        </div>
                        <Button className="no-radius" disabled={selectedScenarioList.length < NUMBER.N2} onClick={() => onCompareScenarioHandler()}>Compare</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <FullPageLoader isLoading={isPowerLoading} />
            {renderCompareView()}
          </div>
        </div>
      </div>
    </div>
  </div>

  );
};

export default CompareScenerioHOC;
