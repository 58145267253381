import { Formik, Form as FormikForm, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { Button, Form, Modal, FormCheck } from 'react-bootstrap';
import Select from 'react-select';
import InputElement from '../shared/InputElement';
import { calculateStandardDeviation, hasAtLeastOneCheckedItem, hasFieldError } from '../../utils';

interface InputVariable {
  value: string
  label: string
}

interface MonteCarloSettingField {
  label: string
  name: string
  value: {
    distribution: string
    param1: number
    param2: number
    param3?: number
  }
  min: number
  max: number
}

const inputVariables: InputVariable[] = [
  { value: 'uniform', label: 'Uniform' },
  { value: 'normal', label: 'Normal' },
  { value: 'triangular', label: 'Triangular' }
];

const generateInitialFormValues = (fields: MonteCarloSettingField[]): any => {
  return fields.reduce((acc: any, field: MonteCarloSettingField) => {
    acc[field.label] = {
      distribution: field.value.distribution || 'uniform',
      param1: field.min || 1,
      param2: field.max || 100,
      param3: field.value.param3 ?? 0,
      checked: false
    };
    return acc;
  }, {});
};

const MonteSettingForm: React.FC<{
  ShowInputVariables: boolean
  setShowInputVariables: React.Dispatch<React.SetStateAction<boolean>>
  monteCarloSettingField: any
  setSettingForm: React.Dispatch<React.SetStateAction<any>>
  settingForm: any
}> = ({ ShowInputVariables, setShowInputVariables, monteCarloSettingField, setSettingForm, settingForm }) => {
  const settingFormRef = useRef<FormikProps<any> | null>(null);
  const [isFormError, setIsFormError] = useState(true);
  const requiredFields = monteCarloSettingField.filter((data: any) => data.label !== 'Power Plant Type');

  const initialFormValues = Object.keys(settingForm).length ? settingForm : generateInitialFormValues(requiredFields);

  const handleFormSubmit = () => {
    const values = settingFormRef.current?.values;
    if (hasAtLeastOneCheckedItem(values)) {
      setIsFormError(true);
      setSettingForm(() => values);
      setShowInputVariables(false);
    } else {
      setIsFormError(false);
    }
  };

  const dummyRange = [1, 2, 3, 4, 5];

  return (
    <Modal
      show={ShowInputVariables}
      onHide={() => setShowInputVariables(false)}
      dialogClassName="modal-817 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal add-new-parameter input-variables-modal"
      className="forget-modal setting-modal"
    >
      <Modal.Header closeButton className="" onClick={() => setShowInputVariables(false)}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">Select Input Variables</h4>
        <div className="objet-layer-main add-object-icon-main">
          <div className="object-ul add-new-parameter-wrap">
            <Formik
              initialValues={initialFormValues}
              onSubmit={handleFormSubmit}
              innerRef={settingFormRef}
            >
              {({
                values,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
                setFieldError
              }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <div className="checkbox-wrapper checkbox-grey-wrap monte_form_group">
                    {requiredFields.map((field: MonteCarloSettingField) => (
                      <div className="monte-checkbox-wrap" key={field.label}>
                        <FormCheck
                          type="checkbox"
                          id={field.name}
                          label={field.label}
                          name={`${field.label}.checked`}
                          onChange={(e) => {
                            setFieldValue(`${field.label}.checked`, e.target.checked);
                            setIsFormError(() => true);
                          }}
                          checked={values[field.label].checked}
                        />
                        <div className={`checkbox-input-wrap ${!values[field.label].checked && 'disabled'}`}>
                          <div className="custom-select-main grey-border-select">
                            <Form.Label>Distribution Type</Form.Label>
                            <Select
                              className="custom-select-wrp"
                              classNamePrefix="select"
                              defaultValue={inputVariables.find((option) => option.value === values[field.label].distribution)}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`distribution_${field.label}`}
                              options={inputVariables}
                              onChange={async (option: any) => {
                                await setFieldValue(`${field.label}.distribution`, option.value);
                                if (option.value === 'normal') {
                                  const mean = parseFloat(values[field.label].param1?.toString() || field.min);
                                  setFieldValue(`${field.label}.param2`, calculateStandardDeviation(dummyRange, mean));
                                } else if (option.value === 'uniform') {
                                  setFieldValue(`${field.label}.param1`, field.min);
                                  setFieldValue(`${field.label}.param2`, field.max);
                                } else if (option.value === 'triangular') {
                                  setFieldValue(`${field.label}.param1`, field.min);
                                  setFieldValue(`${field.label}.param3`, Math.round((field.min + field.max) / 2));
                                  setFieldValue(`${field.label}.param2`, field.max);
                                }
                              }}
                            />
                          </div>
                          <Form.Group className="form-group" controlId={`param1_${field.label}`}>
                            <InputElement
                              label={values[field.label].distribution === 'triangular' ? 'Lower Limit' : values[field.label].distribution === 'uniform' ? 'Min' : 'Mean'}
                              placeholder={values[field.label].distribution === 'triangular' ? 'Enter Lower Limit' : 'Enter Mean/Min'}
                              required={true}
                              type="number"
                              maxLength={field.max}
                              minLength={field.min}
                              value={values[field.label].param1}
                              name={`${field.label}.param1`}
                              data-testid={`param1_${field.label}`}
                              onChange={(e: any) => {
                                let value = e.target.value;
                                if (value > field.max) {
                                  value = field.max;
                                } else if (value < field.min) {
                                  value = field.min;
                                }
                                handleChange(e);
                                setFieldValue(`${field.label}.param1`, value);
                                values[field.label].distribution === 'normal' && setFieldValue(`${field.label}.param2`, calculateStandardDeviation(dummyRange, e.target.value));
                              }}
                              resetError={setFieldError}
                              onBlur={handleBlur}
                              hasError={hasFieldError(errors, touched, `${field.label}.param1`)}
                            />
                          </Form.Group>
                          {values[field.label].distribution === 'triangular' && (
                            <Form.Group className="form-group" controlId={`param3_${field.label}`}>
                              <InputElement
                                label="Mode"
                                placeholder="Enter Mode"
                                required={true}
                                type="number"
                                maxLength={field.max - 1}
                                minLength={field.min + 1}
                                value={values[field.label].param3}
                                name={`${field.label}.param3`}
                                data-testid={`param3_${field.label}`}
                                onChange={(e: any) => {
                                  let value = e.target.value;
                                  if (value > field.max) {
                                    value = field.max - 1;
                                  } else if (value < field.min) {
                                    value = field.min + 1;
                                  }
                                  handleChange(e);
                                  setFieldValue(`${field.label}.param3`, value);
                                }}
                                resetError={setFieldError}
                                onBlur={handleBlur}
                                hasError={hasFieldError(errors, touched, `${field.label}.param3`)}
                              />
                            </Form.Group>
                          )}
                          <Form.Group className="form-group" controlId={`param2_${field.label}`}>
                            <InputElement
                              label={values[field.label].distribution === 'triangular' ? 'Upper Limit'
                                : values[field.label].distribution === 'uniform' ? 'Max'
                                  : 'Standard Deviation'}
                              placeholder={values[field.label].distribution === 'triangular' ? 'Enter Upper Limit' : 'Enter Standard Deviation/Max'}
                              required={true}
                              type="number"
                              maxLength={field.max}
                              minLength={field.min}
                              value={values[field.label].param2}
                              name={`${field.label}.param2`}
                              data-testid={`param2_${field.label}`}
                              onChange={(e: any) => {
                                let value = e.target.value;
                                if (value > field.max) {
                                  value = field.max;
                                } else if (value < field.min) {
                                  value = field.min;
                                }
                                handleChange(e);
                                setFieldValue(`${field.label}.param2`, value);
                              }}
                              resetError={setFieldError}
                              onBlur={handleBlur}
                              hasError={hasFieldError(errors, touched, `${field.label}.param2`)}
                              disabled={values[field.label].distribution === 'normal'}
                            />
                          </Form.Group>

                        </div>
                      </div>
                    ))}
                    {!isFormError && <span className="error-msg">At least one item has to be checked</span>}
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
        <Modal.Footer>
          <Button className="btn-no-outline" onClick={() => setShowInputVariables(false)}>
            Cancel
          </Button>
          <Button className="primary" type="submit" onClick={handleFormSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default MonteSettingForm;
