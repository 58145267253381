import { Button, Form, Spinner } from 'react-bootstrap';
import { commentSendArrow } from '../../utils';
import { Node } from 'reactflow';
import { useEditWorkbenchCommentsMutation, useRemoveWorkbenchCommentsMutation, useSaveCommentDataMutation } from '../../redux/services/workbenchApis';
import { MESSAGES, NUMBER, USER_PERMISSIONS } from '../../constants';
import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';
import CommentDropdownMenu from '../shared/CommenDropDownMenu';
import { toast } from 'react-toastify';

interface ICommentInputProps {
  top: string
  left: string
  right: string
  bottom: string
  id: string
  selectedNode: Node
  commentNodeData: Node
  setCommentUpdates: any
  commentUpdates: boolean
}

const CommentInput = ({ top, left, right, bottom, id, selectedNode, setCommentUpdates, commentUpdates, commentNodeData }: ICommentInputProps) => {
  const [saveCommentData, { isLoading }] = useSaveCommentDataMutation();
  const [deleteWorkbenchComment, { isLoading: deleteLoading }] = useRemoveWorkbenchCommentsMutation();
  const [editWorkbenchComment, { isLoading: editLoading }] = useEditWorkbenchCommentsMutation();
  const { BaselineStatus } = useAppSelector(state => state.scenarioDetails);
  const { permissions } = useAppSelector(state => state.auth);
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const [currentNode, setCurrentNode] = useState<any>(selectedNode);
  const currentUserEmail = useAppSelector(state => state.auth.email);
  const [editComment, setEditComment] = useState(false);
  const [activeId, setActiveId] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const hasCommentPermissionAndNotBaseline = permissions?.includes(USER_PERMISSIONS.add_comments) && !BaselineStatus;

  const getCommentsForSelectedNode = (selectedNode: Node, commentNodeData: any) => {
    const selectedNodeId = selectedNode.id;
    const selectedNodeData = commentNodeData.find((node: Node) => node.id === selectedNodeId);
    if (selectedNodeData) {
      setCurrentNode(selectedNodeData);
      return selectedNodeData.data.comments;
    } else {
      return [];
    }
  };

  useEffect(() => {
    getCommentsForSelectedNode(selectedNode, commentNodeData);
  }, [commentNodeData]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }, [currentNode]);

  const renderEditComment = (id: string) => {
    setEditComment(true);
    setActiveId(id);
  };

  // Delete a specific comment
  const deleteComment = (id: string) => {
    setActiveId(id);
    deleteWorkbenchComment(id).then((res) => {
      if ('data' in res) {
        setCommentUpdates(!commentUpdates);
        setEditComment(false);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
    });
  };

  const saveCommentDataPostCall = (values: { comment: string },
    { resetForm }: FormikHelpers<{ comment: string }>) => {
    const payload = {
      'commentText': values.comment,
      'componentName': selectedNode?.data?.class,
      'nodeID': id,
      'subProjectID': scenarioId
    };
    if (values.comment.trim() !== '') {
      saveCommentData(payload).then((res) => {
        resetForm();
        setCommentUpdates(!commentUpdates);
      });
    }
  };

  const ifNoComments = () => {
    return !currentNode?.data?.comments?.length || currentNode?.data?.comments?.length === NUMBER.N0;
  };

  // Edit a specific comment
  const editComments = (
    values: { comment: string }, id: any
  ) => {
    const payload = {
      commentText: values.comment,
      id
    };
    if (values.comment.trim() !== '') {
      editWorkbenchComment(payload).then((res) => {
        if ('data' in res) {
          setCommentUpdates(!commentUpdates);
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
        setEditComment(false);
      });
    }
  };

  const renderFormik = (text?: string, id?: string) => (
    !hasCommentPermissionAndNotBaseline ? <></>
      : <Formik
        initialValues={{ comment: text ?? '' }}
        onSubmit={text ? (e: any) => editComments(e, id) : saveCommentDataPostCall}
      >
        {({
          handleChange,
          handleBlur,
          values,
          handleSubmit
        }) => (
          <Form className="simulation-form" onSubmit={handleSubmit}>
            <Form.Group
              controlId="formBasicEmail"
              className={`form-group ${id ? 'edit-input' : ''}`}
            >
              <Form.Control
                type="text"
                name='comment'
                placeholder="Type here"
                autoComplete="false"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.comment}
                ref={inputRef}
              />
              {(isLoading || editLoading) ? <div className="comment-send-btn"><Spinner className="blue-spinner" /></div>
                : <Button type='submit' className="primary comment-send-btn" style={!values.comment ? { cursor: 'default' } : {}}>
                  <img src={commentSendArrow} alt="logo" />
                </Button>
              }
            </Form.Group>
          </Form>
        )}
      </Formik>
  );

  return (
    <div className={`hover-box-wrap ${ifNoComments() && 'default-text-comment-wrap'} ${ifNoComments() && !hasCommentPermissionAndNotBaseline ? 'padding-0' : ''} comment-box-wrap`}
      style={{ top, left, right, bottom }}>
      <div className="hover-box">
        {
          currentNode?.data?.comments?.map((entity: any) => {
            const randomId = `${entity.id}${Math.random()}`;
            const timeString = moment(entity?.registerDate).utc(true).fromNow();
            return (
              <div key={randomId}>
                <div className="dropdown-wrapper shared-profile profile-icon-wrap">
                  <span className="profile-icon medium orange">{entity.registerUser.charAt(0)}</span>
                  <div className='profile-comment-wrap'>
                    <span className='comment-user-name'>{entity?.registerUser}</span>
                    <span className='comment-date fix-dublicate'>{timeString}
                      {((deleteLoading) && activeId === entity.id) && <Spinner className="blue-spinner" />}
                    </span>
                  </div>
                  <div className='card-dropdown comment-dropdown-wrap fix-dublicate'>
                    {entity.registerUser === currentUserEmail && hasCommentPermissionAndNotBaseline &&
                      <CommentDropdownMenu
                        entity={entity}
                        renderEditComment={renderEditComment}
                        deleteComment={deleteComment}
                      />}
                  </div>

                </div>
                {!editComment && <p className="comment-des fix-dublicate">{entity.commentText}</p>}
                {editComment && activeId !== entity.id && <p className="comment-des">{entity.commentText}</p>}
                {editComment && activeId === entity.id && renderFormik(entity.commentText, entity.id)}
              </div>
            );
          })
        }
        {ifNoComments() && hasCommentPermissionAndNotBaseline && <div className="dropdown-wrapper shared-profile profile-icon-wrap default-text-comment">
          <div className='profile-comment-wrap'>
            <span className="profile-icon medium orange">{currentUserEmail.charAt(0)}</span>
            <span className='comment-user-name'>Add a comment</span>
          </div>
        </div>}
        {!editComment && renderFormik()}
      </div>
    </div>
  );
};
export default CommentInput;
