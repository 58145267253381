import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
interface ISignUp {
  company_name: string
  company_description: string
  company_logo: string
  email: string
  password: string
}
function CustomSelect() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    { value: 'options', label: 'Options', isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: 'green hydrogen', label: 'Green Hydrogen' },
    { value: 'power', label: 'Power' }
  ];
  return (

    <div className="custom-select-main grey-border-select">
      <Form.Label>Type of Analysis*</Form.Label>
      <Select
        className="custom-select-wrp"
        classNamePrefix="select"
        defaultValue={colourOptions[3]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={colourOptions}
      // menuIsOpen={true}
      />
    </div>
  );
}

export default CustomSelect;
