import React, { useEffect, useState } from 'react';
import { backArrow } from '../../utils/icons';
import { SELECT_SCENARIO_TYPE, getLocalStorageItem, handleFontSize, toTitleCase, ALL_PROJECT } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { IScenarioDetails, MESSAGES, NUMBER, USER_PERMISSIONS } from '../../constants';
import ScenarioListCard from '../../components/dashboard/ScenarioListCard';
import SearchAndFilter from '../../components/shared/SearchAndFilter';
import NoData from '../../components/shared/NoData';
import { LABELS } from '../../constants/labelConstant';
import { useGetScenarioQuery } from '../../redux/services/scenarioApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';

const AllScenarioList = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const [searchTerm, setSearchTerm] = useState('');
  const projectDetails = getLocalStorageItem('selectedProject');
  const projectId = searchParams.get('projectId') ?? '';
  const { data, isSuccess, isLoading, refetch } = useGetScenarioQuery(projectDetails?._id || projectId, { refetchOnMountOrArgChange: true });
  const [scenarios, setScenarios] = useState<IScenarioDetails[] | undefined>(data);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.auth);
  const canCreateScenarios = userData.permissions.includes(USER_PERMISSIONS.create_scenarios);

  useEffect(() => {
    dispatch(startFullLoading());
    if (isSuccess) {
      setScenarios(data);
      dispatch(stopFullLoading());
    }
  }, [isSuccess, data]);

  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };

  const filteredData = scenarios?.filter((item: IScenarioDetails) =>
    item.name.toLowerCase()?.includes(searchTerm)
  );

  const breakLength = [NUMBER.N50, NUMBER.N45, NUMBER.N40, NUMBER.N35, NUMBER.N30];
  const sizes = [NUMBER.N18, NUMBER.N24, NUMBER.N25, NUMBER.N30, NUMBER.N33];

  return (
    <div className="all-models-section all-project-section single-project">
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className={`section-title
                  ${handleFontSize(projectDetails?.name, breakLength, sizes)}`}>
                <button className='close-btn' onClick={() => navigate(ALL_PROJECT)}>
                  <span className="back-arrow">
                    <img src={backArrow} alt="" />
                  </span>
                </button>
                {toTitleCase(projectDetails.name)}
              </h1>
              <p className="section-title-des">
                Find all scenarios in this project.
              </p>
              <SearchAndFilter
                placeHolder={LABELS.SCENARIO_SEARCH_PLACEHOLDER}
                onSearch={handleSearch}
                className="scenario-list-search"
              />
              <div className="single-project-wrapper">
                <span className="tab-sec-thead">All scenarios ({scenarios?.length})</span>
                {canCreateScenarios && <Link to={SELECT_SCENARIO_TYPE}
                  className="create-scenario">
                  <span className="create-plus-icon"></span>
                  Create a Scenario
                </Link>}
                <div className="tab-main-container">
                  {
                    filteredData?.filter((item: IScenarioDetails) =>
                      item.name.toLowerCase()?.includes(searchTerm)
                    ).map((scenario: IScenarioDetails) => {
                      return (
                        <ScenarioListCard
                          key={scenario._id}
                          data={scenario}
                          projectDetails={projectDetails}
                          refreshData={refetch}
                        />
                      );
                    })
                  }
                  {
                    (!filteredData?.length && searchTerm) ? <NoData message={MESSAGES.NO_RECORD_FOUND} /> : ''
                  }
                  {
                    (!searchTerm && !filteredData?.length && !isLoading) && (
                      < NoData
                        message={MESSAGES.NO_SCENARIO_FOUND}
                        image=""
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllScenarioList;
