import React from 'react';
import { WorkbenchEmptyImg } from '../../utils';

function EmptyWorkbench() {
  return (
    <div className="workbench-board-empty">
      <img
        src={WorkbenchEmptyImg}
        alt="logo image"
        className="workbench-empty-img"
      />
      <div className="workbench-empty-wrapper">
        <h2 className="workbench-empty-head">
          Welcome to your Dsider Workbench
        </h2>
        <p className="workbench-empty-des">
        Pick an object from the component library and drag and
        drop it on this board to create your simulation scenario.

        </p>
      </div>
    </div>
  );
}

export default EmptyWorkbench;
