/* eslint-disable react/react-in-jsx-scope */

import { Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import SearchAndFilter from '../../components/shared/SearchAndFilter';
import { adminDsiderLogo, electrolysisLogo, fileInputImg, genericLogo, infoInputLogo, locationLogo, powerBetteryDarkLogo, powerSmallLogo, projectBox1, threeDotHorizontal, turbineSmallLogo, versionLogo, waterTank, windmillDarkLogo } from '../../utils';

import { useState } from 'react';
import Select from 'react-select';
import DsiderPagination from './DsiderPagination';
import { Link } from 'react-router-dom';




const ObjectManagement = () => {
  const handleSearch = (val: string) => {
  };
  const [ShowAddObject, setShowAddObject] = useState(false);
  const [ShowObjectIcon, setShowObjectIcon] = useState(false);
  const [ShowAddParameter, setShowAddParameter] = useState(false);


  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const capitalCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "kwh", label: "KWH" },
    { value: "mwh", label: "MWH" },
    { value: "kt", label: "KT" },
    { value: "mt", label: "MT" },
  ];
  const periodCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const priceSold = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const transCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const variableCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];

  const projectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'All', label: 'All' },
    { value: 'Hydrogen', label: 'Hydrogen' },
    { value: 'Power Turbine', label: 'Power Turbine' },
    { value: 'Solar Turbine', label: 'Solar Turbine' }
  ];
  const objectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Reactor', label: 'Reactor' },
    { value: 'Power Turbine', label: 'Power Turbine' },
    { value: 'Solar Turbine', label: 'Solar Turbine' }
  ];

  return (
    <>
      <div className='admin-sidebar'>
        <div className='dsider-logo-wrap'><img src={adminDsiderLogo} alt="logo img" /></div>
        <Nav variant="tabs" className='admin-sidebar-wrap' defaultActiveKey="/home">
          <Nav.Item>
            <Nav.Link href="/usermanagement">User Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/companymanagement">Company Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/home">Object Management</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
      <div className='admin-section'>
        <div className='admin-body'>
          <div className='admin-body-header'>
            <div className='admin-header-wrap'>
              <h2 className='admin-section-title'>All Objects (12)</h2>
              <div className='admim-profile-wrap'>
                <Dropdown className="profile-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="user-icon">
                      r
                      {/* <img className="user-img" src={userIcon} alt="user" /> */}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-wrapper">
                      <span className="user-icon">A</span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">Andy Smith</h3>
                        <span className="dropdown-email">
                          andysmith038@email.com
                        </span>
                        <div className="dropdown-footer">
                          <Button className="btn-outline small">
                            Edit Profile
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="account-setting">
                      <Link to="#" className="account-item">
                        Switch to Dsider Dashboard
                      </Link>
                      <Link to="#" className="account-item">
                        Change Password
                      </Link>
                      <Link to="#" className="account-item sign-out">
                        Sign Out
                      </Link>
                    </div>
                    <div className="version-sec">
                      <img src={versionLogo} alt="logo" />
                      <span className="version-text">Version v2.0.3</span>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='add-user-wrap'>
              <SearchAndFilter
                placeHolder="Search by name or email"
                onSearch={handleSearch}
                className="scenario-list-search"
              />
              <Button onClick={() => setShowAddObject(true)}>+ New Object</Button>
            </div>
          </div>
          <div className="col-md-12">
            {/* table */}
            <div className='table-wrapper'>
              <div className="table-container contest-table admin-table-container company-management-table object-management-table">
                <div className='table-inner-wrap'>
                  <div className="table-head">
                    <div className="table-row">
                      <div className="th">Name</div>
                      <div className="th">category</div>
                      <div className="th">created on </div>
                      <div className="th">action</div>
                    </div>
                  </div>
                  <div className="table-body">
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={turbineSmallLogo}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon object-user-icon">
                              <img
                                src={projectBox1}
                                alt="logo"
                                className="user-img"
                              />

                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Turbine
                              </h3>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Power Object, Green Hydrogen +2"><span className='td-text category-name'>Power Object, Green Hydrogen +2</span></div>
                      <div className="td" data-label="June 5, 2023 "><span className='td-text user-count'>June 5, 2023 </span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <DsiderPagination />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Add new Object modal start here */}
      <Modal
        show={ShowAddObject}
        onHide={() => setShowAddObject(false)}
        dialogClassName="modal-546 top-right-modal input-output-modal object-layers-modal add-object-modal"
        className="forget-modal setting-modal "
      >
        <Modal.Header closeButton className="" onClick={() => setShowAddObject(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New Object</h4>
          <div className="create-modal-left">
            <Form.Group
              controlId="formFile"
              className="form-group file-input error-field mb-0"
            >
              <div className="file-input-wrapper">
                <div className="file-upload-img">
                  {/* <img
                    src={compnayDummyLogo}
                    alt="img upload"
                    className="file-main-img upload-main-img"
                  /> */}
                  <img
                    src={fileInputImg}
                    alt="img main"
                    className="file-main-img empty-img"
                  />
                  {/* <img
                    src={crossRedLogo}
                    alt="img cross logo"
                    className="cross-logo"
                  /> */}
                  <span className="error-msg d-done">
                    Company logo is required.
                  </span>
                </div>
                <div className="file-input-text-wrapper" onClick={() => setShowObjectIcon(true)}>
                  <div className="file-attachment-wrapper">
                    <span className="file-input-attachment">
                      + Add Object Icon<sup>*</sup>
                    </span>
                  </div>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className='add-object-form-wrap'>
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className='multiselect-wrapper mb-25'>
                    <div className="multiselect-wrapper-inner checkbox-select grey-border-select">
                      <div className="custom-select-main">
                        <Form.Label>Project Type<sup>*</sup></Form.Label>
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={projectType}
                          placeholder=""
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          formatOptionLabel={({ label }) =>
                            <span className='checkbox-label-power'>{label}</span>
                          }
                        // menuIsOpen={true}

                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main grey-border-select">
                    <Form.Label>Object Type<sup>*</sup></Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      defaultValue={objectType[0]}
                      // isDisabled={isDisabled}
                      isLoading={isLoading}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={objectType}
                      placeholder="User Role"
                    // menuIsOpen={true}
                    />

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main grey-border-select">
                    <Form.Label>Select Reference Object</Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      defaultValue={objectType[0]}
                      // isDisabled={isDisabled}
                      isLoading={isLoading}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={objectType}
                      placeholder="User Role"
                    />

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main grey-border-select">
                    <Form.Label>Object Name<sup>*</sup></Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      defaultValue={objectType[0]}
                      // isDisabled={isDisabled}
                      isLoading={isLoading}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={objectType}
                      placeholder="User Role"
                    // menuIsOpen={true}
                    />

                  </div>
                </div>
                <div className='add-parameter-main'>
                  <h4 className='form-group-subhead'>ADD PARAMETERS</h4>
                  <Form.Group className="form-group location-form-group" controlId="latitude">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="number"
                      // value={locationLat}
                      autoComplete='off'
                    />
                    <span className="input-logo location-img"><img src={locationLogo} alt="logo img" className='location-img' /></span>
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Capital Cost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={capitalCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={capitalCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Load Factor</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Period Cost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={periodCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={periodCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Power Capacity</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                    <span className="input-logo location-img">
                      <img src={infoInputLogo} alt="logo img" />
                    </span>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Price of excess sold</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={priceSold[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={priceSold}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>PwrRETransVCost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={transCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={transCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Take of Percent (%)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Transmission Loss Factor (%)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Variable cost of energy</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={variableCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={variableCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>

                </div>
                <div className="col-md-12">
                  <Button className='btn-no-outline new-parameter-btn' onClick={() => setShowAddParameter(true)}>+add new parameter</Button>
                </div>

              </div>

              <Modal.Footer>
                <Button className="btn-no-outline" onClick={() => setShowAddObject(false)}>
                  Cancel
                </Button>
                <Button className="primary">Save Object</Button>
              </Modal.Footer>

            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add Object Icon modal start here */}
      <Modal
        show={ShowObjectIcon}
        onHide={() => setShowObjectIcon(false)}
        dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowObjectIcon(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add Object Icon</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={genericLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Generic
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={waterTank}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          External Gray Supply
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add New Parameter modal start here */}
      <Modal
        show={ShowAddParameter}
        onHide={() => setShowAddParameter(false)}
        dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal add-new-parameter"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowAddParameter(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New Parameter</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul add-new-parameter-wrap'>
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="form-group location-form-group" controlId="latitude">
                      <Form.Label>Property Name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="form-group location-form-group" controlId="latitude">
                      <Form.Label>Units</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="form-group location-form-group" controlId="latitude">
                      <Form.Label>Default Value</Form.Label>
                      <Form.Control
                        type="number"
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                </div>

                <Modal.Footer>
                  <Button className="btn-no-outline" onClick={() => setShowAddParameter(false)}>
                    Cancel
                  </Button>
                  <Button className="primary">Save</Button>
                </Modal.Footer>

              </Form>

            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default ObjectManagement;
