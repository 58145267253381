import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { projectFolder } from '../../utils/icons';
import { useNavigate } from 'react-router-dom';
import { NUMBER } from '../../constants/numbers';
import { useGetProjectDetailsQuery, useGetProjectTypesQuery, useSaveProjectMutation, useUpdateProjectMutation } from '../../redux/services/projectApis';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import InputElement from '../../components/shared/InputElement';
import { IProject, MAX_LENGTH, MESSAGES, PROJECT_TYPE, CREATE_PROJECT_TITLES } from '../../constants';
import { ALL_PROJECT, CREATE_PROJECT_SCHEMA, getProjectTypeLogo, hasFieldError } from '../../utils';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import { toast } from 'react-toastify';
import { LABELS } from '../../constants/labelConstant';

const CreateProject = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const projectId = searchParams.get('project_id') ?? '';
  const { data: projectDetails, isSuccess } = useGetProjectDetailsQuery(projectId, { skip: !projectId });
  const [showAlert, setShowAlert] = useState(false);
  const initialValues: IProject = {
    name: '',
    description: '',
    project_type: ''
  };
  const [projectData, setProjectData] = useState<IProject>(initialValues);
  const [title, setTitle] = useState(LABELS.discardAlertMessage('Project'));
  const [message, setMessage] = useState(MESSAGES.PROJECT_DISCARD_MESSAGE);
  const [cancelText, setCancelText] = useState(LABELS.KEEP_CREATING);
  const { data, isLoading: GetProjectTypesLoading, isSuccess: projectTypeSuccess } = useGetProjectTypesQuery();
  const [saveProject, { isLoading: saveProjectLoading }] = useSaveProjectMutation();
  const [updateProject, { isLoading: updateProjectLoading }] = useUpdateProjectMutation();
  const formikRef = useRef<FormikProps<IProject> | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setProjectData(projectDetails);
      formikRef.current?.setValues({
        name: projectDetails.name,
        description: projectDetails.description,
        project_type: projectDetails.project_type
      });
      setTitle(LABELS.DISCARD_UPDATES);
      setMessage(MESSAGES.EDIT_PROJECT_DISCARD_MESSAGE);
      setCancelText(LABELS.CANCEL);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data?.project_types.length === NUMBER.N1) {
      formikRef.current?.setFieldValue('project_type', data?.project_types[NUMBER.N0]);
    }
  }, [projectTypeSuccess]);

  const handleFormSubmit = (values: IProject) => {
    values.name = values.name.toLowerCase().trim();
    values.createDate = new Date().toISOString();
    if (projectId) {
      updateProject({ name: values.name, description: values.description, project_id: projectId })
        .then((res: any) => {
          handleResponse(res, MESSAGES.PROJECT_UPDATED);
        });
    } else {
      saveProject(values)
        .then((res: any) => {
          handleResponse(res, MESSAGES.PROJECT_CREATED);
        });
    }
  };

  const handleResponse = (res: any, message: string) => {
    if ('data' in res) {
      toast.success(message);
      navigate(ALL_PROJECT);
    }
  };

  const setProjectType = (setFieldValue: any, type: string) => {
    if (!projectId) {
      setFieldValue('project_type', type);
    }
  };

  const showDiscardAlert = () => {
    if (formikRef.current) {
      const formValues = formikRef.current.values;
      // show alert if user enters any one of them...
      if (isShowDiscard(formValues)) {
        setShowAlert(true);
      } else {
        navigate(-NUMBER.N1);
      }
    }
  };

  const isShowDiscard = (formValues: IProject) => {
    return (
      (!projectId && (formValues.description || formValues.name)) ||
      (projectId && ((projectDetails?.description !== formValues.description) || (projectDetails?.name !== formValues.name)))
    );
  };


  return (
    <>
      <div role="dialog" aria-modal="true" className="fade change-pw-modal create-modal show">
        <button className="close-btn" onClick={showDiscardAlert}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="modal-dialog full-width-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-title h4">{projectId ? CREATE_PROJECT_TITLES.EDIT_DETAILS : CREATE_PROJECT_TITLES.CREATE_PROJECT}</div>
              <p className="modal-title-des">
                {projectId ? CREATE_PROJECT_TITLES.EDIT_MESSAGE : CREATE_PROJECT_TITLES.CREATE_MESSAGE}
              </p>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className="create-project-inner">
                    <div className="create-modal-left create-project-left">
                      <div className="modal-left-img project-folder-image">
                        <img src={projectFolder} alt="modal" />
                      </div>
                    </div>
                    <div className="create-modal-right">
                      <Formik
                        innerRef={formikRef}
                        initialValues={projectData}
                        validationSchema={CREATE_PROJECT_SCHEMA}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={handleFormSubmit}
                      >
                        {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, status, ...rest }) => (
                          <Form className="full-width-form" onSubmit={handleSubmit} >
                            {/* Project Name */}
                            <InputElement
                              label="Name"
                              maxLength={MAX_LENGTH.CHAR_LENGTH_50}
                              value={values?.name}
                              type="text"
                              name="name"
                              required={true}
                              className='fix-dublicacy'
                              data-testid="name"
                              onBlur={handleBlur}
                              resetError={setFieldError}
                              onChange={handleChange}
                              hasError={hasFieldError(errors, touched, 'name')}
                            />
                            {/* Project description */}
                            <InputElement
                              label="Description"
                              required={true}
                              type="textarea"
                              value={values?.description}
                              rows={5}
                              maxLength={MAX_LENGTH.company_desc}
                              name="description"
                              onChange={handleChange}
                              className='fix-dublicacy'
                              data-testid="description"
                              resetError={setFieldError}
                              onBlur={handleBlur}
                              hasError={hasFieldError(errors, touched, 'description')}
                            />
                            <div className="select-mode-wrapper">
                              <Form.Label>
                                Select Scenario Type<sup>*</sup>
                              </Form.Label>
                              <div className={`select-mode ${errors.project_type ? 'error-field' : ''}`}>
                                {data?.project_types?.map(
                                  (type: string, index: number) => {
                                    const pType = type.toLocaleLowerCase();
                                    return (
                                      <div className={`select-mode-box ${values.project_type.toLocaleLowerCase() === pType ? 'checked' : ''} ${projectId && 'disabled-div'}`}
                                        key={`id-${type}`} onClick={() => setProjectType(setFieldValue, type)}
                                      >
                                        <img src={getProjectTypeLogo(pType)} alt="Hydrogen logo" />
                                        <span className="select-mode-des">
                                          {PROJECT_TYPE[type]}
                                        </span>
                                        {values.project_type === type && <span className="icon-success-tick d-none"></span>}
                                      </div>
                                    );
                                  }
                                )}
                                {GetProjectTypesLoading && (
                                  <div className="select-mode-box"> <Spinner className="blue-spinner" /> </div>
                                )}
                                {errors.project_type && (
                                  <ErrorMessage name="project_type" component="span" className="error-msg" />
                                )}
                              </div>
                            </div>
                            <div className="sign-btn-wrapper">
                              <Button variant="primary" className="shadow" type="submit" >
                                {(saveProjectLoading || updateProjectLoading) ? (
                                  <Spinner />
                                ) : (
                                  projectId ? LABELS.UPDATE : LABELS.SAVE_AND_PROCEED
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={title}
        message={message}
        yesBtnText={LABELS.YES_DISCARD}
        cancleBtnText={cancelText}
        onConfirm={() => navigate(-NUMBER.N1)}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

export default CreateProject;
