import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar
} from "react-bootstrap";
import {
  carbonTaxLogo,
  electricityGraphImg,
  headerLogo,
  irrLogo,
  locationSmallBlue,
  locationSmallGreen,
  nvpLogo,
  searchSmallLogo,
  simulateSummaryIcon,
  taxCreditLogo
} from "../../utils/icons";


const EVDashboard = () => {
  const [show, setShow] = useState(false);
  const [shareModal, setShareModal] = useState(false);


  return (
    <>
      {/* Please add light-bg-theme this class in below div for light backgound */}
      <div className="workbench-dashboard-main">
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="bg-body-tertiary workbench-header"
          >
            <Container>
              <Navbar.Brand href="#Operational">
                <img src={headerLogo} alt="header logo" className="header-logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="nav-dropdown-wrapper">
                  <div className="user-circle plus-img">
                    <span className="user-icon">A</span>
                    <span className="user-icon">H</span>
                    <span className="user-icon">B</span>
                    <span className="user-icon">C</span>
                    <span className="user-icon five-plus">+1</span>
                  </div>
                  <div className="tooltip-container">
                    <Button
                      className="setting-btn btn-no-outline"
                      onClick={() => setShow(true)}
                    >
                      <div className="circle-logo-wrapper">
                        <span className="svg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M7.4997 5.1953C6.2269 5.1953 5.1951 6.2271 5.1951 7.5C5.1951 8.7728 6.2269 9.8047 7.4997 9.8047C8.7726 9.8047 9.8044 8.7728 9.8044 7.5C9.8044 6.2271 8.7726 5.1953 7.4997 5.1953ZM13.9686 5.8554L13.3446 6.3691C12.6328 6.955 12.6328 8.045 13.3446 8.6309L13.9686 9.1446C14.1897 9.3267 14.2468 9.6419 14.1036 9.89L12.8714 12.0241C12.7282 12.2721 12.4267 12.3803 12.1585 12.2798L11.4016 11.9963C10.5382 11.6728 9.5943 12.2178 9.4427 13.1272L9.3099 13.9245C9.2628 14.207 9.0183 14.4141 8.7319 14.4141H6.2676C5.9812 14.4141 5.7367 14.207 5.6896 13.9245L5.5568 13.1272C5.4052 12.2178 4.4613 11.6728 3.5979 11.9963L2.8411 12.2798C2.5728 12.3803 2.27128 12.2721 2.12805 12.0241L0.895909 9.89C0.752709 9.6419 0.809809 9.3267 1.03094 9.1446L1.65496 8.6309C2.36673 8.045 2.36673 6.955 1.65496 6.3691L1.03094 5.8554C0.809809 5.6733 0.752709 5.3581 0.895909 5.11L2.12805 2.9759C2.27128 2.7278 2.5728 2.6197 2.8411 2.7202L3.5979 3.0037C4.4613 3.3271 5.4052 2.7822 5.5568 1.87277L5.6896 1.07552C5.7367 0.793007 5.9812 0.585938 6.2676 0.585938H8.7319C9.0183 0.585938 9.2628 0.793007 9.3099 1.07552L9.4427 1.87277C9.5943 2.7822 10.5382 3.3271 11.4016 3.0037L12.1585 2.7202C12.4267 2.6197 12.7282 2.7278 12.8714 2.9759L14.1036 5.11C14.2468 5.3581 14.1897 5.6733 13.9686 5.8554Z"
                              stroke="#5A5B5E"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </Button>
                    <div className="tooltip-text">Settings</div>
                  </div>
                  {/* <NavDropdown
                    title="Share"
                    id="collapsible-nav-dropdown"
                    className="request-dropdown"
                    onClick={() => setShareModal(true)}
                  >
                    <span>1</span>
                  </NavDropdown> */}
                  <button className="close-btn">
                    <span className="icon-close-grey-icon"></span>
                  </button>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <div className="workbench-dashboard-inner">
          <div className="dashboard-main-wrapper">
            <div className='ev-graph-header'>
              <div className='ev-graph-left'>
                <span className='ev-graph-des'>EV Charging Station Simulation</span>
                <h2 className='ev-graph-head'>BP Pulse Stations</h2>
              </div>
              <div className='ev-graph-right'>
                <Dropdown className="create-modal-dropdown ev-scenario-dropdown summary-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={simulateSummaryIcon} alt="logo" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h2 className='summary-dropdown-title'>Simulation Summary</h2>
                    <p className='summary-dropdown-des'>Total EV count for the Zip Code 60608 for the current year 2023 is 5,000 and the projected EV growth in the next 26 years is +250%. The Demand Totals on an Avg Weekday is 46.9 KWH, Weekend is 63.7 KWH and on an Avg Week is 72.5 KWH. Visit to any EV Station on an Avg Weekday is 40, Weekend is 73 and on an Avg Week is 54.
The POI count in the 5.5 miles radius of the selected Zip code is 713. There are about 750 households in the selected area with the population of 3,950 people.</p>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="create-modal-dropdown ev-scenario-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    Save this Scenario
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.ItemText>Options</Dropdown.ItemText>
                    <Dropdown.Item as="button">Save Scenario</Dropdown.Item>
                    <Dropdown.Item as="button">Save as Duplicate</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>

            </div>
            {/* EV Map Graph */}
            <div className="simulation-chart-wrapper ev-simulation-chart-wrap">
              <div className="chart-left">
              </div>
              <div className="chart-right">
                <div className="chart-right-inner">
                  <div className="slider-wrap">
                    <div className="simulation-form-wrap">
                    <Form className="simulation-form">
                      <div className='simulation-inner-wrap'>
                        <div className="slider-inner-sec divider-border">
                          <span className='graph-right-head'>Location</span>
                          <div className='zip-code-wrap'>
                            <span className='zip-code-text'>Zip Code:</span>
                            <span className='zip-code-value'>60608</span>
                          </div>
                          <div className='slider-wrap-inner'>
                            <span className='slider-radius'>Radius (0.5m)</span>
                            <div className="star-progress-bar">
                              <Slider
                                min={0}
                                max={100}
                                defaultValue={0}
                                step={10}
                              />
                            </div>
                          </div>
                          <div className="simulate-btn-wrap search-btn-wrap">
                            <Button className="primary">
                              <img src={searchSmallLogo} alt="logo" className='search-small-logo' />
                              Search
                              </Button>
                          </div>
                        </div>
                        <div className="slider-inner-sec">
                          <span className='graph-right-head'>EV Charging Stations</span>
                          <div className='current-stations'>
                            <img src={locationSmallBlue} alt="logo" className='location-logo' />
                            <span className='location-logo-des'>Current Stations</span>
                          </div>
                          <div className='current-stations proposed-station'>
                            <img src={locationSmallGreen} alt="logo" className='location-logo' />
                            <span className='location-logo-des'>Proposed Station</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>1,947</h3>
                            <span className='ev-station-des'>Vehicles</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>23</h3>
                            <span className='ev-station-des'>Proposed Locations</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>109</h3>
                            <span className='ev-station-des'>Current EV Charging Locations</span>
                          </div>

                        </div>

                        {/* <div className="simulation-inner">
                          <p className="slider-head">Battery Capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Battery</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">ELECTROLYSER Capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Electrolyser</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">Green H2 Storage capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Green H2</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">DEMAND</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="star-progress-bar">
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={0}
                            step={10}
                            // value={scores[item.scoreParameter]}
                            // onChange={(v: number | number[]) => handleRating(item.scoreParameter, v)}
                          />
                        </div> */}



                      </div>
                      <div className="simulate-btn-wrap ev-charging-date-wrap">
                        <span className='ev-charging-date'>Last updated 3d ago</span>
                      </div>
                    </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* EV Simulation Chart  */}
            <div className="simulation-chart-wrapper ev-simulation-chart-wrap">
              <div className="chart-left">
                <h2 className="chart-title">Simulation Overview (01 Jan 2023 - 31 Dec 2050)</h2>
                <div className="chart-radio-wrapper">
                <Form>
                      <div className="checkbox-wrapper">
                        <Form.Check
                          type="radio"
                          id="default-checkbox"
                          label="Power"
                          name="simulationOverview"
                        />
                        <Form.Check
                          type="radio"
                          id="default-checkbox1"
                          label="Total Hydrogen"
                          name="simulationOverview"
                        />
                        <Form.Check
                          type="radio"
                          id="default-checkbox2"
                          label="Hydrogen Breakdown"
                          name="simulationOverview"
                        />
                        <Form.Check
                          type="radio"
                          id="default-checkbox3"
                          label="Over Under Chart"
                          name="simulationOverview"
                        />

                      </div>
                    </Form>

                </div>
              </div>
              <div className="chart-right">
                <div className="chart-right-inner">
                  <div className="slider-wrap">
                    <div className="simulation-form-wrap">
                    <Form className="simulation-form">
                      <div className='simulation-inner-wrap'>
                        <div className="slider-inner-sec divider-border">
                          <span className='graph-right-head'>Simulation Variables</span>
                          <div className='zip-code-wrap'>
                            <span className='zip-code-text'>Zip Code:</span>
                            <span className='zip-code-value'>60608</span>
                          </div>
                          <div className='slider-wrap-inner simulation-variables-wrap'>
                            <span className='form-label'>EV Growth</span>
                            <div className='slider-progress-value-wrap'>
                              <div className="star-progress-bar">
                                <Slider
                                  min={0}
                                  max={100}
                                  defaultValue={0}
                                  step={10}
                                />
                              </div>
                              <span className='slider-progress-value'>50%</span>
                            </div>
                          </div>
                          <div className="simulate-btn-wrap search-btn-wrap">
                            <Button className="primary">
                              <img src={searchSmallLogo} alt="logo" className='search-small-logo' />
                              Search
                              </Button>
                          </div>
                        </div>
                        <div className="slider-inner-sec">
                          <span className='graph-right-head'>EV Charging Stations</span>
                          <div className='current-stations'>
                            <img src={locationSmallBlue} alt="logo" className='location-logo' />
                            <span className='location-logo-des'>Current Stations</span>
                          </div>
                          <div className='current-stations proposed-station'>
                            <img src={locationSmallGreen} alt="logo" className='location-logo' />
                            <span className='location-logo-des'>Proposed Station</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>1,947</h3>
                            <span className='ev-station-des'>Vehicles</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>23</h3>
                            <span className='ev-station-des'>Proposed Locations</span>
                          </div>
                          <div className="ev-station-status">
                            <h3 className='ev-station-value'>109</h3>
                            <span className='ev-station-des'>Current EV Charging Locations</span>
                          </div>

                        </div>

                        {/* <div className="simulation-inner">
                          <p className="slider-head">Battery Capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Battery</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">ELECTROLYSER Capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Electrolyser</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">Green H2 Storage capacity</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Green H2</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="simulation-inner">
                          <p className="slider-head">DEMAND</p>
                          <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                          >
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                          </Form.Group>
                        </div>
                        <div className="star-progress-bar">
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={0}
                            step={10}
                            // value={scores[item.scoreParameter]}
                            // onChange={(v: number | number[]) => handleRating(item.scoreParameter, v)}
                          />
                        </div> */}



                      </div>
                      <div className="simulate-btn-wrap ev-charging-date-wrap">
                        <span className='ev-charging-date'>Last updated 3d ago</span>
                      </div>
                    </Form>
                    </div>
                </div>
                </div>
              </div>
            </div>

            {/* Cost Chart  */}
            <div className="simulation-chart-wrapper mitigation-chart">
              <div className="mitigation-wrap">
                <div className="co2-wrap mitigation-double-col">
                  <h4 className="section-head">Carbon Mitigation (MMT)</h4>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Re Power</span>
                    <p className="mitigation-value">12.07</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Low Carbon H2</span>
                    <p className="mitigation-value">9,717.31</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Industry H2</span>
                    <p className="mitigation-value">8,740.80</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Green Store H2</span>
                    <p className="mitigation-value">0</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Grey H2</span>
                    <p className="mitigation-value">0</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Mobility H2</span>
                    <p className="mitigation-value">976.51</p>
                  </div>
                </div>
                <div className="mitigation-right-wrap">
                  <div className="co2-wrap supply-left">
                    <h4 className="section-head">Supply Reliability</h4>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Total Power Produced</span>
                      <p className="mitigation-value">100 MW/Yr</p>
                    </div>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Add Total Hydrogen Produced</span>
                      <p className="mitigation-value">250,000 Kg/Yr</p>
                    </div>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Add Cumulative Hydrogen Shortfall</span>
                      <p className="mitigation-value">40,000 Kg/Yr</p>
                    </div>
                  </div>
                  <div className="supply-right">
                    <div className="tax-credits co2-wrap">
                      <span className="section-logo"><img src={taxCreditLogo} alt="logo image" /></span>
                      <div className="suppy-credit-wrap">
                        <p className="credits-value">$2</p>
                        <span className="credits-des">Local Tax Credits</span>
                      </div>
                    </div>
                    <div className="carbon-tax co2-wrap">
                      <span className="section-logo"><img src={carbonTaxLogo} alt="logo image" /></span>
                      <div className="suppy-credit-wrap">
                        <p className="credits-value">$0</p>
                        <span className="credits-des">Carbon Tax</span>
                      </div>
                    </div>

                  </div>
                  <div className="supply-nvp">
                    <div className="nvp-left co2-wrap">
                      <div className="tax-credits">
                        <span className="section-logo nvp-sec-logo"><img src={nvpLogo} alt="logo image" /></span>
                        <div className="suppy-credit-wrap npv-credit-wrap">
                          <p className="credits-value npv-value">NPV</p>
                          <span className="credits-des npv-des">$-2,401,238.61</span>
                        </div>
                      </div>
                    </div>
                    <div className="nvp-right co2-wrap">
                      <div className="tax-credits">
                        <span className="section-logo nvp-sec-logo"><img src={irrLogo} alt="logo image" /></span>
                        <div className="suppy-credit-wrap npv-credit-wrap">
                          <p className="credits-value npv-value">IRR</p>
                          <span className="credits-des npv-des">-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cost-wrap">
                <div className="cost-img-sec">
                  <div className="cost-img-wrap">
                    <div className="cost-img-inner-wrap">
                      <img src={electricityGraphImg} alt="logo img" />
                      <h3>Cost</h3>
                    </div>
                  </div>
                  <div className="cost-text-wrap">
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Capex</span>
                      <h2 className="cost-text-head">$404,000,100.00</h2>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Opex</span>
                      <h2 className="cost-text-head">$651,086,779.88</h2>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Period Value</span>
                      <h2 className="cost-text-head">$3,129,400</h2>
                    </div>

                  </div>
                </div>
                <div className="cost-value-one">
                  <span className="cost-value-head">LCOE</span>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Total</span>
                    <p className="lcoe-value">$4.97</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Solar</span>
                    <p className="lcoe-value">$4.36</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Turbine</span>
                    <p className="lcoe-value">$3.11</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Battery</span>
                    <p className="lcoe-value">$2.63</p>
                  </div>

                </div>
                <div className="cost-value-two">
                  <span className="cost-value-head">LCOE</span>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Total</span>
                    <p className="lcoe-value">$4.71</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Mobility</span>
                    <p className="lcoe-value">$4.71</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Industry</span>
                    <p className="lcoe-value">$6.25</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-summary-wrap">
              <div className="simulation-summary-inner">
                <h3 className='simulation-summary-head'>Simulation Summary</h3>
                <p className='simulation-summary-des'>Total EV count for the Zip Code 60608 for the current year 2023 is 5,000 and the projected EV growth in the next 26 years is +250%. The Demand Totals on an Avg Weekday is 46.9 KWH, Weekend is 63.7 KWH & on an Avg week is 72.5 KWH. Visit to any EV Station on an Avg Weekday is 40, weekend is 73 and on an Avg Week is 54. The POI count in the 5.5 miles radius of the selected Zip code are 713. There are about 750 households in the selected area with the population of 3,950 people.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default EVDashboard;
