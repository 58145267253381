import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Node } from 'reactflow';
import { MESSAGES } from '../../constants';

interface ILayeredNode extends Node {
  children: ILayeredNode[]
}

interface LayersProps {
  layeredNode: ILayeredNode[]
}

interface INodeProps {
  node: ILayeredNode
}

const Tree = ({ layeredNode }: LayersProps) => {
  const buildTree = (nodes: Node[]) => {
    const nodeMap: any = {};
    const rootNodes: any[] = [];
    let hasLayers = false;

    nodes.forEach((node: any) => {
      node.children = [];
      nodeMap[node.id] = node;
    });

    nodes.forEach((node: Node) => {
      if (node.data.parentNode && nodeMap[node.data.parentNode]) {
        nodeMap[node.data.parentNode].children.push(node);
        hasLayers = true;
      } else {
        rootNodes.push(node);
      }
    });

    return { rootNodes, hasLayers };
  };

  const tree = buildTree(layeredNode);

  return (
    <div className='objet-layer-main'>
      <div className='object-ul'>
        {tree.rootNodes.map((root: ILayeredNode) => {
          return (root.children.length ? <LayeredNodes key={root.id} node={root} /> : <></>);
        })}

        {
          !tree.hasLayers && <div className='no-layers'>
            <span>{MESSAGES.NO_LAYERS_YET}</span>
          </div>
        }
      </div>
    </div>
  );
};

const LayeredNodes = ({ node }: INodeProps) => (
  <div className="tab-left-part object-ul-inner">
    <div className="dropdown-wrapper shared-profile">
      <span className="user-icon object-user-icon">
        <img
          src={node.data.imageS3urlDark}
          alt="logo"
          className="user-img"
        />
      </span>
      <div className="right-sec">
        <h3 className="dropdown-head">
          {node.data.componentDisplayName}
        </h3>
      </div>
    </div>
    {node.children && <div className='object-li-wrap'>
      {node.children?.map((child: any) => (
        <div className='object-li' key={child.id}>
          <div className="tab-left-part">
            <LayeredNodes node={child} />
          </div>
        </div>
      ))}
    </div>}
  </div>
);

function LayersTree() {
  const { nodes } = useAppSelector(state => state.workbench);
  const copiedNodes = JSON.parse(JSON.stringify(nodes));

  return (
    <>
      <h4 className="modal-head modal-head-medium">Object Layers</h4>
      <Tree layeredNode={[...copiedNodes]} />
    </>
  );
}

export default LayersTree;
