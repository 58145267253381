import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { Node, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { commentsLogo, commentsOrangeLogo, isEmailIdAvailable, layersGreyIcon, layersIcon, searchSmallLogo, simulateIcon, simulationLock } from '../../utils';
import WorkbenchFlow from './WorkbenchFlow';
import { GEO_THERMAL, IScenarioDetails, MESSAGES, NUMBER, SHARED_SCENARIO_PERMISSION, USER_PERMISSIONS } from '../../constants';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { LABELS } from '../../constants/labelConstant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ObjectParameters from '../../pages/workbench/Parameters';
import { useSaveWorkbench } from '../../hooks/useSaveWorkbenchData';
import { showComments } from '../../redux/workbenchSlice';
import { ISimulationResponse, ISimulationStatusResponse } from '../../redux/services/simulationApi';
import { changeSpinnerTitle, startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { useDuplicateScenarioMutation } from '../../redux/services/scenarioApis';
import { toast } from 'react-toastify';
import StatusButtons from './StatusButtons';
import { resetLayers, updateBreadCrumb, updateCurrentLayerID } from '../../redux/slices/layersSlice';
import LayersTree from './LayersTree';
import { useGeoThermalSimulation } from '../../hooks/useGeoThermalSimulation';
import SearchParameterInput from './searchParameterInput';
import UpdateParameterValue from './UpdateParameterModal';

interface IWorkFlowProps {
  scenarioDetails: IScenarioDetails
}

// eslint-disable-next-line complexity
function WorkbenchFlowHOC({ scenarioDetails }: IWorkFlowProps) {
  const [isClearBoard, setIsClearBoard] = useState(false);
  const [commentToggle, setCommentToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [clearBoardAttempt, setClearBoardAttempt] = useState(NUMBER.N0);
  const [clearLinkAttempt, setClearLinkAttempt] = useState(NUMBER.N0);
  const [show, setShow] = useState(false);
  const [taskId, setTaskId] = useState('');
  const [component, setComponent] = useState<Node | object>({});
  const [showLayers, setShowLayers] = useState(false);
  const userData = useAppSelector(state => state.auth);
  const workbenchData = useAppSelector(state => state.workbench);
  const { breadCrumb, currentLayerID } = useAppSelector(state => state.layersData);
  const userPermission = workbenchData.scenarioDetails.permission;
  const { BaselineStatus } = scenarioDetails;
  const { saveWorkbench, saveSimulation, getSimulationStatus, saveWorkbenchAsDuplicate } = useSaveWorkbench();
  const { simulateGeoThermal } = useGeoThermalSimulation();
  const noOfComments = useAppSelector(state => state.workbench.commentLength);
  const [saveCommentCount, setCommentCount] = useState(NUMBER.N0);
  const dispatch = useAppDispatch();
  const [duplicate] = useDuplicateScenarioMutation();
  const simulationStatusIntervalRef = useRef<number | undefined>();
  const { user_approver_list, id: scenario_id } = scenarioDetails;
  const isCollaboratorOrLockScenario = userPermission === SHARED_SCENARIO_PERMISSION.COLLABORATOR || BaselineStatus;
  const isSharedOrLockScenario = !!(userPermission ?? BaselineStatus);
  const canSimulateScenario = userData.permissions?.includes(USER_PERMISSIONS.run_simulation);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [paramName, setParamName] = useState<string>('');
  const [openUpdateParam, setOpenUpdateParam] = useState<boolean>(false);
  const isDisableObjectMenu = userData.permissions?.includes(USER_PERMISSIONS.disable_object_menu);

  const updatePropertyShowUp = (node: Node) => {
    setComponent(node);
    setShow(true);
  };

  const isCurrentUserIsAppover = isEmailIdAvailable(userData.email, user_approver_list);

  const clearEntireBoard = useCallback(() => {
    setClearBoardAttempt(prev => prev + NUMBER.N1);
  }, []);

  const clearEntireLinking = useCallback(() => {
    setClearLinkAttempt(prev => prev + NUMBER.N1);
  }, []);

  const showAlertModal = useCallback((value: boolean, action: string) => {
    setShowAlert(value);
    setIsClearBoard(action === 'clearBoard');
  }, []);

  const handleOnCancel = useCallback(() => {
    setShowAlert(false);
  }, []);

  const handleOnConfirm = useCallback(() => {
    if (isClearBoard) {
      clearEntireBoard();
    } else {
      clearEntireLinking();
    }
    handleOnCancel();
  }, [isClearBoard]);

  const saveScenario = useCallback(() => {
    saveWorkbench(true, () => {
      setCommentCount(saveCommentCount + NUMBER.N1);
    });
  }, [workbenchData]);

  const saveScenarioAsDuplicate = () => {
    dispatch(startFullLoading());
    duplicate({ scenarioId: workbenchData?.scenarioDetails.id })
      .then((res) => {
        if ('data' in res) {
          const scenarioDuplicate = res?.data?.scenario_data;
          if (scenarioDuplicate) {
            saveWorkbenchAsDuplicate(true, scenarioDuplicate, () => {
            });
          }
        } else {
          toast.error((res.error as any).detail);
          dispatch(stopFullLoading());
        }
      });
  };

  /**
   * Function to get simulation status in every 10 seconds...
   */
  const getSimulationDataStatus = () => {
    getSimulationStatus(taskId, (res: ISimulationStatusResponse) => {
      clearInterval(simulationStatusIntervalRef.current);
      if (res.status === 'completed') { // when simulation is completed
        setTimeout(() => {
          smoothScollToGraph();
        }, NUMBER.N1000);
      }
    }, simulationStatusIntervalRef.current);
  };

  // Smooth scroll to the Graph Wrapper...
  const smoothScollToGraph = () => {
    const element = document.querySelector('.simulation-chart-wrapper');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * useEffect to set 10 sec interval on get simulation status api call...
   */
  useEffect(() => {
    if (taskId) {
      simulationStatusIntervalRef.current = setInterval(getSimulationDataStatus, NUMBER.N10000) as unknown as number;
      getSimulationDataStatus();
    }
    // Cleanup the interval on component unmount
    return () => clearInterval(simulationStatusIntervalRef.current);
  }, [taskId]);

  /**
   * Function to start the workbench simulation...
   */
  const simulateWorkbenchData = () => {
    if (!workbenchData.unsavedChanges) {
      if (scenarioDetails.subProject_Type === GEO_THERMAL) {
        const simData = {
          subProjectId: scenarioDetails.id,
          isDashboard: false
        };
        simulateGeoThermal(simData, workbenchData.savedNodes, (data) => {
          toast.success(MESSAGES.SIMULATION_IS_COMPLETED);
          setTimeout(() => {
            smoothScollToGraph();
          }, NUMBER.N1000);
        });
      } else {
        // send true incase of simulation 2 otherwise false...
        saveSimulation(false, (res: ISimulationResponse) => {
          setTaskId(res.task_id);
          dispatch(changeSpinnerTitle(MESSAGES.SIMULATION_RUNNING));
        });
      }
    }
  };

  const updateLayersId = (id: string) => {
    if (id === '') {
      dispatch(resetLayers());
    } else {
      const index = breadCrumb.findIndex(bc => bc.id === id);
      const breadCrumbArray = breadCrumb.slice(NUMBER.N0, index + NUMBER.N1);
      dispatch(updateBreadCrumb(breadCrumbArray));
      dispatch(updateCurrentLayerID(id));
    }
  };

  const showLayersModal = () => {
    setShowLayers(true);
  };

  const onParamSelect = (param: any) => {
    setShowSearchInput(false);
    setParamName(param);
    setOpenUpdateParam(true);
  };

  const renderClearBoard = () => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (isCollaboratorOrLockScenario || isDisableObjectMenu) {
      return null;
    } else {
      return <Dropdown.Toggle id="dropdown-basic">Clear Board</Dropdown.Toggle>;
    }
  };

  return (
    <>
      <div className="workbench-chart-main">
        <div className="workbench-chart-header search-param">
          {workbenchData.nodes.length > NUMBER.N0 && workbenchData.nodes[0].data.propertiesValues
            ? <div className="search-param-text">
              <span className="custom-search" title='Search Objects' onClick={() => setShowSearchInput(true)}>
                <img src={searchSmallLogo} alt="logo" className="search-logo" />
              </span>
              {!showSearchInput && <span className="work-board-text">Search Object Properties</span>}
              {showSearchInput && <SearchParameterInput
                showSearchParam={setShowSearchInput}
                onSelect={onParamSelect}
              />}
              <div className={`tooltip-text ${showSearchInput ? 'tooltip-hidden' : ''}`}>
                {'Search: Quickly find object properties. Type keywords like \'Capex,\' \'Period Cost,\' or \'Scope 1 Emissions.'}
              </div>
            </div> : <div className="search-param-text"> </div>}

          {!isCurrentUserIsAppover && <div className="chart-btn-wrapper">
            <Dropdown className="create-modal-dropdown ev-scenario-dropdown dsider-scenario-dropdown">
              <Dropdown.Toggle id="dropdown-basic" className='no-radius primary-btn' disabled={isCollaboratorOrLockScenario}>
                <span className='simulation-lock-img'><img src={simulationLock} alt="logo" /></span>
                Save this Scenario
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.ItemText>Options</Dropdown.ItemText>
                <Dropdown.Item as="button" disabled={isCollaboratorOrLockScenario} onClick={saveScenario}>Save Scenario</Dropdown.Item>
                <Dropdown.Item as="button" disabled={isCollaboratorOrLockScenario} onClick={saveScenarioAsDuplicate}>Save as Duplicate</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


            <Button className={`primary-btn no-radius ${workbenchData.unsavedChanges || workbenchData.nodes.length === NUMBER.N0 ? 'disabled-btn' : ''}`}
              title={workbenchData.unsavedChanges ? MESSAGES.UNSAVED_CHANGES : !workbenchData.nodes.length ? 'Workbench Empty!' : 'Simulate'}
              onClick={simulateWorkbenchData} disabled={!canSimulateScenario || isCollaboratorOrLockScenario}>
              <span className="simulate-icon-workbench"> <img src={simulateIcon} alt="simulation" /></span>
              <span className='simulation-lock-img'><img src={simulationLock} alt="logo" /></span>
              Simulate
            </Button>
          </div>}

          {/* Approve Reject buttons */}
          <StatusButtons isCurrentUserIsAppover={isCurrentUserIsAppover} scenario_id={scenario_id} />
        </div>
        <div className="workbench-board-body">
          <div className="workbench-board-header">
            {/* BreadCrumb Started */}
            {breadCrumb?.length > NUMBER.N0 &&
            <div className="work-board-text breadcrumb-text-board">
              <span className='bread-crumb' onClick={() => updateLayersId('')}>Work Bench  </span>
              {breadCrumb.map((bc) => (
                <span
                  className={`bread-crumb ${bc.id === currentLayerID ? 'active' : ''}`}
                  key={bc.id}
                  onClick={() => updateLayersId(bc.id)}> / {bc.name} </span>
              ))}
            </div>}
            {/* BreadCrumb Ended */}
            <div className="workbench-options">
              <Dropdown className="create-modal-dropdown">
                {renderClearBoard()}
                <Dropdown.Menu>
                  <Dropdown.ItemText>Options</Dropdown.ItemText>
                  <Dropdown.Item as="button" onClick={() => showAlertModal(true, 'clearBoard')}>Clear Entire Board</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => showAlertModal(true, 'clearLink')}>Clear Linking</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="tooltip-container">
                <Button className="setting-btn btn-no-outline" onClick={showLayersModal} disabled={!workbenchData.nodes.length}>
                  <div className="circle-logo-wrapper">
                    <img src={layersGreyIcon} alt="image" />
                  </div>
                </Button>
                <div className="tooltip-text">Layers</div>
              </div>
              <div className="tooltip-container" onClick={() => {
                setCommentToggle(!commentToggle);
                dispatch(showComments(!commentToggle));
              }}>
                <Button className="setting-btn btn-no-outline">
                  <div className="circle-logo-wrapper">
                    <span className="svg-icon">
                      {!commentToggle && <img src={commentsLogo} alt="logo img" />}
                      {commentToggle && <img src={commentsOrangeLogo} alt="logo img" />}
                    </span>
                    {noOfComments > NUMBER.N0 && <div className='comment-count-wrap'>
                      <span className="comment-count">{noOfComments}</span>
                    </div>}
                  </div>
                </Button>
                <div className="tooltip-text">Comment</div>
              </div>
            </div>
          </div>
          <div className="workbench-board-wrapper">
            {/* React Flow code */}

            <ReactFlowProvider>
              <WorkbenchFlow
                clearBoardAttempt={clearBoardAttempt}
                clearLinkAttempt={clearLinkAttempt}
                showparameterpopup={updatePropertyShowUp}
                saveCommentCount={saveCommentCount}
              />
              {show &&
                <ObjectParameters showModal={show} closeModalHandler={() => setShow(false)} component={component} />
              }
              {openUpdateParam && <UpdateParameterValue
                show={openUpdateParam}
                handleClose={() => setOpenUpdateParam(false)}
                paramName={paramName}
              />}
            </ReactFlowProvider>

            {/* React Flow code End */}
          </div>
          <div className="workbench-board-footer d-none">
            <span className="footer-board-left">Last updated 3d ago</span>
          </div>
        </div>
      </div>
      {showAlert && <ConfirmationAlert
        showAlert={showAlert}
        title={isClearBoard ? LABELS.CLEAR_BOARD_TITLE : LABELS.CLEAR_LINK_TITLE}
        message={isClearBoard ? MESSAGES.CLEAR_BOARD_CONFIRM_MESSAGE : MESSAGES.CLEAR_LINK_CONFIRM_MESSAGE}
        yesBtnText={LABELS.YES_CLEAR}
        cancleBtnText={LABELS.CANCEL}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
      />}

      {/* Layers Modal */}
      <Modal
        show={showLayers}
        onHide={() => setShowLayers(false)}
        dialogClassName="modal-546 top-right-modal input-output-modal object-layers-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowLayers(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LayersTree></LayersTree>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WorkbenchFlowHOC;
