import React, { FC, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsStock from 'highcharts/modules/stock';
import { NUMBER } from '../../constants';
import { SCENARIO_TYPE_LABELS } from '../../constants/graph.constant';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import FinancialTab from './FinancialTab';
import { getStackedGraphOptions } from '../../components/shared/Graph/GraphUtils';
import MapView from './MapView';
import TimelineRangeSlider from '../../components/shared/timelineSlider';
import PdfDocument from '../../components/shared/PdfGenerator';
import ObservationModal from './addObservationModal';
import { capitalizeAndRemoveUnderscore, truncateBigNumbers } from '../../utils';

HighchartsBoost(Highcharts);
HighchartsExporting(Highcharts);
HighchartsStock(Highcharts);

interface Props {
  selectedScenarioList: any[]
  financialData: any
  subProjectType: string
  aggregatedData: any[]
  clearAll: () => void
}

const CompareScenerio: FC<Props> = ({ selectedScenarioList, financialData, subProjectType, aggregatedData, clearAll }) => {
  const [graphOptions, setGraphOptions] = useState<any>();
  const [LCOEgraphOptions, setLCOEGraphOptions] = useState<any>();
  const [graphLoading, setGraphLoading] = useState(true);
  const [graphCategories, setGraphCategories] = useState<string[]>([]);
  const [graphLegends, setGraphLegends] = useState<string[]>([]);
  const [range, setRange] = useState<any>([]);
  const [renderPdf, setRenderPdf] = useState<boolean>(false);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const LCOERef = useRef<HighchartsReact.RefObject>(null);
  const [imageDataUrl, setImageDataUrl] = useState<any>();
  const [lcoeImgDataUrl, setLcoeImgDataUrl] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [observationValue, setObservationValue] = useState<string>('');
  const [contextValue, setContextValue] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string | null>('');
  const [chartImgFile, setChartImgFile] = useState<any>(null);
  const [companyLogoFile, setCompanyLogoFile] = useState<any>(null);
  const [scenarioNames, setScenarioNames] = useState<JSX.Element[]>([]);

  const getYearKeysFromArray = (arr: any) => {
    const yearKeys = new Set();
    const traverse = (currentObj: any) => {
      for (const key in currentObj) {
        if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
          const value = currentObj[key];
          if (typeof value === 'object' && value !== null) {
            traverse(value);
          } else {
            if (/^\d{4}$/.test(key)) {
              yearKeys.add(key);
            }
          }
        }
      }
    };
    arr.forEach((item: any) => {
      traverse(item);
    });
    const sortArrAsc = Array.from(yearKeys).sort((a: any, b: any) => a - b);
    return sortArrAsc;
  };

  const updateRangeFromData = (data: any) => {
    const yearKeys = getYearKeysFromArray(data);
    const startYear = Number(yearKeys[0]);
    const endYear = Number(yearKeys[yearKeys.length - 1]);
    setRange([startYear, endYear]);
  };

  useEffect(() => {
    if (aggregatedData !== undefined) {
      updateRangeFromData(aggregatedData);
    }
  }, [aggregatedData]);

  useEffect(() => {
    const newScenarioNames = graphLegends.map((ele: string, index: number) => (
      <p className='graph-s-num' key={index}>S{index + 1}:
        <span className='graph-s-text'>{ele}</span>
      </p>
    ));
    setScenarioNames(newScenarioNames);
  }, [graphLegends]);

  const getEmissionData = () => {
    if (Array.isArray(financialData) && financialData.length > NUMBER.N0) {
      const tempArr: number[] = [];
      const tempCategories: string[] = [];
      const tempLegends: string[] = [];
      financialData.forEach((ele: any, index: any) => {
        tempArr.push(ele.Total_emissions || NUMBER.N0);
        tempLegends.push(ele.scenario_name);
        tempCategories.push(`S${index + NUMBER.N1}`);
      });
      setGraphLegends(tempLegends);
      setGraphCategories(tempCategories);
      return tempArr;
    }
    return null;
  };

  const handleSliderChange = (value: any) => {
    setRange(value);
  };

  const getGraphSeries = () => {
    const seriesData: any[] = [];
    const prepObj: any = {};
    const labelData = SCENARIO_TYPE_LABELS[subProjectType];
    aggregatedData.forEach((obj, index) => {
      Object.values(obj).forEach((scenarioId: any) => {
        Object.entries(scenarioId).map(entry => {
          const objName: string = entry[0];
          const innerObj: any = entry[1];
          let sum: any = 0;
          const innerObjKeys = Object.keys(innerObj);
          const innerObjValues = Object.values(innerObj);
          // Ensure range[0] and range[1] are numbers
          const rangeStart = Number(range[0]);
          const rangeEnd = Number(range[1]);
          const startIndex = innerObjKeys.findIndex(ele => Number(ele) === rangeStart);
          const endIndex = innerObjKeys.findIndex(ele => Number(ele) === rangeEnd);
          if (startIndex === endIndex) {
            sum = innerObjValues[startIndex] || 0;
          } else if (startIndex < endIndex) {
            const adjustedEndIndex = endIndex + 1;
            // Use slice to get the sum of values within the range
            innerObjValues.slice(startIndex, adjustedEndIndex).forEach((value: any) => {
              sum += value;
            });
          }
          if (!prepObj[objName]) {
            prepObj[objName] = Array(aggregatedData.length).fill(0);
          }
          const val = prepObj[objName];
          val[index] = sum;
          prepObj[objName] = val;
        });
      });
    });
    for (const property in prepObj) {
      const isZeroArr = prepObj[property].some((item: number) => item !== 0);
      isZeroArr && seriesData.push({
        name: capitalizeAndRemoveUnderscore(property),
        yAxis: NUMBER.N0,
        type: 'column',
        data: prepObj[property],
        dataLabels: {
          enabled: true, // Enable data labels for the secondary data series
          formatter: function (this: Highcharts.Point): string {
            return `${this.y === NUMBER.N0 ? '' : truncateBigNumbers(this.y)}`; // Show the Y value as the label
          }
        }
      });
    }
    seriesData.push({
      name: 'Emissions',
      type: 'line',
      yAxis: NUMBER.N1,
      data: getEmissionData(),
      dataLabels: {
        enabled: true, // Enable data labels for the secondary data series
        formatter: function (this: Highcharts.Point): string {
          return `${this.y === NUMBER.N0 ? '' : truncateBigNumbers(this.y)}`; // Show the Y value as the label
        }
      }
    });
    return seriesData;
  };

  const getLCOEseries = () => {
    const dataArr: any[] = [];
    const LCOE = [];
    const LCOC = [];
    const LCOH = [];
    let hasLCOC = false;
    let hasLCOH = false;

    if (financialData !== undefined) {
      for (const ele of financialData) {
        LCOE.push(ele.LCOE_Total || 0);
        if ('levelized_costs_of_gaseous_co2' in ele) {
          hasLCOC = true;
          LCOC.push(ele.levelized_costs_of_gaseous_co2 || 0);
        }
        if ('LCOH' in ele) {
          hasLCOH = true;
          LCOH.push(ele.LCOH || 0);
        }
      }
    }
    dataArr.push({
      name: 'LCOE',
      data: LCOE,
      type: 'column',
      dataLabels: {
        enabled: true, // Enable data labels for the secondary data series
        formatter: function (this: Highcharts.Point): string {
          return `${this.y === NUMBER.N0 ? '' : truncateBigNumbers(this.y)}`; // Show the Y value as the label
        }
      }
    });

    if (hasLCOC) {
      dataArr.push({
        name: 'LCOC',
        data: LCOC,
        type: 'column',
        dataLabels: {
          enabled: true, // Enable data labels for the secondary data series
          formatter: function (this: Highcharts.Point): string {
            return `${this.y === NUMBER.N0 ? '' : truncateBigNumbers(this.y)}`; // Show the Y value as the label
          }
        }
      });
    } else if (hasLCOH) {
      dataArr.push({
        name: 'LCOH',
        data: LCOH,
        type: 'column',
        dataLabels: {
          enabled: true, // Enable data labels for the secondary data series
          formatter: function (this: Highcharts.Point): string {
            return `${this.y === NUMBER.N0 ? '' : truncateBigNumbers(this.y)}`; // Show the Y value as the label
          }
        }
      });
    }
    return dataArr;
  };


  useEffect(() => {
    if (aggregatedData?.length) {
      const series = getGraphSeries();
      const LCOESeries = getLCOEseries();
      const dataOptions = getStackedGraphOptions(series, graphLegends, graphCategories, true);
      const LCOEDataOptions = getStackedGraphOptions(LCOESeries, graphLegends, graphCategories);
      setGraphOptions(dataOptions);
      setLCOEGraphOptions(LCOEDataOptions);
      setGraphLoading(false);
    }
  }, [aggregatedData, range]);

  const renderPdfImage = (chart: any, text: string) => {
    setTimeout(() => {
      const chartSvg = chart.container.getElementsByTagName('svg')[0].outerHTML;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const DOMURL = window.URL || window.webkitURL || window;
      const img = new Image();
      const svgBlob = new Blob([chartSvg], { type: 'image/svg+xml;charset=utf-8' });
      const url = DOMURL.createObjectURL(svgBlob);

      img.onload = function () {
        if (ctx) {
          canvas.width = chart.container.offsetWidth;
          canvas.height = chart.container.offsetHeight;
          ctx.drawImage(img, 0, 0);
          DOMURL.revokeObjectURL(url);
          const chartDataUrl = canvas.toDataURL('image/png');
          if (text === 'power') {
            setImageDataUrl(chartDataUrl);
          } else if (text === 'lcoe') {
            setLcoeImgDataUrl(chartDataUrl);
          }
        }
      };
      img.src = url;
      setRenderPdf(true);
    }, NUMBER.N1000);
  };

  const printView = () => {
    const chart = chartRef.current?.chart;
    const lcoeChart = LCOERef.current?.chart;
    if (chart) {
      renderPdfImage(chart, 'power');
    }
    if (lcoeChart) {
      renderPdfImage(lcoeChart, 'lcoe');
    }
  };

  const openPdfViewer = (observationVal: string, contextVal: string) => {
    setIsSubmit(true);
    setRenderPdf(false);
    setObservationValue(observationVal);
    setContextValue(contextVal);
  };

  const handleSelect = (key: string | null) => {
    setActiveKey(key);
  };

  return (

    <div className="dsider-tab-wrapper compare-modal-tab">
      <div className="">
        {financialData?.length && selectedScenarioList?.length &&
          <>
            <div className="print-tb-wrap">
              {activeKey === 'graphView' && <Button className="btn-no-outline print-btn" onClick={() => printView()}>Print Summary</Button>}
              <Button className="btn-no-outline" onClick={clearAll}>Clear All</Button>
            </div>
            <Tabs defaultActiveKey={'Tabular View'} id="uncontrolled-tab-example" onSelect={handleSelect}>
              <Tab eventKey="Tabular View" title="Tabular View">
                <FinancialTab financialData={financialData} projectType={subProjectType} selectedScenarioList={selectedScenarioList} />
              </Tab>
              <Tab eventKey="graphView" title="Graph View">
                <div className="col-md-12">
                  <div className={'table-wrapper power-table-wrap'}>
                    <div className="simulation-chart-wrapper" >
                      {graphLoading
                        ? <div className='map-data-loader'><Spinner /></div>
                        : <>
                          <div className="chart-left w-100">
                            <h2 className="chart-title">Compare Plots</h2>
                            <div className='compare-s-sec'>
                              {scenarioNames}
                            </div>
                            <div className='power-graph-main-wrap'>
                              <div className='power-graph-wrap right-border'>
                                <HighchartsReact ref={chartRef} highcharts={Highcharts} options={graphOptions} />
                                <div>
                                  <TimelineRangeSlider onChange={handleSliderChange} range={range} />
                                </div>
                              </div>
                              <div className='power-graph-wrap'>
                                <HighchartsReact highcharts={Highcharts} options={LCOEgraphOptions} ref={LCOERef} />
                              </div>
                            </div>
                          </div>
                        </>}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="MapView" title="Map View">
                <MapView financialData={financialData} projectType={subProjectType} selectedScenarioList={selectedScenarioList} />
              </Tab>
            </Tabs>
          </>}

      </div>
      {renderPdf && <ObservationModal show={renderPdf} handleClose={() => setRenderPdf(false)}
        isSubmit={(value: string, value2: string) => openPdfViewer(value, value2)} manualChartUpload={(file: File) => setChartImgFile(file)}
        manualCompanyLogoUpload={(file: File) => setCompanyLogoFile(file)} />}
      {isSubmit && <PdfDocument chartImage={imageDataUrl} lcoeImg={lcoeImgDataUrl}
        onClose={() => setIsSubmit(false)} value={observationValue} contextVal={contextValue}
        show={isSubmit} financialData={financialData} manualChartUrl={chartImgFile ? URL.createObjectURL(chartImgFile) : null}
        manualCompanyUrl={companyLogoFile ? URL.createObjectURL(companyLogoFile) : null} scenarioNames={graphLegends} />}
    </div>


  );
};

export default CompareScenerio;
