import React from 'react';
import AdminHoc from '../../components/layout/AdminHoc';
import CompanyManagementHeader from '../../components/admin/company/CompanyManagementHeader';
import CompanyManagementTable from '../../components/admin/company/CompanyManagementTable';

const CompanyManagement = () => {
  return (
    <AdminHoc>
      <div className='admin-section'>
        <div className='admin-body'>
          <CompanyManagementHeader />
          <CompanyManagementTable />
        </div>
      </div>
    </AdminHoc>
  );
};

export default CompanyManagement;
