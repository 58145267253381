import React from 'react';
import AdminHoc from '../../components/layout/AdminHoc';
import ObjectManagementHeader from '../../components/admin/object/ObjectManagementHeader';
import ObjectManagementTable from '../../components/admin/object/ObjectManagementTable';

const ObjectManagement = () => {
  return (
    <AdminHoc>
      <div className='admin-section'>
        <div className='admin-body'>
          <ObjectManagementHeader />
          <ObjectManagementTable />
        </div>
      </div>
    </AdminHoc>
  );
};

export default ObjectManagement;
