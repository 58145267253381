/* eslint-disable complexity */
import React, { FC } from 'react';
import { formatAndRoundOffNumber, formatIntegerOnly, getValueWithUnit, getValueWithUnitFirst, getWorkbenchURL } from '../../utils';
import { GEO_THERMAL, NUMBER, POWER } from '../../constants';
import { useNavigate } from 'react-router-dom';

interface Prop {
  projectType?: string
  financialData: any
  selectedScenarioList: any
}

const changeColor = (value: number) => {
  const valueType = Math.sign(value);
  if (valueType === -NUMBER.N1) {
    return 'red-text';
  } else if (valueType === NUMBER.N1) {
    return 'green-text';
  } else {
    return '';
  }
};

const FinancialTab: FC<Prop> = ({ financialData, projectType, selectedScenarioList }) => {
  const navigate = useNavigate();
  return (
    <div className="tab-main-container">
      <div className="col-md-12">
        {/* table */}
        <div className='table-wrapper'>
          <h2 className='section-head d-none'>Compare Financials</h2>
          {projectType === GEO_THERMAL && <GeoThermalFinacialTab financialData={financialData} selectedScenarioList={selectedScenarioList} />}
          {projectType !== GEO_THERMAL && <div className="table-container contest-table contest-table-power">
            <div className="table-head">
              <div className="table-row">
                <div className="th">Scenario</div>
                <div className="th">Capex</div>
                <div className="th">Variable</div>
                <div className="th">Period Cost</div>
                <div className="th">Local Tax Credits</div>
                <div className="th">Carbon Tax</div>
                {projectType === 'hydrogen' && <div className="th">LCOH</div>}
                {projectType === 'ccus' && <div className="th">LCOC</div>}
                <div className="th">LCOE</div>
                <div className="th">NPV</div>
                <div className="th">IRR</div>
                <div className="th">Emissions</div>
              </div>
            </div>
            <div className="table-body">
              {financialData.map((item: any, index: number) => (<div key={`${item?.scenario_id}-${index}`} className="table-row">
                {/* sccenario name row */}
                <ScenarioNameSec item={item} index={index} selectedScenarioList={selectedScenarioList} />

                <div className="td" data-label="Total Capex">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.Capex)}`}>${formatIntegerOnly(item?.Capex) ?? '0'}</span>
                    <div className="tooltip-text">${formatIntegerOnly(item?.Capex) ?? '0'}</div>
                  </div>
                </div>
                <div className="td" data-label="Variable">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.Opex)}`}>${formatAndRoundOffNumber(item?.Opex) ?? '0'}</span>
                    <div className="tooltip-text">${formatAndRoundOffNumber(item?.Opex) ?? '0'}</div>
                  </div>
                </div>
                <div className="td" data-label="Total Period Cost">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.Period_Value)}`}>${formatIntegerOnly(item?.Period_Value) ?? '0'}</span>
                    <div className="tooltip-text">${formatIntegerOnly(item?.Period_Value) ?? '0'}</div>
                  </div>
                </div>
                <div className="td" data-label="Local Tax Credits">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.taxCredit)}`}>${formatIntegerOnly(item?.taxCredit, '-') ?? '0'}</span>
                    <div className="tooltip-text">${formatIntegerOnly(item?.taxCredit, '-') ?? '-'}</div>
                  </div>
                </div>
                <div className="td" data-label="Carbon Tax">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.carbonTax)}`}>${formatIntegerOnly(item?.carbonTax, '-') ?? '0'}</span>
                    <div className="tooltip-text">${formatIntegerOnly(item?.carbonTax, '-') ?? '-'}</div>
                  </div>
                </div>
                {projectType === 'hydrogen' && <div className="td" data-label="LCOH">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.LCOH)}`}>${formatAndRoundOffNumber(item?.LCOH) ?? '0'}</span>
                    <div className="tooltip-text">${formatAndRoundOffNumber(item?.LCOH) ?? '0'}</div>
                  </div>
                </div>}
                {projectType === 'ccus' && <div className="td" data-label="LCOC">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.levelized_costs_of_gaseous_co2)}`}>${formatAndRoundOffNumber(item?.levelized_costs_of_gaseous_co2) ?? '0'}</span>
                    <div className="tooltip-text">${formatAndRoundOffNumber(item?.levelized_costs_of_gaseous_co2) ?? '0'}</div>
                  </div>
                </div>}
                <div className="td" data-label="LCOE">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.LCOE_Total)}`}>${formatAndRoundOffNumber(item?.LCOE_Total) ?? '0'}</span>
                    <div className="tooltip-text">${formatAndRoundOffNumber(item?.LCOE_Total) ?? '0'}</div>
                  </div>
                </div>
                <div className="td" data-label="NPV">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.NPV)}`}>${formatIntegerOnly(item?.NPV) ?? '0'}</span>
                    <div className="tooltip-text">${formatIntegerOnly(item?.NPV) ?? '0'}</div>
                  </div>
                </div>
                <div className="td" data-label="IRR">
                  <div className="tooltip-container">
                    <span className={`td-text ${changeColor(item?.IRR)}`}>{formatIntegerOnly(item?.IRR, '-')}</span>
                    <div className="tooltip-text">{item?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(item?.IRR, '-')}</div>
                  </div>
                </div>
                <div className="td" data-label="Emissions">
                  <div className="tooltip-container">
                    <span className='td-text'>{formatAndRoundOffNumber(item?.['Carbon_Abatement_(MT)']) ?? '0'} mt</span>
                    <div className="tooltip-text">{formatAndRoundOffNumber(item?.['Carbon_Abatement_(MT)']) ?? '0'}</div>
                  </div>
                  <span className='icon-next-arrow-grey cursor-pointer' onClick={() => navigate(getWorkbenchURL(item?.scenario_id, projectType ?? POWER))}></span>
                </div>
              </div>))}
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default FinancialTab;

const GeoThermalFinacialTab = ({ financialData, selectedScenarioList }: any) => {
  const navigate = useNavigate();
  const ECONOMIC_PARAMETERS = 'ECONOMIC PARAMETERS';

  return (
    <div className="table-container contest-table contest-table-geo-thermal">
      <div className="table-head">
        <div className="table-row">
          <div className="th">Scenario</div>
          <div className="th">Emissions</div>
          <div className="th">Financial Model</div>
          <div className="th">Capacity Factor</div>
          <div className="th">Project Payback Period</div>
          <div className="th">VIR</div>
          <div className="th">MOIC</div>
          <div className="th">Capex</div>
          <div className="th">Opex</div>
          <div className="th">LCOH</div>
          <div className="th">LCOE</div>
          <div className="th">IRR</div>
          <div className="th">NPV</div>
        </div>
      </div>
      <div className="table-body">
        {financialData.map((item: any, index: number) => (<div key={`${item?.scenario_id}-${index}`} className="table-row">
          {/* sccenario name row */}
          <ScenarioNameSec item={item} index={index} selectedScenarioList={selectedScenarioList} />

          <div className="td" data-label="Emissions">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.['RESULTS SUMMARY']?.['Total Avoided Carbon Emissions'])}</span>
            </div>
          </div>
          <div className="td" data-label="Financial Model">
            <div className="tooltip-container">
              <span className="td-text">{item?.[ECONOMIC_PARAMETERS]?.['Economic Model'] ?? '--'}</span>
            </div>
          </div>
          <div className="td" data-label="Capacity Factor">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.[ECONOMIC_PARAMETERS]?.['Capacity factor'])}</span>
            </div>
          </div>
          <div className="td" data-label="Project Payback Period">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.[ECONOMIC_PARAMETERS]?.['Project Payback Period'])}</span>
            </div>
          </div>
          <div className="td" data-label="VIR">
            <div className="tooltip-container">
              <span className="td-text">{item?.[ECONOMIC_PARAMETERS]?.['Project VIR=PI=PIR']?.value ?? '--'}</span>
            </div>
          </div>
          <div className="td" data-label="MOIC">
            <div className="tooltip-container">
              <span className="td-text">{item?.[ECONOMIC_PARAMETERS]?.['Project MOIC']?.value ?? '--'}</span>
            </div>
          </div>
          <div className="td" data-label="Capex">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnitFirst(item?.['CAPITAL COSTS (M$)']?.['Total capital costs'], '')}</span>
            </div>
          </div>
          <div className="td" data-label="Opex">
            <div className="tooltip-container">
              <span className="td-text">
                {getValueWithUnitFirst(item?.['OPERATING AND MAINTENANCE COSTS (M$/yr)']?.['Total operating and maintenance costs'], '/yr')}</span>
            </div>
          </div>
          <div className="td" data-label="LCOH">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.['SUMMARY OF RESULTS']?.['Direct-Use heat breakeven price (LCOH)'], '')}</span>
            </div>
          </div>
          <div className="td" data-label="LCOE">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.['SUMMARY OF RESULTS']?.['Electricity breakeven price'], '')}</span>
            </div>
          </div>
          <div className="td" data-label="IRR">
            <div className="tooltip-container">
              <span className="td-text">{getValueWithUnit(item?.[ECONOMIC_PARAMETERS]?.['Project IRR'])}</span>
            </div>
          </div>
          <div className="td" data-label="NPV">
            <div className="tooltip-container">
              <span className='td-text'>{getValueWithUnitFirst(item?.[ECONOMIC_PARAMETERS]?.['Project NPV'])}</span>
            </div>
            <span className='icon-next-arrow-grey cursor-pointer' onClick={() => navigate(getWorkbenchURL(selectedScenarioList[index]?.value, GEO_THERMAL))}></span>
          </div>
        </div>))}
      </div>
    </div>
  );
};

const ScenarioNameSec = ({ item, index, selectedScenarioList }: any) => {
  return (
    <div className="td" data-label="Model">
      <div className="tooltip-container">
        <span className={'td-text'}>{item?.scenario_name ?? selectedScenarioList[index]?.label}</span>
        <div className="tooltip-text">{item?.scenario_name ?? selectedScenarioList[index]?.label}</div>
      </div>
    </div>
  );
};
