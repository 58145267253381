import { Node } from 'reactflow';
import { IObjectList, IPosition, IProerties, IScenarioSettings, NUMBER, Position } from '../constants';
import { createArrayWithLength, getId, getWorkbenchNodeData } from './helper';

export const getNewNode = (object: IObjectList, position: IPosition, workbenchNodes: Node[], settingsData: IScenarioSettings, parentId: string) => {
  const {
    propertiesNames, propertiesValues, propertiesFormulaTitle,
    setPropertyValues, propertiesType, units, minMax, scheduleProperty, listOfTurbines
  } = getWorkbenchNodeData(object?.properties as IProerties[], settingsData);

  // get duplicated component count and add that in componentDisplayName...
  const duplicateNode = workbenchNodes.filter(n => n.data.class === object.component).length;

  return {
    id: getId(object.componentDisplayName),
    name: object.componentDisplayName,
    type: 'dsiderNode',
    position,
    sourcePosition: ('right' as Position),
    targetPosition: ('left' as Position),
    data: {
      parentNode: parentId,
      class: object.component,
      imageS3url: object.imageS3url,
      imageS3urlDark: object.imageS3urlDark,
      componentDisplayName: `${object.componentDisplayName}${duplicateNode ? `-${duplicateNode}` : ''}`,
      no_of_inputs: object.no_of_inputs,
      no_of_outputs: object.no_of_outputs,
      sensitivityFormCategory: object.SensitivityFormCategory,
      capacityProperty: object.CapacityProperty,
      powerCategory: object.powerCategory,
      input_node_arr: createArrayWithLength(object.no_of_inputs),
      output_node_arr: createArrayWithLength(object.no_of_outputs),
      defaultType: object.no_of_inputs === NUMBER.N0 ? 'output' : object.no_of_outputs === NUMBER.N0 ? 'input' : 'default',
      propertiesType,
      units,
      minMax,
      properties: propertiesNames,
      formulaTitle: propertiesFormulaTitle,
      scheduleProperty,
      listOfTurbines,
      propertiesValues,
      ...setPropertyValues
    },
    selected: false,
    dragging: false
  };
};
