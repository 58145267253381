import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { ADMIN_CHANGE_PASSWORD, ADMIN_EDIT_PROFILE, DASHBOARD, versionLogo } from '../../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUserType } from '../../../redux/auth/authReducer';
import { defaultUserTypes } from '../../../utils/defaultData';
import { updateSignOutUserState } from '../../../redux/slices/adminSlice';
import UserAvatar from '../../approval-workflow/UserAvatar';
import { useGetLatestVersionQuery } from '../../../redux/services/authApis';

const UserMenu = () => {
  const userData = useAppSelector((state) => state.auth);
  const { data: latestVersion } = useGetLatestVersionQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Dropdown className="profile-dropdown">
        <Dropdown.Toggle id="dropdown-basic">
          <UserAvatar firstname={userData.firstName} lastname={userData.lastName}
            imageUrl={userData.image_url} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="profile-dropdown">
          <div className="dropdown-wrapper">
            <UserAvatar firstname={userData.firstName} lastname={userData.lastName}
              imageUrl={userData.image_url} />
            <div className="right-sec">
              <h3 className="dropdown-head">{userData.firstName ? `${userData.firstName} ${userData.lastName}` : userData.company_name}</h3>
              <div className="tooltip-container">
                <span className="dropdown-email">{userData.email}</span>
                <div className="tooltip-text">{userData.email}</div>
              </div>
              <div className="dropdown-footer">
                <Button
                  className="btn-outline small"
                  onClick={() => goTo(ADMIN_EDIT_PROFILE)}
                >
                  Edit Profile
                </Button>
              </div>
            </div>
          </div>
          <div className="account-setting">
            <Link to={DASHBOARD} className="account-item" onClick={() => dispatch(updateUserType(defaultUserTypes.user))}>
              Switch to Dsider Dashboard
            </Link>
            <Link to={ADMIN_CHANGE_PASSWORD} className="account-item">
              Change Password
            </Link>
            <Link
              to="#"
              onClick={() => dispatch(updateSignOutUserState(true))}
              className="account-item sign-out"
            >
              Sign Out
            </Link>
          </div>
          <div className="version-sec">
            <img src={versionLogo} alt="logo" />
            <span className="version-text">Version v{latestVersion?.version}</span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserMenu;
