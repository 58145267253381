import React, { useEffect, useState } from 'react';
import { backArrow } from '../../utils';
import MonteCarloForm from './MonteCarloForm';
import MonteCarloGraph from './MonteCarloGraph';
import { useGeoThermalSimulation } from '../../hooks/useGeoThermalSimulation';
import { toast } from 'react-toastify';
import NoData from '../shared/NoData';
import { MESSAGES } from '../../constants';

function MonteCarloSimulation({ setIsMonteCarloSimulation }: any) {
  const [file, setFile] = useState(null);
  const [monteResponse, setMonteResponse] = useState<any>();
  const [paramFile, setParamFile] = useState(null);
  const { getMonteCarloSimResult } = useGeoThermalSimulation();
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';

  useEffect(() => {
    getMonteCarloSimResult(scenarioId, (res) => {
      console.log(res);
      if (res?.success) {
        setMonteResponse(res?.result);
      } else {
        toast.error(res?.message);
      }
    });
  }, []);

  return (
    <>
      <div className="simulation-chart-wrapper monto-carlo-chart">
        <div className="chart-left">
          <h2 className="chart-title">
            <button className='close-btn' onClick={() => setIsMonteCarloSimulation(false)}>
              <span className="back-arrow">
                <img src={backArrow} alt="logo" />
              </span>
            </button>
            Monte Carlo Simulation Chart
          </h2>
          <div className="power-graph-wrap">
            {monteResponse ? <MonteCarloGraph inputData={monteResponse} />
              : <NoData message={MESSAGES.SIM_RUNNING} />}
          </div>
        </div>
        <div className="chart-right">
          <div className="chart-right-inner">
            <div className="slider-wrap">
              <div className="simulation-form-wrap">
                <MonteCarloForm setFile={setFile} file={file} paramFile={paramFile} setParamFile={setParamFile} setMonteResponse={setMonteResponse} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MonteCarloSimulation;
