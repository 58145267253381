/* eslint-disable react/react-in-jsx-scope */
import Pagination from 'react-bootstrap/Pagination';

function DsiderPagination() {
  return (
    <div className='pagination-wrap'>
      <Pagination>
        <Pagination.Prev />
        <Pagination.Item>{1}</Pagination.Item>

        <Pagination.Item active>{2}</Pagination.Item>
        <Pagination.Item>{3}</Pagination.Item>
        <Pagination.Item>{4}</Pagination.Item>
        <Pagination.Item>{5}</Pagination.Item>

        {/* <Pagination.Item>{6}</Pagination.Item> */}
        <Pagination.Next />
      </Pagination>
    </div>
  );
}

export default DsiderPagination;
