import React, { useEffect, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { CREATE_FROM_SCRATCH, CREATE_WITH_WIZARD, IDashboardScenario, LABELS, MESSAGES, METERS_TO_MILES } from '../../constants';
import {
  CREATE_SCENARIO_WITH_AI, CREATE_SCENARIO_WITH_WIZARD, cardViewLogo, emptyModalLogo,
  evDataCardLogo, formatAndRoundOffNumber, getInitials, getValueWithUnit, getValueWithUnitFirst, getWorkbenchURL
} from '../../utils';
import HoverModal from './HoverModal';
import { useNavigate } from 'react-router-dom';
import { useGetDetailsMutation, useGetSharedUserListQuery } from '../../redux/services/scenarioApis';
import ScenarioDropdownList from '../shared/ScenarioDropdownList';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { useScenario } from '../../hooks/useScenario';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import DateTime from '../shared/DateTime';

interface IScenarioProps {
  data: IDashboardScenario
  className: string
  refetchData: () => void
  createdBy: string
  refreshActivity: () => void
}

const ScenarioCard = (props: IScenarioProps) => {
  const { data, className, createdBy, refetchData, refreshActivity } = props;
  const { deleteScenario } = useScenario();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(state => state.auth);
  const [showAlert, setShowAlert] = useState(false);
  const [sharedUserData, setSharedUserData] = useState([]);
  const { data: sharedUser, isSuccess: sharedUserSuccess } = useGetSharedUserListQuery(data.Scenario_id);
  const [getScenarioDetails] = useGetDetailsMutation();
  const radiusMap = props.data?.metrics?.metrics?.find((metric: any) => metric.name === 'poi_radius')?.value as number;
  const radiusInMiles = radiusMap * METERS_TO_MILES;
  const currentEV = props.data?.metrics?.metrics?.find((metric: any) => metric.name === 'ev_ownership_4_2')?.value as number;

  useEffect(() => {
    if (sharedUserSuccess) {
      setSharedUserData(sharedUser);
    }
  }, [sharedUserSuccess]);

  const goToScenario = (id: string, data: any) => {
    const url = getWorkbenchURL(id, data.Subproject_type);
    if (data.Subproject_type === 'ev') {
      dispatch(startFullLoading());
      getScenarioDetails(id).then((res: any) => {
        if (res.data) {
          const data = res.data;
          if (data.scenarioCreatedType === CREATE_FROM_SCRATCH) {
            navigate(`${url}&scenario_type=${CREATE_FROM_SCRATCH}`);
          } else {
            if ('lat' in data && 'lng' in data && 'radius' in data && 'startYear' in data && 'endYear' in data) {
              navigate(url);
            } else {
              navigate(`${CREATE_SCENARIO_WITH_AI}?scenario_id=${id}&scenarioName=${data.name}`);
            }
          }
        }
        dispatch(stopFullLoading());
      });
    } else {
      if (data.ScenarioCreatedType === CREATE_WITH_WIZARD && !data?.Updated_on) {
        navigate(`${CREATE_SCENARIO_WITH_WIZARD}?scenario_id=${data.Scenario_id}&scenarioName=${data.Scenario_name}&type=${data.Subproject_type}`, {
          state: {
            navigateFrom: LABELS.DASHBOARD
          }
        });
      } else {
        navigate(url, {
          state: {
            navigateFrom: LABELS.DASHBOARD
          }
        });
      }
    }
  };

  function handleRemove(id: string) {
    setShowAlert(false);
    deleteScenario(id, refetchData);
  };

  return (
    <>
      <div className={`col-md-4 ${(data.Subproject_type === 'ev' && props.data?.metrics) ? ' ' : 'empty'}`}>
        <div className="data-card-wrap">
          <div
            className={`data-card-main ${className}`}>
            <div className="data-card-header">
              <div className="user-circle plus-img">
                <span className="user-icon">{getInitials(createdBy)}</span>
              </div>
              <ScenarioDropdownList
                data={data}
                refreshData={refetchData}
                whiteThreeDots={true}
                showConfimationAlert={() => setShowAlert(true)}
                refreshActivity={refreshActivity}
              />
            </div>
            {data.Subproject_type === 'ev' && <div className="ev-card-logo"><img src={evDataCardLogo} alt="Logo" /> </div>}
            <div className="data-card-body" onClick={() => goToScenario(data.Scenario_id, data)}>
              <span className="modal-name">
                Project: {data?.Project_name}
              </span>
              <h2 className="data-card-title">
                {data.Scenario_name}
              </h2>
              {/* three default item list  */}
              {data?.metrics && DynamicListItems(data, currentEV, radiusInMiles)}

              {/* Not simulated help message */}
              {!data?.metrics && <div className="empty-modal-wrapper">
                <img src={emptyModalLogo} alt="logo img" className='empty-modal-img' /> <p className='empty-modal-text'>
                  {MESSAGES.SIMULATE_NOW}
                </p>
              </div>}
              {/* HoverModal */}
              <HoverModal data={props.data.metrics} pType={data.Subproject_type} scenarioName={props.data.Scenario_name} />
            </div>
            <div className="data-card-footer">
              <div className="card-dropdown">
                {[''].map((direction) => (
                  <Dropdown
                    className="create-modal-dropdown three-dot-dropdown"
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    variant="secondary"
                    align="end"
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="card-view">
                        <span className="card-view-img">
                          <img src={cardViewLogo} alt="img logo" />
                        </span>
                        <span className="card-view-number">{sharedUserData.length}</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={!sharedUserData.length ? 'd-none' : ''}>
                      <div className="dropdown-menu-inner">
                        <Dropdown.ItemText>
                          Shared with
                        </Dropdown.ItemText>
                        {sharedUserData.map((val) => {
                          return (
                            <Dropdown.Item as="button" key={val}>
                              <div className="dropdown-wrapper">
                                <span className="user-icon">
                                  {getInitials(val)}
                                </span>
                                <div className="right-sec" title={val}>
                                  <h3 className="dropdown-head current-user-head">
                                    {val}
                                  </h3>
                                  {val === userData.email && <span className='current-user'>(You)</span>}
                                </div>
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
              </div>
              <a onClick={() => goToScenario(data.Scenario_id, data)} className="back-project">
                <span className="icon-next-arrow-grey"></span>
              </a>
            </div>
          </div>
          <span className='card-created-date'>
            <DateTime createdDate={data.Created_on} updatedDate={data.Updated_on} />
          </span>
          {/* Please remvoe d-none class for hover card */}
        </div>
      </div >
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.deleteAlertMessage('Scenarios')}
        _id={data._id || data.Scenario_id}
        message={MESSAGES.DELETE_SCENARIO_CONFIRM_MESSAGE}
        cancleBtnText={LABELS.KEEP_IT_FOR_NOW}
        yesBtnText={LABELS.YES_DELETE}
        onConfirm={(_id) => handleRemove(_id ?? '')}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

interface IItem {
  label: string
  value: any
}

const DynamicListItems = (data: IDashboardScenario, currentEV: number, radiusInMiles: number) => {
  const items = listItems(data, currentEV, radiusInMiles);
  return renderListItems(items);
};

const renderListItems = (items: IItem[]) => (
  <ul className="list-item-wrapper">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item.label}: {item.value}
      </li>
    ))}
  </ul>
);

const listItems = (data: IDashboardScenario, currentEV: number, radiusInMiles: number) => {
  const ECONOMIC_PARAMETERS = data.metrics?.['ECONOMIC PARAMETERS'];
  switch (data.Subproject_type) {
    case 'ev':
      return [
        { label: 'Zip Code', value: data.metrics.zipcode },
        { label: 'Vehicle Fleet', value: formatAndRoundOffNumber(currentEV) },
        { label: 'POI Radius', value: formatAndRoundOffNumber(radiusInMiles) }
      ];
    case 'geothermal':
      return [
        { label: 'Capacity Factor', value: `${getValueWithUnit(ECONOMIC_PARAMETERS?.['Capacity factor'])}` },
        { label: 'NPV', value: `${getValueWithUnitFirst(ECONOMIC_PARAMETERS?.['Project NPV'])}` },
        { label: 'IRR', value: `${getValueWithUnit(ECONOMIC_PARAMETERS?.['Project IRR'])}` }
      ];
    default:
      return [
        { label: 'Carbon Tax', value: `$${formatAndRoundOffNumber(data.metrics.Carbon_Tax)}` },
        { label: 'NPV', value: `$${formatAndRoundOffNumber(data.metrics.NPV)}` },
        { label: 'Emissions Mitigated', value: `${formatAndRoundOffNumber(data.metrics['Carbon_Abatement_(MT)'])} mt` }
      ];
  }
};

export default ScenarioCard;
