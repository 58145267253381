import React from 'react';
import { Logo } from '../../utils';

function SignInSignUpStatic() {
  return (
    <div className="login-left">
      <span>
        <img src={Logo} alt="img dsider logo" className="dsider-logo" />
      </span>
      <h2>Accelerate your NetZero planning with ease and precision</h2>
      <ul className='sign-ul'>
        <div className='sign-li-wrap'>
          <span className='sign-li'>Comprehensive Solution</span>
          <span className='sign-li-des'>Operational, financial, and carbon metrics in a single platform.</span>
        </div>
        <div className='sign-li-wrap'>
          <span className='sign-li'>Informed Decision-Making</span>
          <span className='sign-li-des'>Scenario analysis for informed decisions.</span>
        </div>
        <div className='sign-li-wrap'>
          <span className='sign-li'>Seamless Collaboration</span>
          <span className='sign-li-des'>Collaborate effortlessly with internal and external stakeholders</span>
        </div>
        <div className='sign-li-wrap'>
          <span className='sign-li'>Optimize Outcomes</span>
          <span className='sign-li-des'>Compare metrics across scenarios to optimize results.</span>
        </div>
      </ul>
    </div>
  );
}

export default SignInSignUpStatic;
