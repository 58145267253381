import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SCENARIO_STATUS } from '../../constants';

interface RequestItemProps {
  userName: string
  scenarioName: string
  scenarioId: string
  request_status: string
}

const RequestItem = ({ userName, scenarioName, scenarioId, request_status }: RequestItemProps) => {
  const navigate = useNavigate();
  if (!scenarioId) {
    return <></>;
  }
  return (
    <div className="dropdown-wrapper">
      <span className="user-icon">{userName?.charAt(0)}</span>
      <div className="right-sec">
        <div className='see-simulation-wrap'>
          <h3 className="dropdown-head">{userName}
            <div className="tooltip-container">
              <div className={`${request_status === SCENARIO_STATUS.pending ? 'request-pending' : ''}`}></div>
              <div className="tooltip-text">Pending Request</div>
            </div>
          </h3>
        </div>
        <div className='dropdown-des-arrow-wrap'>
          <p className="dropdown-des">
            has requested approval of scenario, <span className='dropdown-des-bold'>{scenarioName}</span>
          </p>
          <div className="dropdown-footer">
            <Button className="btn-outline small" onClick={() => navigate(`/workbench?scenario_id=${scenarioId}&type=power`)}>
              <span className='icon-next-arrow-grey'></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
