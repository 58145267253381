import React, { useEffect } from 'react';
import { useGetScenarioDetailsQuery } from '../../redux/services/scenarioApis';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from '../../utils';
import { CREATE_FROM_SCRATCH, IScenarioDetails, MESSAGES, NUMBER } from '../../constants';
import EVWorkbench from './EVWorkbench';
import WorkBench from './Workbench';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hooks';
import EVWorkbenchScratch from './EVWorkbenchScratch';
import { WorkBenchProvider } from '../../hooks/useWorkbenchProvider';

const WorkbenchHOC = () => {
  const navigate = useNavigate();
  const unsavedChanges = useAppSelector(state => state.workbench.unsavedChanges);
  const unsavedChangesEV = useAppSelector(state => state.workBenchEV.unsavedChanges);
  const { movedToBaseLine } = useAppSelector(state => state.scenarioDetails);
  const searchParams = new URLSearchParams(window.location.search);
  const evScenarioType = searchParams.get('scenario_type') ?? '';
  const scenarioId = searchParams.get('scenario_id') ?? '';

  if (scenarioId === 'undefined') {
    setTimeout(() => {
      navigate(DASHBOARD);
    }, NUMBER.N500);
  }
  const { data: scenarioDetails, error, isSuccess: scenarioSuccess, isError: scenarioError, refetch } =
    useGetScenarioDetailsQuery(scenarioId, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges || unsavedChangesEV) {
        const message = MESSAGES.UNSAVED_CHANGES;
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges, unsavedChangesEV]);

  useEffect(() => {
    if (scenarioError) {
      setTimeout(() => {
        toast.error((error as any)?.details ?? MESSAGES.SCENARIO_IS_DELETED);
        navigate(DASHBOARD);
      }, NUMBER.N500);
    }
  }, [scenarioError, error]);

  useEffect(() => {
    if (scenarioSuccess && movedToBaseLine) {
      refetch();
    }
  }, [movedToBaseLine]);

  if (scenarioDetails && scenarioDetails.subProject_Type === 'ev') {
    return evScenarioType === CREATE_FROM_SCRATCH ? (
      <EVWorkbenchScratch
        scenarioId={scenarioId}
        scenarioDetails={scenarioDetails}
        scenarioSuccess={scenarioSuccess}
      />
    ) : (
      <EVWorkbench
        scenarioId={scenarioId}
        scenarioDetails={scenarioDetails}
        scenarioSuccess={scenarioSuccess}
      />
    );
  }
  return (
    <WorkBenchProvider>
      <WorkBench scenarioDetails={scenarioDetails as IScenarioDetails} scenarioSuccess={scenarioSuccess} />
    </WorkBenchProvider>
  );
};

export default WorkbenchHOC;
