import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { LABELS, NUMBER } from '../../constants';
import { ILatestActivitiesData } from '../../redux/slices/latestActivitiesSlice';
import { useGetArchiveActivitiesQuery } from '../../redux/services/latestActivitiesApi';
import ActivityGroupHOC from './ActivityGroupHOC';

interface LatestActivitiesProps {
  isLoading: boolean
}

export interface IActivitLogGroup {
  today: ILatestActivitiesData[]
  yesterday: ILatestActivitiesData[]
  lastWeek: ILatestActivitiesData[]
  older: ILatestActivitiesData[]
}

export const ActivityType = { latest: 'latest', archive: 'archive' };

const LatestActivities = ({ isLoading }: LatestActivitiesProps) => {
  const { latest, archive } = ActivityType;
  const { data: archiveActivities, isLoading: archiveLoading } = useGetArchiveActivitiesQuery('');
  const latestActivities = useAppSelector(state => state.latestActivities);
  const { firstName, lastName, email } = useAppSelector(state => state.auth);
  const [activityLog, setActivityLog] = useState<IActivitLogGroup>();
  const [archiveActivityLog, setArchiveActivityLog] = useState<IActivitLogGroup>();
  const [activeHeader, setActiveHeader] = useState(latest);

  useEffect(() => {
    const activities = getGroupedActivityLogs(latestActivities);
    setActivityLog(activities);
  }, [latestActivities]);

  useEffect(() => {
    const activities = getGroupedActivityLogs(archiveActivities as ILatestActivitiesData[]);
    setArchiveActivityLog(activities);
  }, [archiveActivities]);

  /**
   * @param activities list of activities...
   * @returns this will return the grouped activity data like { today: [], yesterday: [], lastWeek: [], older: [] }
   */
  const getGroupedActivityLogs = (activities: ILatestActivitiesData[]) => {
    const today = new Date();
    today.setHours(NUMBER.N0, NUMBER.N0, NUMBER.N0, NUMBER.N0);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - NUMBER.N1);
    yesterday.setHours(NUMBER.N0, NUMBER.N0, NUMBER.N0, NUMBER.N0);

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - NUMBER.N7);
    lastWeek.setHours(NUMBER.N0, NUMBER.N0, NUMBER.N0, NUMBER.N0);

    if (!activities?.length) {
      return { today: [], yesterday: [], lastWeek: [], older: [] };
    }

    // Filter activities and categorize them based on date
    return activities?.reduce((acc: IActivitLogGroup, activity) => {
      // add timezone offset...
      const activityDateTimeString = activity.dateTime + 'Z';
      const activityDate = new Date(activityDateTimeString);
      const activityObj = {
        ...activity,
        activity: activity.userName === email ? activity.activity.replace(`${firstName} ${lastName}`, 'You').replace('has', 'have') : activity.activity
      };
      if (activityDate >= today) {
        acc.today.push(activityObj);
      } else if (activityDate >= yesterday) {
        acc.yesterday.push(activityObj);
      } else if (activityDate >= lastWeek) {
        acc.lastWeek.push(activityObj);
      } else {
        acc.older.push(activityObj);
      }
      return acc;
    }, { today: [], yesterday: [], lastWeek: [], older: [] });
  };

  return (
    <>
      <div className="dashboard-right">
        <div className='activity-head-wrap'>
          <h4 className={`activity-head vertical_divider ${activeHeader === latest && 'active'}`} onClick={() => setActiveHeader(latest)}>Latest Activities</h4>
          <h4 className={`activity-head ${activeHeader === archive && 'active'}`} onClick={() => setActiveHeader(archive)}>Archive</h4>
        </div>
        <div className="dashboard-right-inner">
          {activeHeader === latest
            ? <>
              {latestActivities?.length === NUMBER.N0 && !isLoading && <span className='empty-activities'>{LABELS.NO_ACTIVITIES}</span>}
              <ActivityGroupHOC activityLog={activityLog as IActivitLogGroup} type={latest} />
            </>
            : <>
              {!Array.isArray(archiveActivities) && !archiveLoading && <span className='empty-activities'>{LABELS.NO_ARCHIVE_ACTIVITIES}</span>}
              <ActivityGroupHOC activityLog={archiveActivityLog as IActivitLogGroup} type={archive} />
            </>
          }
        </div >
      </div >
    </>
  );
};

export default LatestActivities;
