import EditProfile from '../pages/auth/EditProfile';
import Login from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import VerifyEmail from '../pages/auth/VerifyEmail';
import Dashboard from '../pages/dashboard/Dashboard';
import CreateProject from '../pages/dashboard/CreateProject';
import {
  ALL_PROJECT,
  CHANGE_PASSWORD,
  COMPARE_SCENARIOS,
  CREATE_PROJECT,
  CREATE_SCENARIO,
  DASHBOARD,
  EDIT_PROFILE,
  LOGIN,
  RESET_PASSWORD,
  SCENARIOS,
  SELECT_SCENARIO_TYPE,
  SIGNUP,
  VERIFY_EMAIL,
  WORKBENCH,
  CREATE_SCENARIO_WITH_AI,
  SET_PASSWORD,
  CREATE_SCENARIO_WITH_WIZARD
} from '../utils';
import AllProjectList from '../pages/dashboard/AllProjectList';
import ResetPassword from '../pages/auth/ResetPassword';
import AllScenarioList from '../pages/scenarios/AllScenarioList';
import ScenarioType from '../pages/scenarios/ScenarioType';
import CreateScenario from '../pages/scenarios/CreateScenario';
import ChangePassword from '../pages/auth/ChangePassword';
import CreateScenarioWithAI from '../pages/AI/CreateScenarioWithAI';
import WorkbenchHOC from '../pages/workbench/WorkbenchHOC';
import CompareScenerioHOC from '../pages/scenarios/CompareScenerioHOC';
import SetPassword from '../pages/auth/SetPassword';
import CreateScenarioWithWizard from '../pages/wizard/CreateScenarioWithWizard';

const UserRoutes = [
  { path: LOGIN, component: Login, isPrivate: false },
  { path: SIGNUP, component: SignUp, isPrivate: false },
  { path: DASHBOARD, component: Dashboard, isPrivate: true },
  { path: VERIFY_EMAIL, component: VerifyEmail, isPrivate: false },
  { path: CREATE_PROJECT, component: CreateProject, isPrivate: true },
  { path: ALL_PROJECT, component: AllProjectList, isPrivate: true },
  { path: EDIT_PROFILE, component: EditProfile, isPrivate: true },
  { path: RESET_PASSWORD, component: ResetPassword, isPrivate: false },
  { path: SCENARIOS, component: AllScenarioList, isPrivate: true },
  { path: SELECT_SCENARIO_TYPE, component: ScenarioType, isPrivate: true },
  { path: CREATE_SCENARIO, component: CreateScenario, isPrivate: true },
  { path: WORKBENCH, component: WorkbenchHOC, isPrivate: true },
  { path: CHANGE_PASSWORD, component: ChangePassword, isPrivate: true },
  { path: COMPARE_SCENARIOS, component: CompareScenerioHOC, isPrivate: true },
  { path: CREATE_SCENARIO_WITH_AI, component: CreateScenarioWithAI, isPrivate: true },
  { path: CREATE_SCENARIO_WITH_WIZARD, component: CreateScenarioWithWizard, isPrivate: true },
  { path: SET_PASSWORD, component: SetPassword, isPrivate: false }
];

export default UserRoutes;
