import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, Methods } from '../../constants';
import { interceptor } from '../../utils/interceptor';
import { ILatestActivitiesData } from '../slices/latestActivitiesSlice';

interface IMarkAsReadPayload {
  activity_id: string[]
  acknowledged: boolean
}

export const latestActivitiesApi = createApi({
  reducerPath: 'latestActivitiesApi',
  baseQuery: interceptor,
  tagTypes: ['LatestActivities', 'ArchiveActivities'],
  endpoints: (builder) => ({
    // api to get all latest activity...
    getLatestActivities: builder.query<any, string>({
      query: () => ({
        url: `${ApiEndpoints.GET_LATEST_ACTIVITIES}?archive=${false}`,
        method: Methods.GET
      }),
      providesTags: ['LatestActivities'],
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
        }
      }
    }),
    markAsReadLogs: builder.mutation<any, IMarkAsReadPayload>({
      query: (payload) => ({
        url: `${ApiEndpoints.MARK_AS_READ_LOGS}`,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['ArchiveActivities']
    }),
    // api to get all Archive activity...
    getArchiveActivities: builder.query<ILatestActivitiesData[], string>({
      query: () => ({
        url: `${ApiEndpoints.GET_LATEST_ACTIVITIES}?archive=${true}`,
        method: Methods.GET
      }),
      providesTags: ['ArchiveActivities']
    }),
    getRequests: builder.query<any, string>({
      query: () => ({
        url: ApiEndpoints.REQUESTS,
        method: Methods.GET
      }),
      transformResponse: (res: any) => {
        return res?.reverse();
      }
    })
  })
});

export const {
  useGetLatestActivitiesQuery,
  useMarkAsReadLogsMutation,
  useGetArchiveActivitiesQuery,
  useGetRequestsQuery
} = latestActivitiesApi;
