import { createSlice } from '@reduxjs/toolkit';
import { IInvitedApproverList } from '../services/requestApprovalApis';


export interface IApprovalStatus {
  approverList: IInvitedApproverList[]
  approvalStatus: string
}

const initialState: IApprovalStatus = {
  approverList: [],
  approvalStatus: ''
};

const approvalSlice = createSlice({
  name: 'approvalSlice',
  initialState,
  reducers: {
    updateApprovalData(state, payload) {
      return { ...state, ...payload.payload };
    },
    updateApproverList(state, payload) {
      return { ...state, approverList: payload.payload };
    },
    updateApproverStatus(state, payload) {
      return { ...state, approvalStatus: payload.payload };
    },
    resetApprovalState(state) {
      return { ...initialState };
    }
  }
});

export const { updateApprovalData, updateApproverList, updateApproverStatus, resetApprovalState } = approvalSlice.actions;

export default approvalSlice.reducer;
