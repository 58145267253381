import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { IForgotPassword } from '../../constants/interface';
import { EMAIL_VALIDATION_SCHEMA } from '../../utils/validation';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants/validationMessages';
import { forgotPassword } from '../../redux/auth/authReducer';
import InputElement from '../shared/InputElement';
import { hasFieldError } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { RESET_PASSWORD } from '../../utils';
import { useLazyValidateUserEmailQuery } from '../../redux/services/authApis';

interface ForgotPasswordProps {
  showModal: boolean
  closeModalHandler: () => void
  submitModalHandler: () => void
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.spinner.loading);
  const dispatch = useAppDispatch();
  const [validateUserEmail] = useLazyValidateUserEmailQuery();
  const initialFPValues: IForgotPassword = {
    email: ''
  };

  const handleFormSubmitt = (values: IForgotPassword) => {
    dispatch(startLoading(true));
    validateUserEmail(values.email)
      .then((res: any) => {
        if (res.isSuccess) {
          sendOTPHandler(values);
        } else {
          toast.error(res.error.data.detail);
          dispatch(stopLoading(false));
        }
      })
      .catch((err: any) => {
        toast.error(err.message);
        dispatch(stopLoading(false));
      });
  };

  const sendOTPHandler = (values: any) => {
    dispatch(forgotPassword(values))
      .unwrap()
      .then(data => {
        toast.success(MESSAGES.RESEND_CODE);
        props.submitModalHandler();
        dispatch(stopLoading(false));
        navigate(RESET_PASSWORD);
      })
      .catch(error => {
        toast.error(error);
        dispatch(stopLoading(false));
      });
  };

  return (
    <div
      className="forgot-ps-dropdown"
    >
      <div className="modal-content">
        <h4 className="modal-head">Forgot Your Password?</h4>
        <p className="modal-text">
          Please enter your registered email address & we’ll send you a verification code.
        </p>
        <Formik
          initialValues={initialFPValues}
          validationSchema={EMAIL_VALIDATION_SCHEMA}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => {
            handleFormSubmitt(values);
          }}
        >
          {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError }) => (
            <Form className="" onSubmit={handleSubmit}>
              {/* email */}
              <InputElement
                label='Email'
                placeholder="Enter email"
                required={true}
                type='text'
                value={values?.email}
                name='email'
                data-testid="email"
                onChange={handleChange}
                resetError={setFieldError}
                onBlur={handleBlur}
                hasError={hasFieldError(errors, touched, 'email')}
              />

              <div className="forgot-ps-footer">
                <Button
                  className="btn-no-outline"
                  onClick={props.closeModalHandler}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-medium"
                  type='submit'
                >{loading ? <Spinner /> : 'Send Code'}</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
