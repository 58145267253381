import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CheckBoxTab from './CheckBoxTab';
import { ICategoryList, ICompanyNameFormValues } from '../../../constants';
interface IPermissionTabs {
  values: ICompanyNameFormValues
  setFieldValue: any
  permissionsOptions: ICategoryList[]
  setFieldChanged: any
}

const PermissionTabs = ({ permissionsOptions, values, setFieldValue, setFieldChanged }: IPermissionTabs) => {
  return (
    <Tabs defaultActiveKey="consumers" id="uncontrolled-tab-example" className="">
      <Tab eventKey="consumers" title="Consumers">
        <CheckBoxTab
          options={permissionsOptions}
          selectedOptions={values.role_permissions.consumer}
          onSelectedOptionsChange={(options) => {
            setFieldValue('role_permissions.consumer', options);
            setFieldChanged(true);
          }}
          mainId='consumer'
        />
      </Tab>
      <Tab eventKey="collaborators" title="Collaborators">
        <CheckBoxTab
          options={permissionsOptions}
          selectedOptions={values.role_permissions.collaborator}
          onSelectedOptionsChange={(options) => {
            setFieldValue('role_permissions.collaborator', options);
            setFieldChanged(true);
          }}
          mainId='collaborators'
        />
      </Tab>
      <Tab eventKey="super user" title="Superuser">
        <CheckBoxTab
          options={permissionsOptions}
          selectedOptions={values.role_permissions.superuser}
          onSelectedOptionsChange={(options) => {
            setFieldValue('role_permissions.superuser', options);
            setFieldChanged(true);
          }}
          mainId='superuser'
        />
      </Tab>
    </Tabs>
  );
};

export default PermissionTabs;
