import React, { useEffect, useState } from 'react';
import DropDownMenu from './DropDownMenu';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ConfirmationAlert from './ConfirmationAlert';
import { IScenarioProps } from '../dashboard/ScenarioListCard';
import { IScenarioDetails, MESSAGES, SHARED_SCENARIO_PERMISSION, USER_PERMISSIONS } from '../../constants';
import { LABELS } from '../../constants/labelConstant';
import { useScenario } from '../../hooks/useScenario';
import { useGetDetailsMutation } from '../../redux/services/scenarioApis';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
import { useExportJson } from '../../hooks/useExportJson';
import { useNavigate } from 'react-router-dom';
import { CREATE_SCENARIO, modifyScenarioDataForShare } from '../../utils';
import ScenarioSharedList from './ScenarioSharedList';
import ScenarioMoveList from './ScenarioMoveList';

const ScenarioDropdownList = (props: IScenarioProps) => {
  const { data, refreshData, whiteThreeDots, showConfimationAlert, refreshActivity, isSharedWithMeFolder, isBaseLine } = props;
  const { deleteScenario, duplicateScenario } = useScenario();
  const { exportJsonData } = useExportJson();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [getScenarioDetails, { data: scenarioData, isSuccess }] = useGetDetailsMutation();
  const userData = useAppSelector(state => state.auth);
  const [showShareModal, setShowShareModal] = useState(false);
  const [scenarioID, setScenarioId] = useState<string>('');
  const [moveScenarioID, setMoveScenarioId] = useState<string>('');
  const [showMoveModal, setShowMoveModal] = useState(false);
  const isSharedOrBaseLineScenario = !!data.permission || data.BaselineStatus;
  const isSharedScenario = !!data.permission;
  const hasSharePermission = userData.permissions?.includes(USER_PERMISSIONS.share_scenario);
  const hasExportJsonPermission = userData.permissions?.includes(USER_PERMISSIONS.import_JSON);

  useEffect(() => {
    if (isSuccess) {
      dispatch(stopFullLoading());
      if (scenarioData?.exportJSON) {
        exportJsonData(scenarioData);
      } else {
        toast.error(MESSAGES.NO_EXPORT_DATA);
      }
    }
  }, [isSuccess]);

  function handleRemove(id: string) {
    setShowAlert(false);
    deleteScenario(id, refreshData);
  };

  const duplicate = (scenario: IScenarioDetails) => {
    duplicateScenario(scenario.subProjectID ?? scenario._id ?? scenario.Scenario_id, scenario, refreshData, isSharedWithMeFolder);
  };

  const exportJson = () => {
    dispatch(startFullLoading());
    getScenarioDetails(data._id as string ?? data.Scenario_id);
  };

  const goToEditScenario = () => {
    navigate(`${CREATE_SCENARIO}?scenario_id=${data._id ?? data.Scenario_id}`);
  };

  const showConfirmationAlert = () => {
    showConfimationAlert ? showConfimationAlert() : setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const menuOptions = [
    {
      name: 'Share Scenario',
      onClickFn: () => {
        setScenarioId(data._id ?? data.Scenario_id as string);
        setShowShareModal(true);
      },
      disabled: !!data.permission || !hasSharePermission // hide share scenario for shared scenarios
    },
    {
      name: 'Move Scenario',
      onClickFn: () => {
        setMoveScenarioId(data._id ?? data.Scenario_id as string);
        setShowMoveModal(true);
      },
      disabled: isSharedOrBaseLineScenario
    },
    {
      name: 'Export JSON',
      onClickFn: exportJson,
      disabled: !hasExportJsonPermission || isSharedScenario
    },
    {
      name: 'Duplicate Scenario',
      onClickFn: duplicate,
      disabled: data.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR || isBaseLine // hide for collaborator
    },
    {
      name: 'Edit Scenario',
      onClickFn: goToEditScenario,
      disabled: isSharedOrBaseLineScenario // hide edit scenario for shared scenarios
    },
    {
      name: 'Remove Scenario',
      onClickFn: showConfirmationAlert,
      optionClassName: 'text-danger',
      disabled: isSharedOrBaseLineScenario // hide remove scenario for shared scenarios
    },
    {
      name: 'Un-share Scenario',
      onClickFn: showConfirmationAlert,
      optionClassName: 'text-danger',
      disabled: !isSharedWithMeFolder && !data.permission // show unshare scenario only for shared folder
    }
  ];

  return (
    <>
      <DropDownMenu
        options={menuOptions}
        className=""
        optionLabel='Options'
        data={data}
        whiteThreeDots={whiteThreeDots}
      />

      <ScenarioMoveList
        showModal={showMoveModal}
        setShowModal={setShowMoveModal}
        scenerioID={scenarioID}
        refreshActivity={refreshActivity}
        scenarioData={modifyScenarioDataForShare(data.name ?? data.Scenario_name, data.userCreator ?? data.Created_by, data.Scenario_id ?? data.id)}
        moveScenarioID={moveScenarioID}
        refreshData={refreshData}
      />

      <ScenarioSharedList
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        scenerioID={scenarioID}
        refreshActivity={refreshActivity}
        approvalStatus={data.approvalStatus ?? ''}
        scenarioData={modifyScenarioDataForShare(data.name ?? data.Scenario_name, data.userCreator ?? data.Created_by, data.Scenario_id ?? data.id)}
      />

      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.deleteAlertMessage('Scenario')}
        _id={data._id ?? data.Scenario_id}
        message={MESSAGES.DELETE_SCENARIO_CONFIRM_MESSAGE}
        cancleBtnText={LABELS.KEEP_IT_FOR_NOW}
        yesBtnText={LABELS.YES_DELETE}
        onConfirm={(_id) => handleRemove(_id ?? '')}
        onCancel={hideConfirmationAlert}
      />
    </>
  );
};

export default ScenarioDropdownList;
