import { createSlice } from '@reduxjs/toolkit';
import { IScenarioDetails } from '../../constants';

const initialState: IScenarioDetails = {
  _id: '',
  projectID: '',
  name: '',
  description: '',
  userCreator: '',
  subProject_Type: '',
  createDate: '',
  updateDate: '',
  id: '',
  approvalStatus: '',
  BaselineStatus: false,
  movedToBaseLine: false,
  location: ''
};

const scenarioDetailsSlice = createSlice({
  name: 'scenarioDetails',
  initialState,
  reducers: {
    updateScenarioDetails: (state, payload) => {
      return { ...state, ...payload.payload };
    },
    updateBaseLineStatus: (state, payload) => {
      return { ...state, BaselineStatus: payload.payload, movedToBaseLine: true };
    },
    updateUserLocation: (state, payload) => {
      return { ...state, location: payload.payload };
    }
  }
});

export const { updateScenarioDetails, updateBaseLineStatus, updateUserLocation } = scenarioDetailsSlice.actions;

export default scenarioDetailsSlice.reducer;
