import React, { useEffect, useState } from 'react';
import CompanyModal from './CompanyModal';
import Table from '../../table/Table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { companyColumns, companySortableFields } from '../../../constants/admin.static';
import { useGetAllCompanyQuery, useRemoveCompanyMutation, useUpdateCompanyStatusMutation } from '../../../redux/services/admin/companyManagementApis';
import { updateSingleCompany, updateTotalCompanyData } from '../../../redux/slices/adminSlice';
import { toast } from 'react-toastify';
import { LABELS, MESSAGES } from '../../../constants';
import ModalHoc from '../shared/ModalHoc';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import { capitalizeFirstLetter, statusTextButton, statusTextTitleCompany } from '../../../utils';
import CompanyUserList from './CompanyUserList';

const CompanyManagementTable = () => {
  const [companyUserModal, setCompanyUserModal] = useState(false);
  const [ShowCompanyModal, setShowCompanyModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showStatusAlert, setShowStatusAlert] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);
  const { companySearch, singleCompany } = useAppSelector((state: any) => state.adminSlice);
  const { data, isLoading } = useGetAllCompanyQuery('', { refetchOnMountOrArgChange: true });
  const dispatch = useAppDispatch();
  const [removeCompany] = useRemoveCompanyMutation();
  const [updateCompanyStatus] = useUpdateCompanyStatusMutation();

  useEffect(() => {
    data && setFilterData(data.filter((item: any) => {
      const search = companySearch?.toLowerCase();
      return (
        item['COMPANY NAME'].toLowerCase()?.includes(search) ||
        item?.email.toLowerCase()?.includes(search)
      );
    }));

    return () => {
      setFilterData(data);
    };
  }, [companySearch]);

  useEffect(() => {
    if (data) {
      setFilterData(data);
      dispatch(updateTotalCompanyData(data.length));
    }
  }, [isLoading, data]);

  const handleCompanyData = (datas: any) => {
    dispatch(updateSingleCompany(datas));
  };

  const handleUserStatus = () => {
    hideStatusConfirmationAlert();
    const formData = {
      companyId: singleCompany._id,
      flag: singleCompany.status === 'active' ? 'deactivate' : 'activate'
    };
    updateCompanyStatus(formData).unwrap().then((res: any) => {
      setShowCompanyModal(false);
      const displayMessage = res.message.split('\n').map((line: any, index: any) => (
        <React.Fragment key={index}>
          {capitalizeFirstLetter(line)}
          <br />
        </React.Fragment>
      ));
      toast.success(<>{displayMessage}</>);
    }).catch(() => {});
  };

  const handleDeleteUser = () => {
    hideConfirmationAlert();
    removeCompany(singleCompany._id).unwrap().then((res: any) => {
      setShowCompanyModal(false);
      toast.success(MESSAGES.COMPANY_DELETED_SUCCESS);
    }).catch(() => {});
  };

  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const showStatusConfirmationAlert = () => {
    setShowStatusAlert(true);
  };

  const hideStatusConfirmationAlert = () => {
    setShowStatusAlert(false);
  };

  const showCompanyUserModel = () => {
    setCompanyUserModal(true);
  };

  const hideCompanyUserModel = () => {
    setCompanyUserModal(false);
  };

  useEffect(() => {
    if (singleCompany?._id && data) {
      const singleCompanyData = data.find((item: any) => item._id === singleCompany._id);
      if (singleCompanyData) {
        dispatch(updateSingleCompany(singleCompanyData));
      }
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Table
        data={filterData}
        columns={companyColumns}
        sortableFields={companySortableFields}
        setShowUserCompany={setShowCompanyModal}
        tableClass='company-management-table admin-table-container'
        userSearch={companySearch}
        handleUserData={handleCompanyData}
        tableType={'companies'}
        handleUserStatus={handleUserStatus}
        handleDeleteUser={handleDeleteUser}
        showConfirmationAlert={showConfirmationAlert}
        showStatusConfirmationAlert={showStatusConfirmationAlert}
        showCompanyUserModel={showCompanyUserModel}
      />
      <CompanyModal ShowCompanyModal={ShowCompanyModal} setShowCompanyModal={setShowCompanyModal} handleUserStatus={showStatusConfirmationAlert}
        handleDeleteUser={showConfirmationAlert} />
      <ModalHoc
        showModal={showAlert} setShowModal={hideConfirmationAlert}
      >
        <ConfirmationAlert
          showAlert={showAlert}
          title={LABELS.DELETE_COMPANY}
          _id={singleCompany.userId}
          message={MESSAGES.DELETE_COMPANY_MESSAGE}
          cancleBtnText={LABELS.NOT_NOW}
          yesBtnText={LABELS.YES_DELETE}
          onConfirm={handleDeleteUser}
          onCancel={hideConfirmationAlert}
        />
      </ModalHoc>

      <ModalHoc
        showModal={showStatusAlert} setShowModal={hideStatusConfirmationAlert}
      >
        <ConfirmationAlert
          showAlert={showStatusAlert}
          title={statusTextTitleCompany(singleCompany.status)}
          _id={singleCompany.userId}
          message={singleCompany.status === 'active' ? MESSAGES.DEACTIVATE_COMPANY_STATUS_MESSAGE : MESSAGES.ACTIVATE_COMPANY_STATUS_MESSAGE}
          cancleBtnText={LABELS.NOT_NOW}
          yesBtnText={statusTextButton(singleCompany.status)}
          onConfirm={handleUserStatus}
          onCancel={hideStatusConfirmationAlert}
        />
      </ModalHoc>
      {companyUserModal ? <CompanyUserList hideCompanyUserModel={hideCompanyUserModel}
        companyUserModal={companyUserModal}/> : <></>}
    </>
  );
};

export default CompanyManagementTable;
