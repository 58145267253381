import Login from './pages/auth/SignIn';
import AllObject from './pages/html/AllObject';
import CreateModal from './pages/html/CreateModal';
import DsiderTab from './pages/html/DsiderTab';
import WorkBench from './pages/html/WorkBench';
import CustomSelect from './pages/html/CustomSelect';
import EditProjectDetails from './pages/html/EditProjectDetails';
import WorkBenchEmpty from './pages/html/WorkBench-Empty';
import CompareModal from './pages/html/CompareModal';
import EVDashboard from './pages/html/EVDashboard';
import Dashboard from './pages/html/Dashboard';
import AllProject from './pages/html/AllProject';
import AdminDashboard from './pages/html/AdminDashboard';
import CompanyManagement from './pages/html/CompanyManagement';
import DsiderPagination from './pages/html/DsiderPagination';
import ObjectManagement from './pages/html/ObjectManagement';
import EditProfile from './pages/html/EditProfile';
import CreateScenario from './pages/html/CreateScenario';
import WizardQuestion from './pages/html/WizardQuestion';
import OnboardUser from './pages/html/OnboardUser';
import HeaderWorkbenchHtml from './pages/html/HeaderWorkbenchHtml';
import AdminLogin from './pages/html/AdminLogin';
import RadioSelectCheckBox from './pages/html/RadioSelectCheckBox';
import MultiSelectCheckBox from './pages/html/MultiSelectCheckBox';
import ThemeCheckBox from './pages/html/ThemeCheckBox';
import DefaultCheckBox from './pages/html/DefaultCheckBox';
import SelectCheckBox from './pages/html/SelectCheckBox';
import UserProfile from './pages/html/UserProfile';
import UserProfileLeft from './pages/html/UserProfileLeft';
import UserProfileRight from './pages/html/UserProfileRight';
import RadioCheckBox from './pages/html/RadioCheckBox';



const htmlRoutes = [
  { path: '/html-login', component: Login },
  { path: '/html-all-object', component: AllObject },
  { path: '/html-CreateModal', component: CreateModal },
  { path: '/html-DsiderTab', component: DsiderTab },
  { path: '/html-WorkBench', component: WorkBench },
  { path: '/html-CustomSelect', component: CustomSelect },
  { path: '/html-EditProjectDetails', component: EditProjectDetails },
  { path: '/html-WorkBenchEmpty', component: WorkBenchEmpty },
  { path: '/html-CompareModal', component: CompareModal },
  { path: '/html-EVDashboard', component: EVDashboard },
  { path: '/html-Dashboard', component: Dashboard },
  { path: '/html-AllProject', component: AllProject },
  { path: '/html-AdminDashboard', component: AdminDashboard },
  { path: '/html-CompanyManagement', component: CompanyManagement },
  { path: '/html-DsiderPagination', component: DsiderPagination },
  { path: '/html-ObjectManagement', component: ObjectManagement },
  { path: '/html-EditProfile', component: EditProfile },
  { path: '/html-CreateScenario', component: CreateScenario },
  { path: '/html-WizardQuestion', component: WizardQuestion },
  { path: '/html-OnboardUser', component: OnboardUser },
  { path: '/html-HeaderWorkbenchHtml', component: HeaderWorkbenchHtml },
  { path: '/html-AdminLogin', component: AdminLogin },
  { path: '/html-RadioSelectCheckBox', component: RadioSelectCheckBox },
  { path: '/html-MultiSelectCheckBox', component: MultiSelectCheckBox },
  { path: '/html-ThemeCheckBox', component: ThemeCheckBox },
  { path: '/html-DefaultCheckBox', component: DefaultCheckBox },
  { path: '/html-SelectCheckBox', component: SelectCheckBox },
  { path: '/html-UserProfile', component: UserProfile },
  { path: '/html-UserProfileLeft', component: UserProfileLeft },
  { path: '/html-UserProfileRight', component: UserProfileRight },
  { path: '/html-RadioCheckBox', component: RadioCheckBox },


];

export default htmlRoutes;
