import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_USER_MANAGEMENT, ADMIN_COMPANY_MANAGEMENT, ADMIN_OBJECT_MANAGEMENT, adminDsiderLogo, LOGIN } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetAdminSearch, updateSignOutUserState } from '../../redux/slices/adminSlice';
import { changeSpinnerTitle, stopFullLoading } from '../../redux/SpinnerSlice';
import { LOADER_TITLE, MESSAGES } from '../../constants';
import { signOutUser } from '../../redux/auth/authReducer';
import { toast } from 'react-toastify';
import ConfirmationAlert from '../shared/ConfirmationAlert';

const AdminHoc = ({ children }: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signOutUserState } = useAppSelector(state => state.adminSlice);
  const activeTab = (path: string) => {
    return pathname === path;
  };
  const body = document.getElementsByTagName('body')[0];
  useEffect(() => {
    dispatch(resetAdminSearch());
  }, [pathname]);

  const handleSignOutClick = () => {
    dispatch(changeSpinnerTitle(LOADER_TITLE.LOGGING_OUT));
    dispatch(signOutUser())
      .unwrap()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        body.classList.remove('body_overflow');
        navigate(LOGIN);
        dispatch(updateSignOutUserState(false));
        dispatch(stopFullLoading());
      })
      .catch((error: any) => {
        toast.error(error);
        dispatch(updateSignOutUserState(false));
        dispatch(stopFullLoading());
      });
  };


  useEffect(() => {
    if (signOutUserState) {
      body.classList.add('body_overflow');
    } else {
      body.classList.remove('body_overflow');
    }
  }, [signOutUserState]);

  return (
    <>
      <div className='admin-sidebar' style={{ overflow: 'hidden' }}>
        <div className='dsider-logo-wrap'><img src={adminDsiderLogo} alt="logo img" onClick={() => navigate(ADMIN_USER_MANAGEMENT)}/></div>
        <Nav variant="tabs" className='admin-sidebar-wrap' defaultActiveKey={pathname}>
          <Nav.Item>
            <Nav.Link active={activeTab(ADMIN_USER_MANAGEMENT)} onClick={() => navigate(ADMIN_USER_MANAGEMENT)}>User Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={activeTab(ADMIN_COMPANY_MANAGEMENT)} onClick={() => navigate(ADMIN_COMPANY_MANAGEMENT)}>Company Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={activeTab(ADMIN_OBJECT_MANAGEMENT)} onClick={() => navigate(ADMIN_OBJECT_MANAGEMENT)}>Object Management</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      {children}
      {signOutUserState && (
        <ConfirmationAlert
          showAlert={signOutUserState}
          title="Sign Out?"
          message={MESSAGES.SIGNOUT_MESSAGE}
          yesBtnText="Yes, Sign Out"
          cancleBtnText="Not Now"
          onConfirm={handleSignOutClick}
          onCancel={() => dispatch(updateSignOutUserState(false))}
        />
      )}
    </>
  );
};

export default AdminHoc;
