import React from 'react';
import { getProjectTypeLogo } from '../../utils';

function ProjectTypeAvatar({ data }: any) {
  return (
    <>
      <span className="user-icon medium">
        <img src={getProjectTypeLogo(data.subProject_Type as string)} alt="logo" className="user-img" />
      </span>
    </>
  );
}

export default ProjectTypeAvatar;
