import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { colourOptions } from '../../utils/defaultData';
import Select from "react-select";
import CustomSelect from "./CustomSelect";

function SelectCheckBox() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'KWH', label: 'KWH' },
    { value: 'Percentage (%)', label: 'Percentage (%)' },
    { value: 'Dollars ($)', label: 'Dollars ($)' },

  ];

  return (
    <div className="checkbox-wrapper">
      <Form.Check
        type="checkbox"
        id="default-checkbox"
        label="Select All"
        name="companycheckbox"
      />


    </div>
  );
}
export default SelectCheckBox;
