import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IProject, IProjectDetails, Methods, PROJECT_TYPE_LABELS } from '../../constants';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';
import { interceptor } from '../../utils/interceptor';

interface IProjectTypes {
  project_types: string[]
}

interface IProjectId {
  project_id: string | undefined
}

interface IUpdateProjectPayload {
  name: string
  description: string
  project_id: string
}

interface ICompareScenarioPayload {
  type: string
  id: string
}

export interface ISimulationCountRes {
  baseline_scenario_count: number
  company_baseline_scenario_count: number
  total_abatement: number
  company_total_abatement: number
}

export const projectApis = createApi({
  reducerPath: 'projectApis',
  baseQuery: interceptor,
  tagTypes: ['GetProject', 'GetProjectType', 'GetProjectDetails'],
  endpoints: (builder) => ({

    // api to get project types...
    getProjectTypes: builder.query<IProjectTypes, void>({
      query: () => ({
        url: ApiEndpoints.GET_PROJECT_TYPE,
        method: Methods.GET
      }),
      providesTags: ['GetProjectType']
    }),

    // api to save Project Data...
    saveProject: builder.mutation<IProject, Partial<IProject>>({
      query: (payload) => ({
        url: ApiEndpoints.CREATE_PROJECT,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetProject']
    }),

    // api to get all projects...
    getAllProjects: builder.query<IProjectDetails[], void>({
      query: () => ({
        url: ApiEndpoints.GET_ALL_PROJECTS,
        method: Methods.GET
      }),
      transformResponse: (res: IProjectDetails[]) => {
        return res.reverse();
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetProject']
    }),

    // api to get all projects...
    getProjectDetails: builder.query<IProject, string>({
      query: (projectId) => ({
        url: `${ApiEndpoints.GET_PROJECT_DETAILS}?project_id=${projectId}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetProjectDetails']
    }),

    // api to get all projects...
    removeProject: builder.mutation<IProject, IProjectId>({
      query: (payload) => ({
        url: ApiEndpoints.REMOVE_PROJECT,
        method: Methods.DELETE,
        body: payload
      }),
      invalidatesTags: ['GetProject']
    }),

    // api call to update Project Details...
    updateProject: builder.mutation<IProject, IUpdateProjectPayload>({
      query: (payload: IUpdateProjectPayload) => ({
        url: `${ApiEndpoints.EDIT_PROJECT}?project_id=${payload.project_id}`,
        method: Methods.POST,
        body: { name: payload.name, description: payload.description }
      }),
      invalidatesTags: ['GetProject', 'GetProjectDetails']
    }),

    // api to get all projects...
    getSimulationCount: builder.query<ISimulationCountRes, string>({
      query: (userEmail) => ({
        url: `${ApiEndpoints.GET_SIMULATION_COUNT}?user_email=${userEmail}`,
        method: Methods.GET
      })
    }),

    // api to get all scenarios and shared scenarios of that selected project type
    getProjectAndSharedScenarios: builder.query<any, ICompareScenarioPayload>({
      query: (payload) => ({
        url: `${ApiEndpoints.GET_PROJECT_AND_SHARED_SCENARIOS}?projectType=${payload.type}&projectID=${payload.id}`,
        method: Methods.GET
      }),
      transformResponse(response: any[], meta, arg) {
        return response.map(s => {
          return {
            ...s,
            isSharedScenario: s.projectID !== arg.id
          };
        });
      }
    }),
    getFormProjectType: builder.query<IProjectTypes, void>({
      query: () => ({
        url: ApiEndpoints.GET_PROJECT_TYPE,
        method: Methods.GET
      }),
      transformResponse: async (res: any) => {
        return res.project_types.map((type: string) => ({
          label: PROJECT_TYPE_LABELS[type],
          value: type
        }));
      },
      providesTags: ['GetProjectType']
    })
  })
});

export const {
  useGetProjectTypesQuery,
  useSaveProjectMutation,
  useGetAllProjectsQuery,
  useRemoveProjectMutation,
  useGetSimulationCountQuery,
  useGetProjectDetailsQuery,
  useUpdateProjectMutation,
  useLazyGetProjectAndSharedScenariosQuery,
  useGetFormProjectTypeQuery
} = projectApis;
