import React, { useState } from 'react';
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import InvitePeople from '../dashboard/InvitePeople';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IForgotPassword, ISharedUserList, MESSAGES, NUMBER, SHARED_SCENARIO_PERMISSION } from '../../constants';
import { EMAIL_VALIDATION_SCHEMA, hasFieldError } from '../../utils';
import { Formik } from 'formik';
import { useShareScenarioMutation } from '../../redux/services/scenarioApis';
import { toast } from 'react-toastify';
import InputElement from './InputElement';
import { updateSharedUserList } from '../../redux/slices/sharedUserSlice';

interface IScenarioSharedProps {
  showModal: boolean
  setShowModal: (show: boolean) => void
  scenerioID?: string
  refreshActivity?: () => void
  approvalStatus: string
  scenarioData?: any
}

function ScenarioSharedList(props: IScenarioSharedProps) {
  const { showModal, setShowModal, refreshActivity, scenarioData, approvalStatus } = props;
  const { COLLABORATOR, CONSUMER } = SHARED_SCENARIO_PERMISSION;
  // If Scenarios approval process is started then set share permission as collaborator only...
  const [permission, setPermission] = useState<any>(!approvalStatus ? COLLABORATOR : CONSUMER);
  const dispatch = useAppDispatch();
  const authData = useAppSelector(state => state.auth);
  const workbenchData = useAppSelector(state => {
    if (state.workBenchEV?.scenarioDetails?._id) {
      return state.workBenchEV;
    } else {
      return state.workbench;
    }
  });
  const sharedList = useAppSelector(state => state.sharedUserList);
  const [shareScenarioMutation, { isLoading }] = useShareScenarioMutation();
  const [initialValues, setInitialValues] = useState<IForgotPassword>({ email: '' });

  const handleFormSubmit = (values: IForgotPassword, userPermission?: string | null, resetForm?: any) => {
    // check for sharing himself...
    if (values.email === authData.email) {
      toast.error(MESSAGES.CANNOT_SHARE_YOURSELF);
      resetForm?.({ values: { email: '' } });
      return;
    }
    const existingListIndex = sharedList?.findIndex((ele: ISharedUserList) => {
      const role = userPermission ?? permission;
      return (ele.sharedToUserName === values.email?.trim() && ele.permission === role);
    });
    if (existingListIndex !== -NUMBER.N1) {
      toast.error(MESSAGES.ALREADY_SHARED);
      resetForm?.({ values: { email: '' } });
      return;
    }
    const payload = {
      userNameToShare: values.email?.trim(),
      permission: userPermission ?? permission,
      duplicate: 'false',
      scenarioId: props.scenerioID ?? workbenchData?.scenarioDetails?.id
    };
    shareScenarioMutation(payload)
      .then((res: any) => {
        const data = {
          permission: userPermission ?? permission,
          sharedByUserName: authData.email,
          sharedToUserName: values.email,
          subProjectID: workbenchData?.scenarioDetails?.id,
          subProjectName: workbenchData?.scenarioDetails?.name
        };
        if (res.data) {
          const existingDataIndex = sharedList?.findIndex((ele: ISharedUserList) => ele.sharedToUserName === values.email);
          const dataToUpdate: ISharedUserList[] = [...sharedList];
          if (existingDataIndex >= NUMBER.N0) {
            dataToUpdate[existingDataIndex] = data;
          } else {
            dataToUpdate.push(data);
          }
          dispatch(updateSharedUserList(dataToUpdate));
          toast.success(res.data.warning ? MESSAGES.USER_NOT_SIGNED_UP_YET : MESSAGES.SHARED_SUCESS);
          resetForm?.({ values: { email: '' } });
          refreshActivity && refreshActivity();
        } else if (res.error) {
          toast.error(res.error.detail);
        }
      })
      .catch((err: any) => {
        toast.error(err.message);
        resetForm?.({ values: { email: '' } });
      });
  };

  const handleSelect = (e: any) => {
    setPermission(e);
  };

  const updatePermission = (data: any, assignedPermission: string) => {
    handleFormSubmit(data, assignedPermission);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-450 top-right-modal"
        className="forget-modal setting-modal share-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Invite people to Collaborate
          </h4>
          <p className="modal-title-des mb-25">
            on the scenario:{' '}
            <span className="title-des-bold">{workbenchData?.scenarioDetails?.name || scenarioData?.Scenario_name}</span>
          </p>
          <div className="tag-input">
            <Formik
              initialValues={initialValues}
              validationSchema={EMAIL_VALIDATION_SCHEMA}
              onSubmit={(values, { resetForm }) => {
                handleFormSubmit(values, null, resetForm);
              }}>
              {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError }) => (
                <Form className="tag-input-form" onSubmit={handleSubmit}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <span className="icon-search-logo"></span>
                    {/* email */}
                    <InputElement
                      label='Email'
                      required={true}
                      type='text'
                      name='email'
                      data-testid="email"
                      onChange={handleChange}
                      resetError={setFieldError}
                      onBlur={handleBlur}
                      hasError={hasFieldError(errors, touched, 'email')}
                      className="share-search-input"
                      placeholder="Email"
                      value={values.email}
                    />
                    <Dropdown className="create-modal-dropdown" onSelect={handleSelect}>
                      <Dropdown.Toggle id="dropdown-basic" disabled={!!approvalStatus}>
                        {permission === SHARED_SCENARIO_PERMISSION.CONSUMER
                          ? SHARED_SCENARIO_PERMISSION.CONSUMER_ROLE
                          : SHARED_SCENARIO_PERMISSION.COLLABORATOR_ROLE}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={CONSUMER}>Consumer</Dropdown.Item>
                        <Dropdown.Item eventKey={COLLABORATOR}>Collaborator</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Button className="btn-primary no-radius" type="submit">
                    {isLoading ? <Spinner /> : 'Send'}
                  </Button>
                </Form>
              )}
            </Formik>

          </div>
          <InvitePeople
            id={workbenchData?.scenarioDetails?.id || props.scenerioID}
            workbenchData={workbenchData}
            handleForm={updatePermission}
            approvalStatus={approvalStatus}
            scenarioData={scenarioData} ></InvitePeople>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScenarioSharedList;
