import React from 'react';
import { QuestionListCard, TextElement } from '../../pages/wizard/WizardQuestionType';
import { Button } from 'react-bootstrap';
import { IQuestionProps } from '../../pages/wizard/wizardInterface';
import { NUMBER } from '../../constants';

export const DemandSupplyQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion } = porps;

  const isElectricPowerDemand = que.mapping === 'Demand';

  const updateDemandSupply = (value: string) => {
    setAnswersList(() => ({
      ...answersList,
      supplyDemand: +value
    }));
  };

  return (
    <div className="wizard-ques-main" key={`${que.question}-${index}`}>
      <QuestionListCard question={que.question} />
      <div className='wizard-ques-input-wrap'>
        {
          que.answers?.map((powerSource, ind) => (
            <TextElement defaultValue={answersList.supplyDemand} updateValue={updateDemandSupply} powerSource={powerSource}
              unit={isElectricPowerDemand ? 'Kwh' : 'Kg/hr'} key={`ques-${ind}`} />
          ))
        }
        <Button disabled={!answersList.supplyDemand} className='shadow' onClick={() => submitQuestion(index + NUMBER.N1)}>Submit</Button>
      </div>
    </div>
  );
};
