import { createSlice } from '@reduxjs/toolkit';
import { ISharedUserList } from '../../constants';

const initialState: ISharedUserList[] = [];

const sharedUserSlice = createSlice({
  name: 'sharedUserList',
  initialState,
  reducers: {
    updateSharedUserList: (state, payload) => {
      return [...payload.payload];
    }
  }
});

export const { updateSharedUserList } = sharedUserSlice.actions;

export default sharedUserSlice.reducer;
