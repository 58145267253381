import React, { FC } from 'react';
import { NUMBER } from '../../constants';
import { formatAndRoundOffNumber, formatIntegerOnly, formatNumberToLocale } from '../../utils';

interface Props {
  columnSpacing: number
  zipCode: string
  metricsData: any
  title: string
}

const CompareMericsRow: FC<Props> = ({ columnSpacing, zipCode, metricsData, title }) => {
  const getValueOf = (keyname: string) => Number(metricsData?.find((metric: any) => metric.name === keyname)?.value || NUMBER.N0) ?? '-';
  const findFleetSize = metricsData?.find((metric: any) => metric?.name === 'traffic_adjusted_fleet_size_2023');
  let currentEV = NUMBER.N0;
  if (findFleetSize) {
    currentEV = Number(getValueOf('traffic_adjusted_fleet_size_2023'));
  } else {
    currentEV = Number(getValueOf('fleet_size_2023'));
  }
  const projectedEV = Number(getValueOf('fleet_size_2035'));
  return (
    <div className={`col-md-${columnSpacing} varticle-border`}>
      <div className="bp-th">{title}</div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Zipcode</span>
        <span className='td-text td-value'>{zipCode}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Traffic Adjusted Fleet Size (Current)</span>
        <span className='td-text td-value'>{formatIntegerOnly(currentEV)}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Traffic Adjusted Fleet Size (Projected)</span>
        <span className='td-text td-value'>{formatIntegerOnly(projectedEV)}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>EV Growth Rate</span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('fleet_size_pct_change')))}%</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Demand Totals (kWh)<span className='td-label-head'>Avg Weekday</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_weekday_demand')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Demand Totals (kWh)<span className='td-label-head'>Avg Weekend</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_weekend_demand')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Demand Totals (kWh)<span className='td-label-head'>Avg Week</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_week_demand')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Visits Totals <span className='td-label-head'>Avg Weekday</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_weekday_visits')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Visits Totals <span className='td-label-head'>Avg Weekend</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_weekend_visits')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>
          Visits Totals <span className='td-label-head'>Avg Week</span>
        </span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('avg_week_visits')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Estimated Demand/Visit (kWh)</span>
        <span className='td-text td-value'>{formatAndRoundOffNumber(getValueOf('avg_demand_per_visit'))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Households</span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('households')))}</span>
      </div>
      <div className='td-inner-wrap'>
        <span className='td-label'>Population</span>
        <span className='td-text td-value'>{formatIntegerOnly(Number(getValueOf('population')))}</span>
      </div>
    </div>
  );
};

export default CompareMericsRow;
