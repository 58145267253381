import { createSlice } from '@reduxjs/toolkit';
import { IScenarioDetails, IViewPort, NUMBER, defaultViewport } from '../constants';
import { Edge, Node } from 'reactflow';

export interface IWorkbenchProps {
  nodes: Node[]
  savedNodes: Node[]
  edges: Edge[]
  theme: string
  scenarioDetails: IScenarioDetails
  viewPort: IViewPort
  importCount: number
  unsavedChanges: boolean
  showComment: boolean
  commentLength: number
  monteCarloSettingField?: []
}

const initialState: IWorkbenchProps = {
  nodes: [],
  savedNodes: [],
  edges: [],
  theme: '',
  scenarioDetails: {
    _id: '',
    projectID: '',
    projectName: '',
    name: '',
    description: '',
    userCreator: '',
    subProject_Type: '',
    createDate: '',
    permission: '',
    id: '',
    location: ''
  },
  viewPort: defaultViewport,
  importCount: NUMBER.N0,
  unsavedChanges: false,
  showComment: false,
  commentLength: 0,
  monteCarloSettingField: []
};

const workbenchSlice = createSlice({
  name: 'workbench',
  initialState,
  reducers: {
    updateNodes: (state, payload) => {
      return { ...state, nodes: payload.payload };
    },
    updateSavedNodes: (state, payload) => {
      return { ...state, savedNodes: payload.payload };
    },
    updateEdges: (state, payload) => {
      return { ...state, edges: payload.payload };
    },
    clearEdges: (state) => {
      return { ...state, edges: initialState.edges, unsavedChanges: true };
    },
    clearEntireWorkbench: (state) => {
      return { ...state, nodes: initialState.nodes, edges: initialState.edges, viewPort: initialState.viewPort, unsavedChanges: true };
    },
    resetWorkbench: (state) => {
      return { ...state, ...initialState };
    },
    updateWorkbenchData: (state, payload) => {
      return {
        ...state, ...payload.payload
      };
    },
    updateTheme: (state, payload) => {
      return { ...state, theme: payload.payload };
    },
    updateWorkbenchScenario: (state, payload) => {
      return { ...state, scenarioDetails: payload.payload };
    },
    updateViewPort: (state, payload) => {
      return { ...state, viewPort: payload.payload };
    },
    updateUnsavedChange: (state, payload) => {
      return { ...state, unsavedChanges: payload.payload };
    },
    showComments: (state, payload) => {
      return { ...state, showComment: payload.payload };
    },
    commentLength: (state, payload) => {
      return { ...state, commentLength: payload.payload };
    },
    addImportJsonData: (state, payload) => {
      return {
        ...state,
        nodes: [
          ...state.nodes,
          ...payload.payload.nodes
        ],
        edges: [
          ...state.edges,
          ...payload.payload.edges
        ],
        viewPort: payload.payload.viewPort,
        importCount: state.importCount + NUMBER.N1
      };
    },
    updateMonteCarloFields: (state, payload) => {
      return { ...state, monteCarloSettingField: payload.payload };
    }
  }
});

export const {
  updateNodes, updateEdges, resetWorkbench, clearEdges, clearEntireWorkbench, updateWorkbenchData, updateWorkbenchScenario, updateTheme,
  updateViewPort, updateUnsavedChange, showComments, commentLength, updateSavedNodes, addImportJsonData, updateMonteCarloFields
} = workbenchSlice.actions;

export default workbenchSlice.reducer;
