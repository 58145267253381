import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { NUMBER } from '../../constants';
interface TimelineRangeSliderProps {
  onChange: (value: any) => void
  range: any
}


const TimelineRangeSlider: React.FC<TimelineRangeSliderProps> = ({ onChange, range }) => {
  const [min, setMin] = useState<any>(range[NUMBER.N0]);
  const [max, setMax] = useState<any>(range[NUMBER.N1]);

  const handleSliderChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className='rc-slider-wrap' style={{ margin: '20px' }}>
      <h4>Select Timeline:</h4>
      <Slider
        min={min}
        max={max}
        range
        step={1}
        keyboard={true}
        defaultValue={range}
        marks={{
          [min]: min.toString(),
          [range[0]]: range[0],
          [range[1]]: range[1],
          [max]: max.toString()
        }}
        onChange={handleSliderChange}
        className='custom-slider'
      />
    </div>
  );
};

export default TimelineRangeSlider;
