import React from 'react';
import { IProjectDetails, IScenarioDetails, NUMBER } from '../../constants';
import { SCENARIOS, getProjectTypeLogo, setLocalStorageItem } from '../../utils';
import DateTime from '../../components/shared/DateTime';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { resetSimulationMetricsDatas } from '../../redux/slices/simulationMetricsDataSlice';

interface IScenarioModalProps {
  data: IScenarioDetails[]
  projectDetail: IScenarioDetails
  changeProjectScenario: (item: IScenarioDetails) => void
}

function AllScenarioModal(props: IScenarioModalProps) {
  const { data, projectDetail, changeProjectScenario } = props;
  const dispatch = useAppDispatch();

  const navigation = useNavigate();

  const goToScenario = (item: IScenarioDetails) => {
    if (projectDetail.id !== item.id) {
      dispatch(resetSimulationMetricsDatas());
      changeProjectScenario(item);
    }
  };

  const gotoProjectScenario = () => {
    const projectData: IProjectDetails = {
      _id: projectDetail.projectID,
      name: projectDetail.projectName ?? '',
      description: projectDetail.description,
      project_type: projectDetail.subProject_Type,
      scenarios: [''],
      scenarios_count: data?.length,
      createDate: projectDetail.createDate,
      updatedAt: projectDetail.updateDate ?? '',
      userCreator: projectDetail.Created_by
    };
    setLocalStorageItem('selectedProject', projectData);
    navigation(`${SCENARIOS}?projectId=${projectData._id}&name=${projectData.name}&projectType=${projectData.project_type}`);
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown-wrapper">
        <div className="dsider-tab-wrapper">
          <h2>Project: <span className='cursor-pointer' onClick={gotoProjectScenario}>{projectDetail.projectName}</span></h2>
          <span className="my-modal-count">{data?.length} {data?.length === NUMBER.N1 ? 'Scenario' : 'Scenarios'}</span>
          <div className="tab-main-container">
            {data?.length > NUMBER.N0 && data.map((item: any) => (<div className="tab-main-wrapper" key={item.id}>
              <div className="tab-left-part">
                <div className="dropdown-wrapper shared-profile cursor-pointer" onClick={() => goToScenario(item)}>
                  <span className="user-icon medium">
                    <img
                      src={getProjectTypeLogo(projectDetail.subProject_Type)}
                      alt="logo"
                      className="user-img"
                    />
                  </span>
                  <div className="right-sec">
                    <h3 className="dropdown-head bold" style={projectDetail.id === item?.id ? { color: '#4387B0' } : {}}>
                      {item.name}
                    </h3>
                    <p className="dropdown-des mediam">
                      <DateTime createdDate={item.createDate} updatedDate={item.updateDate} />
                    </p>
                  </div>
                </div>
              </div>
            </div>))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllScenarioModal;
