import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IEVSimulationPayload, IEVSimulationOtherDataPayload, Methods } from '../../constants';

export const evSimulationApis = createApi({
  reducerPath: 'evSimulationApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_EV_SIMULATION_URL
  }),
  tagTypes: ['SimulationScenario', 'SimulationOtherData'],
  endpoints: (builder) => ({
    simulationScenarioData: builder.mutation<any, IEVSimulationPayload>({
      query: (payload) => ({
        url: ApiEndpoints.EV_SIMULATION_DATA,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['SimulationScenario']
    }),

    //  Simulation other data
    simulationOtherData: builder.mutation<any, IEVSimulationOtherDataPayload>({
      query: (payload) => ({
        url: ApiEndpoints.EV_SIMULATION,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['SimulationOtherData']
    })
  })
});

export const { useSimulationScenarioDataMutation, useSimulationOtherDataMutation } = evSimulationApis;
