import React, { useRef, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { searchDropdownLogo, searchLogo } from '../../utils';

interface ISearch {
  placeHolder: string
  onSearch: (e: any) => void
  onFilter?: (e: any) => void
  showFilter?: boolean
  filterOption?: any
  searchTerm?: string
  className?: string
}

const SearchAndFilter = (props: ISearch) => {
  const { placeHolder, showFilter = false, onSearch, onFilter, filterOption = [], searchTerm = '', className = '' } = props;
  const [selectedFilter, setSelectedFilter] = useState('');
  const [searchVal, setSearchVal] = useState(searchTerm);
  const searchInput = useRef(null);

  const onFiterSelect = (e: any) => {
    if (selectedFilter === e.label) {
      setSelectedFilter('');
      onFilter?.('');
    } else {
      setSelectedFilter(e.label);
      onFilter?.(e.value);
    }
  };

  const clearSearch = () => {
    setSearchVal('');
    onSearch('');
  };

  const onSearchType = (e: any) => {
    const val = (e.target.value as string);
    setSearchVal(val);
    onSearch(val.toLocaleLowerCase());
  };

  return (
    <>
      {/* <Form className="full-width-form" > */}
      <Form.Group
        className={`form-group search-form-group ${className}`}
      >
        <Form.Control
          ref={searchInput}
          type="text"
          value={searchVal}
          placeholder={placeHolder || 'Search by Keyword'}
          onChange={(e) => onSearchType(e)}
          autoComplete='off'
          id="searchInput"
        />
        {searchVal && <span className="icon-close-grey-icon" onClick={clearSearch}></span>}
        <img src={searchLogo} alt="logo" className="search-logo" />

        {/* Filter dropdown */}
        {
          showFilter && <div className={`dropdown-auto ${selectedFilter ? 'filter-applied' : ''}`}>
            {[''].map((direction) => (
              <DropdownButton
                as={ButtonGroup}
                key={direction}
                id={`dropdown-button-drop-${direction}`}
                variant="secondary"
                align="end"
                title={
                  <>
                    <img
                      src={searchDropdownLogo}
                      alt="Image Alt Text"
                      className="search-dropdown-image"
                    />
                    {` ${direction} `}
                  </>
                }
              >
                <Dropdown.ItemText>Filter by Type</Dropdown.ItemText>
                {
                  filterOption.map((filter: any, index: any) => {
                    return (
                      <Dropdown.Item as="button"
                        key={filter.label}
                        className={selectedFilter === filter.label ? 'active' : ''}
                        onClick={() => onFiterSelect(filter)}
                      >
                        {filter.label}
                        {filter.label === selectedFilter && <span className="icon-theme-tick"></span>}
                      </Dropdown.Item>
                    );
                  })
                }
              </DropdownButton>
            ))}
          </div>
        }
      </Form.Group>
      {/* </Form> */}
    </>
  );
};

export default SearchAndFilter;
