import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IExportJSON, ISimulationMatricsData, Methods } from '../../constants';
import { getAuthHeaderMultipart, getAuthHeaders } from '../../utils';
import { HTTPS_STATUS_CODE } from '../../constants/api.constant';
import { toast } from 'react-toastify';

interface ISimulationStatusPayload {
  task_id: string
}

export interface ISimulationStatusResponse {
  message: string
  status: string
  error_message?: string
  results: ISimulationMatricsData
}

interface ISimulationPayload {
  scenario_id: string
  data: {
    Dashboard: boolean
    exportJSON: IExportJSON
  }
}

export interface ISimulationResponse {
  message: string
  task_id: string
}

export const simulationApis = createApi({
  reducerPath: 'simulationApis',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: [''],
  endpoints: (builder) => ({
    /**
     * Api to Save simulation Data...
     */
    saveSimulationData: builder.mutation<ISimulationResponse, ISimulationPayload>({
      query: (payload) => ({
        url: `${ApiEndpoints.SAVE_SIMULATION}?scenario_id=${payload.scenario_id}`,
        method: Methods.POST,
        headers: getAuthHeaders(),
        body: payload.data
      }),
      transformErrorResponse(baseQueryReturnValue, meta, arg) {
        if (baseQueryReturnValue.status === HTTPS_STATUS_CODE.REQUEST_TIMEOUT) {
          toast.error(`${(baseQueryReturnValue.data as any)?.message}. Please try again. `);
        }
      },
      transformResponse: (res: ISimulationResponse) => {
        return res;
      }
    }),
    /**
     * Api to get simulation status...
     */
    getSimulationStatus: builder.mutation<ISimulationStatusResponse, ISimulationStatusPayload>({
      query: (payload) => ({
        url: `${ApiEndpoints.SIMULATION_STATUS}${payload.task_id}`,
        headers: getAuthHeaders(),
        method: Methods.GET
      })
    }),
    /**
     * Api to get S3 URL JSON data...
     */
    getS3UrlJsonData: builder.mutation<any, any>({
      query: (url) => ({
        url: `${url}`,
        method: Methods.GET
      })
    }),
    /**
     * Simulation api for geo thermal simulation results...
     */
    getGeoThermalSimulationData: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.GEO_THERMAL_SIMULATION}?subprojectid=${payload.subprojectid}&is_dashboard=${payload.is_dashboard}`,
        method: Methods.POST,
        body: payload.payload,
        headers: getAuthHeaders()
      })
    })

  })
});

export const {
  useSaveSimulationDataMutation,
  useGetSimulationStatusMutation,
  useGetS3UrlJsonDataMutation,
  useGetGeoThermalSimulationDataMutation
} = simulationApis;
