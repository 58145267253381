import React, { FC, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { NUMBER } from '../../constants';
import FileUpload from '../../components/shared/DropZone';
import { crossObservation } from '../../utils';

interface IObservationProps {
  show: boolean
  handleClose: () => void
  isSubmit: (observation: string, context: string) => void
  manualChartUpload?: any
  manualCompanyLogoUpload?: any
}

const ObservationModal: FC<IObservationProps> = ({ show, handleClose, isSubmit, manualChartUpload, manualCompanyLogoUpload }) => {
  const [observation, setObv] = useState<string>('');
  const [context, setContext] = useState<string>('');
  const [file, setChartFile] = useState<any>(null);
  const [companyLogo, setCompanyLogo] = useState<any>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    isSubmit(observation, context);
    manualChartUpload(file);
    manualCompanyLogoUpload(companyLogo);
  };

  const handleFileUploaded = (file: File) => {
    manualChartUpload(file);
    setChartFile(file);
  };

  const handleCompanyLogo = (file: File) => {
    manualCompanyLogoUpload(file);
    setCompanyLogo(file);
  };

  // function to remove the uploaded file using cross icon
  const removeUploadedImg = (type: string) => {
    if (type === 'chart') {
      manualChartUpload(null);
      setChartFile(null);
    } else if (type === 'logo') {
      manualCompanyLogoUpload(null);
      setCompanyLogo(null);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="observations-modal modal-dialog-centered modal-588"
        className="change-pw-modal create-modal"
      >
        <Modal.Header>
          <button className='close-btn' onClick={handleClose}>
            <span className="icon-close-grey-icon"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="create-modal-wrapper">
            <div className="create-modal-right">
              <Form className="full-width-form" onSubmit={handleSubmit}>
                <Modal.Title>Add Context</Modal.Title>
                <Form.Group
                  className="form-group"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder="Add context"
                    as="textarea"
                    rows={5}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContext(e.target.value)}
                  />
                </Form.Group>

                <Modal.Title>Add Analysis</Modal.Title>
                <Form.Group
                  className="form-group"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder="Add analysis"
                    as="textarea"
                    rows={5}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setObv(e.target.value)}
                  />
                </Form.Group>
                <div className='observation-file-wrap'>
                  <div className="observation-file-inner pr-10">
                    <Modal.Title>Upload Chart</Modal.Title>
                    <Form.Group
                      className="form-group"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <FileUpload onFileUploaded={handleFileUploaded} imgToShow={file} removeImg={() => removeUploadedImg('chart')} />
                    </Form.Group>
                  </div>
                  <div className="observation-file-inner pl-10">
                    <Modal.Title>Upload Company Logo</Modal.Title>
                    <Form.Group
                      className="form-group"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <FileUpload onFileUploaded={handleCompanyLogo} imgToShow={companyLogo} removeImg={() => removeUploadedImg('logo')} />
                    </Form.Group>
                  </div>

                </div>
                <div className="sign-btn-wrapper">
                  <Button variant="primary" type="submit" disabled={observation.length === NUMBER.N0 || context.length === NUMBER.N0}>
                    Save & Proceed
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ObservationModal;
