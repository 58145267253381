import React, { useState } from "react";
import {
  WorkbenchEmptyImg,
  artboardDarkLogo,
  artboardLogo,
  geothermalDarkLogo,
  geothermalLogo,
  hydrogenLogoSmall,
  infoInputLogo,
  locationLogo,
  methaneDarkLogo,
  methaneLogo,
  plusLogo,
  powerBetteryDarkLogo,
  powerBetteryLogo,
  powerLineDarkLogo,
  powerLineLogo,
  turbineDarkLogo,
  turbineLogo,
  windmillDarkLogo,
  windmillLogo,
  workbenchBoardImg,
  zoomOutImg,
} from "../../utils/icons";
import {
  NavDropdown,
  Dropdown,
  Modal,
  Button,
  Container,
  Tab,
  Tabs,
  Form,
  ButtonGroup,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import HeaderWorkbench from "../../components/layout/HeaderWorkbench";
import CustomSelect from "./CustomSelect";
import Select from "react-select";

const WorkBench = () => {
  const [ShowObjectModal, setShowObjectModal] = useState(false);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const capitalCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "kwh", label: "KWH" },
    { value: "mwh", label: "MWH" },
    { value: "kt", label: "KT" },
    { value: "mt", label: "MT" },
  ];
  const periodCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const priceSold = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const transCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const variableCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];

  return (
    <>
      {/* Please add light-bg-theme this class in below div for light backgound */}
      <div className="workbench-dashboard-main">
        <HeaderWorkbench />
        <div className="workbench-dashboard-inner">
          <div className="dashboard-main-wrapper">
            <div className="dashboard-left">
              <div className="home-header-sec">
                <Link to="#" className="link-white-icon">
                  <span className="icon-home-white"></span>
                  Home
                </Link>
                <NavDropdown
                  title="All Projects"
                  id="collapsible-nav-dropdown"
                  className="request-dropdown link-white-icon all-project-dropdown"
                >
                  <div className="custom-dropdown">
                    <div className="dropdown-wrapper">
                      <div className="dsider-tab-wrapper">
                        <h2>Project: Blue Carbon</h2>
                        <span className="my-modal-count">3 Scenarios</span>
                        <div className="tab-main-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* share modal
                     */}
                    {/* <div className="dropdown-wrapper">
                      <div className="dsider-tab-wrapper">
                        <h2 className="shared-head">
                          Shared with me
                          <span className="my-modal-count">(2)</span>
                        </h2>
                        <div className="tab-main-container shared-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head bold">
                                    Turbine
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Power Object, Green Hydrogen +2
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-left-right">
                              <Button className="btn-no-outline">
                                <span className="icon-next-arrow-grey"></span>
                              </Button>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head bold">
                                    Turbine
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Power Object, Green Hydrogen +2
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-left-right">
                              <Button className="btn-no-outline">
                                <span className="icon-next-arrow-grey"></span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </NavDropdown>
              </div>
              <h1 className="section-title">Green Hydrogen Base-Case</h1>
              {/* Select start here */}
              <CustomSelect />

              <div className="drag-drop-main">
                <h5 className="drag-drop-head">Select an Object</h5>
                <ul className="drag-drop-wrapper">
                  <li className="drag-drop-item">
                    <img
                      src={turbineLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={turbineDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={windmillLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={windmillDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={powerLineLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={powerLineDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={powerBetteryLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={powerBetteryDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={geothermalLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={geothermalDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={methaneLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={methaneDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={artboardLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    {/* <img
                      src={artboardDarkLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    /> */}
                    <span className="drag-drop-text">Turbine</span>
                  </li>
                  <li className="drag-drop-item">
                    <img
                      src={plusLogo}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    <span className="drag-drop-text new-object">
                      New Object
                    </span>
                  </li>
                </ul>
                <div className="drag-hint-wrapper">
                  <span className="icon-dragdrop-icon"></span>
                  <span className="drag-hint-text">
                    Drag & Drop an object on your workbench
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-right">
              <div className="workbench-chart-main">
                <div className="workbench-chart-header">
                  <span className="work-board-text">
                    This is your work board:
                  </span>
                  <div className="chart-btn-wrapper">
                    <Button className="primary-btn no-radius save-btn">
                      Save this Scenario
                    </Button>
                    <Button className="primary-btn no-radius">
                      <span className="icon-simulate-icon"></span>
                      Simulate
                    </Button>
                  </div>
                </div>
                <div className="workbench-board-body">
                  <div className="workbench-board-header">
                    <Dropdown className="create-modal-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        Clear Board
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.ItemText>Options</Dropdown.ItemText>
                        <Dropdown.Item as="button">
                          Clear Entire Board
                        </Dropdown.Item>
                        <Dropdown.Item as="button">Clear Linking</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline">
                        <div className="circle-logo-wrapper">
                          <span className="icon icon-layer"></span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Layers</div>
                    </div>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline">
                        <div className="circle-logo-wrapper">
                          <span className="svg-icon">
                            <svg
                              width="17"
                              height="15"
                              viewBox="0 0 17 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                // eslint-disable-next-line max-len
                                d="M10.8574 1.42853H5.97684C3.13944 1.42853 0.839355 3.72861 0.839355 6.56601C0.839355 9.40332 3.13944 11.7035 5.97684 11.7035H7.00434C7.71369 11.7035 8.28871 12.2785 8.28871 12.9879V14.2722C13.6175 10.8818 15.9615 10.0269 15.9945 6.63916C15.9948 6.61485 15.9949 6.59048 15.9949 6.56601C15.9949 3.72864 13.6948 1.42853 10.8574 1.42853Z"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.97705 5.27911H10.8577"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.8574 7.84943H5.97681"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Comment</div>
                    </div>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline">
                        <div className="circle-logo-wrapper">
                          <span className="icon icon-undo"></span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Undo</div>
                    </div>
                  </div>
                  <div className="workbench-board-wrapper">
                    <div className="workbench-board-empty">
                      <img
                        src={WorkbenchEmptyImg}
                        alt="logo image"
                        className="workbench-empty-img"
                      />
                      <div className="workbench-empty-wrapper">
                        <h2 className="workbench-empty-head">
                          Welcome to your Dsider Workbench
                        </h2>
                        <p className="workbench-empty-des">
                          Pick an object from the component library & drag &
                          drop on this board to create your simulation model
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="workbench-board-footer">
                    <span className="footer-board-left">
                      Last updated 3d ago
                    </span>
                    <div className="workbench-board-header">
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper">
                            {/* <span className="icon icon-zoom-in"></span> */}
                            <img src={zoomOutImg} alt="logo" className="icon icon-zoom-in" />
                          </div>
                        </Button>
                        <div className="tooltip-text">Zoom in</div>
                      </div>
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper">
                            <span className="icon icon-zoom-out"></span>
                          </div>
                        </Button>
                        <div className="tooltip-text">Zoom out</div>
                      </div>
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper delete-btn">
                            <span className="svg-icon">
                              <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Group">
                                  <g id="Group_2">
                                    <path
                                      id="Vector (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M0 2.68978C0 2.3377 0.285418 2.05228 0.6375 2.05228H11.6902C12.0422 2.05228 12.3277 2.3377 12.3277 2.68978C12.3277 3.04186 12.0422 3.32728 11.6902 3.32728H0.6375C0.285418 3.32728 0 3.04186 0 2.68978Z"
                                      fill="white"
                                    />
                                    <path
                                      id="Vector_2 (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M2.90125 2.26276C2.90125 1.01305 3.91442 0 5.16405 0H7.16375C8.41349 0 9.42655 1.01307 9.42655 2.26276V2.68968C9.42655 2.97963 9.1915 3.21468 8.90155 3.21468H3.42635C3.13644 3.21468 2.90141 2.9797 2.90135 2.6898L2.90125 2.26276ZM3.95515 2.16468C4.00509 1.54078 4.52729 1.05 5.16405 1.05H7.16375C7.80058 1.05 8.32271 1.54076 8.37264 2.16468H3.95515Z"
                                      fill="white"
                                    />
                                    <g id="Group_3">
                                      <path
                                        id="Vector_3 (Stroke)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        // eslint-disable-next-line max-len
                                        d="M3.76859 5.65617C4.05816 5.64139 4.30488 5.86416 4.31966 6.15373L4.55475 10.7619C4.56953 11.0515 4.34676 11.2982 4.05719 11.313C3.76761 11.3278 3.52089 11.105 3.50612 10.8154L3.27102 6.20723C3.25625 5.91766 3.47901 5.67094 3.76859 5.65617Z"
                                        fill="white"
                                      />
                                      <path
                                        id="Vector_4 (Stroke)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        // eslint-disable-next-line max-len
                                        d="M8.55919 5.65617C8.84876 5.67095 9.07153 5.91767 9.05675 6.20725L8.82155 10.8154C8.80677 11.105 8.56004 11.3278 8.27047 11.313C7.9809 11.2982 7.75813 11.0515 7.77291 10.7619L8.00811 6.15372C8.02289 5.86415 8.26962 5.64139 8.55919 5.65617Z"
                                        fill="white"
                                      />
                                    </g>
                                    <path
                                      id="Vector_5 (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M6.16373 5.65788C6.45368 5.65788 6.68873 5.89293 6.68873 6.18288V10.791C6.68873 11.0809 6.45368 11.316 6.16373 11.316C5.87378 11.316 5.63873 11.0809 5.63873 10.791V6.18288C5.63873 5.89293 5.87378 5.65788 6.16373 5.65788Z"
                                      fill="white"
                                    />
                                  </g>
                                  <path
                                    id="Vector_6 (Stroke)"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    // eslint-disable-next-line max-len
                                    d="M0.800401 2.32823C0.89952 2.22387 1.03714 2.16478 1.18107 2.16478H11.1467C11.2906 2.16478 11.4281 2.22383 11.5272 2.32813C11.6264 2.43243 11.6783 2.57284 11.671 2.71653L11.1681 12.573C11.1068 13.7763 10.1131 14.7205 8.90827 14.7205H3.41947C2.27527 14.7205 1.32171 13.869 1.17594 12.7528C1.17567 12.7508 1.17542 12.7489 1.17518 12.7469C1.16785 12.6896 1.16264 12.6317 1.15965 12.5731L1.15951 12.5701L1.15921 12.5635C1.15812 12.5395 1.15866 12.5159 1.16071 12.4927L0.656764 2.71681C0.649354 2.57307 0.701281 2.43259 0.800401 2.32823ZM2.21932 12.6326C2.30472 13.2226 2.812 13.6705 3.41947 13.6705H8.90827C9.55404 13.6705 10.0866 13.1644 10.1194 12.5196L10.1194 12.5195L10.5942 3.21478H1.73383L2.21932 12.6326Z"
                                    fill="white"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </Button>
                        <div className="tooltip-text">Remove Model</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkBench;
