import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { changeSpinnerTitle, stopFullLoading } from '../redux/SpinnerSlice';
import { useNavigate } from 'react-router-dom';
import { signOutUser, updateWarningAlert } from '../redux/auth/authReducer';
import { toast } from 'react-toastify';
import { LOADER_TITLE, MESSAGES, NUMBER } from '../constants';
import { LOGIN } from '../utils';
import { useIdleTimer, IIdleTimer } from 'react-idle-timer';
import { updateSignOutUserState } from '../redux/slices/adminSlice';

function useAutoLogout(timeout: number = NUMBER.N20Min): void {
  const dispatch = useAppDispatch();
  const warningAlert = useAppSelector((state) => state.auth.warningAlert);
  const navigate = useNavigate();
  const idleTimerRef = useRef<IIdleTimer | null>(null);
  const date = () => new Date();
  const LAST_ACTIVITY_KEY = 'lastActivityTimestamp';
  const urlPath = window.location.pathname;

  const setLocalTime = () => {
    localStorage.setItem(LAST_ACTIVITY_KEY, date().toISOString());
  };

  const logoutFnc = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(stopFullLoading());
    toast.error(MESSAGES.SESSION_EXPIRED);
    dispatch(updateSignOutUserState(false));
    navigate(LOGIN);
  };

  const handleSignOutClick = () => {
    dispatch(changeSpinnerTitle(LOADER_TITLE.LOGGING_OUT));
    dispatch(signOutUser())
      .unwrap()
      .then(() => {
        logoutFnc();
      })
      .catch(() => {
        logoutFnc();
        dispatch(stopFullLoading());
      });
  };

  const handlePreLogoutCheck = () => {
    if (urlPath.includes('workbench')) {
      dispatch(updateWarningAlert({ warningAlert: true }));
    }
  };

  const handleOnAction = () => {
    if (!warningAlert) {
      reset();
      setLocalTime();
    }
  };

  const { reset } = useIdleTimer({
    ref: idleTimerRef,
    timeout,
    onIdle: handleSignOutClick,
    onActive: handleOnAction,
    onAction: handleOnAction,
    onPrompt: handlePreLogoutCheck,
    debounce: 500,
    crossTab: true,
    promptBeforeIdle: timeout - NUMBER.N19Min
  });

  useEffect(() => {
    const lastActivityTime = localStorage.getItem(LAST_ACTIVITY_KEY);
    if (lastActivityTime) {
      const lastActivityDate = new Date(lastActivityTime);
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - lastActivityDate.getTime();

      if (timeDifference >= timeout) {
        handleSignOutClick();
      }
    } else {
      setLocalTime();
    }
  }, []);
}

export default useAutoLogout;
