import React, { FC, useEffect, useRef, useState } from 'react';
import { NavDropdown, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderWorkbench from '../../components/layout/HeaderWorkbench';
import {
  CREATE_WITH_WIZARD,
  EV,
  GRAPH_FORM_TYPE,
  GRAPH_TYPES,
  IScenarioDetails,
  MAX_LENGTH,
  MESSAGES,
  NUMBER
} from '../../constants';
import {
  DASHBOARD,
  inputCheckWhite,
  inputCloseIcon,
  inputCheckDark,
  inputCloseDark,
  calculateFontSize,
  getWorkbenchURL,
  CREATE_SCENARIO_WITH_WIZARD
} from '../../utils';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import ObjectList from '../../components/workbench/ObjectList';
import WorkbenchFlowHOC from '../../components/workbench/WorkbenchFlowHOC';
import AllScenarioModal from './AllScenarioModal';
import { useGetMetricsDataQuery, useGetObjectListQuery } from '../../redux/services/workbenchApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetWorkbench, updateUnsavedChange } from '../../redux/workbenchSlice';
import { IUpdatePayload, useGetScenarioQuery } from '../../redux/services/scenarioApis';
import { LABELS } from '../../constants/labelConstant';
import { updateObjectList } from '../../redux/slices/ObjeectListSlice';
import GraphHOC from '../../components/shared/Graph/GraphHOC';
import EconomicsData from '../../components/shared/EconomicsData';
import { useScenario } from '../../hooks/useScenario';
import { resetSimulationMetricsDatas, updateSimulationMetricsDatas } from '../../redux/slices/simulationMetricsDataSlice';
import { useSaveWorkbench } from '../../hooks/useSaveWorkbenchData';
import { resetLayers } from '../../redux/slices/layersSlice';
import ShowAlertLogout from './ShowAlertLogout';

interface Props {
  scenarioDetails: IScenarioDetails
  scenarioSuccess: boolean
}

// eslint-disable-next-line complexity
const WorkBench: FC<Props> = ({ scenarioDetails, scenarioSuccess }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioType = searchParams.get('type');
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const categoryList = useAppSelector((state) => state.objectList.categoryList);
  const objectList = useAppSelector((state) => state.objectList.list);
  const workbenchTheme = useAppSelector((state) => state.workbench.theme);
  const workbenchData = useAppSelector((state) => state.workbench);
  const loggenInUserData = useAppSelector((state) => state.auth);
  const simulationMetricsData = useAppSelector(state => state.simulationMetricsData);
  const powerGenerationProfile = simulationMetricsData?.['POWER GENERATION PROFILE']; // for geothermal...
  const [showEdtiScenario, setShowEdtiScenario] = useState(false);
  // Static name will be removed after implemetation of edit sceanrion api
  const [scenarioNameValue, setScenarioNameValue] = useState('');
  const [showDeleteScenarioAlert, setShowDeleteScenarioAlert] = useState(false);
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isScenarioChaning, setIsScenarioChaning] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState<IScenarioDetails>();
  const location = useLocation();
  const { saveWorkbench } = useSaveWorkbench();
  const [skipAllScenarios, setSkipAllScenarios] = useState(true);
  const { data: objectListRes, isSuccess, isLoading } = useGetObjectListQuery();
  const { data: metricsData, isFetching, error } = useGetMetricsDataQuery({ scenarioId }, { refetchOnMountOrArgChange: true });
  const [isScrolled, setIsScrolled] = useState<boolean | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const projectId = workbenchData.scenarioDetails.projectID;
  const { data: scenarioList, isFetching: isScenarioListFetching, refetch: refetchScenarios } =
    useGetScenarioQuery(projectId, { skip: skipAllScenarios, refetchOnMountOrArgChange: true });
  const [scenarios, setScenarios] = useState<IScenarioDetails[]>([]);
  const [fontSize, setFontSize] = useState(NUMBER.N35);
  const { updateScenario, updateWorkbech } = useScenario();
  // reset workbench data when the component unmounts
  useEffect(() => {
    scenarioDetails && setScenarioNameValue(scenarioDetails?.name);
    setFontSize(calculateFontSize(scenarioDetails?.name ?? ''));
    return () => {
      if (!window.location.href?.includes('/workbench?scenario_id')) {
        dispatch(resetWorkbench());
        dispatch(resetSimulationMetricsDatas());
      }
    };
  }, [scenarioDetails]);

  useEffect(() => {
    if (!isScenarioListFetching) {
      setScenarios(scenarioList);
      dispatch(updateUnsavedChange(false));
    }
  }, [isScenarioListFetching]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(error ? resetSimulationMetricsDatas() : updateSimulationMetricsDatas(metricsData));
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateObjectList({
          categoryList: objectListRes.categoryList,
          list: objectListRes.data
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (scenarioSuccess && isSuccess) {
      updateWorkbech(scenarioDetails);
      setSkipAllScenarios(false);
      dispatch(resetLayers());
    }
  }, [scenarioSuccess, isSuccess]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > NUMBER.N50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(null);
      }
    };

    if (divRef.current) {
      divRef.current.addEventListener('scroll', handleScroll);
    }
    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const editScenarioName = () => {
    if (loggenInUserData.email === scenarioDetails?.userCreator && !scenarioDetails.BaselineStatus) {
      setShowEdtiScenario(true);
    }
  };

  const onChangeScenarioName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScenarioNameValue(event.target.value);
  };

  const submitEditScenario = (e: any) => {
    e.preventDefault();
    const payload: IUpdatePayload = {
      scenario_id: scenarioId,
      name: scenarioNameValue,
      description: scenarioDetails.description
    };
    updateScenario(payload, () => {
      refetchScenarios();
      setFontSize(calculateFontSize(scenarioNameValue));
      setShowEdtiScenario(false);
    });
  };

  const goTo = (path: string) => {
    if (workbenchData.unsavedChanges) {
      setShowUnsavedAlert(true);
    } else {
      navigate(path);
    }
  };

  const handleWorkBenchCloseClick = () => {
    setShowUnsavedAlert(false);
    dispatch(updateUnsavedChange(false));
    isScenarioChaning ? changeScenario(selectedScenario as IScenarioDetails) : navigate(DASHBOARD);
  };

  const handleSaveChangesClick = () => {
    setShowLoading(true);
    saveWorkbench(false, () => {
      setShowLoading(false);
      setShowUnsavedAlert(false);
      dispatch(updateUnsavedChange(false));
      isScenarioChaning ? changeScenario(selectedScenario as IScenarioDetails) : navigate(DASHBOARD);
    });
  };

  const changeProjectScenario = (item: IScenarioDetails) => {
    setSelectedScenario(item);
    // check if unsaved changes then show alert otherwise navigate to new scenario
    if (workbenchData.unsavedChanges) {
      setShowUnsavedAlert(true);
      setIsScenarioChaning(true);
    } else {
      changeScenario(item);
    }
  };

  const changeScenario = (item: IScenarioDetails) => {
    if (item.scenarioCreatedType === CREATE_WITH_WIZARD && !item?.updateDate) {
      navigate(`${CREATE_SCENARIO_WITH_WIZARD}?scenario_id=${item._id}&scenarioName=${item.name}&type=${item.subProject_Type}`, {
        state: {
          project: location?.state?.project,
          navigateFrom: location?.state?.navigateFrom ?? LABELS.DASHBOARD
        }
      });
    } else {
      navigate(getWorkbenchURL(item.id, item.subProject_Type), {
        state: {
          project: location?.state?.project,
          navigateFrom: location?.state?.navigateFrom ?? LABELS.DASHBOARD
        }
      });
    }
    setIsScenarioChaning(false);
  };

  return (
    <>
      <div
        className={`workbench-dashboard-main ${scenarioType === EV ? 'EV-workbench-bg' : 'workbench-bg'} ${workbenchTheme}`}
        ref={divRef}
      >
        <HeaderWorkbench divScrolled={isScrolled} />
        <div className="workbench-dashboard-inner">
          {!isLoading && scenarioSuccess && (<>
            <div className="dashboard-main-wrapper">
              <div className="dashboard-left">
                <div className="home-header-sec">
                  <a href="#" onClick={() => goTo(DASHBOARD)} className="link-white-icon">
                    <span className="icon-home-white"></span>
                    Home
                  </a>
                  {loggenInUserData.email === scenarioDetails?.userCreator && <NavDropdown
                    title="All Scenarios"
                    id="collapsible-nav-dropdown"
                    className="request-dropdown link-white-icon all-project-dropdown"
                  >
                    <AllScenarioModal
                      data={scenarios}
                      projectDetail={scenarioDetails}
                      changeProjectScenario={changeProjectScenario}
                    ></AllScenarioModal>
                  </NavDropdown>}
                </div>
                {!showEdtiScenario && (
                  <h1
                    className={'section-title'}
                    style={{ 'fontSize': `${fontSize}px`, cursor: loggenInUserData.email === scenarioDetails?.userCreator ? 'pointer' : 'default' }}
                    onClick={() => editScenarioName()}
                  >
                    {scenarioNameValue}
                  </h1>
                )}
                {/* Please use below commented code for editable input */}

                {showEdtiScenario && (
                  <Form className="editable-input-form">
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmailll"
                    >
                      <Form.Control
                        as="textarea"
                        rows={NUMBER.N3}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onChangeScenarioName(event);
                        }}
                        value={scenarioNameValue}
                        maxLength={MAX_LENGTH.CHAR_LENGTH_50}
                      />
                      <p className="max-char">(Limit: 50 characters)</p>

                      <div className="sign-btn-wrapper">
                        <button
                          className="btn-correct"
                          disabled={!scenarioNameValue}
                          onClick={(e) => submitEditScenario(e)}
                        >
                          {!workbenchTheme && (
                            <img src={inputCheckWhite} alt="logo" />
                          )}
                          {workbenchTheme && (
                            <img src={inputCheckDark} alt="logo" />
                          )}
                        </button>
                        <button
                          className="input-btn-close"
                          onClick={(e) => {
                            e.stopPropagation();
                            setScenarioNameValue(scenarioDetails?.name ?? '');
                            setShowEdtiScenario(false);
                          }}
                        >
                          {!workbenchTheme && (
                            <img src={inputCloseIcon} alt="logo" />
                          )}
                          {workbenchTheme && (
                            <img src={inputCloseDark} alt="logo" />
                          )}
                        </button>
                      </div>
                    </Form.Group>
                  </Form>
                )}

                {/* Object Listing start from here... */}
                {objectList?.length > NUMBER.N0 && (
                  <ObjectList
                    categoryList={categoryList}
                    objectList={objectList}
                  />
                )}
              </div>
              <div className="dashboard-right">
                <WorkbenchFlowHOC scenarioDetails={scenarioDetails} />
              </div>
            </div>
            {/* Simulation Chart  */}

            {(simulationMetricsData.S3_URI_JSON || powerGenerationProfile) && <GraphHOC
              radioOptions={GRAPH_TYPES[scenarioDetails?.subProject_Type]}
              title={'Simulation Overview'} formType={GRAPH_FORM_TYPE.POWER_HYDROGEN_FIELDS}
              scenarioId={scenarioId}
              simulationData={null} projType={scenarioDetails?.subProject_Type}
              scenarioDetails={scenarioDetails}
            />}

            {/* Cost Chart  */}
            {(simulationMetricsData.S3_URI_JSON || powerGenerationProfile) &&
              <EconomicsData metricsData={simulationMetricsData} projectType={scenarioDetails?.subProject_Type} />}
          </>)}
        </div>
      </div>
      {showDeleteScenarioAlert && (
        <ConfirmationAlert
          showAlert={showDeleteScenarioAlert}
          title={LABELS.DELETE_SCENARIO}
          message={MESSAGES.DELETE_SCENARIO_CONFIRM_MESSAGE}
          yesBtnText={LABELS.YES_DELETE_SCENARIO}
          cancleBtnText={LABELS.NOT_NOW}
          onConfirm={() => navigate(DASHBOARD)}
          onCancel={() => setShowDeleteScenarioAlert(false)}
        />
      )}

      {showUnsavedAlert && (
        <ConfirmationAlert
          btnClassName="blue-btn"
          cancelBtnClassName="danger-text"
          showAlert={showUnsavedAlert}
          showLoader={showLoading}
          title={LABELS.UNSAVED_CHANGES}
          message={MESSAGES.WORKBENCH_CONFIRM_MESSAGE}
          yesBtnText={LABELS.SAVE_CHANGES}
          cancleBtnText={LABELS.DISCARD_CHANGES}
          onConfirm={handleSaveChangesClick}
          onCancel={handleWorkBenchCloseClick}
          onCross={() => {
            setShowUnsavedAlert(false);
            setIsScenarioChaning(false);
          }}
        />
      )}
      <ShowAlertLogout />
    </>
  );
};

export default WorkBench;
