import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { aiBackBtn, inputCloseIcon, simulateSummaryIcon, warningLogo } from '../../utils';
import { IScenarioDetails, SHARED_SCENARIO_PERMISSION, USER_ROLE } from '../../constants';

interface EVPluseStationCardHeaderProps {
  simulationSummary: string
  onSaveScenario: (isSaveDuplicate: boolean) => void
  scenarioName: string
  scenarioDetails: IScenarioDetails
  goToCreateWithAI?: () => void
  notSimulated: boolean
  closeMessage: (data: boolean) => void
}


const EVPluseStationCardHeader: React.FC<EVPluseStationCardHeaderProps> = ({
  simulationSummary,
  onSaveScenario,
  scenarioName,
  scenarioDetails,
  goToCreateWithAI,
  notSimulated,
  closeMessage
}) => {
  const [show, setShow] = useState(false);
  const scenarioNameData = scenarioName.charAt(0).toUpperCase() + scenarioName.slice(1);
  return (
    <div className="ev-graph-header">
      <div className="ev-graph-left">
        {goToCreateWithAI && <div className='dsider-back-wrap'>
          <Button className='btn-no-outline'>
            <img src={aiBackBtn} alt="logo" className='ai-back-btn' onClick={() => goToCreateWithAI && goToCreateWithAI()} />
          </Button>
          <span className="ev-graph-des">Back to Dsider AI</span>
        </div>}
        <h2 className="ev-graph-head">{scenarioNameData}</h2>
      </div>
      {notSimulated && <div className='zip-code-error-wrap'>
        <p className='zip-code-error-msg'>If you refrain from simulating, your changes will not be saved.</p>
        <button className="close-btn">
          <span className="icon-close-grey-icon" onClick={() => closeMessage(false)}></span>
        </button>
      </div>}
      <div className="ev-graph-right">
        <div className="simulation-summary-popup-wrap">
          <Button
            className="simulation-summary-btn"
            onClick={() => setShow(!show)}
          >
            <img src={simulateSummaryIcon} alt="logo" />
          </Button>
          <div className={`simulation-summary-popup ${!show ? 'd-none' : ''}`}>
            <button className="close-btn" onClick={() => setShow(!show)}>
              <span className="icon-close-grey-icon"></span>
            </button>
            <h2 className="summary-dropdown-title">Simulation Summary</h2>
            <p className="summary-dropdown-des">{simulationSummary}</p>
          </div>
        </div>
        <Dropdown className="create-modal-dropdown ev-scenario-dropdown">
          <Dropdown.Toggle id="dropdown-basic" disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}>
            Save this Scenario
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.ItemText>Options</Dropdown.ItemText>
            <Dropdown.Item as="button" onClick={() => onSaveScenario(false)}>
              Save Scenario
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => onSaveScenario(true)}
            >
              Save as Duplicate
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default EVPluseStationCardHeader;
