import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { mapSelectionToArray } from '../../../utils';
import { NUMBER } from '../../../constants';

interface CheckboxData {
  value: string
  label: string
}

type Data = Record<string, CheckboxData[]>;

type Selection = Record<string, string[]>;

interface Props {
  data: Data
  setFieldValue: any
  values?: any
  isEdit?: boolean
}

function AccessObjectModal({ data, setFieldValue, values = {}, isEdit = false }: Props): JSX.Element {
  const [selection, setSelection] = useState<Selection>(values);
  const [accordionOpen, setAccordionOpen] = useState<string | null>(null);
  const [intial, setIntial] = useState(true);

  const handleParentCheckboxChange = (parent: string, checked: boolean) => {
    let updatedSelection: Selection = { ...selection };

    if (checked) {
      updatedSelection[parent] = data[parent].map((child) => child.value);
    } else {
      const { [parent]: omit, ...rest } = updatedSelection;
      updatedSelection = rest;
    }

    setSelection(updatedSelection);
    setFieldValue('accessToObjects', mapSelectionToArray(updatedSelection));
  };

  const handleChildCheckboxChange = (parent: string, child: string, checked: boolean) => {
    let updatedSelection: Selection = { ...selection };

    if (!updatedSelection[parent]) {
      updatedSelection[parent] = [];
    }

    if (checked) {
      updatedSelection[parent] = [...updatedSelection[parent], child];
    } else {
      updatedSelection[parent] = updatedSelection[parent].filter((value) => value !== child);
    }

    if (updatedSelection[parent].length === 0) {
      const { [parent]: omit, ...rest } = updatedSelection;
      updatedSelection = rest;
    }

    setSelection(updatedSelection);
    setAccordionOpen(parent);
    setFieldValue('accessToObjects', mapSelectionToArray(updatedSelection));
  };

  useEffect(() => {
    if (values && isEdit && intial) {
      setSelection(values);
      setFieldValue('accessToObjects', mapSelectionToArray(values));
    }
    if (Object.keys(values).length > NUMBER.N0) {
      setIntial(() => false);
    }
  }, [values]);

  useEffect(() => {
    if (!intial) {
      setIntial(() => true);
    }
  }, []);

  const handleAccordionToggle = (parent: string, e: React.MouseEvent) => {
    if (e.target instanceof HTMLInputElement) {
      return;
    }

    setAccordionOpen(accordionOpen === parent ? null : parent);
    const accordionItem = document.getElementById(parent);
    if (accordionItem) {
      accordionItem.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  const renderChildCheckboxes = (parent: string, childObjects: CheckboxData[]): JSX.Element[] => {
    return childObjects.map((child, index) => (
      <Form.Check
        key={`${child.value}-${index}`}
        type='checkbox'
        id={child.value}
        label={child.label}
        className='dsider-check-checkbox'
        checked={(selection[parent] || []).includes(child.value)}
        onChange={(e) => handleChildCheckboxChange(parent, child.value, e.target.checked)}
        onClick={(e) => e.stopPropagation()}
      />
    ));
  };

  const renderAccordionItems = (): JSX.Element[] => {
    return Object.keys(data).map((parent, index) => {
      const isOpen = accordionOpen === parent;
      const allChecked = selection[parent] && selection[parent].length === data[parent].length;
      const someChecked = selection[parent] && selection[parent].length > 0 && selection[parent].length < data[parent].length;
      const noneChecked = !selection[parent];

      let parentClass = '';
      if (allChecked) parentClass = 'all-selected';
      else if (someChecked) parentClass = 'parcially-checked';
      else if (noneChecked) parentClass = 'none-selected';

      return (
        <Accordion.Item key={index} eventKey={parent} onClick={(e) => handleAccordionToggle(parent, e)}>
          <Accordion.Header>
            <Form.Check
              type='checkbox'
              id={parent}
              label={''}
              className={`dsider-check-checkbox ${parentClass}`}
              checked={!!selection[parent]}
              onChange={(e) => handleParentCheckboxChange(parent, e.target.checked)}
              onClick={(e) => e.stopPropagation()} // Prevent the accordion from toggling
            />
            <span>{parent}</span> {/* Additional click target to toggle accordion */}
          </Accordion.Header>
          <Accordion.Body className={isOpen ? 'show' : 'hide'}>
            {renderChildCheckboxes(parent, data[parent])}
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  return (
    <div>
      <Accordion>
        {renderAccordionItems()}
      </Accordion>
    </div>
  );
}

export default AccessObjectModal;
