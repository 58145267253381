import React from 'react';

const PermissionModal = ({ permission, handleCross }: any) => {
  return (
    <div className="permission-modal">
      <button className="close-btn" onClick={handleCross}>
        <span className="icon-close-grey-icon"></span>
      </button>
      <div className="row">
        {!permission.length && <div className="no-permission">No permission available for this user role.</div>}
        {permission.map((data: any) => (
          <div className="col-md-6" key={data}>
            <div className="permission-item">{data}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionModal;
