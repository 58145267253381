import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import {
  modelCube,
  tonnesLogo,
  whiteCircleRight,
  energyHoverLogo,
  cardViewLogo,
  cardTriangleLogo,
  hydrogenLogoSmall,
  emptyModalLogo
} from '../../utils/icons';
import Header from './Header';
import ActivitySec from './ActivitySec';

const Dashboard = () => {
  const [hoverStates, setHoverStates] = useState([false, false, false]); // Initialize an array of state values

  const handleMouseOver = (index: number) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };

  const handleMouseOut = (index: number) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  };
  return (
    <>
      <div className="main-login dashboard-main">
        <Header />
        <div className="container">
          <div className="main-login-wrapper">
            <div className="dashboard-main-wrapper">
              <div className="row">
                <div className="col-lg-9">
                  <div className="dashboard-left">
                    <div className="dashboard-header">
                      <h1>My Dashboard</h1>
                      <div className="dashboard-header-right">
                        <div className="dashboard-header-models">
                          <span className="dashboard-header-icon">
                            <img src={modelCube} alt=" cube logo" />
                          </span>
                          <div className="dashboard-header-text">
                            <span className="dashboard-header-head">
                              05 Models
                            </span>
                            <span className="dashboard-header-des">
                              Simulation Count
                            </span>
                          </div>
                        </div>
                        <div className="dashboard-header-models dashboard-header-tonnes">
                          <span className="dashboard-header-icon">
                            <img src={tonnesLogo} alt=" tonnes logo" />
                          </span>
                          <div className="dashboard-header-text">
                            <span className="dashboard-header-head">
                              2,200 Tonnes
                            </span>
                            <span className="dashboard-header-des">
                              Carbon Abatement Value
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-12">
                        <div className="dashboard-graph">
                          <div className="dashboard-graph-inner">
                            <span className="recent-simulation">
                              Your Recent Simulation Model:
                            </span>
                            <h3 className="dashboard-graph-head">
                              Green Hydrogen Basecase
                            </h3>
                            <span className="chart-year">
                              Simluation Chart (2023-2035)
                            </span>
                            <div className="graph-wrapper"></div>
                            <span className="back-circle-logo">
                              <img src={whiteCircleRight} alt="logo img" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-card-wrapper row">
                      {/* first card start here */}
                      <div className="col-md-4">
                        <div className="data-card-wrap empty-modal">
                          <div className="data-card-main">
                            <div className="data-card-header">
                              <div className="user-circle plus-img">
                                <span className="user-icon">A</span>
                                <span className="user-icon">B</span>
                                <span className="user-icon">C</span>
                                <span className="user-icon">
                                  <img src={whiteCircleRight} alt="logo img" />
                                </span>
                              </div>
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <span className="icon-horizontal-three-dot"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.ItemText>
                                        Options
                                      </Dropdown.ItemText>
                                      <Dropdown.Item as="button">
                                        Share Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Export JSON
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Duplicate Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Edit Model
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="text-danger"
                                      >
                                        Remove Model
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                            </div>
                            <div className="data-card-body" onMouseOver={() => handleMouseOver(1)} onMouseOut={() => handleMouseOut(1)}>
                              <span className="modal-name">
                                Model/Green Hydrogen
                              </span>
                              <h2 className="data-card-title">
                                Green Hydrogen Basecase
                              </h2>
                              {/* <ul className="list-item-wrapper">
                                <li className="list-item">Carbon Tax: $2.00</li>
                                <li className="list-item">
                                  NPV: $-1,280,938,930
                                </li>
                                <li className="list-item">
                                  Emissions Mitigated: 9937 MMT
                                </li>
                              </ul> */}
                              <div className="empty-modal-wrapper">
                                <img src={emptyModalLogo} alt="logo img" className='empty-modal-img' /> <p className='empty-modal-text'>Model is not yet simulated. Go to work bench & simulate now!</p>
                              </div>
                            </div>
                            <div className="data-card-footer">
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  // show={true}
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      {/* Add active class for button active bg */}
                                      <div className="card-view">
                                        <span className="card-view-img">
                                          <img src={cardViewLogo} alt="img logo" />
                                        </span>
                                        <span className="card-view-number">12</span>

                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-menu-inner">
                                        <Dropdown.ItemText>
                                          Shared with
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              <img
                                                src={hydrogenLogoSmall}
                                                alt="logo"
                                                className="user-img"
                                              />
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head current-user-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                              <span className='current-user'>(You)</span>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                        >
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                              <a href="" className="back-project">
                                <span className="icon-next-arrow-grey"></span>
                              </a>
                            </div>
                          </div>
                          <span className='card-created-date'>Created Today</span>
                          {/* Please remvoe d-none class for hover card */}
                          <div className={`card-hover-box d-none ${hoverStates ? 'show' : ''} `} onMouseOver={() => handleMouseOver(1)} onMouseOut={() => handleMouseOut(1)}>
                            <div className="card-hover-header">
                              <span className="card-hover-img-wrap">
                                <img
                                  src={energyHoverLogo}
                                  alt="logo img"
                                  className="card-hover-img"
                                />
                              </span>
                              <div className="card-header-right">
                                <h4 className="card-hover-head">
                                  Energy Efficiency
                                </h4>
                                <span className="card-hover-subhead">
                                  Model/Power
                                </span>
                              </div>
                            </div>
                            <div className="card-hover-body">
                              <ul className="list-item-wrapper">
                                <li className="list-item">Capex: $404,000,100</li>
                                <li className="list-item">
                                  Variable: $651,086,779
                                </li>
                                <li className="list-item">Period: $3,129,400</li>
                                <li className="list-item">
                                  Local Tax Credits: $2.00
                                </li>
                                <li className="list-item">Carbon Tax: $0</li>
                                <li className="list-item">LCOE: $4.97</li>
                                <li className="list-item">LCOH: $4.97</li>
                                <li className="list-item">
                                  NPV: $-1,280,938,930
                                </li>
                                <li className="list-item">
                                  IRR: $-1,280,938,930
                                </li>
                                <li className="list-item">
                                  Emissions Mitigated: 9937 MMT
                                </li>
                              </ul>
                            </div>
                            <div className="card-hover-footer">
                              <Button className="btn-outline no-radius">
                                <span className="icon-go-simulation-icon"></span>
                                Go to Simulation
                              </Button>
                            </div>
                            <span className='icon-dropdown-triangle'>
                              <img src={cardTriangleLogo} alt="logo img" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="data-card-wrap">
                          <div className="data-card-main data-card-2">
                            <div className="data-card-header">
                              <div className="user-circle plus-img">
                                <span className="user-icon">A</span>
                                <span className="user-icon">B</span>
                                <span className="user-icon">C</span>
                                <span className="user-icon">
                                  <img src={whiteCircleRight} alt="logo img" />
                                </span>
                              </div>
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <span className="icon-horizontal-three-dot"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.ItemText>
                                        Options
                                      </Dropdown.ItemText>
                                      <Dropdown.Item as="button">
                                        Share Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Export JSON
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Duplicate Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Edit Model
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="text-danger"
                                      >
                                        Remove Model
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                            </div>
                            <div className="data-card-body" onMouseOver={() => handleMouseOver(2)} onMouseOut={() => handleMouseOut(2)}>
                              <span className="modal-name">Model/Power</span>
                              <h2 className="data-card-title">
                                Base-load Clean Power
                              </h2>
                              <ul className="list-item-wrapper">
                                <li className="list-item">Carbon Tax: $2.00</li>
                                <li className="list-item">
                                  NPV: $-1,280,938,930
                                </li>
                                <li className="list-item">
                                  Emissions Mitigated: 9937 MMT
                                </li>
                              </ul>

                            </div>
                            <div className="data-card-footer">
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  // show={true}
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      {/* Add active class for button active bg */}
                                      <div className="card-view">
                                        <span className="card-view-img">
                                          <img src={cardViewLogo} alt="img logo" />
                                        </span>
                                        <span className="card-view-number">12</span>

                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-menu-inner">
                                        <Dropdown.ItemText>
                                          Shared with
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              <img
                                                src={hydrogenLogoSmall}
                                                alt="logo"
                                                className="user-img"
                                              />
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head current-user-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                              <span className='current-user'>(You)</span>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                        >
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                              <a href="" className="back-project">
                                <span className="icon-next-arrow-grey"></span>
                              </a>
                            </div>
                          </div>
                          <span className='card-created-date'>Created Today</span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="data-card-wrap">
                          <div className="data-card-main data-card-3">
                            <div className="data-card-header">
                              <div className="user-circle plus-img">
                                <span className="user-icon">A</span>
                                <span className="user-icon">B</span>
                                <span className="user-icon">C</span>
                                <span className="user-icon">
                                  <img src={whiteCircleRight} alt="logo img" />
                                </span>
                              </div>
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <span className="icon-horizontal-three-dot"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.ItemText>
                                        Options
                                      </Dropdown.ItemText>
                                      <Dropdown.Item as="button">
                                        Share Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Export JSON
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Duplicate Model
                                      </Dropdown.Item>
                                      <Dropdown.Item as="button">
                                        Edit Model
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="text-danger"
                                      >
                                        Remove Model
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                            </div>
                            <div className="data-card-body" onMouseOver={() => handleMouseOver(3)} onMouseOut={() => handleMouseOut(3)}>
                              <span className="modal-name">
                                Model/Carbon Mitigation
                              </span>
                              <h2 className="data-card-title">
                                Clean Enerygy to Sustainable...
                              </h2>
                              <ul className="list-item-wrapper">
                                <li className="list-item">Carbon Tax: $2.00</li>
                                <li className="list-item">
                                  NPV: $-1,280,938,930
                                </li>
                                <li className="list-item">
                                  Emissions Mitigated: 9937 MMT
                                </li>
                              </ul>
                            </div>
                            <div className="data-card-footer">
                              <div className="card-dropdown">
                                {[''].map((direction) => (
                                  <Dropdown
                                    className="create-modal-dropdown three-dot-dropdown"
                                    as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    variant="secondary"
                                    align="end"
                                  // show={true}
                                  >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      {/* Add active class for button active bg */}
                                      <div className="card-view active">
                                        <span className="card-view-img">
                                          <img src={cardViewLogo} alt="img logo" />
                                        </span>
                                        <span className="card-view-number">12</span>

                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-menu-inner">
                                        <Dropdown.ItemText>
                                          Shared with
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              <img
                                                src={hydrogenLogoSmall}
                                                alt="logo"
                                                className="user-img"
                                              />
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head current-user-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                              <span className='current-user'>(You)</span>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button">
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                        >
                                          <div className="dropdown-wrapper">
                                            <span className="user-icon">
                                              A
                                            </span>
                                            <div className="right-sec">
                                              <h3 className="dropdown-head">
                                                Green Hydrogen Basecase
                                              </h3>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ))}
                              </div>
                              <a href="" className="back-project">
                                <span className="icon-next-arrow-grey"></span>
                              </a>
                            </div>
                          </div>
                          <span className='card-created-date'>Created Today</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dashboard-right">
                    <div className='activity-head-wrap'>
                      <h4 className="activity-head vertical_divider active">Latest Activities</h4>
                      <h4 className="activity-head">Archive</h4>
                    </div>

                    <div className="dashboard-right-inner">
                      <div className='activity-outer-wrap active'>
                        <div className='activity-header'>
                          <span className='activity-date'>Today</span>
                          <span className='mark-read-text'>Mark all as read</span>
                        </div>
                        <ActivitySec />
                        <div className='activity-shadow-anim'></div>

                        <ActivitySec />
                        <ActivitySec />
                      </div>
                      <div className='activity-outer-wrap'>
                        <div className='activity-header'>
                          <span className='activity-date'>Yesterday</span>
                        </div>
                        <ActivitySec />
                        <div className='activity-shadow-anim'></div>
                        <ActivitySec />
                        <ActivitySec />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
