import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { IProerties, NUMBER } from '../../constants';

interface ITurbineHeightProps {
  show: boolean
  setShowHide: (show: boolean) => void
  property: IProerties
  onSave: (prop: IProerties, value: string) => void
  list: string[]
  defaultValue: string
}

interface ITurbineList {
  value: string
  label: string
}

function TurbineHeightModal(props: ITurbineHeightProps) {
  const { show, setShowHide, list, defaultValue, property, onSave } = props;
  const [selectedTurbine, setSelectedTurbine] = useState<ITurbineList>({ value: '', label: '' });
  const [turbineHeight, setTurbineHeight] = useState<number | string>('');
  const [turbineList, setTurbineList] = useState<ITurbineList[]>([]);
  const [formError, setFormError] = useState(false);
  /**
   * Funtion to set default selected value and format the turbine list data...
   */
  const setTurbineAllList = useCallback(() => {
    const propertyValue = defaultValue?.split(',');
    const allTurbines: ITurbineList[] = list.map((item) => {
      const selectedOption = propertyValue?.[NUMBER.N0];
      const optionObj = { value: item, label: item };
      if (selectedOption === item) {
        setSelectedTurbine(optionObj);
      }
      return optionObj;
    });
    setTurbineHeight(propertyValue?.[NUMBER.N1] || '');
    setTurbineList(allTurbines);
  }, [list]);

  /**
   * useEffect to run the setTurbineAllList funtion while rendering
   */
  useEffect(() => {
    setTurbineAllList();
  }, [list]);

  /**
   * funtion to format the value and update it inside the parameter form...
   */
  const updateValue = () => {
    if (turbineHeight === '' || !selectedTurbine.value) {
      setFormError(true);
    } else {
      const val = `${selectedTurbine?.value},${turbineHeight}`;
      onSave(property, val);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowHide(false)}
        dialogClassName="modal-680 top-right-modal modal-dialog-centered"
        className="forget-modal setting-modal google-map-modal object-parameters-modal turbine-height-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowHide(false)} >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium modal-head-large-light">Choose Turbine Height</h4>
          <Form className="object-parameter-form turbine-height-form">
            <div className="custom-select-main">
              <Form.Label>Turbine Name</Form.Label>
              <Select
                className={`custom-select-wrp ${formError && !selectedTurbine.value ? 'error-field' : ''}`}
                classNamePrefix="select"
                defaultValue={selectedTurbine}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={turbineList}
                placeholder="Select a Turbine"
                onChange={(event) => setSelectedTurbine(event as ITurbineList)}
                value={selectedTurbine}
              />
              {formError && !selectedTurbine.value && <span className='error-msg'>Please select turbine name.</span>}
            </div>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Turbine Height</Form.Label>
              <Form.Control
                type="number"
                className={`${formError && turbineHeight === '' ? 'error-field' : ''}`}
                value={turbineHeight}
                min={NUMBER.N0}
                autoComplete="false"
                onChange={(event) => setTurbineHeight(event.target.value)}
              />
              {property.unit &&
                <div className="custom-select-main capital-cost-select" title={property.unit}>
                  <Select
                    className="custom-select-wrp"
                    classNamePrefix="select"
                    defaultValue={[{ value: property.unit, label: property.unit, isDisabled: true }]}
                    isDisabled={true}
                    name="color"
                    options={[{ value: property.unit, label: property.unit, isDisabled: true }]}
                  />
                </div>
              }
              {formError && turbineHeight === '' && <span className='error-msg'>Please enter valid turbine height.</span>}
            </Form.Group>
            <Modal.Footer>
              <Button className="btn-no-outline" onClick={() => setShowHide(false)}>
                Cancel
              </Button>
              <Button className="primary" onClick={updateValue}>Save Changes</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TurbineHeightModal;
