import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import SearchAndFilter from '../../components/shared/SearchAndFilter';
import { adminDsiderLogo, falkensteinLogo, hydrogenLogoSmall, infoInputLogo, shortingLogo, threeDotHorizontal, userIcon, versionLogo } from '../../utils';

import Select from 'react-select';
import DsiderPagination from './DsiderPagination';
import { Link } from 'react-router-dom';




const AdminDashboard = () => {
  const handleSearch = (val: string) => {
  };
  const [ShowObjectModal, setShowObjectModal] = useState(false);
  const [ShowUserCompany, setShowUserCompany] = useState(false);


  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'super- user', label: 'Super- User' },
    { value: 'consumer', label: 'Consumer' },
    { value: 'collaborator', label: 'Collaborator' },

  ];

  const selectCompany = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'nexaSynergy', label: 'NexaSynergy' },
    { value: 'quantaQuest', label: 'QuantaQuest' },
    { value: 'eternaTec solutions', label: 'EternaTec Solutions' },
    { value: 'zenithCraft innovations', label: 'ZenithCraft Innovations' },
    { value: 'StellarBloom Creations', label: 'StellarBloom Creations' },
    { value: 'VivaVista Enterprises', label: 'VivaVista Enterprises' },

  ];
  const projectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Hydrogen, EV', label: 'Hydrogen, EV' },
    { value: 'Power Turbine', label: 'Power Turbine' },
    { value: 'Solar Turbine', label: 'Solar Turbine' },

  ];

  return (
    <>
      <div className='admin-sidebar'>
        <div className='dsider-logo-wrap'><img src={adminDsiderLogo} alt="logo img" /></div>
        <Nav variant="tabs" className='admin-sidebar-wrap' defaultActiveKey="/home">
          <Nav.Item>
            <Nav.Link href="/home">User Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-1">Company Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-2">Object Management</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
      <div className='admin-section'>
        <div className='admin-body'>
          <div className='admin-body-header'>
            <div className='admin-header-wrap'>
              <h2 className='admin-section-title'>All Users (104)</h2>
              <div className='admim-profile-wrap'>
                <Dropdown className="profile-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="user-icon">
                      r
                      {/* <img className="user-img" src={userIcon} alt="user" /> */}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-wrapper">
                      <span className="user-icon">A</span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">Andy Smith</h3>
                        <span className="dropdown-email">
                          andysmith038@email.com
                        </span>
                        <div className="dropdown-footer">
                          <Button className="btn-outline small">
                            Edit Profile
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="account-setting">
                      <Link to="#" className="account-item">
                        Switch to Dsider Dashboard
                      </Link>
                      <Link to="#" className="account-item">
                        Change Password
                      </Link>
                      <Link to="#" className="account-item sign-out">
                        Sign Out
                      </Link>
                    </div>
                    <div className="version-sec">
                      <img src={versionLogo} alt="logo" />
                      <span className="version-text">Version v2.0.3</span>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='add-user-wrap'>
              <SearchAndFilter
                placeHolder="Search by name or email"
                onSearch={handleSearch}
                className="scenario-list-search"
              />
              <Button onClick={() => setShowObjectModal(true)}>+ New User</Button>
            </div>
          </div>
          <div className="col-md-12">
            {/* table */}
            <div className='table-wrapper'>
              <div className="table-container contest-table admin-table-container">
                <div className='table-inner-wrap'>
                  <div className="table-head">
                    <div className="table-row">
                      <div className="th">name
                        <Button className='btn-no-outline shorting-btn'><img src={shortingLogo} alt="shorting logo" className='shorting-logo' /></Button>
                      </div>
                      <div className="th">email</div>
                      <div className="th">company</div>
                      <div className="th">role</div>
                      <div className="th">scenarios </div>
                      <div className="th">status
                        <Button className='btn-no-outline shorting-btn'><img src={shortingLogo} alt="shorting logo" className='shorting-logo' /></Button>
                      </div>
                      <div className="th">last session
                        <Button className='btn-no-outline shorting-btn'><img src={shortingLogo} alt="shorting logo" className='shorting-logo' /></Button>
                      </div>
                      <div className="th">action</div>
                    </div>
                  </div>
                  <div className="table-body">
                    <div className="table-row active">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part" onClick={() => setShowUserCompany(true)}>
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              am
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Amy McGrawl
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Crafty Emporium"><span className='td-text company-name'>Crafty Emporium</span></div>
                      <div className="td" data-label="Collaborator"><span className='td-text'>Collaborator</span></div>
                      <div className="td" data-label="1"><span className='td-text'>1</span></div>
                      <div className="td" data-label="active"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="today"><span className='td-text'>today</span></div>
                      <div className="td" data-label="dropdown">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row inactive">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status inactive'>Inactive </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                    <div className="table-row">
                      <div className="td" data-label="Model">
                        <div className="tab-left-part">
                          <div className="dropdown-wrapper shared-profile">
                            <span className="user-icon">
                              {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                              cj
                            </span>
                            <div className="right-sec">
                              <h3 className="dropdown-head">
                                Charlotte  Jones
                              </h3>
                              <p className="dropdown-des mediam">
                                Joined on May 7, 2023
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                      <div className="td" data-label="Variable"><span className='td-text company-name'>Packaging First-Choice</span></div>
                      <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                      <div className="td" data-label="Local Tax Credits"><span className='td-text'>2</span></div>
                      <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                      <div className="td" data-label="LCOH"><span className='td-text'>June 5, 2023</span></div>
                      <div className="td" data-label="LCOE">
                        <div className="tab-right-part">
                          {[''].map((direction) => (
                            <Dropdown
                              className="create-modal-dropdown three-dot-dropdown"
                              as={ButtonGroup}
                              key={direction}
                              id={`dropdown-button-drop-${direction}`}
                              variant="secondary"
                              align="end"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={threeDotHorizontal}
                                  alt="three dot icon"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.ItemText>
                                  Options
                                </Dropdown.ItemText>
                                <Dropdown.Item as="button">
                                  See Details
                                </Dropdown.Item>
                                <Dropdown.Item as="button">
                                  Manage User Role
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Deactivate User
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                >
                                  Delete User
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
                <DsiderPagination />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Object Parameters modal start here */}
      <Modal
        show={ShowObjectModal}
        onHide={() => setShowObjectModal(false)}
        dialogClassName="modal-701 top-right-modal add-user-modal modal-dialog-centered"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowObjectModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New User</h4>
          <p className='modal-head-des'>Fill in the details of the user you
        want to add to your organisation. A request will be sent to
        the added user. The user will be onboarded when they accept the invitation & confirm their profile.</p>
          <Form className="object-parameter-form add-uder-form">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="latitude">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="number"
                    // value={locationLat}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="longitude">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    // value={locationLng}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="longitude">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="custom-select-main mb-0">
                  <Form.Label>Name</Form.Label>
                  <Select
                    className="custom-select-wrp"
                    classNamePrefix="select"
                    defaultValue={colourOptions[1]}
                    // isDisabled={isDisabled}
                    isLoading={isLoading}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={colourOptions}
                    placeholder="User Role"
                  // menuIsOpen={true}
                  />
                  <div className="tooltip-container">
                    <Button className='btn-no-outline info-btn'><img src={infoInputLogo} alt="log" /></Button>
                    <div className="tooltip-text">See Permission</div>
                  </div>

                </div>
              </div>
              <div className="col-md-12">
                <div className="custom-select-main">
                  <Form.Label>Select Company</Form.Label>
                  <Select
                    className="custom-select-wrp"
                    classNamePrefix="select"
                    defaultValue={selectCompany[1]}
                    // isDisabled={isDisabled}
                    isLoading={isLoading}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={selectCompany}
                    placeholder="User Role"
                  // menuIsOpen={true}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Button className='send-invite-btn'>Send Invite</Button>
              </div>

            </div>
          </Form>


        </Modal.Body>
      </Modal>
      {/* User company modal start here */}
      <Modal
        show={ShowUserCompany}
        onHide={() => setShowUserCompany(false)}
        dialogClassName="modal-800 top-right-modal add-user-modal modal-dialog-centered user-compamy-modal"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowUserCompany(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-left-part">
            <div className="dropdown-wrapper shared-profile user-name-email">
              <span className="user-icon orange medium">
                js
              </span>
              <div className="right-sec">
                <h3 className="dropdown-head extra-large">
                  James Smith
                </h3>
                <p className="dropdown-des email extra-large">
                  jamessmith044@email.com
                </p>
              </div>
            </div>
          </div>
          <div className='user-card-main-wrap justify-content-center'>
            <div className='user-info-card user-activity-card'>
              <h3 className='user-info-card-title'>Users Activity</h3>
              <div className='user-card-body'>
                <div className='user-card-active'>
                  <h2 className='user-card-sec-head'>08</h2>
                  <span className='user-status-des'>Projects</span>
                </div>
                <div className='user-card-active'>
                  <h2 className='user-card-sec-head'>21</h2>
                  <span className='user-status-des'>Scenario</span>
                </div>
                <div className='user-card-active user-card-inactive'>
                  <h2 className='user-card-sec-head'>12</h2>
                  <span className='user-status-des'>Simulations</span>
                </div>

              </div>
            </div>
          </div>
          <div className='company-name-wrap user-company-status'>
            <div className='compnay-logo-wrap'>
              <img src={falkensteinLogo} alt="logo" className='company-logo-img' />
            </div>
            <div className='company-des-wrap'>
              <h4 className="company-name-des">Company: <span className='company-name-email'>AngelEcosystems.co</span> </h4>
              <p className='modal-head-des'>Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum</p>
            </div>
          </div>
          <div className="custom-select-main">
            <Form.Label>User Role</Form.Label>
            <Select
              className="custom-select-wrp"
              classNamePrefix="select"
              defaultValue={colourOptions[1]}
              // isDisabled={isDisabled}
              isLoading={isLoading}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={colourOptions}
              placeholder="User Role"
            // menuIsOpen={true}
            />
            <div className="tooltip-container">
              <Button className='btn-no-outline info-btn'><img src={infoInputLogo} alt="log" /></Button>
              <div className="tooltip-text">See Permission</div>
            </div>

          </div>
          <span className='last-activity'>Last active 2d ago</span>
          <div className="delete-compnay-btn-wrap">
            <div className="delete-company-btn">
              <Button className='btn-no-outline deactive-user'>Deactivate User</Button>
              <Button className='btn-no-outline delete-comp'>Delete User</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default AdminDashboard;
