import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { aiMapImg, electrolysisLogo, gotoWizardLogo, modalBgImg, redirectArrow } from '../../utils/icons';

const WizardQuestion = () => {
  return (
    <>
      <div className="change-pw-modal create-modal wizard-ques-section">
        <button className='close-btn'>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className='modal-dialog full-width-dialog'>
          <div className='modal-content'>
            <Modal.Body>
              <Modal.Title>Clean Energy Scenario for Buildings</Modal.Title>
              <p className="modal-title-des">
                Answer a few questions and we will set up a scenario for you.
              </p>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>What is the location of the asset?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-input-profile-wrap'>
                        <div className='wizard-ques-input'>
                          <span className='wizard-input-des'>Select</span>
                        </div>
                        <div className="dropdown-wrapper">
                          <span className="user-icon">
                            a
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>What power sources do you want to consider?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Solar"
                            name="companycheckbox"
                          />

                        </div>
                      </div>

                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Turbine"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Battery"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <div className='wizard-input-profile-wrap'>
                        <div className='wizard-ques-input'>
                          <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Power Grid"
                              name="companycheckbox"
                            />

                          </div>
                        </div>
                        <div className="dropdown-wrapper">
                          <span className="user-icon">
                            a
                          </span>
                        </div>

                      </div>
                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>Define the capacity for selected power sources.</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input rule-input'>
                        <span className='wizard-input-des'>Solar:</span>
                        <span className='wizard-input-des rule'></span>
                        <span className='wizard-input-des'>KW</span>

                      </div>
                      <div className='wizard-ques-input rule-input'>
                        <span className='wizard-input-des'>Turbine:</span>
                        <span className='wizard-input-des rule'></span>
                        <span className='wizard-input-des'>KW</span>
                      </div>
                      <div className='wizard-ques-input rule-input'>
                        <span className='wizard-input-des'>Battery:</span>
                        <span className='wizard-input-des rule'></span>
                        <span className='wizard-input-des'>KW</span>
                      </div>
                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>What are the sources of demand for hydrogen?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Mobility (10,000 kw)"
                            name="companycheckbox"
                          />
                        </div>
                      </div>

                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Refinery (20,000 kw)"
                            name="companycheckbox"
                          />
                        </div>
                      </div>
                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>Do you want to include Hydrogen Storage?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Storage (10,000 kw)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>

                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="External Hydrogen (20,000 kw)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>Credit & Subsidies?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="45Q (0)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>

                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Tax Credit Hydrogen (1)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Tax Power Hydrogen (1)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Subsidies (0)"
                            name="companycheckbox"
                          />

                        </div>
                      </div>
                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>Sceanrio Time Frame?</h2>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper radio-wrap-grey">
                          <Form.Check
                            type="radio"
                            id="default-checkbox"
                            label="5 Years"
                            name="year"
                          />

                        </div>
                      </div>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper radio-wrap-grey">
                          <Form.Check
                            type="radio"
                            id="default-checkbox1"
                            label="10 Years"
                            name="year"
                          />

                        </div>
                      </div>
                      <div className='wizard-ques-input'>
                        <div className="checkbox-wrapper radio-wrap-grey">
                          <Form.Check
                            type="radio"
                            id="default-checkbox2"
                            label="20 Years"
                            name="year"
                          />

                        </div>
                      </div>

                      <Button className='shadow'>Submit</Button>
                    </div>
                  </div>
                  <div className="wizard-ques-main">
                    <div className="wizard-ques-inner">
                      <h2 className='wizard-ques-head'>Based on your inputs, we have created a scenario for you.</h2>
                      <div className="message my-message">
                        <div className="ai-img-msg">
                          <img
                            src={gotoWizardLogo}
                            alt="logo"
                            className="ai-map-img"
                          />

                          <Button className="simulation-summary-btn btn btn-primary">See Work Bench
                            <img
                              src={redirectArrow}
                              alt="logo"
                              className="redirectArrow-chat"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='wizard-ques-input-wrap'>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>
    </>
  );
};

export default WizardQuestion;
