import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { modalBgImg } from '../../utils/icons';

const CreateModal = () => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>

      {/* <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        dialogClassName="full-width-dialog"
        className="change-pw-modal create-modal"
      > */}
      <div className="change-pw-modal create-modal">
        <button className='close-btn'>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className='modal-dialog full-width-dialog'>
          <div className='modal-content'>
            <Modal.Body>
              <Modal.Title>Create a Scenario</Modal.Title>
              <p className="modal-title-des">
                Type: <span className="title-des-bold">Green Hydrogen</span>
              </p>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="create-modal-left">
                        <div className="modal-left-img">
                          <img src={modalBgImg} alt="modal image" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="create-modal-right">
                        <Form className="full-width-form">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Name <sup>*</sup>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Company name" />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                              Description <sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              placeholder="Company description*"
                              as="textarea"
                              rows={5}
                            />
                          </Form.Group>
                          <div className="sign-btn-wrapper">
                            <Button variant="primary" type="submit">
                              Save & Proceed
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateModal;
