import React, { useState } from 'react';
import DropDownMenu from '../shared/DropDownMenu';
import { IProjectDetails, MESSAGES, NUMBER, PROJECT_TYPE } from '../../constants';
import { useRemoveProjectMutation } from '../../redux/services/projectApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CREATE_PROJECT, SCENARIOS, setLocalStorageItem } from '../../utils';
import { LABELS } from '../../constants/labelConstant';
import DateTime from '../shared/DateTime';

interface IPorjectProps {
  cardImage: string
  data: IProjectDetails
  refreshData: (id: string | undefined) => void
}

const ProjectCard = (props: IPorjectProps) => {
  const { cardImage, data, refreshData } = props;
  const userEmail = useAppSelector(state => state.auth.email);
  const isNotProjectOwner = userEmail !== data.userCreator;
  const [showAlert, setShowAlert] = useState(false);
  const [removeProject] = useRemoveProjectMutation();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const handleEditClick = (obj: IProjectDetails) => {
    navigation(`${CREATE_PROJECT}?project_id=${obj._id}`);
  };

  const handleRemoveClick = (projectId: string | undefined) => {
    setShowAlert(false);
    const payload = {
      project_id: projectId
    };
    dispatch(startFullLoading());
    removeProject(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.PROJECT_DELETED);
        refreshData(projectId);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        dispatch(stopFullLoading());
      }
    });
  };

  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const handleCardClick = (project: IProjectDetails) => {
    setLocalStorageItem('selectedProject', project);
    navigation(`${SCENARIOS}?projectId=${project._id}&name=${project.name}&projectType=${project.project_type}`);
  };

  const menuOptions = [
    {
      name: 'Edit Details',
      onClickFn: handleEditClick,
      disabled: isNotProjectOwner
    },
    {
      name: 'Remove Project',
      onClickFn: showConfirmationAlert,
      optionClassName: 'text-danger',
      disabled: isNotProjectOwner
    }
  ];

  return (
    <div className="col-md-4" onMouseEnter={() => document.body.click()} onMouseLeave={() => document.body.click()}>
      <div className="project-card">
        <div className="project-card-img" onClick={() => handleCardClick(data)}>
          <img
            src={cardImage}
            alt="card logo"
            className="card-img"
          />
        </div>
        <div className="project-card-content" onClick={() => handleCardClick(data)}>
          <h3 className="project-card-title">
            {data.name}
          </h3>
          <div className="tooltip-container">
            <p className="project-card-des">
              {data.description}
            </p>
            <div className="tooltip-text">{data.description}</div>
          </div>
          <p className="project-card-source">
            {PROJECT_TYPE[data.project_type]} | {data.scenarios_count} {data.scenarios_count === NUMBER.N1 ? 'scenario' : 'scenarios'}
          </p>
          <span className="project-card-date">
            <DateTime createdDate={data.createDate} updatedDate={data.updatedAt} />
          </span>
        </div>
        <DropDownMenu
          options={menuOptions}
          className="d-none"
          optionLabel='Options'
          data={data}
        />
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.DELETE_ALERT_TITLE}
        _id={data._id}
        message={MESSAGES.PROJECT_DELETE_ALERT}
        cancleBtnText={LABELS.KEEP_IT_FOR_NOW}
        yesBtnText={LABELS.YES_DELETE}
        onConfirm={(_id) => handleRemoveClick(_id)}
        onCancel={hideConfirmationAlert}
      />
    </div>
  );
};

export default ProjectCard;
