/* eslint-disable @typescript-eslint/promise-function-async */
import React, { FC, useEffect, useState } from 'react';
import Highcharts, { ExportingOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsStock from 'highcharts/modules/stock';
import HighchartsData from 'highcharts/modules/export-data';
import { EV_GRAPH_TYPE, IScenarioDetails, NUMBER, USER_PERMISSIONS, SCENARIO_GRAPH_LIST, SCENARIO_TYPE_LABELS, IGraphList } from '../../../constants';
import GraphRadioButtons, { IRadioButtons } from './GraphRadioButtons';
import {
  GraphDataInterface, commonMarker,
  dataGroupingObj,
  getGraphOptionData,
  getEVDemandGraph, getEVFleetGraph,
  getEVVisitsGraph, handleDataGrouping,
  getGeoThermalGraphOptionData
} from './GraphUtils';
import { Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../../redux/hooks';
import { useGetS3UrlJsonDataMutation } from '../../../redux/services/simulationApi';
import PowerGraphScratch from './PowerGraphScratch';
import * as XLSX from 'xlsx';
import { getGeoThermalGraphSeries } from '../../../utils';
import MonteCarloSimulation from '../../simlulation/MonteCarloSimulation';
import Form from 'react-bootstrap/Form';

// Initialize the required Highcharts modules
HighchartsBoost(Highcharts);
HighchartsExporting(Highcharts);
HighchartsStock(Highcharts);

interface Props {
  radioOptions: IRadioButtons[]
  title: string
  formType: string
  scenarioId: string
  simulationData: any
  simulationHandler?: () => void
  setEVTraffic?: React.Dispatch<React.SetStateAction<number>>
  projType: string
  scenarioDetails?: IScenarioDetails
  EVTraffic?: number
  selectedValue?: number
  dailyMiles?: number
  setDailyMiles?: any
  accessHC?: number
  setAccessHC?: any
  preferenceHC?: number
  setPreferenceHC?: any
  handleValueChange?: any
}

interface IGraphSeries {
  series: any
  graphName: string
}

const GraphHOC: FC<Props> = (
  {
    title, radioOptions, formType, scenarioId, simulationData, selectedValue, dailyMiles, setDailyMiles,
    accessHC, setAccessHC, preferenceHC, setPreferenceHC, handleValueChange, simulationHandler = () => { }, setEVTraffic = () => { }, projType, ...rest
  }
) => {
  const S3_JSON_URL = useAppSelector(state => state.simulationMetricsData.S3_URI_JSON);
  const S3__ONEYEAR_JSON_URL = useAppSelector(state => state.simulationMetricsData.S3_URI_ONEYEAR_JSON);
  const simulationMetricsData = useAppSelector(state => state.simulationMetricsData);
  const powerGenerationProfile = simulationMetricsData?.['POWER GENERATION PROFILE'];
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(radioOptions[NUMBER.N0].value);
  const userData = useAppSelector(state => state.auth);
  const { permission: haveSharedPermission } = useAppSelector(state => state.scenarioDetails);
  const canDownloadGraphData = userData.permissions?.includes(USER_PERMISSIONS.download_graph_data);
  const canDownloadGraphImage = userData.permissions?.includes(USER_PERMISSIONS.download_graph_image) || haveSharedPermission;
  const [getS3UrlJsonData] = useGetS3UrlJsonDataMutation();
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState<GraphDataInterface[]>([]);
  const [graphSeriesData, setGraphSeriesData] = useState<IGraphSeries[]>([]);
  const [graphList, setGraphList] = useState<IGraphList[]>([]);
  const [isMonteCarloSimulation, setIsMonteCarloSimulation] = useState(false);

  // EV Graphs...
  const [EVGraphData, setEVGraphData] = useState({});
  const [EVDemandData, setEVDemandData] = useState({});
  const [EVVisitsData, setEVVisitsData] = useState({});

  const [startYear, setStartYear] = useState<any>();
  const [endYear, setEndYear] = useState<any>();
  const [yearCount, setYearCount] = useState<any>();
  const transformedData: any[] = [];
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = () => {
    if (isChecked) {
      getSimDataBasedOnSelection(S3__ONEYEAR_JSON_URL);
    } else {
      getSimDataBasedOnSelection(S3_JSON_URL);
    }
    setIsChecked(!isChecked);
  };


  /**
   * Code For Power, Hydrogen, CCUS Graphs...
   */

  useEffect(() => {
    if (graphData.length > NUMBER.N0) {
      const graphToRender: IGraphList[] = JSON.parse(JSON.stringify({ ...SCENARIO_GRAPH_LIST }[projType]));
      // if graphKeys are empty then get the seriesKeys from get-metrics-data api...
      graphToRender.forEach(graph => {
        if (Object.keys(graph.graphKeys).length === NUMBER.N0 && graph.seriesKeys) {
          graph.graphKeys = simulationMetricsData[graph.seriesKeys] || {};
        }
      });

      setGraphList(graphToRender);
      graphToRender.map(graph => {
        const seriesData = getGraphSeriesData(graphData, graph.type, graph.graphKeys);
        const seriesOptionData = getGraphOptionData(seriesData, graph, isChecked);
        setGraphSeriesData(prevState => [...prevState, { series: seriesOptionData, graphName: graph.graphName }]);
      });
      setSelectedRadioOption(radioOptions[NUMBER.N0].value);
      setGraphLoading(false);
    }
  }, [graphData]);

  const getGraphSeriesData = (data: any[], graphType: string, graphKeys: any) => {
    if (graphKeys) {
      return Object.keys(graphKeys)
        .filter(key => (Object.prototype.hasOwnProperty.call(data[NUMBER.N0], key)))
        .map((key: any) => ({
          name: (graphKeys as any)[key],
          type: graphType,
          data: data.map((a: any) => [new Date(a.TimeUTC + 'Z').getTime(), a[key]?.value]),
          dataGrouping: dataGroupingObj,
          marker: commonMarker
        }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (S3__ONEYEAR_JSON_URL) {
      getSimDataBasedOnSelection(S3__ONEYEAR_JSON_URL);
      setIsChecked(false);
    } else if (S3_JSON_URL) {
      getSimDataBasedOnSelection(S3_JSON_URL);
      setIsChecked(true);
    }
  }, [S3_JSON_URL]);

  const getSimDataBasedOnSelection = (url: string) => {
    if (url) {
      let gData = {
        columns: [],
        data: []
      };
      setGraphLoading(true);
      getS3UrlJsonData(url).then((response) => {
        if ('data' in response) {
          setGraphSeriesData([]);
          gData = response.data;
          // process the Graph data in batches...
          batchProcessGraphData(gData.data, gData.columns, NUMBER.N2000, NUMBER.N0);
        } else {
          setGraphLoading(false);
        }
      });
    }
  };

  /**
   * @param data graph data...
   * @param batchSize array length which we want to process at a time
   * @param currentIndex current index from where loop will be started...
   */

  const batchProcessGraphData = (data: any, columns: any, batchSize: number, currentIndex: number) => {
    for (let index = currentIndex; index < Math.min(currentIndex + batchSize, data.length); index++) {
      const obj = mapGraphDataWithColumns(columns, data[index]);
      transformedData.push(obj);
    }

    if (currentIndex + batchSize < data.length) {
      setTimeout(() => {
        batchProcessGraphData(data, columns, batchSize, currentIndex + batchSize);
      }, NUMBER.N100);
    } else {
      setGraphData(transformedData);
      setStartYear(new Date(data[NUMBER.N0][NUMBER.N0])?.getFullYear());
      setEndYear(new Date(data[data.length - NUMBER.N1][NUMBER.N0])?.getFullYear());
    }
  };

  const mapGraphDataWithColumns = (columns: any, data: any) => {
    return columns.reduce((acc: any, column: any, i: number) => {
      acc[column] = column === 'TimeUTC' ? data[i] : { value: data[i], label: (SCENARIO_TYPE_LABELS[projType] as any)?.[column] };
      return acc;
    }, {});
  };

  /**
   * Code For Geo-Thermal Graphs...
   */
  useEffect(() => {
    if (powerGenerationProfile) {
      setGraphSeriesData([]);
      const graphToRender: IGraphList[] = JSON.parse(JSON.stringify({ ...SCENARIO_GRAPH_LIST }[projType]));
      setGraphList(graphToRender);
      graphToRender.map((graph: IGraphList) => {
        const seriesData = getGeoThermalGraphSeries(simulationMetricsData?.[graph.graphName], graph.secondaryYAxis as string);
        setYearCount(seriesData.years.length);
        const seriesOptionData = getGeoThermalGraphOptionData(seriesData, graph);
        setGraphSeriesData(prevState => [...prevState, { series: seriesOptionData, graphName: graph.graphName }]);
      });
    }
  }, [powerGenerationProfile]);

  /**
   * Code for Ev Graphs...
   */
  useEffect(() => {
    if (simulationData) {
      if (simulationData?.message === 'success') {
        simulationData && setStartYear(new Date(simulationData?.load_profiles[NUMBER.N0]?.date_)?.getFullYear());
        simulationData && setEndYear(new Date(simulationData?.load_profiles[simulationData?.load_profiles?.length - NUMBER.N1]?.date_)?.getFullYear());
        setSelectedRadioOption(radioOptions[NUMBER.N0].value);
        setEVDemandData(getEVDemandGraph(simulationData?.load_profiles));
        setEVVisitsData(getEVVisitsGraph(simulationData?.load_profiles));
        setEVGraphData(getEVFleetGraph(simulationData?.load_profiles));
      }
    }
  }, [simulationData]);



  const exportExcelData = (seriesData: any[]) => {
    const workbook = XLSX.utils.book_new();

    const dataForSheet: any[][] = [['DateTime', ...seriesData.slice(0, -1).map(seriesItem => seriesItem.name)]];
    const maxLength = Math.max(...seriesData.slice(0, -1).map(seriesItem => seriesItem.processedXData.length));

    for (let i = 0; i < maxLength; i++) {
      const row: any[] = [];

      if (seriesData[0].processedXData[i]) {
        row.push(formatDateTime(seriesData[0].processedXData[i]));
      } else {
        row.push('');
      }

      for (const seriesItem of seriesData.slice(0, -1)) {
        // Push the value itself if it's not undefined or null
        const value = seriesItem.processedYData[i];
        row.push(value ?? 0);
      }

      dataForSheet.push(row);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(dataForSheet);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'chart data');

    XLSX.writeFile(workbook, 'chart.xlsx');
  };

  const exportCSVData = (seriesData: any[]) => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    const headers = ['DateTime', ...seriesData.slice(0, -1).map((seriesItem: { name: any }) => seriesItem.name)];
    csvContent += headers.join(',') + '\n';

    const maxLength = Math.max(...seriesData.slice(0, -1).map((seriesItem: { processedXData: string | any[] }) => seriesItem.processedXData.length));
    for (let i = 0; i < maxLength; i++) {
      let row = '';
      if (seriesData[0].processedXData[i]) {
        row += formatDateTime(seriesData[0].processedXData[i]) + ',';
      } else {
        row += ',';
      }
      for (const seriesItem of seriesData.slice(0, -1)) {
        const value = seriesItem.processedYData[i];
        row += value ?? 0;
        if (seriesItem !== seriesData[seriesData.length - 2]) {
          row += ',';
        }
      }
      csvContent += row + '\n';
    }

    // Create a download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'chart.csv');
    document.body.appendChild(link);
    link.click();
  };

  const formatDateTime = (dateTime: string) => {
    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  useEffect(() => {
    if (canDownloadGraphData || canDownloadGraphImage) {
      const imageMenuItems = canDownloadGraphImage ? ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'] : [];
      const dataMenuItems = canDownloadGraphData ? ['customXLS', 'customCSV'] : [];

      const activeMenuItem = dataMenuItems.concat(imageMenuItems);

      HighchartsExporting(Highcharts);
      HighchartsData(Highcharts);
      Highcharts.setOptions({
        exporting: {
          buttons: {
            contextButton: {
              menuItems: activeMenuItem
            }
          },
          menuItemDefinitions: {
            customXLS: {
              text: 'Download XLS',
              textKey: 'Download XLS',
              onclick: function () {
                exportExcelData(this.series);
              }
            },
            customCSV: {
              text: 'Download CSV',
              textKey: 'Download CSV',
              onclick: function () {
                exportCSVData(this.series);
              }
            }
          }
        } satisfies ExportingOptions
      });
    } else {
      Highcharts.setOptions({
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        }
      });
    }
  }, [userData?.isDesiderAdmin]);

  const handleChartRender = (chart: any) => {
    const series = chart?.series[0];
    if (chart?.rangeSelector?.buttons?.length) {
      handleDataGrouping(chart, series);
      // Add event listener to the range selector buttons
      chart.rangeSelector.buttons.forEach((button: any) => {
        button.element.addEventListener('click', () => {
          handleDataGrouping(chart, series, button.textStr);
        });
      });
    }
  };

  const renderYearToggleButtons = () => (<div className='switch-year-btn'>
    <Form>
      <div className='ai-chat-switch-wrap'>
        <span className={`switch-btn-label ${!isChecked ? 'active' : ''}`}>Single Year</span>
        <Form.Check
          type="switch"
          id="custom-switch"
          label=""
          onChange={handleSwitchChange}
          checked={isChecked}
          disabled={S3__ONEYEAR_JSON_URL === undefined}
        />
        <span className={`switch-btn-label switch-btn-label-right ${isChecked ? 'active' : ''}`}>Multi Year</span>
      </div>
    </Form>

  </div>);

  const renderTitle = () => {
    if (startYear === endYear) {
      return (<h2 className="chart-title">{title} ({yearCount ? `${yearCount} Years` : `${startYear ?? ''}`})</h2>);
    } else {
      return (<h2 className="chart-title">{title} ({yearCount ? `${yearCount} Years` : `${startYear ?? ''} - ${endYear ?? ''}`})</h2>);
    }
  };

  return (
    <>
      {!isMonteCarloSimulation && <div className="simulation-chart-wrapper" >
        <div className="chart-left">
          {renderTitle()}
          {graphLoading
            ? <div className='map-data-loader'><Spinner /></div>
            : (
              <div>
                {graphList.length > NUMBER.N0 && (<GraphRadioButtons
                  radioOptions={radioOptions}
                  graphList={graphList}
                  selectedRadioOption={selectedRadioOption}
                  setSelectedRadioOption={setSelectedRadioOption}
                />)}
                <div className='power-graph-wrap'>
                  {graphSeriesData.map((graph, index: number) => (
                    <div key={`${graph.graphName}--${index}`}>
                      {
                        selectedRadioOption === graph.graphName &&
                        <HighchartsReact highcharts={Highcharts} options={graph.series} callback={(chart: any) => {
                          handleChartRender(chart);
                        }} />
                      }
                    </div>
                  ))}
                  {renderYearToggleButtons()}
                </div>
              </div>
            )
          }
          {(projType === 'ev' && simulationData?.message === 'success') && (<div>
            <GraphRadioButtons
              radioOptions={radioOptions}
              selectedRadioOption={selectedRadioOption}
              setSelectedRadioOption={setSelectedRadioOption}
            />
            {selectedRadioOption === EV_GRAPH_TYPE.AVG_DEMAND && (<HighchartsReact highcharts={Highcharts} options={EVDemandData} callback={(chart: any) => {
              handleChartRender(chart);
            }} />)}
            {selectedRadioOption === EV_GRAPH_TYPE.AVG_VISITS && (<HighchartsReact highcharts={Highcharts} options={EVVisitsData} callback={(chart: any) => {
              handleChartRender(chart);
            }} />)}
            {selectedRadioOption === EV_GRAPH_TYPE.FLEET_SIZE && (<HighchartsReact highcharts={Highcharts} options={EVGraphData} callback={(chart: any) => {
              handleChartRender(chart);
            }} />)}
          </div>)}
        </div>
        <PowerGraphScratch formType={formType} simulationHandler={simulationHandler} scenarioDetails={rest.scenarioDetails}
          setSliderValue={setEVTraffic} simulationData={simulationData} EVTraffic={rest.EVTraffic} selectedValue={selectedValue}
          dailyMiles={dailyMiles}
          setDailyMiles={setDailyMiles}
          accessHC={accessHC}
          setAccessHC={setAccessHC}
          preferenceHC={preferenceHC}
          setPreferenceHC={setPreferenceHC}
          handleValueChange={handleValueChange} setIsMonteCarloSimulation={setIsMonteCarloSimulation} />
      </div >}
      {isMonteCarloSimulation && <MonteCarloSimulation setIsMonteCarloSimulation={setIsMonteCarloSimulation} />}
    </>
  );
};

export default GraphHOC;
