import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { NUMBER } from '../../constants';
import { getValueWithUnit, getValueWithUnitFirst } from '../../utils';

interface CostBreakdownProps {
  showPopOverModal: boolean
  setShowPopOverModal: (value: boolean) => void
  modalData: any
  modalTitle: string
  modalUnit?: string
}

const PopoverModal: FC<CostBreakdownProps> = ({ showPopOverModal, setShowPopOverModal, modalData, modalTitle, modalUnit }: any) => {
  const postFix = modalTitle === 'Operating & Maintenance Costs' ? '/yr' : '';
  return (
    <>
      <Modal
        show={showPopOverModal}
        onHide={() => setShowPopOverModal(false)}
        dialogClassName="modal-dialog-centered reservair-modal modal-680"
        className="setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowPopOverModal(false)}>
          <Modal.Title className='modal-head modal-head-medium'>{modalTitle}{` ${modalUnit}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Modal-wrap'>
            <div className="row">
              {Object.keys(modalData).map((key: string) => {
                return (
                  <div className={`${Object.keys(modalData).length > NUMBER.N4 ? 'col-md-6' : 'col-md-12'}`} key={key}>
                    <div className='modal-table-inner'>
                      <p className='modal-table-head'>{key}</p>
                      <span className='modal-table-value'>{
                        typeof modalData[key] === 'string' ? modalData[key] : modalTitle === 'Reservoir Simulation Results'
                          ? getValueWithUnit(modalData[key]) : getValueWithUnitFirst(modalData[key], postFix)
                      }</span>
                    </div>
                  </div>
                );
              })
              }
            </div>
          </div>
        </Modal.Body>
      </ Modal>
    </>
  );
};

export default PopoverModal;
