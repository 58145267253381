import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { parseData, parseRadioBtns } from '../../utils/file_helper';
import GraphRadioButtons from '../shared/Graph/GraphRadioButtons';
import { NUMBER } from '../../constants';

const MonteCarloGraph = ({ inputData }: any) => {
  const [stats, setStats] = useState<any>({});
  const [inputRadioBtns, setInputRadioBtns] = useState<any[]>([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>('');

  useEffect(() => {
    const parsedStats = parseData(inputData);
    setInputRadioBtns(parseRadioBtns(parsedStats));
    setStats(parsedStats);
  }, [inputData]);

  useEffect(() => {
    setSelectedRadioOption(inputRadioBtns?.[NUMBER.N0]?.value);
  }, [inputRadioBtns]);

  if (!stats || Object.keys(stats).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {inputRadioBtns.length && <GraphRadioButtons
        radioOptions={inputRadioBtns}
        selectedRadioOption={selectedRadioOption}
        setSelectedRadioOption={setSelectedRadioOption}
      />}
      {Object.keys(stats).map((category: string) => (
        selectedRadioOption === category &&
        <div key={category}>
          <h2>{category}</h2>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'column'
              },
              title: {
                text: `Statistics for ${category}`
              },
              xAxis: {
                categories: Object.keys(stats[category]),
                title: {
                  text: 'Iterations'
                }
              },
              yAxis: {
                title: {
                  text: 'Values'
                }
              },
              series: [{
                name: category,
                data: Object.values(stats[category])
              }]
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MonteCarloGraph;
