import React from 'react';
import { ADMIN_USER_MANAGEMENT, DASHBOARD, LOGIN, dsiderDashboardLogo, userManagementLogo } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { changeSpinnerTitle, stopFullLoading } from '../../redux/SpinnerSlice';
import { LOADER_TITLE } from '../../constants';
import { signOutUser, updateUserType } from '../../redux/auth/authReducer';
import { toast } from 'react-toastify';
import { defaultUserTypes } from '../../utils/defaultData';

const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSignOutClick = () => {
    dispatch(changeSpinnerTitle(LOADER_TITLE.LOGGING_OUT));
    dispatch(signOutUser())
      .unwrap()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        navigate(LOGIN);
        dispatch(stopFullLoading());
      })
      .catch((error: any) => {
        toast.error(error);
        dispatch(stopFullLoading());
      });
  };

  const navigateTo = (path: string, type: string) => {
    dispatch(updateUserType(type));
    navigate(path);
  };

  return (
    <>
      <div className="main-login admin-login-main">
        <div className="container">
          <div className="main-login-wrapper">
            <div className="form-content-main login-form-content">
              <div className="login-right">
                <div className="login-header">
                  <h3>Admin Sign In</h3>
                </div>
                <div className='admin-login-card-wrap'>
                  <div className='admin-login-card' onClick={() => navigateTo(DASHBOARD, defaultUserTypes.user)}>
                    <img src={dsiderDashboardLogo} alt="logo" className='admin-login-card-img' />
                    <span className='admin-login-card-des'>Dsider Dashboard</span>
                  </div>
                  <div className='admin-login-card' onClick={() => navigateTo(ADMIN_USER_MANAGEMENT, defaultUserTypes.admin)}>
                    <img src={userManagementLogo} alt="logo" className='admin-login-card-img' />
                    <span className='admin-login-card-des'>User Management</span>

                  </div>
                </div>
                <Link
                  to="#"
                  className="forgot-pw"
                  onClick={handleSignOutClick}
                >
                  Sign Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
