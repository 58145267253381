import React from 'react';
import { CREATE_SCENARIO_WITH_AI, CREATE_SCENARIO_WITH_WIZARD, getWorkbenchURL } from '../../utils';
import { CREATE_FROM_SCRATCH, CREATE_WITH_WIZARD, IProjectDetails, IScenarioDetails, ISharedUserList, LABELS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import ScenarioDropdownList from '../shared/ScenarioDropdownList';
import DateTime from '../shared/DateTime';
import ProjectTypeAvatar from '../shared/ProjectTypeAvatar';
import { Button } from 'react-bootstrap';

export interface IScenarioProps {
  data: IScenarioDetails | ISharedUserList
  refreshData: () => void
  projectDetails?: IProjectDetails
  whiteThreeDots?: boolean
  showConfimationAlert?: () => void
  refreshActivity?: () => void
  isSharedWithMeFolder?: boolean
  isBaseLine?: boolean
}

const ScenarioListCard = (props: IScenarioProps) => {
  const { data, refreshData, projectDetails, isBaseLine } = props;
  const navigate = useNavigate();

  const goToScenarioDetails = (id: string, data: any) => {
    const url = getWorkbenchURL(id, data.subProject_Type);
    if (data.subProject_Type === 'ev') {
      if (data.scenarioCreatedType === CREATE_FROM_SCRATCH) {
        navigate(`${url}&scenario_type=${CREATE_FROM_SCRATCH}`);
      } else {
        if (
          'lat' in data &&
          'lng' in data &&
          'radius' in data &&
          'startYear' in data &&
          'endYear' in data
        ) {
          navigate(url);
        } else {
          navigate(`${CREATE_SCENARIO_WITH_AI}?scenario_id=${id}&scenarioName=${data.name}`);
        }
      }
    } else {
      if (data.scenarioCreatedType === CREATE_WITH_WIZARD && !data?.updateDate) {
        navigate(`${CREATE_SCENARIO_WITH_WIZARD}?scenario_id=${data._id}&scenarioName=${data.name}&type=${data.subProject_Type}`, {
          state: {
            project: projectDetails,
            navigateFrom: LABELS.ALL_SCENARIO_LIST
          }
        });
      } else {
        navigate(url, {
          state: {
            project: projectDetails,
            navigateFrom: isBaseLine ? LABELS.BASELINE_SCENARIO_LIST : LABELS.ALL_SCENARIO_LIST
          }
        });
      }
    }
  };

  return (
    <div className="tab-main-wrapper">
      <div className="tab-left-part cursor-pointer" onClick={() => goToScenarioDetails(data._id as string, data)}>
        <div className="dropdown-wrapper shared-profile">
          <ProjectTypeAvatar data={data} />
          <div className="right-sec">
            <h3 className="dropdown-head">{data.name}</h3>
            <p className="dropdown-des">
              <DateTime createdDate={data.createDate} updatedDate={data.updateDate} />
            </p>
          </div>
        </div>
      </div>
      <div className="tab-right-part scenario-actions">
        {data.BaselineStatus && <Button className="status success mr-16">Baseline</Button>}
        <a onClick={() => goToScenarioDetails(data._id as string, data)} className="back-project cursor-pointer" >
          <span className="icon-next-arrow-grey margin-r15"></span>
        </a>
        <ScenarioDropdownList data={data} refreshData={refreshData} isBaseLine={isBaseLine} />
      </div>
    </div>
  );
};

export default ScenarioListCard;
