import React from 'react';
import { Button } from 'react-bootstrap';
import { formatAndRoundOffNumber, formatIntegerOnly, getProjectTypeLogo, getValueWithUnit, getValueWithUnitFirst } from '../../utils';
import { GO_TO_EV, GO_TO_WORKBENCH, MESSAGES, METERS_TO_MILES, NUMBER } from '../../constants';

const HoverModal = ({ data, pType, scenarioName }: any) => {
  return (
    <div className="card-hover-box">
      <div className="card-hover-header">
        <span className="card-hover-img-wrap">
          <img
            src={getProjectTypeLogo(pType)}
            alt="logo img"
            className="card-hover-img"
          />
        </span>
        <div className="card-header-right">
          <h4 className="card-hover-head">
            {scenarioName ?? 'NA'}
          </h4>
        </div>
      </div>
      <HoverListItems data={data} pType={pType} />
      {
        !data && <div className="card-hover-body">
          <span className='no-sim-data'>{MESSAGES.SIMULATE_NOW}</span>
        </div>
      }
      <div className="card-hover-footer">
        <Button className="btn-outline no-radius">
          <span className="icon-go-simulation-icon"></span>
          {pType === 'ev' ? GO_TO_EV : GO_TO_WORKBENCH}
        </Button>
      </div>
    </div >
  );
};

export default HoverModal;


const HoverListItems = ({ data, pType }: any) => {
  const items = getListItems(data, pType);
  return <div className="card-hover-body">{renderListItems(items)}</div>;
};

const getListItems = (data: any, pType: string) => {
  if (!data) {
    return [];
  }
  const metricsData = data?.metrics;
  const currentEV = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'ev_ownership_4_2')?.value as number);
  const projectedEV = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_ev_fleet')?.value as number);
  const avgDemandPV = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_demand_per_visit')?.value as number);

  const avgWeekdayVisits = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_weekday_visits')?.value as number);
  const avgWeekEndVisits = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_weekend_visits')?.value as number);
  const avgWeekVisits = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_week_visits')?.value as number);

  const radiusMap = metricsData?.find((metric: any) => metric.name === 'poi_radius')?.value as number;
  const radiusInMiles = radiusMap * METERS_TO_MILES;

  const avgWeekDayDemand = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_weekday_demand')?.value as number);
  const avgWeekEndDemand = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_weekend_demand')?.value as number);
  const avgWeekDemand = formatAndRoundOffNumber(metricsData?.find((metric: any) => metric.name === 'avg_week_demand')?.value as number);

  const evItems = [
    { label: 'Zipcode', value: data.zipcode },
    { label: 'Radius', value: radiusInMiles?.toFixed(NUMBER.N1) },
    { label: 'POI', value: data.POIs_count },
    { label: 'Total EV Count (Current)', value: currentEV },
    { label: 'Total EV Count (Projected)', value: projectedEV },
    { label: 'Demand Totals Avg Week (KWH)', value: avgWeekDemand },
    { label: 'Demand Totals Avg Weekday (KWH)', value: avgWeekDayDemand },
    { label: 'Demand Totals Avg Weekend (KWH)', value: avgWeekEndDemand },
    { label: 'Visit Totals Avg Week (KWH)', value: avgWeekVisits },
    { label: 'Visit Totals Avg Weekday (KWH)', value: avgWeekdayVisits },
    { label: 'Visit Totals Avg Weekend (KWH)', value: avgWeekEndVisits },
    { label: 'Estimated Demand/Visit (KHW)', value: avgDemandPV }
  ];

  const ECONOMIC_PARAMETERS = data?.['ECONOMIC PARAMETERS'];
  const geothermalItems = [
    { label: 'Emissions Mitigated', value: getValueWithUnit(data?.['RESULTS SUMMARY']?.['Total Avoided Carbon Emissions'], '-') },
    { label: 'Capacity Factor', value: getValueWithUnit(ECONOMIC_PARAMETERS?.['Capacity factor'], '-') },
    { label: 'VIR', value: ECONOMIC_PARAMETERS?.['Project VIR=PI=PIR']?.value },
    { label: 'MOIC', value: ECONOMIC_PARAMETERS?.['Project MOIC']?.value },
    { label: 'IRR', value: getValueWithUnit(ECONOMIC_PARAMETERS?.['Project IRR'], '-') },
    { label: 'NPV', value: getValueWithUnitFirst(ECONOMIC_PARAMETERS?.['Project NPV'], '', '-') },
    { label: 'Total Capex', value: getValueWithUnitFirst(data?.['CAPITAL COSTS (M$)']?.['Total CAPEX'], '', '-') },
    { label: 'Opex/yr', value: getValueWithUnitFirst(data?.['PERATING AND MAINTENANCE COSTS (M$/yr)']?.OPEX, '', '-') },
    { label: 'LCOH', value: getValueWithUnitFirst(data?.['SUMMARY OF RESULTS']?.LCOH, '', '-') },
    { label: 'LCOE/kWh', value: getValueWithUnitFirst(data?.['SUMMARY OF RESULTS']?.LCOE, '', '-') }

  ];

  const defaultItems = [
    { label: 'Total Capex', value: `$${formatAndRoundOffNumber(data.Capex)}` },
    { label: 'Opex/yr', value: `$${formatAndRoundOffNumber(data.Opex)}` },
    { label: 'Period Cost/yr', value: `$${formatAndRoundOffNumber(data.Period_Value)}` },
    { label: 'Local Tax Credits', value: `$${formatAndRoundOffNumber(data.Local_Tax_Credits, '-')}` },
    { label: 'Carbon Tax', value: `$${formatAndRoundOffNumber(data.Carbon_Tax, '-')}` },
    { label: 'LCOE', value: `$${formatAndRoundOffNumber(data.LCOE_Total)}` },
    ...(pType?.toLowerCase() !== 'power'
      ? [{ label: 'LCOH', value: `$${formatAndRoundOffNumber(data.LCOH)}` }]
      : []),
    { label: 'NPV', value: `$${formatAndRoundOffNumber(data.NPV)}` },
    { label: 'IRR', value: data?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(data?.IRR, '-') },
    { label: 'Emissions Mitigated', value: `${formatAndRoundOffNumber(data['Carbon_Abatement_(MT)'])} mt` }
  ];

  const ccusItems = [
    { label: 'Total Capex', value: `$${formatAndRoundOffNumber(data.Capex)}` },
    { label: 'Opex/yr', value: `$${formatAndRoundOffNumber(data.Opex)}` },
    { label: 'Period Cost/yr', value: `$${formatAndRoundOffNumber(data.Period_Value)}` },
    { label: 'Local Tax Credits', value: `$${formatAndRoundOffNumber(data.Local_Tax_Credits, '-')}` },
    { label: 'Carbon Tax', value: `$${formatAndRoundOffNumber(data.Carbon_Tax, '-')}` },
    { label: 'LCOE', value: `$${formatAndRoundOffNumber(data.LCOE_Total)}` },
    { label: 'LCOC', value: `$${formatAndRoundOffNumber(data.levelized_costs_of_gaseous_co2)}` },
    { label: 'NPV', value: `$${formatAndRoundOffNumber(data.NPV)}` },
    { label: 'IRR', value: data?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(data?.IRR, '-') },
    { label: 'Emissions Mitigated', value: `${formatAndRoundOffNumber(data['Carbon_Abatement_(MT)'])} mt` }
  ];

  switch (pType) {
    case 'ev':
      return evItems;
    case 'geothermal':
      return geothermalItems;
    case 'ccus':
      return ccusItems;
    default:
      return defaultItems;
  }
};

const renderListItems = (items: any[]) => (
  <ul className="list-item-wrapper">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item.label}: {item.value}
      </li>
    ))}
  </ul>
);
