import React, { useEffect, useState } from 'react';
import { QuestionListCard, TextElement } from '../../pages/wizard/WizardQuestionType';
import { Button } from 'react-bootstrap';
import { IPowerSourceCapacity, IQuestionProps } from '../../pages/wizard/wizardInterface';
import { NUMBER, SCENARIO_TYPES } from '../../constants';

export const SourcesCapacityQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion } = porps;
  const searchParams = new URLSearchParams(window.location.search);
  const isPower = searchParams.get('type') === SCENARIO_TYPES.power;
  const isPowersourceCapacity = que.question.includes('selected power sources');
  const sourceCapacityType = isPowersourceCapacity ? 'powerSourceCapacity' : 'hydrogenSourceCapacity';

  const [sourceCapacity, setSourceCapacity] = useState(answersList[sourceCapacityType]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    setSourceCapacity(answersList[sourceCapacityType]);
  }, [answersList[sourceCapacityType]]);

  /**
   * @param value updated capacity value
   * @param powerSource selected power source...
   * @returns Function to update the capacity property value...
   */
  const updateSourceCapacity = (value: number, powerSource: IPowerSourceCapacity) => {
    const updatedData = sourceCapacity.map((sc) => {
      if (sc.component === powerSource.component) {
        return {
          ...sc,
          capacity: +value
        };
      }
      return sc;
    });
    setSourceCapacity(updatedData);
    setAnswersList(() => ({
      ...answersList,
      [sourceCapacityType]: updatedData
    }));
  };

  /**
   * @returns Function to handle question navigation if power grid is used or not...
   */
  const updateAnswerListAndNodes = () => {
    if (!answersList.usePowerGrid && isPower) {
      submitQuestion(index + NUMBER.N3);
    } else {
      submitQuestion(index + NUMBER.N1);
    }
  };

  /**
   * Disable submit button if any capacity property value is null...
   */
  useEffect(() => {
    const isAllCapacityValid = sourceCapacity.every(sc => sc.capacity > NUMBER.N0);
    setDisableSubmit(!isAllCapacityValid);
  }, [sourceCapacity]);

  return (
    <div className="wizard-ques-main" key={`${que.question}-${index}`}>
      <QuestionListCard question={que.question} />
      <div className='wizard-ques-input-wrap'>
        {
          answersList[sourceCapacityType]?.map((powerSource, ind) => (
            <TextElement que={que} updateValue={updateSourceCapacity} powerSource={powerSource} unit={powerSource.unit} key={`ques-${ind}`} />
          ))
        }
        <Button disabled={disableSubmit} className='shadow' onClick={() => {
          updateAnswerListAndNodes();
        }}>Submit</Button>
      </div>
    </div>
  );
};
