import { NUMBER } from './numbers';

const { N0, N100, N1, N20, N50, N10, N12, HALF } = NUMBER;
export const compnayData: any[] = [];
for (let i = N0; i < N100; i++) {
  const companyName = `Company ${i + N1}`;
  const email = `company${i + 1}@example.com`;
  const numOfUsers = Math.floor(Math.random() * N100) + N1;
  const projects = Math.floor(Math.random() * N20) + N1;
  const scenarios = Math.floor(Math.random() * N50) + N1;
  const status = Math.random() < HALF ? 'Active' : 'Inactive';

  compnayData.push({
    COMPANY: companyName,
    EMAIL: email,
    NO_OF_USERS: numOfUsers,
    PROJECTS: projects,
    SCENARIOS: scenarios,
    STATUS: status
  });
}

export const companyColumns = ['COMPANY NAME',
  'email',
  'no. of users',
  'projects',
  'scenarios',
  'status'
];

export const companyUserColumns = ['name',
  'status'
];

export const companySortableFields = {
  'COMPANY NAME': true,
  'email': false,
  'no. of users': false,
  'projects': false,
  'scenarios': false,
  'status': true
};

export const companyUserSortableFields = {
  'name': false,
  'status': false
};

export const userSortableFields: Record<string, boolean> = {
  name: true,
  email: false,
  company: false,
  role: false,
  scenarios: false,
  status: true,
  'last Session': true,
  lastSession: true
};

export const userColumns = [
  'name',
  'email',
  'company',
  'role',
  'scenarios',
  'status',
  'last Session'];


export const objectData: any[] = [];


for (let i = N0; i < N10; i++) {
  objectData.push({
    name: `Turbine ${i + N12}`,
    category: `Power Object, Green Hydrogen${i + N12}`,
    'power Category': 'Power Object',
    'created On': 'June 5, 2023'
  });
}

export const objectColumns = ['name', 'category', 'power Category', 'created On'];

export const objectSortableFields = {
  name: true,
  category: false,
  'power Category': false,
  'created On': false
};

export const itemsPerPage = 10;
