import Highcharts, { DataGroupingOptionsObject, Options } from 'highcharts';
import { NUMBER } from '../../../constants';
import { IGraphList, globalObject, rangeSelectorObj, rangeSelectorObjEv } from '../../../constants/graph.constant';
import { formatAndRoundOffNumber, formatNumberToLocale, formatNumberWithCommas } from '../../../utils';



export interface GraphDataInterface {
  TimeUTC: string
  total_supply: string
  total_demand: string
  Solar_Actual: string
  Turbine_Actual: string
  grid_demand_actual: string
  grid_supply_actual_with_transmission_loss: string
  battery_supply_actual_with_transmission_loss: string
  battery_demand_actual: string
}

// Extend Highcharts.AxisLabelsFormatterContextObject with the `total` property
interface ExtendedAxisLabelsFormatterContextObject extends Highcharts.AxisLabelsFormatterContextObject {
  total: number
}

export const commonMarker = {
  symbol: 'circle', // Customize the symbol for the legend
  radius: NUMBER.N6 // Customize the radius of the legend marker
};

export const geoThermalCommonMarker = {
  symbol: 'circle', // Customize the symbol for the legend
  radius: NUMBER.N1 // Customize the radius of the legend marker
};

export const dataGroupingObj: DataGroupingOptionsObject = {
  approximation: 'sum',
  enabled: true,
  forced: true,
  groupAll: true,
  units: [
    ['hour', [NUMBER.N1]],
    ['day', [NUMBER.N1]],
    ['week', [NUMBER.N1]],
    ['month', [NUMBER.N1]],
    ['month', [NUMBER.N3]],
    ['year', [NUMBER.N1]] // group by month, allowing different intervals
  ]
};

export const dashboardGraphGroupingObj: DataGroupingOptionsObject = {
  approximation: 'sum',
  enabled: true,
  forced: true,
  groupAll: true,
  units: [
    ['year', [NUMBER.N1]] // group by month, allowing different intervals
  ]
};

export const dataGroupingEVObj: DataGroupingOptionsObject = {
  approximation: 'sum',
  enabled: true,
  forced: true,
  groupAll: true,
  units: [
    ['day', [NUMBER.N1]],
    ['week', [NUMBER.N1]],
    ['month', [NUMBER.N1]],
    ['month', [NUMBER.N3]],
    ['year', [NUMBER.N1]] // group by month, allowing different intervals
  ]
};

export const tooltipObj = {
  style: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px'
  },
  pointFormatter: function (this: Highcharts.Point) {
    if (this.series.xAxis.options.type === 'datetime') {
      const formattedY = typeof this.y !== 'undefined' ? Highcharts.numberFormat(this.y, NUMBER.N0, '.', ',') : 'N/A';
      return `${this.series.name}: ${formattedY}`;
    } else {
      const yValue = typeof this.y !== 'undefined' ? this.y.toFixed(NUMBER.N0) : 'N/A';
      return `${this.series.name}: ${yValue}`;
    }
  }
};

export const stackedTooltipObj = (legends: any) => {
  return {
    shared: true,
    formatter: function (this: any) {
      const splitArr = this.x;
      const index = splitArr[NUMBER.N1];
      let tooltip = `<b>${legends[index - NUMBER.N1]}</b> </br>`;
      this.points.forEach((ele: any) => {
        const color = ele.point.color ?? 'black';
        tooltip += `<span style="color:${color as string}">${ele.series.name}</span>: ${formatNumberWithCommas(formatAndRoundOffNumber(ele.y as number))}</br>`;
      });
      return tooltip;
    }
  };
};

export const geoThermalTooltipObj = {
  style: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px'
  },
  formatter: function (this: Highcharts.Point) {
    return `Year: ${this.x + NUMBER.N1} <br/> ${this.series.name}: ${formatAndRoundOffNumber(this.y as number, 'N/A')}`;
  }
};

export const dashboardGraphTooltipObj = {
  formatter: function (this: Highcharts.Point) {
    return `${this.series.name}: ${formatNumberWithCommas(this.y as number)}`;
  }
};

export const handleDataGrouping = (chart: any, series: any, buttonType?: string) => {
  let minLength = NUMBER.N0;
  if (buttonType === 'Hourly') { // for hourly data show only 5% of data at a time...
    minLength = Math.ceil(series?.xData?.length * NUMBER.N095);
  }
  // Get the min and max values of the data
  const dataMin = series?.xData[minLength];
  const dataMax = series?.xData[series?.xData.length - 1];

  // Set the extremes of the navigator xAxis to the full range of the data
  chart.xAxis[0].setExtremes(dataMin, dataMax);
};

export const getEVFleetGraph = (simulationData: any) => {
  return {
    global: globalObject,
    chart: {
      type: 'column'
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      },
      crosshair: true
    },
    tooltip: tooltipObj,
    yAxis: {
      title: { text: '' }
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: 'EV Vehicles',
        color: '#6BAD90',
        type: 'column',
        data: simulationData?.map((a: any) => [new Date(a.date_ + 'Z').getTime(), a.traffic_adjusted_fleet_size]),
        dataGrouping: dataGroupingEVObj
      },
      {
        name: 'Non EV Vehicles',
        color: '#107994',
        type: 'column',
        data: simulationData?.map((a: any) => [new Date(a.date_ + 'Z').getTime(), a.traffic_adjusted_non_ev_fleet_size]),
        dataGrouping: dataGroupingEVObj
      }
    ],
    rangeSelector: rangeSelectorObjEv,
    navigator: {
      enabled: true
    }
  } satisfies Options;
};

export const getEVDemandGraph = (simulationData: any) => {
  return {
    global: globalObject,
    chart: {
      type: 'column'
    },
    title: { text: '' },
    tooltip: tooltipObj,
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      },
      crosshair: true
    },
    yAxis: {
      title: { text: 'Power (kWh)' }
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: 'Total Demand',
        color: '#107994',
        type: 'column',
        data: simulationData?.map((a: any) => [new Date(a.date_ + 'Z').getTime(), a.total_demand]),
        dataGrouping: dataGroupingEVObj
      }
    ],
    rangeSelector: rangeSelectorObjEv,
    navigator: {
      enabled: true
    }
  } satisfies Options;
};

export const getEVVisitsGraph = (simulationData: any) => {
  return {
    global: globalObject,
    chart: {
      type: 'column'
    },
    title: { text: '' },
    tooltip: tooltipObj,
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      },
      crosshair: true
    },
    yAxis: {
      title: { text: '' }
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: 'Avg Visits',
        color: '#107994',
        type: 'column',
        data: simulationData?.map((a: any) => [new Date(a.date_ + 'Z').getTime(), a.visits]),
        dataGrouping: dataGroupingEVObj
      }
    ],
    rangeSelector: rangeSelectorObjEv,
    navigator: {
      enabled: true
    }
  } satisfies Options;
};

export const getDashboardEvGraph = (simulationData: any) => {
  return {
    global: globalObject,
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: NUMBER.N180 // Set the height of the chart
    },
    tooltip: {
      formatter: function () {
        return `${this.series.name}: ${formatNumberToLocale(this.y ?? NUMBER.N0)}`;
      }
    },
    title: {
      text: ''
    },
    yAxis: {
      visible: false
    },
    xAxis: {
      type: 'datetime',
      lineColor: '#dddddd', // Set the color of the x-axis line
      tickWidth: NUMBER.N0,
      labels: {
        style: {
          color: '#dddddd' // Set the color of the x-axis labels
        },
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      }
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    plotOptions: {
      line: {
        lineWidth: NUMBER.N1,
        marker: {
          enabled: false
        }
      },
      column: {
        borderWidth: 0
      }
    },
    series:
      [
        {
          name: 'Total Demand',
          color: '#e7e9e9',
          type: 'column',
          data: simulationData?.load_profiles?.map((a: any) => [new Date(a.date_ + 'Z').getTime(), a.total_demand]),
          dataGrouping: {
            approximation: 'sum',
            enabled: true,
            forced: true,
            groupAll: true,
            units: [
              ['year', [NUMBER.N1]] // group by month, allowing different intervals
            ]
          }
        }
      ]
  } satisfies Options;
};

export const getDashboardGraphOptionData = (graphSeriesData: any) => {
  return {
    global: globalObject,
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: NUMBER.N180 // Set the height of the chart
    },
    tooltip: {
      formatter: function () {
        return `${this.series.name}: ${formatNumberToLocale(this.y ?? NUMBER.N0)}`;
      }
    },
    title: {
      text: ''
    },
    yAxis: {
      visible: false
    },
    xAxis: {
      type: 'datetime',
      lineColor: '#dddddd', // Set the color of the x-axis line
      tickWidth: NUMBER.N0,
      labels: {
        style: {
          color: '#dddddd' // Set the color of the x-axis labels
        },
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      }
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    plotOptions: {
      line: {
        lineWidth: NUMBER.N1,
        marker: {
          enabled: false
        }
      },
      column: {
        borderWidth: 0
      }
    },
    series: graphSeriesData
  } satisfies Options;
};


export const getGraphOptionData = (graphSeriesData: any, graphData: IGraphList, graphType?: boolean) => {
  return {
    global: globalObject,
    chart: {
      type: graphData.type
    },
    title: { text: '' },
    tooltip: tooltipObj,
    xAxis: {
      type: 'datetime',
      crosshair: true,
      labels: {
        formatter: (value: any) => {
          return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
        }
      }
    },
    yAxis: {
      title: { text: graphData.yAxisUnit },
      labels: {
        formatter: function() {
          const value: any = this.value;
          if (value >= NUMBER.N1000000) {
            return (value / NUMBER.N1000000) + 'M';
          } else if (value >= NUMBER.N1000) {
            const formattedValue = (value / NUMBER.N1000).toFixed(NUMBER.N1);
            return formattedValue.endsWith('.0') ? formattedValue.slice(NUMBER.N0, -NUMBER.N2) + 'k' : formattedValue + 'k';
          } else {
            return value.toFixed(NUMBER.N2);
          }
        }
      }
    },
    legend: {
      enabled: graphData.showLegends
    },
    series: graphSeriesData,
    rangeSelector: rangeSelectorObj(!graphType),
    navigator: {
      enabled: true
    }
  } satisfies Options;
};

export const getGeoThermalGraphOptionData = (graphSeriesData: any, graphData: IGraphList, hideLegends?: boolean) => {
  return {
    global: globalObject,
    chart: {
      type: 'line',
      height: NUMBER.N480
    },
    exporting: {
      enabled: false
    },
    title: { text: '' },
    tooltip: geoThermalTooltipObj,
    xAxis: {
      categories: graphSeriesData?.years,
      tickInterval: 5,
      startOnTick: false,
      showFirstLabel: false,
      title: {
        text: 'Years'
      }
    },
    yAxis: [{
      title: {
        text: graphData?.yAxisTitle?.primary
      }
    }, {
      title: {
        text: graphData?.yAxisTitle?.secondary
      },
      opposite: true
    }],
    legend: {
      enabled: !hideLegends
    },
    series: graphSeriesData?.series
  };
};

export const getStackedGraphOptions = (graphSeriesData: any, graphLegends: string[], graphCategories: string[], titleVisible?: boolean) => {
  return {
    global: globalObject,
    chart: {
      type: 'column',
      height: NUMBER.N480
    },
    exporting: {
      enabled: false
    },
    title: { text: '' },
    xAxis: {
      categories: graphCategories
    },
    yAxis: [{
      min: NUMBER.N0,
      gridLineWidth: NUMBER.N0,
      stackLabels: {
        enabled: false
      },
      title: {
        text: null
      }
    }, {
      title: {
        text: titleVisible ? 'Emissions (mt)' : null
      },
      min: NUMBER.N0,
      opposite: true,
      gridLineWidth: NUMBER.N0
    }],
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      x: NUMBER.N0,
      y: NUMBER.N0
    },
    tooltip: stackedTooltipObj(graphLegends),
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function (this: any): string {
            return formatNumberWithCommas(formatAndRoundOffNumber(this.y, 'N/A'));
          },
          style: {
            fontWeight: 'bold'
          }
        }
      }
    },
    series: graphSeriesData
  };
};

const grayColor = '#dddddd';

const styleGrayColor = {
  style: {
    color: grayColor
  }
};

export const getGeoThermalDashboardGraphOptionData = (graphSeriesData: any, graphData: IGraphList) => {
  return {
    global: globalObject,
    chart: {
      type: 'line',
      backgroundColor: 'transparent',
      height: NUMBER.N200 // Set the height of the chart
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: { text: '' },
    tooltip: dashboardGraphTooltipObj,
    xAxis: {
      lineColor: grayColor,
      labels: {
        ...styleGrayColor
      },
      categories: graphSeriesData?.years,
      tickInterval: 5,
      startOnTick: false,
      showFirstLabel: false,
      title: {
        text: 'Years',
        ...styleGrayColor
      }
    },
    yAxis: [
      {
        lineColor: grayColor,
        gridLineWidth: 0,
        labels: {
          ...styleGrayColor
        },
        title: {
          text: graphData?.yAxisTitle?.primary,
          ...styleGrayColor
        },
        visible: false
      }, {
        lineColor: grayColor,
        gridLineWidth: 0,
        labels: {
          ...styleGrayColor
        },
        title: {
          text: graphData?.yAxisTitle?.secondary,
          ...styleGrayColor
        },
        visible: false,
        opposite: true
      }],
    series: graphSeriesData?.series
  };
};
