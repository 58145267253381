import React, { useState } from 'react';
import { getWorkbenchURL, toTitleCase } from '../../utils';
import { ISharedUserList, LABELS, MESSAGES } from '../../constants';
import { useNavigate } from 'react-router-dom';
import ScenarioDropdownList from '../shared/ScenarioDropdownList';
import DateTime from '../shared/DateTime';
import ProjectTypeAvatar from '../shared/ProjectTypeAvatar';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { useUnshareScenarioMutation } from '../../redux/services/scenarioApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';

export interface IScenarioProps {
  data: ISharedUserList
  refreshData: () => void
  whiteThreeDots?: boolean
  showConfimationAlert?: () => void
  reFreshSharedData?: () => void
}

const SharedScenarioListCard = (props: IScenarioProps) => {
  const { data, refreshData, reFreshSharedData } = props;
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [unShareMutation] = useUnshareScenarioMutation();
  const { email: currentUserEmail } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const goToScenarioDetails = (scenario: ISharedUserList) => {
    const url = getWorkbenchURL(scenario.subProjectID, scenario.subProject_Type as string);
    navigate(url);
  };

  const marginRight = {
    marginRight: '15px'
  };

  function handleUnshare(id: string) {
    setShowAlert(false);
    const payload = {
      scenarioId: id,
      userNameToUnShare: currentUserEmail
    };
    dispatch(startFullLoading());
    unShareMutation(payload)
      .then((res: any) => {
        dispatch(stopFullLoading());
        if ('data' in res) {
          reFreshSharedData?.();
          toast.success(MESSAGES.UNSHARE_SUCCESSFULLY);
        }
      });
  };

  return (
    <>
      <div className="tab-main-wrapper">
        <div className="tab-left-part cursor-pointer" onClick={() => goToScenarioDetails(data)}>
          <div className="dropdown-wrapper shared-profile">
            <ProjectTypeAvatar data={data} />
            <div className="right-sec">
              <span className='shared-by'>Created by: {data.sharedByUserName}</span>
              <h3 className="dropdown-head">
                {data.subProjectName}
              </h3>
              <p className="dropdown-des">
                <DateTime createdDate={data.createDate} defaultLabel="Shared" />
              </p>
              <p className="dropdown-des">
                Permission: {toTitleCase(data.permission)}
              </p>
            </div>
          </div>
        </div>
        <div className="tab-right-part">
          <a onClick={() => goToScenarioDetails(data)} className="back-project cursor-pointer">
            <span className="icon-next-arrow-grey" style={marginRight}></span>
          </a>
          <ScenarioDropdownList
            data={data}
            refreshData={refreshData}
            isSharedWithMeFolder={true}
            showConfimationAlert={() => setShowAlert(true)}
          />
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.UNSHARE_SCENARIO}
        _id={data.subProjectID ?? data.Scenario_id}
        message={MESSAGES.UNSHARE_SCENARIO_CONFIRM_MESSAGE}
        cancleBtnText={LABELS.CANCEL}
        yesBtnText={LABELS.YES_UNSHARE}
        onConfirm={(_id) => handleUnshare(_id ?? '')}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

export default SharedScenarioListCard;
