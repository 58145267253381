import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import { usePasswordToggler } from '../../hooks/usePasswordShowHide';
import { NUMBER } from '../../constants';

interface IProps {
  label: string
  subLabel?: string
  type: string
  rows?: number
  name: string
  value?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEventHandler<any>) => void
  onKeyPressDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClick?: (e: any) => void
  placeholder?: string
  required?: boolean
  hasError?: boolean
  readOnly?: boolean
  maxLength?: number
  disabled?: boolean
  showValidationError?: boolean
  autoComplete?: boolean
  resetError?: any
  className?: string
  labelClassName?: string
  pattern?: string
  countRequired?: boolean
  minLength?: number
  description?: string
}

const InputElement = ({
  label,
  subLabel,
  className = '',
  labelClassName = '',
  type,
  name,
  value,
  readOnly,
  placeholder,
  required,
  hasError,
  resetError,
  countRequired = true,
  description,
  autoComplete = false,
  onFocus,
  ...rest
}: IProps) => {
  const { fieldType, passwordVisibility, handlePasswordVisibility } = usePasswordToggler();
  const [inputValue, setInputValue] = useState(value);
  return (
    <>
      <Form.Group className={`form-group ${className} ${(hasError) ? 'error-field' : ''}`} controlId={name}>
        <div className="form-label-wrapper">
          <div className="tooltip-container">
            <Form.Label className={`form-label ${labelClassName}`}>
              {label}
              {required && (<sup>*</sup>)}
            </Form.Label>
            {description && <div className="tooltip-text">{description}</div>}
          </div>
          {type === 'textarea' && countRequired && <span className='char-count'>
            ({inputValue?.length ?? NUMBER.N0}/{rest.maxLength} char)
          </span>}
        </div>
        {subLabel && (<p className="pw-tip">{subLabel}</p>)}
        {
          type === 'textarea'
            ? <div className='textarea-wrapper'> <Form.Control
              as={'textarea'}
              {...rest}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                rest.onChange(event);
                setInputValue(event.target.value);
              }}
              value={value}
              onFocus={(field: any) => {
                resetError?.(name, ''); // Reset the field's error
              }}
              readOnly={readOnly}
              autoComplete="new-password"
              maxLength={rest.maxLength}
              minLength={rest.minLength}
              disabled={rest.disabled}
            /> </div>
            : <>
              <Form.Control
                type={type === 'password' ? fieldType : type}
                placeholder={placeholder}
                name={name}
                {...rest}
                onChange={(event: any) => {
                  rest.onChange(event);
                }}
                value={value}
                onFocus={(field: any) => {
                  onFocus?.(name as any);
                  resetError?.(name, ''); // Reset the field's error
                }}
                readOnly={readOnly}
                autoComplete={autoComplete ? 'off' : 'new-password'}
                maxLength={rest.maxLength}
                minLength={rest.minLength}
                pattern={rest.pattern}
                disabled={rest.disabled}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (rest.onKeyPressDown) {
                    rest.onKeyPressDown(event);
                  }
                }}
                onClick={rest.onClick}
              />
              {type === 'password' && <span className="password-toggle">
                <span className={passwordVisibility ? 'eye-off' : 'eye-on'} onClick={handlePasswordVisibility}></span>
              </span>}
            </>
        }
        <ErrorMessage name={name} component="span" className='error-msg' />
      </Form.Group>
    </>
  );
};

export default InputElement;
