import React from 'react';
import { Form } from 'react-bootstrap';

interface Option {
  label: string
  value: string
}

interface CheckBoxTabProps {
  options: Option[]
  selectedOptions: string[]
  onSelectedOptionsChange: (options: string[]) => void
  error?: string
  mainId: string
}

const CheckBoxTab: React.FC<CheckBoxTabProps> = ({ options, selectedOptions, onSelectedOptionsChange, error, mainId }) => {
  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      onSelectedOptionsChange([]);
    } else {
      onSelectedOptionsChange(options.map(option => option.value));
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let updatedSelectedOptions: string[];

    if (checked) {
      updatedSelectedOptions = [...selectedOptions, value];
    } else {
      updatedSelectedOptions = selectedOptions.filter(item => item !== value);
    }

    const allOptionsSelected = updatedSelectedOptions.length === options.length;
    onSelectedOptionsChange(updatedSelectedOptions);

    const selectAllCheckbox = document.getElementById(`select-all-${mainId}`) as HTMLInputElement;
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = allOptionsSelected;
    }
  };


  return (
    <div className="tab-main-container">
      <div className="tab-main-wrapper">
        <Form>
          <div className="checkbox-wrapper checkbox-grey-wrap">
            <div className="row">
              <div className="col-md-6">
                <Form.Check
                  type="checkbox"
                  id={`select-all-${mainId}`}
                  label="Select All"
                  checked={selectedOptions.length === options.length}
                  onChange={handleSelectAll}
                />
              </div>
              {options.map((option, index) => (
                <div className="col-md-6" key={index}>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${mainId}-${index}`}
                    label={option.label}
                    value={option.value}
                    checked={selectedOptions?.includes(option.value)}
                    onChange={handleCheckboxChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </Form>
      </div>
      {error && <span className="error-msg">{error}</span>} {/* Display error message if provided */}
    </div>
  );
};

export default CheckBoxTab;
