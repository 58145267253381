import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface OptionType {
  value: any
  label: string
}

interface CustomSelectProps {
  options: OptionType[]
  label: string
  name: string
  value: OptionType | null
  onChange: (name: string, value: OptionType | null) => void
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, label, name, value, onChange }) => {
  return (
    <div className="custom-select-main grey-border-select">
      <Form.Label>{label}*</Form.Label>
      <Select
        className="custom-select-wrp"
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name={name}
        value={value}
        options={options}
        onChange={(selectedOption) => onChange(name, selectedOption)}
      />
    </div>
  );
};

export default CustomSelect;
