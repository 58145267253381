import React from 'react';
import { Routes as ReactRouterRoutes, Route, Navigate } from 'react-router-dom';
import NotFound from './pages/NotFound';
import UserRoutes from './constants/routes.constant';
import htmlRoutes from './RoutesHtml';
import { ADMIN_USER_MANAGEMENT, DASHBOARD, LOGIN } from './utils';
import { useAppSelector } from './redux/hooks';
import { defaultUserTypes } from './utils/defaultData';
import AdminRoutes from './constants/admin.routes.constant';
import useAutoLogout from './hooks/useAutoLogout';

interface IRoute {
  path: string
  component: () => JSX.Element
  isPrivate?: boolean
  isAdmin?: boolean
}
interface IRouteProps {
  component: () => JSX.Element
  isAuthenticated: boolean | undefined
  isUser?: boolean
  userType?: string
}

interface IAdminRouteProps {
  component: () => JSX.Element
  isAuthenticated: boolean | undefined
  isAdmin: boolean | undefined
}

const routes: IRoute[] = [
  ...UserRoutes,
  ...AdminRoutes,
  ...htmlRoutes
];

const PublicRoute = ({ component: Component, isAuthenticated, userType }: IRouteProps) => {
  return (
    !isAuthenticated ? <Component /> : userType === defaultUserTypes.user ? <Navigate to={DASHBOARD} /> : <Navigate to={ADMIN_USER_MANAGEMENT} />
  );
};

const PrivateRoute = ({ component: Component, isAuthenticated, isUser }: IRouteProps) => {
  useAutoLogout();
  return (
    isAuthenticated ? <Component /> : <Navigate to={LOGIN} />
  );
};

const AdminRoute = ({ component: Component, isAuthenticated, isAdmin }: IAdminRouteProps) => {
  useAutoLogout();
  return (
    isAuthenticated && isAdmin ? <Component /> : isAuthenticated ? <Navigate to={DASHBOARD} /> : <Navigate to={LOGIN} />);
};

const AppRoutes = () => {
  const { isAuthenticated, user_type } = useAppSelector(state => state.auth);

  const routeComponents = routes.map(({ path, component: Component, isPrivate, isAdmin }, index) => {
    return (
      <Route
        key={path}
        path={path}
        element={
          isPrivate && isAdmin ? (
            <AdminRoute component={Component} isAuthenticated={isAuthenticated} isAdmin={user_type === defaultUserTypes.admin} />
          ) : isPrivate ? (
            <PrivateRoute component={Component} isAuthenticated={isAuthenticated} />
          ) : (
            <PublicRoute component={Component} isAuthenticated={isAuthenticated} userType={user_type} />
          )
        }
      />
    );
  });

  return (
    <ReactRouterRoutes>
      {routeComponents}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate replace to="/login" />} />
    </ReactRouterRoutes>
  );
};
export default AppRoutes;
