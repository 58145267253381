export const NUMBER = {
  N0: 0,
  N1: 1,
  N2: 2,
  N3: 3,
  N5: 5,
  N4: 4,
  N6: 6,
  N7: 7,
  N8: 8,
  N9: 9,
  N10: 10,
  N11: 11,
  N12: 12,
  N13: 13,
  N14: 14,
  N15: 15,
  N16: 16,
  N17: 17,
  N18: 18,
  N19: 19,
  N20: 20,
  N21: 21,
  N24: 24,
  N25: 25,
  N28: 28,
  N30: 30,
  N32: 32,
  N33: 33,
  N34: 34,
  N35: 35,
  N40: 40,
  N45: 45,
  N48: 48,
  N50: 50,
  N55: 55,
  N57: 57,
  N60: 60,
  N70: 70,
  N75: 75,
  N80: 80,
  N90: 90,
  N100: 100,
  N180: 180,
  N200: 200,
  N225: 225,
  N250: 250,
  N280: 280,
  N300: 300,
  N400: 400,
  N480: 480,
  N500: 500,
  N550: 550,
  N600: 600,
  N700: 700,
  N800: 800,
  N900: 900,
  N1000: 1000,
  N1500: 1500,
  N2000: 2000,
  N3000: 3000,
  N2022: 2022,
  N5000: 5000,
  N10000: 10000,
  N3600000: 3600000,
  N360: 360,
  HALF: 0.5,
  N075: 0.75,
  N095: 0.95,
  N099: 0.99,
  N1600: 1600,
  ONE_AND_HALF: 1.5,
  N013: 0.31,
  N2023: 2023,
  N2035: 2035,
  N41878: 41.878,
  N87629: 87.629,
  N15Min: 900000,
  N20Min: 1200000,
  N1Min: 60000,
  N2Min: 120000,
  N3Min: 180000,
  N60Min: 3600000,
  N19Min: 1140000,
  N9999999999: 9999999999,
  N1000000: 1000000,
  N001: 0.0001
};
