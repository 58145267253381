import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer
} from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { NUMBER } from '../../constants';
import { getLocationName, pdfDsiderLogo } from '../../utils';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: '#000',
    padding: 20,
    fontSize: 12
  },
  header: {
    fontSize: 12,
    color: '#8496b0',
    textTransform: 'uppercase',
    marginBottom: 5
  },
  contextHeader: {
    fontSize: 12,
    color: '#0172b6',
    marginBottom: 5
  },
  contextBody: {
    color: '#000',
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'justify',
    marginLeft: 10
  },
  section: {
    color: '#000'
  },
  viewer: {
    width: '100%', // the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    maxHeight: '490px',
    minHeight: '490px'
  },
  body: {
    color: '#000',
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'justify'
  },
  energyHeader: {
    textAlign: 'center',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginBottom: 30,
    fontSize: 12
  },
  spacer: {
    height: 10
  },
  image: {
    marginBottom: 20,
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    borderRadius: 5
  },
  dsiderLogo: {
    marginBottom: 10,
    width: '117px',
    height: '24px',
    zIndex: '999',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 15
  },
  companyLogo: {
    width: '50px',
    height: '30px',
    zIndex: '999',
    marginLeft: '0',
    marginRight: 'auto',
    position: 'absolute',
    top: '-5',
    objectFit: 'contain'
  },

  chartLogo: {
    marginBottom: 10,
    marginTop: 20,
    height: 'auto'
  },

  locationSection: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center'
  },
  locationBody: {
    fontSize: 12,
    color: '#000',
    marginBottom: 10,
    paddingLeft: '5px'
  },
  contextSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  recommendtionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  recommendtionLeft: {
    width: '49%',
    paddingRight: 10
  },
  recommendtionRight: {
    width: '49%',
    paddingLeft: 10
  },
  recommendtionBody: {
    color: '#000',
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'justify'
  },
  locationHeader: {
    fontSize: 12,
    color: '#0172b6',
    textTransform: 'uppercase',
    marginBottom: 10

  },
  scenarioHeader: {
    fontSize: 12,
    color: '#0172b6',
    textTransform: 'uppercase',
    marginBottom: 15,
    marginTop: 10
  },
  leftImage: {
    paddingRight: '10'
  },
  rightImage: {
    paddingLeft: '10'
  },
  compareSSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
    flexDirection: 'row'
  },
  compareSWrap: {
    marginRight: 12,
    marginBottom: 7
  },
  scenarioNamesHeader: {
    fontSize: 9,
    fontWeight: 700,
    fontStyle: 'bold',
    color: '#000'
  },
  scenarioNamesBody: {
    fontSize: 9,
    fontWeight: 400,
    color: '#5a5b5e',
    paddingLeft: 2
  },
  doubleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center'
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
    color: '#000'
  }
});

interface PdfDocumentProps {
  chartImage: string
  lcoeImg?: string
  onClose: () => void
  value: string
  contextVal: string
  show: boolean
  financialData: any
  manualChartUrl?: any
  manualCompanyUrl?: any
  scenarioNames?: string[]
}

// Create Document Component
const PdfDocument: React.FC<PdfDocumentProps> = ({ chartImage, lcoeImg, onClose, value, contextVal, show, financialData, manualChartUrl, manualCompanyUrl, scenarioNames }) => {
  const [locArr, setLocArr] = useState<string[]>([]);

  const traverseOnData = async (data: any) => {
    const locationPromises = data.map(async (ele: any) => await getLocationName(ele.latitude, ele.longitude, 'locality').then((result: any) => result || 'NA'));
    const locations = await Promise.all(locationPromises);
    setLocArr(locations);
  };

  useEffect(() => {
    if (Array.isArray(financialData) && financialData.length > NUMBER.N0) {
      traverseOnData(financialData);
    }
  }, []);

  const printArrayCommaSeparated = (arr: any[]) => {
    const finalData: any = [];
    for (let i = 0; i < arr.length; i++) {
      finalData.push(<Text key={`S${i + NUMBER.N1}`} style={[styles.compareSWrap]}>
        <Text style={styles.scenarioNamesHeader}>{` S${i + NUMBER.N1}:`}</Text>
        <Text style={styles.scenarioNamesBody}>{' ' + arr[i]}</Text>
        {i < arr.length - NUMBER.N1 && <Text style={styles.scenarioNamesHeader}>,</Text>}
      </Text>);
    }
    return finalData;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        dialogClassName="observations-modal pdf-generator-modal modal-dialog-centered modal-800"
        className="change-pw-modal create-modal"
      >
        <Modal.Header>
          <button className='close-btn' onClick={onClose}>
            <span className="icon-close-grey-icon"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="create-modal-wrapper">
            <div className="create-modal-right">
              <PDFViewer style={styles.viewer}>
                <Document>
                  <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                      {manualCompanyUrl && <Image style={styles.companyLogo} src={manualCompanyUrl} />}
                      <Text style={[styles.header, styles.energyHeader]}>Energy Planning Report</Text>
                    </View>
                    <View style={[styles.contextSection]}>
                      <Text style={styles.contextHeader}>CONTEXT:
                        <Text style={styles.contextBody}>
                          {' ' + contextVal}
                        </Text>
                      </Text>
                      <View style={[styles.contextSection]}>
                        <Text style={styles.contextHeader}>LOCATION:
                          {printArrayCommaSeparated(locArr)}
                        </Text>
                      </View>
                      <Text style={styles.scenarioHeader}>Scenario analysis and comparison plots with financials</Text>
                      <View style={[styles.compareSSection]}>
                        {scenarioNames !== undefined && scenarioNames.length > NUMBER.N0 && scenarioNames.map((ele: string, index: number) => (
                          <Text key={index} style={[styles.compareSWrap]}>
                            <Text style={styles.scenarioNamesHeader}>S{index + 1}:</Text>
                            <Text style={styles.scenarioNamesBody}>{' ' + ele}</Text>
                          </Text>
                        ))}
                      </View>
                      <View style={[styles.doubleSection]}>
                        {chartImage && <Image style={[styles.image, styles.leftImage]} src={chartImage} />}
                        {lcoeImg && <Image style={[styles.image, styles.rightImage]} src={lcoeImg} />}
                      </View>

                    </View>
                    <View style={[styles.recommendtionSection]}>
                      <View style={[styles.recommendtionLeft]}>
                        <Text style={styles.contextHeader}>ANALYSIS</Text>
                        <Text style={styles.recommendtionBody}>{value}</Text>
                      </View>
                      <View style={[styles.recommendtionRight]}>
                        {manualChartUrl && <Image style={styles.chartLogo} src={manualChartUrl} />}
                      </View>
                    </View>
                    <Text style={styles.footer}>Analysis provided by Dsider Energy Planner</Text>
                    {/* <Image style={styles.dsiderLogo} src={headerLogo} /> */}
                    <Image style={styles.dsiderLogo} src={pdfDsiderLogo} />
                  </Page>
                </Document>
              </PDFViewer >
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};
export default PdfDocument;
