import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { dropdownArrowTriagle } from '../../utils';
import { NUMBER } from '../../constants';

interface IPopoverProps {
  data: any[]
}

function PopoverButton(props: IPopoverProps) {
  const { data } = props;
  const [show, setShow] = useState(false);

  const popoverRef = useRef(null);

  const handleOutsideClick = (event: any) => {
    if (popoverRef.current && !(popoverRef.current as Node).contains(event.target)) {
      // Click occurred outside the popover, so close it
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  return (
    <>
      <div className="simulation-summary-popup-wrap parameters-dropdown-btn" ref={popoverRef}>
        <Button
          className="simulation-summary-btn"
          onClick={() => setShow(!show)}
        >+{data.length - NUMBER.N1} Parameters
          <img src={dropdownArrowTriagle} alt="logo" className='dropdown-arrow-logo' />
        </Button>
        <div className={`simulation-summary-popup parameters-dropdown-popup ${!show ? 'd-none' : ''}`}>
          {/* <h2 className="summary-dropdown-title parameters-dropdown-title">Edit Parameters</h2> */}
          <div className='parameters-popup-inner'>
            {data.map((item, index) => {
              if (item) {
                const year = Object.keys(item)[0];
                const value = item[year];
                return (
                  <p key={`${year}-${index}`} className="summary-dropdown-des parameters-dropdown-des">
                    {year}: {value}
                  </p>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default PopoverButton;
