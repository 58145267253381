import React, { useState } from 'react';
import { IPowerSouces, IPowerSourceCapacity, IQuestionProps } from '../../pages/wizard/wizardInterface';
import { NUMBER, POWER_SOURCES } from '../../constants';
import { CheckboxElement, QuestionListCard } from '../../pages/wizard/WizardQuestionType';
import { Button } from 'react-bootstrap';

const sourceUnit = {
  powerSources: 'KW',
  hydrogenSources: 'Kg'
};

export const PowerSourcesQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion } = porps;
  const isPowersource = que.question.includes('power supply');
  const sourceType = isPowersource ? 'powerSources' : 'hydrogenSources';
  const sourceCapacityType = isPowersource ? 'powerSourceCapacity' : 'hydrogenSourceCapacity';

  const [selectedPowerSources, setPowerSources] = useState<IPowerSouces[]>(answersList[sourceType]);

  const selectPowerSources = (selectedOption: string) => {
    const selectedAnswers = selectedPowerSources || [];
    let updatedPowerSource: IPowerSouces[];
    const selectedSource: IPowerSouces = JSON.parse(selectedOption);

    // Check if the selected option is already in the array
    const isOptionSelected = selectedAnswers.some(option => option.component === selectedSource.component);

    if (isOptionSelected) {
      updatedPowerSource = selectedAnswers.filter(option => option.component !== selectedSource.component);
    } else {
      updatedPowerSource = [...selectedAnswers, selectedSource];
    }
    setPowerSources(updatedPowerSource);
  };

  const updateAnswerList = () => {
    const selectedSourceCapacity: IPowerSourceCapacity[] = [];
    let hasPowerGrid = false;
    selectedPowerSources.map(ps => {
      const node = POWER_SOURCES[ps.component];
      const sourceCapacityObj = answersList[sourceCapacityType].find(sc => sc?.component === ps.component);
      // set capacity property
      const capacityObj: IPowerSourceCapacity = {
        ...ps,
        capacity: sourceCapacityObj?.capacity ?? node.data[ps.CapacityProperty],
        unit: sourceUnit[sourceType]
      };
      selectedSourceCapacity.push(capacityObj);
      if (ps.component === 'Grid') {
        hasPowerGrid = true;
      }
    });

    setAnswersList(() => ({
      ...answersList,
      usePowerGrid: hasPowerGrid,
      [sourceType]: selectedPowerSources,
      [sourceCapacityType]: selectedSourceCapacity
    }));
  };

  return (
    <div className="wizard-ques-main" key={`${que.question}-${index}`}>
      <QuestionListCard question={que.question} />
      <div className='wizard-ques-input-wrap'>
        {
          que?.answers?.map((ans, ind) => {
            const isChecked = selectedPowerSources.some(ps => ps.component === ans.component);
            if (ans.component === 'electrolyzer') {
              return <></>;
            }
            return (
              <CheckboxElement isChecked={isChecked} key={`${ans.component}check-${ind}`} ans={ans} index={ind} selectPowerSources={selectPowerSources} />
            );
          })
        }
        <Button disabled={!selectedPowerSources.length} className='shadow' onClick={() => {
          updateAnswerList();
          submitQuestion(index + NUMBER.N1);
        }}>Submit</Button>
      </div>
    </div>
  );
};
