import { createSlice } from '@reduxjs/toolkit';


export interface IApprovalStatus {
  companySearch: string
  userSearch: string
  objectSearch: string
  companyTotalData: number
  userTotalData: number
  objectTotalData: number
  singleUser: any
  signOutUserState: boolean
  singleCompany: any
  singleParameter: any
  singleObject: any
}

const initialState: IApprovalStatus = {
  companySearch: '',
  userSearch: '',
  objectSearch: '',
  companyTotalData: 0,
  userTotalData: 0,
  objectTotalData: 0,
  singleUser: {},
  signOutUserState: false,
  singleCompany: {},
  singleParameter: {},
  singleObject: {}
};

const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    updateCompanySearch(state, payload) {
      return { ...state, companySearch: payload.payload };
    },
    updateUserSearch(state, payload) {
      return { ...state, userSearch: payload.payload };
    },
    updateObjectSearch(state, payload) {
      return { ...state, objectSearch: payload.payload };
    },
    updateTotalCompanyData(state, payload) {
      return { ...state, companyTotalData: payload.payload };
    },
    updateTotalUserData(state, payload) {
      return { ...state, userTotalData: payload.payload };
    },
    updateTotalObjectData(state, payload) {
      return { ...state, objectTotalData: payload.payload };
    },
    updateSignOutUserState(state, payload) {
      return { ...state, signOutUserState: payload.payload };
    },
    resetAdminSearch(state) {
      return {
        ...state,
        companySearch: '',
        userSearch: '',
        objectSearch: ''
      };
    },
    updateSingleUser(state, payload) {
      return { ...state, singleUser: payload.payload };
    },
    updateSingleCompany(state, payload) {
      return { ...state, singleCompany: payload.payload };
    },
    updateSingleParameter(state, payload) {
      return { ...state, singleParameter: payload.payload };
    },
    updateSingleObject(state, payload) {
      return { ...state, singleObject: payload.payload };
    }
  }
});

export const {
  resetAdminSearch,
  updateCompanySearch,
  updateUserSearch,
  updateObjectSearch,
  updateTotalCompanyData,
  updateTotalUserData,
  updateTotalObjectData,
  updateSingleUser,
  updateSignOutUserState,
  updateSingleCompany,
  updateSingleParameter,
  updateSingleObject
} = adminSlice.actions;

export default adminSlice.reducer;
