import React from 'react';
import UserAvatar from '../../approval-workflow/UserAvatar';
import { NUMBER } from '../../../constants';
import { defaultDays } from '../../../utils/defaultData';

const Avatar = ({ name, setShowUserCompany, dateAdded, type, data, imageUrl, handleUserData }: any) => {
  return (
    <div className="td" onClick={() => {
      setShowUserCompany(true);
      handleUserData(data);
    }}>
      <div className="tab-left-part">
        <div className="dropdown-wrapper shared-profile">
          <UserAvatar
            classname='user-icon'
            firstname={name?.split(' ')[NUMBER.N0] ?? ''}
            lastname={name?.split(' ')[NUMBER.N1] ?? ''}
            imageUrl={imageUrl}
          />
          <div className="right-sec">
            <div className="tooltip-container">
              <h3 className="dropdown-head">
                {name}
              </h3>
              <div className="tooltip-text">{name}</div>
            </div>
            {!data.imageS3url &&
              <p className="dropdown-des mediam">
                {dateAdded === defaultDays.today || dateAdded === defaultDays.yesterday ? 'Joined' : 'Joined on'}  {dateAdded}
              </p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
