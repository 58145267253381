import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, Methods } from '../../constants';
import { getAuthHeaderMultipart, getAuthHeaders } from '../../utils';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';
import { interceptor } from '../../utils/interceptor';
import { updateAdminUser } from '../auth/authReducer';

interface IUserData {
  company_name: string
  id: string
  image_name: string
  userName: string
}

interface IUserUpdateData {
  firstName: string
  lastName: string
}

interface IUserImage {
  image: any
}

interface IUserProfile {
  company_description: string
  company_name: string
  content_type: string
  image_data: string
  image_name: string
  userName: string
  _id: string
}

export const authApis = createApi({
  reducerPath: 'authApis',
  baseQuery: interceptor,
  tagTypes: ['userData', 'userDetails'],
  endpoints: (builder) => ({
    /**
     * api to save the user data
     * @param {Object} formData - The userdata in multipart/formdata
     * @return - A promise resolves to user data if success
     */
    saveUserData: builder.mutation<IUserData, any>({
      query: (formData: FormData) => ({
        url: ApiEndpoints.SAVE_USER_DATA,
        method: Methods.POST,
        body: formData,
        headers: getAuthHeaderMultipart()
      })
    }),
    /**
     * api to update the user data
     * @param {Object} payload - The company_name, company_description in application/json
     * @return - A promise resolves to user data if success
     */
    updateUserData: builder.mutation({
      query: (data: IUserUpdateData) => ({
        url: ApiEndpoints.UPDATE_USER_DATA,
        headers: getAuthHeaders(),
        method: Methods.POST,
        body: data
      }),
      invalidatesTags: ['userDetails']
    }),
    /**
     * api to update the user image
     * @param {Object} payload - The email in application/json and image in multipart/formdata
     * @return - A promise resolves to user image if success
     */
    updateUserImage: builder.mutation<any, IUserImage>({
      query: (payload) => ({
        url: `${ApiEndpoints.UPDATE_USER_IMAGE}`,
        headers: getAuthHeaders(),
        method: Methods.POST,
        body: payload.image
      })
    }),
    /**
     * api to get the user image
     * @param {Object} payload - The email in application/json
     * @return - A promise resolves user image if success
     */
    getUserImage: builder.query<IUserProfile, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.GET_USER_IMAGE}?email=${payload.email}`,
        headers: getAuthHeaders(),
        method: Methods.GET
      })
    }),
    /**
    * api to get the user data
    * @param {Object} payload - The user_email in application/json
    * @return - A promise resolves user image if success
    */
    getUserData: builder.query({
      query: () => ({
        url: ApiEndpoints.GET_USER_DATA,
        headers: getAuthHeaders(),
        method: Methods.GET
      }),
      providesTags: ['userDetails'],
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          const { data } = await queryFulfilled;
          dispatch(updateAdminUser(data));
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    getUserAdminData: builder.query({
      query: () => ({
        url: ApiEndpoints.GET_USER_DATA,
        headers: getAuthHeaders(),
        method: Methods.GET
      })
    }),
    /**
 * api to check whether email id exist in Dsider DB or not
 * @param {Object} payload - The email in application/json
 * @return - A promise resolves 200 ok if success
 */
    validateUserEmail: builder.query<IUserProfile, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.VALIDATE_USER_EMAIL}?email=${payload}`,
        method: Methods.GET
      })
    }),

    // api to get project types...
    getLatestVersion: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_LATEST_VERSION,
        method: Methods.GET
      })
    })
  })
});

export const {
  useSaveUserDataMutation,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUpdateUserImageMutation,
  useGetUserImageQuery,
  useLazyValidateUserEmailQuery,
  useGetUserAdminDataQuery,
  useLazyGetUserAdminDataQuery,
  useGetLatestVersionQuery
} = authApis;
