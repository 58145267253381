import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { genericLogo } from '../../../utils';
import { NUMBER } from '../../../constants';

interface IAddObjectIcon {
  setShowObjectIcon: any
  ShowObjectIcon: boolean | undefined
  data: any
  setSelectedObject: any
  type: string
}

const AddObjectIcon = ({ ShowObjectIcon, setShowObjectIcon, data, setSelectedObject, type }: IAddObjectIcon) => {
  const [sortedData, setSortedData] = useState<any>([]);
  const handleObject = (item: any) => {
    const imageUrls = {
      imageUrl: item.imageUrl || item.imageDarkUrl,
      imageDarkUrl: item.imageDarkUrl ?? ''
    };
    type === 'objectImage' ? setSelectedObject(imageUrls) : setSelectedObject(item);
    setShowObjectIcon(false);
  };

  // useEffect(() => {
  //   if (data && data.length > NUMBER.N0) {
  //     const selectedObject = data.find((item: any) => item.componentDisplayName === 'Generic');
  //     const remaniningData = data.filter((item: any) => item.componentDisplayName !== 'Generic');
  //     const sortedDataFunc = remaniningData.sort((a: any, b: any) => {
  //       // Compare the 'name' field in each object
  //       if (a.componentDisplayName.toLowerCase() < b.componentDisplayName.toLowerCase()) {
  //         return -1;
  //       }
  //       if (a.componentDisplayName.toLowerCase() > b.componentDisplayName.toLowerCase()) {
  //         return NUMBER.N1;
  //       }
  //       return NUMBER.N0;
  //     });
  //     const result = [selectedObject, ...sortedDataFunc];
  //     setSortedData(result);
  //   }
  // }, [data]);

  return (
    < Modal
      show={ShowObjectIcon}
      onHide={() => setShowObjectIcon(false)}
      dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal"
      className="forget-modal setting-modal"
    >
      <Modal.Header closeButton className="" onClick={() => setShowObjectIcon(false)}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">{type === 'objectImage' ? 'Add Object Icon' : 'Select Reference Object'}</h4>
        <div className='objet-layer-main add-object-icon-main'>
          <div className='object-ul'>
            <div className='row'>
              {!data?.length && <div className='col-md-6'><div className="tab-left-part">No objects found!</div></div>}
              {data?.map((item: any, index: number) => (
                <div className='col-md-6 cursor-pointer' key={index} onClick={() => handleObject(item)}>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon darkblue">
                        <img
                          src={item.imageUrl ?? genericLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          {item.componentDisplayName}
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </Modal.Body>
    </ Modal>
  );
};

export default AddObjectIcon;
