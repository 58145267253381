export const ApiEndpoints = {
  AUTHETICATION: '/api/WebAPI_Authentication',
  GET_PROJECT_TYPE: '/get-project-type/',
  CREATE_PROJECT: '/create-project/',
  CREATE_SCENARIO: '/create-scenario/',
  EDIT_SCENARIO: '/edit-scenario-details/',
  GET_ALL_SCENARIO: '/get-all-scenarios/',
  GET_ALL_PROJECTS: '/get-all-projects/',
  REMOVE_PROJECT: '/remove-project/',
  EDIT_PROJECT: '/edit-project-details/',
  GET_PROJECT_DETAILS: '/get-project-details/',
  REMOVE_SCENARIO: '/remove-scenario/',
  DUPLICATE_SCENARIO: '/duplicate-scenario/',
  GET_SCENARIO_DETAILS: '/get-scenario-details/',
  SAVE_USER_DATA: '/save-user-data/',
  UPDATE_USER_DATA: '/edit-profile-details/',
  GET_USER_DATA: '/get-profile-details/',
  UPDATE_USER_IMAGE: '/upload-image-for-existing-user/',
  GET_USER_IMAGE: '/get-user-image/',
  GET_SIMULATION_COUNT: '/get-simcount-and-carbonabatement/',
  GET_PROJECT_AND_SHARED_SCENARIOS: '/get-project-and-shared-scenarios/',
  GET_COMPONENT_PROPERTIES: '/get-component-properties/',
  SAVE_WORKBENCH_DATA: '/save-scenario-workbench/',
  GET_OBJECT_LIST: '/get-all-objects/',
  GET_RECENT_SCENARIOS: '/get-recent-models/',
  GET_SHARED_USER: '/get-shared-users-all-data/',
  VALIDATE_USER_EMAIL: '/get_user_status/',
  COMPARE_EV_SCENARIO: '/get-compare-plots/',
  COMPARE_SCENARIO: '/get-compare-dashboard-statistics/',
  COMPARE_GRAPH_VIEW: '/get-compare-plots-aggregated/',
  GET_SCENARIO_SETTINGS: '/get-scenario-settings/',
  SAVE_SCENARIO_SETTINGS: '/save-scenario-settings/',
  SAVE_LOCATION_SETTINGS: '/save-location-api/',
  CREATE_SCENARIO_WITH_AI: '/chat',
  SHARE_SCENARIO: '/share-scenario/',
  SAVE_COMMENT_COMPONENT: '/save-component-comment/',
  GET_COMMENT_COMPONENT: '/get-component-comments/',
  EV_SIMULATION: '/ev-simulation/',
  GET_METRICS_DATA: '/get-metrics-data/',
  GET_SHARED_SCENARIOS: '/get-scenario-shared/',
  GET_SHARED_USER_LIST: '/get-shared-users/',
  UNSHARE_SCENARIO: '/unshare-scenario/',
  SAVE_SIMULATION: '/simulation-api/',
  GEO_THERMAL_SIMULATION: '/get-geophires-result/',
  SIMULATION_STATUS: '/simulation-status/',
  EV_SIMULATION_DATA: '/data',
  GET_LATEST_ACTIVITIES: '/get-company-activity-logs/',
  GET_ARCHIVE_ACTIVITIES: '/get-archived-logs/',
  MARK_AS_READ_LOGS: '/edit-acknowledged-logs/',
  EV_SIMULATION_SAVE_DATA: '/save-simulation-results/',
  EV_WORKBENCH_SAVE_AS_DUPLICATE: '/save-as-duplicate/',
  SAVE_MAP_COMMENT: '/save-comment/',
  GET_MAP_COMMENT: '/get-comments/',
  DELETE_MAP_COMMENT: '/remove-comment/',
  EDIT_MAP_COMMENT: '/edit-comment/',
  DELETE_WORKBENCH_COMMENT: '/remove-component-comment/',
  EDIT_WORKBENCH_COMMENT: '/edit-component-comment/',
  GET_SIMILAR_PROJECTS: '/get-similar-projecttypes/',
  MOVE_SCENARIO: '/move-scenario/',
  GET_APPROVER_LIST: '/get-possible-approvers/',
  GET_INVITED_APPROVERS: '/invited-users/',
  GET_APPROVAL_STATUS: '/approval-status/',
  START_APPROVAL_PROCESS: '/__start-approval-process__/',
  ADD_TO_BASELINE: '/__add-to-baseline__/',
  SEND_APPROVAL_REQUEST: '/share-and-request-approval/',
  EDIT_APPROVER: '/edit-approver/',
  REQUESTS: '/requests/',
  APPROVE_REJECT: '/approve-reject/',
  GET_BASELINE_PROJECT: '/get-all-baseline-scenarios/',
  GET_ADMIN_USER_LIST: '/admin/users/list',
  ADD_ADMIN_USER: '/admin/users/add',
  DELETE_ADMIN_USER: '/admin/users/delete',
  UPDATE_ADMIN_USER_STATUS: '/admin/users/update_status',
  UPDATE_ADMIN_USER: '/admin/users/edit',
  GET_ADMIN_COMPANY_LIST: '/admin/companies/list',
  ADD_ADMIN_COMPANY: '/admin/companies/add',
  GET_ALL_PERMISSION: '/get-all-permissions/',
  DELETE_COMPANY: '/admin/companies/delete',
  UPDATE_COMPANY_STATUS: '/admin/companies/deactivate_activate',
  EDIT_COMPANY: '/admin/companies/edit',
  ADMIN_COMPANY_USER_LIST: '/admin/companies/users',
  GET_WIZARD_QUESTIONS: '/get-wizard-questions/',
  GET_DEFAULT_PERMISSION: '/admin/companies/default_permissions/',
  ADD_ADMIN_COMPANY_OBJECT_ACCESS: '/admin/objects/assign-objects-access',
  GET_ADMIN_COMPANY_OBJECT_TYPE: '/admin/objects/get-object-type',
  ADMIN_CHECK_OBJECT_NAME: '/admin/objects/check-object-name',
  ADMIN_CREATE_NEW_OBJECT: '/admin/objects/create-new-object',
  ADMIN_OBJECT_ADD_PARAMETER: '/admin/objects/add-parameter',
  ADMIN_GET_ALL_UNITS: '/admin/objects/get-all-units',
  ADMIN_UPDATE_OBJECT: '/admin/objects/edit-object',
  ADMIN_DELETE_OBJECT: '/admin/objects/delete-object',
  RUN_MONTE_CARLO_SIMULATION: '/queue-monte-carlo-data/',
  GET_MONTE_CARLO_SIMULATION: '/get-monte-carlo-result/',
  ADMIN_DELETE_PARAMETER: '/admin/objects/delete-parameter',
  GET_LATEST_VERSION: '/latest-version/'
};

export const Methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const HTTPS_STATUS_CODE = {
  UNAUTHORIZED: 401,
  Unprocessable_Content: 422,
  SESSION_EXPIRED: 403,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  REQUEST_TIMEOUT: 504,
  FETCH_ERROR: 'FETCH_ERROR',
  INTERNAL_SERVER_ERROR: 502
};
