import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { SIGN_UP_SCHEMA } from '../../utils/validation';
import { ISignUp } from '../../constants/interface';
import InputElement from '../../components/shared/InputElement';
import { handleCognitoError, hasFieldError } from '../../utils/helper';
import { MAX_LENGTH } from '../../constants/static';
import ImageUpload from '../../components/auth/ImageUpload';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { signUpUser } from '../../redux/auth/authReducer';
import { LOGIN, VERIFY_EMAIL } from '../../utils/routeConstants';
import { MESSAGES } from '../../constants/validationMessages';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import { useSaveUserDataMutation } from '../../redux/services/authApis';
import { LABELS } from '../../constants/labelConstant';
import SignInSignUpStatic from './SignInSignUpStatic';

function SignUp() {
  const loading = useAppSelector((state) => state.spinner.loading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [saveUserData] = useSaveUserDataMutation();

  const initialValues: ISignUp = {
    company_name: '',
    company_description: '',
    company_logo: '',
    email: '',
    password: ''
  };

  const handleFormSubmit = (values: ISignUp) => {
    dispatch(startLoading(true));
    const formData = new FormData();
    values.email = values.email?.trim()?.toLowerCase();
    formData.append('email', values.email);
    formData.append('company_name', values.company_name);
    formData.append('company_description', values.company_description);
    formData.append('image', imageFile ?? '');
    saveUserData(formData)
      .then((res: any) => {
        if ('data' in res) {
          values.user_id = res.data.id;
          values.company_logo = res.data.image_url;
          dispatch(signUpUser(values))
            .unwrap()
            .then((data: any) => {
              dispatch(stopLoading(false));
              if (!data.email_verified) {
                toast.success(MESSAGES.RESEND_CODE);
                navigate(VERIFY_EMAIL);
              }
            })
            .catch((err) => {
              handleCognitoError(err);
              dispatch(stopLoading(false));
            });
        } else {
          dispatch(stopLoading(false));
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
      })
      .catch((err) => {
        dispatch(stopLoading(false));
        toast.error(err);
      });
  };

  const handleImageUpload = (file: File) => {
    setImageFile(file);
  };

  const handleConfirm = () => {
    navigate(LOGIN);
  };

  const goToSignIn = (values: ISignUp) => {
    // Check if any field has a value
    const hasValues = Object.values(values).some((value) => value);

    // Show the confirmation alert if there are values
    if (hasValues) {
      setShowAlert(true);
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      <div className="main-login">
        <div className="container">
          <div className="main-login-wrapper">
            <SignInSignUpStatic />
            <div className="form-content-main">
              <div className="login-right signup-right">
                <div className="login-header">
                  <h3>Create an Account</h3>
                  <span className="title-des">
                    Welcome to Dsider! Kindly fill in the necessary details to create your account.
                  </span>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={SIGN_UP_SCHEMA}
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={(values) => handleFormSubmit(values)}
                >
                  {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                    <Form className='login-form signup-form' onSubmit={handleSubmit}>
                      {/* company logo */}
                      <ImageUpload
                        onImageUpload={handleImageUpload}
                        setFieldValue={setFieldValue}
                      />
                      {/* company name */}
                      <InputElement
                        label='Company Name'
                        maxLength={MAX_LENGTH.company_name}
                        type='text'
                        value={values?.company_name}
                        name='company_name'
                        data-testid="company_name"
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        resetError={setFieldError}
                        hasError={hasFieldError(errors, touched, 'company_name')}
                      />
                      {/* company description */}
                      <InputElement
                        label='Company Description'
                        required={true}
                        type='textarea'
                        maxLength={MAX_LENGTH.company_desc}
                        rows={5}
                        name='company_description'
                        data-testid="company_description"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'company_description')}
                      />
                      {/* email */}
                      <InputElement
                        label='Email'
                        required={true}
                        type='text'
                        name='email'
                        data-testid="email"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'email')}
                      />
                      {/* password */}
                      <InputElement
                        label='Password'
                        subLabel={MESSAGES.PASSWORD_HINT}
                        labelClassName='mb-5'
                        required={true}
                        type='password'
                        name='password'
                        data-testid="password"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'password')}
                        autoComplete={false}
                      />
                      {/* submit button */}
                      <div className="sign-btn-wrapper">
                        <Button variant="primary" type="submit">
                          {!loading && <span>Sign Up</span>}
                          {loading && <Spinner />}
                        </Button>
                      </div>
                      <span className="no-account">
                        Already a member?{' '}
                        <span onClick={() => goToSignIn(values)} className="sign-up">
                          Sign in
                        </span>
                      </span>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.discardAlertMessage('Registration')}
        message={MESSAGES.SIGNUP_DISCARD_MESSAGE}
        yesBtnText={LABELS.YES_DISCARD}
        cancleBtnText={LABELS.STAY}
        onConfirm={handleConfirm}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
}

export default SignUp;
