import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BUTTON_TITLES, ISetPassword, MESSAGES, NUMBER, STATIC_SET_PASSWORD_STRING } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setPassword, updateAdminUser, updateUserType } from '../../redux/auth/authReducer';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { Formik } from 'formik';
import { ADMIN_SIGN_IN, DASHBOARD, LOGIN, Logo, SET_PASS_VALIDATION_SCHEMA, dsiderDefaultLogo, hasFieldError, isAdmin } from '../../utils';
import InputElement from '../../components/shared/InputElement';
import { toast } from 'react-toastify';
import { defaultUserTypes } from '../../utils/defaultData';
import { useLazyGetUserAdminDataQuery } from '../../redux/services/authApis';

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.spinner.loading);
  const [validateOnChangeBlur, setValidateOnChangeBlur] = useState(false);
  const { oldPassword, password_reset } = useAppSelector((state) => state.auth);
  const session = useAppSelector((state) => state.auth.session);
  const [getUserAdminData, { data: userData, isLoading }] = useLazyGetUserAdminDataQuery();

  const initialCPValues: ISetPassword = {
    oldPassword,
    newPassword: '',
    session
  };

  const handleClose = () => {
    navigate(-NUMBER.N1);
  };

  const handleFormSubmitt = async (values: ISetPassword) => {
    dispatch(startLoading(true));
    try {
      const params: ISetPassword = {
        session,
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword
      };
      changePasswordSubmit(params);
    } catch (err) { }
  };

  const changePasswordSubmit = (values: ISetPassword) => {
    try {
      dispatch(startLoading(true));
      dispatch(setPassword(values))
        .unwrap()
        .then(() => {
          getUserAdminData(true).then((data: any) => {
            reDirectUser(data);
          });
        })
        .catch(() => {
          dispatch(stopLoading(false));
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        });
      setValidateOnChangeBlur(true);
    } catch (error) { }
  };

  const reDirectUser = (data: any) => {
    if (isAdmin(data)) {
      dispatch(updateUserType(defaultUserTypes.admin));
      setTimeout(() => {
        navigate(ADMIN_SIGN_IN);
      }, NUMBER.N0);
    } else {
      setTimeout(() => {
        navigate(DASHBOARD);
      }, NUMBER.N0);
      dispatch(updateUserType(defaultUserTypes.user));
    }
    dispatch(stopLoading(false));
  };

  useEffect(() => {
    if (!oldPassword) {
      navigate(LOGIN);
    }
  }, [oldPassword]);

  useEffect(() => {
    if (password_reset) {
      dispatch(updateAdminUser(userData))
        .unwrap()
        .then((data: any) => {
          reDirectUser(data);
        }).catch(() => {});
    }
  }, [password_reset, isLoading]);

  return (
    <>
      <div className="change-pw-modal main-login set_password">
        <button className='close-btn' style={{ cursor: 'pointer' }} onClick={handleClose}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className='container'>
          <div className='main-login-wrapper'>
            <div className="form-content-main login-form-content">
              <span><img src={Logo} alt="img logo" className="dsider-logo" /></span>
              <div className='login-right'>

                <div className='modal-content'>
                  <Modal.Header>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='login-header'>
                      <h3 className='mb-8'>Set Password</h3>
                      <p className='title-des mb-50'>{STATIC_SET_PASSWORD_STRING.SET_PASS_DESCRIPTION}</p>
                    </div>
                    <Formik
                      initialValues={initialCPValues}
                      validationSchema={SET_PASS_VALIDATION_SCHEMA}
                      validateOnBlur={validateOnChangeBlur}
                      validateOnChange={validateOnChangeBlur}
                      onSubmit={handleFormSubmitt}
                    >
                      {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                        <Form className="full-width-form" onSubmit={handleSubmit}>
                          {/* password */}
                          <InputElement
                            label={STATIC_SET_PASSWORD_STRING.LABEL_INPUT}
                            placeholder="New Password"
                            required={true}
                            type='password'
                            name='newPassword'
                            data-testid="newPassword"
                            subLabel={STATIC_SET_PASSWORD_STRING.PASS_DESCRIPTION}
                            onChange={handleChange}
                            resetError={setFieldError}
                            onBlur={handleBlur}
                            hasError={hasFieldError(errors, touched, 'newPassword')}
                            autoComplete={false}
                          />
                          <div className="sign-btn-wrapper">
                            <Button variant="primary" type="submit">
                              {loading ? <Spinner /> : BUTTON_TITLES.SAVE}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Modal.Body>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
