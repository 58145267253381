import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import {
  headerDarkLogo,
  headerLogo,
  versionLogo
} from '../../utils/icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { signOutUser, updateUserType } from '../../redux/auth/authReducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { toast } from 'react-toastify';
import {
  ADMIN_USER_MANAGEMENT,
  ALL_PROJECT,
  CHANGE_PASSWORD,
  COMPARE_SCENARIOS,
  CREATE_PROJECT,
  DASHBOARD,
  EDIT_PROFILE,
  getCreateProjectPermission,
  isAdmin,
  LOGIN
} from '../../utils';
import { changeSpinnerTitle, stopFullLoading } from '../../redux/SpinnerSlice';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { LOADER_TITLE, MESSAGES, USER_PERMISSIONS } from '../../constants';
import { projectApis } from '../../redux/services/projectApis';
import { scenarioApis } from '../../redux/services/scenarioApis';
import { LABELS } from '../../constants/labelConstant';
import { resetLatestActivities } from '../../redux/slices/latestActivitiesSlice';
import { latestActivitiesApi } from '../../redux/services/latestActivitiesApi';
import { defaultUserTypes } from '../../utils/defaultData';
import RequestSection from '../dashboard/RequestSection';
import UserAvatar from '../approval-workflow/UserAvatar';
import AddNewObject from '../admin/object/AddNewObject';
import { useGetLatestVersionQuery } from '../../redux/services/authApis';

const Header = (props: any) => {
  const { divScrolled, isScrolled, isAllProject } = props;
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.auth);
  const [ShowAddObject, setShowAddObject] = useState(false);
  const navigate = useNavigate();
  const [signoutAlert, setSignoutAlert] = useState(false);
  const canCreateProject = getCreateProjectPermission(userData);
  const canCreateObject = userData.permissions?.includes(USER_PERMISSIONS.create_object);
  const { data: latestVersion } = useGetLatestVersionQuery();

  const handleSignOutClick = () => {
    dispatch(changeSpinnerTitle(LOADER_TITLE.LOGGING_OUT));
    dispatch(signOutUser())
      .unwrap()
      .then((data) => {
        localStorage.clear();
        sessionStorage.clear();
        setSignoutAlert(false);
        dispatch(resetLatestActivities());
        dispatch(stopFullLoading());
        dispatch(projectApis.util.resetApiState());
        dispatch(scenarioApis.util.resetApiState());
        dispatch(latestActivitiesApi.util.resetApiState());
        navigate(LOGIN);
      })
      .catch((error) => {
        toast.error(error);
        dispatch(stopFullLoading());
      });
  };

  const goTo = (path: string) => {
    navigate(path);
  };

  const adminRole = userData.user_role === 'admin';

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`bg-body-tertiary workbench-header ${(isScrolled || divScrolled) ? 'header-scroll-bg' : ''} ${divScrolled ? 'dashboard-header-scroll' : ''}`}
      >
        <Container>
          <Navbar.Brand onClick={() => goTo(DASHBOARD)}>
            {isAllProject && <img
              src={headerDarkLogo}
              alt="header logo"
              className="header-dark-logo"
            />}
            {!isAllProject &&
              <img src={divScrolled ? headerDarkLogo : headerLogo} alt="header logo" className="header-logo" />
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink to={DASHBOARD} className="nav-link">
                dashboard
              </NavLink>
              <NavLink to={ALL_PROJECT} className="nav-link">
                all projects
              </NavLink>
              <NavLink to={COMPARE_SCENARIOS} className="nav-link">
                compare scenarios
              </NavLink>
            </Nav>
            <Nav className="nav-dropdown-wrapper">
              {/* Reqest section starts here */}

              <RequestSection />

              {/* Request section ends here */}
              <Dropdown className="profile-dropdown show dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  <UserAvatar firstname={userData.firstName || userData.company_name}
                    lastname={userData.lastName} imageUrl={userData.image_url}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-wrapper">
                    <UserAvatar firstname={userData.firstName || userData.company_name}
                      lastname={userData.lastName} imageUrl={userData.image_url}
                    />
                    <div className="right-sec">
                      <h3 className="dropdown-head">{userData.firstName ? `${userData.firstName} ${userData.lastName}` : userData.company_name}</h3>
                      <div className="tooltip-container">
                        <span className="dropdown-email">{userData.email}</span>
                        <div className="tooltip-text">{userData.email}</div>
                      </div>
                      <div className="dropdown-footer">
                        <Button
                          className="btn-outline small"
                          onClick={() => goTo(EDIT_PROFILE)}
                        >
                          Edit Profile
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="account-setting">
                    {isAdmin(userData) &&
                      <Link to={ADMIN_USER_MANAGEMENT} onClick={() => dispatch(updateUserType(defaultUserTypes.admin))} className="account-item">
                        Switch to User Management
                      </Link>
                    }
                    <Link to="#" className="account-item cursor-disabled" onClick={(event) => event.preventDefault()}>
                      Onboard New User
                    </Link>
                    <Link to="#" className="account-item cursor-disabled" onClick={(event) => event.preventDefault()}>
                      Scenario Requests
                    </Link>
                    <Link to="#" className="account-item cursor-disabled" onClick={(event) => event.preventDefault()}>
                      Object Requests
                    </Link>
                    <Link to={CHANGE_PASSWORD} className="account-item">
                      Change Password
                    </Link>
                    <Link
                      to="#"
                      onClick={() => setSignoutAlert(true)}
                      className="account-item sign-out"
                    >
                      Sign Out
                    </Link>
                  </div>
                  <div className="version-sec">
                    <img src={versionLogo} alt="logo" />
                    <span className="version-text">Version v{latestVersion?.version}</span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="create-modal-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  + Create New
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.ItemText>Options</Dropdown.ItemText>
                  <Dropdown.Item as="button" disabled={!canCreateProject} onClick={() => goTo(CREATE_PROJECT)} > {LABELS.CREATE_NEW_PROJECT}</Dropdown.Item>
                  <Dropdown.Item as="button"
                    disabled={!canCreateObject || !adminRole}
                    onClick={() => adminRole && setShowAddObject(true)}>
                    Create new Object
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {signoutAlert && (
        <ConfirmationAlert
          showAlert={signoutAlert}
          title="Sign Out?"
          message={MESSAGES.SIGNOUT_MESSAGE}
          yesBtnText="Yes, Sign Out"
          cancleBtnText="Not Now"
          onConfirm={handleSignOutClick}
          onCancel={() => setSignoutAlert(false)}
        />
      )}
      {adminRole && <AddNewObject ShowAddObject={ShowAddObject} setShowAddObject={setShowAddObject}
      />}
    </>
  );
};

export default Header;
