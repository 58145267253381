import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { LABELS } from '../../constants/labelConstant';

interface IConfirmAlert {
  showAlert: boolean
  showLoader?: boolean
  title?: string
  message: string
  yesBtnText?: string
  cancleBtnText?: string
  onConfirm?: (id?: string) => void
  onCancel?: () => void
  onCross?: () => void
  _id?: string
  btnClassName?: string
  cancelBtnClassName?: string
  actionButton?: boolean
}

function ConfirmationAlert(props: IConfirmAlert) {
  const {
    showAlert,
    title,
    message,
    yesBtnText = LABELS.YES,
    cancleBtnText = LABELS.CANCEL,
    onCancel,
    onConfirm,
    _id,
    btnClassName,
    onCross,
    cancelBtnClassName,
    actionButton = true, ...rest
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (showAlert) {
      setIsVisible(true);
    }
  }, [showAlert]);

  const handleConfirm = () => {
    rest.showLoader !== false && setIsVisible(false);
    onConfirm?.(_id);
  };

  const handleCancel = () => {
    setIsVisible(false);
    onCancel?.();
  };

  const handleCross = () => {
    setIsVisible(false);
    if (typeof onCross === 'function') {
      onCross();
    } else {
      onCancel?.();
    }
  };

  return (
    <>
      {isVisible && <div className={`confirmation-alert ${isVisible ? 'show' : ''}`}>
        <div className="confirmation-dialog">
          <button className='close-btn' onClick={handleCross}>
            <span className="icon-close-grey-icon"></span>
          </button>
          <div className='confirmation-title'>{title}</div>
          <p className='confirmation-des' dangerouslySetInnerHTML={{ __html: message }}></p>
          <Button className={`discard-btn ${btnClassName}`} onClick={handleConfirm}>
            {rest.showLoader ? <Spinner /> : yesBtnText}
          </Button>
          {actionButton && <><Button className={`btn-no-outline ${cancelBtnClassName}`} onClick={handleCancel}>{cancleBtnText}</Button>
          </>}
        </div>
      </div>}
    </>
  );
}

export default ConfirmationAlert;
