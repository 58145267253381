import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IFormValue, INameFormValues, IProject, Methods, NUMBER } from '../../../constants';
import { startFullLoading, stopFullLoading } from '../../SpinnerSlice';
import { interceptor } from '../../../utils/interceptor';
import { toDateFormat, toFormatDate } from '../../../utils/helper';
import { colourOptions } from '../../../utils/defaultData';


interface IUpdateUserPayload {
  userId: string
  flag: string
}

export const userManagementApis = createApi({
  reducerPath: 'userManagementApis',
  baseQuery: interceptor,
  tagTypes: ['GetUser'],
  endpoints: (builder) => ({
    // api to save Project Data...
    saveUser: builder.mutation<IFormValue, Partial<IFormValue>>({
      query: (payload) => ({
        url: ApiEndpoints.ADD_ADMIN_USER,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetUser']
    }),

    // api to get all projects...
    getAllUsers: builder.query<any, any>({
      query: () => ({
        url: ApiEndpoints.GET_ADMIN_USER_LIST,
        method: Methods.GET
      }),
      transformResponse: (res: any[]) => {
        return res.reverse().map(user => ({
          name: `${user.firstName || '--'} ${user.lastName || '--'}`,
          email: user.userName,
          company: user.company_name,
          role: user.role === colourOptions[NUMBER.N0].value ? colourOptions[NUMBER.N0].label : user.role,
          scenarios: user.scenarios || '-',
          scenarios_count: user.noOfScenarios || NUMBER.N0,
          status: user.status,
          'last Session': toFormatDate(user.lastSession || user.dateAdded),
          dateAdded: toFormatDate(user.dateAdded),
          lastSession: user.lastSession || user.dateAdded,
          projects: user.noOfProjects || NUMBER.N0,
          simulations: user.noOfSimulations || NUMBER.N0,
          imageUrl: user.image_url,
          userId: user._id,
          company_name: user.company_name,
          company_description: user.company_description,
          firstName: user.firstName,
          lastName: user.lastName,
          roleType: user.role,
          permissions: user.permissions || []
        }));
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetUser']
    }),

    // api to get all projects...
    removeUser: builder.mutation<IProject, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.DELETE_ADMIN_USER}/${payload}`,
        method: Methods.DELETE,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetUser']
    }),

    updateUser: builder.mutation<IProject, INameFormValues>({
      query: (payload: INameFormValues) => ({
        url: ApiEndpoints.UPDATE_ADMIN_USER,
        method: Methods.PUT,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetUser']
    }),

    updateUserStatus: builder.mutation<IProject, IUpdateUserPayload>({
      query: (payload: IUpdateUserPayload) => ({
        url: ApiEndpoints.UPDATE_ADMIN_USER_STATUS,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetUser']
    })
  })
});

export const {
  useSaveUserMutation,
  useGetAllUsersQuery,
  useRemoveUserMutation,
  useUpdateUserMutation,
  useUpdateUserStatusMutation
} = userManagementApis;
