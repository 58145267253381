import React from 'react';
import { NUMBER } from '../../constants';

interface IUserAvatarProps {
  classname?: string
  firstname: string
  lastname: string
  imageUrl: string
}

function UserAvatar({ classname, firstname, lastname, imageUrl }: IUserAvatarProps) {
  const initials = lastname ? `${firstname?.charAt(NUMBER.N0)}${lastname?.charAt(NUMBER.N0)}` : firstname?.slice(NUMBER.N0, NUMBER.N2);

  return (
    <>
      <span className={`user-icon ${classname ?? 'medium orange'}`}>
        {imageUrl ? <img src={imageUrl} alt="logo" className="user-img" /> : initials}
      </span>
    </>
  );
}

export default UserAvatar;
