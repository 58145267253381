import React, { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useAppSelector } from '../../../redux/hooks';
import { useLazyGetCompanyUserListQuery } from '../../../redux/services/admin/companyManagementApis';
import { toast } from 'react-toastify';
import UserListTable from './UserListTable';

const CompanyUserList = ({ hideCompanyUserModel, companyUserModal }: any) => {
  const [getCompanyUserList, { isLoading, isFetching }] = useLazyGetCompanyUserListQuery();
  const { singleCompany } = useAppSelector((state) => state.adminSlice);
  const [consumer, setConsumer] = useState<any[]>([]);
  const [collaborator, setCollaborator] = useState<any[]>([]);
  const [superUser, setSuperUser] = useState<any[]>([]);
  const [totalUser, setTotalUser] = useState(0);

  const resetState = () => {
    setConsumer([]);
    setCollaborator([]);
    setSuperUser([]);
    setTotalUser(0);
  };

  useEffect(() => {
    resetState();
    if (singleCompany._id) {
      getCompanyUserList(singleCompany._id)
        .unwrap()
        .then((res: any) => {
          setConsumer(res.separatedByRole.consumer);
          setCollaborator(res.separatedByRole.collaborator);
          setSuperUser(res.separatedByRole.superuser);
          setTotalUser(res.totalCount);
        })
        .catch((error: any) => {
          resetState();
          toast.error(error.data.detail);
        });
    }
  }, [singleCompany]);

  const apiLoading = isLoading || isFetching;
  return (
    <Modal
      show={companyUserModal}
      onHide={hideCompanyUserModel}
      dialogClassName="modal-800 top-right-modal add-user-modal modal-dialog-centered"
      className="forget-modal setting-modal object-parameters-modal add-company-modal users-modal"
    >
      <Modal.Header closeButton className="" onClick={hideCompanyUserModel}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium mb-3 0">Users ({totalUser})</h4>
        {/* Tab start here */}
        <div className="dsider-tab-wrapper admin-tab-wrap">
          <div className="container1">
            <Tabs defaultActiveKey="consumers" id="uncontrolled-tab-example" className="">
              <Tab eventKey="consumers" title="Consumers">
                <UserListTable data={consumer || []} isLoading={apiLoading}/>
              </Tab>
              <Tab eventKey="collaborators" title="Collaborators">
                <UserListTable data={collaborator || []} isLoading={apiLoading} />
              </Tab>
              <Tab eventKey="super user" title="Superuser">
                <UserListTable data={superUser || []} isLoading={apiLoading} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyUserList;
