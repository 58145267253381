export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const DASHBOARD = '/dashboard';
export const VERIFY_EMAIL = '/verify-email';
export const EDIT_PROFILE = '/edit-profile';
export const CREATE_PROJECT = '/create-project';
export const ALL_PROJECT = '/all-projects';
export const RESET_PASSWORD = '/reset-password';
export const SCENARIOS = '/scenarios';
export const SELECT_SCENARIO_TYPE = '/select-scenario-type';
export const CREATE_SCENARIO = '/create-scenario';
export const WORKBENCH = '/workbench';
export const CHANGE_PASSWORD = '/change_password';
export const ADMIN_CHANGE_PASSWORD = '/admin/change_password';
export const COMPARE_SCENARIOS = '/compare_scenarios';
export const CREATE_SCENARIO_WITH_AI = '/create-scenario-with-AI';
export const CREATE_SCENARIO_WITH_WIZARD = '/create-scenario-with-wizard';
export const ADMIN_SIGN_IN = '/admin/signin';
export const ADMIN_EDIT_PROFILE = '/admin/edit-profile';
export const ADMIN_USER_MANAGEMENT = '/admin/user-management';
export const ADMIN_COMPANY_MANAGEMENT = '/admin/company-management';
export const ADMIN_OBJECT_MANAGEMENT = '/admin/object-management';
export const SET_PASSWORD = '/set-password';
