import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { colourOptions } from '../../utils/defaultData';
import Select from "react-select";
import CustomSelect from "./CustomSelect";

function MultiSelectCheckBox() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const projectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Power', label: 'Power' },
    { value: 'Hydrogen', label: 'Hydrogen' },
  ];
  return (
    <div className='multiselect-wrapper mb-25'>
      <div className="multiselect-wrapper-inner checkbox-select grey-border-select">
        <div className="custom-select-main">
          <Form.Label>Project Type<sup>*</sup></Form.Label>
          <Select
            className="custom-select-wrp"
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name="color"
            options={projectType}
            placeholder=""
            isMulti={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            formatOptionLabel={({ label }) =>
              <span className='checkbox-label-power'>{label}</span>
            }

          />
        </div>
      </div>
    </div>
  );
}
export default MultiSelectCheckBox;
