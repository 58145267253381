import { startFullLoading, stopFullLoading } from '../redux/SpinnerSlice';
import { getWorkbenchEVPayload } from '../utils';
import { useSaveEVSimulationDataMutation, useSaveWorkbenchDataMutation } from '../redux/services/workbenchApis';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { toast } from 'react-toastify';
import { MESSAGES, NUMBER } from '../constants';
import { updateUnsavedChange } from '../redux/workbenchSliceEV';
import { scenarioApis } from '../redux/services/scenarioApis';

export const useSaveWorkbenchEV = () => {
  const [saveWorkbenchData] = useSaveWorkbenchDataMutation();
  const [saveEVSimulationData] = useSaveEVSimulationDataMutation();
  const dispatch = useAppDispatch();
  const workbenchData = useAppSelector(state => state.workBenchEV);

  const saveWorkbenchEV = async (showLoader: boolean, callback?: any) => {
    let workbenchSaved = false;
    let simulationSaved = false;

    try {
      showLoader && dispatch(startFullLoading());
      const payload = getWorkbenchEVPayload(workbenchData);
      const workbenchRes = await saveWorkbenchData(payload);
      if ('data' in workbenchRes) {
        workbenchSaved = true;
        dispatch(updateUnsavedChange(false));
        scenarioApis.util.invalidateTags(['GetScenarioDetails']);
      } else {
        throw new Error('Failed to save simulation data');
      }

      const payloadSimulation = { simulationData: workbenchData.simulationData, scenarioId: workbenchData.scenarioDetails.id };
      let simulationRes;
      for (let i = NUMBER.N0; i < NUMBER.N5; i++) {
        try {
          simulationRes = await saveEVSimulationData(payloadSimulation);
          if ('data' in simulationRes) {
            simulationSaved = true;
            break;
          } else {
            throw new Error('Failed to save simulation data');
          }
        } catch (error) {
          if (i === NUMBER.N4) {
            throw new Error('Maximum retries reached for saveEVSimulationData');
          }
        }
      }

      if (workbenchSaved && simulationSaved) {
        toast.success(MESSAGES.EV_SIMULATION_SAVED);
      } else {
        toast.error(MESSAGES.EV_SIMULATION_SAVED_ERROR);
      }
      dispatch(stopFullLoading());
      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error: any) {
      toast.error(MESSAGES.EV_SIMULATION_SAVED_ERROR);
      dispatch(stopFullLoading());
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
  };
  return {
    saveWorkbenchEV
  };
};


