import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { SIGN_IN_SCHEMA } from '../../utils/validation';
import { ISignIn } from '../../constants/interface';
import InputElement from '../../components/shared/InputElement';
import { handleCognitoError, hasFieldError, isAdmin } from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resendSignUpCode, signInUser, updateAdminUser, updateUserType } from '../../redux/auth/authReducer';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
import { ADMIN_SIGN_IN, DASHBOARD, SET_PASSWORD, VERIFY_EMAIL } from '../../utils/routeConstants';
import ForgotPassword from '../../components/auth/ForgotPassword';
import SignInSignUpStatic from './SignInSignUpStatic';
import { defaultUserTypes } from '../../utils/defaultData';
import { useLazyGetUserAdminDataQuery } from '../../redux/services/authApis';

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const loading = useAppSelector((state) => state.spinner.loading);
  const dispatch = useAppDispatch();
  const [getUserAdminData] = useLazyGetUserAdminDataQuery();
  const initialValues: ISignIn = {
    email: '',
    password: ''
  };

  const handleFormSubmit = (values: ISignIn) => {
    dispatch(startLoading(true));
    dispatch(signInUser(values))
      .unwrap()
      .then((data: any) => {
        if (!data.isAuthenticated && data.oldPassword) {
          navigate(SET_PASSWORD);
          dispatch(stopLoading(false));
          return;
        }
        getUserAdminData(true).then((res) => {
          if ('data' in res) {
            dispatch(updateAdminUser(res.data));
          }
        });
        if (isAdmin(data)) {
          dispatch(updateUserType(defaultUserTypes.admin));
          navigate(ADMIN_SIGN_IN);
        } else {
          navigate(DASHBOARD);
          dispatch(updateUserType(defaultUserTypes.user));
        }
        dispatch(stopLoading(false));
      })
      .catch((error: any) => {
        if (error.code === 'UserNotConfirmedException') {
          resenEmailConfirmationCode(values);
        } else if (error.code === 'NotAuthorizedException') {
          toast.error(error.message);
          dispatch(stopLoading(false));
        } else {
          handleCognitoError(error);
          dispatch(stopLoading(false));
        }
      });
  };

  const resenEmailConfirmationCode = (values: ISignIn) => {
    dispatch(resendSignUpCode(values.email))
      .unwrap()
      .then((data) => {
        navigate(VERIFY_EMAIL);
        dispatch(stopLoading(false));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="main-login">
        <div className="tost-msg-wrapper d-none">
          <span className="icon-invalid"></span>
          <span className="icon-success-tick"></span>
          <span className="tost-msg">Incorrect email or password.ooooo</span>
          <button className="icon-close-grey-icon"></button>
        </div>
        <div className="container">
          <div className="main-login-wrapper">
            <SignInSignUpStatic />
            <div className="form-content-main login-form-content">
              <div className="login-right">
                <div className="login-header">
                  <h3>Sign In</h3>
                  <span className="title-des">
                    Welcome back! We’re glad to see you again.
                  </span>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={SIGN_IN_SCHEMA}
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={(values) => handleFormSubmit(values)}
                >
                  {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                    <Form className="login-form" onSubmit={handleSubmit}>
                      {/* email */}
                      <InputElement
                        label='Email'
                        required={false}
                        type='text'
                        name='email'
                        data-testid="email"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'email')}
                      />
                      {/* password */}
                      <InputElement
                        label='Password'
                        required={false}
                        type='password'
                        name='password'
                        data-testid="password"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'password')}
                        autoComplete={false}
                      />
                      <div className="sign-btn-wrapper">
                        <Button variant="primary" type="submit">
                          {loading ? <Spinner /> : 'Sign In'}
                        </Button>
                      </div>
                      {/* commented as of now... */}
                      {/* <span className="no-account">
                        Don’t have an account?{' '}
                        <Link to="/signup" className="sign-up">
                          Sign up
                        </Link>
                      </span> */}
                      <div className="forgot-ps-wrapper">
                        <a
                          href="#"
                          className="forgot-pw"
                          onClick={() => setShow(true)}
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {show &&
                <ForgotPassword
                  showModal={show}
                  submitModalHandler={() => setShow(false)}
                  closeModalHandler={() => setShow(false)}
                />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
