import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Modal,
  NavDropdown,
  Tab,
  Tabs
} from "react-bootstrap";
import {
  artboardLogo,
  backArrow,
  carbonTaxLogo,
  commentSendArrow,
  compnayDummyLogo,
  crossRedLogo,
  deleteRedLogo,
  electricityGraphImg,
  electrolysisLogo,
  fileInputImg,
  genericLogo,
  geothermalLogo,
  hydrogenLogoSmall,
  infoInputLogo,
  inputModalImg,
  irrLogo,
  locationLogo,
  methaneLogo,
  nvpLogo,
  plusLogo,
  powerBetteryDarkLogo,
  powerBetteryLogo,
  powerLineLogo,
  powerSmallLogo,
  taxCreditLogo,
  turbineLogo,
  turbineSmallLogo,
  waterTank,
  windmillDarkLogo,
  windmillLogo,
  workbenchBoardImg
} from "../../utils/icons";

import { Link } from "react-router-dom";
import Select from "react-select";
import CustomSelect from "./CustomSelect";
import HeaderWorkbenchHtml from './HeaderWorkbenchHtml';
import RadioSelectCheckBox from './RadioSelectCheckBox';
import Slider from 'rc-slider';
import { NUMBER } from '../../constants';

const WorkBench = () => {
  const [ShowObjectModal, setShowObjectModal] = useState(false);
  const [showInputOutput, setInputOutput] = useState(false);
  const [ShowObjectLayer, setShowObjectLayer] = useState(false);
  const [ShowAddObject, setShowAddObject] = useState(false);
  const [ShowObjectIcon, setShowObjectIcon] = useState(false);
  const [ShowAddParameter, setShowAddParameter] = useState(false);
  const [ShowReferenceObject, setShowReferenceObject] = useState(false);
  const [ShowReservairModal, setShowReservairModal] = useState(false);
  const [ShowCapitalCostsModal, setShowCapitalCostsModal] = useState(false);
  const [ShowMaintenanceCostsModal, setShowMaintenanceCostsModal] = useState(false);
  const [sliderValue, setSliderValue] = useState<number>(NUMBER.N100);
  const [ShowInputVariables, setShowInputVariables] = useState(false);






  const sliderChangeHandler = (value: number | number[]) => {
    if (typeof value === 'number') {
      setSliderValue(value);
    }
  };

  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const capitalCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "kwh", label: "KWH" },
    { value: "mwh", label: "MWH" },
    { value: "kt", label: "KT" },
    { value: "mt", label: "MT" },
  ];
  const periodCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const priceSold = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const transCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];
  const variableCost = [
    { value: "select", label: "Select", isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: "dollar", label: "$" },
    { value: "cad", label: "CAD" },
    { value: "clf", label: "CLF" },
  ];

  const projectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Power', label: 'Power' },
    { value: 'Hydrogen', label: 'Hydrogen' },
  ];
  const objectType = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'Power Turbine', label: 'Power Turbine' },
    { value: 'Solar Turbine', label: 'Solar Turbine' }
  ];

  const colourOptions = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'KWH', label: 'KWH' },
    { value: 'Percentage (%)', label: 'Percentage (%)' },
    { value: 'Dollars ($)', label: 'Dollars ($)' },

  ];
  const inputVariables = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'normal', label: 'Normal' },
    { value: 'mediam', label: 'Mediam' },
    { value: 'hard', label: 'Hard' },

  ];


  return (
    <>
      {/* Please add light-bg-theme this class in below div for light backgound */}
      <div className="workbench-dashboard-main">
        <HeaderWorkbenchHtml />
        <div className="workbench-dashboard-inner">
          <div className="dashboard-main-wrapper">
            <div className="dashboard-left">
              <div className="home-header-sec">
                <Link to="#" className="link-white-icon">
                  <span className="icon-home-white"></span>
                  Home
                </Link>
                <NavDropdown
                  title="All Projects"
                  id="collapsible-nav-dropdown"
                  className="request-dropdown link-white-icon all-project-dropdown"
                >
                  <div className="custom-dropdown">
                    <div className="dropdown-wrapper">
                      <div className="dsider-tab-wrapper">
                        <h2>Project: Blue Carbon</h2>
                        <span className="my-modal-count">3 Scenarios</span>
                        <div className="tab-main-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="tab-left-right">
                              <Button className="btn-no-outline">
                                <span className="icon-next-arrow-grey"></span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head bold">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Created on: May 9, 2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* share modal
                     */}
                    {/* <div className="dropdown-wrapper">
                      <div className="dsider-tab-wrapper">
                        <h2 className="shared-head">
                          Shared with me
                          <span className="my-modal-count">(2)</span>
                        </h2>
                        <div className="tab-main-container shared-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head bold">
                                    Turbine
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Power Object, Green Hydrogen +2
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-left-right">
                              <Button className="btn-no-outline">
                                <span className="icon-next-arrow-grey"></span>
                              </Button>
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head bold">
                                    Turbine
                                  </h3>
                                  <p className="dropdown-des mediam">
                                    Power Object, Green Hydrogen +2
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-left-right">
                              <Button className="btn-no-outline">
                                <span className="icon-next-arrow-grey"></span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </NavDropdown>
              </div>
              <h1 className="section-title">Green Hydrogen Base-Case</h1>
              {/* Select start here */}
              <CustomSelect />

              <div className="drag-drop-main">
                <h5 className="drag-drop-head">Select an Object</h5>
                <div className='drag-drop-main-wrap'>
                  <div className="drag-drop-wrapper">
                    <div className="drag-drop-item">
                      <img
                        src={turbineLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={turbineDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={windmillLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={windmillDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={powerLineLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={powerLineDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={powerBetteryLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={powerBetteryDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={geothermalLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={geothermalDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={methaneLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      {/* <img
                        src={methaneDarkLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      /> */}
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    {/* <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div>
                    <div className="drag-drop-item">
                      <img
                        src={artboardLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text">Turbine</span>
                    </div> */}


                    <div className="drag-drop-item" onClick={() => setShowAddObject(true)}>
                      <img
                        src={plusLogo}
                        alt="img logo"
                        className="drag-drop-img"
                      />
                      <span className="drag-drop-text new-object">
                        New Object
                      </span>
                    </div>
                  </div>
                </div>
                <div className="drag-hint-wrapper">
                  <span className="icon-dragdrop-icon"></span>
                  <span className="drag-hint-text">
                    Drag & Drop an object on your workbench
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-right">
              <div className="workbench-chart-main">
                <div className="workbench-chart-header">
                  <span className="work-board-text">
                    This is your work board:
                  </span>
                  <Button className="primary-btn no-radius">
                    <span className="icon-simulate-icon"></span>
                    Simulate
                  </Button>
                </div>
                <div className="workbench-board-body">
                  <div className="workbench-board-header">
                    <Dropdown className="create-modal-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        Clear Board
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.ItemText>Options</Dropdown.ItemText>
                        <Dropdown.Item as="button">
                          Clear Entire Board
                        </Dropdown.Item>
                        <Dropdown.Item as="button">Clear Linking</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline" onClick={() => setShowObjectLayer(true)}>
                        <div className="circle-logo-wrapper">
                          <span className="icon icon-layer"></span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Layers</div>
                    </div>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline">
                        <div className="circle-logo-wrapper">
                          <span className="svg-icon">
                            <svg
                              width="17"
                              height="15"
                              viewBox="0 0 17 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                // eslint-disable-next-line max-len
                                d="M10.8574 1.42853H5.97684C3.13944 1.42853 0.839355 3.72861 0.839355 6.56601C0.839355 9.40332 3.13944 11.7035 5.97684 11.7035H7.00434C7.71369 11.7035 8.28871 12.2785 8.28871 12.9879V14.2722C13.6175 10.8818 15.9615 10.0269 15.9945 6.63916C15.9948 6.61485 15.9949 6.59048 15.9949 6.56601C15.9949 3.72864 13.6948 1.42853 10.8574 1.42853Z"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.97705 5.27911H10.8577"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.8574 7.84943H5.97681"
                                stroke="#5A5B5E"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Comment</div>
                    </div>
                    <div className="tooltip-container">
                      <Button className="setting-btn btn-no-outline">
                        <div className="circle-logo-wrapper">
                          <span className="icon icon-undo"></span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Undo</div>
                    </div>
                  </div>
                  <div className="workbench-board-wrapper">
                    <img
                      src={workbenchBoardImg}
                      alt="image logo"
                      className="workbench-board-img"
                    />
                    {/* <div className="hover-box-wrap">
                      <div className="hover-box">
                        <span className="disable-text">Options</span>
                        <ul className="hover-box-inner">
                          <li className="hover-box-item" onClick={() => setInputOutput(true)}>
                            Change Input Output Nodes
                          </li>
                          <li className="hover-box-item">
                            Duplicate Component
                          </li>
                          <li
                            className="hover-box-item hover-box-divider"
                            onClick={() => setShowObjectModal(true)}
                          >
                            Show Properties
                          </li>
                          <li className="hover-box-item text-danger">
                            Remove Link
                          </li>
                          <li className="hover-box-item text-danger">
                            Delete Component
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    {/* <div className="hover-box-wrap comment-box-wrap">
                      <div className="hover-box">
                        <div className="dropdown-wrapper shared-profile profile-icon-wrap">
                          <span className="profile-icon medium orange">a</span>
                          <span className='comment-user-name'>Sarah James</span>
                          <span className='comment-date'>32 mins ago</span>
                        </div>
                        <p className='comment-des'>Can we add an output node here ?</p>
                        <Form className="simulation-form">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            <Button className="primary comment-send-btn">
                              <img src={commentSendArrow} alt="logo" />
                            </Button>
                          </Form.Group>
                        </Form>

                      </div>
                    </div> */}
                    <div className="comment-small-wrap">
                      <span className="profile-icon medium orange">a</span>
                    </div>
                    {/* <div className='show-hide-dropdown-wrap'>
                      <div className='hover-box'>
                        <span className='disable-text'>Objects</span>
                        <span className='hover-box-item'>Turbine
                        </span>
                        <span className='hover-box-item'>Solar Panel
                        </span>
                      </div>
                    </div> */}


                  </div>
                  <div className="workbench-board-footer">
                    <span className="footer-board-left">
                      Last updated 3d ago
                    </span>
                    <div className="workbench-board-header">
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper">
                            <span className="icon icon-zoom-in"></span>
                          </div>
                        </Button>
                        <div className="tooltip-text">Zoom in</div>
                      </div>
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper">
                            <span className="icon icon-zoom-out"></span>
                          </div>
                        </Button>
                        <div className="tooltip-text">Zoom out</div>
                      </div>
                      <div className="tooltip-container">
                        <Button className="setting-btn btn-no-outline">
                          <div className="circle-logo-wrapper delete-btn">
                            <span className="svg-icon">
                              <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Group">
                                  <g id="Group_2">
                                    <path
                                      id="Vector (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M0 2.68978C0 2.3377 0.285418 2.05228 0.6375 2.05228H11.6902C12.0422 2.05228 12.3277 2.3377 12.3277 2.68978C12.3277 3.04186 12.0422 3.32728 11.6902 3.32728H0.6375C0.285418 3.32728 0 3.04186 0 2.68978Z"
                                      fill="white"
                                    />
                                    <path
                                      id="Vector_2 (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M2.90125 2.26276C2.90125 1.01305 3.91442 0 5.16405 0H7.16375C8.41349 0 9.42655 1.01307 9.42655 2.26276V2.68968C9.42655 2.97963 9.1915 3.21468 8.90155 3.21468H3.42635C3.13644 3.21468 2.90141 2.9797 2.90135 2.6898L2.90125 2.26276ZM3.95515 2.16468C4.00509 1.54078 4.52729 1.05 5.16405 1.05H7.16375C7.80058 1.05 8.32271 1.54076 8.37264 2.16468H3.95515Z"
                                      fill="white"
                                    />
                                    <g id="Group_3">
                                      <path
                                        id="Vector_3 (Stroke)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        // eslint-disable-next-line max-len
                                        d="M3.76859 5.65617C4.05816 5.64139 4.30488 5.86416 4.31966 6.15373L4.55475 10.7619C4.56953 11.0515 4.34676 11.2982 4.05719 11.313C3.76761 11.3278 3.52089 11.105 3.50612 10.8154L3.27102 6.20723C3.25625 5.91766 3.47901 5.67094 3.76859 5.65617Z"
                                        fill="white"
                                      />
                                      <path
                                        id="Vector_4 (Stroke)"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        // eslint-disable-next-line max-len
                                        d="M8.55919 5.65617C8.84876 5.67095 9.07153 5.91767 9.05675 6.20725L8.82155 10.8154C8.80677 11.105 8.56004 11.3278 8.27047 11.313C7.9809 11.2982 7.75813 11.0515 7.77291 10.7619L8.00811 6.15372C8.02289 5.86415 8.26962 5.64139 8.55919 5.65617Z"
                                        fill="white"
                                      />
                                    </g>
                                    <path
                                      id="Vector_5 (Stroke)"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      // eslint-disable-next-line max-len
                                      d="M6.16373 5.65788C6.45368 5.65788 6.68873 5.89293 6.68873 6.18288V10.791C6.68873 11.0809 6.45368 11.316 6.16373 11.316C5.87378 11.316 5.63873 11.0809 5.63873 10.791V6.18288C5.63873 5.89293 5.87378 5.65788 6.16373 5.65788Z"
                                      fill="white"
                                    />
                                  </g>
                                  <path
                                    id="Vector_6 (Stroke)"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    // eslint-disable-next-line max-len
                                    d="M0.800401 2.32823C0.89952 2.22387 1.03714 2.16478 1.18107 2.16478H11.1467C11.2906 2.16478 11.4281 2.22383 11.5272 2.32813C11.6264 2.43243 11.6783 2.57284 11.671 2.71653L11.1681 12.573C11.1068 13.7763 10.1131 14.7205 8.90827 14.7205H3.41947C2.27527 14.7205 1.32171 13.869 1.17594 12.7528C1.17567 12.7508 1.17542 12.7489 1.17518 12.7469C1.16785 12.6896 1.16264 12.6317 1.15965 12.5731L1.15951 12.5701L1.15921 12.5635C1.15812 12.5395 1.15866 12.5159 1.16071 12.4927L0.656764 2.71681C0.649354 2.57307 0.701281 2.43259 0.800401 2.32823ZM2.21932 12.6326C2.30472 13.2226 2.812 13.6705 3.41947 13.6705H8.90827C9.55404 13.6705 10.0866 13.1644 10.1194 12.5196L10.1194 12.5195L10.5942 3.21478H1.73383L2.21932 12.6326Z"
                                    fill="white"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </Button>
                        <div className="tooltip-text">Remove Model</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Simulation Chart  */}
            <div className="simulation-chart-wrapper">
              <div className="chart-left">
                <h2 className="chart-title">Simulation Overview (01 Jan 2023 - 31 Dec 2050)</h2>
                <div className="chart-radio-wrapper">
                  <Form>
                    <div className="checkbox-wrapper">
                      <Form.Check
                        type="radio"
                        id="default-checkbox"
                        label="Power"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox1"
                        label="Total Hydrogen"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox2"
                        label="Hydrogen Breakdown"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox3"
                        label="Over Under Chart"
                        name="simulationOverview"
                      />

                    </div>
                  </Form>

                </div>
              </div>
              <div className="chart-right">
                <div className="chart-right-inner">
                  <div className="slider-wrap">
                    <div className="simulation-form-wrap">
                      <Form className="simulation-form">
                        <div className='simulation-inner-wrap'>
                          <div className="simulation-inner">
                            <p className="slider-head">Green Energy Capacity</p>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Turbine</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Solar Panel</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                          </div>
                          <div className="simulation-inner">
                            <p className="slider-head">Battery Capacity</p>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Battery</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                          </div>
                          <div className="simulation-inner">
                            <p className="slider-head">ELECTROLYSER Capacity</p>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Electrolyser</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                          </div>
                          <div className="simulation-inner">
                            <p className="slider-head">Green H2 Storage capacity</p>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Green H2</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                          </div>
                          <div className="simulation-inner">
                            <p className="slider-head">DEMAND</p>
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Industry</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="false"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="simulate-btn-wrap">
                          <Button className="primary">Simulate</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Cost Chart  */}
            <div className="simulation-chart-wrapper mitigation-chart">
              <div className="mitigation-wrap">
                <div className="co2-wrap">
                  <h4 className="section-head">CO2 Mitigation (MMT)</h4>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Re Power</span>
                    <p className="mitigation-value">12.07</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Low Carbon H2</span>
                    <p className="mitigation-value">9,717.31</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Industry H2</span>
                    <p className="mitigation-value">8,740.80</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Green Store H2</span>
                    <p className="mitigation-value">0</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Grey H2</span>
                    <p className="mitigation-value">0</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Mobility H2</span>
                    <p className="mitigation-value">976.51</p>
                  </div>
                </div>
                <div className="mitigation-right-wrap">
                  <div className="co2-wrap supply-left">
                    <h4 className="section-head">Supply Reliability</h4>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Total Power Produced</span>
                      <p className="mitigation-value">100 MW/Yr</p>
                    </div>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Add Total Hydrogen Produced</span>
                      <p className="mitigation-value">250,000 Kg/Yr</p>
                    </div>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Add Cumulative Hydrogen Shortfall</span>
                      <p className="mitigation-value">40,000 Kg/Yr</p>
                    </div>
                  </div>
                  <div className="supply-right">
                    <div className="tax-credits co2-wrap">
                      <span className="section-logo"><img src={taxCreditLogo} alt="logo image" /></span>
                      <div className="suppy-credit-wrap">
                        <p className="credits-value">$2</p>
                        <span className="credits-des">Local Tax Credits</span>
                      </div>
                    </div>
                    <div className="carbon-tax co2-wrap">
                      <span className="section-logo"><img src={carbonTaxLogo} alt="logo image" /></span>
                      <div className="suppy-credit-wrap">
                        <p className="credits-value">$0</p>
                        <span className="credits-des">Carbon Tax</span>
                      </div>
                    </div>

                  </div>
                  <div className="supply-nvp">
                    <div className="nvp-left co2-wrap">
                      <div className="tax-credits">
                        <span className="section-logo nvp-sec-logo"><img src={nvpLogo} alt="logo image" /></span>
                        <div className="suppy-credit-wrap npv-credit-wrap">
                          <p className="credits-value npv-value">NPV</p>
                          <span className="credits-des npv-des">$-2,401,238.61</span>
                        </div>
                      </div>
                    </div>
                    <div className="nvp-right co2-wrap">
                      <div className="tax-credits">
                        <span className="section-logo nvp-sec-logo"><img src={irrLogo} alt="logo image" /></span>
                        <div className="suppy-credit-wrap npv-credit-wrap">
                          <p className="credits-value npv-value">IRR</p>
                          <span className="credits-des npv-des">-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cost-wrap">
                <div className="cost-img-sec">
                  <div className="cost-img-wrap">
                    <div className="cost-img-inner-wrap">
                      <img src={electricityGraphImg} alt="logo" />
                      <h3>Cost</h3>
                    </div>
                  </div>
                  <div className="cost-text-wrap">
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Capex</span>
                      <h2 className="cost-text-head">$404,000,100.00</h2>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Opex</span>
                      <h2 className="cost-text-head">$651,086,779.88</h2>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Period Value</span>
                      <h2 className="cost-text-head">$3,129,400</h2>
                    </div>

                  </div>
                </div>
                <div className="cost-value-one">
                  <span className="cost-value-head">LCOE</span>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Total</span>
                    <p className="lcoe-value">$4.97</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Solar</span>
                    <p className="lcoe-value">$4.36</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Turbine</span>
                    <p className="lcoe-value">$3.11</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Battery</span>
                    <p className="lcoe-value">$2.63</p>
                  </div>

                </div>
                <div className="cost-value-two">
                  <span className="cost-value-head">LCOE</span>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Total</span>
                    <p className="lcoe-value">$4.71</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Mobility</span>
                    <p className="lcoe-value">$4.71</p>
                  </div>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Industry</span>
                    <p className="lcoe-value">$6.25</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Geothermal Table  */}
            <div className="simulation-chart-wrapper mitigation-chart geothermal-table">
              <div className="mitigation-wrap">
                <div className='geo-left-wrap'>
                  <div className="co2-wrap">
                    <h4 className="section-head">Emissions</h4>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Heading</span>
                      <p className="mitigation-value">121 MMT</p>
                    </div>
                  </div>
                  <div className="co2-wrap supply-left">
                    <h4 className="section-head">Financial Model</h4>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Model</span>
                      <p className="mitigation-value">Bicycle</p>
                    </div>
                    <div className="co2-inner-wrap">
                      <span className="mitigation-label">Capacity Factor</span>
                      <p className="mitigation-value">90 %</p>
                    </div>
                  </div>
                </div>
                <div className="mitigation-right-wrap">
                  <div className="supply-right">
                    <Button className="vir-head geo-dropdown-btn btn-no-outline" onClick={() => setShowReservairModal(true)}>Reservoir Simulation Results</Button>
                    <div className="reservoir-row row">
                      <div className="reservoir-wrap col-md-6">
                        <span className="credits-des">Reservoir Model</span>
                        <p className="credits-value">Multiple Parallel Fractures</p>
                      </div>
                      <div className="reservoir-wrap col-md-6">
                        <span className="credits-des">Avg. Reservoir Heat Extraction</span>
                        <p className="credits-value">52 MW</p>
                      </div>
                      <div className="reservoir-wrap col-md-6">
                        <span className="credits-des">Avg. Net Electricity Production</span>
                        <p className="credits-value">5 MW</p>
                      </div>
                      <div className="reservoir-wrap col-md-6">
                        <span className="credits-des">Average Production Temperature</span>
                        <p className="credits-value">167 °C</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="supply-nvp">
                <div className="nvp-left co2-wrap">
                  <div className="vir-sec">
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="vir-head">VIR</p>
                      <span className="vir-des">1.79</span>
                    </div>
                  </div>
                  <div className="vir-sec moic-sec">
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="vir-head">MOIC</p>
                      <span className="vir-des">2.48</span>
                    </div>
                  </div>
                </div>
                <div className="nvp-right co2-wrap">
                  <div className="vir-sec">
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <Button className="vir-head geo-dropdown-btn btn-no-outline">Bicycle</Button>
                      <span className="vir-des">5</span>
                    </div>
                  </div>
                  <div className="vir-sec moic-sec">
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="vir-head">Project Payback Period</p>
                      <span className="vir-des">9.24 yr</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="supply-nvp geo-npv">
                <div className="nvp-left co2-wrap">
                  <div className="tax-credits geo-net-avg">
                    <span className="section-logo nvp-sec-logo visibility-hidden"><img src={nvpLogo} alt="logo" /></span>
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="credits-value npv-value vir-head">Avg. Net Electricity Production</p>
                      <span className="credits-des npv-des vir-des">35.82 MW</span>
                    </div>
                  </div>
                  <div className="tax-credits geo-npv">
                    <span className="section-logo nvp-sec-logo"><img src={nvpLogo} alt="logo" /></span>
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="credits-value npv-value vir-head">NPV</p>
                      <span className="credits-des npv-des vir-des">$142,000,000</span>
                    </div>
                  </div>
                  <div className="tax-credits geo-irr">
                    <span className="section-logo nvp-sec-logo"><img src={irrLogo} alt="logo" /></span>
                    <div className="suppy-credit-wrap npv-credit-wrap">
                      <p className="credits-value npv-value vir-head">IRR</p>
                      <span className="credits-des npv-des vir-des">12.26%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cost-wrap">
                <div className="cost-img-sec">
                  <div className="cost-img-wrap">
                    <div className="cost-img-inner-wrap">
                      <img src={electricityGraphImg} alt="logo" />
                      <h3>Cost</h3>
                    </div>
                  </div>
                  <div className="cost-text-wrap">
                    <div className="cost-text-inner-wrap">
                      <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() => setShowCapitalCostsModal(true)}>Total Capital Costs</Button>
                      <h2 className="cost-text-head">$5,000,000</h2>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() => setShowMaintenanceCostsModal(true)}>Total Operating and Maintenance Costs</Button>
                      <h2 className="cost-text-head">$14,000,000/yr</h2>
                    </div>
                  </div>
                </div>
                <div className="cost-value-two">
                  <span className="cost-value-head">LCOE</span>
                  <div className="co2-inner-wrap cost-value-wrap">
                    <span className="mitigation-label">Total</span>
                    <p className="lcoe-value">$3.82</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Monte Carlo Simulation Chart  */}
            <div className="simulation-chart-wrapper monto-carlo-chart">
              <div className="chart-left">
                <h2 className="chart-title">
                  <button className='close-btn'>
                    <span className="back-arrow">
                      <img src={backArrow} alt="logo" />
                    </span>
                  </button>

                  Monte Carlo Simulation Chart</h2>
                <div className="power-graph-wrap">
                  <h3>Chart div</h3>
                </div>
              </div>
              <div className="chart-right">
                <div className="chart-right-inner">
                  <div className="slider-wrap">
                    <div className="simulation-form-wrap">
                      <Form className="simulation-form">
                        <CustomSelect />
                        <CustomSelect />
                        <div className="monte-form-group" onClick={() => setShowInputVariables(true)}>
                          <span className='form-label'>Configure Input Variables</span>
                          <div className="monte-select-button">
                            <span className='disable-placeholder d-none'>Select...</span>
                            <p className='monte-modal-value'>Reservoir Gradient 1, Ambient Temperature <span className='value-numbers'> +3</span></p>
                          </div>
                        </div>

                        <span className='demand-slider-value form-label'>No. Iteration*({sliderValue}%)</span>
                        <div className="slider-wrap-inner">
                          <div className='schedule-slider-progress-value-wrap'>
                            <div className="star-progress-bar">
                              <Slider
                                min={10}
                                max={200}
                                value={sliderValue}
                                step={10}
                                onChange={sliderChangeHandler}
                              />
                            </div>
                          </div>
                          <Button className='btn-outline calculate-btn'>250</Button>
                        </div>

                        <div className="simulate-btn-wrap">
                          <Button className="primary">Calculate</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Object Parameters modal start here */}
      <Modal
        show={ShowObjectModal}
        onHide={() => setShowObjectModal(false)}
        dialogClassName="modal-450 top-right-modal"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowObjectModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Turbines</h4>
          <div className="dsider-tab-wrapper">
            <Tabs
              defaultActiveKey="parameters"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="parameters" title="Parameters">
                <div className="tab-main-container">
                  <div className="operational-main-wrapper">
                    <Form className="object-parameter-form">
                      <div className="setting-form-content">
                        <Form.Group
                          className="form-group error-field"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="false"
                          />
                          <span className="error-msg">
                            This field is required.
                          </span>
                          <span className="input-logo">
                            <img src={locationLogo} alt="logo" />
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Capital Cost</Form.Label>
                          <div className="form-Control-wrapper">
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                              className="big-input"
                            />
                            <div className="custom-select-main capital-cost-select">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                defaultValue={capitalCost[1]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={capitalCost}
                              // menuIsOpen={true}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Load Factor</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="false"
                          />
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Period Cost</Form.Label>
                          <div className="form-Control-wrapper">
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                              className="big-input"
                            />
                            <div className="custom-select-main capital-cost-select">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                defaultValue={periodCost[1]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={periodCost}
                              // menuIsOpen={true}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Power Capacity</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="false"
                          />
                          <span className="input-logo">
                            <img src={infoInputLogo} alt="logo" />
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Price of excess sold</Form.Label>
                          <div className="form-Control-wrapper">
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                              className="big-input"
                            />
                            <div className="custom-select-main capital-cost-select">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                defaultValue={priceSold[1]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={priceSold}
                              // menuIsOpen={true}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>PwrRETransVCost</Form.Label>
                          <div className="form-Control-wrapper">
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                              className="big-input"
                            />
                            <div className="custom-select-main capital-cost-select">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                defaultValue={transCost[1]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={transCost}
                              // menuIsOpen={true}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Take of Percent (%)</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="false"
                          />
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Transmission Loss Factor (%)</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="false"
                          />
                        </Form.Group>
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Variable cost of energy</Form.Label>
                          <div className="form-Control-wrapper">
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                              className="big-input"
                            />
                            <div className="custom-select-main capital-cost-select">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                defaultValue={variableCost[1]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={variableCost}
                              // menuIsOpen={true}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Modal.Footer>
                          <Button className="primary">Save Properties</Button>
                        </Modal.Footer>
                      </div>
                    </Form>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="output" title="Output">
                <div className="tab-main-container">
                  <div className="tab-main-wrapper">
                    <div className="financial-tab">
                      <Form>
                        <h4 className="output-value">
                          Renewable Power Available (KWH)
                        </h4>
                        <h4 className="output-value">
                          Renewable Power Produced (KWH)
                        </h4>
                      </Form>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="methods" title="Methods">
                <div className="tab-main-container">
                  <div className="tab-main-wrapper">
                    <div className="financial-tab carbon-tab">
                      <Form>
                        <div className="method-wrapper">
                          <p className="method-text">
                            This program calculates the Lowest Common multiple
                            for excessively long input values function
                            IcmNaive(Argument one, Argument two)
                            <br />
                            &#123;
                            <br />
                            Calculate the lowest common variable of Argument 1
                            and Argument 2 by dividing their product by their
                            Greatest common divisor product return lowest common
                            multiple end
                            <br /> &#125;
                            <br /> function greatestCommonDivisor(Argument one,
                            Argument two) &#123; <br />
                            if Argument two is equal to zero then return
                            Argument one return the greatest common divisor end
                            <br /> &#125;
                            <br /> &#123; <br />
                            In the main function Take the first number from the
                            user Take the second number from the user Send the
                            first number and second number to the IcmNaive
                            function and print the result to the user
                            <br /> &#125;
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey="contact" title="Contact" disabled>
          Tab content for Contact
        </Tab> */}
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>

      {/* Input Output modal start here */}
      <Modal
        show={showInputOutput}
        onHide={() => setInputOutput(false)}
        dialogClassName="modal-418 top-right-modal input-output-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setInputOutput(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dsider-tab-wrapper input-output-main">
            <div className="input-output-inner">
              <div className="input-img-wrap">
                <span className='input-node-img'>
                  <img src={inputModalImg} alt="img logo" />
                  <div className="input-node-wrap">
                    <span className='input-node'></span>
                    <span className='input-node'></span>
                    <span className='input-node'></span>
                    <span className='input-node'></span>
                    <span className='input-node'></span>
                  </div>
                  <div className="output-node-wrap">
                    <span className='output-node'></span>
                    <span className='output-node'></span>
                    <span className='output-node'></span>
                    <span className='output-node'></span>
                    <span className='output-node'></span>
                  </div>
                </span>
                <p className='input-img-des'>Electricity LB</p>
              </div>
            </div>
            <div className="operational-main-wrapper">
              <div className="operational">
                <div className="supply">
                  <p className="supply-head">Input Nodes</p>
                  <div className="operational-box">
                    <span className="operational-box-des">Input Node 1</span>
                    <button className='btn btn-no-outline delete-btn'><img src={deleteRedLogo} alt="logo" /></button>
                  </div>
                  <div className="operational-box">
                    <span className="operational-box-des">
                      Input Node 2
                    </span>
                  </div>
                  <div className="operational-box">
                    <span className="operational-box-des">Input Node 3</span>
                  </div>
                  <div className="operational-box">
                    <span className="operational-box-des">Input Node 4</span>
                  </div>
                  <div className="operational-box">
                    <button className='btn btn-no-outline'>+Add more</button>
                  </div>
                </div>
              </div>
              <div className="operational operational-right">
                <div className="demand">
                  <p className="supply-head">Output Nodes</p>
                  <div className="operational-box">
                    <span className="operational-box-des">Output Node 1</span>
                  </div>
                  <div className="operational-box">
                    <span className="operational-box-des">
                      Output Node 2
                    </span>
                  </div>
                  <div className="operational-box">
                    <button className='btn btn-no-outline'>+Add more</button>
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <Button className="btn-no-outline" onClick={() => setInputOutput(false)}>
                  Cancel
                </Button>
                <Button className="primary">Save Changes</Button>
              </Modal.Footer>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Object Layers modal start here */}
      <Modal
        show={ShowObjectLayer}
        onHide={() => setShowObjectLayer(false)}
        dialogClassName="modal-418 top-right-modal input-output-modal object-layers-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowObjectLayer(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Object Layers</h4>
          <div className='objet-layer-main'>
            <div className='object-ul'>
              <div className="tab-left-part object-ul-inner">
                <div className="dropdown-wrapper shared-profile">
                  <span className="user-icon object-user-icon">
                    <img
                      src={turbineSmallLogo}
                      alt="logo"
                      className="user-img"
                    />

                  </span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">
                      Power Grid
                    </h3>
                  </div>
                </div>
                <div className='object-li-wrap'>
                  <div className='object-li'>
                    <div className="tab-left-part">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Turbine
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='object-li'>
                    <div className="tab-left-part">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Solar Panel
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='object-li'>
                    <div className="tab-left-part object-li-inner">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Battery
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className='object-li-nested-wrap'>
                      <div className="tab-left-part object-li-nested-inner">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon object-user-icon">
                            <img
                              src={turbineSmallLogo}
                              alt="logo"
                              className="user-img"
                            />

                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Battery
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="tab-left-part object-li-nested-inner">
                        <div className="dropdown-wrapper shared-profile">
                          <span className="user-icon object-user-icon">
                            <img
                              src={turbineSmallLogo}
                              alt="logo"
                              className="user-img"
                            />

                          </span>
                          <div className="right-sec">
                            <h3 className="dropdown-head">
                              Turbine
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="tab-left-part object-ul-inner">
                <div className="dropdown-wrapper shared-profile">
                  <span className="user-icon object-user-icon">
                    <img
                      src={turbineSmallLogo}
                      alt="logo"
                      className="user-img"
                    />

                  </span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">
                      Turbine
                    </h3>
                  </div>
                </div>
              </div>
              <div className="tab-left-part object-ul-inner">
                <div className="dropdown-wrapper shared-profile">
                  <span className="user-icon object-user-icon">
                    <img
                      src={turbineSmallLogo}
                      alt="logo"
                      className="user-img"
                    />

                  </span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">
                      Power Grid
                    </h3>
                  </div>
                </div>
                <div className='object-li-wrap'>
                  <div className='object-li'>
                    <div className="tab-left-part">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Turbine
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='object-li'>
                    <div className="tab-left-part">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Solar Panel
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='object-li'>
                    <div className="tab-left-part object-li-inner">
                      <div className="dropdown-wrapper shared-profile">
                        <span className="user-icon object-user-icon">
                          <img
                            src={turbineSmallLogo}
                            alt="logo"
                            className="user-img"
                          />

                        </span>
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            Battery
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add new Object modal start here */}
      <Modal
        show={ShowAddObject}
        onHide={() => setShowAddObject(false)}
        dialogClassName="modal-546 top-right-modal input-output-modal object-layers-modal add-object-modal"
        className="forget-modal setting-modal "
      >
        <Modal.Header closeButton className="" onClick={() => setShowAddObject(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New Object</h4>
          <div className="create-modal-left">
            <Form.Group
              controlId="formFile"
              className="form-group file-input error-field mb-0"
            >
              <div className="file-input-wrapper">
                <div className="file-upload-img">
                  {/* <img
                    src={compnayDummyLogo}
                    alt="img upload"
                    className="file-main-img upload-main-img"
                  /> */}
                  <img
                    src={fileInputImg}
                    alt="img main"
                    className="file-main-img empty-img"
                  />
                  {/* <img
                    src={crossRedLogo}
                    alt="img cross logo"
                    className="cross-logo"
                  /> */}
                  <span className="error-msg d-done">
                    Company logo is required.
                  </span>
                </div>
                <div className="file-input-text-wrapper" onClick={() => setShowObjectIcon(true)}>
                  <div className="file-attachment-wrapper">
                    <span className="file-input-attachment">
                      + Add Object Icon<sup>*</sup>
                    </span>
                  </div>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className='add-object-form-wrap'>
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className='multiselect-wrapper mb-25'>
                    <div className="multiselect-wrapper-inner checkbox-select grey-border-select">
                      <div className="custom-select-main">
                        <Form.Label>Project Type<sup>*</sup></Form.Label>
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={projectType}
                          placeholder=""
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          formatOptionLabel={({ label }) =>
                            <span className='checkbox-label-power'>{label}</span>
                          }
                        // menuIsOpen={true}

                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-select-main select-radio-grey grey-border-select">
                    <Form.Label>Object Name</Form.Label>
                    <Select
                      className="custom-select-wrp"
                      classNamePrefix="select"
                      defaultValue={colourOptions[1]}
                      // isDisabled={isDisabled}
                      isLoading={isLoading}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={colourOptions}
                      placeholder="User Role"
                    // menuIsOpen={true}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Group className="form-group form-group-object" onClick={() => setShowReferenceObject(true)}>
                    <Form.Label>Select Reference Object</Form.Label>
                    <Form.Control
                      type="text"
                      // value={locationLat}
                      autoComplete='off'
                    />

                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="form-group">
                    <Form.Label>Object Name</Form.Label>
                    <Form.Control
                      type="text"
                      // value={locationLat}
                      autoComplete='off'
                    />

                  </Form.Group>
                </div>
                <div className='add-parameter-main'>
                  <h4 className='form-group-subhead'>ADD PARAMETERS</h4>
                  <Form.Group className="form-group location-form-group" controlId="latitude">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="number"
                      // value={locationLat}
                      autoComplete='off'
                    />
                    <span className="input-logo location-img"><img src={locationLogo} alt="logo" className='location-img' /></span>
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Capital Cost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={capitalCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={capitalCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Load Factor</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Period Cost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={periodCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={periodCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Power Capacity</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                    <span className="input-logo location-img">
                      <img src={infoInputLogo} alt="logo" />
                    </span>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Price of excess sold</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={priceSold[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={priceSold}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>PwrRETransVCost</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={transCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={transCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Take of Percent (%)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Transmission Loss Factor (%)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="false"
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Variable cost of energy</Form.Label>
                    <div className="form-Control-wrapper">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="false"
                        className="big-input"
                      />
                      <div className="custom-select-main capital-cost-select">
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={variableCost[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={variableCost}
                        // menuIsOpen={true}
                        />
                      </div>
                    </div>
                  </Form.Group>

                </div>
                <div className="col-md-12">
                  <Button className='btn-no-outline new-parameter-btn' onClick={() => setShowAddParameter(true)}>+add new parameter</Button>
                </div>

              </div>

              <Modal.Footer>
                <Button className="btn-no-outline" onClick={() => setInputOutput(false)}>
                  Cancel
                </Button>
                <Button className="primary">Save Object</Button>
              </Modal.Footer>

            </Form>
          </div>
        </Modal.Body >
      </Modal>

      {/* Add Object Icon modal start here */}
      < Modal Modal
        show={ShowObjectIcon}
        onHide={() => setShowObjectIcon(false)}
        dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowObjectIcon(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add Object Icon</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={genericLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Generic
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={waterTank}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          External Gray Supply
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </ Modal>
      {/* Add New Parameter modal start here */}
      < Modal Modal
        show={ShowAddParameter}
        onHide={() => setShowAddParameter(false)}
        dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal add-new-parameter"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowAddParameter(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New Parameter</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul add-new-parameter-wrap'>
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="form-group location-form-group" controlId="latitude">
                      <Form.Label>Property Name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <div className="custom-select-main select-radio-grey grey-border-select">
                      <Form.Label>Unit</Form.Label>
                      <Select
                        className="custom-select-wrp"
                        classNamePrefix="select"
                        defaultValue={colourOptions[1]}
                        // isDisabled={isDisabled}
                        isLoading={isLoading}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={colourOptions}
                        placeholder="User Role"
                      // menuIsOpen={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="form-group location-form-group" controlId="latitude">
                      <Form.Label>Default Value</Form.Label>
                      <Form.Control
                        type="number"
                        autoComplete='off'
                      />
                    </Form.Group>
                  </div>
                </div>

                <Modal.Footer>
                  <Button className="btn-no-outline" onClick={() => setShowAddParameter(false)}>
                    Cancel
                  </Button>
                  <Button className="primary">Save</Button>
                </Modal.Footer>

              </Form>

            </div>
          </div>
        </Modal.Body>
      </ Modal>
      {/* Add Object Icon modal start here */}
      < Modal Modal
        show={ShowReferenceObject}
        onHide={() => setShowReferenceObject(false)}
        dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowReferenceObject(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Select Reference Object</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={genericLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Generic
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={waterTank}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          External Gray Supply
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={electrolysisLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Electrolyzer
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Turbine
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={turbineSmallLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Grid
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={windmillDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Solar Panel
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerBetteryDarkLogo}
                          alt="logo"
                          className="user-img"
                        />

                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Battery
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </ Modal>

      {/* Reservoir Simulation Results modal start here */}
      < Modal Modal
        show={ShowReservairModal}
        onHide={() => setShowReservairModal(false)}
        dialogClassName="modal-dialog-centered reservair-modal modal-934"
        className="setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowReservairModal(false)}>
          <Modal.Title className='modal-head modal-head-medium'>Reservoir Simulation Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Modal-wrap'>
            <div className="row">
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Maximum Production Temperature</p>
                  <span className='modal-table-value'>167.2°C</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Production Wellbore Heat Transmission Model</p>
                  <span className='modal-table-value'>Ramey Model</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Average Production Temperature</p>
                  <span className='modal-table-value'>167.2°C</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Avg. Production Well Temperature Drop</p>
                  <span className='modal-table-value'>3 °C</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Minimum Production Temperature</p>
                  <span className='modal-table-value'>165.2 °C</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Avg. Injection Well Pump Pressure Drop</p>
                  <span className='modal-table-value'>219.1 kPa</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Initial Production Temperature</p>
                  <span className='modal-table-value'>165.2 °C</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Avg. Production Well Pump Pressure Drop</p>
                  <span className='modal-table-value'>1248.2 kPa</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Avg. Reservoir Heat Extraction</p>
                  <span className='modal-table-value'>52.38 MW</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Avg. Net Electricity Production</p>
                  <span className='modal-table-value'>5.37 MW</span>
                </div>
              </div>
            </div>

          </div>
        </Modal.Body>
      </ Modal>

      {/* Capital Costs modal start here */}
      < Modal Modal
        show={ShowCapitalCostsModal}
        onHide={() => setShowCapitalCostsModal(false)}
        dialogClassName="modal-dialog-centered reservair-modal modal-843 capital-cost-modal"
        className="setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowCapitalCostsModal(false)}>
          <Modal.Title className='modal-head modal-head-medium'>Capital Costs (M$)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Modal-wrap'>
            <div className="row">
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Drilling and Completion Costs</p>
                  <span className='modal-table-value'>$22,000,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Field Gathering System Costs</p>
                  <span className='modal-table-value'>$2,300,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Drilling and Completion Costs per well</p>
                  <span className='modal-table-value'>$5,000,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Total Surface Equipment Costs</p>
                  <span className='modal-table-value'>$23,100,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Simulation Costs</p>
                  <span className='modal-table-value'>$3,000,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Exploration Costs</p>
                  <span className='modal-table-value'>$5,300,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Surface Power Plant Costs</p>
                  <span className='modal-table-value'>$20,000,000</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Total Capital Costs</p>
                  <span className='modal-table-value'>$53,530,000</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </ Modal>
      {/* Capital Costs modal start here */}
      < Modal Modal
        show={ShowMaintenanceCostsModal}
        onHide={() => setShowMaintenanceCostsModal(false)}
        dialogClassName="modal-dialog-centered reservair-modal modal-843 capital-cost-modal maintenance-cost-modal"
        className="setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowMaintenanceCostsModal(false)}>
          <Modal.Title className='modal-head modal-head-medium'>Operating & Maintenance Costs (M$/yr)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Modal-wrap'>
            <div className="row">
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Wellfield Maintenance Cost</p>
                  <span className='modal-table-value'>$400,000/yr</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Power Plant Maintenance Cost</p>
                  <span className='modal-table-value'>$900,000/yr</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Water Costs</p>
                  <span className='modal-table-value'>$60,000/yr</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className='modal-table-inner'>
                  <p className='modal-table-head'>Total Operating & Maintenance Cost</p>
                  <span className='modal-table-value'>1,400,000/yr</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </ Modal>

      {/* Select Input Variables modal start here */}
      < Modal Modal
        show={ShowInputVariables}
        onHide={() => setShowInputVariables(false)}
        dialogClassName="modal-817 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal add-new-parameter input-variables-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowInputVariables(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Select Input Variables</h4>
          <div className='objet-layer-main add-object-icon-main'>
            <div className='object-ul add-new-parameter-wrap'>
              <Form>
                <div className="checkbox-wrapper checkbox-grey-wrap">
                  <div className="monte-checkbox-wrap">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      label="Reservoir Gradient 1"
                      name="companycheckbox"
                    />
                    <div className='checkbox-input-wrap'>
                      <div className="custom-select-main grey-border-select">
                        <Form.Label>Distribution Type</Form.Label>
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={inputVariables[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={inputVariables}
                        />
                      </div>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Mean</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          autoComplete="false"
                          className="big-input"
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Standard Deviation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          autoComplete="false"
                          className="big-input"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="monte-checkbox-wrap">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      label="Ambient Temperature"
                      name="companycheckbox"
                    />
                    <div className='checkbox-input-wrap'>
                      <div className="custom-select-main grey-border-select">
                        <Form.Label>Distribution Type</Form.Label>
                        <Select
                          className="custom-select-wrp"
                          classNamePrefix="select"
                          defaultValue={inputVariables[1]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={inputVariables}
                        />
                      </div>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Value 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          autoComplete="false"
                          className="big-input"
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Value 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          autoComplete="false"
                          className="big-input"
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Value 3</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          autoComplete="false"
                          className="big-input"
                        />
                      </Form.Group>

                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <Modal.Footer>
            <Button className="btn-no-outline" onClick={() => setShowAddParameter(false)}>
              Cancel
            </Button>
            <Button className="primary">Submit</Button>
          </Modal.Footer>
        </Modal.Body>
      </ Modal>


    </>
  );
};

export default WorkBench;
