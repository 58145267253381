import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BUTTON_TITLES, IChangePassword, IChangePasswordCognitoObject, INPUT_FIELD_LABELS, MESSAGES, NUMBER, STATIC_STRINGS } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';
import { Auth } from 'aws-amplify';
import { changePassword } from '../../redux/auth/authReducer';
import { Formik } from 'formik';
import { CHANGE_PASS_VALIDATION_SCHEMA, hasFieldError } from '../../utils';
import InputElement from '../../components/shared/InputElement';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [validateOnChangeBlur, setValidateOnChangeBlur] = useState(false);

  const initialCPValues: IChangePassword = {
    oldPassword: '',
    newPassword: ''
  };

  const handleClose = () => {
    navigate(-NUMBER.N1);
  };

  const handleFormSubmitt = async (values: any) => {
    try {
      const userObject = await Auth.currentAuthenticatedUser();
      const params: IChangePasswordCognitoObject = {
        user: userObject,
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword
      };
      changePasswordSubmit(params);
    } catch (err) {
    }
  };

  const changePasswordSubmit = (values: IChangePasswordCognitoObject) => {
    if (values.newPassword === values.oldPassword) {
      toast.error(MESSAGES.INVALID_NEW_PASSWORD);
      return;
    }
    setShowLoader(true);
    dispatch(changePassword(values))
      .unwrap()
      .then((res) => {
        if ('error' in res) {
          const errorMsg = res.error.code === 'NotAuthorizedException' ? MESSAGES.INVALID_OLD_PASSWORD : res.error.message;
          toast.error(errorMsg);
        } else {
          toast.success(MESSAGES.CHANGE_PASS_SUCCESS);
          navigate('/');
        }
        setShowLoader(false);
      });
    setValidateOnChangeBlur(true);
  };

  return (
    <>
      <div className="change-pw-modal">
        <button className='close-btn' style={{ cursor: 'pointer' }} onClick={handleClose}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="modal-dialog full-width-dialog">
          <div className='modal-content'>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <Modal.Title>Change Password</Modal.Title>
              <Formik
                initialValues={initialCPValues}
                validationSchema={CHANGE_PASS_VALIDATION_SCHEMA}
                validateOnBlur={validateOnChangeBlur}
                validateOnChange={validateOnChangeBlur}
                onSubmit={handleFormSubmitt}
              >
                {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                  <Form className="full-width-form" onSubmit={handleSubmit}>
                    {/* password */}
                    <InputElement
                      label={INPUT_FIELD_LABELS.CURRENT_PASS}
                      placeholder="Current Password"
                      required={true}
                      type='password'
                      name='oldPassword'
                      data-testid="oldPassword"
                      onChange={handleChange}
                      resetError={setFieldError}
                      onBlur={handleBlur}
                      hasError={hasFieldError(errors, touched, 'oldPassword')}
                      autoComplete={false}
                    />
                    {/* password */}
                    <InputElement
                      label={INPUT_FIELD_LABELS.SET_PASS}
                      placeholder="New Password"
                      required={true}
                      type='password'
                      name='newPassword'
                      data-testid="newPassword"
                      subLabel={STATIC_STRINGS.PASS_DESCRIPTION}
                      onChange={handleChange}
                      resetError={setFieldError}
                      onBlur={handleBlur}
                      hasError={hasFieldError(errors, touched, 'newPassword')}
                      autoComplete={false}
                    />
                    <div className="sign-btn-wrapper">
                      <Button variant="primary" type="submit">
                        {showLoader ? <Spinner /> : BUTTON_TITLES.SAVE}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
