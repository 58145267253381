import React, { useCallback, useEffect } from 'react';
import { Edge, Handle, Position } from 'reactflow';
import { IObjectList, NUMBER } from '../../constants';
import { createArrayWithLength, layersIcon } from '../../utils';
import { useAppSelector } from '../../redux/hooks';

interface ICustomNodeProps {
  data: IObjectList
  id: string
}

const DsiderCustomNode = ({ data, id }: ICustomNodeProps) => {
  const theme = useAppSelector(state => state.workbench.theme);
  const inputNode = data.no_of_inputs;
  const outputNode = data.no_of_outputs;
  const outputTopVariance = NUMBER.N100 / (outputNode + NUMBER.N1);
  const inputTopVariance = NUMBER.N100 / (inputNode + NUMBER.N1);
  const { nodes: workbenchNodes, edges: workbenchEdges, showComment: showCommentFlag } = useAppSelector(state => state.workbench);
  const { BaselineStatus } = useAppSelector(state => state.workbench.scenarioDetails);
  const hasLayers = workbenchNodes.find((node: any) => node.data.parentNode === id);

  // Determine if the handle is connected to an edge
  const isHandleConnected = (nodeId: string) => {
    return workbenchEdges.some((element: Edge) =>
      (element?.source === nodeId || element.target === nodeId)
    );
  };

  /**
   * function to add/remove disabled class when its capacityProperty value is Zero...
   */
  const getDisableClassName = useCallback((data: any) => {
    const nodeEle = document.querySelectorAll(`[data-id="${id}"]`)?.[NUMBER.N0];
    let canDisabled = false;
    if (Array.isArray(data.capacityProperty)) {
      canDisabled = true;
      data.capacityProperty[0].split(',').map((cp: string) => {
        if (typeof data === 'object' && typeof data[cp] === 'object') {
          const finalData = data[cp]?.scheduleData[data[cp]?.scheduleType];
          for (const key in finalData) {
            if (finalData[key] > NUMBER.N0) {
              canDisabled = false;
              break;
            }
          }
        } else if (+data[cp] > NUMBER.N0) {
          canDisabled = false;
        }
      });
    } else {
      canDisabled = +data[data.capacityProperty] === NUMBER.N0;
    }
    if (canDisabled) {
      nodeEle.classList.add('disabled');
    } else {
      nodeEle.classList.remove('disabled');
    }
  }, [workbenchNodes]);

  useEffect(() => {
    const updatedData = workbenchNodes.find((d) => d.id === id)?.data;
    getDisableClassName(updatedData ?? {});
  }, [workbenchNodes]);

  return (
    <div className={showCommentFlag && !BaselineStatus ? 'custom-cursor' : ''} >
      {(data.input_node_arr ? data.input_node_arr : createArrayWithLength(inputNode)).map((val, index) => (
        <Handle
          className={isHandleConnected(id) ? 'edge-connected' : ''}
          key={val}
          type="target"
          position={Position.Left}
          style={{ top: `${((index + NUMBER.N1) * inputTopVariance)}%` }}
          id={`input-${val}-${id}`} />
      ))}

      <div className='node-container'>
        {
          (data?.comments?.length > NUMBER.N0 && showCommentFlag) &&
          <div className="comment-small-wrap">
            <span className="profile-icon medium orange">{data?.comments[data?.comments.length - NUMBER.N1].registerUser.charAt(0)}</span>
          </div>
        }
        {
          hasLayers && <span className='layer-icon'>
            <img src={layersIcon} alt="layer" />
          </span>
        }
        <img className='object-icon' height="30px" width="30px" src={theme ? data.imageS3urlDark : data.imageS3url} alt="logo image" />
        <span className='object-label'>{data.componentDisplayName}</span>
      </div>
      {(data.output_node_arr ? data.output_node_arr : createArrayWithLength(outputNode)).map((val, index) => (
        <Handle
          className={isHandleConnected(id) ? 'edge-connected' : ''}
          key={val}
          type="source"
          position={Position.Right}
          style={{ top: `${((index + NUMBER.N1) * outputTopVariance)}%` }}
          id={`output-${val}-${id}`} />
      ))}
    </div>
  );
};

export default DsiderCustomNode;
