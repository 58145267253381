import React, { useCallback, useState } from 'react';
import { Node, useReactFlow, useUpdateNodeInternals } from 'reactflow';
import { NUMBER, USER_PERMISSIONS } from '../../constants';
import { hasPermissionToChangeComponents, rand } from '../../utils';
import ChangeInputOutputNode from './ChangeInputOutputNode';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateNodes, updateUnsavedChange } from '../../redux/workbenchSlice';
import { useComponentProperties } from '../../hooks/useComponentProperty';

interface IContextMenuProps {
  id: string
  top: string
  left: string
  right: string
  bottom: string
  selectedNode: Node
  onClick: () => void
  showparameterpopup: () => void
  takeSnapshot: () => void
}

export default function ContextMenu({ id, top, left, right, bottom, showparameterpopup, takeSnapshot, ...props }: IContextMenuProps) {
  const { getNode, setNodes, addNodes, getNodes, setEdges } = useReactFlow();
  const [showInputOutputModal, setShowInputOutputModal] = useState(false);
  const dispatch = useAppDispatch();
  const workbenchNodes = useAppSelector(state => state.workbench.nodes);
  const userPermission = useAppSelector(state => state.workbench.scenarioDetails.permission); // if shared
  const { BaselineStatus } = useAppSelector(state => state.scenarioDetails);
  const userData = useAppSelector(state => state.auth);
  const updateNodeInternals = useUpdateNodeInternals();
  const { deleteNodeAndDescendants, deleteEdgesAndDescendants } = useComponentProperties();

  // handled change component permissions...
  const canChangeComponents = hasPermissionToChangeComponents(!!userPermission, userData, !!BaselineStatus);
  const isDisableObjectMenu = userData.permissions?.includes(USER_PERMISSIONS.disable_object_menu);
  const disabledClass = canChangeComponents && !isDisableObjectMenu ? '' : 'hide';

  const duplicateNode = useCallback(() => {
    props.onClick();
    const node = getNode(id) ?? {} as any;
    const position = {
      x: node?.position?.x + NUMBER.N50 + rand(rand(NUMBER.N200)),
      y: node?.position?.y + NUMBER.N50 + rand(NUMBER.N50)
    };
    // get duplicated component count and add that in componentDisplayName...
    const duplicateNode = getNodes().filter(n => n.data.class === node.data.class);
    const randomId = `${node.id}${rand(NUMBER.N5000)}-copy`;
    // 👇 make dublicate nodes undoable
    takeSnapshot();
    addNodes({
      ...node,
      id: randomId,
      position,
      data: {
        ...node.data,
        componentDisplayName: `${node.name}${duplicateNode.length ? `-${duplicateNode.length}` : ''}`
      }
    });
    setTimeout(() => {
      // manually trigger click on dublicated component to fix drag issue...
      document.getElementById(randomId)?.click();
    }, NUMBER.N500);
    dispatch(updateUnsavedChange(true));
  }, [id, getNode, addNodes]);

  const deleteNode = useCallback(() => {
    props.onClick();
    // 👇 make removing nodes undoable
    takeSnapshot();
    // 👇 remove node and the layered nodes if the parent node get deleted...
    deleteNodeAndDescendants(id);
    deleteEdgesAndDescendants(id);
    if (workbenchNodes.length === NUMBER.N1) { // reset the workbench nodes when there is only one component and is deleted itself...
      dispatch(updateNodes([]));
    }
    dispatch(updateUnsavedChange(true));
  }, [id, setNodes, setEdges]);

  const removeLink = useCallback(() => {
    props.onClick();
    // 👇 make removing edges undoable
    takeSnapshot();
    setEdges((edges) => edges.filter((edge) => !(edge.source === id || edge.target === id)));
    dispatch(updateUnsavedChange(true));
  }, []);

  const showProperties = () => {
    props.onClick();
    showparameterpopup();
  };

  // function to update no of input and output of a Node in workbench...
  const updateWorkbenchNodes = (workbenchNodes: Node[]) => {
    // 👇 make change input/output nodes undoable
    takeSnapshot();
    setNodes(workbenchNodes);
    updateNodeInternals(props.selectedNode.id);
    dispatch(updateUnsavedChange(true));
    props.onClick();
  };

  const closeModal = () => {
    setShowInputOutputModal(false);
    props.onClick();
  };

  return (
    <>
      <div className="hover-box-wrap" style={{ top, left, right, bottom }}>
        <div className="hover-box">
          <span className="disable-text">Options</span>
          <ul className="hover-box-inner">
            <li className={`hover-box-item ${disabledClass}`} onClick={() => setShowInputOutputModal(true)}>Change Input/Output Nodes</li>
            <li className={`hover-box-item ${disabledClass}`} onClick={duplicateNode}>Duplicate Component</li>
            <li className={`hover-box-item ${disabledClass ? 'margin-b0' : 'hover-box-divider'}`} onClick={showProperties}>Show Properties</li>
            <li className={`hover-box-item text-danger ${disabledClass}`} onClick={removeLink}>Remove Link</li>
            <li className={`hover-box-item text-danger ${disabledClass}`} onClick={deleteNode}>Delete Component</li>
          </ul>
        </div>
      </div >
      {showInputOutputModal &&
        <ChangeInputOutputNode
          showModal={showInputOutputModal}
          closeModalHandler={closeModal}
          component={props.selectedNode}
          updateWorkbenchNodes={updateWorkbenchNodes}
        />
      }
    </>
  );
}
