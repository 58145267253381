import React from 'react';
import NoData from '../shared/NoData';
import { IScenarioDetails, MESSAGES } from '../../constants';
import ScenarioListCard from './ScenarioListCard';
import { useGetBaseLineProjectListQuery } from '../../redux/services/requestApprovalApis';
import { useAppDispatch } from '../../redux/hooks';
import { stopFullLoading } from '../../redux/SpinnerSlice';

interface IProps {
  searchTerm: string
}

const BaseLineCard = ({ searchTerm }: IProps) => {
  const { data, refetch, isLoading } = useGetBaseLineProjectListQuery();
  const dispatch = useAppDispatch();

  const baselineFilteredData = data?.filter((item) =>
    item.name.toLowerCase()?.includes(searchTerm)
  );

  const refresh = () => {
    refetch().then(() => {
      dispatch(stopFullLoading());
    });
  };

  return (
    <div className="project-card-wrapper">
      <span className="tab-sec-thead">
         Baseline Scenarios ({baselineFilteredData?.length})
      </span>
      <div className="row">
        {
          baselineFilteredData?.map((scenario: IScenarioDetails) => {
            return (
              <ScenarioListCard key={scenario._id}
                data={scenario}
                refreshData={refresh}
                isBaseLine={true}
              />
            );
          })
        }
        {
          (!baselineFilteredData?.length && searchTerm) ? <NoData message={MESSAGES.NO_RECORD_FOUND} /> : ''
        }
        {
          (!searchTerm && !baselineFilteredData?.length && !isLoading) && (
            < NoData
              message={MESSAGES.NO_SCENARIO_FOUND}
              image=""
            />
          )
        }
      </div>
    </div>
  );
};

export default BaseLineCard;
