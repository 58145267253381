import React from 'react';
import { Button } from 'react-bootstrap';
import UserAvatar from '../../approval-workflow/UserAvatar';
import { NUMBER } from '../../../constants';

interface IUserListTable {
  data: any
  isLoading: any
}

const UserListTable = ({ data, isLoading }: IUserListTable) => {
  if (isLoading) {
    return null;
  }

  return (
    <div className="tab-main-container tab-max-300">
      {data.length === 0 ? <div className="tab-main-wrapper">No user exists.</div> : <></>}
      {data.map((item: any, index: number) => (
        <div className="tab-main-wrapper" key={item.email}>
          <div className="tab-left-part">
            <div className="dropdown-wrapper shared-profile">
              <UserAvatar
                classname="user-icon small orange"
                firstname={item.name?.split(' ')[NUMBER.N0] ?? ''}
                lastname={item.name?.split(' ')[NUMBER.N1] ?? ''}
                imageUrl={item.imageUrl}
              />
              <div className="right-sec">
                <h3 className="dropdown-head">{item.name}</h3>
                <p className="dropdown-des">{item.email}</p>
              </div>
            </div>
          </div>
          <div className="tab-right-part">
            <Button className={`btn-no-outline default-cursor text-captilize ${item.status === 'active' ? 'success' : 'danger'}`}>
              {item.status}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserListTable;
