import React from 'react';
import { ButtonGroup, Dropdown, Tab, Tabs } from 'react-bootstrap';
import {
  hydrogenLogoSmall,
  powerSmallLogo,
  threeDotHorizontal
} from '../../utils/icons';

const DsiderTab = () => {
  return (
    <>
      <div className="dsider-tab-wrapper">
        <div className="container">
          <Tabs
            defaultActiveKey="projects"
            id="uncontrolled-tab-example"
            className=""
          >
            <Tab eventKey="projects" title="Projects">
              <span className="tab-sec-thead">All Scenarios (3)</span>
              <div className="tab-main-container">
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des">Model/Green Hydrogen</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-right-part">
                    <span className="tab-date">Updated on: May 4, 2023</span>
                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          <Dropdown.Item as="button">Share Model</Dropdown.Item>
                          <Dropdown.Item as="button">Export JSON</Dropdown.Item>
                          <Dropdown.Item as="button">
                            Duplicate Model
                          </Dropdown.Item>
                          <Dropdown.Item as="button">Edit Model</Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove Model
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={powerSmallLogo}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Green Hydrogen with Battery
                        </h3>
                        <p className="dropdown-des">Model/Green Hydrogen</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-right-part">
                    <span className="tab-date">Updated on: May 4, 2023</span>
                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          <Dropdown.Item as="button">Share Model</Dropdown.Item>
                          <Dropdown.Item as="button">Export JSON</Dropdown.Item>
                          <Dropdown.Item as="button">
                            Duplicate Model
                          </Dropdown.Item>
                          <Dropdown.Item as="button">Edit Model</Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove Model
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">
                          Green Hydrogen with Battery
                        </h3>
                        <p className="dropdown-des">Model/Green Hydrogen</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-right-part">
                    <span className="tab-date">Updated on: May 4, 2023</span>

                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          <Dropdown.Item as="button">Share Model</Dropdown.Item>
                          <Dropdown.Item as="button">Export JSON</Dropdown.Item>
                          <Dropdown.Item as="button">
                            Duplicate Model
                          </Dropdown.Item>
                          <Dropdown.Item as="button">Edit Model</Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove Model
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
              </div>
              <span className="tab-sec-thead">Shared with me (2)</span>
              <div className="tab-main-container shared-container">
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <span className="shared-by">Shared by Alan Smith</span>
                        <h3 className="dropdown-head">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des">Model/Green Hydrogen</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-right-part">
                    <span className="tab-date">Updated on: May 4, 2023</span>
                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          <Dropdown.Item as="button">Share Model</Dropdown.Item>
                          <Dropdown.Item as="button">Export JSON</Dropdown.Item>
                          <Dropdown.Item as="button">
                            Duplicate Model
                          </Dropdown.Item>
                          <Dropdown.Item as="button">Edit Model</Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove Model
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <span className="shared-by">Shared by Alan Smith</span>
                        <h3 className="dropdown-head">
                          Green Hydrogen with Battery
                        </h3>
                        <p className="dropdown-des">Model/Green Hydrogen</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-right-part">
                    <span className="tab-date">Updated on: May 4, 2023</span>
                    {[''].map((direction) => (
                      <Dropdown
                        className="create-modal-dropdown three-dot-dropdown"
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={threeDotHorizontal} alt="three dot icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.ItemText>Options</Dropdown.ItemText>
                          <Dropdown.Item as="button">Share Model</Dropdown.Item>
                          <Dropdown.Item as="button">Export JSON</Dropdown.Item>
                          <Dropdown.Item as="button">
                            Duplicate Model
                          </Dropdown.Item>
                          <Dropdown.Item as="button">Edit Model</Dropdown.Item>
                          <Dropdown.Item as="button" className="text-danger">
                            Remove Model
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="objects" title="Objects">
              <span className="tab-sec-thead">All Objects (6)</span>
              <div className="tab-main-container">
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">Turbine</h3>
                        <p className="dropdown-des mediam">
                          Power Object, Green Hydrogen +2
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des mediam">
                          Model/Green Hydrogen
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des mediam">
                          Model/Green Hydrogen
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des mediam">
                          Model/Green Hydrogen
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des mediam">
                          Model/Green Hydrogen
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-main-wrapper">
                  <div className="tab-left-part">
                    <div className="dropdown-wrapper shared-profile">
                      <span className="user-icon medium">
                        <img
                          src={hydrogenLogoSmall}
                          alt="logo"
                          className="user-img"
                        />
                      </span>
                      <div className="right-sec">
                        <h3 className="dropdown-head bold">
                          Green Hydrogen Basecase
                        </h3>
                        <p className="dropdown-des mediam">
                          Model/Green Hydrogen
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey='contact' title='Contact' disabled>
          Tab content for Contact
        </Tab> */}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default DsiderTab;
