import AdminDashboard from '../pages/admin/UserManagement';
import CompanyManagement from '../pages/admin/CompanyManagement';
import ObjectManagement from '../pages/admin/ObjectManagement';
import { ADMIN_CHANGE_PASSWORD, ADMIN_COMPANY_MANAGEMENT, ADMIN_EDIT_PROFILE, ADMIN_OBJECT_MANAGEMENT, ADMIN_SIGN_IN, ADMIN_USER_MANAGEMENT } from '../utils';
import ChangePassword from '../pages/auth/ChangePassword';
import AdminLogin from '../pages/auth/AdminLogin';
import EditProfile from '../pages/auth/EditProfile';

const AdminRoutes = [
  { path: ADMIN_USER_MANAGEMENT, component: AdminDashboard, isPrivate: true, isAdmin: true },
  { path: ADMIN_COMPANY_MANAGEMENT, component: CompanyManagement, isPrivate: true, isAdmin: true },
  { path: ADMIN_OBJECT_MANAGEMENT, component: ObjectManagement, isPrivate: true, isAdmin: true },
  { path: ADMIN_CHANGE_PASSWORD, component: ChangePassword, isPrivate: true, isAdmin: true },
  { path: ADMIN_SIGN_IN, component: AdminLogin, isPrivate: true, isAdmin: true },
  { path: ADMIN_EDIT_PROFILE, component: EditProfile, isPrivate: true, isAdmin: true }
];

export default AdminRoutes;
