import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './redux/store';
import { NUMBER } from './constants/numbers';
import { PersistGate } from 'redux-persist/integration/react';
import { ScrollProvider } from './hooks/useScrollProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  /* commented StrictMode for devlopment mode to avoid useEffect to run twice...
    we will uncomment this for production because in production mode StrictMode does not run useEffect twice...
  */
  // <React.StrictMode>
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <ScrollProvider>
        <App />
      </ScrollProvider>
      <ToastContainer
        position="top-center"
        autoClose={NUMBER.N5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={NUMBER.N1}
        transition={Slide}
      />
    </Provider>
  </PersistGate>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
