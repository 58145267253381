import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { exportIcon } from '../../utils';
import { Link } from 'react-router-dom';
import { NUMBER, VEHICLES_GRAPH_COLORS } from '../../constants';

interface Props {
  scenerioNames: string[]
}

const getColorCodesForLegends = (i: number) => {
  const colorArray = VEHICLES_GRAPH_COLORS;
  return colorArray[i];
};
const CompareLegends: FC<Props> = ({ scenerioNames }) => {
  return (
    <div className="chart-right">
      <div className="chart-right-inner">
        <div className="slider-wrap">
          <div className="simulation-form-wrap power-tab-right">
            {scenerioNames.map((scenerio, i: number) => <><p className="slider-head">{scenerio}</p>
              <div className="scenario-list">
                <div className="color-box" style={{ backgroundColor: getColorCodesForLegends(i * NUMBER.N2) }}></div>
                <span className='color-box-des'>Demand Total</span>
              </div>
            </>)}

            {/* <div className="simulate-btn-wrap">
              <Button className="primary">
                <img src={exportIcon} alt="logo img" className='export-logo' />
                            Export</Button>
            </div>
            <Link to={''} className='data-table-link' >See Data Table</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareLegends;
