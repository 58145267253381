
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { deleteRedLogo, exportIcon, inputModalImg } from '../../utils';
import Header from './HeaderWorkbenchHtml';
import { Link } from 'react-router-dom';

const CompareModal = () => {
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);
  const [showInputOutput, setInputOutput] = useState(false);

  const colourOptions = [
    { value: 'select', label: 'Select', isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: 'green hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'blue carbon', label: 'Blue Carbon' },
    { value: 'green hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'renewable energy', label: 'Renewable Energy' },
    { value: 'power', label: 'Power', isDisabled: true },
    { value: 'agricultural land management', label: 'Agricultural Land Management' },
    { value: 'power', label: 'Power', isDisabled: true },
    { value: 'forestry and land use', label: 'Forestry and Land Use' },
    { value: 'green hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'household & community use', label: 'Household & Community Use' }
  ];
  const multiSelect = [
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: 'blue carbon scenario no.3', label: 'Blue Carbon Scenario No.3', isFixed: true },
    { value: 'renewable energy', label: 'Renewable Energy' },
    { value: 'renewable energy', label: 'Renewable Energy' },
    { value: 'green hydrogen base', label: 'Green Hydrogen Base Green Hydrogen Base' }

  ];

  return (
    <>
      <div className="main-login dashboard-main compare-modal">
        <Header />
        <div className="container">
          <div className="main-login-wrapper">
            <div className="dashboard-main-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-left">
                    <div className="dashboard-header">
                      <h1>Compare Simulation Statistics</h1>
                      <p className='dashboard-header-des'>Search & add 2 or more models to compare.</p>
                    </div>
                    <div className="multiselect-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="custom-select-main">
                            <Select
                              className="custom-select-wrp"
                              classNamePrefix="select"
                              defaultValue={colourOptions[2]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={colourOptions}
                              placeholder="Select a project"
                            // menuIsOpen={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="multiselect-wrapper-inner checkbox-select">
                            <div className="custom-select-main">
                              <Select
                                className="custom-select-wrp"
                                classNamePrefix="select"
                                // defaultValue={multiSelect[3]}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={false}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={multiSelect}
                                placeholder="Select 2 or more models to compare"
                                isMulti={true}
                                menuIsOpen={true}
                              />
                              <span className='select-count'>+3</span>
                            </div>
                            <Button className="no-radius">Compare</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {/* table */}
                  <div className='table-wrapper'>
                    <div className="table-container contest-table">
                      <div className="table-head">
                        <div className="table-row">
                          <div className="th">Model</div>
                          <div className="th">Capex</div>
                          <div className="th">Variable</div>
                          <div className="th">Period</div>
                          <div className="th">Local Tax Credits</div>
                          <div className="th">Carbon Tax</div>
                          <div className="th">LCOH</div>
                          <div className="th">LCOE</div>
                          <div className="th">NPV</div>
                          <div className="th">IRR</div>
                          <div className="th">Emissions</div>
                        </div>
                      </div>
                      <div className="table-body">
                        <div className="table-row">
                          <div className="td" data-label="Model"><span className='td-text'>Green Hydrogen Baseca Green Hydrogen Baseca</span></div>
                          <div className="td" data-label="Capex"><span className='td-text'>$404,000,100</span></div>
                          <div className="td" data-label="Variable"><span className='td-text'>$404,000,100</span></div>
                          <div className="td" data-label="Period"><span className='td-text'>$3,129,400</span></div>
                          <div className="td" data-label="Local Tax Credits"><span className='td-text'>$2</span></div>
                          <div className="td" data-label="Carbon Tax"><span className='td-text'>$0</span></div>
                          <div className="td" data-label="LCOH"><span className='td-text'>$4.79</span></div>
                          <div className="td" data-label="LCOE"><span className='td-text'>$4.04</span></div>
                          <div className="td" data-label="NPV"><span className='td-text'>$-1,280,938,930</span></div>
                          <div className="td" data-label="IRR"><span className='td-text'>$-1,280,938,930</span></div>
                          <div className="td" data-label="Emissions Mitigated">
                            <span className='td-text'>9372 MMT</span>
                            <span className='icon-next-arrow-grey'></span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="simulation-chart-wrapper">
              <div className="chart-left">
                <h2 className="chart-title">Simulation Overview (01 Jan 2023 - 31 Dec 2050)</h2>
                <div className="chart-radio-wrapper">
                  <Form>
                    <div className="checkbox-wrapper">
                      <Form.Check
                        type="radio"
                        id="default-checkbox"
                        label="Power"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox1"
                        label="Total Hydrogen"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox2"
                        label="Hydrogen Breakdown"
                        name="simulationOverview"
                      />
                      <Form.Check
                        type="radio"
                        id="default-checkbox3"
                        label="Over Under Chart"
                        name="simulationOverview"
                      />

                    </div>
                  </Form>
                </div>
              </div>
              <div className="chart-right">
                <div className="chart-right-inner">
                  <div className="slider-wrap">
                    <div className="simulation-form-wrap power-tab-right">
                      <p className="slider-head">ELECTROLYSER Capacity</p>
                      <div className="scenario-list">
                        <div className="color-box"></div>
                        <span className='color-box-des'>Turbine</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box purple"></div>
                        <span className='color-box-des'>Solar</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box light-green"></div>
                        <span className='color-box-des'>Battery</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box orange"></div>
                        <span className='color-box-des'>Fuel Cell</span>
                      </div>
                      <p className="slider-head">low carbon economy scenario</p>
                      <div className="scenario-list">
                        <div className="color-box light-sky"></div>
                        <span className='color-box-des'>Turbine</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box pink"></div>
                        <span className='color-box-des'>Solar</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box light-cyan"></div>
                        <span className='color-box-des'>Battery</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box dark-red"></div>
                        <span className='color-box-des'>Fuel Cell</span>
                      </div>

                      <p className="slider-head">fossil fuel power scenario</p>
                      <div className="scenario-list">
                        <div className="color-box light-yellow"></div>
                        <span className='color-box-des'>Turbine</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box pink"></div>
                        <span className='color-box-des'>Solar</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box purple"></div>
                        <span className='color-box-des'>Battery</span>
                      </div>
                      <div className="scenario-list">
                        <div className="color-box dark-purple"></div>
                        <span className='color-box-des'>Fuel Cell</span>
                      </div>
                    </div>
                    <div className="simulate-btn-wrap">
                      <Button className="primary">
                        <img src={exportIcon} alt="logo img" className='export-logo' />
                        Export</Button>
                    </div>
                    <Link to={''} className='data-table-link' onClick={() => setInputOutput(true)}>See Data Table</Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Data Table modal start here */}
      <Modal
        show={showInputOutput}
        onHide={() => setInputOutput(false)}
        dialogClassName="modal-701 top-right-modal input-output-modal data-table-modal modal-dialog-centered"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setInputOutput(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="data-table-main">
            <div className="data-table-inner">
              <h3 className='data-table-title'>Data Table</h3>
              <div className='data-table-row-wrap'>
                <div className="data-table-row">
                  <span className="data-table-row-head">Blue Carbon Power Scenario</span>
                  <div className='data-table-inner-wrap'>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Date</h5>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Time</h5>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Tubine</h5>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Solar</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Battery</h5>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Fuel Cell</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                  </div>
                </div>
                <div className="data-table-row">
                  <span className="data-table-row-head">Blue Carbon Power Scenario</span>
                  <div className='data-table-inner-wrap'>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Date</h5>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Time</h5>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Tubine</h5>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Solar</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Battery</h5>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Fuel Cell</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                  </div>
                </div>
                <div className="data-table-row">
                  <span className="data-table-row-head">Blue Carbon Power Scenario</span>
                  <div className='data-table-inner-wrap'>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Date</h5>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                      <p className='data-table-value'>July 1, 2025</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Time</h5>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                      <p className='data-table-value'>03:15 pm</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Tubine</h5>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                      <p className='data-table-value'>0</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Solar</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Battery</h5>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                      <p className='data-table-value'>176.4</p>
                    </div>
                    <div className="data-table-col">
                      <h5 className='data-table-head'>Fuel Cell</h5>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                      <p className='data-table-value'>58.8</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default CompareModal;
