import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DATE_FORMATS, NUMBER } from '../../constants';

type dateString = string | undefined;

interface IDateTimeProps {
  createdDate: dateString
  updatedDate?: dateString
  defaultLabel?: string
}

function DateTime(props: IDateTimeProps) {
  const { createdDate, updatedDate, defaultLabel } = props;
  const date = updatedDate ?? createdDate;
  let label = updatedDate ? 'Updated' : 'Created';
  label = defaultLabel ?? label;
  const [dateTime, setDateTime] = useState('');

  const customFormatTimeDifference = (dateStr: dateString) => {
    const momentCreatedOn = moment.utc(dateStr);
    const now = moment.utc();

    let formattedTime = '';
    if (momentCreatedOn.isSame(now, 'day')) {
      formattedTime = `${label} Today`;
    } else if (momentCreatedOn.isSame(now.clone().subtract(NUMBER.N1, 'day'), 'day')) {
      formattedTime = `${label} Yesterday`;
    } else {
      formattedTime = `${label} on: ${momentCreatedOn.format(DATE_FORMATS.medium)}`;
    }
    setDateTime(formattedTime);
  };

  useEffect(() => {
    customFormatTimeDifference(date);
  });

  return (
    <span title={moment.utc(date).local().format(DATE_FORMATS.full)}>{dateTime}</span>
  );
}

export default DateTime;
