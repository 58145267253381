import React, { useEffect, useState } from 'react';
import Table from '../../table/Table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { objectColumns, objectSortableFields } from '../../../constants/admin.static';
import { useGetObjectListQuery, workbenchApis } from '../../../redux/services/workbenchApis';
import { IObjectList, LABELS, MESSAGES, NUMBER } from '../../../constants';
import { toFormatDate } from '../../../utils';
import AddNewObject from './AddNewObject';
import { updateSingleObject } from '../../../redux/slices/adminSlice';
import ModalHoc from '../shared/ModalHoc';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import { toast } from 'react-toastify';
import { useDeleteObjectMutation } from '../../../redux/services/admin/objectManagementApis';
import { stopFullLoading } from '../../../redux/SpinnerSlice';

const ObjectManagementTable = () => {
  const { objectSearch, singleObject } = useAppSelector(state => state.adminSlice);
  const [showAlert, setShowAlert] = useState(false);
  const [ShowAddObject, setShowAddObject] = useState(false);
  const { data: objectListRes, isSuccess: isObjectListSuccess } = useGetObjectListQuery();
  const [objectList, setObjectList] = useState<IObjectList[]>([]);
  const [filterData, setFilterData] = useState<IObjectList[]>([]);
  const dispatch = useAppDispatch();
  const [deleteObject] = useDeleteObjectMutation();

  useEffect(() => {
    if (isObjectListSuccess && objectListRes.data && objectListRes.data.length > NUMBER.N0) {
      const data = objectListRes.data;
      const finalData = data.map((ele) => {
        return {
          ...ele,
          name: ele.componentDisplayName,
          'power Category': ele.powerCategory?.charAt(NUMBER.N0).toUpperCase() + ele.powerCategory?.slice(NUMBER.N1) ?? '-',
          'last session': '-',
          'created On': toFormatDate(ele.createdOn)
        };
      });
      setObjectList(finalData);
    }
  }, [objectListRes, isObjectListSuccess]);

  useEffect(() => {
    objectList && setFilterData(objectList.filter((item: any) => {
      const search = objectSearch?.toLowerCase();
      return (
        item.name.toLowerCase()?.includes(search)
      );
    }));
    return () => {
      setFilterData(objectList);
    };
  }, [objectSearch]);

  useEffect(() => {
    if (objectList) {
      setFilterData(objectList.reverse());
    }
  }, [objectList]);

  const handleObjectData = (datas: any) => {
    dispatch(updateSingleObject(datas));
  };

  const handleDeleteUser = () => {
    hideConfirmationAlert();
    deleteObject(singleObject._id).unwrap().then((res: any) => {
      dispatch(workbenchApis.util.invalidateTags(['Workbench']));
      toast.success(MESSAGES.OBJECT_DELETED_SUCCESS);
    }).catch(() => {
      toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      dispatch(stopFullLoading());
    });
  };

  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="col-md-12">
      {objectList && objectList.length > NUMBER.N0 &&
        <Table
          data={filterData}
          columns={objectColumns}
          sortableFields={objectSortableFields}
          tableClass='object-management-table admin-table-container'
          tableType='objects'
          setShowUserCompany={setShowAddObject}
          handleUserData={handleObjectData}
          showConfirmationAlert={showConfirmationAlert}
        />}
      <AddNewObject ShowAddObject={ShowAddObject} setShowAddObject={setShowAddObject}
        isEditObject={true}
      />
      <ModalHoc
        showModal={showAlert} setShowModal={hideConfirmationAlert}
      >
        <ConfirmationAlert
          showAlert={showAlert}
          title={LABELS.DELETE_OBJECT}
          _id={singleObject.userId}
          message={MESSAGES.DELETE_OBJECT_MESSAGE}
          cancleBtnText={LABELS.NOT_NOW}
          yesBtnText={LABELS.YES_DELETE}
          onConfirm={handleDeleteUser}
          onCancel={hideConfirmationAlert}
        />
      </ModalHoc>
    </div>
  );
};

export default ObjectManagementTable;
