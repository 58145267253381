import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from "react-select";

function SelectCheckBox() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    { value: 'select', label: 'Select', isDisabled: true },
    // value: 'disabled', label: 'Select a color', isDisabled: true
    { value: 'green-hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'blue-carbon', label: 'Blue Carbon' },
    { value: 'green-hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'renewable-energy', label: 'Renewable Energy' },
    { value: 'power', label: 'Power', isDisabled: true },
    { value: 'agricultural-land-management', label: 'Agricultural Land Management' },
    { value: 'power', label: 'Power', isDisabled: true },
    { value: 'forestry-land-use', label: 'Forestry and Land Use' },
    { value: 'green-hydrogen', label: 'Green Hydrogen', isDisabled: true },
    { value: 'household-community-use', label: 'Household & Community Use' }
  ];
  return (
    <div className="custom-select-main grey-border-select">
      <Select
        className="custom-select-wrp"
        classNamePrefix="select"
        defaultValue={colourOptions[2]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={colourOptions}
        placeholder="Select a project"
      // menuIsOpen={true}
      />
    </div>
  );
}
export default SelectCheckBox;
