import React, { useState } from 'react';
import {
  NavDropdown,
  Dropdown,
  Form,
  Modal,
  Button,
  Container,
  Nav,
  Navbar,
  Tab,
  Tabs,
  ButtonGroup

} from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { backgroundLogo, backgroundLogoDark, editIcon, headerDarkLogo, headerLogo } from '../../utils/icons';

function HeaderWorkbenchHtml() {
  const [show, setShow] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [ShowExportCSVModal, setShowExportCSVModal] = useState(false);

  const handleExportCSV = () => {
    setShowExportCSVModal(true);
  };

  return (
    <>
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-body-tertiary workbench-header"
        >
          <Container>
            <Navbar.Brand href="#Operational">
              <img src={headerLogo} alt="header logo" className="header-logo" />
              {/* <img
                src={headerDarkLogo}
                alt="header logo"
                className="header-dark-logo"
              /> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#features" className="background-link">
                  <span className="background-logo">
                    <img src={backgroundLogo} alt="bg logo" />
                    {/* <img src={backgroundLogoDark} alt="bg logo" /> */}
                  </span>
                  Background
                </Nav.Link>
              </Nav>
              <Nav className="nav-dropdown-wrapper">
                <div className="user-circle plus-img">
                  <span className="user-icon">A</span>
                  <span className="user-icon">H</span>
                  <span className="user-icon">B</span>
                  <span className="user-icon">C</span>
                  <span className="user-icon five-plus">+1</span>
                </div>
                <div className="tooltip-container">
                  <Button
                    className="setting-btn btn-no-outline"
                    onClick={() => setShow(true)}
                  >
                    <div className="circle-logo-wrapper">
                      <span className="svg-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M7.4997 5.1953C6.2269 5.1953 5.1951 6.2271 5.1951 7.5C5.1951 8.7728 6.2269 9.8047 7.4997 9.8047C8.7726 9.8047 9.8044 8.7728 9.8044 7.5C9.8044 6.2271 8.7726 5.1953 7.4997 5.1953ZM13.9686 5.8554L13.3446 6.3691C12.6328 6.955 12.6328 8.045 13.3446 8.6309L13.9686 9.1446C14.1897 9.3267 14.2468 9.6419 14.1036 9.89L12.8714 12.0241C12.7282 12.2721 12.4267 12.3803 12.1585 12.2798L11.4016 11.9963C10.5382 11.6728 9.5943 12.2178 9.4427 13.1272L9.3099 13.9245C9.2628 14.207 9.0183 14.4141 8.7319 14.4141H6.2676C5.9812 14.4141 5.7367 14.207 5.6896 13.9245L5.5568 13.1272C5.4052 12.2178 4.4613 11.6728 3.5979 11.9963L2.8411 12.2798C2.5728 12.3803 2.27128 12.2721 2.12805 12.0241L0.895909 9.89C0.752709 9.6419 0.809809 9.3267 1.03094 9.1446L1.65496 8.6309C2.36673 8.045 2.36673 6.955 1.65496 6.3691L1.03094 5.8554C0.809809 5.6733 0.752709 5.3581 0.895909 5.11L2.12805 2.9759C2.27128 2.7278 2.5728 2.6197 2.8411 2.7202L3.5979 3.0037C4.4613 3.3271 5.4052 2.7822 5.5568 1.87277L5.6896 1.07552C5.7367 0.793007 5.9812 0.585938 6.2676 0.585938H8.7319C9.0183 0.585938 9.2628 0.793007 9.3099 1.07552L9.4427 1.87277C9.5943 2.7822 10.5382 3.3271 11.4016 3.0037L12.1585 2.7202C12.4267 2.6197 12.7282 2.7278 12.8714 2.9759L14.1036 5.11C14.2468 5.3581 14.1897 5.6733 13.9686 5.8554Z"
                            stroke="#5A5B5E"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </Button>
                  <div className="tooltip-text">Settings</div>
                </div>
                <Dropdown className="create-modal-dropdown export-dropdown">
                  <div className="tooltip-container">
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="circle-logo-wrapper">
                        <span className="svg-icon">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.906801 1.73597L9.28638 0.503479C9.33371 0.496489 9.38191 0.50006 9.4277 0.513937C9.47357 0.527813 9.51597 0.551673 9.55203 0.583904C9.58816 0.616141 9.61709 0.655985 9.63696 0.700753C9.65676 0.745514 9.66703 0.794148 9.66703 0.843358V15.1567C9.66703 15.2058 9.65676 15.2545 9.63696 15.2992C9.61716 15.3439 9.5883 15.3837 9.5523 15.4159C9.51623 15.4481 9.4739 15.472 9.42817 15.4859C9.38244 15.4999 9.33431 15.5035 9.28704 15.4966L0.906135 14.2641C0.747223 14.2408 0.601816 14.1592 0.496615 14.0343C0.39142 13.9095 0.333503 13.7497 0.333496 13.5844V2.41573C0.333503 2.2504 0.39142 2.09062 0.496615 1.96574C0.601816 1.84087 0.747889 1.75928 0.906801 1.73597ZM1.66743 3.01172V12.9884L8.33376 13.9696V2.03053L1.66743 3.01172ZM10.3337 12.8064H12.3336V3.19367H10.3337V1.82042H13.0002C13.177 1.82042 13.3466 1.89277 13.4716 2.02153C13.5966 2.1503 13.6668 2.32494 13.6668 2.50705V13.4931C13.6668 13.6752 13.5966 13.8498 13.4716 13.9786C13.3466 14.1073 13.177 14.1797 13.0002 14.1797H10.3337V12.8064ZM5.80055 8.00006L7.66712 10.7466H6.0672L5.0006 9.17694L3.93399 10.7466H2.33406L4.20064 8.00006L2.33406 5.25355H3.93399L5.0006 6.82319L6.0672 5.25355H7.66712L5.80055 8.00006Z" fill="#5A5B5E" />
                          </svg>

                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <div className="tooltip-text">Export Excel</div>
                  </div>
                  <Dropdown.Menu className='export-excel-menu'>
                    <Dropdown.ItemText>Options</Dropdown.ItemText>
                    <Dropdown.Item as="button">Import Excel</Dropdown.Item>
                    <Dropdown.Item as="button">Export Excel</Dropdown.Item>

                    <Dropdown.Item as="button" onClick={handleExportCSV}>Export Simulation as CSV</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="create-modal-dropdown export-dropdown">
                  <div className="tooltip-container">
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="circle-logo-wrapper">
                        <span className="svg-icon">
                          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.34386 2.98409L9.27016 2.9079H9.16416H8.91748H8.66748V3.1579V5.00944C8.66748 5.16322 8.72845 5.3108 8.83714 5.41968C8.94583 5.52858 9.09335 5.58984 9.24727 5.58984H11.011H11.261V5.33984V5.06891V4.96775L11.1906 4.89506L10.8134 4.50519L10.7397 4.42904H10.6337H9.82706V3.58477V3.48364L9.75676 3.41096L9.34386 2.98409Z" fill="#5A5B5E" stroke="#5A5B5E" strokeWidth="0.5" />
                            <path d="M13.7539 2.73158L13.7545 2.73225C13.8585 2.84066 13.9164 2.98509 13.9164 3.13524C13.9164 3.13528 13.9164 3.13531 13.9164 3.13535V15.1696C13.9164 15.3234 13.8554 15.471 13.7468 15.5798L13.5698 15.4032L13.7468 15.5798C13.6381 15.6887 13.4905 15.75 13.3366 15.75H2.56563C2.41171 15.75 2.26419 15.6887 2.15549 15.5798C2.04681 15.471 1.98584 15.3234 1.98584 15.1696V12.4273V12.1773H2.23584H2.89542H3.14542V12.4273V14.5892H12.7572V3.36977L10.8644 1.4108H3.14542V6.74317V6.99317H2.89542H2.23584H1.98584V6.74317V0.830399C1.98584 0.676616 2.04681 0.52904 2.15549 0.420159C2.26419 0.311263 2.41171 0.250003 2.56563 0.250003H11.1094H11.1099V0.500003C11.1542 0.499818 11.198 0.508621 11.2388 0.525884C11.2797 0.543146 11.3166 0.568509 11.3473 0.600443L13.7539 2.73158ZM13.7539 2.73158L11.5274 0.42701L13.7539 2.73158Z" fill="#5A5B5E" stroke="#5A5B5E" strokeWidth="0.5" />
                            <path d="M1.52486 8H8.66647V14.4193H1.5249L1.52486 8Z" fill="white" />
                            <path d="M0.177742 12.4359C0.293005 12.4781 0.414763 12.4997 0.537478 12.4997C0.844448 12.4997 1.35945 12.3808 1.35945 11.5843V10.202C1.35945 10.1494 1.3386 10.099 1.30149 10.0618C1.26439 10.0247 1.21406 10.0038 1.16158 10.0038H0.895368C0.842888 10.0038 0.792558 10.0247 0.755449 10.0618C0.718341 10.099 0.697493 10.1494 0.697493 10.202V11.5633C0.697493 11.877 0.621377 11.877 0.515844 11.877C0.464198 11.8773 0.412919 11.8683 0.364404 11.8506C0.337229 11.84 0.30805 11.8355 0.278956 11.8375C0.249862 11.8395 0.221571 11.848 0.196108 11.8622C0.170646 11.8765 0.148641 11.8962 0.131669 11.9199C0.114696 11.9437 0.103176 11.9709 0.0979321 11.9996L0.0583572 12.2172C0.0502696 12.2618 0.0577106 12.3079 0.0794379 12.3477C0.101165 12.3875 0.135864 12.4186 0.177742 12.4359Z" fill="#5A5B5E" />
                            <path d="M6.73202 12.483H6.98266C7.03514 12.483 7.08547 12.4622 7.12258 12.425C7.15968 12.3878 7.18053 12.3374 7.18053 12.2848V11.402C7.18053 11.3773 7.18053 11.353 7.18053 11.329C7.18317 11.3331 7.18581 11.3374 7.18845 11.3416L7.84315 12.39C7.861 12.4184 7.88575 12.4417 7.91507 12.4579C7.9444 12.4741 7.97734 12.4826 8.01081 12.4825H8.28006C8.33254 12.4825 8.38287 12.4616 8.41998 12.4244C8.45708 12.3873 8.47793 12.3368 8.47793 12.2843V10.2189C8.47793 10.1663 8.45708 10.1159 8.41998 10.0787C8.38287 10.0415 8.33254 10.0207 8.28006 10.0207H8.02941C7.97694 10.0207 7.9266 10.0415 7.8895 10.0787C7.85239 10.1159 7.83154 10.1663 7.83154 10.2189V11.1275L7.19003 10.1128C7.17221 10.0845 7.14753 10.0611 7.11828 10.045C7.08903 10.0288 7.05617 10.0203 7.02276 10.0203H6.73254C6.68006 10.0203 6.62973 10.0411 6.59262 10.0783C6.55552 10.1155 6.53467 10.1659 6.53467 10.2185V12.2848C6.53467 12.3373 6.55544 12.3876 6.59244 12.4248C6.62943 12.462 6.67963 12.4829 6.73202 12.483Z" fill="#5A5B5E" />
                            <path d="M4.96226 12.5169C5.53741 12.5169 6.11784 12.1191 6.11784 11.2306C6.11784 10.4984 5.65521 9.98712 4.99299 9.98712C4.3127 9.98712 3.8374 10.5148 3.8374 11.2705C3.83793 12.0043 4.30056 12.5169 4.96226 12.5169ZM4.98072 10.6006C5.34455 10.6006 5.44058 11.0203 5.44058 11.2422C5.44058 11.5708 5.29746 11.9022 4.97756 11.9022C4.66096 11.9022 4.5177 11.5718 4.5177 11.2636C4.5177 10.9554 4.63959 10.6011 4.98125 10.6011L4.98072 10.6006Z" fill="#5A5B5E" />
                            <path d="M1.86473 12.3495C2.05825 12.4606 2.27793 12.5179 2.50097 12.5152C3.13654 12.5152 3.42108 12.117 3.42108 11.7223C3.42108 11.2444 3.07203 11.0483 2.76823 10.9304C2.48487 10.8206 2.48487 10.768 2.48487 10.7169C2.48487 10.6473 2.55611 10.6055 2.67549 10.6055C2.779 10.6052 2.88098 10.6306 2.9723 10.6794C2.99765 10.6933 3.02572 10.7014 3.05455 10.7033C3.08337 10.7051 3.11225 10.7006 3.13916 10.6901C3.16606 10.6796 3.19034 10.6633 3.21028 10.6423C3.23022 10.6214 3.24535 10.5963 3.2546 10.5689L3.32808 10.3514C3.34281 10.3077 3.3419 10.2603 3.3255 10.2173C3.30911 10.1742 3.27825 10.1382 3.23825 10.1155C3.06717 10.0271 2.8766 9.98346 2.6842 9.98849C2.18291 9.98849 1.81922 10.3078 1.81922 10.7476C1.81922 11.1006 2.04678 11.3606 2.49081 11.5182C2.7524 11.6191 2.7524 11.6824 2.7524 11.7438C2.7524 11.8714 2.62536 11.898 2.51877 11.898C2.38709 11.8966 2.25815 11.8601 2.14519 11.7923C2.11937 11.7764 2.09025 11.7666 2.06007 11.7638C2.02989 11.7609 1.99945 11.765 1.97111 11.7758C1.94277 11.7866 1.91729 11.8038 1.89662 11.826C1.87596 11.8482 1.86067 11.8749 1.85194 11.904L1.78466 12.1276C1.77251 12.1683 1.77374 12.2118 1.78815 12.2518C1.80257 12.2917 1.82942 12.326 1.86473 12.3495Z" fill="#5A5B5E" />
                          </svg>
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <div className="tooltip-text">Export Json</div>
                  </div>
                  <Dropdown.Menu>
                    <Dropdown.ItemText>Options</Dropdown.ItemText>
                    <Dropdown.Item as="button">Import Json</Dropdown.Item>
                    <Dropdown.Item as="button">Export Json</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <NavDropdown
                  title="Share"
                  id="collapsible-nav-dropdown"
                  className="request-dropdown"
                  onClick={() => setShareModal(true)}
                >
                  <span>1</span>
                </NavDropdown> */}


                <button className="close-btn">
                  <span className="icon-close-grey-icon"></span>
                </button>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {/* setting modal start here */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-450 top-right-modal"
        className="forget-modal setting-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShow(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Settings</h4>
          <div className="dsider-tab-wrapper">
            <Tabs
              defaultActiveKey="operational"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="operational" title="Operational Rules">
                <div className="tab-main-container">
                  <div className="operational-main-wrapper">
                    <div className="operational">
                      <div className="supply">
                        <p className="supply-head">Supply Priority</p>
                        <div className="operational-box">
                          <span className="operational-equal">=</span>
                          <span className="operational-box-des">Turbine</span>
                        </div>
                        <div className="operational-box">
                          <span className="operational-equal">=</span>
                          <span className="operational-box-des">
                            Solar Panel
                          </span>
                        </div>
                        <div className="operational-box">
                          <span className="operational-equal">=</span>
                          <span className="operational-box-des">Battery</span>
                        </div>
                      </div>
                    </div>
                    <div className="operational operational-right">
                      <div className="demand">
                        <p className="supply-head">Demand Priority</p>
                        <div className="operational-box">
                          <span className="operational-equal">=</span>
                          <span className="operational-box-des">Industry</span>
                        </div>
                        <div className="operational-box">
                          <span className="operational-equal">=</span>
                          <span className="operational-box-des">
                            Green H2 Storage
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="financial" title="Financials">
                <div className="tab-main-container">
                  <div className="tab-main-wrapper">
                    <div className="financial-tab">
                      <Form>
                        <span className="supply-head">Time Steps</span>
                        <div className="checkbox-wrapper">
                          <Form.Check
                            type="radio"
                            id="default-checkbox"
                            label="Hourly"
                            name="hourly"
                          />
                          <Form.Check
                            type="radio"
                            id="default-checkbox1"
                            label="Daily"
                            name="hourly"
                          />
                        </div>
                        <div className="setting-form-content">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Discount Rate (%)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Corporate Carbon Tax</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Repurchase Rate (%) (Enter Terminal Value)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Number of Years</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Start Year</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="carbon" title="Carbon">
                <div className="tab-main-container">
                  <div className="tab-main-wrapper">
                    <div className="financial-tab carbon-tab">
                      <Form>
                        <div className="setting-form-content">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Discount Rate (%)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              autoComplete="false"
                            />
                            {/* <span className="error-msg">This field is required.</span> */}
                            {/* <span className="error-msg">
                              Invalid email address.
                            </span> */}
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey="contact" title="Contact" disabled>
          Tab content for Contact
        </Tab> */}
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-no-outline" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button className="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* share modal start here */}
      <Modal
        show={shareModal}
        onHide={() => setShareModal(false)}
        dialogClassName="modal-450 top-right-modal"
        className="forget-modal setting-modal share-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShareModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Invite people to Collaborate
          </h4>
          <p className="modal-title-des mb-25">
            on the model:{' '}
            <span className="title-des-bold">Plant Watering Automation</span>
          </p>
          <div className="tag-input">
            <Form className="tag-input-form">
              <Form.Group className="form-group" controlId="formBasicEmail">
                <span className="icon-search-logo"></span>
                <Form.Control type="text" placeholder="Email" />
                <Dropdown className="create-modal-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    Model Consumer
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as="button">Model Consumer</Dropdown.Item>
                    <Dropdown.Item as="button">Collaborator</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button className="btn-primary no-radius">Send</Button>
            </Form>
          </div>
          <div className="email-input-wrapper">
            <div className="tab-main-wrapper">
              <div className="tab-left-part">
                <div className="dropdown-wrapper shared-profile">
                  <span className="user-icon medium">A</span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">Andy Smith (You)</h3>
                    <p className="dropdown-des">Angelasmith@email.com</p>
                  </div>
                </div>
              </div>
              <div className="tab-right-part">
                <span className="disable-field">Owner</span>
              </div>
            </div>
            <div className="tab-main-wrapper">
              <div className="tab-left-part">
                <div className="dropdown-wrapper">
                  <span className="user-icon medium">A</span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">Art Bartillion</h3>
                    <p className="dropdown-des">Artbartillion038@email.com</p>
                  </div>
                </div>
              </div>
              <div className="tab-right-part">
                {[''].map((direction) => (
                  <Dropdown
                    className="create-modal-dropdown three-dot-dropdown"
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    variant="secondary"
                    align="end"
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      Model Consumer
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as="button">Model Consumer</Dropdown.Item>
                      <Dropdown.Item as="button">Collaborator</Dropdown.Item>
                      <Dropdown.Item as="button" className="text-danger">
                        Remove User
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
              </div>
            </div>
            <div className="tab-main-wrapper">
              <div className="tab-left-part">
                <div className="dropdown-wrapper">
                  <span className="user-icon medium">C</span>
                  <div className="right-sec">
                    <h3 className="dropdown-head">Cindy Wang</h3>
                    <p className="dropdown-des">Angelasmith@email.com</p>
                  </div>
                </div>
              </div>
              <div className="tab-right-part">
                {[''].map((direction) => (
                  <Dropdown
                    className="create-modal-dropdown three-dot-dropdown"
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    variant="secondary"
                    align="end"
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      Collaborator
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as="button">Model Consumer</Dropdown.Item>
                      <Dropdown.Item as="button">Collaborator</Dropdown.Item>
                      <Dropdown.Item as="button" className="text-danger">
                        Remove User
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Export CSV modal start here */}
      <Modal
        show={ShowExportCSVModal}
        onHide={() => setShowExportCSVModal(false)}
        dialogClassName="modal-503 top-right-modal"
        className="forget-modal setting-modal share-modal approval-modal export-csv-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowExportCSVModal(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Download  Simulation as CSV
          </h4>
          <div className='approval-req-wrap'>
            <Form>
              <div className="checkbox-wrapper checkbox-grey-wrap checkbox-trans-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      label="Graphs"
                      name="companycheckbox"
                    />

                  </div>
                  <div className="col-md-12">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox1"
                      label="Workbench Data"
                      name="companycheckbox2"
                    />

                  </div>
                  <div className="col-md-12">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox2"
                      label="Simulation Metrics"
                      name="companycheckbox3"
                    />

                  </div>
                  <div className="col-md-12">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox3"
                      label="Simulation Summary"
                      name="companycheckbox4"
                    />
                  </div>

                </div>

              </div>

            </Form>
          </div>
          <div className="simulate-btn-wrap modal-footer">
            <button
              type="button"
              className="btn-no-outline btn btn-primary">
              Cancel
            </button>
            <Button className="primary">Download</Button>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
}

export default HeaderWorkbenchHtml;
