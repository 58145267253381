import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import SearchAndFilter from '../shared/SearchAndFilter';
import RequestItem from './RequestItem';
import { useGetRequestsQuery } from '../../redux/services/latestActivitiesApi';
import { MESSAGES, NUMBER } from '../../constants';

interface IItem {
  projectId: string
  scenarioId: string
  scenarioName: string
  userName: string
  request_status: string
}

const RequestSection = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pendingRequest, setPendingRequest] = useState(NUMBER.N0);
  const { data, isFetching, error } = useGetRequestsQuery('', { refetchOnMountOrArgChange: true });
  const [filteredData, setFilteredData] = useState<IItem[]>([]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleCloseDropdown = () => {
    setSearchTerm('');
    const searchInput = document.getElementById('searchInput') as HTMLInputElement | null;
    if (searchInput) {
      searchInput.value = '';
    }
  };


  useEffect(() => {
    if (!isFetching) {
      setFilteredData(data);
      setPendingRequest(data?.[NUMBER.N0]?.pending_status_count);
    }
  }, [isFetching]);

  useEffect(() => {
    const filterData = data?.filter((item: IItem) => item?.scenarioName?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
    setFilteredData(filterData || []);
  }, [searchTerm]);

  if (isFetching) {
    return <></>;
  }

  return (
    <NavDropdown
      title={`${pendingRequest || 'All'} Requests`}
      id="collapsible-nav-dropdown"
      className={`request-dropdown icon-white-arrow ${pendingRequest && 'pending-request'}`}
      onToggle={handleCloseDropdown}
    >
      <div className="custom-dropdown">
        <h2>ALL REQUESTS</h2>
        {data?.length === NUMBER.N0 || error ? <div className='dropdown-wrapper-main'><div className='no-result'>{MESSAGES.NO_REQUEST_YET}</div></div> : <>
          <div className='search-wrap'>
            <SearchAndFilter
              placeHolder="Search"
              onSearch={handleSearch}
              className="scenario-list-search margin-b0"
            />
          </div>
          <div className='dropdown-wrapper-main'>
            {filteredData.length === 0 && <div className='no-result'>{MESSAGES.NO_RECORD_FOUND}</div>}
            {filteredData.map((item: IItem, index) => <RequestItem key={`${item.scenarioId}-${index}`} {...item} />)}
          </div>
        </>}
      </div>
    </NavDropdown>
  );
};

export default RequestSection;
