import React from 'react';
import InputElement from '../../shared/InputElement';
import Select from 'react-select';
import { hasFieldError } from '../../../utils';
import { NUMBER } from '../../../constants';
import { Form } from 'react-bootstrap';


const CommonFieldsCompany = ({
  values,
  handleBlur,
  handleChange,
  setFieldError,
  errors,
  touched,
  setIsPermissionChanged,
  setFieldValue,
  loadingProjectType,
  setFieldTouched,
  projectType,
  showObjectModal,
  disabled = false,
  isEdit = false
}: any) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <InputElement
          label="Company Name"
          placeholder="Enter Name"
          required={true}
          type="text"
          value={values?.name}
          name="name"
          data-testid="name"
          onChange={(event: any) => {
            handleChange(event);
            setIsPermissionChanged(true);
          }}
          resetError={setFieldError}
          onBlur={handleBlur}
          hasError={hasFieldError(errors, touched, 'name')}
          maxLength={NUMBER.N25}
        />
      </div>
      <div className="col-md-6">
        <InputElement
          label="Email Address"
          placeholder="Enter Email"
          required={true}
          type="text"
          value={values?.email}
          name="email"
          data-testid="email"
          onChange={(event: any) => {
            handleChange(event);
            setIsPermissionChanged(true);
          }}
          resetError={setFieldError}
          onBlur={handleBlur}
          hasError={hasFieldError(errors, touched, 'email')}
          disabled={disabled}
        />
      </div>
      <div className="col-md-6">
        <div className="custom-select-main">
          <Form.Label>
            Access to Project Type<sup>*</sup>
          </Form.Label>
          <Select
            className={`custom-select-wrp ${hasFieldError(errors, touched, 'accessToProjectType') ? 'error-field-select' : ''}`}
            classNamePrefix="select"
            value={isEdit ? values.accessToProjectType : values.accessToProjectType.value}
            name="accessToProjectType"
            options={projectType as any}
            placeholder="Select Project Type"
            // menuIsOpen={true}

            onChange={(selectedOptions) => {
              const selectedValues = isEdit ? selectedOptions.map((option: any) => option) : selectedOptions.map(option => option.value).join(', ');
              setFieldValue('accessToProjectType', selectedValues);
              setIsPermissionChanged(true);
            }}
            closeMenuOnSelect={false}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onBlur={async () => await setFieldTouched('accessToProjectType', true)}
            isMulti={true}
            isLoading={loadingProjectType}
            hideSelectedOptions={false}

          />
          {touched.accessToProjectType && errors.accessToProjectType && (
            <span className="error-msg">{errors.accessToProjectType}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className='object-modal-input'>
          <InputElement
            label="Access to Objects"
            placeholder="Access to Objects"
            required={true}
            type="text"
            value={`${values?.accessToObjects[0] || ''} ${values?.accessToObjects.length > 1 ? `, + ${values?.accessToObjects.length - 1} more` : ''}`}
            name="accessToObjects"
            data-testid="accessToObjects"
            onChange={(event: any) => {
              handleChange(event);
              setIsPermissionChanged(true);
            }}
            resetError={setFieldError}
            onBlur={handleBlur}
            hasError={hasFieldError(errors, touched, 'accessToObjects')}
            maxLength={NUMBER.N25}
            onClick={showObjectModal}
            className="access-object-modal"
            // disabled={disabled}
          />
        </div>
      </div>
      <div className="col-md-12">
        <InputElement
          label="Company Description"
          placeholder="Write Company Description Here"
          required={true}
          type="textarea"
          value={values?.description}
          name="description"
          data-testid="description"
          onChange={(event: any) => {
            handleChange(event);
            setIsPermissionChanged(true);
          }}
          resetError={setFieldError}
          onBlur={handleBlur}
          hasError={hasFieldError(errors, touched, 'description')}
          countRequired={false}
        />
      </div>
    </div>
  );
};

export default CommonFieldsCompany;
