/* eslint-disable complexity */
import React, { useRef, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { FINANCIAL_SETTINGS_SCHEMA, hasFieldError } from '../../utils';
import { GEO_THERMAL, G_THERM, IFinancialSettings, defaultGTherm, defaultGeoThermalValues } from '../../constants';
import { FormikProps, Formik } from 'formik';
import InputElement from '../shared/InputElement';

interface IFormikProps {
  handleForm: (values: any) => void
  formData: any
  fieldChange: any
  loading: boolean
  hideModal: () => void
}

const GthermInputs = [
  { label: 'License Fees ($)', value: 'LicenseFees' },
  { label: 'Permitting Costs ($)', value: 'PermittingCosts' },
  { label: 'Tax Credits ($/Year)', value: 'TaxCredits' },
  { label: 'Tax Credit 45Q ($/CO2)', value: 'TaxCredit45Q' },
  { label: 'Debt Interest Payment (%)', value: 'DebtInterestPayment' },
  { label: 'Director Expenses (%)', value: 'DirectorExpenses' },
  { label: 'Total COGS (Royalty Paid for the tech + Insurance + Maintenance) (%)', value: 'TotalCOGS' },
  { label: 'Direct Expenses (%)', value: 'DirectExpenses' }
];

const FinancialSettings = (props: IFormikProps) => {
  const { formData, handleForm, fieldChange, hideModal, loading } = props;
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioType = searchParams.get('type');
  const formikRef = useRef<FormikProps<IFinancialSettings> | null>(null);
  const [showWarningMsg, setWarningMsg] = useState<any>(false);

  const initialValues: IFinancialSettings = {
    discountRate: formData?.discountRate ?? defaultGeoThermalValues.discount_rate,
    timeSteps: formData?.timeSteps ?? '',
    ccTax: formData?.ccTax ?? '',
    terminalValue: formData?.terminalValue ?? '',
    noOfYears: formData?.noOfYears || defaultGeoThermalValues.noOfYears,
    startYear: formData?.startYear || '',
    subsidies: formData?.subsidies || '',
    economic_model: formData?.economic_model || defaultGeoThermalValues.economic_model,
    fixed_charge_rate: formData?.fixed_charge_rate || defaultGeoThermalValues.fixed_charge_rate,
    inflation_rate_during_construction: formData?.inflation_rate_during_construction || defaultGeoThermalValues.inflation_rate_during_construction,
    // for gtherm only
    LicenseFees: formData?.LicenseFees || defaultGTherm.LicenseFees,
    PermittingCosts: formData?.PermittingCosts || defaultGTherm.PermittingCosts,
    TaxCredits: formData?.TaxCredits || defaultGTherm.TaxCredits,
    TaxCredit45Q: formData?.TaxCredit45Q || defaultGTherm.TaxCredit45Q,
    DebtInterestPayment: formData?.DebtInterestPayment || defaultGTherm.DebtInterestPayment,
    DirectorExpenses: formData?.DirectorExpenses || defaultGTherm.DirectorExpenses,
    TotalCOGS: formData?.TotalCOGS || defaultGTherm.TotalCOGS,
    DirectExpenses: formData?.DirectExpenses || defaultGTherm.DirectExpenses
  };

  const submitForm = (values: any) => {
    handleForm(values);
    setWarningMsg(false);
  };

  return (
    <>
      <div className="tab-main-container">
        <div className="tab-main-wrapper">
          <div className="financial-tab carbon-tab">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={FINANCIAL_SETTINGS_SCHEMA}
              onSubmit={submitForm}
            >
              {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                <Form onSubmit={handleSubmit}>
                  <span className="supply-head">Time Steps</span>
                  <div className="checkbox-wrapper">
                    <Form.Check
                      type="radio"
                      id="default-checkbox"
                      label="Hourly"
                      name="hourly"
                      defaultChecked
                    />
                    <Form.Check
                      type="radio"
                      id="default-checkbox1"
                      label="Daily"
                      name="hourly"
                      disabled
                    />
                  </div>
                  <div className="setting-form-content object-form-wrap">
                    <div className="object-left-col row">
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formDiscountRate"
                        >
                          <InputElement
                            label='Discount Rate (%)'
                            type='text'
                            value={values?.discountRate}
                            name='discountRate'
                            data-testid="discountRate"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPressDown={props.fieldChange(values?.discountRate, 'discountRate')}
                            resetError={setFieldError}
                            hasError={hasFieldError(errors, touched, 'discountRate')}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formTerminalValue"
                        >
                          <InputElement
                            label='Repurchase Rate (%) (Enter Terminal Value)'
                            type='text'
                            value={values?.terminalValue}
                            name='terminalValue'
                            data-testid="terminalValue"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPressDown={fieldChange(values?.terminalValue, 'terminalValue')}
                            resetError={setFieldError}
                            hasError={hasFieldError(errors, touched, 'terminalValue')}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formStartYear"
                        >
                          <InputElement
                            label='Start Year'
                            type='text'
                            value={values?.startYear}
                            name='startYear'
                            data-testid="startYear"
                            required={true}
                            onChange={handleChange}
                            onBlur={() => setWarningMsg(true)}
                            onKeyPressDown={fieldChange(values?.startYear, 'startYear')}
                            resetError={setFieldError}
                            hasError={hasFieldError(errors, touched, 'startYear')}
                          />
                          {Number(values.startYear) < new Date().getFullYear() && values.startYear !== '' && showWarningMsg &&
                            <p className="location-des">
                              Warning: Start year is set before the current year. Update accordingly.
                            </p>}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formCarbonTax"
                        >
                          <InputElement
                            label='Corporate Carbon Tax'
                            type='text'
                            value={values?.ccTax}
                            name='ccTax'
                            data-testid="ccTax"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPressDown={fieldChange(values?.ccTax, 'ccTax')}
                            resetError={setFieldError}
                            hasError={hasFieldError(errors, touched, 'ccTax')}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formNoOfYears"
                        >
                          <InputElement
                            label='Number of Years'
                            type='text'
                            value={values?.noOfYears}
                            name='noOfYears'
                            data-testid="noOfYears"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPressDown={fieldChange(values?.noOfYears, 'noOfYears')}
                            resetError={setFieldError}
                            hasError={hasFieldError(errors, touched, 'noOfYears')}
                          />
                        </Form.Group>
                      </div>
                      {scenarioType === GEO_THERMAL && <>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="economic_model"
                          >
                            <InputElement
                              label='Economic Model'
                              type='number'
                              value={values?.economic_model}
                              name='economic_model'
                              data-testid="economic_model"
                              description='Specify the economic model to calculate the levelized cost of energy. 1: Fixed Charge Rate Model,
                              2: Standard Levelized Cost Model, 3: BICYCLE Levelized Cost Model, 4: CLGS'
                              required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPressDown={fieldChange(values?.economic_model, 'economic_model')}
                              resetError={setFieldError}
                              hasError={hasFieldError(errors, touched, 'economic_model')}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="fixed_charge_rate"
                          >
                            <InputElement
                              label='Fixed Charge Rate'
                              type='number'
                              value={values?.fixed_charge_rate}
                              name='fixed_charge_rate'
                              data-testid="fixed_charge_rate"
                              description='Fixed charge rate (FCR) used in the Fixed Charge Rate Model'
                              required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPressDown={fieldChange(values?.fixed_charge_rate, 'fixed_charge_rate')}
                              resetError={setFieldError}
                              hasError={hasFieldError(errors, touched, 'fixed_charge_rate')}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlId="inflation_rate_during_construction"
                          >
                            <InputElement
                              label='Inflation Rate During Construction'
                              type='number'
                              value={values?.inflation_rate_during_construction}
                              name='inflation_rate_during_construction'
                              data-testid="inflation_rate_during_construction"
                              description='Inflation Rate During Construction'
                              required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPressDown={fieldChange(values?.inflation_rate_during_construction, 'inflation_rate_during_construction')}
                              resetError={setFieldError}
                              hasError={hasFieldError(errors, touched, 'inflation_rate_during_construction')}
                            />
                          </Form.Group>
                        </div>
                      </>}
                      {
                        scenarioType === G_THERM && <>
                          {GthermInputs.map((item, index) => {
                            return (
                              <div className="col-md-6" key={index}>
                                <Form.Group
                                  controlId="formNoOfYears"
                                >
                                  <InputElement
                                    label={item.label}
                                    type='text'
                                    value={values?.[item.value]}
                                    name={item.value}
                                    data-testid={item.value}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyPressDown={fieldChange(values?.[item.value], item.value)}
                                    resetError={setFieldError}
                                    hasError={hasFieldError(errors, touched, item.value)}
                                  />
                                </Form.Group>
                              </div>
                            );
                          })}
                        </>
                      }
                    </div>
                  </div>
                  <div className="setting-btn-wrap">
                    <Button className="btn-no-outline" onClick={hideModal}>
                      Cancel
                    </Button>
                    <Button className="primary" type="submit">
                      {loading ? <Spinner /> : 'Save Changes'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialSettings;
