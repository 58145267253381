import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown, Dropdown } from 'react-bootstrap';
import {
  headerDarkLogo,
  headerLogo,
  userIcon,
  versionLogo
} from '../../utils/icons';
import { Link } from 'react-router-dom';
import SearchAndFilter from '../../components/shared/SearchAndFilter';

function Header() {
  const handleSearch = (val: string) => {
  };

  const [open, setOpen] = React.useState(false);

  const handleShow = () => setOpen(!open);

  const openProjectProps = {
    show: open,
    handleShow
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary workbench-header"
      >
        <Container>
          <Navbar.Brand href="#home">
            <img src={headerLogo} alt="header logo" className="header-logo" />
            <img
              src={headerDarkLogo}
              alt="header logo"
              className="header-dark-logo d-done"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#features" className="active">
                dashboard
              </Nav.Link>
              <Nav.Link href="#pricing">all projects</Nav.Link>
              <Nav.Link href="#pricing">compare models</Nav.Link>
            </Nav>
            <Nav className="nav-dropdown-wrapper">
              <NavDropdown
                title="2 Requests"
                id="collapsible-nav-dropdown"
                className="request-dropdown icon-white-arrow"
              >
                <div className="custom-dropdown">
                  <h2>ALL REQUESTS</h2>
                  <div className='search-wrap'>
                    <SearchAndFilter
                      placeHolder="Search"
                      onSearch={handleSearch}
                      className="scenario-list-search"
                    />
                  </div>
                  <div className='dropdown-wrapper-main'>
                    <div className="dropdown-wrapper">
                      <span className="user-icon">R</span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">Richeu Smith</h3>
                        <p className="dropdown-des">
                          has requested to approve a scenario, <span className='dropdown-des-bold'>Green Hydrogen Base-case</span>
                        </p>
                        <div className="dropdown-footer">
                          <Button className="btn-outline small">See Simulation</Button>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-wrapper">
                      <span className="user-icon">j</span>
                      <div className="right-sec">
                        <h3 className="dropdown-head">Jay Summers</h3>
                        <p className="dropdown-des">
                          has requested to approve a scenario, <span className='dropdown-des-bold'>Base-load Clean Power</span>
                        </p>
                        <div className="dropdown-footer">
                          <Button className="btn-outline small">See Simulation</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavDropdown>
              <Dropdown className="profile-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  <span className="user-icon">
                    <img className="user-img" src={userIcon} alt="user" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-wrapper">
                    <span className="user-icon">A</span>
                    <div className="right-sec">
                      <h3 className="dropdown-head">Andy Smith</h3>
                      <span className="dropdown-email">
                        andysmith038@email.com
                      </span>
                      <div className="dropdown-footer">
                        <Button className="btn-outline small">
                          Edit Profile
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="account-setting">
                    <Link to="#" className="account-item">
                      Onboard New User
                    </Link>
                    <Link to="#" className="account-item">
                      Model Requests
                    </Link>
                    <Link to="#" className="account-item">
                      Object Requests
                    </Link>
                    <Link to="#" className="account-item">
                      Change Password
                    </Link>
                    <Link to="#" className="account-item sign-out">
                      Sign Out
                    </Link>
                  </div>
                  <div className="version-sec">
                    <img src={versionLogo} alt="logo" />
                    <span className="version-text">Version v2.0.3</span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="create-modal-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  + Create New
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.ItemText>Options</Dropdown.ItemText>
                  <Dropdown.Item as="button" onClick={handleShow}>Create new Project</Dropdown.Item>
                  <Dropdown.Item as="button">Create new Object</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
