import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { IGraphList } from '../../../constants';

export interface IRadioButtons {
  label: string
  value: string
}

interface Props {
  radioOptions: IRadioButtons[]
  graphList?: IGraphList[]
  selectedRadioOption: string
  setSelectedRadioOption: React.Dispatch<React.SetStateAction<string>>
}

const GraphRadioButtons: FC<Props> = ({
  radioOptions,
  graphList,
  selectedRadioOption,
  setSelectedRadioOption
}) => {
  return (
    <div className="chart-radio-wrapper">
      <Form>
        <div className="checkbox-wrapper">
          {radioOptions.map((option, index) => {
            // if graph keys are not there then do not show radio button for this...
            if (graphList && !Object.keys(graphList[index]?.graphKeys)?.length) {
              return <span key={`${option.value}-${index}`}></span>;
            }
            return (
              <Form.Check
                key={`${option.value}-${index}`}
                type="radio"
                id={option.value}
                label={option.label}
                value={option.value}
                name="simulationOverview"
                onChange={(event) =>
                  setSelectedRadioOption(event.currentTarget.value)
                }
                checked={selectedRadioOption === option.value}
              />
            );
          }
          )}
        </div>
      </Form>
    </div>
  );
};

export default GraphRadioButtons;
