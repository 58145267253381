
import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap';
import { hydrogenLogoSmall, infoInputLogo, shortingLogo, threeDotHorizontal } from '../../utils';

import Select from 'react-select';
import DsiderPagination from './DsiderPagination';





const OnboardUser = () => {
  const [ShowAddUser, setShowAddUser] = useState(false);

  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'super- user', label: 'Super- User' },
    { value: 'consumer', label: 'Consumer' },
    { value: 'collaborator', label: 'Collaborator' },

  ];

  const selectCompany = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'nexaSynergy', label: 'NexaSynergy' },
    { value: 'quantaQuest', label: 'QuantaQuest' },
    { value: 'eternaTec solutions', label: 'EternaTec Solutions' },
    { value: 'zenithCraft innovations', label: 'ZenithCraft Innovations' },
    { value: 'StellarBloom Creations', label: 'StellarBloom Creations' },
    { value: 'VivaVista Enterprises', label: 'VivaVista Enterprises' },

  ];


  return (
    <>
      <div className="change-pw-modal create-modal wizard-ques-section onboard-user-section">
        <button className='close-btn'>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className='modal-dialog full-width-dialog'>
          <div className='modal-content'>
            <Modal.Body>
              <Modal.Title></Modal.Title>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className='admin-section'>
                    <div className='admin-body'>
                      <div className='admin-body-header'>
                        <div className='onboard-title-wrap'>
                          <h2 className='admin-section-title'>Onboard User</h2>
                          <p className="modal-title-des">
                            Onboard the organization users to Dsider platform.
                          </p>
                        </div>
                        <div className='add-user-wrap'>
                          <Button onClick={() => setShowAddUser(true)}>+ New User</Button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {/* table */}
                        <div className='table-wrapper'>
                          <div className="table-container contest-table admin-table-container onboard-user-table">
                            <div className='table-inner-wrap'>
                              <div className="table-head">
                                <div className="table-row">
                                  <div className="th">name
                                    <Button className='btn-no-outline shorting-btn'><img src={shortingLogo} alt="shorting logo" className='shorting-logo' /></Button>
                                  </div>
                                  <div className="th">email</div>
                                  <div className="th">user role</div>
                                  <div className="th">status
                                    <Button className='btn-no-outline shorting-btn'><img src={shortingLogo} alt="shorting logo" className='shorting-logo' /></Button>
                                  </div>
                                  <div className="th">action</div>
                                </div>
                              </div>
                              <div className="table-body">
                                <div className="table-row active">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          a
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Amy McGrawl
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="amymcgrawl038@email.com"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Collaborator"><span className='td-text'>Collaborator</span></div>
                                  <div className="td" data-label="active"><span className='td-text status active'>Active </span></div>
                                  <div className="td" data-label="dropdown">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row inactive">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          c
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Consumer</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status inactive'>Inactive </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          c
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Super User</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status pending'>Pending </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          <img
                                            src={hydrogenLogoSmall}
                                            alt="logo"
                                            className="user-img"
                                          />

                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Consumer</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          c
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Consumer</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          c
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Collaborator</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                                <div className="table-row">
                                  <div className="td" data-label="Model">
                                    <div className="tab-left-part">
                                      <div className="dropdown-wrapper shared-profile">
                                        <span className="user-icon">
                                          {/* <img
                              src={hydrogenLogoSmall}
                              alt="logo"
                              className="user-img"
                            /> */}
                                          c
                                        </span>
                                        <div className="right-sec">
                                          <h3 className="dropdown-head">
                                            Charlotte  Jones
                                          </h3>
                                          <p className="dropdown-des mediam">
                                            Joined on May 7, 2023
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="td" data-label="Capex"><span className='td-text'>amymcgrawl038@email.com</span></div>
                                  <div className="td" data-label="Period"><span className='td-text'>Collaborator</span></div>
                                  <div className="td" data-label="Carbon Tax"><span className='td-text status active'>Active </span></div>
                                  <div className="td" data-label="LCOE">
                                    <div className="tab-right-part">
                                      {[''].map((direction) => (
                                        <Dropdown
                                          className="create-modal-dropdown three-dot-dropdown"
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          variant="secondary"
                                          align="end"
                                        >
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <img
                                              src={threeDotHorizontal}
                                              alt="three dot icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.ItemText>
                                              Options
                                            </Dropdown.ItemText>
                                            <Dropdown.Item as="button">
                                              See Details
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button">
                                              Manage User Role
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Deactivate User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="text-danger"
                                            >
                                              Delete User
                                            </Dropdown.Item>

                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ))}
                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>
                            <DsiderPagination />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>

      {/* Add new user modal start here */}
      <Modal
        show={ShowAddUser}
        onHide={() => setShowAddUser(false)}
        dialogClassName="modal-701 top-right-modal add-user-modal modal-dialog-centered"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowAddUser(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium">Add New User</h4>
          <p className='modal-head-des'>Fill in the details of the user you
        want to add to your organisation. A request will be sent to
        the added user. The user will be onboarded when they accept the invitation & confirm their profile.</p>
          <Form className="object-parameter-form add-uder-form">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="latitude">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="number"
                    // value={locationLat}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="longitude">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    // value={locationLng}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="longitude">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="custom-select-main mb-0">
                  <Form.Label>Name</Form.Label>
                  <Select
                    className="custom-select-wrp"
                    classNamePrefix="select"
                    defaultValue={colourOptions[1]}
                    // isDisabled={isDisabled}
                    isLoading={isLoading}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={colourOptions}
                    placeholder="User Role"
                  // menuIsOpen={true}
                  />
                  <div className="tooltip-container">
                    <Button className='btn-no-outline info-btn'><img src={infoInputLogo} alt="log" /></Button>
                    <div className="tooltip-text">See Permission</div>
                  </div>

                </div>
              </div>
              <div className="col-md-12">
                <div className="custom-select-main">
                  <Form.Label>Select Company</Form.Label>
                  <Select
                    className="custom-select-wrp"
                    classNamePrefix="select"
                    defaultValue={selectCompany[1]}
                    // isDisabled={isDisabled}
                    isLoading={isLoading}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={selectCompany}
                    placeholder="User Role"
                  // menuIsOpen={true}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Button className='send-invite-btn'>Send Invite</Button>
              </div>

            </div>
          </Form>


        </Modal.Body>
      </Modal>


    </>
  );
};

export default OnboardUser;
