import React from 'react';
import { Form } from 'react-bootstrap';

function DefaultCheckBox() {
  return (
    <>
      <Form.Check
        type="radio"
        id="default-checkbox"
        label="Power"
        name="radio"
        className='radio-check-grey'
      />
    </>

  );
}
export default DefaultCheckBox;
