import { useCallback, useEffect, useState } from 'react';
import { Edge, Node, useReactFlow } from 'reactflow';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateEdges, updateNodes } from '../redux/workbenchSlice';
import { NUMBER } from '../constants';

interface UseUndoRedoOptions {
  maxHistorySize: number
  enableShortcuts: boolean
};

type UseUndoRedo = (options?: UseUndoRedoOptions) => {
  undo: () => void
  redo: () => void
  takeSnapshot: () => void
  canUndo: boolean
  canRedo: boolean
};

interface HistoryItem {
  nodes: Node[]
  edges: Edge[]
  currentLayer: string
};

const defaultOptions: UseUndoRedoOptions = {
  maxHistorySize: NUMBER.N100,
  enableShortcuts: true
};

// https://redux.js.org/usage/implementing-undo-history
export const useUndoRedo: UseUndoRedo = ({
  maxHistorySize = defaultOptions.maxHistorySize,
  enableShortcuts = defaultOptions.enableShortcuts
} = defaultOptions) => {
  // the past and future arrays store the states that we can jump to
  const [past, setPast] = useState<HistoryItem[]>([]);
  const [future, setFuture] = useState<HistoryItem[]>([]);
  const [canRedo, setCanRedo] = useState(true);
  const [canUndo, setCanUndo] = useState(true);
  const { currentLayerID } = useAppSelector(state => state.layersData);

  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow();
  const dispatch = useAppDispatch();

  const takeSnapshot = useCallback(() => {
    // push the current graph to the past state
    setPast((past) => [
      ...past.slice(past.length - maxHistorySize + NUMBER.N1, past.length),
      { nodes: getNodes(), edges: getEdges(), currentLayer: currentLayerID }
    ]);

    // whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
    setFuture([]);
  }, [getNodes, getEdges, maxHistorySize, currentLayerID]);

  const undo = useCallback(() => {
    // get the last state that we want to go back to
    const pastState = past[past.length - NUMBER.N1];

    if (pastState) {
      // first we remove the state from the history
      setPast((p) => p.slice(0, p.length - NUMBER.N1));
      // we store the current graph for the redo operation
      setFuture((f) => [...f, { nodes: getNodes(), edges: getEdges(), currentLayer: currentLayerID }]);
      // now we can set the graph to the past state
      setUndoRedoNodes(pastState.nodes);
      setUndoRedoEdges(pastState.edges);
      dispatch(updateNodes(pastState.nodes));
      dispatch(updateEdges(pastState.edges));
    }
  }, [setNodes, setEdges, getNodes, getEdges, past, currentLayerID]);

  const redo = useCallback(() => {
    const futureState = future[future.length - NUMBER.N1];

    if (futureState) {
      setFuture((f) => f.slice(0, f.length - NUMBER.N1));
      setPast((p) => [...p, { nodes: getNodes(), edges: getEdges(), currentLayer: currentLayerID }]);
      setUndoRedoNodes(futureState.nodes);
      setUndoRedoEdges(futureState.edges);
      dispatch(updateNodes(futureState.nodes));
      dispatch(updateEdges(futureState.edges));
    }
  }, [setNodes, setEdges, getNodes, getEdges, future, currentLayerID]);

  const setUndoRedoEdges = (Edges: Edge[]) => {
    setEdges((Edges.map((e) => {
      if (e.data?.parentNode === currentLayerID || (!e.data?.parentNode && currentLayerID === '')) {
        return { ...e, hidden: false };
      } else {
        return { ...e, hidden: true };
      }
    })));
  };

  const setUndoRedoNodes = (Nodes: Node[]) => {
    setNodes((Nodes.map((n) => {
      if (n.data.parentNode === currentLayerID || (!n.data.parentNode && currentLayerID === '')) {
        return { ...n, hidden: false };
      } else {
        return { ...n, hidden: true };
      }
    })));
  };

  useEffect(() => {
    setCanUndo(past.length ? past[past.length - NUMBER.N1].currentLayer === currentLayerID : false);
    setCanRedo(future.length ? future[future.length - NUMBER.N1].currentLayer === currentLayerID : false);
  }, [past, future, currentLayerID]);

  useEffect(() => {
    // this effect is used to attach the global event handlers
    if (!enableShortcuts) {
      return;
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        redo();
      } else if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        undo();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [undo, redo, enableShortcuts]);

  return {
    undo,
    redo,
    takeSnapshot,
    canUndo,
    canRedo
  };
};

export default useUndoRedo;
