import React from 'react';

interface INoData {
  message: string
  image?: string
  className?: string
}

function NoData(props: INoData) {
  const { message, image, className } = props;
  return (
    <>
      <section className='no-data-section'>
        <div className={`no-data-wrapper ${className ?? ''}`}>
        {image && <img src={image} alt="no data logo" />}
        <span>{message}</span>
        </div>
      </section>
    </>
  );
}

export default NoData;
