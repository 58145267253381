import React from 'react';
import { Form } from 'react-bootstrap';
import { formatNumberWithCommas, keyPressHandle, locationLogo, parseFormattedNumber } from '../../../utils';
import InputElement from '../../shared/InputElement';
import Select from 'react-select';
import { useAppDispatch } from '../../../redux/hooks';
import { updateSingleParameter } from '../../../redux/slices/adminSlice';
import BottonDiv from './BottonDiv';
import { toast } from 'react-toastify';
import { MESSAGES, NUMBER } from '../../../constants';
import { useDeleteObjectParameterMutation } from '../../../redux/services/admin/objectManagementApis';

interface Property {
  _id: string
  id: string
  propertyName: string
  propertyType: string
  unit: string | null
  propertyValue: string | number
  formulaTitle: string
  propertyLabel: string
  newParameter?: boolean
}

interface RenderFormFieldsProps {
  property: Property
  handleChange: any
  setFieldError: any
  handleBlur: any
  errors: any
  touched: any
  setFieldValue: any
  values: any
  setShowGoogleMaps: any
  handleEditParameter: any
  focusedProperty: any
  setFocusedProperty: any
  isEditObject: any
}

const RenderFormFields = ({
  property,
  handleChange,
  setFieldError,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  values,
  setShowGoogleMaps,
  handleEditParameter,
  focusedProperty,
  setFocusedProperty,
  isEditObject
}: RenderFormFieldsProps) => {
  const dispatch = useAppDispatch();
  const [deleteObjectParameter] = useDeleteObjectParameterMutation();
  const updatePropertyValue = (propertyName: any, newValue: any) => {
    const updatedProperties = values.properties.map((property: any) =>
      property.propertyName === propertyName
        ? { ...property, propertyValue: newValue }
        : property
    );
    setFieldValue('properties', updatedProperties);
  };

  const editParameter = () => {
    handleEditParameter(property);
  };

  const removeNewProperties = () => {
    const newPropperty = values.properties.filter((data: any) => data.propertyName !== property.propertyName);
    setFieldValue('properties', newPropperty);
    isEditObject && deleteObjectParameter(property.id);
    dispatch(updateSingleParameter({}));
    toast.success(MESSAGES.PARAMETER_DELETED_SUCCESS);
  };

  const value = values.properties.find((data: any) => data.propertyName === property.propertyName).propertyValue;
  const defaultValue: any = [{ value: property.unit, label: property.unit, isDisabled: true }];

  return (
    <>
      {property.propertyType === 'location'
        ? <Form.Group className="form-group location-form-group" controlId="latitude">
          {focusedProperty === property.propertyName
            ? <BottonDiv handleEditParameter={editParameter} removeNewProperties={removeNewProperties} />
            : null}
          <Form.Label className="lable-width-250">{property.propertyLabel}</Form.Label>
          <Form.Control
            type="text"
            autoComplete='off'
            value={value}
            className="cursor-pointer"
            onFocus={() => setFocusedProperty(property.propertyName)}
            // readOnly
          />
          <span
            className="input-logo location-img"
            onClick={() => setShowGoogleMaps(property.propertyName)}>
            <img src={locationLogo} alt="logo img" className='location-img' /></span>
        </Form.Group>
        : <Form.Group controlId={property.id} className="form-group">
          {focusedProperty === property.propertyName
            ? <BottonDiv handleEditParameter={editParameter} removeNewProperties={removeNewProperties} />
            : null}
          <div className="form-Control-wrapper">
            <InputElement
              key={property.propertyName}
              label={property.propertyLabel}
              placeholder={`Enter ${property.propertyLabel}`}
              type={'text'}
              value={formatNumberWithCommas(value)}
              name={property.propertyName}
              data-testid={property.propertyName}
              onFocus={() => setFocusedProperty(property.propertyName)}
              onChange={event => {
                const rawValue = parseFormattedNumber(event.target.value);
                handleChange(event);
                updatePropertyValue(property.propertyName, rawValue);
              }}
              onBlur={handleBlur}
              onKeyPressDown={keyPressHandle}
            />
            {defaultValue ? (
              <div className="custom-select-main capital-cost-select" title={defaultValue}>
                <div>
                </div>
                <Select
                  className="custom-select-wrp"
                  classNamePrefix="select"
                  defaultValue={defaultValue}
                  isDisabled={true}
                  isLoading={false}
                  name='select'
                  value={defaultValue[NUMBER.N0]}
                  options={defaultValue}
                />
              </div>
            ) : null}
          </div>
        </Form.Group>}
    </>
  );
};

export default RenderFormFields;
