import React, { useEffect, useRef, useState } from 'react';
import {
  Tab,
  Tabs
} from 'react-bootstrap';
import {
  projectBox1,
  projectBox2,
  projectBox3,
  projectBox4,
  projectBox5
} from '../../utils';
import Header from '../../components/layout/Header';
import { useGetAllProjectsQuery } from '../../redux/services/projectApis';
import ProjectCard from '../../components/dashboard/ProjectCard';
import { EV_PROJECT_FILTER_OPTION, IProjectDetails, ISharedUserList, MESSAGES, NUMBER, PROJECT_FILTER_OPTIONS } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { stopFullLoading } from '../../redux/SpinnerSlice';
import SearchAndFilter from '../../components/shared/SearchAndFilter';
import NoData from '../../components/shared/NoData';
import { LABELS } from '../../constants/labelConstant';
import ObjectListCard from '../../components/dashboard/ObjectListCard';
import { useGetSharedScenarioQuery } from '../../redux/services/scenarioApis';
import SharedScenarioListCard from '../../components/dashboard/sharedScenarioListCard';
import { useGetObjectListQuery } from '../../redux/services/workbenchApis';
import BaseLineCard from '../../components/dashboard/BaseLineCard';

type ImageType = Record<string, string>;

interface IObjectList {
  component: string
  componentDisplayName: string
  imageS3urlDark: string
  category: string[]
}

const CardImages: ImageType = {
  'img-0': projectBox1,
  'img-1': projectBox2,
  'img-2': projectBox3,
  'img-3': projectBox4,
  'img-4': projectBox5
};

// eslint-disable-next-line complexity
const AllProjectList = () => {
  const { data, isFetching, isLoading, refetch } = useGetAllProjectsQuery();
  const [projects, setProjects] = useState<IProjectDetails[] | undefined>(data);
  const [objects, setObjects] = useState<IObjectList[]>([]);
  const [isScrolled, setIsScrolled] = useState<boolean | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.auth);
  const projectDivRef = useRef<HTMLDivElement>(null);
  const { data: sharedData, isSuccess: successShared, isLoading: sharedLoading, refetch: refetchSharedData } = useGetSharedScenarioQuery();
  const [scenarios, setScenarios] = useState<ISharedUserList[] | undefined>(sharedData);
  const [selectedTab, setSelectedTab] = useState<string>('projects');
  const { data: objectListRes, isSuccess: isObjectListSuccess } = useGetObjectListQuery();
  useEffect(() => {
    if (!isFetching) {
      setProjects(data);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isObjectListSuccess) {
      const categoryList = objectListRes.data.map((r) => {
        return { value: r.component, label: r.componentDisplayName, image: r.imageS3urlDark };
      });
      const uniqeComponentArr = categoryList.filter((item, index) => index === categoryList.findIndex((o) => item.value === o.value));
      const objectDataArray: IObjectList[] = [];
      uniqeComponentArr.forEach((comp) => {
        const tempArray: string[] = [];
        objectListRes.data.forEach((item) => {
          if (comp.value === item.component) {
            tempArray.push(item.category);
          }
        });
        objectDataArray.push({
          component: comp.value,
          componentDisplayName: comp.label,
          imageS3urlDark: comp.image,
          category: tempArray
        });
      });
      setObjects(objectDataArray);
    }
  }, [isObjectListSuccess]);

  useEffect(() => {
    if (successShared) {
      setScenarios(sharedData);
    }
  }, [successShared, sharedData]);

  const refreshProjectData = (id: string | undefined) => {
    const filterProject = projects?.filter((d: IProjectDetails) => d?._id !== id);
    setProjects(filterProject);
    dispatch(stopFullLoading());
  };

  const handleOnSearch = (val: string) => {
    setSearchTerm(val);
  };

  const handleFilter = (seletedValue: string) => {
    const val = seletedValue.toLocaleLowerCase();
    setFilterTerm(val);
  };

  const getUserProjectFilterOption = () => {
    return userData.isBpUser ? EV_PROJECT_FILTER_OPTION : PROJECT_FILTER_OPTIONS.concat(EV_PROJECT_FILTER_OPTION);
  };

  const filterOptions = selectedTab === 'projects' ? getUserProjectFilterOption() : objectListRes?.categoryList;

  const filteredData = projects?.filter((item) =>
    item.project_type?.toLocaleLowerCase()?.includes(filterTerm) && item.name.toLowerCase()?.includes(searchTerm)
  );

  const sharedFilteredData = scenarios?.filter((item: ISharedUserList) =>
    item.subProjectName.toLowerCase()?.includes(searchTerm)
  );

  const objectFilteredData = objects?.filter((item) =>
    item.component.toLowerCase()?.includes(searchTerm)
  );

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > NUMBER.N50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(null);
      }
    };

    if (projectDivRef.current) {
      projectDivRef.current.addEventListener('scroll', handleScroll);
    }
    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      if (projectDivRef.current) {
        projectDivRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const refresh = () => {
    setSelectedTab('projects');
    refetch().then(() => {
      dispatch(stopFullLoading());
    });
  };

  return (
    <>
      <div className="all-models-section all-project-section" ref={projectDivRef}>
        <Header isScrolled={isScrolled} isAllProject={true} />
        <div className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="section-title">All Projects & Objects</h1>
                <p className="section-title-des">
                  Explore a comprehensive list of all projects & objects to date.
                </p>
                <SearchAndFilter
                  placeHolder={LABELS.PROJECT_SEARCH_PLACEHOLDER}
                  onSearch={handleOnSearch}
                  onFilter={handleFilter}
                  showFilter={selectedTab === 'projects'}
                  filterOption={filterOptions}
                  searchTerm=""
                  className="project-list-search"
                />
                <div className="dsider-tab-wrapper">
                  <div className="container">
                    <div className="dsider-tab-inner">
                      <Tabs
                        defaultActiveKey="projects"
                        id="uncontrolled-tab-example"
                        onSelect={(k: any) => setSelectedTab(k)}
                        className=""
                        activeKey={selectedTab}
                      >
                        <Tab eventKey="projects" title="Projects">
                          <div className="project-card-wrapper">
                            <div className="row project-card-row">
                              {
                                filteredData?.map((project: IProjectDetails, index) => {
                                  return (
                                    <ProjectCard key={project._id}
                                      cardImage={CardImages[`img-${index % NUMBER.N5}`]}
                                      data={project}
                                      refreshData={refreshProjectData}
                                    />
                                  );
                                })
                              }
                              {
                                ((searchTerm || filterTerm) && !filteredData?.length && !isLoading) && (
                                  <NoData
                                    message={MESSAGES.NO_RECORD_FOUND}
                                    image=""
                                  />
                                )
                              }
                              {
                                (!searchTerm && !filterTerm && !filteredData?.length && !isLoading) && (
                                  < NoData
                                    message={MESSAGES.NO_PROJECT_FOUND}
                                    image=""
                                  />
                                )
                              }
                            </div>
                          </div>
                        </Tab>
                        {(userData.user_role === 'admin' && !userData.isBpUser) && <Tab eventKey="objects" title="Objects">
                          <div className="tab-main-container">
                            <span className="tab-sec-thead">
                              All Objects ({objectListRes?.data?.length})
                            </span>
                            {objectFilteredData?.map((item) => (
                              <ObjectListCard key={item.componentDisplayName} data={item} />
                            ))}
                          </div>
                        </Tab>}
                        <Tab eventKey="sharedWithMe" title="Shared with me">
                          <div className="project-card-wrapper">
                            <span className="tab-sec-thead">
                              Shared with me ({sharedFilteredData?.length})
                            </span>
                            <div className="row">
                              {
                                sharedFilteredData?.filter((item: ISharedUserList) =>
                                  item.subProjectName.toLowerCase()?.includes(searchTerm)
                                ).map((scenario: ISharedUserList) => {
                                  return (
                                    <SharedScenarioListCard key={scenario._id}
                                      data={scenario}
                                      refreshData={refresh}
                                      reFreshSharedData={refetchSharedData}
                                    />
                                  );
                                })
                              }
                              {
                                (!sharedFilteredData?.length && searchTerm) ? <NoData message={MESSAGES.NO_RECORD_FOUND} /> : ''
                              }
                              {
                                (!searchTerm && !sharedFilteredData?.length && !sharedLoading) && (
                                  < NoData
                                    message={MESSAGES.NO_SCENARIO_FOUND}
                                    image=""
                                  />
                                )
                              }
                            </div>
                          </div>
                        </Tab>

                        <Tab eventKey="baselineScenarios" title="Baseline Scenarios">
                          <BaseLineCard searchTerm={searchTerm} />
                        </Tab>

                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectList;
