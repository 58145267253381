import React, { createContext, useContext, useState } from 'react';
import { NUMBER } from '../constants';

const initialValue = {
  isScenarioChangeAttempt: NUMBER.N0,
  clearBoard: () => { }
};

const WorkbenchContext = createContext(initialValue);

export function WorkBenchProvider({ children }: any) {
  const [isScenarioChangeAttempt, setIsScenarioChangeAttempt] = useState(NUMBER.N0);

  const clearBoard = () => {
    setIsScenarioChangeAttempt(isScenarioChangeAttempt + NUMBER.N1);
  };

  return (
    <WorkbenchContext.Provider value={{ isScenarioChangeAttempt, clearBoard }}>
      {children}
    </WorkbenchContext.Provider>
  );
}

export function useWorkbench() {
  return useContext(WorkbenchContext);
}
