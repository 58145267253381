import React, { useState } from 'react';
import { MAX_LENGTH } from '../constants/static';
import { toast } from 'react-toastify';
import { MESSAGES } from '../constants/validationMessages';

interface IImageFile {
  imageType?: any
}

// Custom hook for image selection, preview, and AWS S3 image upload
export const useImageUpload = (imageType: IImageFile) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | string | null>(null);
  const supportedImageFormat = ['image/png', 'image/jpeg', 'image/jpg'];
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (!supportedImageFormat?.includes(file.type)) {
      toast.error(MESSAGES.IMAGE_FORMAT);
      return;
      // @ts-expect-error is not assignable
    } else if (imageType === 'company' && file?.size > MAX_LENGTH.company_logo_size) {
      toast.error(MESSAGES.COMPANY_LOGO_SIZE);
      return;
    } else if (file?.size > MAX_LENGTH.image_size) {
      toast.error(MESSAGES.IMAGE_SIZE);
      return;
    }

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetImage = () => {
    setSelectedImage(null);
    setSelectedFile(null);
  };

  const onExistingImage = (image: string) => {
    setSelectedImage(image);
    setSelectedFile(image);
  };

  return {
    selectedImage,
    selectedFile,
    handleImageChange,
    resetImage,
    onExistingImage
  };
};
