import React from 'react';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MESSAGES } from '../../constants';
import { updateWarningAlert } from '../../redux/auth/authReducer';

const ShowAlertLogout = () => {
  const loggenInUserData = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { warningAlert } = loggenInUserData;

  const resetFunction = () => {
    dispatch(updateWarningAlert({ warningAlert: false }));
    localStorage.setItem('lastActivityTimestamp', new Date().getTime().toString());
  };

  return (
    <>
      {warningAlert && (
        <ConfirmationAlert
          showAlert={warningAlert}
          message={MESSAGES.PRE_LOGOUT_MSG}
          title="Auto Logout Warning"
          yesBtnText="Yes"
          cancleBtnText="No"
          onConfirm={resetFunction}
          onCancel={resetFunction}
          actionButton={false}
        />
      )}
    </>
  );
};

export default ShowAlertLogout;
