import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryList: [],
  list: []
};

const objectListSlice = createSlice({
  name: 'objectList',
  initialState,
  reducers: {
    updateObjectList: (state, payload) => {
      return { ...state, ...payload.payload };
    },
    updateList: (state, payload) => {
      return { ...state, list: payload.payload };
    }
  }
});

export const { updateObjectList, updateList } = objectListSlice.actions;

export default objectListSlice.reducer;
