import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions, MESSAGES, USER_ROLE } from '../../constants';
import { IChangePasswordCognitoObject, IEditProfile, IForgotPassword, IResetPassword, ISignIn, ISignUp, IVerifyCode, IWarningAlert } from '../../constants/interface';
import { Auth } from 'aws-amplify';
import { getHighestLevelPermission, saveAccessTokenToStore } from '../../utils';
import { toast } from 'react-toastify';
import { defaultUserTypes } from '../../utils/defaultData';

export interface IUserState {
  company_name: string
  company_logo: string
  companyEmail: string
  company_description: string
  firstName: string
  lastName: string
  email: string
  email_verified?: boolean
  user_role?: string
  isDesiderAdmin?: boolean
  isBpUser?: boolean
  isAuthenticated?: boolean
  user_id: string
  user_type?: string
  permissions: string[]
  oldPassword: string
  session: string
  password_reset?: boolean
  image_url: string
  warningAlert?: boolean
}

function signInfn(user: any) {
  localStorage.setItem('jwt_token', user.signInUserSession.accessToken.jwtToken);
  saveAccessTokenToStore(user);
  // user role logic starts from here...
  let userRole = '';
  const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'];
  const isEmailVarified = user.attributes.email_verified;
  const heighestLevelPermission = getHighestLevelPermission(userGroups) ?? USER_ROLE.DSIDER_ADMIN;

  // CHECKS FOR BPUSERS(EV_USERS)...
  if (heighestLevelPermission === USER_ROLE.EV_USER) {
    // assign the user role other than 'bpuser'...
    userRole = userGroups.find((role: string) => role !== USER_ROLE.EV_USER) || USER_ROLE.DSIDER_ADMIN;
  } else {
    userRole = heighestLevelPermission;
  }
  const isDesiderAdmin = userRole === USER_ROLE.DSIDER_ADMIN;
  const isBpUser = userGroups?.includes(USER_ROLE.EV_USER);
  if (userRole && userGroups?.length) {
    return {
      ...initialState,
      company_logo: user.attributes.picture,
      company_name: user.attributes.name,
      company_description: user.attributes.address,
      email: (user.attributes.email as string).toLowerCase(),
      email_verified: user.attributes.email_verified,
      user_role: userRole,
      user_type: isDesiderAdmin ? defaultUserTypes.admin : defaultUserTypes.user,
      isDesiderAdmin: userRole === USER_ROLE.DSIDER_ADMIN,
      isBpUser: isBpUser ?? false,
      user_id: user.attributes.locale,
      isAuthenticated: true
    };
  } else if (!userGroups && isEmailVarified) {
    return { isEmailVarified, password_reset: true, isAuthenticated: true, user_type: defaultUserTypes.user };
  } else {
    toast.error(MESSAGES.UNAPPROVED_USER);
    return { ...initialState };
  }
}
export const signInUser = createAsyncThunk(
  actions.onboardingSignIn,
  async (params: ISignIn) => {
    try {
      const user = await Auth.signIn({
        username: params?.email?.trim(),
        password: params?.password?.trim()
      });
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return { ...initialState, oldPassword: params?.password?.trim(), email: user.username, session: user };
      };
      // It is for temporary use to test Authorization, it will be removed after permanent solution
      return signInfn(user);
    } catch (error) {
      throw error;
    }
  });

export const getAttributes = createAsyncThunk(
  actions.userAttributes,
  async (loggedInUser: any) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return { ...user };
    } catch (error) {
      return error;
    }
  });

export const resendSignUpCode = createAsyncThunk(
  actions.resendSignUpCode,
  async (username: string) => {
    try {
      await Auth.resendSignUp(username);
      return { ...initialState, email: username };
    } catch (error) {
      throw error;
    }
  });

/**
 * Return user attributes
 * @param {Object} payload - updated user attribute object
 * @return - Returns user attributes if success and update the store
 */
export const updateUserAttributes = createAsyncThunk(
  actions.updateAttributes,
  async (payload: IEditProfile) => {
    const attributes = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      picture: payload.company_logo || ''
    };
    try {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          // Update the user's attributes
          updateData(user, attributes);
        }).catch(() => { });
      return { ...payload };
    } catch (error) {
      return error;
    }
  }
);

const updateData = (user: any, attributes: any) => {
  Auth.updateUserAttributes(user, attributes);
};

export const signOutUser = createAsyncThunk(
  actions.signout,
  async () => {
    try {
      await Auth.signOut();
      return { ...initialState };
    } catch (error) {
      return error;
    }
  });

export const resendCode = createAsyncThunk(
  actions.resendCode,
  async (params: IVerifyCode) => {
    try {
      return await Auth.resendSignUp(params.email);
    } catch (error) {
      throw error;
    }
  });

export const verifyEmail = createAsyncThunk(
  actions.verifyEmail,
  async (params: IVerifyCode) => {
    try {
      await Auth.confirmSignUp(params.email, params.verify_code);
      return { ...initialState };
    } catch (error) {
      throw error;
    }
  });

export const signUpUser = createAsyncThunk(
  actions.onboardingSignUp,
  async (params: ISignUp) => {
    const payload = {
      username: params?.email?.trim(),
      password: params?.password,
      attributes: {
        email: params?.email?.trim(),
        name: params?.company_name?.trim(),
        address: params?.company_description?.trim(),
        picture: params?.company_logo?.trim(),
        locale: params.user_id
      },
      autoSignIn: { enabled: true }
    };
    try {
      const response = await Auth.signUp(payload);
      return { ...params, email_verified: response.userConfirmed };
    } catch (error) {
      throw error;
    }
  });

export const forgotPassword = createAsyncThunk(
  actions.forgotPassword,
  async (params: IForgotPassword) => {
    try {
      return await Auth.forgotPassword(params.email);
    } catch (error) {
      return error;
    }
  });

export const resetPassword = createAsyncThunk(
  actions.resetPassword,
  async (payload: IResetPassword) => {
    try {
      return await Auth.forgotPasswordSubmit(
        payload?.username,
        String(payload?.code),
        payload?.password
      );
    } catch (error) {
      return error;
    }
  });

// change password
export const changePassword = createAsyncThunk(
  actions.changePassword,
  async (payload: IChangePasswordCognitoObject) => {
    try {
      return await Auth.changePassword(payload.user, payload.oldPassword, payload.newPassword)
        .then(() => {
          return { ...initialState };
        })
        .catch((error) => {
          return { error };
        });
    } catch (error: any) {
      toast.error(error.message);
      return error;
    }
  });

export const setPassword = createAsyncThunk(
  actions.setPassword,
  async (payload: any) => {
    const { newPassword, session } = payload;
    try {
      await Auth.completeNewPassword(session, newPassword).then(user => {
        signInfn(user);
      }).catch(() => { });
    } catch (error: any) {
    }
  }
);

export const updateAdminUser = createAsyncThunk(
  actions.localUser,
  (payload: any) => {
    const isDsiderAdmin = payload.role === USER_ROLE.DSIDER_ADMIN;
    return {
      company_name: payload.company_name,
      company_description: payload.company_description,
      companyEmail: payload.companyEmail,
      firstName: payload.firstName,
      lastName: payload.lastName,
      permissions: payload.permissions,
      email: payload.userName,
      email_verified: true,
      user_role: payload.role,
      user_type: isDsiderAdmin ? defaultUserTypes.admin : defaultUserTypes.user,
      isDesiderAdmin: isDsiderAdmin,
      user_id: payload._id,
      image_url: payload.image_url,
      isAuthenticated: true
    };
  }
);

const initialState: IUserState = {
  company_name: '',
  company_logo: '',
  company_description: '',
  companyEmail: '',
  firstName: '',
  lastName: '',
  email: '',
  isAuthenticated: false,
  user_role: '',
  email_verified: false,
  user_id: '',
  user_type: '',
  permissions: [],
  oldPassword: '',
  session: '',
  image_url: '',
  warningAlert: false
};

export const updateAuthState = createAsyncThunk(
  actions.updateAuthState,
  async (payload: IEditProfile) => {
    try {
      return { ...payload };
    } catch (error) {
      return error;
    }
  }
);

export const updateWarningAlert = createAsyncThunk(
  actions.warningAlert,
  async (payload: IWarningAlert) => {
    try {
      return { ...payload };
    } catch (error) {
      return error;
    }
  }
);

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      return { ...state, ...action.payload };
    },
    updateUserType(state, action: PayloadAction<any>) {
      return { ...state, user_type: action.payload };
    },
    updateUserPermissions(state, action: PayloadAction<any>) {
      return { ...state, permissions: action.payload };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(signUpUser.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      })
      .addCase(resendSignUpCode.fulfilled, (state, { payload }: PayloadAction<IUserState>) => {
        return { ...state, ...payload };
      })
      .addCase(verifyEmail.fulfilled, (state, { payload }: PayloadAction<IUserState>) => {
        return { ...state, ...payload };
      })
      .addCase(signInUser.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      })
      .addCase(signOutUser.fulfilled, (state, { payload }: any) => {
        return { ...payload };
      })
      .addCase(updateUserAttributes.fulfilled, (state, { payload }: any) => {
        return { ...state, ...payload };
      })
      .addCase(getAttributes.fulfilled, (state, { payload }: any) => {
        return { ...state, ...payload };
      })
      .addCase(forgotPassword.fulfilled, (state, { meta }) => {
        return { ...state, email: meta.arg.email };
      })
      .addCase(updateAdminUser.fulfilled, (state, { payload }: any) => {
        return { ...state, ...payload };
      })
      .addCase(setPassword.fulfilled, (state, { payload }: any) => {
        return { ...state, isEmailVarified: true, password_reset: true };
      })
      .addCase(updateAuthState.fulfilled, (state, { payload }: any) => {
        return { ...state, ...payload };
      })
      .addCase(updateWarningAlert.fulfilled, (state, { payload }: any) => {
        return { ...state, ...payload };
      });
  }
});

export const { updateUser, updateUserType, updateUserPermissions } = authSlice.actions;
export default authSlice.reducer;
