import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { threeDotHorizontal, threeDotHorizontalWhite } from '../../utils';
import { EV, SHARED_SCENARIO_PERMISSION } from '../../constants';

interface IOption {
  name: string
  onClickFn: any
  optionClassName?: string
  disabled?: boolean
}

interface IDropdown {
  options: IOption[]
  className: string
  optionLabel?: string
  data: any
  whiteThreeDots?: boolean
}

const DropDownMenu = ({ options, className, optionLabel, data, whiteThreeDots }: IDropdown) => {
  return (
    <div className={`card-dropdown ${className || ''}`}>
      {[''].map((direction) => (
        <Dropdown
          className="create-modal-dropdown three-dot-dropdown"
          as={ButtonGroup}
          key={direction}
          id={`dropdown-button-drop-${direction}`}
          variant="secondary"
          align="end"
        >
          <Dropdown.Toggle id="dropdown-basic">
            {whiteThreeDots && <img
              src={threeDotHorizontalWhite}
              alt="three dot icon"
            />}
            {!whiteThreeDots && <img
              src={threeDotHorizontal}
              alt="three dot icon"
            />}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.ItemText>
              {optionLabel ?? 'Options'}
            </Dropdown.ItemText>
            {
              options.map((opt) => {
                let exportDisable = false;
                if (opt.name === 'Export JSON' && (data?.subProject_Type === EV || data?.Subproject_type === EV)) {
                  exportDisable = true;
                }
                const disabledStatus = exportDisable || opt.disabled;
                return (
                  !opt.disabled && (<Dropdown.Item disabled={disabledStatus} as="button" key={opt.name.trim()} className={opt.optionClassName} onClick={() => opt.onClickFn(data)}>
                    {opt.name}
                  </Dropdown.Item>)
                );
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </div>
  );
};

export default DropDownMenu;
