import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  useCheckObjectNameMutation,
  useGetObjectLibraryQuery,
  useGetObjectTypeMutation,
  useLazyGetComponentPropertiesQuery,
  useLazyGetRefrenceObjectQuery,
  useUpdateObjectMutation
} from '../../../redux/services/admin/objectManagementApis';
import { useGetFormProjectTypeQuery } from '../../../redux/services/projectApis';
import AddObjectIcon from './AddObjectIcon';
import AddNewParameter from './AddNewParameter';
import LocationModal from '../../shared/LocationModal';
import { LABELS, MESSAGES, NUMBER, genericObject } from '../../../constants';
import { FormikHelpers, FormikProps } from 'formik';
import { useSaveObjectMutation } from '../../../redux/services/admin/companyManagementApis';
import { toast } from 'react-toastify';
import { workbenchApis } from '../../../redux/services/workbenchApis';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateSingleObject, updateSingleParameter } from '../../../redux/slices/adminSlice';
import ModalHoc from '../shared/ModalHoc';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import AddNewObjectForm from './AddNewObjectForm';
import { stopFullLoading } from '../../../redux/SpinnerSlice';
import { capitalizeFirstLetter } from '../../../utils';

interface IIntialObject {
  category: string
  component: string
  componentDisplayName: string
  imageUrl: string
}

const intialObject: IIntialObject = {
  category: '',
  component: '',
  componentDisplayName: '',
  imageUrl: ''
};

interface Property {
  propertyLabel: string
  propertyValue: any // Adjust the type as needed
  propertyType: string
  propertyName: string
  unit: string | null
}

interface InitialObjects {
  projectType: string
  object_library: string
  objectType: string
  component_name: string
  reference_object: string
  properties: Property[]
  object_id?: string
}

const emptyImage = {
  imageUrl: '',
  imageDarkUrl: ''
};


const AddNewObject = ({ ShowAddObject, setShowAddObject, isEditObject }: any) => {
  const [showObjectIcon, setShowObjectIcon] = useState<undefined | boolean>(undefined);
  const [showObjectImage, setShowObjectImage] = useState<undefined | boolean>(undefined);
  const [showAddParameter, setShowAddParameter] = useState(false);
  const [objectName, setObjectName] = useState('');
  const [showGoogleMaps, setShowGoogleMaps] = useState(false);
  const [selectLocProps, setSelectedLocProps] = useState('');
  const [selectImage, setSelectImage] = useState<any>(emptyImage);
  const [focusedProperty, setFocusedProperty] = useState('');
  const [selectObjectType, setSelectObjectType] = useState('');
  const [objectType, setObjectType] = useState({});
  const [selectObjectLibrary, setSelectObjectLibrary] = useState('');
  const [formError, setFormError] = useState<any>({});
  const [showError, setShowError] = useState(false);
  const [refObj, setRefObj] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedObject, setSelectedObject] = useState(intialObject);
  const [isPermissionChanged, setIsPermissionChanged] = useState(false);
  const [intialValidation, setIntialValidation] = useState(false);
  const { singleObject } = useAppSelector((state: any) => state.adminSlice);
  const dispatch = useAppDispatch();
  const intialValue = {
    projectType: '',
    object_library: '',
    objectType: '',
    component_name: '',
    reference_object: '',
    object_image_url: '',
    properties: []
  };

  const [intialObjects, setInitialObject] = useState(intialValue);
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [getObjectType, { data: objectTypes, isLoading: objectTypeLoading }] = useGetObjectTypeMutation();
  const [checkObjectName, { isLoading: objectNameStatusLoading, isError: objectNameError }] = useCheckObjectNameMutation();
  const { data: projectType, isLoading: projectTypeLoading } = useGetFormProjectTypeQuery();
  const { data: objectLibrary, isLoading: objectLibraryLoading } = useGetObjectLibraryQuery();
  const [getRefrenceObject, { data: refrenceObject, isLoading: objectLoading }] = useLazyGetRefrenceObjectQuery({});
  const [getComponentProperties, { data: customProps }] = useLazyGetComponentPropertiesQuery({});
  const [saveObject] = useSaveObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const handleObjectNameStatus = () => {
    checkObjectName(objectName);
  };

  useEffect(() => {
    getObjectType({});
  }, []);

  const showConfirmationAlert = () => {
    isPermissionChanged ? setShowAlert(true) : resetToIntial();
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };


  useEffect(() => {
    objectName && handleObjectNameStatus();
  }, [objectName]);

  useEffect(() => {
    if (selectObjectLibrary) {
      const objectsUnderLibrary = refrenceObject.filter((item: any) => item.category.includes(selectObjectLibrary));
      // @ts-expect-error expected error.
      const newObjectType = [...new Set(objectsUnderLibrary.map(item => item.powerCategory))]
        .filter(category => objectTypes.includes(category))
        .map(category => ({ label: capitalizeFirstLetter(category), value: category }));
      setObjectType(newObjectType);
    }
    if (selectObjectLibrary && selectObjectType && refrenceObject) {
      const data = refrenceObject.filter((item: any) => item.category.includes(selectObjectLibrary) && item.powerCategory === selectObjectType);
      setRefObj(data);
    }
  }, [selectObjectLibrary, selectObjectType]);

  useEffect(() => {
    if (formikRef.current) {
      !isEditObject && selectedObject.component && getComponentProperties(selectedObject.component);
      !isEditObject && formikRef.current.setFieldValue('reference_object', selectedObject.component);
      formikRef.current.values.properties = [];
    }
  }, [selectedObject]);

  useEffect(() => {
    if (singleObject) {
      ShowAddObject && isEditObject && singleObject.component && getComponentProperties(singleObject.component);
      const newIntialObject = {
        component_name: singleObject.componentDisplayName,
        object_library: singleObject.category,
        objectType: singleObject.powerCategory,
        projectType: singleObject.backend_component_type_1,
        reference_object: singleObject.reference_object || singleObject.componentDisplayName,
        properties: [],
        object_id: singleObject._id,
        object_image_url: singleObject.imageS3url
      };
      setInitialObject(newIntialObject);
      const imageUrls = {
        imageUrl: singleObject.imageS3url || singleObject.imageS3urlDark,
        imageDarkUrl: singleObject.imageS3urlDark ?? ''
      };
      setSelectImage(imageUrls);
    }
  }, [singleObject, formikRef, ShowAddObject]);

  useEffect(() => {
    if (singleObject && !objectLoading) {
      const newRefObj = refrenceObject?.find((d: any) => d.component === intialObjects.reference_object);
      setSelectedObject({ ...selectedObject, componentDisplayName: newRefObj?.componentDisplayName || intialObjects.component_name });
    }
  }, [objectLoading, intialObjects]);

  useEffect(() => {
    if (singleObject && projectType && isEditObject) {
      formikRef.current?.setFieldValue('projectType', singleObject.powerCategory);
      setSelectObjectType(singleObject.powerCategory);
    }
  }, [projectType]);

  useEffect(() => {
    if (formikRef.current) {
      if (selectedObject.componentDisplayName) {
        formikRef.current.values.properties = [];
        const newProperties = customProps
          ? customProps.map((property: any) => ({
            propertyName: property.formulaTitle,
            propertyValue: property.propertyValue || 0,
            propertyType: property.propertyType,
            propertyLabel: property.propertyName,
            unit: property.unit || property.propertyType,
            description: property.description || '',
            id: property._id
          }))
          : [];
        formikRef.current.setFieldValue('properties', newProperties);
      } else {
        formikRef.current.values.properties = [];
      }
    }
  }, [customProps, selectedObject]);

  const resetToIntial = () => {
    setObjectName('');
    setSelectedObject(intialObject);
    setInitialObject(() => intialValue);
    setSelectImage(emptyImage);
    setShowAddObject(false);
    setShowError(false);
    setSelectObjectLibrary('');
    setSelectObjectType('');
    setFormError({});
    hideConfirmationAlert();
    setIsPermissionChanged(false);
    setShowAddParameter(false);
    setIntialValidation(false);
    dispatch(updateSingleObject({}));
  };

  useEffect(() => {
    ShowAddObject && getRefrenceObject();
  }, [ShowAddObject]);

  const handleFormSubmit = () => {
    return async (values: any, { resetForm }: FormikHelpers<any>) => {
      if (!selectImage.imageUrl) {
        setShowError(true);
        return;
      }
      if (objectNameError && isEditObject && values.component_name === singleObject.componentDisplayName) {
        // do nothing
      } else if (objectNameError) {
        toast.error(MESSAGES.OBJECT_NAME_EXISTS);
        return;
      }
      const formData = new FormData();
      const newValueObject = JSON.parse(JSON.stringify(values));
      newValueObject.object_image_url = selectImage.imageUrl;
      newValueObject.object_image_dark_url = selectImage.imageDarkUrl;
      newValueObject.properties = newValueObject.properties.map((property: any) => ({
        propertyName: property.propertyLabel,
        propertyValue: property.propertyValue,
        propertyType: property.propertyType,
        propertyLabel: property.propertyName,
        unit: property.unit,
        description: property.description
      }));

      const updateObjectPayload = {
        component_name: newValueObject.component_name,
        object_image_url: selectImage.imageUrl,
        object_image_dark_url: selectImage.imageDarkUrl,
        object_id: newValueObject.object_id,
        properties: newValueObject.properties
      };

      const updateLoackedObject = {
        object_image_url: selectImage.imageUrl,
        object_image_dark_url: selectImage.imageDarkUrl,
        object_id: newValueObject.object_id
      };
      // will Use these line if needed
      // delete newValueObject.projectType;
      // delete newValueObject.objectType;

      formData.append('objects_data_json', JSON.stringify(newValueObject));
      try {
        isEditObject ? updateObject(singleObject.locked ? updateLoackedObject : updateObjectPayload)
          .then((res: any) => {
            if (res.error) {
              toast.error(res.error.data.detail || res.error.data.message || MESSAGES.SOMETHING_WENT_WRONG);
            } else {
              toast.success(MESSAGES.OBJECT_UPDATED_SUCCESS);
              resetForm();
              resetToIntial();
              dispatch(workbenchApis.util.invalidateTags(['Workbench']));
            }
          })
          .catch(() => {
            dispatch(stopFullLoading());
          })
          : saveObject(formData)
            .then((res: any) => {
              if (res.error) {
                toast.error(res.error.data.detail || res.error.data.message || MESSAGES.SOMETHING_WENT_WRONG);
              } else {
                toast.success(MESSAGES.OBJECT_ADDED_SUCCESS);
                resetForm();
                resetToIntial();
                dispatch(workbenchApis.util.invalidateTags(['Workbench']));
              }
            })
            .catch(() => {
              dispatch(stopFullLoading());
            });
      } catch (error) { }
    };
  };

  useEffect(() => {
    if (showObjectImage === false && !selectImage.imageDarkUrl) {
      setShowError(true);
    }
  }, [showObjectImage]);

  const validateInitialObjects = (values: InitialObjects) => {
    const errors: Partial<InitialObjects> = {};
    setIntialValidation(true);
    if (!selectImage.imageUrl) {
      setShowError(() => true);
    }

    if (!values.projectType) {
      errors.projectType = 'Project type is required';
    }
    if (!values.object_library) {
      errors.object_library = 'Object library is required';
    }
    if (!values.objectType) {
      errors.objectType = 'Object type is required';
    }
    if (!values.component_name) {
      errors.component_name = 'Object name is required';
    }

    if (!values.reference_object) {
      errors.reference_object = 'Reference object is required';
    }
    setFormError(errors);

    return errors;
  };

  const openGoogleMap = (property: any) => {
    setSelectedLocProps(() => property);
    setShowGoogleMaps(true);
  };

  const updateLocationProp = (newLat: string, newLng: string) => {
    const newLocation = `${Number(newLat)},${Number(newLng)}`;
    const updatedProperties = formikRef.current?.values.properties.map((property: any) =>
      property.propertyName === selectLocProps
        ? { ...property, propertyValue: newLocation, unit: 'location' }
        : property
    );
    formikRef.current?.setFieldValue('properties', updatedProperties);
  };

  const handleImageSelect = (img: any) => {
    setSelectImage(img);
    setShowError(false);
    setIsPermissionChanged(true);
  };

  const addedStaticData = refrenceObject && [genericObject, ...refrenceObject];

  const handleEditParameter = (property: any) => {
    setShowAddParameter(true);
    dispatch(updateSingleParameter(property));
  };

  const formProps = {
    formikRef,
    intialObjects,
    validateInitialObjects,
    handleFormSubmit,
    intialValidation,
    selectImage,
    showError,
    setShowObjectImage,
    projectTypeLoading,
    setSelectImage,
    openGoogleMap,
    objectLibraryLoading,
    setIsPermissionChanged,
    formError,
    objectLibrary,
    setFocusedProperty,
    objectTypeLoading,
    setSelectObjectLibrary,
    objectType,
    isEditObject,
    setObjectName,
    setSelectObjectType,
    objectName,
    objectNameStatusLoading,
    objectNameError,
    selectedObject,
    setShowObjectIcon,
    setShowAddParameter,
    handleEditParameter,
    selectObjectLibrary,
    selectObjectType,
    focusedProperty,
    showConfirmationAlert,
    projectType,
    singleObject,
    setSelectedObject,
    intialObject
  };

  return (
    <Modal
      show={ShowAddObject}
      onHide={showConfirmationAlert}
      dialogClassName="modal-588 top-right-modal input-output-modal object-layers-modal add-object-modal"
      className="forget-modal setting-modal "
    >
      <Modal.Header closeButton className="" onClick={showConfirmationAlert}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddObjectIcon ShowObjectIcon={showObjectIcon} setShowObjectIcon={setShowObjectIcon} data={refObj} setSelectedObject={setSelectedObject} type="referenceObject" />
        <AddObjectIcon ShowObjectIcon={showObjectImage} setShowObjectIcon={setShowObjectImage} data={addedStaticData} setSelectedObject={handleImageSelect} type="objectImage" />
        <AddNewParameter
          ShowAddParameter={showAddParameter}
          setShowAddParameter={setShowAddParameter}
          properties={formikRef.current?.values.properties}
          setIsPermissionChanged={setIsPermissionChanged} />
        <LocationModal
          showGoogleMaps={showGoogleMaps}
          setShowGoogleMaps={setShowGoogleMaps}
          saveMapLocation={updateLocationProp}
          lat={customProps && formikRef.current?.values?.properties?.find((data: any) => data.propertyName === selectLocProps)?.propertyValue?.split(',')[NUMBER.N0]}
          lng={customProps && formikRef.current?.values?.properties?.find((data: any) => data.propertyName === selectLocProps)?.propertyValue?.split(',')[NUMBER.N1]}
        />
        <h4 className="modal-head modal-head-medium">{isEditObject ? 'Edit' : 'Add New'} Object</h4>
        <AddNewObjectForm {...formProps} />
        <ModalHoc showModal={showAlert} setShowModal={hideConfirmationAlert}>
          <ConfirmationAlert
            showAlert={showAlert}
            title={LABELS.DISCARD_CHANGE}
            message={MESSAGES.DISCARD_COMPANY_PERMISSION_MESSAGE}
            cancleBtnText={LABELS.STAY}
            yesBtnText={LABELS.YES_DISCARD}
            onConfirm={resetToIntial}
            onCancel={hideConfirmationAlert}
          />
        </ModalHoc>
      </Modal.Body >
    </Modal>
  );
};

export default AddNewObject;
