import React from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import GoogleMaps from '../shared/GoogleMaps';

interface ILocationProps {
  latitude: any
  longitude: any
  loading: boolean
  setLatitude: (lat: string) => void
  setLongitude: (lng: string) => void
  saveLocSettings: () => void
  hideModal: () => void
}

const LocationSettingsTab = (props: ILocationProps) => {
  const { latitude, longitude, setLatitude, setLongitude, saveLocSettings, hideModal, loading } = props;

  const updateLocationProp = (newLat: string, newLng: string) => {
    setLatitude(newLat);
    setLongitude(newLng);
  };

  return (
    <div className='tab-main-container'>
      <div className='operational-main-wrapper loaction-map-wrap'>
        <div className="location-main-wrapper">
          <div className="location-map">
            <GoogleMaps lat={latitude} lng={longitude} updateLocationProp={updateLocationProp} />
          </div>
          <Form className="object-parameter-form">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="latitude">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="number"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group" controlId="longitude">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="text"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    autoComplete='off'
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
          <p className="location-des">
            Updating the location will override all the locations of the components.
          </p>
          <div className="setting-btn-wrap">
            <Button className="btn-no-outline" onClick={hideModal}>
              Cancel
            </Button>
            <Button className="primary" onClick={saveLocSettings}>
              {loading ? <Spinner /> : 'Save Changes'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSettingsTab;
