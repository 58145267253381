import React, { useState, useEffect, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import { DEFAULT_LOCATION, grayStylesMap } from '../../constants';
import { pinIcon } from '../../utils/icons';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import GoogleMapSearch from './GoogleMapSearch';

interface IMapsInterface {
  lat: string
  lng: string
  updateLocationProp: (lat: string, lng: string) => void
}

function GoogleMaps(props: IMapsInterface) {
  const { lat, lng, updateLocationProp } = props;
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState<any>(null);
  const [marketObj, setMarkerObject] = useState<any>(null);

  const libraries: Libraries = ['places'];

  const loaderOptions = useMemo(() => ({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY as string,
    libraries
  }), []);


  const { isLoaded, loadError } = useJsApiLoader(loaderOptions);


  useEffect(() => {
    if (lat !== undefined && lng !== undefined) {
      setLatitude(+lat);
      setLongitude(+lng);
    } else {
      setLatitude(DEFAULT_LOCATION.lat);
      setLongitude(DEFAULT_LOCATION.lng);
    }
  }, [lat, lng]);

  const handleDragEnd = (event: any) => {
    updateLocationProp(event.latLng.lat(), event.latLng.lng());
  };

  useEffect(() => {
    if (googleApiObj) {
      const { map, maps } = googleApiObj;
      loadMap(map, maps);
    }
  }, [googleApiObj, latitude, longitude]);


  const loadMap = (map: any, maps: any) => {
    if (marketObj) {
      marketObj.setMap(null);
    }
    let marker = new maps.Marker();
    marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      icon: pinIcon,
      draggable: true,
      className: 'google-map-draggable'
    });
    setMarkerObject(marker);
    marker.addListener('dragend', handleDragEnd);
  };

  const handleLocationClick = (lats: string, lngs: string) => {
    setLatitude(() => +lats);
    setLongitude(() => +lngs);
    updateLocationProp(lats, lngs);
  };

  return (
    <>
      <div className="gootle-map-wrap" style={{ height: '100vh', width: '100%' }}>
        {isLoaded && <GoogleMapSearch handleLocationClick={handleLocationClick} />}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY as string }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          center={{ lat: latitude, lng: longitude }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          options={{ styles: grayStylesMap }}
          onGoogleApiLoaded={({ map, maps }) => setIsGoogleApiLoadedObj({ map, maps })} >
        </GoogleMapReact>
      </div>
    </>
  );
}

export default GoogleMaps;
