import React, { useEffect, useRef, useState } from 'react';
import { NUMBER } from '../../constants';
import { Form } from 'react-bootstrap';
import { useGetApproverUserListQuery } from '../../redux/services/requestApprovalApis';
import { useAppSelector } from '../../redux/hooks';
import UserAvatar from './UserAvatar';

export interface IList {
  email: string
  firstName: string
  lastName: string
  imgUrl?: string
}

interface IAddApproverProps {
  onSelect: (approver: IList) => void
  setEditApproverId?: (id: string) => void
  showAddApprover: (show: boolean) => void
}

function AddApprover(props: IAddApproverProps) {
  const { onSelect, setEditApproverId, showAddApprover } = props;
  const { approverList } = useAppSelector(state => state.approvalData);
  const [userList, setUserList] = useState<IList[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: list, isLoading, isSuccess } = useGetApproverUserListQuery();
  const [isFocused, setIsFocused] = useState(false);
  const inputEle = useRef<HTMLInputElement>(null);

  const onChangeHandler = (approver: IList) => {
    onSelect(approver);
  };

  useEffect(() => {
    if (isSuccess) {
      updateUserList();
    }
  }, [isSuccess]);

  const updateUserList = () => {
    const approverEmail = approverList?.map(data => data.emailid);
    const filteredList = list?.filter(user => {
      return (!approverEmail.includes(user.email) &&
        (user.firstName?.toLowerCase()?.includes(searchValue) || user.lastName?.toLowerCase()?.includes(searchValue) || user.email?.toLowerCase()?.includes(searchValue)));
    });
    setUserList(filteredList ?? []);
  };

  const onBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      showAddApprover(false);
      setEditApproverId && setEditApproverId('');
    }, NUMBER.N200);
  };

  useEffect(() => {
    if (inputEle.current) {
      inputEle.current.focus();
    }
  }, []);

  useEffect(() => {
    updateUserList();
  }, [searchValue]);

  return (
    <>
      <div className='approval-search-wrap'>
        <Form.Group className="form-group search-form-group" controlId="formBasicEmail" >
          <Form.Control
            ref={inputEle}
            type="search"
            autoComplete='off'
            placeholder="Search Approver"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => onBlur()}
          />
        </Form.Group>
        {isFocused && <div className="hover-box-wrap comment-box-wrap">
          <div className="hover-box">
            {!isLoading && userList?.map((data) => {
              return (
                <div className="tab-left-part" key={data.email} onClick={() => onChangeHandler(data)}>
                  <div className="dropdown-wrapper shared-profile">
                    <UserAvatar
                      classname='small blue'
                      firstname={data.firstName ?? data.email}
                      lastname={data.lastName ?? ''}
                      imageUrl={data.imgUrl ?? ''}
                    />
                    <div className="right-sec">
                      <h3 className="dropdown-head">
                        {data.firstName} {data.lastName}
                      </h3>
                      <p className="dropdown-des">
                        {data.email}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* if there is no approver... */}
            {
              !isLoading && !userList?.length && <div className="tab-left-part">
                <div className="dropdown-wrapper shared-profile">
                  No Approver Found!
                </div>
              </div>
            }
            {/* if data is loading... */}
            {
              isLoading && <div className="tab-left-part">
                <div className="dropdown-wrapper shared-profile">
                  Loading...
                </div>
              </div>
            }
          </div>
        </div>}
      </div>
    </>
  );
}

export default AddApprover;
