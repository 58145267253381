import React from 'react';
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import Select from "react-select";
import { hydrogenLogoSmall, threeDotHorizontal } from '../../utils/icons';

function UserProfileRight() {
  return (
    <div className="tab-main-wrapper">
      <div className="tab-left-part">
        <div className="dropdown-wrapper shared-profile">
          <span className="user-icon medium">
            <img
              src={hydrogenLogoSmall}
              alt="logo"
              className="user-img"
            />
          </span>
          <div className="right-sec">
            <h3 className="dropdown-head">
              Green Hydrogen Basecase
            </h3>
            <p className="dropdown-des">
              Model/Green Hydrogen
            </p>
          </div>
        </div>
      </div>
      <div className="tab-right-part">
        {[''].map((direction) => (
          <Dropdown
            className="create-modal-dropdown three-dot-dropdown"
            as={ButtonGroup}
            key={direction}
            id={`dropdown-button-drop-${direction}`}
            variant="secondary"
            align="end"
          >
            <Dropdown.Toggle id="dropdown-basic">
              <img
                src={threeDotHorizontal}
                alt="three dot icon"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.ItemText>
                Options
              </Dropdown.ItemText>
              <Dropdown.Item as="button">
                Export JSON
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className="text-danger"
              >
                Remove Scenario
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ))}
      </div>
    </div>
  );
}
export default UserProfileRight;
