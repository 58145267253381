import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, Methods } from '../../constants';
import { interceptor } from '../../utils/interceptor';

export interface IZipCode {
  zipCode: string
}

export const evStationsApis = createApi({
  reducerPath: 'evStationsApis',
  baseQuery: interceptor,
  tagTypes: ['evStations'],
  endpoints: (builder) => ({
    // api to get Ev stations data by zip code
    getEvStations: builder.query<IZipCode, { zipCode: string, radius: number }>({
      query: ({ zipCode, radius }) => ({
        url: `${ApiEndpoints.GET_PROJECT_DETAILS}?zipcode=${zipCode}&radius=${radius}`,
        method: Methods.GET
      }),
      providesTags: ['evStations']
    })
  })
});

export const { useGetEvStationsQuery } = evStationsApis;
