import React, { useState, useEffect } from 'react';
import { ButtonGroup, Dropdown, Spinner } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetSharedUserQuery, useUnshareScenarioMutation } from '../../redux/services/scenarioApis';
import { updateSharedUserList } from '../../redux/slices/sharedUserSlice';
import { ISharedUserList, LABELS, MESSAGES, NUMBER, SHARED_SCENARIO_PERMISSION } from '../../constants';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
interface IInvitePeople {
  id: any
  handleForm: (values: any, permission: string) => void
  scenarioData?: any
  workbenchData: any
  approvalStatus: string
}

function InvitePeople(props: IInvitePeople) {
  const { id, handleForm, scenarioData, workbenchData, approvalStatus } = props;
  const dispatch = useAppDispatch();
  const authData = useAppSelector(state => state.auth);
  const loading = useAppSelector((state) => state.spinner.loading);
  const sharedList = useAppSelector(state => state.sharedUserList);
  const { data: sharedUser, isSuccess, isError } = useGetSharedUserQuery(id || scenarioData?.Scenario_id);
  const [getUserList, setUserList] = useState<ISharedUserList[]>();
  const [showAlert, setShowAlert] = useState(false);
  const [currentUserName, setCurrentUserName] = useState<string>('');
  const [unShareMutation] = useUnshareScenarioMutation();
  const [isOwnerTheLoggedInUser, setOwner] = useState(false);
  const [userCreator, setUserCreator] = useState(workbenchData?.scenarioDetails?.userCreator || scenarioData?.Created_by);

  useEffect(() => {
    if (userCreator === authData.email) {
      setOwner(true);
    } else {
      setOwner(false);
    }
  }, [workbenchData, authData, scenarioData]);

  useEffect(() => {
    if (isSuccess || isError) {
      const sharedUserList = isSuccess ? sharedUser : [];
      setUserList(sharedUserList);
      dispatch(updateSharedUserList(sharedUserList));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setUserList(sharedList);
  }, [sharedList]);

  const handleSelect = (e: any, ele: any) => {
    const data = {
      email: ele.sharedToUserName
    };
    setCurrentUserName(ele.sharedToUserName);
    e !== null && handleForm(data, e);
    e === null && showConfirmationAlert();
  };

  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const handleRemove = () => {
    setShowAlert(false);
    dispatch(startLoading(true));
    const payload = {
      scenarioId: id || scenarioData?.Scenario_id,
      userNameToUnShare: currentUserName
    };
    unShareMutation(payload)
      .then((res: any) => {
        const existingDataIndex = sharedList?.findIndex((ele: ISharedUserList) => ele.sharedToUserName === currentUserName);
        const dataToUpdate: ISharedUserList[] = [...sharedList];
        if (existingDataIndex >= NUMBER.N0) {
          dataToUpdate.splice(existingDataIndex, 1);
        }
        dispatch(stopLoading(false));
        dispatch(updateSharedUserList(dataToUpdate));
        !res.error && toast.success(MESSAGES.UNSHARE_SUCCESS);
      })
      .catch((err: any) => {
        toast.error(err.message);
        dispatch(stopLoading(false));
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="email-input-wrapper">
        <div className="tab-main-wrapper">
          <div className="tab-left-part">
            <div className="dropdown-wrapper shared-profile">
              <span className="user-icon medium">{userCreator?.charAt(NUMBER.N0)}</span>
              <div className="right-sec">
                <h3 className="dropdown-head">{isOwnerTheLoggedInUser
                  ? authData.company_name : ''} {`${isOwnerTheLoggedInUser ? '(You)' : ''}`}</h3>
                <p className="dropdown-des">{userCreator}</p>
              </div>
            </div>
          </div>
          <div className="tab-right-part">
            <span className="disable-field">Owner</span>
          </div>
        </div>
        {getUserList?.map((ele: any, index: number) => (
          <div className="tab-main-wrapper" key={index}>
            <div className="tab-left-part">
              <div className="dropdown-wrapper">
                <span className="user-icon medium">{ele.sharedToUserName.charAt(NUMBER.N0)}</span>
                <div className="right-sec">
                  {/* <h3 className="dropdown-head">Art Bartillion</h3> */}
                  <p className="dropdown-des">{ele.sharedToUserName}</p>
                </div>
              </div>
            </div>
            <div className="tab-right-part">
              {[''].map((direction) => (
                <Dropdown
                  className="create-modal-dropdown three-dot-dropdown"
                  as={ButtonGroup}
                  key={direction}
                  id={`dropdown-button-drop-${direction}`}
                  variant="secondary"
                  align="end"
                  onSelect={(e) => handleSelect(e, ele)}
                >
                  <Dropdown.Toggle id="dropdown-basic" disabled={ele.approver_flag}>
                    {ele.permission === SHARED_SCENARIO_PERMISSION.CONSUMER
                      ? SHARED_SCENARIO_PERMISSION.CONSUMER_ROLE
                      : SHARED_SCENARIO_PERMISSION.COLLABORATOR_ROLE}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {!approvalStatus && <Dropdown.Item eventKey="consumer">Consumer</Dropdown.Item>}
                    <Dropdown.Item eventKey="collaborator">Collaborator</Dropdown.Item>
                    <Dropdown.Item as="button" className="text-danger">
                      Remove User
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ))}
            </div>
          </div>
        ))}
        <ConfirmationAlert
          showAlert={showAlert}
          title={LABELS.REMOVE_USER}
          _id={id || scenarioData?.Scenario_id}
          message={MESSAGES.UNSHARE_USER_MSG}
          cancleBtnText={LABELS.KEEP_IT_FOR_NOW}
          yesBtnText={LABELS.YES_DELETE}
          onConfirm={handleRemove}
          onCancel={hideConfirmationAlert}
        />
      </div>
    </>
  );
};

export default InvitePeople;
