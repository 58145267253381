import React from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Tab,
  Tabs
} from 'react-bootstrap';
import {
  hydrogenLogoSmall,
  powerSmallLogo,
  projectBox1,
  projectBox2,
  projectBox3,
  projectBox4,
  projectBox5,
  searchDropdownLogo,
  searchLogo,
  threeDotHorizontal
} from '../../utils/icons';
import Header from './Header';

const AllModal = () => {
  return (
    <div className="all-models-section all-project-section">
      <Header />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="section-title">All Projects & Objects</h1>
              <p className="section-title-des">
                Find all projects & objects till date..
              </p>
              <Form className="full-width-form">
                <Form.Group
                  className="form-group search-form-group"
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    type="search"
                    placeholder="Search by Keyword"
                  />
                  <span className="icon-close-grey-icon "></span>
                  <img src={searchLogo} alt="logo" className="search-logo" />

                  {/* search dropdown */}
                  <div className="dropdown-auto">
                    {[''].map((direction) => (
                      <DropdownButton
                        as={ButtonGroup}
                        key={direction}
                        id={`dropdown-button-drop-${direction}`}
                        variant="secondary"
                        align="end"
                        title={
                          // Include an image within the title
                          <>
                            <img
                              src={searchDropdownLogo} // Replace with your image URL
                              alt="Image Alt Text"
                              className="search-dropdown-image" // Optional: Apply CSS classes for styling
                            />
                            {` ${direction} `}
                          </>
                        }
                      >
                        <Dropdown.ItemText>Options</Dropdown.ItemText>
                        <Dropdown.Item as="button">
                          Gas Processing
                          <span className="icon-theme-tick"></span>
                        </Dropdown.Item>
                        <Dropdown.Item as="button">
                          Green Hydrogen{' '}
                          <span className="icon-theme-tick"></span>
                        </Dropdown.Item>
                        <Dropdown.Item as="button" active>
                          SAF
                          <span className="icon-theme-tick"></span>
                        </Dropdown.Item>
                        <Dropdown.Item as="button">
                          Power <span className="icon-theme-tick"></span>
                        </Dropdown.Item>
                      </DropdownButton>
                    ))}
                  </div>
                </Form.Group>
              </Form>
              <div className="dsider-tab-wrapper">
                <div className="container">
                  <div className="dsider-tab-inner">
                    <Tabs
                      defaultActiveKey="projects"
                      id="uncontrolled-tab-example"
                      className=""
                    >
                      <Tab eventKey="projects" title="Projects">
                        <div className="project-card-wrapper">
                          <div className="row project-card-row">
                            <div className="col-md-4">
                              <div className="project-card">
                                <div className="project-card-img">
                                  <img
                                    src={projectBox1}
                                    alt="image logo"
                                    className="card-img"
                                  />
                                </div>
                                <div className="project-card-content">
                                  <h3 className="project-card-title">
                                    Green Hydrogen Base Green Hydrogen Base
                                  </h3>
                                  <div className="tooltip-container d-none">
                                    <p className="project-card-des">
                                      Clean Energy model for buildings takes
                                      wind, solar & battery.
                                    </p>
                                    <div className="tooltip-text">Clean Energy model for buildings takes
                                      wind, solar & battery.</div>
                                  </div>
                                  <p className="project-card-source">
                                    Green Hydrogen | 3 scenarios
                                  </p>
                                  <span className="project-card-date">
                                    Updated on: May 4, 2023
                                  </span>
                                </div>
                                <div className="card-dropdown d-none">
                                  {[''].map((direction) => (
                                    <Dropdown
                                      className="create-modal-dropdown three-dot-dropdown"
                                      as={ButtonGroup}
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      variant="secondary"
                                      align="end"
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img
                                          src={threeDotHorizontal}
                                          alt="three dot icon"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                          Options
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          Edit Details
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="text-danger"
                                        >
                                          Remove Project
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="project-card">
                                <div className="project-card-img">
                                  <img
                                    src={projectBox2}
                                    alt="image logo"
                                    className="card-img"
                                  />
                                </div>
                                <div className="project-card-content">
                                  <h3 className="project-card-title">
                                    Base-load Clean Power
                                  </h3>
                                  <div className="tooltip-container d-none">
                                    <p className="project-card-des">
                                      Clean Energy model for buildings takes
                                      wind, solar & battery.
                                    </p>
                                    <div className="tooltip-text">Clean Energy model for buildings takes
                                      wind, solar & battery.</div>
                                  </div>
                                  <p className="project-card-source">
                                    Green Hydrogen | 3 scenarios
                                  </p>
                                  <span className="project-card-date">
                                    Updated on: May 4, 2023
                                  </span>
                                </div>
                                <div className="card-dropdown d-none">
                                  {[''].map((direction) => (
                                    <Dropdown
                                      className="create-modal-dropdown three-dot-dropdown"
                                      as={ButtonGroup}
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      variant="secondary"
                                      align="end"
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img
                                          src={threeDotHorizontal}
                                          alt="three dot icon"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                          Options
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          Edit Details
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="text-danger"
                                        >
                                          Remove Project
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="project-card">
                                <div className="project-card-img">
                                  <img
                                    src={projectBox3}
                                    alt="image logo"
                                    className="card-img"
                                  />
                                </div>
                                <div className="project-card-content">
                                  <h3 className="project-card-title">
                                    Clean Energy Power M...
                                  </h3>
                                  <div className="tooltip-container d-none">
                                    <p className="project-card-des">
                                      Clean Energy model for buildings takes
                                      wind, solar & battery.
                                    </p>
                                    <div className="tooltip-text">Clean Energy model for buildings takes
                                      wind, solar & battery.</div>
                                  </div>
                                  <p className="project-card-source">
                                    Green Hydrogen | 3 scenarios
                                  </p>
                                  <span className="project-card-date">
                                    Updated on: May 4, 2023
                                  </span>
                                </div>
                                <div className="card-dropdown d-none">
                                  {[''].map((direction) => (
                                    <Dropdown
                                      className="create-modal-dropdown three-dot-dropdown"
                                      as={ButtonGroup}
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      variant="secondary"
                                      align="end"
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img
                                          src={threeDotHorizontal}
                                          alt="three dot icon"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                          Options
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          Edit Details
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="text-danger"
                                        >
                                          Remove Project
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="project-card">
                                <div className="project-card-img">
                                  <img
                                    src={projectBox4}
                                    alt="image logo"
                                    className="card-img"
                                  />
                                </div>
                                <div className="project-card-content">
                                  <h3 className="project-card-title">
                                    Green Hydrogen Base...
                                  </h3>
                                  <div className="tooltip-container">
                                    <p className="project-card-des d-none">
                                      Clean Energy model for buildings takes
                                      wind, solar & battery.
                                    </p>
                                    <div className="tooltip-text">Clean Energy model for buildings takes
                                      wind, solar & battery.</div>
                                  </div>
                                  <p className="project-card-source">
                                    Green Hydrogen | 3 scenarios
                                  </p>
                                  <span className="project-card-date">
                                    Updated on: May 4, 2023
                                  </span>
                                </div>
                                <div className="card-dropdown d-none">
                                  {[''].map((direction) => (
                                    <Dropdown
                                      className="create-modal-dropdown three-dot-dropdown"
                                      as={ButtonGroup}
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      variant="secondary"
                                      align="end"
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img
                                          src={threeDotHorizontal}
                                          alt="three dot icon"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                          Options
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          Edit Details
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="text-danger"
                                        >
                                          Remove Project
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="project-card">
                                <div className="project-card-img">
                                  <img
                                    src={projectBox5}
                                    alt="image logo"
                                    className="card-img"
                                  />
                                </div>
                                <div className="project-card-content">
                                  <h3 className="project-card-title">
                                    Green Hydrogen Base...
                                  </h3>
                                  <div className="tooltip-container d-none">
                                    <p className="project-card-des">
                                      Clean Energy model for buildings takes
                                      wind, solar & battery.
                                    </p>
                                    <div className="tooltip-text">Clean Energy model for buildings takes
                                      wind, solar & battery Clean Energy model for buildings takes
                                      wind, solar & batter.</div>
                                  </div>
                                  <p className="project-card-source">
                                    Green Hydrogen | 3 scenarios
                                  </p>
                                  <span className="project-card-date">
                                    Updated on: May 4, 2023
                                  </span>
                                </div>
                                <div className="card-dropdown d-none">
                                  {[''].map((direction) => (
                                    <Dropdown
                                      className="create-modal-dropdown three-dot-dropdown"
                                      as={ButtonGroup}
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      variant="secondary"
                                      align="end"
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img
                                          src={threeDotHorizontal}
                                          alt="three dot icon"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                          Options
                                        </Dropdown.ItemText>
                                        <Dropdown.Item as="button">
                                          Edit Details
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="text-danger"
                                        >
                                          Remove Project
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="objects" title="Objects">
                        <span className="tab-sec-thead">All models (3)</span>
                        <div className="tab-main-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <span className="tab-date">
                                Updated on: May 4, 2023
                              </span>
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Share Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Duplicate Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Edit Model
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Model
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={powerSmallLogo}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen with Battery
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <span className="tab-date">
                                Updated on: May 4, 2023
                              </span>
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Share Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Duplicate Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Edit Model
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Model
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen with Battery
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <span className="tab-date">
                                Updated on: May 4, 2023
                              </span>

                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Share Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Duplicate Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Edit Model
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Model
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                        </div>
                        <span className="tab-sec-thead">
                          Shared with me (2)
                        </span>
                        <div className="tab-main-container shared-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <span className="tab-date">
                                Updated on: May 4, 2023
                              </span>
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Share Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Duplicate Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Edit Model
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Model
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <span className="shared-by">
                                    Shared by Alan Smith
                                  </span>
                                  <h3 className="dropdown-head">
                                    Green Hydrogen with Battery
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <span className="tab-date">
                                Updated on: May 4, 2023
                              </span>
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Share Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Duplicate Model
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Edit Model
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Model
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="baseline scenarios" title="Baseline Scenarios">
                        <div className="tab-main-container">
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon medium">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen Basecase
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              <Button className="status success mr-16">Baseline</Button>
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Scenario
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={powerSmallLogo}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen with Battery
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Scenario
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                          <div className="tab-main-wrapper">
                            <div className="tab-left-part">
                              <div className="dropdown-wrapper shared-profile">
                                <span className="user-icon">
                                  <img
                                    src={hydrogenLogoSmall}
                                    alt="logo"
                                    className="user-img"
                                  />
                                </span>
                                <div className="right-sec">
                                  <h3 className="dropdown-head">
                                    Green Hydrogen with Battery
                                  </h3>
                                  <p className="dropdown-des">
                                    Model/Green Hydrogen
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="tab-right-part">
                              {[''].map((direction) => (
                                <Dropdown
                                  className="create-modal-dropdown three-dot-dropdown"
                                  as={ButtonGroup}
                                  key={direction}
                                  id={`dropdown-button-drop-${direction}`}
                                  variant="secondary"
                                  align="end"
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img
                                      src={threeDotHorizontal}
                                      alt="three dot icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.ItemText>
                                      Options
                                    </Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                      Export JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                    >
                                      Remove Scenario
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllModal;
