import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, ICategoryList, Methods, NUMBER } from '../../../constants';
import { startFullLoading, stopFullLoading } from '../../SpinnerSlice';
import { formatAndRoundOffNumber, getAuthHeaderMultipart, getAuthHeaders, toFormatDate } from '../../../utils';

export const companyManagementApis = createApi({
  reducerPath: 'companyManagementApis',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['GetCompany'],
  endpoints: (builder) => ({
    // api to get all projects...
    getAllCompany: builder.query<any, any>({
      query: () => ({
        url: ApiEndpoints.GET_ADMIN_COMPANY_LIST,
        method: Methods.GET,
        headers: getAuthHeaders()
      }),
      transformResponse: (res: any[]) => {
        return res.reverse().map(user => ({
          label: user.name,
          value: user._id,
          'COMPANY NAME': user.name,
          email: user.email,
          'no. of users': formatAndRoundOffNumber(user.numberOfUsers) || '-',
          projects: formatAndRoundOffNumber(user.numberOfProjects) || '-',
          scenarios: formatAndRoundOffNumber(user['numberOfScenarios ']) || formatAndRoundOffNumber(user.numberOfScenarios) || '-',
          noOfUsers: formatAndRoundOffNumber(user.numberOfUsers) || NUMBER.N0,
          noOfprojects: formatAndRoundOffNumber(user.numberOfProjects) || NUMBER.N0,
          noOfscenarios: formatAndRoundOffNumber(user['numberOfScenarios ']) || formatAndRoundOffNumber(user.numberOfScenarios) || NUMBER.N0,
          noOfsimulations: formatAndRoundOffNumber(user.numberOfSimulationsRan) || NUMBER.N0,
          noOfActiveUser: formatAndRoundOffNumber(user.numberOfActiveUsers) || NUMBER.N0,
          noOfInactiveUser: formatAndRoundOffNumber(user.numberOfInactiveUsers) || NUMBER.N0,
          status: user.status,
          role_permissions: user.role_permissions,
          simulations: formatAndRoundOffNumber(user.numberOfSimulationsRan) || '-',
          imageUrl: user.image_url || '',
          _id: user._id,
          description: user.description,
          accessToProjectType: user.accessToProjectType,
          dateAdded: toFormatDate(user.createDate || '-'),
          accessToObjects: user.accessToObjects
        }));
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetCompany']
    }),
    getPermissions: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_ALL_PERMISSION,
        method: Methods.GET,
        headers: getAuthHeaders()
      }),
      transformResponse: (res: ICategoryList[]) => {
        return res.map(permission => ({
          label: permission,
          value: permission
        }));
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    saveCompany: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.ADD_ADMIN_COMPANY,
        method: Methods.POST,
        body: payload,
        headers: getAuthHeaderMultipart()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetCompany']
    }),
    removeCompany: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.DELETE_COMPANY}/${payload}`,
        method: Methods.DELETE,
        body: payload,
        headers: getAuthHeaders()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetCompany']
    }),
    updateCompanyStatus: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: ApiEndpoints.UPDATE_COMPANY_STATUS,
        method: Methods.POST,
        body: payload,
        headers: getAuthHeaders()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetCompany']
    }),
    editCompany: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.EDIT_COMPANY,
        method: Methods.PUT,
        body: payload,
        headers: getAuthHeaderMultipart()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      invalidatesTags: ['GetCompany']
    }),
    getCompanyUserList: builder.query<any, void>({
      query: (id: any) => ({
        url: `${ApiEndpoints.ADMIN_COMPANY_USER_LIST}?companyID=${id}`,
        method: Methods.GET,
        headers: getAuthHeaders()
      }),
      transformResponse: (res: any[]) => {
        const separatedByRole = res.reverse().reduce((acc, user) => {
          const { firstName = '--', lastName = '--', _id, status = 'active', role = 'consumer', userName } = user;
          const fullName = `${firstName} ${lastName}`;
          if (!acc[role]) {
            acc[role] = [];
          }
          acc[role].push({ name: fullName, email: userName, _id, status, role, imageUrl: user.image_url || '' });
          return acc;
        }, {});
        const totalCount = res.filter((user: any) => user.role !== 'admin').length;
        return { separatedByRole, totalCount };
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    getObjectList: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_OBJECT_LIST,
        method: Methods.GET,
        headers: getAuthHeaders()
      }),
      transformResponse: (res: any[]) => {
        // get the list of array of category name...
        return res.reverse().reduce((acc, user) => {
          const {
            category,
            componentDisplayName
          } = user;
          if (!acc[category[0]]) {
            acc[category[0]] = [];
          }
          acc[category[0]].push({
            value: componentDisplayName,
            label: componentDisplayName
          });
          return acc;
        }, {});
      }
    }),
    getDefaultPermissions: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_DEFAULT_PERMISSION,
        method: Methods.GET,
        headers: getAuthHeaders()
      })
    }),
    updateObject: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.ADD_ADMIN_COMPANY_OBJECT_ACCESS,
        method: Methods.POST,
        body: payload,
        headers: getAuthHeaders()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    saveObject: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.ADMIN_CREATE_NEW_OBJECT,
        method: Methods.POST,
        body: payload,
        headers: getAuthHeaderMultipart()
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    saveUserData: builder.mutation<any, any>({
      query: (formData: FormData) => ({
        url: ApiEndpoints.SAVE_USER_DATA,
        method: Methods.POST,
        body: formData,
        headers: getAuthHeaderMultipart()
      })
    })
  })
});

export const {
  useGetAllCompanyQuery,
  useSaveCompanyMutation,
  useGetPermissionsQuery,
  useRemoveCompanyMutation,
  useUpdateCompanyStatusMutation,
  useEditCompanyMutation,
  useLazyGetCompanyUserListQuery,
  useLazyGetAllCompanyQuery,
  useGetObjectListQuery,
  useGetDefaultPermissionsQuery,
  useUpdateObjectMutation,
  useSaveObjectMutation,
  useSaveUserDataMutation
} = companyManagementApis;
