import React from 'react';
import GoogleMapComponent from './GoogleMapComponent';

const MapView = ({ financialData, selectedScenarioList, projectType }: any) => {
  return (
    <GoogleMapComponent financialData={financialData} selectedScenarioList={selectedScenarioList} projectType={projectType} />
  );
};

export default MapView;
