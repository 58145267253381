import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { colourOptions } from '../../utils/defaultData';
import Select from "react-select";
import CustomSelect from "./CustomSelect";

function RadioCheckBox() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  const colourOptions = [
    // { value: 'select', label: 'Select', isDisabled: false },
    { value: 'KWH', label: 'KWH' },
    { value: 'Percentage (%)', label: 'Percentage (%)' },
    { value: 'Dollars ($)', label: 'Dollars ($)' },

  ];

  return (
    <div className="custom-select-main select-radio-grey grey-border-select">
      <Form.Label>Unit</Form.Label>
      <Select
        className="custom-select-wrp"
        classNamePrefix="select"
        defaultValue={colourOptions[1]}
        // isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={colourOptions}
        placeholder="User Role"
      // menuIsOpen={true}
      />
    </div>
  );
}
export default RadioCheckBox;
