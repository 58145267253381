import React, { useEffect, useState } from 'react';
import UserCompanyModal from '../company/UserCompanyModal';
import Table from '../../table/Table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateSingleUser, updateTotalUserData } from '../../../redux/slices/adminSlice';
import { userColumns, userSortableFields } from '../../../constants/admin.static';
import { useGetAllUsersQuery, useRemoveUserMutation, useUpdateUserStatusMutation } from '../../../redux/services/admin/userManagementApis';
import { toast } from 'react-toastify';
import { LABELS, MESSAGES } from '../../../constants';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import { statusTextButton, statusTextTitle } from '../../../utils';
import ModalHoc from '../shared/ModalHoc';

const UserDashboardTable = () => {
  const [ShowUserCompany, setShowUserCompany] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showStatusAlert, setShowStatusAlert] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);
  const { userSearch, singleUser } = useAppSelector(state => state.adminSlice);
  const [removeUser] = useRemoveUserMutation();
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const { data, isLoading } = useGetAllUsersQuery('', { refetchOnMountOrArgChange: true });
  const dispatch = useAppDispatch();

  useEffect(() => {
    data && setFilterData(data.filter((item: any) => {
      const search = userSearch?.toLowerCase();
      return (
        item.name.toLowerCase()?.includes(search) ||
        item.email.toLowerCase()?.includes(search) ||
        item.company.toLowerCase()?.includes(search)
      );
    }));

    return () => {
      setFilterData(data);
    };
  }, [userSearch]);

  useEffect(() => {
    if (data) {
      setFilterData(data);
      dispatch(updateTotalUserData(data.length));
      if (ShowUserCompany && singleUser) {
        const newData = data.find((item: any) => item.userId === singleUser.userId);
        handleUserData(newData);
      }
    }
  }, [isLoading, data]);

  if (isLoading) {
    return null;
  }

  const handleUserData = (datas: any) => {
    dispatch(updateSingleUser(datas));
  };

  const handleUserStatus = () => {
    hideStatusConfirmationAlert();
    const formData = {
      userId: singleUser.email,
      flag: singleUser.status === 'active' ? 'deactivate' : 'activate'
    };
    updateUserStatus(formData).unwrap().then((res: any) => {
      setShowUserCompany(false);
      toast.success(`${res.message}!`);
    }).catch((err) => {
      const errMessage = err.detail?.includes('Not allowed') ? MESSAGES.USER_ACTIVATE_FAILED : err.details;
      err && toast.error(errMessage);
    });
  };

  const handleDeleteUser = () => {
    hideConfirmationAlert();
    removeUser(singleUser.email).unwrap().then((res: any) => {
      setShowUserCompany(false);
      toast.success(MESSAGES.USER_DELETED_SUCCESS);
    }).catch((err) => {
      err && toast.error(err.detail || MESSAGES.SOMETHING_WENT_WRONG);
    });
  };

  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const showStatusConfirmationAlert = () => {
    setShowStatusAlert(true);
  };

  const hideStatusConfirmationAlert = () => {
    setShowStatusAlert(false);
  };

  return (
    <>
      <Table
        data={filterData}
        sortableFields={userSortableFields}
        columns={userColumns}
        setShowUserCompany={setShowUserCompany}
        handleUserData={handleUserData}
        handleUserStatus={handleUserStatus}
        handleDeleteUser={handleDeleteUser}
        showConfirmationAlert={showConfirmationAlert}
        showStatusConfirmationAlert={showStatusConfirmationAlert}
        tableClass='admin-table-container'
        userSearch={userSearch}
        tableType={'users'}
      />
      <UserCompanyModal
        ShowUserCompany={ShowUserCompany}
        setShowUserCompany={setShowUserCompany}
        handleUserStatus={showStatusConfirmationAlert}
        handleDeleteUser={showConfirmationAlert} />
      <ModalHoc
        showModal={showAlert} setShowModal={hideConfirmationAlert}
      >
        <ConfirmationAlert
          showAlert={showAlert}
          title={LABELS.DELETE_USER}
          _id={singleUser.userId}
          message={MESSAGES.DELETE_USER_MESSAGE}
          cancleBtnText={LABELS.NOT_NOW}
          yesBtnText={LABELS.YES_DELETE}
          onConfirm={handleDeleteUser}
          onCancel={hideConfirmationAlert}
        />
      </ModalHoc>

      <ModalHoc
        showModal={showStatusAlert} setShowModal={hideStatusConfirmationAlert}
      >
        <ConfirmationAlert
          showAlert={showStatusAlert}
          title={statusTextTitle(singleUser.status)}
          _id={singleUser.userId}
          message={singleUser.status === 'active' ? MESSAGES.DEACTIVATE_USER_STATUS_MESSAGE : MESSAGES.ACTIVATE_USER_STATUS_MESSAGE}
          cancleBtnText={LABELS.NOT_NOW}
          yesBtnText={statusTextButton(singleUser.status)}
          onConfirm={handleUserStatus}
          onCancel={hideStatusConfirmationAlert}
        />
      </ModalHoc>
    </>
  );
};

export default UserDashboardTable;
