import React, { useEffect, useRef, useState } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { Button, Form, Spinner } from 'react-bootstrap';
import { commentSendArrow } from '../../utils';
import { useAppSelector } from '../../redux/hooks';
import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { useEditMapCommentsMutation, useRemoveMapCommentsMutation, useSaveMapCommentDataMutation } from '../../redux/services/workbenchApis';
import { toast } from 'react-toastify';
import { MESSAGES, NUMBER } from '../../constants';
import CommentDropdownMenu from '../shared/CommenDropDownMenu';

const CustomMarker = ({ lat, lng, onClick, data, isAddComment, selectedCommentMarker, scenarioId, commentUpdates, setCommentUpdates, handleAddFirstComment }: any) => {
  const [saveMapCommentData, { isLoading }] = useSaveMapCommentDataMutation();
  const [deleteMapComment, { isLoading: deleteLoading }] = useRemoveMapCommentsMutation();
  const [editMapComment, { isLoading: editLoading }] = useEditMapCommentsMutation();
  const currentUserEmail = useAppSelector((state) => state.auth.email);
  const inputRef = useRef<HTMLInputElement>(null);
  const [doubleClick, setDoubleClick] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [activeId, setActiveId] = useState('');
  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / NUMBER.N2),
    y: -(height / NUMBER.N2)
  });

  const saveCommentDataPostCall = (
    values: { comment: string },
    { resetForm }: FormikHelpers<{ comment: string }>
  ) => {
    const payload = {
      commentText: values.comment,
      commentType: 'location',
      commentid: isAddComment ? `${lat},${lng}` : selectedCommentMarker?.id,
      componentName: 'ev',
      subProjectID: scenarioId
    };
    if (values.comment.trim() !== '') {
      saveMapCommentData(payload).then((res) => {
        if ('data' in res) {
          if (isAddComment) {
            handleAddFirstComment(payload);
          }
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
        resetForm();
        setCommentUpdates(!commentUpdates);
      });
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (onClick) {
      onClick(data);
    }
  };
  const handleHover = (event: any) => {
    event.stopPropagation();
  };

  const handleDoubleClick = (event: any) => {
    event.stopPropagation();
    setDoubleClick(true); // Set doubleClick state to true on double-click
  };

  useEffect(() => {
    if (doubleClick && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, NUMBER.N100);
      setDoubleClick(false);
    }
  }, [doubleClick]);

  // Delete a specific comment
  const deleteComment = (id: string) => {
    setActiveId(id);
    deleteMapComment(id).then((res) => {
      if ('data' in res) {
        setCommentUpdates(!commentUpdates);
        setEditComment(false);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
    });
  };

  // Edit a specific comment
  const editComments = (
    values: { comment: string }, id: any
  ) => {
    const payload = {
      commentText: values.comment,
      id
    };
    if (values.comment.trim() !== '') {
      editMapComment(payload).then((res) => {
        if ('data' in res) {
          setCommentUpdates(!commentUpdates);
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
        setEditComment(false);
      });
    }
  };

  const renderEditComment = (id: string) => {
    setEditComment(true);
    setActiveId(id);
  };

  const renderComment = (entity: any) => {
    const timeString = moment(entity?.registerDate).utc(true).fromNow();
    return (
      <div key={`${entity.id}${Math.random()}`}>
        <div className="dropdown-wrapper shared-profile profile-icon-wrap">
          <span className="profile-icon medium orange">
            {entity.registerUser.charAt(0)}
          </span>
          <div className="profile-comment-wrap">
            <span className="comment-user-name">{entity.registerUser}</span>
            <span className="comment-date">{timeString}
              {((deleteLoading) && activeId === entity.id) && <Spinner className="blue-spinner" />}
            </span>
          </div>
          <div className='card-dropdown comment-dropdown-wrap'>
            {entity.registerUser === currentUserEmail && <CommentDropdownMenu entity={entity} renderEditComment={renderEditComment} deleteComment={deleteComment} />}
          </div>
        </div>
        {!editComment && <p className="comment-des">{entity.commentText}</p>}
        {editComment && activeId !== entity.id && <p className="comment-des">{entity.commentText}</p>}
        {editComment && activeId === entity.id && renderFormik(entity.commentText, entity.id)}
      </div>
    );
  };

  const renderFormik = (text?: string, id?: string) => (
    <Formik
      initialValues={{ comment: text ?? '' }}
      onSubmit={text ? (e: any) => editComments(e, id) : saveCommentDataPostCall}
    >
      {({ handleChange, handleBlur, values, handleSubmit }) => (
        <Form className="simulation-form" onSubmit={handleSubmit}>
          <Form.Group className={`form-group ${id ? 'edit-input' : ''}`} controlId="formBasicEmail"
            ref={(ref: any) => ref && google?.maps?.OverlayView && google.maps.OverlayView.preventMapHitsFrom(ref)}
          >
            <Form.Control type="text" name="comment" placeholder="Type here" autoComplete="false"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
              onMouseOver={(event) => handleHover(event)} onDoubleClick={(event) => handleDoubleClick(event)}
              onClick={(event) => {
                handleDoubleClick(event);
              }}
              onBlur={handleBlur} value={values.comment} ref={inputRef} autoFocus
            />
            {(isLoading || editLoading) ? (<div className="comment-send-btn"><Spinner className="blue-spinner" /></div>) : (
              <Button type="submit" className="primary comment-send-btn">
                <img src={commentSendArrow} alt="logo" />
              </Button>
            )}
          </Form.Group>
        </Form>
      )}
    </Formik>
  );

  return (
    <OverlayView position={{ lat, lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset} >
      <>
        {data?.comments?.length && (
          <div
            style={{ transform: 'translate(-50%, -50%)' }}
            className='ev-comment-box'
            onMouseEnter={(event) => handleHover(event)}
            onWheel={(event) => handleHover(event)}
            onClick={(event) => handleClick(event)}
            onDoubleClick={(event) => handleClick(event)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
              }
            }}
          >
            <div className="comment-small-wrap">
              <span className="profile-icon medium orange">{data?.comments[0].registerUser.charAt(0)}</span>
            </div>
          </div>
        )}
        {(isAddComment || (selectedCommentMarker?.comments && data.id === selectedCommentMarker.id)) && (
          <div
            className="hover-box-wrap comment-box-wrap ev-comment-box default-text-comment-wrap"
            tabIndex={NUMBER.N0}
            ref={(ref) => ref && google?.maps?.OverlayView && google.maps.OverlayView.preventMapHitsFrom(ref)
            }
          >
            <div className="hover-box" onWheel={(event) => handleHover(event)}>
              {selectedCommentMarker?.comments?.map((entity: any) => renderComment(entity))}
              {((selectedCommentMarker != null && !selectedCommentMarker?.comments) || isAddComment) && (
                <div className="dropdown-wrapper shared-profile profile-icon-wrap default-text-comment">
                  <div className="profile-comment-wrap">
                    <span className="profile-icon medium orange"> {currentUserEmail.charAt(NUMBER.N0)} </span>
                    <span className="comment-user-name"> Add a comment </span>
                  </div>
                </div>
              )}
              {!editComment && renderFormik()}
            </div>
          </div>
        )}
      </>
    </OverlayView>
  );
};

export default CustomMarker;
