import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  Autocomplete,
  useJsApiLoader,
  Libraries
} from '@react-google-maps/api';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  evMapDefault,
  mapBlueMarker,
  mapGreenMarker,
  searchSmallLogo,
  mapPOIMarker,
  commentsLogo,
  commentsOrangeLogo,
  messageCursor,
  aadtLogo,
  formatNumberToLocale,
  poiInfoIcon,
  aadtInfoIcon,
  searchLogo,
  aadtDetailIcon
} from '../../utils';
import {
  NUMBER,
  MIN_MILE,
  MAX_MILE,
  IEVSimulation,
  ISimulationLocation,
  IEVMarker,
  AADT,
  POI,
  SLIDER_STEP,
  ICommentData,
  grayStylesMap,
  IScenarioDetails,
  SHARED_SCENARIO_PERMISSION,
  IMapIconPaths,
  COMPETITOR_STATION,
  Metric
} from '../../constants';
import EvChargingStationInfo from './EvChargingStationInfo';
import CustomMarker from '../../components/workbench/CustomMarker';
const apiKey = process.env.REACT_APP_MAP_KEY;
interface BPPulseStationsMapProps {
  markerClickHandler: (marker: any) => void
  infoWindowCloseHandler: () => void
  sliderChangeHandler: (value: any) => void
  searchHandler: () => void
  maker: any | null
  radiusSlider: number
  radiusMap: number
  simulationData: IEVSimulation | null
  centerLocation: Coordinates
  showComment: boolean
  commentToggleHandler: () => void
  scenarioId: string
  mapComments: ICommentData[]
  commentUpdates: boolean
  setCommentUpdates: any
  currentEVData: Metric | null
  chargingStationCountMetricData: Metric | null
  avgWeekDemandData: Metric | null
  scenarioDetails: IScenarioDetails
  clickedCoordinates?: any
  clickedCoordinatesHandler?: any
  updateCoordinates?: any
  commentCount: any
  clickedMarker: any
  setClickedMarker: any
  setClickedCoordinates: any
}

interface Coordinates {
  lat: number
  lng: number
}

const BPPulseStationsMapForScratch: React.FC<BPPulseStationsMapProps> = ({
  maker,
  markerClickHandler,
  infoWindowCloseHandler,
  sliderChangeHandler,
  searchHandler,
  radiusSlider,
  radiusMap,
  simulationData,
  centerLocation,
  showComment,
  commentToggleHandler,
  scenarioId,
  mapComments,
  commentUpdates,
  setCommentUpdates,
  currentEVData,
  chargingStationCountMetricData,
  avgWeekDemandData,
  scenarioDetails,
  clickedCoordinatesHandler,
  clickedCoordinates,
  updateCoordinates,
  commentCount,
  clickedMarker,
  setClickedMarker,
  setClickedCoordinates

}) => {
  const libraries: Libraries = ['places'];

  const loaderOptions = useMemo(() => ({
    id: 'google-map-script',
    googleMapsApiKey: apiKey as string,
    libraries
  }), [apiKey]);


  const { isLoaded, loadError } = useJsApiLoader(loaderOptions);

  const [mapOptions, setMapOptions] = useState({
    draggableCursor: showComment ? `url(${messageCursor}), pointer` : 'grab'
  });
  const [mapMarkerData, setMapMarkerData] = useState<IEVMarker[] | null>(null);
  const [addComment, setAddComment] = useState<any | null>(null);
  const [selectedCommentMarker, setSelectedCommentMarker] = useState<any | null>(null);
  const [searchedPlace, setSearchedPlace] = useState<any>(null);
  const [searchPlaceInput, setSearchPlaceInput] = useState('');

  const [mapZoom, setMapZoom] = useState<number>(NUMBER.N15);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<Coordinates>({
    lat: centerLocation.lat,
    lng: centerLocation.lng
  });

  const [circleCenter, setCircleCenter] = useState<google.maps.LatLngLiteral | null | undefined>(null);
  const [proposedLocation, setProposedLocation] = useState<any>({
    lat: centerLocation.lat,
    lng: centerLocation.lng
  });

  const [proposedData, setProposedData] = useState<any>(null);
  const [iconPaths, setIconPaths] = useState<IMapIconPaths>({
    aadt: aadtDetailIcon,
    poi: mapPOIMarker,
    blueMarker: mapBlueMarker,
    greenMarker: mapGreenMarker,
    searchSmallLogo,
    commentsLogo,
    commentsOrangeLogo,
    messageCursor,
    evMapDefault,
    poiInfoIcon,
    aadtInfoIcon
  });

  const markerPosition =
    centerLocation && typeof centerLocation.lat === 'number' && typeof centerLocation.lng === 'number'
      ? { lat: centerLocation.lat, lng: centerLocation.lng }
      : undefined;

  useEffect(() => {
    setMapOptions((prevOptions) => ({
      ...prevOptions,
      styles: grayStylesMap,
      draggableCursor: showComment ? `url(${messageCursor}), pointer` : 'grab'
    }));
  }, [showComment, messageCursor, selectedCommentMarker]);

  useEffect(() => {
    if (centerLocation.lat !== null && centerLocation.lng !== null) {
      setMapCenter({
        lat: Number(centerLocation.lat),
        lng: Number(centerLocation.lng)
      });
      setCircleCenter(
        centerLocation.lat !== null && centerLocation.lng !== null
          ? {
            lat: Number(centerLocation.lat),
            lng: Number(centerLocation.lng)
          }
          : undefined
      );
    } else {
      setMapCenter({ lat: 0, lng: 0 });
      setCircleCenter(null);
    }
  }, [centerLocation]);

  const mapStyles = {
    height: '600px',
    width: '100%'
  };

  const getMarkerIcon = (locationType: string) => {
    if (locationType === AADT) {
      return aadtLogo;
    } else if (locationType === POI) {
      return mapPOIMarker;
    } else if (locationType === COMPETITOR_STATION) {
      return iconPaths.blueMarker;
    } else {
      return mapBlueMarker;
    }
  };

  const getInfoMarker = (locationType: string) => {
    if (locationType === AADT) {
      return iconPaths.aadt;
    } else if (locationType === POI) {
      return iconPaths.poiInfoIcon;
    } else if (locationType === COMPETITOR_STATION) {
      return iconPaths.aadtInfoIcon;
    } else {
      return iconPaths.evMapDefault;
    }
  };

  const getStationWrapClass = (makerData: any) => {
    if (makerData?.type === POI) {
      return 'aadt-station-wrap';
    } else if (makerData?.type === 'PROPOSED') {
      return 'proposed-station-wrap';
    } else if (makerData?.type === AADT) {
      return 'poi-station-wrap';
    } else {
      return '';
    }
  };

  useEffect(() => {
    setCircleCenter(null);
  }, []);

  useEffect(() => {
    const poisData: IEVMarker[] = (simulationData?.POIs ?? []).map((poi) => ({
      name: poi?.name,
      lat: poi?.location?.lat,
      lng: poi?.location?.lng,
      address: poi?.vicinity,
      type: POI,
      bidirectional_aadt: null,
      level1_charger_count: null,
      level2_charger_count: null,
      dc_fast_charger_count: null

    }));

    const aadtData: IEVMarker[] = (simulationData?.AADT ?? []).map((aadt) => ({
      name: aadt?.properties?.name,
      lat: aadt?.geometry?.coordinates[NUMBER.N1],
      lng: aadt?.geometry?.coordinates[NUMBER.N0],
      address: aadt?.properties?.state,
      type: AADT,
      bidirectional_aadt: aadt?.properties?.bidirectional_aadt,
      level1_charger_count: null,
      level2_charger_count: null,
      dc_fast_charger_count: null
    }));

    const competitorData: IEVMarker[] = (simulationData?.competitor_stations ?? []).map((competitorStation) => ({
      name: competitorStation.address,
      lat: competitorStation.latitude,
      lng: competitorStation.longitude,
      address: competitorStation.state,
      type: COMPETITOR_STATION,
      bidirectional_aadt: null,
      level1_charger_count: competitorStation?.level1_charger_count,
      level2_charger_count: competitorStation?.level2_charger_count,
      dc_fast_charger_count: competitorStation?.dc_fast_charger_count
    }));

    const combinedData: IEVMarker[] = [...poisData, ...aadtData, ...competitorData];
    const proposedLat = simulationData?.proposed_station?.lat ?? centerLocation?.lat ?? NUMBER.N0;
    const proposedLng = simulationData?.proposed_station?.lng ?? centerLocation?.lng ?? NUMBER.N0;
    const proposedMarkerData = {
      name: 'BP Pulse Proposed Station',
      lat: proposedLat,
      lng: proposedLng,
      address: simulationData?.proposed_station?.address ?? '',
      type: 'PROPOSED',
      bidirectional_aadt: null,
      level1_charger_count: null,
      level2_charger_count: null,
      dc_fast_charger_count: null
    };
    setProposedLocation({ lat: proposedLat, lng: proposedLng });
    setProposedData(proposedMarkerData);
    setMapMarkerData(combinedData);
    setMapCenter(proposedMarkerData);
  }, [simulationData]);

  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName('gm-ui-hover-effect');
      if (maker) {
        for (let i = NUMBER.N0; i < elements.length; i++) {
          elements[i].classList.add('default-close-btn');
        }
      } else {
        if (elements.length > NUMBER.N0) {
          elements[NUMBER.N0].classList.remove('default-close-btn');
        }
      }
    }, NUMBER.N100);
  }, [maker]);

  useEffect(() => {
    if (selectedCommentMarker) {
      const foundObject = mapComments.find((comment) => comment.id === selectedCommentMarker.id);
      setSelectedCommentMarker(foundObject);
    }
  }, [mapComments]);

  const handleAddFirstComment = (data: any) => {
    setAddComment(false);
    setSelectedCommentMarker(null);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event?.latLng?.lat() ?? null;
    const lng = event?.latLng?.lng() ?? null;

    if (lat !== null && lng !== null) {
      updateCoordinates(lat, lng);

      setClickedMarker({ lat, lng });
    }

    if (showComment) {
      event.stop();
      setSelectedCommentMarker(null);
      if (addComment) {
        setAddComment(false);
      } else {
        const addComment = {
          commentType: 'location',
          commentid: `${lat},${lng}`,
          componentName: 'other',
          subProjectID: scenarioId,
          lat,
          lng
        };
        setAddComment(addComment);
      }
    }
  };

  const handleMarkerClick = (data: any) => {
    setAddComment(false);
    if (selectedCommentMarker) {
      setSelectedCommentMarker(null);
    } else {
      setSelectedCommentMarker(data);
    }
  };
  const toggleComment = () => {
    if (showComment) {
      setSelectedCommentMarker(null);
    }
    commentToggleHandler();
  };

  if (loadError) {
    return <div>Error loading Google Maps API: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <div className="simulation-chart-wrapper ev-scratch-simulation-chart-wrap">
      <div className="chart-left ev-simulation-map-left p-0">
        <div className="comment-msg-wrap" >
          <div className="tooltip-container" onClick={toggleComment}>
            <button
              type="button"
              className="setting-btn btn-no-outline btn btn-primary"
            >
              <div className="circle-logo-wrapper">
                <span className="svg-icon">
                  <img
                    src={showComment ? commentsOrangeLogo : commentsLogo}
                    alt="logo"
                  />
                </span>
                {commentCount > NUMBER.N0 && <div className='comment-count-wrap'>
                  <span className="comment-count">{commentCount}</span>
                </div>}
              </div>
            </button>
            <div className="tooltip-text">Comment</div>
          </div>
        </div>

        {isLoaded && (
          <>
            <Autocomplete className='place-search-box'
              onLoad={(autocomplete) => {
                setSearchedPlace(autocomplete);
              }}
              onPlaceChanged={() => {
                if (searchedPlace) {
                  const place = searchedPlace.getPlace();
                  if (place.geometry?.location) {
                    const selectedLat = place.geometry.location.lat();
                    const selectedLng = place.geometry.location.lng();

                    setMapCenter({
                      lat: parseFloat(selectedLat),
                      lng: parseFloat(selectedLng)
                    });
                    setClickedCoordinates({
                      lat: parseFloat(selectedLat),
                      lng: parseFloat(selectedLng)
                    });
                  }
                }
                setSearchPlaceInput('');
              }}

            >
              <>
                <input type="text" placeholder="Search by Name or ZIP Code" value={searchPlaceInput} onChange={(e) => setSearchPlaceInput(e.target.value)}
                />
                <img src={searchLogo} alt="logo" className="search-logo" />
              </>
            </Autocomplete>
            <GoogleMap
              onLoad={(map) => {
                mapRef.current = map;
              }}
              mapContainerStyle={mapStyles}
              zoom={mapZoom}
              center={mapCenter
                ? {
                  lat: typeof mapCenter.lat === 'number' ? mapCenter.lat : clickedCoordinates.lat,
                  lng: typeof mapCenter.lng === 'number' ? mapCenter.lng : clickedCoordinates.lng
                }
                : undefined}
              onClick={handleMapClick}
              options={mapOptions}
              onDragEnd={() => {
                if (mapRef.current) {
                  const center = mapRef.current.getCenter();
                  if (center) {
                    setMapCenter({
                      lat: center.lat(),
                      lng: center.lng()
                    });
                  }
                }
              }}
              onZoomChanged={() => {
                if (mapRef.current) {
                  const zoom = mapRef.current.getZoom();
                  if (zoom !== undefined) {
                    setMapZoom(zoom);
                  }
                }
              }}
            >
              <Marker
                position={proposedLocation}
                title={proposedData?.address ?? 'BP Pulse Proposed Station'}
                icon={{
                  url: iconPaths.greenMarker
                  // origin: new google.maps.Point(NUMBER.N0, NUMBER.N0),
                  // anchor: new google.maps.Point(NUMBER.N40, NUMBER.N40)
                }}
                onClick={() => {
                  if (!showComment) {
                    markerClickHandler(proposedData);
                  }
                }}
                zIndex={NUMBER.N1000}
              ></Marker>

              {showComment && addComment && (
                <CustomMarker
                  key={1}
                  lat={addComment.lat}
                  lng={addComment.lng}
                  onClick={handleMarkerClick} // Pass the click event handler
                  isAddComment={true}
                  scenarioId={scenarioId}
                  commentUpdates={commentUpdates}
                  setCommentUpdates={setCommentUpdates}
                  handleAddFirstComment={handleAddFirstComment}
                />
              )}

              {showComment &&
                mapComments?.map((data: any, index: any) => {
                  const splitCoordinates = data.id.split(',');
                  return (
                    <CustomMarker
                      key={index}
                      lat={splitCoordinates[NUMBER.N0]}
                      lng={splitCoordinates[NUMBER.N1]}
                      onClick={handleMarkerClick} // Pass the click event handler
                      data={data}
                      isAddComment={false}
                      selectedCommentMarker={selectedCommentMarker}
                      scenarioId={scenarioId}
                      commentUpdates={commentUpdates}
                      setCommentUpdates={setCommentUpdates}
                    />
                  );
                })}

              {mapMarkerData?.map((data, index) => {
                return (
                  <Marker
                    key={`${index}-${data?.name}`}
                    position={{
                      lat: data?.lat,
                      lng: data?.lng
                    }}
                    title={data?.name}
                    icon={{
                      url: getMarkerIcon(data?.type),
                      scale: NUMBER.N7
                    }}
                    onClick={() => {
                      if (!showComment) {
                        markerClickHandler(data);
                      }
                    }}
                  />
                );
              })}

              {!showComment && maker && (
                <InfoWindow
                  position={{
                    lat: maker?.lat,
                    lng: maker?.lng
                  }}
                  onCloseClick={infoWindowCloseHandler}
                >
                  <div className={`map-tooltip-wrap ${getStationWrapClass(maker)}`}>
                    <span className="map-tooltip-logo-wrap">
                      <img
                        src={getInfoMarker(maker?.type)}
                        alt="logo"
                        className="map-tooltip-logo"
                      />
                    </span>
                    <h3 className="map-tooltip-title">{maker?.name}</h3>
                    <span className="map-tooltip-des">{maker?.address}</span>
                    {maker?.type === AADT && (
                      <>
                        <div className="map-traffic-wrap">
                          <span className="map-traffic-des">Traffic:</span>
                          <span className="map-traffic-revenue">
                            {`${formatNumberToLocale(maker?.bidirectional_aadt)}/day`}
                          </span>
                        </div>
                        <div className="map-traffic-wrap">
                          {/* <span className="map-traffic-des">Revenue:</span>
                          <span className="map-traffic-revenue">
                            ${NUMBER.N0}/day
                          </span> */}
                        </div>
                      </>
                    )}

                    {maker?.type === COMPETITOR_STATION && (
                      <>
                        {maker?.level1_charger_count && <div className="map-traffic-wrap">
                          <span className="map-traffic-des">Level 1 Charger Count:</span>
                          <span className="map-traffic-revenue">
                            {`${formatNumberToLocale(maker?.level1_charger_count)}`}
                          </span>
                        </div>
                        }
                        {maker?.level2_charger_count && <div className="map-traffic-wrap">
                          <span className="map-traffic-des">Level 2 Charger Count:</span>
                          <span className="map-traffic-revenue">
                            {`${formatNumberToLocale(maker?.level2_charger_count)}`}
                          </span>
                        </div>
                        }

                        {maker?.dc_fast_charger_count && <div className="map-traffic-wrap">
                          <span className="map-traffic-des">DC Fast Charger Count:</span>
                          <span className="map-traffic-revenue">
                            {`${formatNumberToLocale(maker?.dc_fast_charger_count)}`}
                          </span>
                        </div>
                        }
                        <div className="map-traffic-wrap">
                          {/* <span className="map-traffic-des">Revenue:</span>
                          <span className="map-traffic-revenue">
                            ${NUMBER.N0}/day
                          </span> */}
                        </div>
                      </>
                    )}

                  </div>
                </InfoWindow>
              )}
              <Circle
                center={proposedLocation}
                editable={false}
                radius={radiusMap}
                options={{
                  strokeColor: '#379777',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: 'rgba(55, 151, 119, 0.04)',
                  fillOpacity: 0.35,
                  clickable: false
                }}
              />
              {clickedMarker && (
                <Marker
                  position={clickedMarker}
                  title="Clicked Location"
                />
              )}
            </GoogleMap>
          </>
        )}
      </div>
      <div className="chart-right">
        <div className="chart-right-inner ev-chart-right">
          <div className="slider-wrap">
            <div className="simulation-form-wrap">
              <Form className="simulation-form">
                <div className="simulation-inner-wrap">
                  <div className="slider-inner-sec divider-border-ev-scratch">
                    <div className="pulse-stations-location">
                      {/* <span className="graph-right-head">Location</span> */}
                      <div className='pulse-stations-latitude-container'> <span className='form-label'> Latitude</span>
                        <Form.Group
                          className="form-group pulse-stations-latitude"
                          controlId="formBasicEmail"
                        >
                          <Form.Control

                            type="number"
                            name="lat"
                            value={clickedCoordinates?.lat !== null
                              ? clickedCoordinates?.lat
                              : ''}
                            onChange={clickedCoordinatesHandler}
                            min={-90}
                            max={90}
                          />
                        </Form.Group></div>
                      <div className='pulse-stations-longitude-container'>  <span className='form-label'> Longitude</span>
                        <Form.Group
                          className="form-group pulse-stations-longitude"
                          controlId="formBasicEmail"
                        >
                          <Form.Control

                            type="number"
                            name="lng"
                            value={clickedCoordinates?.lng !== null
                              ? clickedCoordinates?.lng
                              : ''}
                            min={-180}
                            max={180}
                            onChange={clickedCoordinatesHandler}
                          />
                        </Form.Group></div>
                    </div>
                    <div className="slider-wrap-inner">
                      <span className="slider-radius">
                        {`Radius ${radiusSlider} mi `}
                      </span>
                      <div className="star-progress-bar">
                        <Slider
                          min={MIN_MILE}
                          max={MAX_MILE}
                          value={radiusSlider}
                          step={SLIDER_STEP}
                          disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                          onChange={sliderChangeHandler}
                        />
                      </div>
                    </div>
                    <div className="simulate-btn-wrap search-btn-wrap">
                      <Button className="primary" onClick={searchHandler} disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}>
                        <img
                          src={searchSmallLogo}
                          alt="logo"
                          className="search-small-logo"
                        />
                        Search
                      </Button>
                    </div>
                  </div>
                  <EvChargingStationInfo currentEVData={currentEVData}
                    chargingStationCountMetricData={chargingStationCountMetricData}
                    avgWeekDemandData={avgWeekDemandData} />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BPPulseStationsMapForScratch;

