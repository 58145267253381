import React from 'react';
import './assets/style.scss';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Routes';
import { Amplify } from 'aws-amplify';
import FullPageLoader from './components/shared/FullPageLoader';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import moment from 'moment';
import { useLazyGetUserAdminDataQuery } from './redux/services/authApis';
import { updateAdminUser } from './redux/auth/authReducer';
import { NUMBER } from './constants';

function App() {
  const fullPageLoading = useAppSelector(state => state.spinner.fullPageLoading);
  const title = useAppSelector(state => state.spinner.loaderTitle);
  const { user_id } = useAppSelector(state => state.auth);
  const [getUserProfileData] = useLazyGetUserAdminDataQuery();
  const dispatch = useAppDispatch();

  moment.updateLocale('en', {
    relativeTime: {
      future: (diff) => (diff === 'Just now' ? diff : `in ${diff}`),
      past: (diff) => (diff === 'Just now' ? diff : `${diff} ago`),
      s: 'Just now',
      ss: 'Just now'
    }
  });

  // We will move these open keys values in env file when we finally merge Sprint-1 in dev.
  const amplifyConfig = {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_Id,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE
    }
  };

  React.useEffect((): void => {
    Amplify.configure(amplifyConfig);
    setTimeout(() => {
      user_id && getUserProfileData(true).then((res) => {
        if ('data' in res) {
          dispatch(updateAdminUser(res.data));
        }
      });
    }, NUMBER.N2000);
  }, []);

  return (
    <BrowserRouter >
      <AppRoutes />
      <FullPageLoader isLoading={fullPageLoading} title={title} />
    </BrowserRouter>
  );
}

export default App;
