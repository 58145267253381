import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { logoDark } from '../../utils/icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { AWS_CONGNITO_ERRORS, IResetPassword, MESSAGES, NUMBER } from '../../constants';
import { startFullLoading, startLoading, stopFullLoading, stopLoading } from '../../redux/SpinnerSlice';
import { forgotPassword, resetPassword } from '../../redux/auth/authReducer';
import { toast } from 'react-toastify';
import { RESET_PASSWORD, RESET_PASS_VALIDATION_SCHEMA, hasFieldError } from '../../utils';
import { Formik } from 'formik';
import InputElement from '../../components/shared/InputElement';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [validateOnChangeBlur, setValidateOnChangeBlur] = useState(false);
  const loading = useAppSelector((state) => state.spinner.loading);
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector((state) => state.auth.email);
  const initialRPValues: IResetPassword = {
    username: userEmail,
    code: '',
    password: ''
  };

  /**
* Function to submit reset password values to cognito Auth.forgotPasswordSubmit() method.
 * @param {object} values object consist otp and new password
*/
  const handleFormSubmitt = (values: IResetPassword) => {
    dispatch(startLoading(true));
    dispatch(resetPassword(values))
      .unwrap()
      .then((data: any) => {
        dispatch(stopLoading(false));
        if (data.message === AWS_CONGNITO_ERRORS.INVALID_OTP) {
          toast.error(data.message);
        } else if (data.message === AWS_CONGNITO_ERRORS.ATTEMPT_EXCEEDED) {
          toast.error(data.message);
          navigate('/');
        } else {
          toast.success(MESSAGES.FORGOT_SUCCESS);
          navigate('/');
        }
      })
      .catch(error => {
        toast.error(error);
        dispatch(stopLoading(false));
      });
    setValidateOnChangeBlur(true);
  };

  /**
* Function to resend verification code in forgot password flow
*/
  const resendVerificationCode = () => {
    dispatch(startFullLoading());
    dispatch(forgotPassword({ email: userEmail }))
      .unwrap()
      .then(data => {
        toast.success(MESSAGES.RESEND_CODE);
        dispatch(stopFullLoading());
        navigate(RESET_PASSWORD);
      })
      .catch(error => {
        toast.error(error);
        dispatch(stopFullLoading());
      });
  };

  /**
 * Function to restrict/prevent the typing for numbers only
 * @param {object} event object of keyboard button events
 */
  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.keyCode < NUMBER.N48 || event.keyCode > NUMBER.N57) && event.keyCode !== NUMBER.N8) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="main-login reset-pw-main">
        <button className='close-btn' onClick={() => navigate('/')}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="container">
          <div className="main-login-wrapper verify-email-wrapper reset-pw-wrapper">
            <div className="form-content-main login-form-content">
              <span>
                <img src={logoDark} alt="img dsider logo" className="dsider-logo" />
              </span>
              <div className="login-right reset-pw-right">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <span className="title-des">
                    Please set a new password for your account
                  </span>
                </div>
                <Formik
                  initialValues={initialRPValues}
                  validationSchema={RESET_PASS_VALIDATION_SCHEMA}
                  validateOnBlur={validateOnChangeBlur}
                  validateOnChange={validateOnChangeBlur}
                  onSubmit={(values) => {
                    handleFormSubmitt(values);
                  }}
                >
                  {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError }) => (

                    <Form className="login-form reset-form" onSubmit={handleSubmit}>

                      {/* OTP */}
                      <InputElement
                        label='6-digit verification code'
                        placeholder="Verification code"
                        required={true}
                        type='text'
                        value={values?.code}
                        name='code'
                        data-testid="code"
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        maxLength={NUMBER.N6}
                        hasError={hasFieldError(errors, touched, 'code')}
                        onKeyPressDown={(event) => {
                          keyPressHandler(event);
                        }}
                      />

                      <InputElement
                        label='Password'
                        placeholder="Password"
                        className='mb-45'
                        labelClassName='mb-5'
                        required={true}
                        type='password'
                        value={values?.password}
                        name='password'
                        data-testid="password"
                        subLabel='Password must contain at least eight characters with one uppercase, one lowercase, one numeric, and one special character.'
                        onChange={handleChange}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'password')}
                      />

                      <div className="sign-btn-wrapper">
                        <Button disabled={loading} variant="primary" type="submit">
                          {loading ? <Spinner /> : 'Save'}
                        </Button>
                      </div>
                      <span className="no-account" style={{ marginTop: '30px' }}>
                        Didn&apos;t receive the code?{' '}
                        <Link to="#" onClick={() => resendVerificationCode()} className="sign-up resend-code">
                          Resend Code
                        </Link>
                      </span>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ResetPassword;
