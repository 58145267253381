import React from 'react';

interface IBootonDiv {
  handleEditParameter: () => void
  removeNewProperties: () => void
}

const BottonDiv = ({ handleEditParameter, removeNewProperties }: IBootonDiv) => {
  return (
    <div className='edit-remove-btn-wrap'>
      <p className='btn btn-no-outline success' onClick={handleEditParameter}>Edit</p>
      <p className='btn btn-no-outline remove' onClick={removeNewProperties}>Remove</p>
    </div>
  );
};

export default BottonDiv;
