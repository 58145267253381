import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { baselinLogo, checkTickGreen, editIcon, infoInputLogoWhite, reloadLogo } from '../../utils';
import AddApprover, { IList } from './AddApprover';
import { LABELS, MESSAGES, NUMBER, SCENARIO_STATUS } from '../../constants';
import {
  IInvitedApproverList, useAddToBaseLineMutation, useEditApproverMutation,
  useGetInvitedApproverListQuery, useSendApprovalRequestMutation
} from '../../redux/services/requestApprovalApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateApproverList, updateApproverStatus } from '../../redux/slices/approvalSlice';
import { toast } from 'react-toastify';
import UserAvatar from './UserAvatar';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { updateBaseLineStatus } from '../../redux/slices/scenarioDetailsSlice';
import { scenarioApis } from '../../redux/services/scenarioApis';

interface IRequestApprovalProps {
  approvalModal: boolean
  setApprovalModal: (value: boolean) => void
}

function RequestApproval(props: IRequestApprovalProps) {
  const { approvalModal, setApprovalModal } = props;
  const { rejected, approved, pending, all_approvers_approved } = SCENARIO_STATUS;
  const dispatch = useAppDispatch();
  const { approverList, approvalStatus } = useAppSelector(state => state.approvalData);
  const { email: selfEmail } = useAppSelector(state => state.auth);
  const scenarioDetails = useAppSelector(state => state.scenarioDetails);
  const [showAddApprover, setShowAddApprover] = useState(false);
  const [allApproverApproved, setAllApproverApproved] = useState(false);
  const [editApproverId, setEditApproverId] = useState('');
  const [showAddToBaseLineAlert, setShowAddToBaseLineAlert] = useState(false);
  const [sendRequest, { isLoading }] = useSendApprovalRequestMutation();
  const [editApprover] = useEditApproverMutation();
  const [addToBaseLine, { isLoading: addToBaseLineLoading }] = useAddToBaseLineMutation();
  const { data: invitedApprover } = useGetInvitedApproverListQuery(scenarioDetails.id, { refetchOnMountOrArgChange: true });

  const onApproverSelect = (approver: IList) => {
    setShowAddApprover(false);
    if (editApproverId) {
      const selectedApprover = approverList.find((item) => item.emailid === editApproverId);
      if (selectedApprover) {
        callEditApproverApi(selectedApprover, approver);
      }
    } else {
      const newUser: IInvitedApproverList = getNewApprover(approver, true);
      const updatedApproverList = [{ ...newUser }, ...approverList];
      dispatch(updateApproverList(updatedApproverList));
    }
  };

  /**
   * Function to check if approver is already saved then call the edit approver api otherwise just update the data in the store....
   * @param selectedApprover selected approver from the list
   * @param approver new approver to be added...
   */
  const callEditApproverApi = (selectedApprover: IInvitedApproverList, approver: IList) => {
    const updatedApproverList = [...approverList];
    updatedApproverList[approverList.indexOf(selectedApprover)] = { ...getNewApprover(approver, selectedApprover.isNotSaved ?? false) };

    if (!selectedApprover.isNotSaved) {
      const paylod = {
        scenarioId: scenarioDetails.id,
        currentApprover: editApproverId,
        newApprover: approver.email
      };
      dispatch(startFullLoading());
      dispatch(updateApproverList(updatedApproverList));
      editApprover(paylod).then((res) => {
        if ('data' in res) {
          setEditApproverId('');
          dispatch(stopFullLoading());
          toast.success(MESSAGES.APPROVER_UPDATED);
        } else {
          setEditApproverId('');
          dispatch(stopFullLoading());
        }
      });
    } else {
      dispatch(updateApproverList(updatedApproverList));
      setEditApproverId('');
    }
  };

  const getNewApprover = (approver: IList, isNew: boolean) => {
    return {
      firstname: approver.firstName,
      lastname: approver.lastName,
      emailid: approver.email,
      image_url: approver.imgUrl,
      status: '',
      reason_for_rejection: '',
      isNotSaved: isNew
    };
  };

  /**
   * Function to send approval request to approvers...
   */
  const sendApprovalRequest = () => {
    const newAddedApprover = approverList.filter((approver) => approver.isNotSaved);
    if (newAddedApprover.length) {
      const payload = {
        scenarioId: scenarioDetails.id,
        approval_user_list: newAddedApprover.map((approver) => approver.emailid),
        do_self_approval: false
      };
      sendRequest(payload).then((res) => {
        if ('data' in res) {
          toast.success(MESSAGES.REQUEST_SUCCESS);
        }
      });
    } else {
      toast.error(MESSAGES.NO_NEW_APPROVER);
    }
  };

  /**
   * Function to self approve scenario...
   */
  const selfApproveScenario = () => {
    const payload = {
      scenarioId: scenarioDetails.id,
      approval_user_list: [selfEmail],
      do_self_approval: true
    };
    dispatch(startFullLoading());
    sendRequest(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.SELF_APPROVED);
        setAllApproverApproved(true);
      }
      dispatch(stopFullLoading());
    });
  };

  /**
   * Function to resubnit the request for approval in case approver reject the previous request
   */
  const reSubmitRequest = (approverEmail: string) => {
    const payload = {
      scenarioId: scenarioDetails.id,
      approval_user_list: [approverEmail],
      do_self_approval: false
    };
    dispatch(startFullLoading());
    sendRequest(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.RE_SUBMIT_REQUEST);
      }
      dispatch(stopFullLoading());
    });
  };

  /**
   * Function to Add scenario to base line scenarios...
   */
  const addToBaseLineScenario = () => {
    // logic here
    addToBaseLine(scenarioDetails.id).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.ADD_TO_BASELINE_SUCCESS);
        scenarioApis.util.invalidateTags(['GetScenario']);
        dispatch(updateBaseLineStatus(true));
        setApprovalModal(false);
      }
      setShowAddToBaseLineAlert(false);
    });
  };

  const handleModalClose = () => {
    const savedApprover = approverList.filter((approver) => !approver.isNotSaved);
    dispatch(updateApproverList(savedApprover));
    setApprovalModal(false);
  };

  useEffect(() => {
    if (invitedApprover?.length) {
      dispatch(updateApproverList(invitedApprover));
      const approvedRequest = invitedApprover.filter(approver => approver.status === approved);
      if (approvedRequest.length === invitedApprover.length) {
        dispatch(updateApproverStatus(all_approvers_approved));
      } else {
        dispatch(updateApproverStatus(pending));
      }
    }
  }, [invitedApprover]);

  useEffect(() => {
    if (approvalStatus === all_approvers_approved) {
      setAllApproverApproved(true);
    } else {
      setAllApproverApproved(false);
    }
  }, [approvalStatus]);

  const isApproverPendingAndNoReasonForRejection = (approver: IInvitedApproverList) => {
    return approver.status === pending && approver.reason_for_rejection === '';
  };

  return (
    <>
      {/* Request Approval modal start here */}
      <Modal
        backdrop="static"
        show={approvalModal}
        onHide={handleModalClose}
        dialogClassName="modal-424 top-right-modal"
        className="forget-modal setting-modal share-modal approval-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={handleModalClose}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Request Approval
          </h4>
          <div className='approval-req-wrap'>
            {allApproverApproved && <div className='add-approver-wrap self-approval baseline-approval' onClick={() => setShowAddToBaseLineAlert(true)}>
              <span className='add-approver-des'><img src={baselinLogo} alt="logo" className='green-tick-circle' /> Add to Baseline</span>
            </div>}

            {!approverList?.length && !allApproverApproved && <div className='add-approver-wrap self-approval' onClick={selfApproveScenario}>
              <span className='add-approver-des'><img src={checkTickGreen} alt="logo" className='green-tick-circle' /> Self Approve this Scenario</span>
            </div>}
            {(!allApproverApproved || approverList.length > NUMBER.N0) && <>
              {!showAddApprover && <div className='add-approver-wrap' onClick={() => setShowAddApprover(true)}>
                <span className='add-approver-des'>+ Add Approver</span>
              </div>}
            </>}

            {showAddApprover && <AddApprover
              showAddApprover={setShowAddApprover}
              onSelect={onApproverSelect}
            />}

            {approverList.map((approver) => (
              <div key={approver.emailid}>
                {editApproverId !== approver.emailid &&
                  <div className="tab-main-wrapper">
                    <div className="tab-left-part">
                      <div className="dropdown-wrapper shared-profile">
                        <UserAvatar
                          firstname={approver.firstname ?? approver.emailid}
                          lastname={approver.lastname ?? ''}
                          imageUrl={approver.image_url ?? ''}
                        />
                        <div className="right-sec">
                          <h3 className="dropdown-head">
                            <span className='approver-email'>{approver.firstname ? `${approver.firstname} ${approver.lastname}` : 'abc test'}</span>
                            {isApproverPendingAndNoReasonForRejection(approver) &&
                              <Button className='btn-no-outline show-edit' onClick={() => setEditApproverId(approver.emailid)}>
                                <img src={editIcon} alt="logo" />
                              </Button>
                            }
                          </h3>
                          <p className="dropdown-des">
                            {approver.emailid}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-right-part">
                      <Button className={`status ${approver.status}`}>
                        {approver.status}
                        {approver.status === rejected &&
                          <div className="tooltip-container">
                            <img src={infoInputLogoWhite} alt="logo" className='reload-logo' />
                            <div className="tooltip-text">
                              {approver.reason_for_rejection}
                            </div>
                          </div>
                        }

                      </Button>
                    </div>
                    {approver.status === rejected && <Button className='btn-no-outline reload-btn' onClick={() => reSubmitRequest(approver.emailid)}>
                      <img src={reloadLogo} alt="logo" className='reload-logo' />
                      Resubmit
                    </Button>}
                  </div>
                }
                {editApproverId === approver.emailid && <AddApprover
                  showAddApprover={setShowAddApprover}
                  onSelect={onApproverSelect}
                  setEditApproverId={setEditApproverId}
                />
                }
              </div>
            ))}
          </div>
          <div className="simulate-btn-wrap modal-footer">
            <button
              type="button"
              className="btn-no-outline btn btn-primary"
              onClick={handleModalClose}
            >
              Cancel
            </button>
            <button disabled={isLoading} type="submit" className="primary btn btn-primary" onClick={sendApprovalRequest}>
              {isLoading ? <Spinner /> : 'Save Changes'}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddToBaseLineAlert}
        onHide={() => setShowAddToBaseLineAlert(false)}
        dialogClassName="modal-503 modal-dialog-centered top-right-modal"
        className="forget-modal add-to-baseline-modal share-modal approval-modal"
      >
        <Modal.Body>
          <ConfirmationAlert
            btnClassName="blue-btn"
            showLoader={addToBaseLineLoading}
            showAlert={showAddToBaseLineAlert}
            title={LABELS.ADD_TO_BASELINE}
            message={MESSAGES.ADD_TO_BASELINE}
            yesBtnText={LABELS.YES_PROCEED}
            cancleBtnText={LABELS.NOT_NOW}
            onConfirm={addToBaseLineScenario}
            onCancel={() => setShowAddToBaseLineAlert(false)}
            onCross={() => setShowAddToBaseLineAlert(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestApproval;
