import React from 'react';
import ActivityGroup from './ActivityGroup';
import { IActivitLogGroup } from './LatestActivities';

interface IActivityGroupHOCProps {
  activityLog: IActivitLogGroup
  type: string
}

function ActivityGroupHOC(props: IActivityGroupHOCProps) {
  const { activityLog } = props;
  return (
    <>
      {!!activityLog?.today?.length && <ActivityGroup title='Today' data={activityLog?.today} {...props} />}
      {!!activityLog?.yesterday?.length && <ActivityGroup title='Yesterday' data={activityLog?.yesterday} {...props} />}
      {!!activityLog?.lastWeek?.length && <ActivityGroup title='Last Week' data={activityLog?.lastWeek} {...props} />}
      {!!activityLog?.older?.length && <ActivityGroup title='Older' data={activityLog?.older} {...props} />}
    </>
  );
}

export default ActivityGroupHOC;
