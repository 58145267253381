import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Logo } from '../../utils/icons';
import { Link, useNavigate } from 'react-router-dom';
import InputElement from '../../components/shared/InputElement';
import { MAX_LENGTH } from '../../constants/static';
import { Formik } from 'formik';
import { NUMBER } from '../../constants/numbers';
import { IVerifyCode } from '../../constants/interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { verifyEmail, resendCode } from '../../redux/auth/authReducer';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants/validationMessages';
import { LOGIN } from '../../utils/routeConstants';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { handleCognitoError } from '../../utils/helper';
import { LABELS } from '../../constants/labelConstant';

const VerifyEmail = () => {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector((state) => state.auth.email);
  const navigate = useNavigate();

  const initialValues: IVerifyCode = {
    email: userEmail,
    verify_code: ''
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldError: any) => {
    const val = event.target.value;
    if (val?.length === NUMBER.N6) {
      handleFormSubmit({ email: userEmail, verify_code: val }, setFieldError);
    }
  };

  const handleFormSubmit = (values: IVerifyCode, setFieldError?: any) => {
    dispatch(startFullLoading());
    dispatch(verifyEmail(values))
      .unwrap()
      .then((res: any) => {
        if (res) {
          toast.success(MESSAGES.SIGNUP_SUCCESS);
          navigate(LOGIN);
        }
        dispatch(stopFullLoading());
      })
      .catch((err) => {
        if (err.code === 'CodeMismatchException') {
          setFieldError?.('verify_code', MESSAGES.INVALID_CODE);
        } else {
          handleCognitoError(err);
        }
        dispatch(stopFullLoading());
      });
  };

  const resendVerificationCode = (values: IVerifyCode) => {
    dispatch(startFullLoading());
    dispatch(resendCode(values))
      .unwrap()
      .then((res) => {
        dispatch(stopFullLoading());
        toast.success(MESSAGES.RESEND_CODE);
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(stopFullLoading());
      });
  };

  const goBack = () => {
    setShowAlert(true);
  };

  const handleConfirm = () => {
    // navigate to previous route
    navigate(-NUMBER.N1);
  };

  return (
    <>
      <div className="main-login">
        <button className='close-btn' onClick={goBack}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="container">
          <div className="main-login-wrapper verify-email-wrapper">
            <div className="form-content-main login-form-content">
              <span>
                <img src={Logo} alt="img dsider logo" className="dsider-logo" />
              </span>
              <div className="login-right">
                <div className="login-header">
                  <h3>Verify Your Email</h3>
                  <span className="title-des">
                    Please enter the 6-digit verification code sent
                    to your email address.
                  </span>
                </div>
                <Formik
                  initialValues={initialValues}
                  validateOnChange={true}
                  initialTouched={{ verify_code: true }}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, errors, handleSubmit, handleChange, touched, setFieldError, ...rest }) => (
                    <Form className="login-form" onSubmit={handleSubmit}>
                      <InputElement
                        label='6-digit verification code'
                        name='verify_code'
                        data-testid="verify_code"
                        onChange={(event) => handleOnChange(event, setFieldError)}
                        required={true}
                        maxLength={MAX_LENGTH.verify_code}
                        autoComplete={false}
                        type='text'
                        placeholder="Verification code"
                        hasError={!!errors.verify_code}
                        className='code-input'
                      />
                      <span className="no-account">
                         Didn&apos;t receive the code? {' '}
                        <Link to="#" onClick={() => resendVerificationCode(values)} className="sign-up resend-code">
                          Resend Code
                        </Link>
                      </span>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.discardAlertMessage('Registration')}
        message={MESSAGES.SIGNUP_DISCARD_MESSAGE}
        yesBtnText={LABELS.YES_DISCARD}
        cancleBtnText={LABELS.STAY}
        onConfirm={handleConfirm}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

export default VerifyEmail;
