import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IProjectComponent, Methods } from '../../../constants';
import { startFullLoading, stopFullLoading } from '../../SpinnerSlice';
import { interceptor } from '../../../utils/interceptor';

export const objectManagementsApis = createApi({
  reducerPath: 'objectManagementApis',
  baseQuery: interceptor,
  tagTypes: ['ObjectManagement'],
  endpoints: (builder) => ({
    // api to save Project Data...
    saveObject: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.ADD_ADMIN_COMPANY_OBJECT_ACCESS,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    getObjectType: builder.mutation<any, Partial<any>>({
      query: (payload) => ({
        url: ApiEndpoints.GET_ADMIN_COMPANY_OBJECT_TYPE,
        method: Methods.POST,
        body: payload
      }),
      transformResponse: (res: any[]) => {
        return res.reverse().map(data => data.toLowerCase());
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    getObjectLibrary: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_OBJECT_LIST,
        method: Methods.GET
      }),
      transformResponse: (res: IProjectComponent[]) => {
        // @ts-expect-error expected error.
        const uniqueCategories: string[] = [...new Set(res.map(item => item.category[0]))];
        return uniqueCategories.map(category => ({ value: category, label: category }));
      }
    }),
    getRefrenceObject: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_OBJECT_LIST,
        method: Methods.GET
      }),
      transformResponse: (res: IProjectComponent[]) => {
        return res.map((item: IProjectComponent) => ({
          category: item.category,
          component: item.component,
          componentDisplayName: item.componentDisplayName,
          imageUrl: item.imageS3url || item.imageS3urlDark,
          imageDarkUrl: item.imageS3urlDark ?? '',
          powerCategory: item.powerCategory
        }));
      }
    }),
    checkObjectName: builder.mutation({
      query: (payload) => ({
        url: `${ApiEndpoints.ADMIN_CHECK_OBJECT_NAME}?object_name=${payload}`,
        method: Methods.POST
      })
    }),
    getComponentProperties: builder.query({
      query: (payload) => ({
        url: `${ApiEndpoints.GET_COMPONENT_PROPERTIES}?component_name=${payload}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    addObjectParameter: builder.mutation({
      query: (payload) => ({
        url: ApiEndpoints.ADMIN_OBJECT_ADD_PARAMETER,
        method: Methods.POST,
        body: payload
      })
    }),
    getAllUnits: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.ADMIN_GET_ALL_UNITS,
        method: Methods.GET
      }),
      transformResponse: (res: any) => {
        const validPermissions = res.filter(Boolean);
        return validPermissions.map((permission: any) => ({
          label: permission,
          value: permission
        }));
      }
    }),
    updateObject: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: ApiEndpoints.ADMIN_UPDATE_OBJECT,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    deleteObject: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.ADMIN_DELETE_OBJECT}?object_id=${payload}`,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    }),
    deleteObjectParameter: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.ADMIN_DELETE_PARAMETER}?parameter_object_id=${payload}`,
        method: Methods.POST,
        body: payload
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    })
  })
});

export const {
  useSaveObjectMutation,
  useGetObjectTypeMutation,
  useGetObjectLibraryQuery,
  useGetRefrenceObjectQuery,
  useCheckObjectNameMutation,
  useLazyGetComponentPropertiesQuery,
  useAddObjectParameterMutation,
  useGetAllUnitsQuery,
  useLazyGetRefrenceObjectQuery,
  useUpdateObjectMutation,
  useDeleteObjectMutation,
  useDeleteObjectParameterMutation
} = objectManagementsApis;
