import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, ICreateWithAIPayload, Methods } from '../../constants';

export const scenarioWithAIApis = createApi({
  reducerPath: 'scenarioWithAIApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_EV_CHAT_AI_URL
  }),
  tagTypes: ['CreateWithAI'],
  endpoints: (builder) => ({
    // create scenario with AI
    createScenarioWithAI: builder.mutation<any, ICreateWithAIPayload>({
      query: (payload) => ({
        url: ApiEndpoints.CREATE_SCENARIO_WITH_AI,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['CreateWithAI']
    })
  })
});

export const { useCreateScenarioWithAIMutation } = scenarioWithAIApis;
