import React, { useRef, useState } from 'react';
import { ILatestActivitiesData, updateLatestActivities } from '../../redux/slices/latestActivitiesSlice';
import moment from 'moment';
import { LABELS, NUMBER } from '../../constants';
import { ActivityType } from './LatestActivities';
import { useMarkAsReadLogsMutation } from '../../redux/services/latestActivitiesApi';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { CREATE_SCENARIO_WITH_AI, getWorkbenchURL } from '../../utils';
import { useGetDetailsMutation } from '../../redux/services/scenarioApis';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../approval-workflow/UserAvatar';

interface IActivityGroupProps {
  title: string
  data: ILatestActivitiesData[]
  type: string
}

function ActivityGroup(props: IActivityGroupProps) {
  const { title, data, type } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const activityWrap = useRef<HTMLDivElement>(null);
  const latestActivities = useAppSelector(state => state.latestActivities);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [markAsReadLogs] = useMarkAsReadLogsMutation();
  const [getScenarioDetails] = useGetDetailsMutation();


  const expandCollaps = () => {
    setIsExpanded(pre => !pre);
  };

  const navigateToWorkbench = (logData: any) => {
    const url = getWorkbenchURL(logData.subProjectID ?? logData.Scenario_id, logData.subProject_Type);
    navigate(url, {
      state: {
        navigateFrom: LABELS.DASHBOARD
      }
    });
  };

  const onActivityClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, logData: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (isExpanded || data.length === NUMBER.N1) {
      goToScenarioDetails(logData);
    } else {
      setIsExpanded(true);
    }
  };

  const goToScenarioDetails = (logData: any) => {
    if (logData.subProject_Type === 'ev') {
      dispatch(startFullLoading());
      getScenarioDetails(logData.subProjectID).then((res: any) => {
        if (res.data) {
          const resData = res.data;
          if ('lat' in resData && 'lng' in resData && 'radius' in resData && 'startYear' in resData && 'endYear' in resData) {
            navigateToWorkbench(logData);
          } else {
            navigate(`${CREATE_SCENARIO_WITH_AI}?scenario_id=${logData.subProjectID}&scenarioName=${resData.name}`);
          }
        }
        dispatch(stopFullLoading());
      });
    } else {
      navigateToWorkbench(logData);
    }
  };

  const markActivityAsRead = (log: ILatestActivitiesData, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.closest('.activity-sec')?.classList.add('fade-out');
    setTimeout(() => {
      const payload = {
        activity_id: [log.id],
        acknowledged: true
      };
      markAsReadLogs(payload);
      const filteredLog = latestActivities.filter((acc) => acc.id !== log.id);
      dispatch(updateLatestActivities(filteredLog));
    }, NUMBER.N500);
  };

  const markAllAsRead = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.currentTarget.closest('.activity-outer-wrap')?.classList.add('fade-out');
    setTimeout(() => {
      const activitiesIds = data.map(acc => acc.id);
      const payload = {
        activity_id: activitiesIds,
        acknowledged: true
      };
      markAsReadLogs(payload);
      const filteredLog = latestActivities.filter((acc) => !activitiesIds?.includes(acc.id));
      dispatch(updateLatestActivities(filteredLog));
    }, NUMBER.N1000);
  };

  return (
    <>
      <div className={`activity-outer-wrap ${isExpanded ? 'active' : ''}`} ref={activityWrap}>
        <div className='activity-header'>
          <span className='activity-date cursor-pointer' onClick={expandCollaps}>{title}</span>
          {type === ActivityType.latest && <span className='mark-read-text' onClick={(event) => markAllAsRead(event)}>Mark all as Read</span>}
        </div>
        {
          data.map((ele, index: number) => (
            <div className="activity-sec" key={`${ele.id}-${index}`} onClick={(e) => onActivityClick(e, ele)}>
              <div className="dropdown-wrapper">
                <UserAvatar
                  classname="user-icon orange"
                  firstname={ele.firstName ?? ''}
                  lastname={ele.lastName ?? ''}
                  imageUrl={ele.image_url ?? ''}
                />
                <div className="right-sec">
                  <p className="dropdown-des">
                    {ele.activity}
                  </p>
                  <span className="duration-status">{moment(ele.dateTime).utc(true).fromNow()}</span>
                  {type === ActivityType.latest && <span className="mark-as-read" onClick={(event) => markActivityAsRead(ele, event)}>Mark as Read</span>}
                </div>
              </div>
            </div>
          ))
        }
        {data.length > NUMBER.N1 && <div className='activity-shadow-anim' onClick={expandCollaps}></div>}
      </div >
    </>
  );
}

export default ActivityGroup;
