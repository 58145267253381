import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Card from 'react-bootstrap/Card';
import { cardDragLogo } from '../../../utils';

interface IColumn {
  id: any
}

const Column = (props: IColumn) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card body className=''>
        <span className='card-drag-logo'><img src={cardDragLogo} alt="log img" /></span>
        {props.id}
        </Card>
    </div>
  );
};

export default Column;
