import React, { useState } from 'react';
import { QuestionListCard } from '../../pages/wizard/WizardQuestionType';
import LocationModal from '../shared/LocationModal';
import { DEFAULT_LOCATION, NUMBER } from '../../constants';
import { IQuestionProps } from '../../pages/wizard/wizardInterface';

export const LocationQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion } = porps;
  const [showGoogleMaps, setShowGoogleMaps] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({ lat: '', lng: '' });

  const saveLoactionMap = (lat: string, lng: string) => {
    setSelectedLocation({ lat, lng });
    setAnswersList(() => ({
      ...answersList,
      location: `${lat}, ${lng}`
    }));
    submitQuestion(index + NUMBER.N1);
  };

  return (
    <>
      <div className="wizard-ques-main" key={`${que.question}-${index}`}>
        <QuestionListCard question={que.question} />
        <div className='wizard-ques-input-wrap'>
          <div className='wizard-ques-input' onClick={() => setShowGoogleMaps(true)}>
            <span className='wizard-input-des'>{selectedLocation.lat ? `${selectedLocation.lat}, ${selectedLocation.lng}` : 'Select'}</span>
          </div>
        </div>
      </div>
      {showGoogleMaps && <LocationModal
        showGoogleMaps={showGoogleMaps}
        setShowGoogleMaps={setShowGoogleMaps}
        saveMapLocation={saveLoactionMap}
        lat={selectedLocation.lat || DEFAULT_LOCATION.lat}
        lng={selectedLocation.lng || DEFAULT_LOCATION.lng}
      />}
    </>
  );
};
