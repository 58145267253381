import { createSlice } from '@reduxjs/toolkit';

export interface ILatestActivitiesData {
  activity: string
  company_name: string
  dateTime: string
  frontend_relevance: string
  id: string
  pageName: string
  projectID: string
  subProjectID: string
  subProject_Type: string
  userName: string
  image_url: string
  fullName: string
  firstName: string
  lastName: string
}

const initialState: ILatestActivitiesData[] = [];

const latestActivities = createSlice({
  name: 'latestActivities',
  initialState,
  reducers: {
    updateLatestActivities: (state, payload) => {
      return [...payload.payload];
    },
    resetLatestActivities: (state) => {
      return [...initialState];
    }
  }
});

export const { updateLatestActivities, resetLatestActivities } = latestActivities.actions;

export default latestActivities.reducer;
