import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, ISimulationMatricsData, Methods } from '../../constants';
import { getAuthHeaderMultipart } from '../../utils';

export interface ISimulationStatusResponse {
  message: string
  status: string
  error_message?: string
  results: ISimulationMatricsData
}

export interface ISimulationResponse {
  message: string
  task_id: string
}

export const monteCarloApi = createApi({
  reducerPath: 'monteCarloApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://ao8m7j4alb.execute-api.us-east-1.amazonaws.com/dev' }),
  tagTypes: [''],
  endpoints: (builder) => ({
    runMonteCarloSimulationApi: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.RUN_MONTE_CARLO_SIMULATION}?subprojectid=mc_${payload.id}`,
        method: Methods.POST,
        body: payload.formData,
        headers: getAuthHeaderMultipart()
      })
    }),
    getMonteCarloSimulationResult: builder.query({
      query: (id) => ({
        url: `${ApiEndpoints.GET_MONTE_CARLO_SIMULATION}?subprojectid=mc_${id}`,
        method: Methods.GET,
        headers: getAuthHeaderMultipart()
      })
    })
  })
});

export const {
  useRunMonteCarloSimulationApiMutation,
  useLazyGetMonteCarloSimulationResultQuery
} = monteCarloApi;
