import { Autocomplete } from '@react-google-maps/api';
import React, { useState } from 'react';
import { searchLogo } from '../../utils';

const GoogleMapSearch = ({ handleLocationClick }: { handleLocationClick: any }) => {
  const [searchedPlace, setSearchedPlace] = useState<any>(null);
  const [searchPlaceInput, setSearchPlaceInput] = useState('');

  return (
    <Autocomplete className='place-search-box'
      onLoad={(autocomplete) => {
        setSearchedPlace(autocomplete);
      }}
      onPlaceChanged={() => {
        if (searchedPlace) {
          const place = searchedPlace.getPlace();
          if (place.geometry?.location) {
            const selectedLat = place.geometry.location.lat();
            const selectedLng = place.geometry.location.lng();
            handleLocationClick(selectedLat, selectedLng);
          }
        }
        setSearchPlaceInput('');
      }}

    >
      <>
        <input type="text" placeholder="Search by Name or ZIP Code" value={searchPlaceInput} onChange={(e) => setSearchPlaceInput(e.target.value)}
        />
        <img src={searchLogo} alt="logo" className="search-logo" />
      </>
    </Autocomplete>
  );
};

export default GoogleMapSearch;
