import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {
  crossRedLogo,
  fileInputImg,
  mainBgImg
} from '../../utils/icons';

const CreateModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        dialogClassName="full-width-dialog"
        className="change-pw-modal create-modal edit-profile"
      >
        <Modal.Header>
          <Button
            className="icon-close-grey-icon"
            onClick={handleClose}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title className="mb-46">Edit Profile</Modal.Title>
          <div className="create-modal-wrapper edit-profile-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="create-modal-left">
                    <Form className="login-form signup-form">
                      <Form.Group
                        controlId="formFile"
                        className="form-group file-input error-field"
                      >
                        <Form.Label>
                          Company Logo <sup>*</sup>
                        </Form.Label>
                        <div className="file-input-wrapper">
                          <div className="file-upload-img">
                            <img
                              src={mainBgImg}
                              alt="img upload image"
                              className="file-main-img upload-main-img"
                            />
                            <img
                              src={fileInputImg}
                              alt="img main image"
                              className="file-main-img empty-img"
                            />
                            <img
                              src={crossRedLogo}
                              alt="img cross logo"
                              className="cross-logo"
                            />
                            <span className="error-msg d-done">
                              Company logo is required.
                            </span>
                          </div>
                          <div className="file-input-text-wrapper">
                            <div className="file-attachment-wrapper">
                              <span className="file-input-attachment">
                                + Upload Logo
                              </span>
                              <span className="file-input-des">
                                (jpg, png, jpeg of max 10 mb)
                              </span>
                            </div>
                            <Form.Control type="file" name="upload" />
                          </div>
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="create-modal-right">
                    <Form className="full-width-form">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Name <sup>*</sup>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Company name" />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          Description <sup>*</sup>
                        </Form.Label>
                        <Form.Control
                          placeholder="Company description*"
                          as="textarea"
                          rows={5}
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group disabled-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Name <sup>*</sup>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Company name" />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Form className="">
                    <div className="sign-btn-wrapper">
                      <Button className="btn-no-outline">
                        Delete My Account
                      </Button>
                      <Button variant="primary" type="submit">
                        Update Profile
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateModal;
