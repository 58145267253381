import React, { useState } from 'react';
import SearchAndFilter from '../../shared/SearchAndFilter';
import { Button } from 'react-bootstrap';
import AddCompanyModal from './AddCompanyModal';
import UserMenu from '../../shared/User/UserMenu';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateCompanySearch } from '../../../redux/slices/adminSlice';
import { formatAndRoundOffNumber } from '../../../utils';

const CompanyManagementHeader = () => {
  const [ShowAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const dispatch = useAppDispatch();
  const { companyTotalData } = useAppSelector(state => state.adminSlice);

  const handleSearch = (val: string) => {
    dispatch(updateCompanySearch(val));
  };
  return (
    <><div className='admin-body-header'>
      <div className='admin-header-wrap'>
        <h2 className='admin-section-title'>Companies ({formatAndRoundOffNumber(companyTotalData)})</h2>
        <div className='admim-profile-wrap'>
          <UserMenu/>
        </div>
      </div>
      <div className='add-user-wrap'>
        <SearchAndFilter
          placeHolder="Search by name or email"
          onSearch={handleSearch}
          className="scenario-list-search"
        />
        <Button onClick={() => setShowAddCompanyModal(true)}>+ New Company</Button>
      </div>
    </div>
    {ShowAddCompanyModal && <AddCompanyModal ShowAddCompanyModal={ShowAddCompanyModal} setShowAddCompanyModal={setShowAddCompanyModal}/>}
    </>
  );
};

export default CompanyManagementHeader;
