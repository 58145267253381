import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';

import { useGetSimilarProjectsQuery } from '../../redux/services/scenarioApis';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { useScenario } from '../../hooks/useScenario';


interface IScenarioMoveProps {
  showModal: boolean
  setShowModal: (show: boolean) => void
  scenerioID?: string
  refreshActivity?: () => void
  scenarioData?: any
  moveScenarioID: any
  refreshData: any
}


function ScenarioMoveList(props: IScenarioMoveProps) {
  const { showModal, setShowModal, refreshActivity, scenarioData, moveScenarioID, refreshData } = props;
  const { moveScenarioToProject } = useScenario();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get('name');

  const workbenchData = useAppSelector(state => {
    if (state.workBenchEV?.scenarioDetails?._id) {
      return state.workBenchEV;
    } else {
      return state.workbench;
    }
  });

  const [selectedProject, setSelectedProject] = useState('');
  const { data: allProjects, isLoading, error } = useGetSimilarProjectsQuery(moveScenarioID);
  const similarProjects = allProjects?.filter((project: any) => project.name !== projectName);
  const options = similarProjects?.map(({ _id, name }: { _id: string, name: string }) => ({
    value: _id,
    label: name
  }));

  const handleSelectChange = (selectedOption: any) => {
    setSelectedProject(selectedOption ? selectedOption.value : null);
  };


  const handleMove = async (payload: any) => {
    setShowModal(false);
    moveScenarioToProject(payload, refreshData);
    refreshActivity && refreshActivity();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-450 top-right-modal"
        className="forget-modal setting-modal move-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={() => setShowModal(false)}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Move Scenario
          </h4>
          <p className="modal-title-des mb-25">
            Selected scenario:{' '}
            <span className="title-des-bold">{workbenchData?.scenarioDetails?.name || scenarioData?.Scenario_name}</span>
          </p>
          <div className="tag-input">
            <Select
              className="custom-select-cls"
              classNamePrefix="select"
              value={options?.find((option: { value: string }) => option.value === selectedProject)}
              isSearchable
              onChange={handleSelectChange}
              options={options && options}
              placeholder="Choose a project"
            />
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                <Button className="btn-primary no-radius" style={{ width: '100%' }}
                  onClick={async () => await handleMove({ scenarioId: moveScenarioID, projectId: selectedProject })}>
                  Move
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScenarioMoveList;
