import React from 'react';
import { NUMBER } from '../../constants';
import { Dropdown } from 'react-bootstrap';

const ObjectListCard = (props: any) => {
  return (
    <div className="tab-main-wrapper">
      <div className="tab-left-part">
        <div className="dropdown-wrapper shared-profile object-dropdown-wrapper">
          <span className="user-icon medium">
            <img
              src={props.data.imageS3urlDark}
              alt="logo"
              className="user-img"
            />
          </span>
          <div className="right-sec">
            <h3 className="dropdown-head">
              {props.data.componentDisplayName}
            </h3>
            {props.data.category.length <= NUMBER.N2 && <p className="dropdown-des">
              {props.data.category.join(', ')}
            </p>}
            {props.data.category.length > NUMBER.N2 && (
              <div className='object-dropdown-wrap'>
                <span className='dropdown-des'>
                  {`${props.data.category[NUMBER.N0]}, ${props.data.category[NUMBER.N1]}`}
                </span>
                <Dropdown className="create-modal-dropdown all-object-dropdown-wrap">
                  <Dropdown.Toggle id="dropdown-basic">
                    {` +${props.data.category.length - NUMBER.N1}`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.ItemText>{props.data.componentDisplayName}</Dropdown.ItemText>
                    {props.data.category.map((item: string) => (<Dropdown.Item as="button" key={item} >{item}</Dropdown.Item>))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectListCard;
