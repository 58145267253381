import React, { useState } from 'react';
import { electricityGraphImg, getValueWithUnit, getValueWithUnitFirst, irrLogo, nvpLogo } from '../../utils';
import { Button } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';
import { GeoThermalEconomicsModalKeys } from '../../constants';
import PopoverModal from '../shared/CostBreakdownModal';

function GeoThermalEconomics() {
  const [showPopOverModal, setShowPopOverModal] = useState(false);
  const [modalData, setModalData] = useState<Record<string, any>>();
  const [modalTitle, setModalTitle] = useState('');
  const [modalUnit, setModalUnit] = useState('');
  const simulationMetricsData = useAppSelector(state => state.simulationMetricsData);

  const ECONOMIC_PARAMETERS = simulationMetricsData['ECONOMIC PARAMETERS'];
  const RESERVOIR_SIM_RESULTS = simulationMetricsData['RESERVOIR SIMULATION RESULTS'];
  const RESERVOIR_PARAMETERS = simulationMetricsData['RESERVOIR PARAMETERS'];
  const RESULTS_SUMMARY = simulationMetricsData['SUMMARY OF RESULTS'];
  const CAPITAL_COSTS = simulationMetricsData['CAPITAL COSTS (M$)'];
  const OPERATING_AND_MAINTENANCE = simulationMetricsData['OPERATING AND MAINTENANCE COSTS (M$/yr)'];
  const economicModel = 'Economic Model';

  const formatModalData = (title: string, data: Record<string, any>) => {
    const dataObj: Record<string, any> = {};
    GeoThermalEconomicsModalKeys[title]?.map((key: string) => {
      dataObj[key] = data[key];
    });
    return dataObj;
  };

  const openModal = (title: string, data: any, unit?: string) => {
    setModalTitle(title);
    setModalUnit(unit ?? '');
    const updateData = formatModalData(title, data);
    setModalData(updateData);
    setShowPopOverModal(true);
  };

  return (
    <>
      <div className="simulation-chart-wrapper mitigation-chart geothermal-table">
        <div className="mitigation-wrap">
          <div className='geo-left-wrap'>
            <div className="co2-wrap">
              <h4 className="section-head">Emissions (mt)</h4>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">Total Avoided Carbon Emissions</span>
                <p className="mitigation-value">{RESULTS_SUMMARY?.['Total Avoided Carbon Emissions'] ?? '--'}</p>
              </div>
            </div>
            <div className="co2-wrap supply-left">
              <h4 className="section-head">Financial Model</h4>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">Model</span>
                <p className="mitigation-value">{ECONOMIC_PARAMETERS?.[economicModel] ?? '--'}</p>
              </div>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">Capacity Factor</span>
                <p className="mitigation-value">{getValueWithUnit(ECONOMIC_PARAMETERS?.['Capacity factor'])}</p>
              </div>
            </div>
          </div>
          <div className="mitigation-right-wrap">
            <div className="supply-right">
              <Button className="vir-head geo-dropdown-btn btn-no-outline"
                onClick={() => openModal('Reservoir Simulation Results', RESERVOIR_SIM_RESULTS)}>
                Reservoir Simulation Results
              </Button>
              <div className="reservoir-row row">
                <div className="reservoir-wrap col-md-6">
                  <span className="credits-des">Reservoir Model</span>
                  <p className="credits-value">{RESERVOIR_PARAMETERS?.['Reservoir Model']}</p>
                </div>
                <div className="reservoir-wrap col-md-6">
                  <span className="credits-des">Avg. Reservoir Heat Extraction</span>
                  <p className="credits-value">{getValueWithUnit(RESERVOIR_SIM_RESULTS['Average Reservoir Heat Extraction'])}</p>
                </div>
                <div className="reservoir-wrap col-md-6">
                  <span className="credits-des">Avg. Net Electricity Production</span>
                  <p className="credits-value">{getValueWithUnit(RESERVOIR_SIM_RESULTS?.['Average Net Electricity Production'])}</p>
                </div>
                <div className="reservoir-wrap col-md-6">
                  <span className="credits-des">Average Production Temperature</span>
                  <p className="credits-value">{getValueWithUnit(RESERVOIR_SIM_RESULTS['Average Production Temperature'])}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="supply-nvp">
          <div className="nvp-left co2-wrap">
            <div className="vir-sec">
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="vir-head">VIR</p>
                <span className="vir-des">{ECONOMIC_PARAMETERS?.['Project VIR=PI=PIR']?.value}</span>
              </div>
            </div>
            <div className="vir-sec moic-sec">
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="vir-head">MOIC</p>
                <span className="vir-des">{ECONOMIC_PARAMETERS?.['Project MOIC']?.value}</span>
              </div>
            </div>
          </div>
          <div className="nvp-right co2-wrap">
            <div className="vir-sec">
              <div className="suppy-credit-wrap npv-credit-wrap">
                {/* <Button className="vir-head geo-dropdown-btn btn-no-outline" onClick={() => openModal(RESERVOIR_PARAMETERS?.['Reservoir Model'], ECONOMIC_PARAMETERS)}>
                  {RESERVOIR_PARAMETERS?.['Reservoir Model']}
                </Button> */}
                <p className="vir-head">{ECONOMIC_PARAMETERS?.[economicModel] ?? '--'}</p>
                <span className="vir-des">{ECONOMIC_PARAMETERS?.[ECONOMIC_PARAMETERS?.[economicModel]]?.value}</span>
              </div>
            </div>
            <div className="vir-sec moic-sec">
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="vir-head">Project Payback Period</p>
                <span className="vir-des">{getValueWithUnit(ECONOMIC_PARAMETERS?.['Project Payback Period'])}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="supply-nvp geo-npv">
          <div className="nvp-left co2-wrap">
            <div className="tax-credits geo-net-avg">
              <span className="section-logo nvp-sec-logo visibility-hidden"><img src={nvpLogo} alt="logo" /></span>
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="credits-value npv-value vir-head">Avg. Net Electricity Production</p>
                <span className="credits-des npv-des vir-des">{getValueWithUnit(RESULTS_SUMMARY?.['Average Net Electricity Production'])}</span>
              </div>
            </div>
            <div className="tax-credits geo-npv">
              <span className="section-logo nvp-sec-logo"><img src={nvpLogo} alt="logo" /></span>
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="credits-value npv-value vir-head">NPV</p>
                <span className="credits-des npv-des vir-des">{getValueWithUnitFirst(ECONOMIC_PARAMETERS?.['Project NPV'])}</span>
              </div>
            </div>
            <div className="tax-credits geo-irr">
              <span className="section-logo nvp-sec-logo"><img src={irrLogo} alt="logo" /></span>
              <div className="suppy-credit-wrap npv-credit-wrap">
                <p className="credits-value npv-value vir-head">IRR</p>
                <span className="credits-des npv-des vir-des">{getValueWithUnit(ECONOMIC_PARAMETERS?.['Project IRR'])}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="cost-wrap">
          <div className="cost-img-sec">
            <div className="cost-img-wrap">
              <div className="cost-img-inner-wrap">
                <img src={electricityGraphImg} alt="logo" />
                <h3>Cost</h3>
              </div>
            </div>
            <div className="cost-text-wrap">
              <div className="cost-text-inner-wrap">
                <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                  openModal('Capital Costs', CAPITAL_COSTS, '(M$)')}>
                  Total Capital Costs
                </Button>
                <h2 className="cost-text-head">{getValueWithUnitFirst(CAPITAL_COSTS?.['Total capital costs'])}</h2>
              </div>
              <div className="cost-text-inner-wrap">
                <Button className="mitigation-label geo-dropdown-btn btn-no-outline"
                  onClick={() => openModal('Operating & Maintenance Costs', OPERATING_AND_MAINTENANCE, '(M$/yr)')}>
                  Total Operating and Maintenance Costs
                </Button>
                <h2 className="cost-text-head">{getValueWithUnitFirst(OPERATING_AND_MAINTENANCE?.['Total operating and maintenance costs'], '/yr')}</h2>
              </div>
            </div>
          </div>
          <div className="cost-value-two">
            <span className="cost-value-head">LCOE/kWh</span>
            <div className="co2-inner-wrap cost-value-wrap">
              <span className="mitigation-label">Total</span>
              <p className="lcoe-value">{getValueWithUnit(RESULTS_SUMMARY?.['Electricity breakeven price'])}</p>
            </div>
          </div>
          <div className="cost-value-two">
            <span className="cost-value-head">LCOH</span>
            <div className="co2-inner-wrap cost-value-wrap">
              <span className="mitigation-label">Total</span>
              <p className="lcoe-value">{getValueWithUnit(RESULTS_SUMMARY?.['Direct-Use heat breakeven price (LCOH)'])}</p>
            </div>
          </div>
        </div>
      </div>
      {modalData && <PopoverModal showPopOverModal={showPopOverModal}
        setShowPopOverModal={setShowPopOverModal}
        modalTitle={modalTitle} modalData={modalData} modalUnit={modalUnit} />
      }
    </>
  );
}

export default GeoThermalEconomics;

