import React, { useState } from 'react';
import UserMenu from '../../shared/User/UserMenu';
import SearchAndFilter from '../../shared/SearchAndFilter';
import { Button } from 'react-bootstrap';
import AddNewUser from './AddNewUser';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUserSearch } from '../../../redux/slices/adminSlice';
import { formatAndRoundOffNumber } from '../../../utils';

const UserManagementHeader = () => {
  const [ShowObjectModal, setShowObjectModal] = useState(false);
  const dispatch = useAppDispatch();
  const { userTotalData } = useAppSelector(state => state.adminSlice);
  const handleSearch = (val: string) => {
    dispatch(updateUserSearch(val));
  };
  return (
    <>
      <div className='admin-body-header'>
        <div className='admin-header-wrap'>
          <h2 className='admin-section-title'>All Users ({formatAndRoundOffNumber(userTotalData)})</h2>
          <div className='admim-profile-wrap'>
            <UserMenu />
          </div>
        </div>
        <div className='add-user-wrap'>
          <SearchAndFilter
            placeHolder="Search by name, email or company name"
            onSearch={handleSearch}
            className="scenario-list-search"
          />
          <Button onClick={() => setShowObjectModal(true)}>+ New User</Button>
        </div>
      </div>
      <AddNewUser ShowObjectModal={ShowObjectModal} setShowObjectModal={setShowObjectModal} />
    </>
  );
};

export default UserManagementHeader;
