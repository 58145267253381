import { createApi } from '@reduxjs/toolkit/query/react';
import { interceptor } from '../../utils/interceptor';
import { ApiEndpoints, IScenarioDetails, Methods } from '../../constants';
import { IList } from '../../components/approval-workflow/AddApprover';
import { IApprovalStatus } from '../slices/approvalSlice';

interface IUserList {
  userName: string
  firstName: string
  lastName: string
  image_url: string
}

export interface IInvitedApproverList {
  firstname?: string
  lastname?: string
  status: string
  emailid: string
  image_url?: string
  reason_for_rejection: string
  isNotSaved?: boolean
}

interface IApproverListResponse {
  approvalStatus: string
  user_approver_list: IInvitedApproverList[]
}

interface ISendRequestPayload {
  scenarioId: string
  approval_user_list: string[]
  do_self_approval: boolean
}

interface IEditApproverPayload {
  scenarioId: string
  currentApprover: string
  newApprover: string
}

export const requestApprovalApi = createApi({
  reducerPath: 'requestApprovalApi',
  baseQuery: interceptor,
  tagTypes: ['companyUserList', 'invitedApproverList', 'approvalStatus', 'GetBaseLineScenarios'],
  endpoints: (builder) => ({

    // api to get the list of users within a company...
    getApproverUserList: builder.query<IList[], void>({
      query: () => ({
        url: ApiEndpoints.GET_APPROVER_LIST,
        method: Methods.GET
      }),
      transformResponse: (res: IUserList[]) => {
        return res.map((list) => {
          return {
            email: list.userName,
            firstName: list.firstName,
            lastName: list.lastName,
            imgUrl: list.image_url
          };
        });
      },
      providesTags: ['companyUserList']
    }),

    // api to get the list of invited approvers for a specific scenario...
    getInvitedApproverList: builder.query<IInvitedApproverList[], string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.GET_INVITED_APPROVERS}?scenario_id=${id}`,
        method: Methods.GET
      }),
      transformResponse: (res: IInvitedApproverList[]) => {
        return res.reverse();
      },
      providesTags: ['invitedApproverList']
    }),

    // api to get the approval status...
    getApprovalStatus: builder.query<IApprovalStatus, string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.GET_APPROVAL_STATUS}?scenario_id=${id}`,
        method: Methods.GET
      }),
      transformResponse: (res: IApproverListResponse) => {
        return {
          approvalStatus: res.approvalStatus ?? '',
          approverList: res.user_approver_list?.reverse() ?? []
        };
      },
      transformErrorResponse: () => {
        return {
          approvalStatus: '',
          approverList: []
        };
      },
      providesTags: ['approvalStatus']
    }),

    // api to start the approval process for a specific scenario...
    startApprovalProcess: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.START_APPROVAL_PROCESS}?scenarioID=${id}`,
        method: Methods.POST
      }),
      invalidatesTags: ['approvalStatus']
    }),

    /**
     * Api to send the approval request to users
     */
    sendApprovalRequest: builder.mutation<any, ISendRequestPayload>({
      query: (payload: ISendRequestPayload) => ({
        url: ApiEndpoints.SEND_APPROVAL_REQUEST,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['approvalStatus']
    }),

    /**
     * Api to edit the existing approver
     */
    editApprover: builder.mutation<any, IEditApproverPayload>({
      query: (payload: IEditApproverPayload) => ({
        url: ApiEndpoints.EDIT_APPROVER,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['approvalStatus', 'invitedApproverList']
    }),

    /**
     * Api to add the scenario to base line
     */
    addToBaseLine: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.ADD_TO_BASELINE}?scenarioID=${id}`,
        method: Methods.POST
      }),
      invalidatesTags: ['approvalStatus', 'GetBaseLineScenarios']
    }),

    /**
     * Api to get all baseline scenarios
     */
    getBaseLineProjectList: builder.query<IScenarioDetails[], void>({
      query: () => ({
        url: ApiEndpoints.GET_BASELINE_PROJECT,
        method: Methods.GET
      }),
      providesTags: ['GetBaseLineScenarios']
    })
  })
});

export const {
  useGetApproverUserListQuery,
  useGetInvitedApproverListQuery,
  useGetApprovalStatusQuery,
  useStartApprovalProcessMutation,
  useSendApprovalRequestMutation,
  useEditApproverMutation,
  useAddToBaseLineMutation,
  useGetBaseLineProjectListQuery
} = requestApprovalApi;
