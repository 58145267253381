import React, { useState } from 'react';

const AllObject = () => {
  const [text, setText] = useState('Initial Text');
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (event: any) => {
    setText(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };
  return (
    <>
      <div className="input-editable">
        {isEditing ? (
          <input
            type="text"
            value={text}
            onChange={(e: any) => handleInputChange(e)}
            onBlur={handleSaveClick}
            autoFocus
          />
        ) : (
          <div>
            <span>{text}</span>
            <button onClick={handleEditClick}>Edit</button>
          </div>
        )}
      </div>
    </>
  );
};

export default AllObject;
