/* eslint-disable complexity */
import React, { FC, useEffect, useRef, useState } from 'react';
import 'rc-slider/assets/index.css';
import {
  demographicLogo,
  estimateDemandLogo,
  evCountLogo,
  pointIntLogo
} from '../../utils/icons';
import HeaderWorkbench from '../../components/layout/HeaderWorkbench';
import GraphHOC from '../../components/shared/Graph/GraphHOC';
import {
  GRAPH_FORM_TYPE,
  IEVSimulation,
  NUMBER,
  IScenarioDetails,
  GRAPH_TYPES,
  MESSAGES,
  MILE_IN_METER,
  METERS_TO_MILES
} from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import EVPluseStationCardHeader from './EVPluseStationCardHeader';
import { useSimulationScenarioDataMutation } from '../../redux/services/evSimulationApis';
import { useLocation } from 'react-router-dom';
import { getEVWorkbenchDuplicatePayload, getEVWorkbenchPayload, formatIntegerOnly, metersToMiles, formatAndRoundOffNumber, milesToMeters } from '../../utils';
import { toast } from 'react-toastify';
import { useGetMapCommentsQuery, useSaveEVSimulationDataMutation, useSaveWorkbenchDataMutation, useSaveWorkbenchEVAsDuplicateMutation } from '../../redux/services/workbenchApis';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { resetWorkbenchEv, updateUnsavedChange, updateWorkbenchDataEV } from '../../redux/workbenchSliceEV';
import BPPulseStationsMapForScratch from './BPPulseStationsMapforScratch';
import { updateScenarioDetails } from '../../redux/slices/scenarioDetailsSlice';

interface Props {
  scenarioId: string
  scenarioDetails: IScenarioDetails
  scenarioSuccess: boolean
}

interface Coordinates {
  lat: number
  lng: number
}


const EVWorkbenchScratch: FC<Props> = ({ scenarioId, scenarioDetails, scenarioSuccess }) => {
  const location = useLocation();
  const { N1600, N41878, N87629, N2023, N2035, N100, N1, N25, N60, N50, N800, N4, N2, N3, N500, N90, N180, N5, N0 } = NUMBER;
  const { id, radius, lat, lng, startYear, endYear, evTrafficProp, nrel_avg_dvmt, nrel_pref_dist, nrel_home_access_dist, nrel_home_power_dist } = scenarioDetails;
  const scenarioSimulationData = {
    scenario_id: id,
    radius: radius ?? N1600,
    lat: lat ?? N41878,
    lng: lng ?? -N87629,
    start_year: startYear ?? N2023,
    end_year: endYear ?? N2035,
    isDuplicate: false,
    ev_traffic_prop: evTrafficProp ? evTrafficProp / N100 : N1,
    nrel_avg_dvmt: nrel_avg_dvmt ?? N25,
    nrel_pref_dist: nrel_pref_dist ?? N60,
    nrel_home_access_dist: nrel_home_access_dist ?? N50,
    nrel_home_power_dist: nrel_home_power_dist ?? 'MostL1'
  };
  const dispatch = useAppDispatch();
  const [saveWorkbenchData, { isLoading: saveWorkbenchLoading }] = useSaveWorkbenchDataMutation();
  const [saveEVSimulationData, { isLoading: saveEVSimulationLoading }] = useSaveEVSimulationDataMutation();
  const [saveWorkbenchEVAsDuplicate, { isLoading: saveWorkBanchDuplicateLoading }] = useSaveWorkbenchEVAsDuplicateMutation();
  const { data: commentsData, refetch } = useGetMapCommentsQuery({ scenarioId });
  const simulationPayload = scenarioSimulationData;
  const navigateFrom = location.state ? location.state.navigateFrom : null;
  const workbenchTheme = useAppSelector((state) => state.workbench.theme);
  const [isScrolled, setIsScrolled] = useState<boolean | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [simulationScenarioData, { isLoading, isSuccess }] = useSimulationScenarioDataMutation();
  const [simulationPayloadData, setsimulationPayloadData] = useState<any>(scenarioSimulationData);
  const [simulationData, setSimulationData] = useState<IEVSimulation | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [radiusSlider, setRadiusSlider] = useState<number>(parseFloat(metersToMiles(simulationPayload?.radius)) || 1);
  const [radiusMap, setRadiusMap] = useState<number>(simulationPayload.radius || N800);
  const [commentToggle, setCommentToggle] = useState<boolean>(false);
  const [notSimulated, setNotSimulated] = useState<boolean>(false);
  const [clickedMarker, setClickedMarker] = useState<google.maps.LatLngLiteral | null>(null);

  const [summary, setSummary] = useState<string>('');
  const [EVTraffic, setEVTraffic] = useState<number>(evTrafficProp ?? N100);
  const [EVTrafficScenario, setEVTrafficScenario] = useState<number>(evTrafficProp ?? N100);
  const [commentUpdates, setCommentUpdates] = useState(false);

  const [centerLocation, setCenterLocation] = useState<Coordinates>({ lat: lat ?? N41878, lng: lng ?? -N87629 });

  const [clickedCoordinates, setClickedCoordinates] = useState<Coordinates>({
    lat: centerLocation.lat ?? N41878,
    lng: centerLocation.lng ?? -N87629
  });
  const currentRequest = useRef<any>(null);

  const customStringValues = ['MostL1', 'MostL2', 'Equal'];

  const [homePDDefVal, setHomePDDefVal] = useState<string>(nrel_home_power_dist ?? 'MostL1');

  const mapStringToNumeric = (stringValue: string) => {
    return customStringValues.indexOf(stringValue) + 1;
  };
  const [selectedValue, setSelectedValue] = useState(mapStringToNumeric(homePDDefVal) ?? 1);
  const [dailyMiles, setDailyMiles] = useState(nrel_avg_dvmt ?? N25);
  const [accessHC, setAccessHC] = useState(nrel_home_access_dist ?? N50);
  const [preferenceHC, setPreferenceHC] = useState(nrel_pref_dist ?? N60);
  const handleValueChange = (value: any) => {
    setSelectedValue(value);
  };

  const [dailyMilesScenario, setDailyMilesScenario] = useState(nrel_avg_dvmt ?? N25);
  const [accessHCScenario, setAccessHCScenario] = useState(nrel_home_access_dist ?? N50);
  const [preferenceHCScenario, setPreferenceHCScenario] = useState(nrel_pref_dist ?? N60);
  const [homePDDefValScenario, setHomePDDefValScenario] = useState(homePDDefVal ?? 'MostL1');

  const mapNumericToString = (numericValue: any) => {
    return customStringValues[numericValue - 1];
  };
  const homePDVal = mapNumericToString(selectedValue);

  const startYearSimulation = simulationPayload?.start_year;
  const endYearSimulation = simulationPayload?.end_year;

  const radiusInMiles = radiusMap * METERS_TO_MILES;

  const currentEVData = simulationData?.metrics?.find((metric) => metric.name === 'traffic_adjusted_fleet_size_2023');
  const projectedEVData = simulationData?.metrics?.find((metric) => metric.name === 'fleet_size_2035');
  const avgDemandPVData = simulationData?.metrics?.find((metric) => metric.name === 'avg_demand_per_visit');
  const fleetSizePctChangeData = simulationData?.metrics?.find((metric) => metric.name === 'fleet_size_pct_change');
  const avgWeekdayVisitsData = simulationData?.metrics?.find((metric) => metric.name === 'avg_weekday_visits');
  const avgWeekEndVisitsData = simulationData?.metrics?.find((metric) => metric.name === 'avg_weekend_visits');
  const avgWeekVisitsData = simulationData?.metrics?.find((metric) => metric.name === 'avg_week_visits');

  const avgWeekDayDemandData = simulationData?.metrics?.find((metric) => metric.name === 'avg_weekday_demand');
  const avgWeekEndDemandData = simulationData?.metrics?.find((metric) => metric.name === 'avg_weekend_demand');
  const avgWeekDemandData = simulationData?.metrics?.find((metric) => metric.name === 'avg_week_demand');

  const chargingStationCountMetricData = simulationData?.metrics?.find((metric) => metric.name === 'charging_station_count');
  const avgWeekDayDemandFinal = formatIntegerOnly(avgWeekDayDemandData?.value as number, '-');
  const avgWeekEndDemandFinal = formatIntegerOnly(avgWeekEndDemandData?.value as number, '-');
  const avgWeekDemandFinal = formatIntegerOnly(avgWeekDemandData?.value as number, '-');

  const avgWeekdayVisitsFinal = formatIntegerOnly(avgWeekdayVisitsData?.value as number, '-');
  const avgWeekEndVisitsFinal = formatIntegerOnly(avgWeekEndVisitsData?.value as number, '-');
  const avgWeekVisitsFinal = formatIntegerOnly(avgWeekVisitsData?.value as number, '-');
  const growthPercFinal = formatIntegerOnly(fleetSizePctChangeData?.value as number, '-');

  const POICount = simulationData?.POIs && formatIntegerOnly(simulationData.POIs.length, '-');

  const populationData = simulationData?.metrics?.find((metric) => metric.name === 'population');
  const householdsData = simulationData?.metrics?.find((metric) => metric.name === 'households');
  const populationFinal = formatIntegerOnly(populationData?.value as number);
  const householdsFinal = formatIntegerOnly(householdsData?.value as number);
  const fleetSizeFinal = formatIntegerOnly(currentEVData?.value as number);
  const numberOfYearsSimulation = endYearSimulation - startYearSimulation;

  useEffect(() => {
    setSelectedValue(mapStringToNumeric(nrel_home_power_dist ?? 'MostL1'));
  }, [nrel_home_power_dist]);

  useEffect(() => {
    if (homePDDefVal === 'MostL1') {
      setSelectedValue(1);
    } else if (homePDDefVal === 'MostL2') {
      setSelectedValue(N2);
    } else if (homePDDefVal === 'Equal') {
      setSelectedValue(N3);
    }
  }, [homePDDefVal]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > N50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(null);
      }
    };

    if (divRef.current) {
      divRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const prevCommentUpdates = useRef(commentUpdates);
  useEffect(() => {
    if (commentUpdates !== prevCommentUpdates.current) {
      refetch();
      prevCommentUpdates.current = commentUpdates;
    }
  }, [commentsData, commentUpdates]);


  useEffect(() => {
    if (simulationData && clickedCoordinates.lat !== null && clickedCoordinates.lng !== null) {
      const summary = `Traffic Adjusted Fleet Size for the location (Latitude: ${clickedCoordinates.lat},
        Longitude : ${clickedCoordinates.lng}) starting from year ${startYearSimulation} is ${fleetSizeFinal} and
      the projected EV growth in the  next ${numberOfYearsSimulation} years is ${growthPercFinal}%. The Demand Total on an Avg Weekday is
      ${avgWeekDayDemandFinal} kWh, on Weekends is ${avgWeekEndDemandFinal} kWh and on an Avg Week is ${avgWeekDemandFinal} kWh.
       Visit to any EV Station on an Avg Weekday is ${avgWeekdayVisitsFinal}, Weekend is ${avgWeekEndVisitsFinal} and on an Avg Week is
       ${avgWeekVisitsFinal}. The POI count in the ${radiusInMiles.toFixed(N1) || ''} mile radius of the selected location is ${POICount}.
       There are about ${householdsFinal} households in the selected area with the population of ${populationFinal} people.`;
      setSummary(summary);
    }
  }, [simulationData]);

  useEffect(() => {
    if (simulationData) {
      const workbench = {
        scenarioDetails,
        lat: centerLocation?.lat,
        lng: centerLocation?.lng,
        startYear: simulationPayload.start_year,
        endYear: simulationPayload.end_year,
        evTrafficProp: EVTrafficScenario,
        radius: radiusMap,
        isDuplicate: false,
        simulationData,
        nrel_avg_dvmt: dailyMilesScenario,
        nrel_pref_dist: preferenceHCScenario,
        nrel_home_access_dist: accessHCScenario,
        nrel_home_power_dist: homePDDefValScenario
      };
      dispatch(updateScenarioDetails(scenarioDetails));
      dispatch(updateWorkbenchDataEV(workbench));
    }
  }, [simulationData]);

  useEffect(() => {
    if (navigateFrom) {
      dispatch(updateUnsavedChange(true));
    }
  }, []);


  const performSimulation = async (payload: any) => {
    dispatch(startFullLoading());
    if (Object.prototype.hasOwnProperty.call(payload, 'isDuplicate')) {
      delete payload.isDuplicate;
    }

    try {
      const responseData = simulationScenarioData(payload);
      currentRequest.current = responseData;
      const res: any = await responseData;
      if (res?.data) {
        if (res?.data?.message !== 'success') {
          toast.error(res?.data?.message);
        }
        setSimulationData(res?.data);
        const { lat: latInput, lng: lngInput, nrel_home_power_dist: nrelHomePower } = res?.data?.inputs;
        setCenterLocation({ lat: Number(latInput), lng: Number(lngInput) });
        setRadiusMap(radiusSlider * MILE_IN_METER);
        setEVTrafficScenario(EVTraffic);
        setDailyMilesScenario(dailyMiles);
        setAccessHCScenario(accessHC);
        setPreferenceHCScenario(preferenceHC);
        setHomePDDefValScenario(nrelHomePower);
        setTimeout(() => {
          dispatch(stopFullLoading());
        }, N500);
        toast.success(MESSAGES.SIMULATION_IS_COMPLETED);
      } else {
        toast.error('Reference data is currently unavailable for the specified input location');
        setSimulationData(null);
        setSummary('');
        dispatch(stopFullLoading());
      }
    } catch (error) {
      toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      setSimulationData(null);
      setSummary('');
    }
    dispatch(stopFullLoading());
  };


  useEffect(() => {
    const performSimulationWithPayload = () => {
      performSimulation(simulationPayload);
    };
    setTimeout(performSimulationWithPayload, N100);
    return () => {
      if (currentRequest.current) {
        currentRequest.current.abort();
      }
    };
  }, []);


  const markerClickHandler = (marker: any) => {
    setSelectedMarker(marker);
  };

  const infoWindowCloseHandler = () => {
    setSelectedMarker(null);
  };

  const sliderChangeHandler = (value: any) => {
    setRadiusSlider(value);
  };



  const updateCoordinates = (
    lat: number,
    lng: number
  ) => {
    setClickedCoordinates({
      lat: lat ?? 0,
      lng: lng ?? 0
    });
  };

  const clickedCoordinatesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) || value === '') {
      let newValue = 0;

      if (name === 'lat' && (value === '' || (parsedValue >= -N90 && parsedValue <= N90))) {
        newValue = value === '' ? 0 : parsedValue;
      } else if (name === 'lng' && (value === '' || (parsedValue >= -N180 && parsedValue <= N180))) {
        newValue = value === '' ? 0 : parsedValue;
      }

      updateCoordinates(
        name === 'lat' ? newValue : clickedCoordinates.lat,
        name === 'lng' ? newValue : clickedCoordinates.lng
      );
    }
  };


  const searchHandler = () => {
    const payLoadtoSend = {
      scenario_id: simulationPayload.scenario_id,
      radius: radiusSlider * MILE_IN_METER,
      lat: clickedCoordinates?.lat,
      lng: clickedCoordinates?.lng,
      start_year: simulationPayload.start_year,
      end_year: simulationPayload.end_year,
      ev_traffic_prop: EVTraffic / N100,
      nrel_avg_dvmt: dailyMiles,
      nrel_pref_dist: preferenceHC,
      nrel_home_access_dist: accessHC,
      nrel_home_power_dist: homePDVal
    };
    setsimulationPayloadData(() => payLoadtoSend);
    dispatch(updateUnsavedChange(true));
    const performSimulationWithPayload = () => {
      performSimulation(payLoadtoSend);
    };
    setTimeout(performSimulationWithPayload, N100);
    setSelectedMarker(null);
    setClickedMarker(null);
    setNotSimulated(false);
  };

  const saveScenarioHandler = async (isSaveDuplicate: boolean) => {
    let scenarioIdUpdate = scenarioId;
    const evWorkBenchData = { ...simulationPayload, isSaveDuplicate };
    const payload = getEVWorkbenchPayload(scenarioDetails, evWorkBenchData);
    payload.lat = centerLocation.lat;
    payload.lng = centerLocation.lng;
    payload.radius = radiusMap;
    payload.evTrafficProp = EVTrafficScenario;
    payload.nrel_avg_dvmt = dailyMilesScenario;
    payload.nrel_pref_dist = preferenceHCScenario;
    payload.nrel_home_access_dist = accessHCScenario;
    payload.nrel_home_power_dist = homePDDefValScenario;
    let scenarioSaved = false;
    let simulationSaved = false;
    dispatch(startFullLoading());

    if (isSaveDuplicate) {
      const payloadDuplicate = getEVWorkbenchDuplicatePayload(evWorkBenchData);
      payloadDuplicate.radius = radiusMap;
      payloadDuplicate.lat = centerLocation.lat;
      payloadDuplicate.lng = centerLocation.lng;
      payloadDuplicate.nrel_avg_dvmt = dailyMilesScenario;
      payloadDuplicate.nrel_pref_dist = preferenceHCScenario;
      payloadDuplicate.nrel_home_access_dist = accessHCScenario;
      payloadDuplicate.nrel_home_power_dist = homePDDefValScenario;
      payloadDuplicate.evTrafficProp = EVTrafficScenario;
      const saveDuplicatePayload = { scenario_id: scenarioDetails.id, data: payloadDuplicate };
      try {
        const res = await saveWorkbenchEVAsDuplicate(saveDuplicatePayload);
        if ('data' in res) {
          scenarioIdUpdate = res?.data?.new_scenario_data?.id;
          scenarioSaved = true;
        }
      } catch (error) {
        console.error('Error saving duplicate scenario:', error);
      }
    } else {
      try {
        const res = await saveWorkbenchData(payload);
        if ('data' in res) {
          dispatch(updateUnsavedChange(false));
          scenarioSaved = true;
        }
      } catch (error) {
        console.error('Error saving scenario:', error);
      }
    }
    if (simulationData) {
      try {
        const payloadSimulation = { simulationData, scenarioId: scenarioIdUpdate };
        const maxRetries = N5;
        let retries = N0;
        let success = false;
        let errorOccured = false;

        while (!success && retries < maxRetries) {
          try {
            const res = await saveEVSimulationData(payloadSimulation);
            if ('data' in res) {
              success = true;
              simulationSaved = true;
            } else {
              throw new Error('Request failed');
            }
          } catch (error) {
            errorOccured = true;
            retries++;
            if (retries < maxRetries) {
              await new Promise((resolve) => setTimeout(resolve, N100));
            }
          }
        }

        if (!success && errorOccured) {
          throw new Error('Maximum retries reached');
        }
      } catch (error: any) {
        console.error('Maximum retries reached without success');
      }

      if (scenarioSaved && simulationSaved) {
        if (isSaveDuplicate) {
          toast.success(MESSAGES.EV_COPY_SIMULATION_CREATED);
        } else {
          toast.success(MESSAGES.EV_SIMULATION_SAVED);
        }
      } else {
        toast.error(MESSAGES.EV_SIMULATION_SAVED_ERROR);
      }
    }
    dispatch(stopFullLoading());
  };

  useEffect(() => {
    return () => {
      if (!window.location.href?.includes('/workbench?scenario_id')) {
        dispatch(resetWorkbenchEv());
      }
    };
  }, [scenarioDetails]);


  const commentToggleHandler = () => {
    setSelectedMarker(null);
    setCommentToggle(!commentToggle);
  };

  useEffect(() => {
    const {
      radius: localRadius,
      lat: localLat,
      lng: localLng,
      nrel_avg_dvmt: nrelAvgDvmt,
      nrel_pref_dist: nrelPrefDist,
      nrel_home_access_dist: nrelHomeAccess
    } = simulationPayloadData;
    if (!isLoading && isSuccess && (dailyMiles !== nrelAvgDvmt || accessHC !== nrelHomeAccess ||
      localRadius !== milesToMeters(radiusSlider) ||
      nrelPrefDist !== preferenceHC ||
      customStringValues[selectedValue - 1] !== homePDDefValScenario ||
      centerLocation.lat !== localLat || centerLocation.lng !== localLng
    )) {
      setNotSimulated(true);
    }

    return () => {
      setNotSimulated(false);
    };
  }, [dailyMiles, accessHC, centerLocation, homePDDefVal, preferenceHC, radiusSlider, EVTraffic, selectedValue]);

  return (
    <div className={`workbench-dashboard-main EV-workbench-bg ${workbenchTheme}`} ref={divRef}>
      <HeaderWorkbench divScrolled={isScrolled} navigateFrom={navigateFrom} isEVHeader={true} />
      <div className="workbench-dashboard-inner">
        <div className="dashboard-main-wrapper">
          <EVPluseStationCardHeader
            onSaveScenario={saveScenarioHandler}
            simulationSummary={summary}
            scenarioName={scenarioDetails?.name}
            scenarioDetails={scenarioDetails}
            notSimulated={notSimulated}
            closeMessage={setNotSimulated}
          />
          <BPPulseStationsMapForScratch
            markerClickHandler={markerClickHandler}
            infoWindowCloseHandler={infoWindowCloseHandler}
            maker={selectedMarker}
            radiusSlider={radiusSlider}
            radiusMap={radiusMap}
            sliderChangeHandler={sliderChangeHandler}
            searchHandler={searchHandler}
            simulationData={simulationData}
            centerLocation={centerLocation}
            showComment={commentToggle}
            commentToggleHandler={commentToggleHandler}
            scenarioId={scenarioId}
            mapComments={commentsData?.mapComments}
            commentCount={commentsData?.data?.length}
            commentUpdates={commentUpdates}
            setCommentUpdates={setCommentUpdates}
            currentEVData={currentEVData ?? null}
            chargingStationCountMetricData={chargingStationCountMetricData ?? null}
            avgWeekDemandData={avgWeekDemandData ?? null}
            scenarioDetails={scenarioDetails}
            clickedCoordinates={clickedCoordinates}
            clickedCoordinatesHandler={clickedCoordinatesHandler}
            updateCoordinates={updateCoordinates}
            clickedMarker={clickedMarker}
            setClickedMarker={setClickedMarker}
            setClickedCoordinates={setClickedCoordinates}
          />
          {/* EV Simulation Chart  */}
          <GraphHOC
            radioOptions={GRAPH_TYPES.ev}
            title={'Simulation Overview'}
            formType={GRAPH_FORM_TYPE.EV_FIELDS}
            scenarioId={scenarioId}
            simulationData={simulationData}
            simulationHandler={searchHandler}
            setEVTraffic={setEVTraffic}
            projType='ev'
            scenarioDetails={scenarioDetails}
            EVTraffic={EVTraffic}
            selectedValue={selectedValue}
            dailyMiles={dailyMiles}
            setDailyMiles={setDailyMiles}
            accessHC={accessHC}
            setAccessHC={setAccessHC}
            preferenceHC={preferenceHC}
            setPreferenceHC={setPreferenceHC}
            handleValueChange={handleValueChange}
          />
          {/* Cost Chart  */}
          <div className="simulation-chart-wrapper mitigation-chart ev-summary-table">
            <div className="mitigation-wrap">
              <div className="co2-wrap point-int-wrap">
                <div className="ev-scratch-co2-wrap">
                  <span className="point-int-img">
                    <img src={pointIntLogo} alt="logo img" />
                  </span>
                  <h4 className="section-head ev-section-head">
                    Point of Interest
                  </h4>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Radius</span>
                    <p className="mitigation-value">{`${radiusInMiles.toFixed(N1) || ''} miles`}{' '}</p>
                  </div>
                  <div className="co2-inner-wrap">
                    <span className="mitigation-label">Count</span>
                    <p className="mitigation-value">{POICount}</p>
                  </div>
                </div>
                <div className="co2-inner-wrap location-code-col">
                  <h4 className="section-head">Location</h4>
                  {clickedCoordinates.lat !== null && clickedCoordinates.lng !== null && (
                    <div className="location-code-container">
                      <div>
                        <span className="mitigation-label mitigation-label-latitude"> Latitude</span>
                        <p className="mitigation-value">{clickedCoordinates.lat !== null ? clickedCoordinates.lat.toFixed(N4) : ''}</p>
                      </div>
                      <div>
                        <span className="mitigation-label mitigation-label-longitude"> Longitude</span>
                        <p className="mitigation-value">{clickedCoordinates.lng !== null ? clickedCoordinates.lng.toFixed(N4) : ''}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mitigation-right-wrap">
                <div className="supply-right">
                  <div className="tax-credits co2-wrap">
                    <h4 className="section-head">Traffic Adjusted Fleet Size</h4>
                    <div className="suppy-credit-main-wrap">
                      <div className="suppy-credit-wrap">
                        <div className="tooltip-container">
                          <p className="credits-value">{fleetSizeFinal}</p>
                          <div className="tooltip-text">{currentEVData?.tooltip}</div>
                        </div>
                        <span className="credits-des">Current ({startYearSimulation})</span>
                      </div>
                      <div className="suppy-credit-wrap">
                        <div className="tooltip-container">
                          <p className="credits-value">{formatIntegerOnly(projectedEVData?.value as number)}</p>
                          <div className="tooltip-text">{projectedEVData?.tooltip}</div>
                        </div>
                        <span className="credits-des">Projected ({endYearSimulation})</span>
                      </div>
                    </div>
                  </div>
                  <div className="carbon-tax co2-wrap">
                    <span className="section-logo">
                      <img src={evCountLogo} alt="logo image" />
                    </span>
                    <h4 className="section-head">EV Growth Rate</h4>
                    <div className="suppy-credit-wrap">
                      <div className="tooltip-container">
                        <p className="credits-value">{growthPercFinal}%</p>
                        <div className="tooltip-text">{fleetSizePctChangeData?.tooltip}</div>
                      </div>
                      <span className="credits-des">({startYearSimulation}-{endYearSimulation})</span>
                    </div>
                  </div>
                </div>
                <div className="supply-nvp">
                  <div className="nvp-left co2-wrap">
                    <div className="tax-credits ev-demand-total">
                      <div className="suppy-credit-wrap npv-credit-wrap">
                        <p className="credits-value npv-value demand-head">
                          Total Demand (kWh)
                        </p>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Weekday
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekDayDemandFinal}</span>
                          <div className="tooltip-text">{avgWeekDayDemandData?.tooltip}</div>
                        </div>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Weekend
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekEndDemandFinal}</span>
                          <div className="tooltip-text">{avgWeekEndDemandData?.tooltip}</div>
                        </div>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Week
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekDemandFinal}</span>
                          <div className="tooltip-text">{avgWeekDemandData?.tooltip}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nvp-right co2-wrap">
                    <div className="tax-credits ev-demand-total">
                      <div className="suppy-credit-wrap npv-credit-wrap">
                        <p className="credits-value npv-value demand-head">
                          Total Visits
                        </p>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Weekday
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekdayVisitsFinal}</span>
                          <div className="tooltip-text">{avgWeekdayVisitsData?.tooltip}</div>
                        </div>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Weekend
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekEndVisitsFinal}</span>
                          <div className="tooltip-text">{avgWeekEndVisitsData?.tooltip}</div>
                        </div>
                      </div>
                      <div className="suppy-credit-wrap npv-credit-wrap ev-demand-total-inner">
                        <p className="credits-value npv-value demand-title">
                          Avg Week
                        </p>
                        <div className="tooltip-container">
                          <span className="demand-value">{avgWeekVisitsFinal}</span>
                          <div className="tooltip-text">{avgWeekVisitsData?.tooltip}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cost-wrap">
              <div className="cost-img-sec">
                <div className="cost-img-wrap">
                  <div className="cost-img-inner-wrap">
                    <img src={demographicLogo} alt="logo img" />
                  </div>
                </div>
                <div className="cost-text-wrap">
                  <h3 className="demographics-title">Demographics</h3>
                  <div className="demographics-left-wrap">
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Households</span>
                      <div className="tooltip-container">
                        <h2 className="cost-text-head">{householdsFinal}</h2>
                        <div className="tooltip-text">{householdsData?.tooltip}</div>
                      </div>
                    </div>
                    <div className="cost-text-inner-wrap">
                      <span className="mitigation-label">Population</span>
                      <div className="tooltip-container">
                        <h2 className="cost-text-head">{populationFinal}</h2>
                        <div className="tooltip-text">{populationData?.tooltip}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cost-value-one">
                <span className="section-logo">
                  <img src={estimateDemandLogo} alt="logo image" />
                </span>
                <h4 className="sstimated-head">Estimated Demand/Visit (kWh)</h4>
                <div className="tooltip-container">
                  <p className="sstimated-value">{formatAndRoundOffNumber(avgDemandPVData?.value as number, '-')}</p>
                  <div className="tooltip-text">{avgDemandPVData?.tooltip}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="simulation-summary-wrap">
            <div className="simulation-summary-inner">
              <h3 className="simulation-summary-head">Simulation Summary</h3>
              <p className="simulation-summary-des">{summary}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EVWorkbenchScratch;
