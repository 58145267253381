import React from 'react';
import { tonnesLoaderLogo } from '../../utils';
import { LOADER_TITLE } from '../../constants';

interface ILoaderProps {
  isLoading: boolean
  title?: string
}

function FullPageLoader({ isLoading, title }: ILoaderProps) {
  return (
    <div className={`full-page-loader ${isLoading ? 'show' : ''} `}>
      <div className="spinner-container">
        <span className="loader-animation spinner-style" role="status">
          <img src={tonnesLoaderLogo} alt=" tonnes logo" />
        </span>
        <span className="loader-text"> {title ?? LOADER_TITLE.GETTING_READY} </span>
      </div>
    </div>
  );
}

export default FullPageLoader;
