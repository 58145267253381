import React from 'react';
import AdminHoc from '../../components/layout/AdminHoc';
import UserManagementHeader from '../../components/admin/dashboard/UserManagementHeader';
import UserDashboardTable from '../../components/admin/dashboard/UserDashboardTable';

const UserManagement = () => {
  return (
    <AdminHoc>
      <div className='admin-section'>
        <div className='admin-body'>
          <UserManagementHeader />
          <div className="col-md-12">
            <UserDashboardTable />
          </div>
        </div>
      </div>
    </AdminHoc>
  );
};

export default UserManagement;
