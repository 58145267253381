import { toast } from 'react-toastify';
import { startFullLoading, stopFullLoading } from '../redux/SpinnerSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  IUpdatePayload,
  scenarioApis,
  useDuplicateScenarioMutation,
  useGetDetailsMutation,
  useMoveScenarioMutation,
  useRemoveScenarioMutation,
  useUpdateScenarioMutation
} from '../redux/services/scenarioApis';
import { IExportJSON, IScenarioDetails, MESSAGES, NUMBER, defaultViewport } from '../constants';
import { updateSavedNodes, updateWorkbenchData } from '../redux/workbenchSlice';
import { getScenarioDetails } from '../utils';
import { useSaveWorkbenchEVAsDuplicateMutation } from '../redux/services/workbenchApis';
import { updateScenarioDetails } from '../redux/slices/scenarioDetailsSlice';
import { projectApis } from '../redux/services/projectApis';


interface IMovePayload {
  scenarioId: string
  projectId: string
}

export const useScenario = () => {
  const [removeScenario] = useRemoveScenarioMutation();
  const [moveScenario] = useMoveScenarioMutation();
  const [duplicate] = useDuplicateScenarioMutation();
  const [updateScenrio] = useUpdateScenarioMutation();
  const [getScenarioDetailsData] = useGetDetailsMutation();
  const [saveWorkbenchEVAsDuplicate] = useSaveWorkbenchEVAsDuplicateMutation();
  const dispatch = useAppDispatch();
  const workbenchData = useAppSelector(state => state.workbench);
  const savedNodes = useAppSelector(state => state.workbench.savedNodes);

  const deleteScenario = (id: string, callback: () => void) => {
    dispatch(startFullLoading());
    removeScenario({ scenarioId: id })
      .then((res) => {
        if ('data' in res) {
          toast.success(MESSAGES.SCENARIO_DELETED);
          callback();
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
          dispatch(stopFullLoading());
        }
      });
  };
  const moveScenarioToProject = (payload: IMovePayload, callback: () => void) => {
    dispatch(startFullLoading());
    moveScenario(payload)
      .then((res: any) => {
        if ('data' in res) {
          const { new_project_folder_name, scenario_name } = res?.data;
          toast.success(`Scenario: ${scenario_name} is successfully moved to the Project: ${new_project_folder_name}`);
          dispatch(projectApis.util.invalidateTags(['GetProject']));
          dispatch(scenarioApis.util.invalidateTags(['GetScenario']));
          callback();
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
          dispatch(stopFullLoading());
        }
      });
  };

  const duplicateScenario = (id: string, scenario: any, callback: () => void, isSharedWithMeFolder = false) => {
    // from shared with me folder call 'duplicate-scenario' route for ev scenario as well...
    if (!isSharedWithMeFolder && (scenario?.Subproject_type === 'ev' || scenario?.subProject_Type === 'ev')) {
      dispatch(startFullLoading());
      getScenarioDetailsData(id).then(async (resp: any) => {
        if (resp.data) {
          const data = resp.data;
          const payload = { lat: data?.lat, lng: data?.lng, startYear: data?.startYear, endYear: data?.endYear, radius: data?.radius };
          const saveDuplicatePayload = { scenario_id: id, data: payload };
          const resData = await saveWorkbenchEVAsDuplicate(saveDuplicatePayload);
          if ('data' in resData) {
            toast.success(scenario.permission ? MESSAGES.SHARED_SCENARIO_DUPLICATED : MESSAGES.SCENARIO_DUPLICATED);
            callback();
          } else {
            toast.error(MESSAGES.SOMETHING_WENT_WRONG);
            dispatch(stopFullLoading());
          }
        }
      });
    } else {
      dublicateNormalDcenario(scenario, id, callback);
    }
  };

  const dublicateNormalDcenario = (scenario: any, id: string, callback: () => void) => {
    dispatch(startFullLoading());
    duplicate({ scenarioId: id })
      .then((res) => {
        if ('data' in res) {
          toast.success(scenario.permission ? MESSAGES.SHARED_SCENARIO_DUPLICATED : MESSAGES.SCENARIO_DUPLICATED);
          callback();
        } else {
          toast.error((res.error as any).detail);
          dispatch(stopFullLoading());
        }
      });
  };

  const updateScenario = (payload: IUpdatePayload, callback: () => void) => {
    dispatch(startFullLoading());
    updateScenrio(payload)
      .then((res) => {
        if ('data' in res) {
          toast.success(MESSAGES.SCENARIO_UPDATED);
          callback();
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
          dispatch(stopFullLoading());
        }
      });
  };

  /**
   * @description Update the workbench data in the store after getScenarioDetails api call...
   * @param scenarioDetails : complete details of scenario...
   */

  const updateWorkbech = (scenarioDetails: IScenarioDetails) => {
    const details = getScenarioDetails(scenarioDetails);
    const workbench = {
      nodes: (scenarioDetails.exportJSON as IExportJSON)?.nodes || [],
      savedNodes: (scenarioDetails.exportJSON as IExportJSON)?.nodes || [],
      edges: (scenarioDetails.exportJSON as IExportJSON)?.edges || [],
      viewPort:
        (scenarioDetails.exportJSON as IExportJSON)?.viewPort ||
        defaultViewport,
      scenarioDetails: details,
      importCount: workbenchData.importCount + NUMBER.N1
    };
    dispatch(updateScenarioDetails(details));
    dispatch(updateWorkbenchData(workbench));
  };

  /**
  * @returns Update the simulation node properties in the workbench
  */
  const updateSimulationNodeProperties = async (values: any, multiplier?: number, hydrogenmultiplier?: number, co2Multiplier?: number) => {
    return await new Promise((resolve, reject) => {
      const updatedSavedNodes = savedNodes.map(node => {
        let nodeToUpdate: any;
        if (node.data.class === 'schedule') {
          nodeToUpdate = {
            ...node,
            data: {
              ...node.data,
              multiplier
            }
          };
        } else if (node.data.class === 'hydrogen') {
          nodeToUpdate = {
            ...node,
            data: {
              ...node.data,
              multiplier: hydrogenmultiplier
            }
          };
        } else if (node.data.class === 'gaseous_co2_demander') {
          nodeToUpdate = {
            ...node,
            data: {
              ...node.data,
              multiplier: co2Multiplier
            }
          };
        } else {
          nodeToUpdate = {
            ...node,
            data: {
              ...node.data,
              propertiesValues: {
                ...node.data.propertiesValues
              }
            }
          };
        };
        Object.keys(values).forEach(key => {
          const [id, property] = key.split('#');
          if (typeof values[key] !== 'object' && node.id === id) {
            nodeToUpdate.data[property] = values[key];
            nodeToUpdate.data.propertiesValues[property] = values[key];
          }
        });
        return nodeToUpdate;
      });
      dispatch(updateSavedNodes(updatedSavedNodes));
      resolve(updatedSavedNodes);
    });
  };

  return {
    deleteScenario,
    moveScenarioToProject,
    duplicateScenario,
    updateScenario,
    updateWorkbech,
    updateSimulationNodeProperties
  };
};
