/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useRef, useState } from 'react';
import { hasPermissionToChangeComponents, plusDarkIcon, plusLogo } from '../../utils';
import Select from 'react-select';
import { ICategoryList, IObjectList, NUMBER, SHARED_SCENARIO_PERMISSION, USER_PERMISSIONS } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import AddNewObject from '../admin/object/AddNewObject';
import ObjectSearch from '../shared/ObjectSearch';

interface IObjectListProps {
  categoryList: ICategoryList[]
  objectList: IObjectList[]
}

function ObjectList(props: IObjectListProps) {
  const { categoryList, objectList } = props;
  const theme = useAppSelector(state => state.workbench.theme);
  const [ShowAddObject, setShowAddObject] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const userData = useAppSelector(state => state.auth);
  const { permission, BaselineStatus } = useAppSelector(state => state.workbench.scenarioDetails);
  const [selectedCategory, setSelectedCategory] = useState(categoryList[NUMBER.N0]);
  const [imgSrc, setImgSrc] = useState('');
  const dragPreview = useRef(null);

  // handled change component permissions...
  const isCollaboratorOrLockScenario = permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR || BaselineStatus;
  const isDisableObjectMenu = userData.permissions?.includes(USER_PERMISSIONS.disable_object_menu);
  const disabledClass = (isCollaboratorOrLockScenario || isDisableObjectMenu) ? 'disabled-object' : '';

  const handleChange = (object: any) => {
    setSelectedCategory(object);
  };

  const handleOnSearch = (val: string) => {
    setSearchVal(val);
  };

  const clearSearch = () => {
    setSearchVal('');
    setShowSearchInput(false);
  };

  const onDragStart = (event: React.DragEvent, node: IObjectList) => {
    event.dataTransfer.setData('application/objectData', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'move';
    setImgSrc(node.imageS3url);
    if (dragPreview.current) {
      event.dataTransfer.setDragImage(dragPreview.current, NUMBER.N80, NUMBER.N80);
    }
  };

  let filterData: any[] = [];

  if (showSearchInput) {
    filterData = objectList.filter((obj) => {
      return obj.componentDisplayName?.toLowerCase()?.includes(searchVal?.toLowerCase()?.trim());
    });
  } else {
    filterData = objectList.filter((obj) => {
      return obj.category.includes(selectedCategory.value);
    });
  }

  const adminRole = userData.user_role === 'admin';

  return (
    <>
      <div className="custom-select-main object-select">
        <>
          <ObjectSearch searchVal={searchVal} onSearchType={handleOnSearch} clearSearch={clearSearch}
            showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} />
        </>
        {!showSearchInput && <Select
          className="custom-select-wrp"
          classNamePrefix="select"
          defaultValue={selectedCategory}
          isDisabled={!!disabledClass}
          isClearable={false}
          isSearchable={true}
          name="color"
          options={categoryList}
          onChange={handleChange}
        />}
      </div>
      <div className="drag-drop-main">
        {filterData?.length > 0 && <h5 className="drag-drop-head">Select an Object</h5>}
        <div className='drag-drop-main-wrap'>
          <div className="drag-drop-wrapper">
            {!showSearchInput && <div className={`drag-drop-item ${!adminRole && 'not-allowed'}`} title={`${!adminRole ? 'Coming Soon' : 'Create New Object'}`}
              onClick={() => adminRole && setShowAddObject(true)}>
              <img
                src={theme ? plusDarkIcon : plusLogo}
                alt="img logo"
                className="drag-drop-img add-logo"
              />
              <span className="drag-drop-text new-object">
                New Object
              </span>
            </div>}
            {
              filterData.map((obj) => {
                return (
                  <div className={`drag-drop-item ${disabledClass}`} key={obj._id}
                    onClick={() => setImgSrc(obj.imageS3url)} onDragStart={(event) => onDragStart(event, obj)} draggable>
                    <img
                      src={theme ? obj.imageS3urlDark : obj.imageS3url}
                      alt="img logo"
                      className="drag-drop-img"
                    />
                    <span className="drag-drop-text">{obj.componentDisplayName}</span>
                  </div>
                );
              })
            }
            {
              !filterData?.length && <span className="no-object">No objects found!</span>
            }
          </div>
        </div>
        <div className="drag-hint-wrapper">
          <span className="icon-dragdrop-icon"></span>
          <span className="drag-hint-text">
            Drag & Drop an object onto your workbench
          </span>
        </div>
      </div>
      {/* Custom Drag Preview */}
      <div className='preview-container' ref={dragPreview}>
        <div id='drag-preview' className="custom-drag-preview">
          <img height="40px" src={imgSrc} alt="Preview" />
        </div>
      </div>
      {adminRole && <AddNewObject ShowAddObject={ShowAddObject} setShowAddObject={setShowAddObject}
      />}
    </>
  );
};

export default ObjectList;
