import React from 'react';

function ZoomInOut(props: any) {
  const { className, tooltip, zoomClass, image } = props;

  return (
    <div className="tooltip-container">
      <div className="setting-btn btn-no-outline">
        <div className={`circle-logo-wrapper ${zoomClass}`}>
          <span className={`icon ${className}`}>
            <img src={image} alt="logo" />
          </span>
        </div>
      </div>
      <div className="tooltip-text up">{tooltip}</div>
    </div>
  );
}

export default ZoomInOut;
