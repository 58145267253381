import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { modalBgImg, evImageScenario } from '../../utils/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ALL_PROJECT, CREATE_SCENARIO_SCHEMA, CREATE_SCENARIO_WITH_AI, CREATE_SCENARIO_WITH_WIZARD, getLocalStorageItem, getWorkbenchURL, hasFieldError } from '../../utils';
import { CREATE_FROM_SCRATCH, CREATE_WITH_AI, CREATE_WITH_WIZARD, EV, IScenario, MAX_LENGTH, MESSAGES, NUMBER, PROJECT_TYPE } from '../../constants';
import { toast } from 'react-toastify';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import { FormikProps, Formik } from 'formik';
import InputElement from '../../components/shared/InputElement';
import { IUpdatePayload, useGetScenarioDetailsQuery, useSaveScenarioMutation } from '../../redux/services/scenarioApis';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { LABELS } from '../../constants/labelConstant';
import { projectApis } from '../../redux/services/projectApis';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { useScenario } from '../../hooks/useScenario';

const CreateScenario = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const scenarioType = searchParams.get('scenario_type') ?? '';
  const { updateScenario } = useScenario();
  const { data: scenarioDetails, isSuccess } = useGetScenarioDetailsQuery(scenarioId, { skip: !scenarioId });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.auth);
  const [saveScenario, { isLoading }] = useSaveScenarioMutation();
  const [showAlert, setShowAlert] = useState(false);
  const formikRef = useRef<FormikProps<IScenario> | null>(null);
  const projectDetails: any = getLocalStorageItem('selectedProject');
  const [projectData, setProjectData] = useState(projectDetails);

  useEffect(() => {
    if (scenarioId) {
      dispatch(startFullLoading());
    }
  }, [scenarioId]);

  useEffect(() => {
    if (isSuccess) {
      formikRef.current?.setValues({
        name: scenarioDetails.name,
        description: scenarioDetails.description,
        project_type: scenarioDetails.subProject_Type
      });
      setProjectData({
        name: scenarioDetails.projectName,
        _id: scenarioDetails.projectID,
        project_type: scenarioDetails.subProject_Type
      });
      dispatch(stopFullLoading());
    }
  }, [isSuccess]);

  const handleFormSubmit = async (values: IScenario) => {
    if (scenarioId) {
      update(values);
    } else {
      save(values);
    }
  };
  const save = (values: IScenario) => {
    const payload = {
      projectID: projectData._id,
      name: values.name,
      description: values.description,
      userCreator: userData.email,
      subProject_Type: projectData.project_type,
      scenarioCreatedType: scenarioType
    };
    saveScenario(payload)
      .then((res: any) => {
        if (res.data) {
          toast.success(MESSAGES.SCENARIO_CREATED);
          if (projectData.project_type === EV) {
            if (scenarioType === CREATE_FROM_SCRATCH) {
              const url = getWorkbenchURL(res?.data?.id, EV);
              navigate(`${url}&scenario_type=${CREATE_FROM_SCRATCH}`, {
                state: {
                  project: projectDetails,
                  navigateFrom: LABELS.CREATE_SCENARIO
                }
              });
            } else {
              navigate(`${CREATE_SCENARIO_WITH_AI}?scenario_id=${res.data.id}&scenarioName=${values.name}`, {
                state: {
                  project: projectDetails,
                  navigateFrom: LABELS.CREATE_SCENARIO
                }
              });
            }
            dispatch(projectApis.util.invalidateTags(['GetProject']));
          } else {
            if (scenarioType === CREATE_WITH_WIZARD) {
              navigate(`${CREATE_SCENARIO_WITH_WIZARD}?scenario_id=${res.data.id}&scenarioName=${values.name}&type=${projectData.project_type}`, {
                state: {
                  project: projectDetails,
                  navigateFrom: LABELS.CREATE_SCENARIO
                }
              });
            } else {
              navigate(getWorkbenchURL(res.data.id, projectData.project_type), {
                state: {
                  project: projectDetails,
                  navigateFrom: LABELS.CREATE_SCENARIO
                }
              });
            }
            dispatch(projectApis.util.invalidateTags(['GetProject']));
          }
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };

  const update = (values: IScenario) => {
    const payload: IUpdatePayload = {
      scenario_id: scenarioId,
      name: values.name,
      description: values.description
    };
    updateScenario(payload, () => {
      navigate(-NUMBER.N1);
    });
  };

  const initialValues: IScenario = {
    name: '',
    description: '',
    project_type: ''
  };

  const showDiscardAlert = () => {
    if (formikRef.current) {
      const formValues = formikRef.current.values;
      // show alert if user enters any one of them while save scenario...
      if ((formValues.description || formValues.name) && !scenarioId) {
        setShowAlert(true);
      } else {
        navigate(-NUMBER.N1);
      }
    }
  };

  return (
    <>
      <div className="change-pw-modal create-modal">
        <button className="close-btn" onClick={showDiscardAlert}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="modal-dialog full-width-dialog">
          <div className="modal-content">
            <Modal.Body>
              <Modal.Title>{`${scenarioId ? 'Update' : 'Create'} a Scenario`}</Modal.Title>
              <p className="modal-title-des">
                Type: <span className="title-des-bold">{PROJECT_TYPE[projectData.project_type]}</span>
              </p>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className="create-project-inner">
                    <div className="create-modal-left">
                      <div className="modal-left-img">
                        <img src={projectData?.project_type === 'ev' ? evImageScenario : modalBgImg} alt="modal" />
                      </div>
                    </div>
                    <div className="create-modal-right">
                      <p className="modal-title-des" style={{ textAlign: 'left', marginBottom: '20px' }}>
                        Project: <span className="title-des-bold">{projectData.name}</span>
                      </p>
                      <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={CREATE_SCENARIO_SCHEMA}
                        onSubmit={handleFormSubmit}
                      >
                        {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                          <Form className="full-width-form" onSubmit={handleSubmit}>
                            {/* Project Name */}
                            <InputElement
                              label='Name'
                              placeholder="Name"
                              maxLength={MAX_LENGTH.CHAR_LENGTH_50}
                              type='text'
                              value={values?.name}
                              name='name'
                              data-testid="name"
                              required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              resetError={setFieldError}
                              hasError={hasFieldError(errors, touched, 'name')}
                            />
                            {/* Project description */}
                            <InputElement
                              label='Description'
                              required={true}
                              type='textarea'
                              value={values?.description}
                              maxLength={MAX_LENGTH.company_desc}
                              rows={5}
                              name='description'
                              data-testid="description"
                              onChange={handleChange}
                              resetError={setFieldError}
                              onBlur={handleBlur}
                              hasError={hasFieldError(errors, touched, 'description')}
                            />
                            <div className="sign-btn-wrapper">
                              <Button variant="primary" type="submit">
                                {isLoading ? <Spinner /> : `${scenarioId ? 'Update Scenario' : 'Save & Proceed'}`}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={LABELS.discardAlertMessage('Scenario')}
        message={MESSAGES.SCENARIO_DISCARD_MESSAGE}
        yesBtnText={LABELS.YES_DISCARD}
        cancleBtnText={LABELS.KEEP_CREATING}
        onConfirm={() => navigate(ALL_PROJECT)}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

export default CreateScenario;
