import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationAlert from '../../components/shared/ConfirmationAlert';
import {
  CHAT_ROLE_ASSISTANT,
  CHAT_ROLE_USER,
  EV,
  IMessage,
  IPayloadMessage,
  MESSAGES,
  NUMBER,
  TWO_MILES_IN_METERS
} from '../../constants';
import { LABELS } from '../../constants/labelConstant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useCreateScenarioWithAIMutation } from '../../redux/services/scenarioWithAIApis';
import { SCENARIOS, getEVWorkbenchDuplicatePayload, getWorkbenchURL } from '../../utils';
import { aiMapImg, chatAiLogo, chatSendBtn, leavesLogo, redirectArrow } from '../../utils/icons';
import { useSaveWorkbenchDataMutation } from '../../redux/services/workbenchApis';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';

const CreateScenarioWithAI = () => {
  const userData = useAppSelector((state) => state.auth);
  const [createScenarioWithAI, { isLoading }] = useCreateScenarioWithAIMutation();
  const msgEnd = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const scenarioName = searchParams.get('scenarioName') ?? '';
  const [showAlert, setShowAlert] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isCloseAlert, setIsCloseAlert] = useState(true);
  const [inputValue, setInputValue] = useState<string>('');
  const [isChecked, setIsChecked] = useState(true);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messagesPayload, setMessagesPayload] = useState<IPayloadMessage[]>([]);
  const [saveWorkbenchData, { isLoading: saveWorkbenchLoading }] = useSaveWorkbenchDataMutation();
  const oldSimulation = location.state ? location.state.oldSimulation : null;

  const showDiscardAlert = () => {
    if (messages.length > NUMBER.N0) {
      setIsCloseAlert(true);
      setShowAlert(true);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (oldSimulation) {
      const response = {
        new_message: `Certainly, Here's a simulation for the Prospect BP Pulse Charging Stations within the ${oldSimulation?.zipCode}  area code with latitude
         ${oldSimulation?.lat} and longitude ${oldSimulation?.lng} , covering a radius of ${oldSimulation?.radius} meters and projecting from
         ${oldSimulation?.start_year} to ${oldSimulation?.end_year}.`,
        sender: CHAT_ROLE_ASSISTANT,
        new_json: oldSimulation,
        ready: true
      };

      const newMessage: IMessage = {
        text: response.new_message,
        sender: CHAT_ROLE_ASSISTANT,
        simulationData: JSON.stringify(response.new_json),
        ready: response.ready,
        time: ''
      };
      setMessages([newMessage]);
    }
  }, []);

  useEffect(() => {
    if (msgEnd.current) {
      msgEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const sendMessage = (messageText: string) => {
    const newMessage: IMessage = {
      text: messageText,
      sender: CHAT_ROLE_ASSISTANT,
      simulationData: null,
      ready: false,
      time: moment().format('h:mm A')
    };
    setMessages([...messages, newMessage]);
    setIsBlocking(true);
  };

  const handleSubmit = (
    values: { message: string },
    { resetForm }: FormikHelpers<{ message: string }>
  ) => {
    if (values.message.trim() !== '') {
      const finalMessage = values.message.replace(/\n/g, ' ');
      sendMessage(finalMessage);
      const updatedPayload = [
        ...messagesPayload,
        {
          role: CHAT_ROLE_ASSISTANT,
          content: finalMessage
        }
      ];
      const payload = {
        'mode': isChecked ? 'gpt' : 'llama ',
        messages: updatedPayload
      };

      setMessagesPayload(updatedPayload);
      createScenarioWithAI(payload)
        .then((res: any) => {
          if (res.data) {
            const response = res.data;
            setMessagesPayload((prevMessages) => [
              ...prevMessages,
              {
                role: CHAT_ROLE_USER,
                content: response.message
              }
            ]);
            setMessages((prevMessages) => {
              const newMessage: IMessage = {
                text: response.message,
                sender: CHAT_ROLE_USER,
                simulationData: response.json,
                ready: response.ready,
                time: moment().format('h:mm A')
              };
              return [...prevMessages, newMessage];
            });
          } else {
            toast.error(MESSAGES.SOMETHING_WENT_WRONG);
          }
        })
        .catch((err: any) => {
          toast.error(err.message);
        });
      const textarea = document.getElementById('chatMessageTextarea');
      if (textarea) {
        textarea.style.height = `${NUMBER.N25}px`;
      }
      resetForm();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const goBack = () => {
    if (location?.state?.navigateFrom === 'CREATE_SCENARIO' || location?.state?.navigateFrom === 'EVSimulation') {
      const project = location?.state?.project ?? {};
      navigate(`${SCENARIOS}?projectId=${project._id}&name=${project.name}&projectType=${project.project_type}`);
    } else {
      navigate(-NUMBER.N1);
    }
  };

  const onConfirmation = () => {
    if (isCloseAlert) {
      goBack();
    } else {
      setMessages([]);
      setMessagesPayload([]);
      setShowAlert(false);
      setIsCloseAlert(true);
    }
  };
  const clearChatAlert = () => {
    setIsCloseAlert(false);
    setShowAlert(true);
  };

  const goToEVSimulation = (message: IMessage) => {
    if (message.simulationData) {
      const parseData = JSON.parse(message.simulationData);
      let radius = Number(parseData?.radius || NUMBER.N100);
      let isRadiusMoreThen2Miles = false;
      if (radius > TWO_MILES_IN_METERS) {
        radius = TWO_MILES_IN_METERS;
        isRadiusMoreThen2Miles = true;
      }
      const payloadData = {
        scenario_id: scenarioId,
        lat: Number(parseData.lat),
        lng: Number(parseData.lng),
        start_year: parseData.start_year,
        end_year: parseData.end_year,
        radius
      };
      const saveWorkBench = getEVWorkbenchDuplicatePayload(payloadData);
      const savePayload = { ...saveWorkBench, id: scenarioId, theme: '', name: scenarioName };
      dispatch(startFullLoading());
      saveWorkbenchData(savePayload).then((res: any) => {
        if ('data' in res) {
          const url = getWorkbenchURL(scenarioId, EV);
          navigate(url, {
            state: {
              simulationData: payloadData,
              navigateFrom: LABELS.CREATE_WITH_AI,
              isRadiusMoreThen2Miles
            }
          });
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
        dispatch(stopFullLoading());
      });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isBlocking) {
        const confirmationMessage = MESSAGES.SCENARIO_DISCARD_MESSAGE;
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);

  const handleResize = (event: any) => {
    const textarea = event.target;
    textarea.style.height = `${NUMBER.N25}px`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="change-pw-modal create-modal ev-chat-modal">
        <button className="close-btn" onClick={showDiscardAlert}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className="modal-dialog full-width-dialog">
          <div className="modal-content">
            <Modal.Body>
              <div className="create-modal-wrapper">
                <div className="container">
                  <div className="row clearfix">
                    <div className="col-lg-12">
                      <div className="card chat-app">
                        <div id="plist" className="people-list">
                          <div className="ev-ai-chat-box1">
                            <p className="ev-ai-chat-des">
                              Hello! <br />
                              Do you need help with<br /> your model?
                            </p>
                            <span className="leaves-logo">
                              <img src={leavesLogo} alt="logo" />
                            </span>
                          </div>
                          <div className="ev-ai-chat-box1 ev-ai-chat-box2">
                            <span className="profile-icon">a</span>
                            <p className="ev-ai-chat-des">
                              Run an analysis for San Francisco, California with a radius of 500 meters from 2023 to 2035
                            </p>
                          </div>
                          <div>
                            <h2 className="ev-ai-chat-title">
                              Describe your simulation & Dsider AI will set it
                              up for you
                            </h2>
                            <p className="ev-ai-chat-des">
                              Dsider’s AI for your assistance
                            </p>
                            <Form>
                              <div className='ai-chat-switch-wrap'>
                                <span className={`switch-btn-label ${!isChecked ? 'active' : ''}`}>Llama</span>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                  onChange={handleSwitchChange}
                                  checked={isChecked}
                                />
                                <span className={`switch-btn-label switch-btn-label-right ${isChecked ? 'active' : ''}`}>ChatGPT</span>
                              </div>
                            </Form>

                          </div>
                        </div>
                        <div className="chat">
                          <div className="ev-chat-header">
                            <div className="ev-chat-header-left">
                              <span className="ev-chat-header-logo">
                                <img src={chatAiLogo} alt="logo" />
                              </span>
                              <span className="ev-chat-header-des">
                                Dsider AI
                              </span>
                            </div>
                            <div className="ev-chat-header-right">
                              <Button
                                className="btn-no-outline"
                                disabled={messages.length === NUMBER.N0}
                                onClick={clearChatAlert}
                              >
                                Clear All
                              </Button>
                            </div>
                          </div>
                          <div className='chat-history-main'>
                            <div className="chat-history">
                              <div className="scrollbar">
                                <ul className="m-b-0">
                                  {messages.map((message, index) => (
                                    <li className="clearfix chat-wrap" key={index}>
                                      {!message.ready && (
                                        <div
                                          className={`message ${message.sender === CHAT_ROLE_ASSISTANT
                                            ? 'other-message'
                                            : 'my-message'
                                            }`}
                                        >
                                          {message.text}
                                        </div>
                                      )}

                                      {message.ready ? (
                                        <div className="message my-message">
                                          {message.text}
                                          <div className="ai-img-msg">
                                            <img
                                              src={aiMapImg}
                                              alt="logo"
                                              className="ai-map-img"
                                            />
                                            <Button
                                              className="simulation-summary-btn"
                                              onClick={() =>
                                                goToEVSimulation(message)
                                              }
                                            >
                                              Go to EV Simulation
                                              <img
                                                src={redirectArrow}
                                                alt="logo"
                                                className="redirectArrow-chat"
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={`message-data ${message.sender === CHAT_ROLE_ASSISTANT
                                            ? 'other-message-data'
                                            : 'my-message-data'
                                            }`}
                                        >
                                          <span className="message-data-time">
                                            {message.time}
                                          </span>
                                          <span
                                            className={`profile-icon ${message.sender === CHAT_ROLE_ASSISTANT
                                              ? 'profile-icon'
                                              : 'profile-icon d-none'
                                              }`}
                                          >
                                            {userData?.email
                                              .charAt(NUMBER.N0)
                                              .toUpperCase()}
                                          </span>
                                        </div>
                                      )}

                                      <div ref={msgEnd} />
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="chat-message clearfix">
                            <Formik
                              initialValues={{ message: '' }}
                              onSubmit={handleSubmit}
                            >
                              {({
                                handleChange,
                                handleBlur,
                                values,
                                handleSubmit
                              }) => (
                                <Form
                                  onSubmit={handleSubmit}
                                  className="chat-ai-form"
                                >
                                  <div className="ai-chatbox-textarea">
                                    <Form.Control
                                      id="chatMessageTextarea"
                                      as="textarea"
                                      rows={NUMBER.N3}
                                      onInput={handleResize}
                                      maxLength={NUMBER.N600}
                                      name="message"
                                      placeholder="Write your prompt here..."
                                      value={values.message}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleInputChange(e);
                                        handleChange(e);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                          e.preventDefault();
                                          handleSubmit();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <Button
                                      type="submit"
                                      className="send-button"
                                      disabled={!inputValue.trim()}
                                    >
                                      {isLoading ? (
                                        <Spinner />
                                      ) : (
                                        <img src={chatSendBtn} alt="logo" />
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>
      <ConfirmationAlert
        showAlert={showAlert}
        title={isCloseAlert ? LABELS.DISCARD_CHAT : LABELS.CLEAR_CHAT_BOARD}
        message={isCloseAlert ? LABELS.DISCARD_CHAT_PAGE : MESSAGES.SCENARIO_CHAT_MESSAGE}
        yesBtnText={LABELS.YES_CLEAR}
        cancleBtnText={LABELS.KEEP_CREATING}
        onConfirm={onConfirmation}
        onCancel={() => setShowAlert(false)}
      />
    </>
  );
};

export default CreateScenarioWithAI;
