import { GeoThermalFormCategory, MESSAGES, NUMBER } from '../constants';
import { changeSpinnerTitle, startFullLoading, stopFullLoading } from '../redux/SpinnerSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useGetGeoThermalSimulationDataMutation } from '../redux/services/simulationApi';
import defaultPayload from '../constants/geoThermalPayload.json';
import { Node } from 'reactflow';
import { HTTPS_STATUS_CODE } from '../constants/api.constant';
import { toast } from 'react-toastify';
import { updateSimulationMetricsDatas } from '../redux/slices/simulationMetricsDataSlice';
import { useLazyGetMonteCarloSimulationResultQuery, useRunMonteCarloSimulationApiMutation } from '../redux/services/monteCarloApi';

export const useGeoThermalSimulation = () => {
  const dispatch = useAppDispatch();
  const workBenchSetting = useAppSelector(state => state.WorkbenchSettings);
  const { savedNodes } = useAppSelector(state => state.workbench);

  const [saveGeoThermalSimulation] = useGetGeoThermalSimulationDataMutation();

  const [runMonteCarloSimulationApi] = useRunMonteCarloSimulationApiMutation();
  const [getMonteCarloSimResultApi] = useLazyGetMonteCarloSimulationResultQuery();

  const simulateGeoThermal = (simData: any, updatedNodes: Node[], callback: (e: any) => void) => {
    if (+workBenchSetting.discountRate > NUMBER.N1) { // added a validation for discount rate...
      toast.error(MESSAGES.DISCOUNT_VALIDATION);
      return;
    }

    dispatch(changeSpinnerTitle(MESSAGES.SIMULATION_STARTED));

    const payload = {
      ...defaultPayload,
      ...getWorkbenchNodePropertiesValues(updatedNodes),
      economic_model: +workBenchSetting.economic_model,
      inflation_rate_during_construction: +workBenchSetting.inflation_rate_during_construction,
      fixed_charge_rate: +workBenchSetting.fixed_charge_rate,
      discount_rate: +workBenchSetting.discountRate,
      plant_lifetime: +workBenchSetting.noOfYears
    };

    saveGeoThermalSimulation({ payload, subprojectid: simData.subProjectId, is_dashboard: simData.isDashboard }).then((res) => {
      if ('data' in res) {
        callback(res.data);
        dispatch(updateSimulationMetricsDatas({ ...res.data?.result }));
      } else {
        const { status, data } = res.error as any;
        if (status === HTTPS_STATUS_CODE.Unprocessable_Content) {
          const errDetails = data?.detail[0];
          const errorMsg = `GEOPHIRES encountered an exception: Error: Parameter given (${errDetails?.input}) for ${errDetails?.loc[1]} is not valid.`;
          toast.error(errorMsg);
        } else {
          toast.error(MESSAGES.GENERIC_ERROR_MESSAGE);
        }
      }
      dispatch(stopFullLoading());
    });
  };

  const getWorkbenchNodePropertiesValues = (nodes: Node[]) => {
    let res = {};
    nodes.map((n) => {
      if (n.data.sensitivityFormCategory === GeoThermalFormCategory) {
        res = {
          ...res,
          ...n.data.propertiesValues
        };
      }
    });
    return res;
  };


  /**
   * Function to run the monte carlo simulation...
   */
  const runMonteCarloSimulation = () => {
    // dispatch(runMonteCarloSimulation());
    const payload = {
      ...defaultPayload,
      ...getWorkbenchNodePropertiesValues(savedNodes),
      economic_model: +workBenchSetting.economic_model,
      inflation_rate_during_construction: +workBenchSetting.inflation_rate_during_construction,
      fixed_charge_rate: +workBenchSetting.fixed_charge_rate
    };

    return payload;
  };

  const runMontiCarloApi = (payload: any, id: string, callback: (e: any) => void) => {
    dispatch(changeSpinnerTitle(MESSAGES.SIMULATION_STARTED));

    runMonteCarloSimulationApi({ formData: payload, id }).then((res) => {
      if ('data' in res) {
        callback(res.data);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
      dispatch(stopFullLoading());
    }).catch(error => {
      console.log('monte carlo error', error);
    });
  };

  /**
   * function to return the monte carlo simulation result...
   * @param id scenario id
   * @param callback callback function to return api response data...
   */
  const getMonteCarloSimResult = (id: string, callback: (e: any) => void) => {
    dispatch(startFullLoading());
    getMonteCarloSimResultApi(id).then((res) => {
      if ('data' in res) {
        callback?.(res.data);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
      dispatch(stopFullLoading());
    });
  };

  // MONTE_CARLO_SIMULATION_API

  return { simulateGeoThermal, runMonteCarloSimulation, runMontiCarloApi, getMonteCarloSimResult };
};
