import { RangeSelectorButtonsOptions } from 'highcharts';
import { NUMBER } from './numbers';
import { totalPowerConsumed, totalPowerProduced } from './static';
import { COMPONENT_LABELS } from './labelConstant';


export const EV_GRAPH_TYPE = {
  FLEET_SIZE: 'Fleet Size',
  AVG_DEMAND: 'Avg Demand (kW)',
  AVG_VISITS: 'Avg Visits'
};

const PowerSummary = 'Power Summary';
const PowerDetails = 'Power Details';

export const SCENARIO_GRAPH_TYPE = {
  POWER_SUMMARY: PowerSummary,
  POWER_DETAILS: PowerDetails,
  NATURAL_GAS: 'Natural Gas',
  SCENARIO_TYPE_SUMMARY: 'Scenario Summary',
  SCENARIO_TYPE_DETAIL: 'Scenario Detail',
  HYDROGEN_BREAKDOWN: 'Hydrogen breakdown',
  POWER_GENERATION_PROFILE: 'POWER GENERATION PROFILE',
  HEAT_GENERATION_PROFILE: 'HEAT AND/OR ELECTRICITY EXTRACTION AND GENERATION PROFILE',
  REVENUE_PROFILE: 'REVENUE & CASHFLOW PROFILE'
};

const PowerRadioButtons = [
  { label: PowerSummary, value: SCENARIO_GRAPH_TYPE.POWER_SUMMARY },
  { label: PowerDetails, value: SCENARIO_GRAPH_TYPE.POWER_DETAILS }
];

const HydrogenRadioButtons = [
  ...PowerRadioButtons,
  { label: 'Hydrogen Summary', value: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_SUMMARY },
  { label: 'Hydrogen Details', value: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_DETAIL },
  { label: 'Hydrogen Shortfall', value: SCENARIO_GRAPH_TYPE.HYDROGEN_BREAKDOWN }
];

const CcusRadioButtons = [
  { label: 'Carbon Capture Summary', value: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_SUMMARY },
  { label: 'Carbon Capture Details', value: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_DETAIL },
  { label: PowerDetails, value: SCENARIO_GRAPH_TYPE.POWER_DETAILS },
  { label: 'Natural Gas', value: SCENARIO_GRAPH_TYPE.NATURAL_GAS }
];

const GeoThermalRadioButtons = [
  { label: 'Power Generation Profile', value: SCENARIO_GRAPH_TYPE.POWER_GENERATION_PROFILE },
  { label: 'Heat and/or Electricity Extraction and Generation Profile', value: SCENARIO_GRAPH_TYPE.HEAT_GENERATION_PROFILE },
  { label: 'Revenue and Cashflow Profile', value: SCENARIO_GRAPH_TYPE.REVENUE_PROFILE }
];

const EvRadioButtons = [
  { label: EV_GRAPH_TYPE.AVG_DEMAND, value: EV_GRAPH_TYPE.AVG_DEMAND },
  { label: EV_GRAPH_TYPE.AVG_VISITS, value: EV_GRAPH_TYPE.AVG_VISITS },
  { label: EV_GRAPH_TYPE.FLEET_SIZE, value: EV_GRAPH_TYPE.FLEET_SIZE }
];

// Graph Radio buttons
export const GRAPH_TYPES: Record<string, any> = {
  power: PowerRadioButtons,
  hydrogen: HydrogenRadioButtons,
  ccus: CcusRadioButtons,
  geothermal: GeoThermalRadioButtons,
  gtherm: HydrogenRadioButtons,
  ev: EvRadioButtons
};


export const POWER_GRAPHS_LABELS = {
  'total_supply': 'Total Supply',
  'total_demand': 'Total Demand',
  'Solar_Actual': 'Solar',
  'Turbine_Actual': 'Land Turbine',
  'grid_power_demand_actual': 'To Grid',
  'grid_power_supply_actual_with_transmission_loss': 'From Grid',
  'rec_power_demand_actual': 'To Rec',
  'rec_power_supply_actual_with_transmission_loss': 'From Rec',
  'battery_power_supply_actual_with_transmission_loss': 'Battery',
  'hydrogen_supply_actual_with_transmission_loss': 'Fuel Cell',
  'total_supply_actual': 'Total Produced',
  'total_demand_potential': 'Total Consumed',
  'natural_gas_power_supply_actual_with_transmission_loss': 'Natural Gas',
  'diesel_power_supply_actual_with_transmission_loss': 'Diesel',
  'capitol_energy_wind_turbine_2023_power_supply_actual_with_transmission_loss': 'Capitol Energy Wind Turbine 2023',
  'capitol_energy_wind_turbine_power_supply_actual_with_transmission_loss': 'Capitol Energy Wind Turbine'
  // 'total_flue_gas_co2_supply_potential': 'Fuel Gas co2 Supply',
  // 'total_flue_gas_supply_potential': 'Fuel Gas Supply',
  // 'total_gaseous_co2_supply_potential': 'Gaseous co2 Supply',
  // 'total_hydrogen_supply_actual': COMPONENT_LABELS.HYDROGEN_ACTUAL_SUPPLY,
  // 'total_hydrogen_supply_actual_with_transmission_loss': 'Hydrogen Supply Actual',
  // 'total_hydrogen_supply_potential_with_transmission_loss': 'Hydrogen Supply Potential'
};

export const HYDROGEN_GRAPH_LABELS = {
  'total_supply': totalPowerProduced,
  'total_demand': 'Total Power Consumed',
  'Solar_Actual': 'Power Solar Output',
  'Turbine_Actual': 'Power Turbine Output',
  'grid_power_supply_actual_with_transmission_loss': 'From Grid',
  'rec_power_supply_actual_with_transmission_loss': 'From Rec',
  'H2_electrolyzer_out_grid': 'Hydrogen Electrolyzer Out to Grid',
  'H2_electrolyzer_out_re': 'Hydrogen Electrolyzer Out to Renewable Energy',
  'H2_electrolyzer_out_bat': 'Hydrogen Electrolyzer Out to Battery',
  'total_hydrogen_supply_potential_with_transmission_loss': 'Hydrogen Electrolyzer Out',
  'external_gray_supplier_hydrogen_supply_actual_with_transmission_loss': 'Hydrogen External Supply Out',
  'hydrogen_storage_hydrogen_supply_actual_with_transmission_loss': 'Hydrogen Green Store Out',
  'pwr_elec_loadbalancer_to_electrolyzer': 'Power Load Balancer to Electrolyzer',
  'pwr_battery_storage': 'Power Battery Storage',
  'pwr_battery_cap': 'Power Battery Capacity',
  'pwr_turbine_avail': 'Power Turbine Availability',
  'pwr_solar_avail': 'Power Solar Availability',
  'battery_power_supply_actual_with_transmission_loss': 'Power Battery Output',
  'pwr_elec_loadbalancer_excess': 'Power Load Balancer Excess',
  'pwr_electrolyzer_in': 'Power Electrolyzer In',
  'pwr_cap_electrolyzer': 'Power Capacity of Electrolyzer',
  'H2_loadbalancer_excess': 'Hydrogen Load Balancer Excess',
  'hydrogen_demand_hydrogen_demand_potential': 'Hydrogen Industry Demand',
  'H2_mobility_demand': 'Hydrogen Mobility Demand',
  'H2_over_under': 'Hydrogen Over/Under',
  'pwr_over_under': 'Power Over/Under',
  'diesel_power_supply_actual_with_transmission_loss': 'Diesel Supply Out',
  'natural_gas_power_supply_actual_with_transmission_loss': 'Natural Gas Supply Out',
  // 'total_flue_gas_co2_supply_potential': 'Fuel Gas co2 Supply Out',
  // 'total_flue_gas_supply_potential': 'Fuel Gas Supply Out',
  // 'total_gaseous_co2_supply_potential': 'Gaseous co2 Supply Out',
  'total_hydrogen_supply_actual': COMPONENT_LABELS.HYDROGEN_ACTUAL_SUPPLY,
  'total_hydrogen_supply_actual_with_transmission_loss': 'Hydrogen Actual Out'
};

// CCUS graph series Labels
const CCUS_GRAPH_LABELS = {
  'total_gaseous_co2_demand_actual': 'CO2 Demand Actual',
  'total_gaseous_co2_supply_potential': 'CO2 Supply Potential',
  'total_flue_gas_co2_supply_potential': 'Flue gas CO2 Supply Potential',
  'total_flue_gas_supply_potential': 'Flue gas Supply Potential',
  'total_gaseous_co2_supply_actual': 'CO2 Supply Actual',
  'total_hydrogen_supply_actual': COMPONENT_LABELS.HYDROGEN_ACTUAL_SUPPLY,
  'total_hydrogen_supply_actual_with_transmission_loss': 'Hydrogen Actual Out',
  'total_hydrogen_supply_potential_with_transmission_loss': 'Hydrogen Potential Out',
  'total_supply': 'Total Supply',
  'co2_demand_gas_co2_demand_actual_kg': 'Total gas CO2 demand (kg)',
  'flue_gas_flue_gas_co2_supply_actual_kg': 'Flue Gas CO2 Supply (kg)',
  'sequestration_liquid_co2_demand_actual': 'Total CO2 Sequestered (kg)',
  'total_gas_co2_supply_actual_kg': 'Total Gas CO2 Supply (kg)',
  'total_hydrogen_supply_potential': 'Total Hydrogen Supplied (kg)'
};

export const SCENARIO_TYPE_LABELS: Record<string, any> = {
  'power': POWER_GRAPHS_LABELS,
  'hydrogen': HYDROGEN_GRAPH_LABELS,
  'ccus': CCUS_GRAPH_LABELS,
  'gtherm': HYDROGEN_GRAPH_LABELS
};

export const GRAPH_COLUMN_KEYS = [
  'Solar_Actual',
  'Turbine_Actual',
  'grid_power_demand_actual',
  'rec_power_demand_actual',
  'grid_power_supply_actual_with_transmission_loss',
  'rec_power_supply_actual_with_transmission_loss',
  'battery_power_supply_actual_with_transmission_loss',
  'hydrogen_supply_actual_with_transmission_loss',
  'total_supply_actual',
  'total_demand_potential',
  'natural_gas_power_supply_actual_with_transmission_loss',
  'diesel_power_supply_actual_with_transmission_loss',
  'capitol_energy_wind_turbine_2023_power_supply_actual_with_transmission_loss',
  'capitol_energy_wind_turbine_power_supply_actual_with_transmission_loss'

];
export const HYDROGEN_GRAPH_COLUMN_KEYS = [
  'H2_electrolyzer_out_grid',
  'H2_electrolyzer_out_re',
  'H2_electrolyzer_out_bat',
  'total_hydrogen_supply_potential_with_transmission_loss',
  'external_gray_supplier_hydrogen_supply_actual_with_transmission_loss',
  'hydrogen_storage_hydrogen_supply_actual_with_transmission_loss',
  'pwr_elec_loadbalancer_to_electrolyzer',
  'pwr_battery_storage',
  'pwr_battery_cap',
  'pwr_turbine_avail',
  'pwr_solar_avail',
  'battery_power_supply_actual_with_transmission_loss',
  'pwr_elec_loadbalancer_excess',
  'pwr_electrolyzer_in',
  'pwr_cap_electrolyzer',
  'H2_loadbalancer_excess',
  'hydrogen_demand_hydrogen_demand_potential',
  'H2_mobility_demand',
  'H2_over_under',
  'pwr_over_under'
];
const CCUS_GRAPH_KEYS = [
  'total_gaseous_co2_demand_actual',
  'total_gaseous_co2_supply_potential',
  'total_flue_gas_co2_supply_potential',
  'total_flue_gas_supply_potential'
];

export const SCENARIO_TYPE_KEYS: Record<string, string[]> = {
  'power': GRAPH_COLUMN_KEYS,
  'hydrogen': HYDROGEN_GRAPH_COLUMN_KEYS,
  'ccus': CCUS_GRAPH_KEYS,
  'gtherm': HYDROGEN_GRAPH_COLUMN_KEYS
};

export const GRAPH_COLORS_ARRAY = [
  '#1f77b4', '#ff7f0e', '#2ca02c',
  '#d62728', '#9467bd', '#8c564b',
  '#e377c2', '#7f7f7f', '#bcbd22',
  '#17becf', '#aec7e8', '#ffbb78',
  '#98df8a', '#ff9896', '#c5b0d5',
  '#c49c94', '#f7b6d2', '#c7c7c7',
  '#dbdb8d', '#9edae5', '#393b79',
  '#637939', '#8c6d31', '#843c39',
  '#7b4173', '#5254a3', '#636363',
  '#8ca252', '#bd9e39', '#ad494a',
  '#d6616b', '#6b6ecf', '#b5cf6b',
  '#e7ba52', '#c49c94', '#9c9ede',
  '#cedb9c', '#e7969c', '#de9ed6',
  '#3182bd'
];

export const globalObject = {
  useUTC: true
};

const commonButtons = [
  {
    type: 'day',
    count: NUMBER.N1,
    text: 'Daily',
    dataGrouping: {
      forced: true,
      units: [['day', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  {
    type: 'week',
    count: NUMBER.N1,
    text: 'Weekly',
    dataGrouping: {
      forced: true,
      units: [['week', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  {
    type: 'month',
    count: NUMBER.N1,
    text: 'Monthly',
    dataGrouping: {
      forced: true,
      units: [['month', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  {
    type: 'month',
    count: NUMBER.N3,
    text: 'Quarterly',
    dataGrouping: {
      forced: true,
      units: [['month', [NUMBER.N3]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  }
];

export const buttonsForEvGraph = [
  ...commonButtons,
  {
    type: 'year',
    count: NUMBER.N1,
    text: 'Yearly',
    dataGrouping: {
      forced: true,
      units: [['year', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  {
    type: 'all',
    text: 'All'
  }
] as RangeSelectorButtonsOptions[];

export const commonButtonsForGraph = [
  {
    type: 'hour',
    count: NUMBER.N1,
    text: 'Hourly',
    dataGrouping: {
      forced: true,
      units: [['hour', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  ...commonButtons,
  {
    type: 'year',
    count: NUMBER.N1,
    text: 'Yearly',
    groupAll: true,
    dataGrouping: {
      forced: true,
      units: [['year', [NUMBER.N1]]],
      approximation: 'sum'
    },
    preserveDataGrouping: true
  },
  {
    type: 'all',
    text: 'All'
  }
] as RangeSelectorButtonsOptions[];

export const rangeSelectorObj = (isDefaultMonthly?: boolean) => {
  return ({
    enabled: true,
    inputEnabled: false,
    allButtonsEnabled: true,
    buttons: commonButtonsForGraph,
    buttonTheme: {
      width: NUMBER.N60
    },
    selected: isDefaultMonthly ? NUMBER.N3 : NUMBER.N5
  });
};

export const rangeSelectorObjEv = {
  enabled: true,
  inputEnabled: false,
  allButtonsEnabled: true,
  buttons: buttonsForEvGraph,
  buttonTheme: {
    width: NUMBER.N60
  },
  selected: NUMBER.N4
};

// POWER GRAPH LIST KEYS

const LIST_TYPE_POWER_SUMMARY_GRAPH = {
  'total_supply': 'SUPPLY',
  'total_demand': 'DEMAND'
};

// HYDROGEN GRAPH LIST KEYS
export const LIST_TYPE_HYDROGEN_POWER_SUMMARY_GRAPH = {
  'total_supply': totalPowerProduced,
  'total_demand': totalPowerConsumed
};

export const LIST_TYPE_HYDROGEN_POWER_DETAIL_GRAPH = {
  'total_supply': totalPowerProduced,
  'total_demand': totalPowerConsumed,
  'Turbine_Actual': 'Land Turbine',
  'Solar_Actual': 'Solar',
  'battery_power_supply_actual_with_transmission_loss': 'Battery Out',
  'grid_power_demand_actual': 'To Grid',
  'grid_power_supply_actual_with_transmission_loss': 'From Grid',
  'rec_power_supply_actual_with_transmission_loss': 'From Rec',
  'natural_gas_power_supply_actual_with_transmission_loss': 'NATURAL GAS',
  'diesel_power_supply_actual_with_transmission_loss': 'DIESEL'
};

export const LIST_TYPE_HYDROGEN_SUMMARY_GRAPH = {
  'hydrogen_demand_gas_h2_demand_potential': 'Total Hydrogen Consumed',
  'total_hydrogen_supply_actual_with_transmission_loss': 'Total Hydrogen Produced'
};

export const LIST_TYPE_HYDROGEN_DETAIL_GRAPH = {
  'electrolyzer_gas_h2_supply_potential_with_transmission_loss': 'Hydrogen From Electrolyzer',
  'hydrogen_storage_gas_h2_supply_actual_with_transmission_loss': 'Hydrogen From Storage',
  'external_hydrogen_supplier_gas_h2_supply_actual_with_transmission_loss': 'Hydrogen From External'
};

export const LIST_TYPE_HYDROGEN_OVER_UNDER_GRAPH = {
  'H2_over_under': 'Hydrogen',
  'pwr_over_under': 'Power'
};

// CCUS GRAPH LIST KEYS
export const LIST_TYPE_CCUS_SUMMARY_GRAPH = {
  'co2_demand_gas_co2_demand_actual_kg': CCUS_GRAPH_LABELS.co2_demand_gas_co2_demand_actual_kg,
  'flue_gas_flue_gas_co2_supply_actual_kg': CCUS_GRAPH_LABELS.flue_gas_flue_gas_co2_supply_actual_kg,
  'sequestration_liquid_co2_demand_actual': CCUS_GRAPH_LABELS.sequestration_liquid_co2_demand_actual,
  'total_gas_co2_supply_actual_kg': CCUS_GRAPH_LABELS.total_gas_co2_supply_actual_kg,
  'total_hydrogen_supply_potential': CCUS_GRAPH_LABELS.total_hydrogen_supply_potential
  // 'total_gaseous_co2_demand_actual': CCUS_GRAPH_LABELS.total_gaseous_co2_demand_actual,
  // 'total_flue_gas_co2_supply_potential': CCUS_GRAPH_LABELS.total_flue_gas_co2_supply_potential,
  // 'total_gaseous_co2_supply_potential': CCUS_GRAPH_LABELS.total_gaseous_co2_supply_potential,
  // 'total_gaseous_co2_supply_actual': CCUS_GRAPH_LABELS.total_gaseous_co2_supply_actual
};

// ALL GRAPH LIST ON THE BASIS OF SCENARIO TYPES
export interface IGraphList {
  graphKeys: any
  seriesKeys?: string
  yAxisUnit?: string
  yAxisTitle?: any
  secondaryYAxis?: string
  graphName: string
  type: string
  showLegends: boolean
  [key: string]: any
  DETAIL_GRAPH?: any
}

const PowerYaxisUnit = 'Power (kWh)';
const CcusYaxisUnit = 'Gas (kg)';
const PowerGHYaxisUnit = 'Power (KWh) / Hydrogen (Nm3)';

const hydrogenGraphs = [
  { graphKeys: {}, type: 'column', graphName: SCENARIO_GRAPH_TYPE.POWER_SUMMARY, showLegends: false, seriesKeys: 'summary_graph_series', yAxisUnit: PowerYaxisUnit },
  { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.POWER_DETAILS, showLegends: true, seriesKeys: 'details_graph_series', yAxisUnit: PowerYaxisUnit },
  { graphKeys: {}, type: 'column', graphName: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_SUMMARY, seriesKeys: 'hydrogen_summary_graph_series', showLegends: true, yAxisUnit: '(Nm3)' },
  { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_DETAIL, showLegends: true, seriesKeys: 'hydrogen_details_graph_series', yAxisUnit: '(Nm3)' },
  { graphKeys: {}, type: 'column', graphName: SCENARIO_GRAPH_TYPE.HYDROGEN_BREAKDOWN, showLegends: true, seriesKeys: 'hydrogen_over_under_series', yAxisUnit: PowerGHYaxisUnit }
];

// Graph List ordering should be same as the the ordering of GRAPH_TYPES (declared above)...
export const SCENARIO_GRAPH_LIST: Record<string, IGraphList[]> = {
  'power': [
    { graphKeys: {}, type: 'column', graphName: SCENARIO_GRAPH_TYPE.POWER_SUMMARY, showLegends: false, seriesKeys: 'summary_graph_series', yAxisUnit: PowerYaxisUnit },
    { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.POWER_DETAILS, showLegends: true, seriesKeys: 'details_graph_series', yAxisUnit: PowerYaxisUnit }
  ],
  'hydrogen': hydrogenGraphs,
  'ccus': [
    { graphKeys: {}, type: 'column', graphName: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_SUMMARY, showLegends: false, seriesKeys: 'summary_graph_series', yAxisUnit: CcusYaxisUnit },
    { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.SCENARIO_TYPE_DETAIL, showLegends: true, seriesKeys: 'details_graph_series', yAxisUnit: CcusYaxisUnit },
    { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.POWER_DETAILS, showLegends: true, seriesKeys: 'power_graph_series', yAxisUnit: PowerYaxisUnit },
    { graphKeys: {}, type: 'line', graphName: SCENARIO_GRAPH_TYPE.NATURAL_GAS, showLegends: true, seriesKeys: 'natural_gas_graph_series', yAxisUnit: CcusYaxisUnit }
  ],
  'geothermal': [
    {
      graphKeys: { 'THERMAL DRAWDOWN': 'THERMAL DRAWDOWN' },
      type: 'line',
      graphName: SCENARIO_GRAPH_TYPE.POWER_GENERATION_PROFILE,
      showLegends: true,
      yAxisTitle: { primary: '℃, MW', secondary: 'Drawdown' },
      secondaryYAxis: 'THERMAL DRAWDOWN'
    },
    {
      graphKeys: { 'PERCENTAGE OF TOTAL HEAT MINED (%)': 'PERCENTAGE OF TOTAL HEAT MINED (%)' },
      type: 'line',
      graphName: SCENARIO_GRAPH_TYPE.HEAT_GENERATION_PROFILE,
      showLegends: true,
      yAxisTitle: { primary: 'GWh/year, 10^15 J', secondary: 'Percent' },
      secondaryYAxis: 'PERCENTAGE OF TOTAL HEAT MINED (%)'
    },
    {
      graphKeys: { 'Cooling Price (cents/kWh)': 'Cooling Price (cents/kWh)' },
      type: 'line',
      graphName: SCENARIO_GRAPH_TYPE.REVENUE_PROFILE,
      showLegends: true,
      yAxisTitle: { primary: 'MUSD, MUSD/yr', secondary: 'cents/kWh' },
      secondaryYAxis: 'Cooling Price (cents/kWh)'
    }
  ],
  'gtherm': hydrogenGraphs
};

export const MY_DASHBOARD_GRAPH: Record<string, any> = {
  'power': LIST_TYPE_POWER_SUMMARY_GRAPH,
  'hydrogen': LIST_TYPE_POWER_SUMMARY_GRAPH,
  'ccus': LIST_TYPE_CCUS_SUMMARY_GRAPH,
  'gtherm': LIST_TYPE_POWER_SUMMARY_GRAPH
};
