import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, DEFAULT_LOCATION, IEVSettings, ILocationSettings, IScenarioSettings, Methods } from '../../constants';
import { interceptor } from '../../utils/interceptor';
import { getLocationName } from '../../utils';
import { updateUserLocation } from '../slices/scenarioDetailsSlice';

interface ILocationPayload {
  scenarioId: string
  latitude: number
  longitude: number
  abatement_latitude: number
  abatement_longitude: number
}

const getLocName = (lat: string | null | number, lng: string | null | number, dispatch: any) => {
  getLocationName(lat, lng, 'country').then((res: string | null) => {
    if (typeof res === 'string') {
      dispatch(updateUserLocation(res));
    }
  });
};

export const settingsApis = createApi({
  reducerPath: 'settingsApis',
  baseQuery: interceptor,
  tagTypes: ['GetSettings'],
  endpoints: (builder) => ({
    // api to save Project Data...
    saveSettings: builder.mutation<IScenarioSettings, Partial<IScenarioSettings>>({
      query: (payload) => ({
        url: ApiEndpoints.SAVE_SCENARIO_SETTINGS,
        method: Methods.POST,
        body: payload
      }),
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    }),

    // api to get all projects...
    getSettingsData: builder.query<IScenarioSettings, string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SCENARIO_SETTINGS}?model_id=${scenarioId}`,
        method: Methods.GET
      }),
      providesTags: ['GetSettings'],
      onQueryStarted: async (scenarioId, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          getLocName(data.latitude || DEFAULT_LOCATION.lat, data.longitude || DEFAULT_LOCATION.lng, dispatch);
        } catch (error) {
          console.error(error);
        }
      }
    }),

    // api to save location settings
    saveLocationSettings: builder.mutation<ILocationSettings, ILocationPayload>({
      query: (payload: ILocationPayload) => ({
        url: `${ApiEndpoints.SAVE_LOCATION_SETTINGS}?scenario_id=${payload.scenarioId}`,
        method: Methods.POST,
        body: { latitude: payload.latitude, longitude: payload.longitude, abatement_latitude: payload.abatement_latitude, abatement_longitude: payload.abatement_longitude }
      }),
      onQueryStarted: async (payload: ILocationPayload, { dispatch, queryFulfilled }) => {
        try {
          getLocName(payload.latitude, payload.longitude, dispatch);
        } catch (error) {
          console.error(error);
        }
      },
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    }),

    saveEVSettings: builder.mutation<IEVSettings, Partial<IEVSettings>>({
      query: (payload) => ({
        url: `${ApiEndpoints.SAVE_SCENARIO_SETTINGS}`,
        method: Methods.POST,
        body: payload
      }),
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    })
  })
});

export const {
  useSaveSettingsMutation,
  useGetSettingsDataQuery,
  useLazyGetSettingsDataQuery,
  useSaveLocationSettingsMutation,
  useSaveEVSettingsMutation
} = settingsApis;
