import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';
import { IWebApiAuthReq, IWebAuthResponse, IWebAuthRootResponse } from '../../constants/interface';
import { ApiEndpoints, Methods } from '../../constants';

export const onWebAuthApi = createApi({
  reducerPath: 'dsiderAuthentication',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.BASE_URL}`
    // for future use
    // prepareHeaders: (headers) => {
    //   const token = sessionStorage.getItem('token');
    //   if (token) {
    //     headers.set('token', token);
    //   }
    //   return headers;
    // }
  }),
  endpoints: (builder) => ({
    onWebAuthentication: builder.mutation<IWebAuthRootResponse<IWebAuthResponse>, IWebApiAuthReq>({
      query: (body: IWebApiAuthReq) => {
        return {
          url: ApiEndpoints.AUTHETICATION,
          method: Methods.POST,
          body
        };
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      }
    })
  })
});

export const { useOnWebAuthenticationMutation } = onWebAuthApi;
