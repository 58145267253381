import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useSaveWorkbench } from '../../hooks/useSaveWorkbenchData';
import { useScenario } from '../../hooks/useScenario';
import { CCUS, GEO_THERMAL, GeoThermalFormCategory, IComponentProperties, IProerties, LB, MESSAGES, NUMBER, SHARED_SCENARIO_PERMISSION } from '../../constants';
import { ISimulationResponse, ISimulationStatusResponse } from '../../redux/services/simulationApi';
import { changeSpinnerTitle } from '../../redux/SpinnerSlice';
import { Node } from 'reactflow';
import Select from 'react-select';
import Slider from 'rc-slider';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useGeoThermalSimulation } from '../../hooks/useGeoThermalSimulation';
import { CCUSSim2ItemsNotToInclude, formatNumberWithCommas, parseFormattedNumber, threeDotHorizontal } from '../../utils';
import SchedulingModal from '../workbench/SchedulingModal';
import { useLazyGetComponentPropertiesQuery } from '../../redux/services/admin/objectManagementApis';
import { useComponentProperties } from '../../hooks/useComponentProperty';
import { updateMonteCarloFields } from '../../redux/workbenchSlice';
interface IGroupedComponentProps {
  category: string
  component: Component[]
}

interface ICapacityPropertyValue {
  node_id: string
  label: string
  name: string
  value: string
  unit: string
  min?: number
  max?: number
}

interface Component {
  parentNode: string
  class: string
  imageS3url: string
  imageS3urlDark: string
  componentDisplayName: string
  no_of_inputs: number
  no_of_outputs: number
  sensitivityFormCategory: string
  capacityProperty: string[]
  powerCategory: string
  input_node_arr: number[]
  output_node_arr: number[]
  defaultType: string
  capacityPropertyValues: ICapacityPropertyValue[]
}

function SimulationForm({ setIsMonteCarloSimulation }: any) {
  const [groupedComponents, setGroupComponents] = useState<IGroupedComponentProps[]>([]);
  const [validationSchema, setValidationSchema] = useState<any>({});
  const [taskId, setTaskId] = useState('');
  const { saveSimulation, getSimulationStatus } = useSaveWorkbench();
  const { simulateGeoThermal } = useGeoThermalSimulation();
  const { updateSimulationNodeProperties } = useScenario();
  const dispatch = useAppDispatch();
  const workbenchNodes = useAppSelector(state => state.workbench.nodes);
  const [getComponentProperties] = useLazyGetComponentPropertiesQuery();
  const wokbenchSavedNodes = useAppSelector(state => state.workbench.savedNodes);
  const { permission, BaselineStatus, id: scenarioID, subProject_Type } = useAppSelector(state => state.scenarioDetails);
  const [initialFormValues, setInitialsFormValues] = useState<IComponentProperties>({});
  const initialValue: any = {};
  const simulationStatusIntervalRef = useRef<number | undefined>();
  const disabledClass = (permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR || BaselineStatus) ? 'disabled' : '';
  const [isSchedule, setIsSchedule] = useState(false);
  const [hydrogenSchedule, setHydrogenIsSchedule] = useState(false);
  const [sliderValue, setSliderValue] = useState<number>(NUMBER.N100);
  const [hydrogensliderValue, setHydrogenSliderValue] = useState<number>(NUMBER.N100);
  const [co2sliderValue, setCo2SliderValue] = useState<number>(NUMBER.N100);
  const [showSchedulingModal, setShowSchedulingModal] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [componentDataState, setComponentDataState] = useState<any>();
  const [capacityProperty, setCapacityProperty] = useState<string>('');
  const [componentProperties, setComponentProperties] = useState<any>();
  const { updateNodeProperties } = useComponentProperties();

  const sliderChangeHandler = (value: number | number[]) => {
    if (typeof value === 'number') {
      setSliderValue(value);
    }
  };
  const co2sliderChangeHandler = (value: number | number[]) => {
    if (typeof value === 'number') {
      setCo2SliderValue(value);
    }
  };
  const hydrogenSliderChangeHandler = (value: number | number[]) => {
    if (typeof value === 'number') {
      setHydrogenSliderValue(value);
    }
  };

  function combineCapacityPropertyValues(data: IGroupedComponentProps[]): ICapacityPropertyValue[] {
    return data.reduce((acc: ICapacityPropertyValue[], category: IGroupedComponentProps) => {
      return acc.concat(
        category.component.reduce((a: ICapacityPropertyValue[], b: Component) => {
          return a.concat(b.capacityPropertyValues);
        }, [])
      );
    }, []);
  }

  const checkForFormInputs = (item: any) => {
    const isMiticoObj = workbenchNodes.find((ele: any) => ele.data.class === 'mitico');
    if (isMiticoObj && CCUSSim2ItemsNotToInclude.includes(item.data.class as string)) {
      return false;
    } else if (subProject_Type === GEO_THERMAL && item.data.sensitivityFormCategory !== GeoThermalFormCategory) {
      return false;
    }
    if (item.data.capacityProperty && !(item.data.class as string)?.includes(LB)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Create an object to store groups
    const groupedData: any = {};
    // Iterate through the parent layer wokbenchSavedNodes and populate the groupedData object
    wokbenchSavedNodes.filter(n => !n.data.parentNode).forEach(item => {
      if (checkForFormInputs(item)) {
        const category = item.data.sensitivityFormCategory;
        if (!groupedData[category]) {
          groupedData[category] = [];
        }
        const data = {
          ...item.data,
          capacityPropertyValues: getCapacityPropertyValue(item.data, item.id)
        };
        groupedData[category].push(data);
      }
    });
    setInitialsFormValues(initialValue);
    // Convert the groupedData object to the final groupedList array
    const groupedList = Object.entries(groupedData as IGroupedComponentProps).map(([category, component]) => ({
      category,
      component
    }));

    const hasScheduleTest = groupedList.some((item: any) => item.category === 'Schedule Demand');
    const hasHydrogenScheduleTest = groupedList.some((item: any) => item.category === 'Green Hydrogen' && item.component.some((comp: any) => comp.class === 'hydrogen'));

    if (hasScheduleTest) {
      setIsSchedule(true);
    }
    if (hasHydrogenScheduleTest) {
      setHydrogenIsSchedule(true);
    }
    setGroupComponents(groupedList);
    getValidationSchema(groupedList);
    const combinedValues = combineCapacityPropertyValues(groupedList);
    dispatch(updateMonteCarloFields(combinedValues));
  }, [wokbenchSavedNodes]);

  interface MinMax {
    min: number
    max: number
  }

  interface Comp {
    capacityPropertyValues?: ICapacityPropertyValue[]
    sensitivityFormCategory: string
    minMax?: Record<string, MinMax>
  }

  const getValidationSchema = (data: IGroupedComponentProps[]) => {
    let finalSchema: Record<string, Yup.AnySchema> = {};

    data.forEach(d => {
      d.component.forEach((comp: Comp) => {
        if (comp?.sensitivityFormCategory === GeoThermalFormCategory) {
          const valSchema = comp.capacityPropertyValues?.reduce<Record<string, Yup.AnySchema>>((schema, prop) => {
            const minMax = comp.minMax?.[prop.name];
            if (minMax) {
              schema[getKey(prop.node_id, prop.name)] = Yup.number()
                .min(minMax?.min ?? NUMBER.N0, `Value must be at least ${minMax?.min}`)
                .max(minMax?.max ?? NUMBER.N10, `Value must be at most ${minMax?.max}`)
                .required(MESSAGES.REQUIRED);
            } else {
              schema[getKey(prop.node_id, prop.name)] = Yup.number()
                .required(MESSAGES.REQUIRED);
            }
            return schema;
          }, {}) ?? {};

          finalSchema = { ...finalSchema, ...valSchema };
        }
      });
    });

    setValidationSchema(Yup.object().shape(finalSchema));
  };

  const getCapacityPropertyValue = (component: any, nodeId: string) => {
    const valuesArr: ICapacityPropertyValue[] = [];
    let cp: string[] = [];
    if (Array.isArray(component.capacityProperty)) {
      cp = component.capacityProperty[0].split(', ');
    } else {
      cp = [component.capacityProperty];
    }
    cp.map((val) => {
      valuesArr.push({
        node_id: nodeId,
        label: getLabel(component, val),
        name: val,
        value: component[val],
        unit: getUnit(component, val),
        min: component.minMax?.[val]?.min,
        max: val === 'gradient_1' ? 198 : component.minMax?.[val]?.max
      });
      initialValue[getKey(nodeId, val)] = component[val];
    });
    return valuesArr;
  };

  const getLabel = (component: any, val: string) => {
    let propertyName = '';
    for (const key in component?.formulaTitle) {
      if (component?.formulaTitle?.[key] === val) {
        propertyName = key;
        break;
      }
    }
    return propertyName;
  };

  const getUnit = (component: any, val: string) => {
    return component?.units?.[val];
  };

  const getKey = (nodeId: string, name: string) => {
    return `${nodeId}#${name}`;
  };

  const handleFormSubmit = (multiplier: number, hydrogenMultiplier: number, co2sliderValue?: number) => (values: any, formikHelpers: FormikHelpers<any>) => {
    updateSimulationNodeProperties(values, multiplier, hydrogenMultiplier, co2sliderValue).then((updatedSavedNodes) => {
      // send true incase of simulation 2 otherwise false...
      // send multiplier to DB
      if (subProject_Type === GEO_THERMAL) {
        const simData = {
          subProjectId: scenarioID,
          isDashboard: true
        };
        simulateGeoThermal(simData, updatedSavedNodes as Node[], (data) => {
          toast.success(MESSAGES.SIMULATION_IS_COMPLETED);
        });
      } else {
        saveSimulation(true, (res: ISimulationResponse) => {
          setTaskId(res.task_id);
          dispatch(changeSpinnerTitle(MESSAGES.SIMULATION_RUNNING));
        }, updatedSavedNodes as Node[]);
      }
    });
  };

  /**
  * Function to get simulation status in every 10 seconds...
  */
  const getSimulationDataStatus = () => {
    getSimulationStatus(taskId, (res: ISimulationStatusResponse) => {
      clearInterval(simulationStatusIntervalRef.current);
      if (res.status === 'completed') {
        // when simulation is completed
      }
    }, simulationStatusIntervalRef.current);
  };

  const handleSelectedProperty = (node: any, label: string) => {
    setShowSchedulingModal(true);
    setComponentDataState(node);
    setCapacityProperty(node.capacityProperty);
    getComponentProperties(node.class).then((res: any) => {
      const filterData = res?.data.filter((ele: any) => ele.paramType === 'input');
      setComponentProperties(filterData);
      const property = res?.data?.find((ele: any) => label === ele.propertyName);
      setSelectedProperty(property);
    });
  };

  const updateSchedule = async (prop: IProerties, value: any, scheduleType: string) => {
    const nodeProperties = workbenchNodes.find((ele: any) => ele.data.capacityProperty === capacityProperty);
    const properties = { ...nodeProperties?.data?.properties };
    properties[prop.formulaTitle] = { scheduleType, scheduleData: value };
    updateNodeProperties((nodeProperties as Node).id, properties, componentProperties, true);
    setShowSchedulingModal(false);
  };

  // useEffect to set 10 sec interval on get simulation status api call...
  useEffect(() => {
    if (taskId) {
      simulationStatusIntervalRef.current = setInterval(getSimulationDataStatus, NUMBER.N10000) as unknown as number;
      getSimulationDataStatus();
    }
    // Cleanup the interval on component unmount
    return () => clearInterval(simulationStatusIntervalRef.current);
  }, [taskId]);

  return (
    <>
      {Object.keys(initialFormValues).length > 0 &&
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit(sliderValue, hydrogensliderValue, co2sliderValue)}
        >
          {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
            <Form className={`simulation-form ${disabledClass}`} onSubmit={handleSubmit}>
              <div className='simulation-inner-wrap'>
                {groupedComponents.map((group, index) => (
                  <div key={group.category} className="simulation-inner">
                    <p className="slider-head">{group.category}</p>
                    {
                      group.component.map((comp, i) => (
                        <Form.Group key={`${comp.componentDisplayName}-${i}`} className="form-group" controlId="formBasicEmail">
                          {comp?.capacityPropertyValues.map((cp: ICapacityPropertyValue, index: number) => (
                            <div className={`component-property three-dot-form-wrap ${index > NUMBER.N0 ? 'multiple-properties' : ''}`} key={getKey(cp.node_id, cp.name)}>
                              <div className="three-dot-wrap">
                                <Form.Label>{`${comp.componentDisplayName} (${cp.label})`}</Form.Label>
                                {typeof values[getKey(cp.node_id, cp.name)] === 'object' &&
                                  <Button className='btn-no-outline btn btn-primary' onClick={() => handleSelectedProperty(comp, cp.label)}>
                                    <img src={threeDotHorizontal} alt="three dot icon" />
                                  </Button>
                                }

                              </div>
                              <Form.Control
                                type="string"
                                name={getKey(cp.node_id, cp.name)}
                                onChange={event => {
                                  event.target.value = parseFormattedNumber(event.target.value);
                                  handleChange(event);
                                }}
                                className={`${errors[getKey(cp.node_id, cp.name)] ? 'error-field' : ''}`}
                                autoComplete="false"
                                value={typeof values[getKey(cp.node_id, cp.name)] === 'object' ? formatNumberWithCommas(values[getKey(cp.node_id, cp.name)].scheduleType)
                                  : formatNumberWithCommas(values[getKey(cp.node_id, cp.name)])}
                                maxLength={NUMBER.N28}
                                disabled={typeof values[getKey(cp.node_id, cp.name)] === 'object'}
                              />
                              {cp.unit && <div className="custom-select-main capital-cost-select" title={cp.unit}>
                                <Select
                                  className="custom-select-wrp"
                                  classNamePrefix="select"
                                  defaultValue={[{ value: cp.unit, label: cp.unit, isDisabled: true }]}
                                  isDisabled={true}
                                  name="color"
                                  options={[{ value: cp.unit, label: cp.unit, isDisabled: true }]}
                                />
                              </div>}
                              {errors[getKey(cp.node_id, cp.name)] && <span className="error-msg">{`${errors[getKey(cp.node_id, cp.name)] as string}`}</span>}
                            </div>
                          ))}
                          {group.category === 'Schedule Demand' && isSchedule && (
                            <div className='schedule-slider-progress-value-wrap'>
                              <span className='demand-slider-value'>Multiplier({sliderValue}%)</span>
                              <div className="star-progress-bar">
                                <Slider
                                  min={10}
                                  max={200}
                                  value={sliderValue}
                                  step={10}
                                  onChange={sliderChangeHandler}
                                />
                              </div>
                            </div>
                          )}
                          {group.category === 'CO2 Demand' && (
                            <div className='schedule-slider-progress-value-wrap'>
                              <span className='demand-slider-value'>Multiplier({co2sliderValue}%)</span>
                              <div className="star-progress-bar">
                                <Slider
                                  min={10}
                                  max={200}
                                  value={co2sliderValue}
                                  step={10}
                                  onChange={co2sliderChangeHandler}
                                />
                              </div>
                            </div>
                          )}
                        </Form.Group>
                      ))
                    }
                    {group.category === 'Green Hydrogen' && hydrogenSchedule && group.component.some((comp: any) => comp.class === 'hydrogen') && (
                      <div className='schedule-slider-progress-value-wrap' key={group.category}>
                        <span className='demand-slider-value'>Multiplier({hydrogensliderValue}%)</span>
                        <div className="star-progress-bar">
                          <Slider
                            min={10}
                            max={200}
                            value={hydrogensliderValue}
                            step={10}
                            onChange={hydrogenSliderChangeHandler}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <Button
                disabled={!!disabledClass}
                className="primary simulate-map-btn"
                type="submit"
              >
                Simulate
              </Button>
            </Form>
          )}
        </Formik>}
      {showSchedulingModal && selectedProperty !== undefined &&
        <SchedulingModal
          show={showSchedulingModal}
          setShowHide={(action: boolean) => setShowSchedulingModal(action)}
          onSave={updateSchedule}
          componentData={componentDataState}
          selectedProperty={selectedProperty}
        />
      }


      {subProject_Type === GEO_THERMAL &&
        <span onClick={() => setIsMonteCarloSimulation(true)}
          className='monte-carlo-link'>
          Run Monte Carlo Simulation
        </span>}
    </>
  );
};

export default SimulationForm;
