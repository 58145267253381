import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { MESSAGES, NUMBER } from '../constants';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { setLocalStorageItem } from './helper';
import { HTTPS_STATUS_CODE } from '../constants/api.constant';
import { toast } from 'react-toastify';
import { LOGIN } from './routeConstants';

export const baseQuery: BaseQueryFn<any, any, any> = fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL });

const refreshAccessToken = async (isUnauthorised: boolean): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/return-await, @typescript-eslint/no-misused-promises
  return new Promise(async resolve => {
    const tokenData = JSON.parse(localStorage.getItem('token') ?? '{}');
    if (tokenData != null && typeof tokenData === 'object') {
      try {
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
        const { exp: expirationTime } =
          tokenData.CognitoUser.signInUserSession.accessToken.payload;
        const currentTime = new Date().getTime() / NUMBER.N1000;
        if (expirationTime <= currentTime || isUnauthorised) {
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            async (_err: any, session: any) => {
              const { idToken, refreshToken, accessToken } = session ?? {};
              const newData = {
                CognitoUser: {
                  signInUserSession: {
                    ...tokenData.CognitoUser.signInUserSession,
                    ...session
                  }
                },
                accessToken: accessToken?.jwtToken,
                idToken: idToken?.jwtToken,
                refreshToken: refreshToken?.token
              };
              setLocalStorageItem('token', newData);
              resolve(newData);
            }
          );
        } else {
          resolve(tokenData);
        }
      } catch (e) {
        resolve({});
      }
    } else {
      resolve({});
    }
  });
};

export const interceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args: any, api: any, extraOptions: any) => {
  /**
   * Intercept the request here, Add custom headers to the request
   */
  const { accessToken } = await refreshAccessToken(false);
  const headers = {
    ...args.headers,
    'content-Type': 'application/json'
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  try {
    const result = await baseQuery({ ...args, headers }, api, extraOptions);
    if (result.error) {
      if (result.error.status === HTTPS_STATUS_CODE.UNAUTHORIZED) {
        localStorage.clear();
        sessionStorage.clear();
        toast.error(MESSAGES.SESSION_EXPIRED);
        setTimeout(() => {
          window.location.href = LOGIN;
        }, NUMBER.N1000);
      }

      if (result.error.status === HTTPS_STATUS_CODE.BAD_REQUEST && typeof result?.error?.data?.detail === 'string') {
        toast.error(result?.error?.data?.detail);
      }
      if (result.error.status === HTTPS_STATUS_CODE.INTERNAL_SERVER_ERROR) {
        toast.error(MESSAGES.INTERNAL_SERVER_ERROR);
      }

      if (result.error.status === HTTPS_STATUS_CODE.REQUEST_TIMEOUT) {
        toast.error(`${result?.error?.data?.message}. Please try again. `);
      }

      return { error: { detail: result.error?.data?.detail } };
    } else {
      return await Promise.resolve(result);
    }
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/return-await
    return Promise.reject(error);
  }
};
