import { GoogleMap, InfoWindow, Libraries, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_LOCATION, NUMBER, grayStylesMap } from '../../constants';
import { formatAndRoundOffNumber, poiInfoIcon } from '../../utils';
import SingalFinancialData from './SingalFinancialData';
import { Spinner } from 'react-bootstrap';
const apiKey = process.env.REACT_APP_MAP_KEY;
const libraries: Libraries = ['places'];

const mapStyles = {
  height: '600px',
  width: '100%'
};

const GoogleMapComponent = ({ financialData, selectedScenarioList, projectType }: any) => {
  const [mapZoom, setMapZoom] = useState<number>(NUMBER.N2);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: Number(financialData[NUMBER.N0].latitude ?? DEFAULT_LOCATION.lat),
    lng: Number(financialData[NUMBER.N0].longitude ?? DEFAULT_LOCATION.lng)
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey as string,
    libraries
  });

  const handleMapClick = () => {
    setActiveMarker(null);
  };

  const handleMarkerClick = (index: number) => {
    setActiveMarker(index);
  };

  const addClassToCloseButtons = useCallback(() => {
    const buttonElements = document.querySelectorAll('[aria-label="Close"]');
    if (buttonElements.length > NUMBER.N0) {
      buttonElements.forEach(button => {
        button.classList.add('map-close-button');
      });
    } else {
      setTimeout(() => {
        addClassToCloseButtons();
      }, NUMBER.N0);
    }
  }, []);

  useEffect(() => {
    if (activeMarker !== null) {
      addClassToCloseButtons();
    }
  }, [activeMarker, addClassToCloseButtons]);

  const getOffsetPosition = (lat: number, lng: number, index: number, total: number) => {
    const angle = (index / total) * NUMBER.N360;
    const offset = NUMBER.N001;
    const radian = (angle * Math.PI) / NUMBER.N180;
    return {
      lat: lat + offset * Math.cos(radian),
      lng: lng + offset * Math.sin(radian)
    };
  };

  const groupedMarkers = financialData.reduce((acc: any, data: any, index: number) => {
    const key = `${data.latitude}-${data.longitude}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({ data, index });
    return acc;
  }, {});

  const lableValue = (data: any) => {
    if (projectType === 'hydrogen') {
      return `LCOH ${formatAndRoundOffNumber(data?.LCOH ?? '0')}`;
    } else if (projectType === 'ccus') {
      return `LCOC ${formatAndRoundOffNumber(data?.levelized_costs_of_gaseous_co2 ?? '0')}`;
    } else {
      return `LCOE ${formatAndRoundOffNumber(data?.LCOE_Total ?? '0')}`;
    }
  };

  return (
    <>
      {isLoaded ? (
        <div className='map-view-wrap'>
          <GoogleMap
            onLoad={(map) => {
              mapRef.current = map;
            }}
            mapContainerStyle={mapStyles}
            zoom={mapZoom}
            center={mapCenter}
            onClick={handleMapClick}
            options={{ styles: grayStylesMap }}
            onDragEnd={() => {
              if (mapRef.current) {
                const center = mapRef.current.getCenter();
                if (center) {
                  setMapCenter({
                    lat: center.lat(),
                    lng: center.lng()
                  });
                }
              }
            }}
            onZoomChanged={() => {
              if (mapRef.current) {
                const zoom = mapRef.current.getZoom();
                if (zoom !== undefined) {
                  setMapZoom(zoom);
                }
              }
            }}
          >
            {Object.entries(groupedMarkers).map(([key, group]: any) =>
              group.map(({ data, index }: any, groupIndex: number) => (
                <Marker
                  key={data.scenario_id}
                  position={getOffsetPosition(Number(data.latitude), Number(data.longitude), groupIndex, group.length)}
                  title={data.scenario_name}
                  icon={{
                    url: poiInfoIcon
                  }}
                  label={{
                    text: `${lableValue(data)}`,
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    className: 'marker-label'
                  }}
                  zIndex={NUMBER.N1000}
                  onClick={() => handleMarkerClick(index)}
                >
                  {activeMarker === index && (
                    <InfoWindow
                      position={getOffsetPosition(Number(data.latitude), Number(data.longitude), groupIndex, group.length)}
                      onCloseClick={() => setActiveMarker(null)}
                    >
                      <SingalFinancialData item={data} selectedScenarioList={selectedScenarioList} index={index} projectType={projectType} />
                    </InfoWindow>
                  )}
                </Marker>
              ))
            )}
          </GoogleMap>
        </div>
      ) : <div className='map-data-loader'><Spinner /></div>}</>
  );
};

export default GoogleMapComponent;
