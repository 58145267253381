import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useApproveRejectScenarioMutation } from '../../redux/services/scenarioApis';
import FullPageLoader from '../shared/FullPageLoader';
import { toast } from 'react-toastify';
import { MESSAGES, NUMBER, SCENARIO_STATUS, USER_PERMISSIONS } from '../../constants';
import { useAppSelector } from '../../redux/hooks';

const StatusButtons = ({ isCurrentUserIsAppover, scenario_id }: any) => {
  const [rejectionModal, setRejectionModal] = useState(false);
  const { approved, pending, rejected } = SCENARIO_STATUS;
  const [decision, setDecision] = useState<string>(approved);
  const [approveRejectScenario, { isLoading }] = useApproveRejectScenarioMutation();
  const [reason, setReason] = useState<string>('');
  const { permissions } = useAppSelector(state => state.auth);
  const hasPermissionToApproveScenario = permissions?.includes(USER_PERMISSIONS.approve_scenarios);


  const formData = {
    decision,
    reason,
    scenario_id
  };

  const updateReason = (event: any) => {
    setReason(() => event.target.value);
  };


  const openRejectModal = () => {
    setDecision(() => rejected);
    setRejectionModal(true);
  };

  const closeRejectModal = () => {
    setRejectionModal(false);
    setReason('');
    setDecision(() => approved);
  };

  const handleConform = () => {
    approveRejectScenario(formData).then(() => {
      toast.success(MESSAGES.SCENARIO_UPDATED_SUCCESS);
    }).catch(() => {
      toast.error(MESSAGES.SOMETHING_WRONG);
    });
  };

  const handleReject = () => {
    closeRejectModal();
    handleConform();
  };

  return (
    <>
      {isLoading && <FullPageLoader isLoading={isLoading} title={'Updating Status'} />}
      {isCurrentUserIsAppover && <div className='approval-chart-btn-wrap'>
        {isCurrentUserIsAppover.status === pending && <>
          <Button disabled={!hasPermissionToApproveScenario} title={hasPermissionToApproveScenario ? '' : 'Not Authorised'}
            className="no-radius primary-btn status rejected" onClick={openRejectModal}>
            Reject
          </Button>
          <Button disabled={!hasPermissionToApproveScenario} title={hasPermissionToApproveScenario ? '' : 'Not Authorised'}
            className="primary-btn no-radius" onClick={handleConform}>
            Approve Scenario
          </Button>
        </>}

        {isCurrentUserIsAppover.status === rejected && <Button className="no-radius primary-btn status rejected cursor-default">
          Rejected
        </Button>}
        {isCurrentUserIsAppover.status === approved && <Button className="primary-btn no-radius cursor-default">
          Approved
        </Button>}

      </div>}

      <Modal
        show={rejectionModal}
        onHide={closeRejectModal}
        dialogClassName="modal-503 top-right-modal"
        className="forget-modal setting-modal share-modal approval-modal rejection-modal"
      >
        <Modal.Header
          closeButton
          className=""
          onClick={closeRejectModal}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-large">
            Reason of Rejection
          </h4>
          <div className='approval-req-wrap rejection-wrap'>
            <Form>
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={5}
                  onChange={updateReason}
                  maxLength={NUMBER.N250}
                />
              </Form.Group>
            </Form>
            <span className='reason-char-limit'>{reason.length}/{NUMBER.N250}</span>
            <div className="simulate-btn-wrap modal-footer">
              <button
                type="button"
                className="btn-no-outline btn btn-primary"
                onClick={closeRejectModal}
              >
                Cancel
              </button>
              <button type="submit" className="primary btn btn-primary" onClick={handleReject} disabled={reason.trim().length <= NUMBER.N5}>
                Submit
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusButtons;
