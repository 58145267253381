/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { NUMBER } from '../../constants';

interface PaginationsProps {
  totalItems: number
  itemsPerPage: number
  onPageChange: (pageNumber: number) => void
  activePage: number
  setActivePage: (pageNumber: number) => void
}

const Paginations: React.FC<PaginationsProps> = ({ totalItems, itemsPerPage, onPageChange, activePage, setActivePage }) => {
  const { N1, N2, N4 } = NUMBER;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    onPageChange(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];

    const startPage = Math.max(N1, activePage - N2);
    const endPage = Math.min(totalPages, startPage + N4);

    // Previous Page
    items.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(activePage - N1)} disabled={activePage === N1} />
    );

    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Next Page
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => handlePageChange(activePage + N1)}
        disabled={activePage === totalPages}
      />
    );

    return items;
  };

  return (
    <div className='pagination-wrap'>
      <Pagination>{renderPaginationItems()}</Pagination>
    </div>
  );
};

export default Paginations;
