import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { threeDotHorizontal } from '../../utils';

interface IDropdown {
    entity: any
    renderEditComment: any
    deleteComment: any
}

const CommentDropdownMenu = ({ entity, renderEditComment, deleteComment }: IDropdown) => {
    return (
        <div className='card-dropdown comment-dropdown-wrap'>
            {[''].map((direction) => (
                <Dropdown
                    className="create-modal-dropdown three-dot-dropdown"
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    variant="secondary"
                    align="end"
                >
                    <Dropdown.Toggle id="dropdown-basic">
                        <img
                            src={threeDotHorizontal}
                            alt="three dot icon"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.ItemText>
                            Options
                        </Dropdown.ItemText>
                        <Dropdown.Item as="button" className='' onClick={() => renderEditComment(entity?.id)}>
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className='text-danger' onClick={() => deleteComment(entity?.id)}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ))}
        </div>
    );
};

export default CommentDropdownMenu;
