import React, { useRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { EDIT_NEW_COMPANY_VALIDATION_SCHEMA, mapKeys } from '../../../utils';
import { ICompanyNameFormValues, MESSAGES } from '../../../constants';
import { toast } from 'react-toastify';
import { updateSingleCompany } from '../../../redux/slices/adminSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Button, Form } from 'react-bootstrap';
import { useEditCompanyMutation, useGetObjectListQuery, useGetPermissionsQuery, useUpdateObjectMutation } from '../../../redux/services/admin/companyManagementApis';
import PermissionTabs from './PermissionTabs';
import { useGetFormProjectTypeQuery } from '../../../redux/services/projectApis';
import CommonFieldsCompany from './CommonFieldsCompany';
import AccessObjectModal from './AccessObjectModal';

interface IEditCompanyName {
  initialFormValues: ICompanyNameFormValues
  setEditComponentName: () => void
  showPermission?: boolean
  setIsPermissionChanged: (type: boolean) => void
  hideModal: () => void
  showObject?: boolean
  showName?: boolean
  setShowObject?: any
}

const EditCompanyName = ({
  initialFormValues,
  setEditComponentName,
  showPermission,
  setIsPermissionChanged,
  hideModal,
  showObject = false,
  showName = false,
  setShowObject
}: IEditCompanyName) => {
  const [editCompany] = useEditCompanyMutation();
  const formikRef = useRef<FormikProps<any> | null>(null);
  const { data: permissionsOptions } = useGetPermissionsQuery();
  const { data: objectList } = useGetObjectListQuery();
  const { data: projectType, isLoading: loadingProjectType } = useGetFormProjectTypeQuery();
  const dispatch = useAppDispatch();
  const { singleCompany } = useAppSelector(state => state.adminSlice);
  const [updateObject] = useUpdateObjectMutation();

  const handleFormComponent = () => {
    setEditComponentName();
  };

  const updateValues = (values: ICompanyNameFormValues) => {
    const newData = JSON.parse(JSON.stringify(values));
    newData.accessToProjectType = newData.accessToProjectType.map((value: any) => value.value).join(', ');
    dispatch(updateSingleCompany({ ...singleCompany, ...values, 'COMPANY NAME': values.name, accessToProjectType: newData.accessToProjectType }));
  };

  const handleObjModal = (type: boolean) => {
    setShowObject(type);
    const values = formikRef.current?.values;
    updateValues(values);
  };

  const updateObjectRequest = (values: ICompanyNameFormValues) => {
    const objectJson = {
      company_name: values.name,
      objectslist: values.accessToObjects
    };
    updateObject(objectJson).then(() => {
      toast.success(MESSAGES.OBJECT_ACCESS_UPDATE_SUCCESS);
      updateValues(values);
      hideModal();
    }).catch(() => {
      hideModal();
      toast.error(MESSAGES.OBJECT_ACCESS_UPDATE_ERROR);
    });
  };

  const handleFormSubmit = () => {
    return (values: ICompanyNameFormValues, { resetForm }: FormikHelpers<ICompanyNameFormValues>) => {
      if (
        !values.role_permissions.consumer.length &&
        !values.role_permissions.collaborator.length &&
        !values.role_permissions.superuser.length
      ) {
        toast.error(MESSAGES.ROLE_ERROR);
        return;
      }
      const formData = new FormData();
      const newData = JSON.parse(JSON.stringify(values));
      newData.accessToProjectType = newData.accessToProjectType.map((value: any) => value.value).join(', ');
      formData.append('company_update_json', JSON.stringify(newData));
      editCompany(formData).unwrap().then((res: any) => {
        resetForm();
        dispatch(updateSingleCompany({ ...singleCompany, ...values, 'COMPANY NAME': values.name, accessToProjectType: newData.accessToProjectType }));
        hideModal();
        toast.success(showPermission ? MESSAGES.COMPANY_PERMISSION_UPDATE_SUCCESS : MESSAGES.COMPANY_UPDATE_SUCCESS);
      }).catch(() => {
        hideModal();
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      });
    };
  };

  const accessObjectValues = mapKeys(objectList, initialFormValues.accessToObjects);
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialFormValues}
      enableReinitialize={true}
      onSubmit={handleFormSubmit()}
      validationSchema={EDIT_NEW_COMPANY_VALIDATION_SCHEMA}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError, setFieldValue, setFieldTouched }) => (
        <Form className="object-parameter-form add-uder-form edit-company-name-wrap" onSubmit={handleSubmit} >
          <div className={`${showName ? 'd-block' : 'd-none'}`}>
            <h4 className="modal-head modal-head-medium">Edit Company Details</h4>
            <div className="add-company-form-wrap">
              <div className="create-modal-right">
                <div className="full-width-form">
                  <CommonFieldsCompany values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldError={setFieldError}
                    errors={errors}
                    touched={touched}
                    setIsPermissionChanged={setIsPermissionChanged}
                    setFieldValue={setFieldValue}
                    loadingProjectType={loadingProjectType}
                    setFieldTouched={setFieldTouched}
                    projectType={projectType}
                    disabled={true}
                    isEdit={true}
                    showObjectModal={handleObjModal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`dsider-tab-wrapper admin-tab-wrap edit-object-access  ${showObject ? 'd-block' : 'd-none'}`}>
            <h4 className="modal-head modal-head-medium">Edit Object Access</h4>
            <div className="dsider-tab-inner">
              <div className='access-objec-form-wrap'>
                <AccessObjectModal data={objectList || {}} setFieldValue={setFieldValue} values={accessObjectValues} isEdit={true} />
              </div>
              <div className="setting-btn-wrap">
                <Button
                  type="button"
                  className="btn-no-outline btn btn-primary"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Cancel
                </Button>
                <Button className="send-invite-btn" disabled={!values.accessToObjects.length} onClick={() => updateObjectRequest(values)}>
                  Update
                </Button>
              </div>
            </div>
          </div>
          <div className={`${showPermission ? 'd-block' : 'd-none'} dsider-tab-wrapper admin-tab-wrap`}>
            <h4 className="modal-head modal-head-medium">Edit User Permissions</h4>
            <div className="dsider-tab-inner">
              <PermissionTabs setFieldValue={setFieldValue} permissionsOptions={permissionsOptions || []} values={values} setFieldChanged={setIsPermissionChanged} />
            </div>
          </div>
          <div className={`${showObject ? 'd-none' : ''} setting-btn-wrap`}>
            <Button className="btn-no-outline" onClick={handleFormComponent}>
              Cancel
            </Button>
            <Button className="primary" type="submit">Update</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditCompanyName;
