import React from 'react';
import {
  Button
} from 'react-bootstrap';
import {
  aiImg,
  scratchImg,
  wizardImg
} from '../../utils/icons';
import { useNavigate } from 'react-router-dom';
import { ALL_PROJECT, CREATE_SCENARIO, getLocalStorageItem } from '../../utils';
import { CREATE_FROM_SCRATCH, CREATE_WITH_AI, CREATE_WITH_WIZARD, EV, USER_PERMISSIONS } from '../../constants';
import { useAppSelector } from '../../redux/hooks';

const ScenarioType = () => {
  const navigate = useNavigate();
  const { permissions } = useAppSelector(state => state.auth);
  const projectDetails = getLocalStorageItem('selectedProject');
  const projectType = projectDetails?.project_type;

  const hasScratchPermission = permissions?.includes(USER_PERMISSIONS.create_from_scratch);
  const hasAiPermission = permissions?.includes(USER_PERMISSIONS.create_with_ai);
  const hasWizardPermission = permissions?.includes(USER_PERMISSIONS.create_with_wizard);

  const clickHandlerCreateFromScratch = (createScenarioType: string) => {
    const URL = `${CREATE_SCENARIO}?scenario_type=${createScenarioType}`;
    navigate(URL);
  };

  const wizardOption = ['power', 'hydrogen'];

  const handleCloseClick = () => {
    navigate(ALL_PROJECT);
  };

  return (
    <div className="all-models-section all-project-section modal-type-section">
      <button className='close-btn' onClick={handleCloseClick}>
        <span className="icon-close-grey-icon"></span>
      </button>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="section-title">How do you want to start?</h1>
              <p className="section-title-des">
                You can select any of the 3 processes to get started.
              </p>
              <div className="project-card-wrapper modal-type-card">
                <div className="row project-card-row">
                  <div className="col-md-4">
                    <div className="project-card">
                      <div className="project-card-img">
                        <img
                          src={scratchImg}
                          alt="logo"
                          className="card-img"
                        />
                      </div>
                      <div className="project-card-content">
                        <h3 className="project-card-title">
                          Create from Scratch
                        </h3>
                        <p className="project-card-des">
                          Click on the Create from Scratch to open a blank workbook.
                        </p>
                      </div>
                      <Button disabled={!hasScratchPermission} className="btn-outline" title={!hasScratchPermission ? 'Not Authorised' : ''}
                        onClick={() => clickHandlerCreateFromScratch(CREATE_FROM_SCRATCH)}>Go with this</Button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="project-card">
                      <div className="project-card-img">
                        <img
                          src={wizardImg}
                          alt="logo"
                          className="card-img"
                        />
                      </div>
                      <div className="project-card-content">
                        <h3 className="project-card-title">
                          Create with Wizard
                        </h3>
                        <p className="project-card-des">
                          Select any model you want to create from pre-defined templates.
                        </p>
                      </div>
                      {wizardOption?.includes(projectType)
                        ? <Button disabled={!hasWizardPermission} className="btn-outline" title={!hasWizardPermission ? 'Not Authorised' : ''}
                          onClick={() => clickHandlerCreateFromScratch(CREATE_WITH_WIZARD)}>
                          Go with this</Button>
                        : <Button className="btn-outline">Coming Soon...</Button>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={`project-card`}>
                      <div className="project-card-img">
                        <img
                          src={aiImg}
                          alt="logo"
                          className="card-img"
                        />
                      </div>
                      <div className="project-card-content">
                        <h3 className="project-card-title">
                          Create with AI
                        </h3>
                        <p className="project-card-des">
                          AI asks you a few questions & with those answers, we set up the correct scenario.
                        </p>
                      </div>
                      {projectType === EV
                        ? <Button disabled={!hasAiPermission} className="btn-outline" onClick={() => clickHandlerCreateFromScratch(CREATE_WITH_AI)}>
                          Go with this
                        </Button>
                        : <Button className="btn-outline">Coming Soon...</Button>}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioType;
