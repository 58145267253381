import { createSlice } from '@reduxjs/toolkit';

interface BreadCrumb {
  id: string
  name: string
}

interface LayersState {
  breadCrumb: BreadCrumb[]
  currentLayerID: string
}

const initialState: LayersState = {
  breadCrumb: [],
  currentLayerID: ''
};

const layersSlice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    updateBreadCrumb(state, payload) {
      return { ...state, breadCrumb: payload.payload };
    },
    updateCurrentLayerID(state, payload) {
      return { ...state, currentLayerID: payload.payload };
    },
    resetLayers() {
      return initialState;
    }
  }
});

export const { updateBreadCrumb, updateCurrentLayerID, resetLayers } = layersSlice.actions;

export default layersSlice.reducer;
