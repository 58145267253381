export const actions = {
  onboardingSignUp: 'action/onboardingSignUp',
  onboardingSignIn: 'action/onboardingSignIn',
  verifyEmail: 'action/verifyEmail',
  resendCode: 'action/resendCode',
  signout: 'action/signout',
  userAttributes: 'action/getAttributes',
  resendSignUpCode: 'action/resendSignUpCode',
  forgotPassword: 'action/forgotPassword',
  updateAttributes: 'action/updateAttributes',
  resetPassword: 'action/resetPassword',
  changePassword: 'action/changePassword',
  setPassword: 'auth/setPassword',
  localUser: 'auth/localUser',
  updateAuthState: 'auth/updateAuthState',
  warningAlert: 'auth/warningAlert'
};
