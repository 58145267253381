import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { dsiderDashboardLogo, userManagementLogo } from '../../utils';

const AdminLogin = () => {
  return (
    <>
      <div className="main-login admin-login-main">
        <div className="container">
          <div className="main-login-wrapper">
            <div className="form-content-main login-form-content">
              <div className="login-right">
                <div className="login-header">
                  <h3>Admin sign in</h3>
                </div>
                <div className='admin-login-card-wrap'>
                  <div className='admin-login-card'>
                    <img src={dsiderDashboardLogo} alt="logo" className='admin-login-card-img' />
                    <span className='admin-login-card-des'>Dsider Dashboard</span>
                  </div>
                  <div className='admin-login-card'>
                    <img src={userManagementLogo} alt="logo" className='admin-login-card-img' />
                    <span className='admin-login-card-des'>User Management</span>

                  </div>
                </div>
                <a
                  href="#"
                  className="forgot-pw"
                >
                  Back to Sign In
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
