import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOADER_TITLE } from '../constants';

const initialState = {
  loading: false,
  fullPageLoading: false,
  loaderTitle: LOADER_TITLE.GETTING_READY
};

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    stopLoading: (state, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    changeSpinnerTitle: (state, payload: PayloadAction<any>) => {
      return { ...state, fullPageLoading: true, loaderTitle: payload.payload || LOADER_TITLE.GETTING_READY };
    },
    startFullLoading: (state) => {
      return { ...state, fullPageLoading: true };
    },
    stopFullLoading: (state) => {
      return { ...state, fullPageLoading: false, loaderTitle: LOADER_TITLE.GETTING_READY };
    }
  }
});

export const { startLoading, stopLoading, startFullLoading, stopFullLoading, changeSpinnerTitle } = spinnerSlice.actions;

export default spinnerSlice.reducer;
