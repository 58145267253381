import React, { useEffect, useRef, useState } from 'react';
import { NUMBER } from '../../constants';
import { Form } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';
import { crossIcon } from '../../utils';

interface IAddApproverProps {
  onSelect: (parameter: any) => void
  showSearchParam: (show: boolean) => void
}

function SearchParameterInput(props: IAddApproverProps) {
  const { onSelect, showSearchParam } = props;
  const { nodes } = useAppSelector(state => state.workbench);
  const [selectedParamsList, setSelectedParamsList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const inputEle = useRef<HTMLInputElement>(null);
  const [paramsList, setParamsList] = useState<string[]>([]);

  useEffect(() => {
    const keysArr = new Set();
    nodes.map((ele: any) => {
      if (ele.data?.formulaTitle) {
        for (const data in ele.data.formulaTitle) {
          keysArr.add(data);
        }
      }
    });
    setParamsList((prevState) => {
      let data: any = [...prevState];
      data = Array.from(keysArr);
      return data;
    });
  }, []);

  const onChangeHandler = (parameter: any) => {
    onSelect(parameter);
  };

  const updateUserList = () => {
    const filteredList = paramsList?.filter(param => {
      return (
        param.toLowerCase()?.includes(searchValue));
    });
    setSelectedParamsList(filteredList);
  };

  const onBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      showSearchParam(false);
    }, NUMBER.N200);
  };

  useEffect(() => {
    if (inputEle.current) {
      inputEle.current.focus();
    }
  }, []);

  useEffect(() => {
    updateUserList();
  }, [searchValue]);

  return (
    <>
      <div className='approval-search-wrap'>
        <Form.Group className="form-group search-form-group" controlId="formBasicEmail" >
          <Form.Control
            ref={inputEle}
            type="search"
            autoComplete='off'
            placeholder="Search Parameter"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => onBlur()}
          />
          <img src={crossIcon} alt="logo" className="cross-logo" onClick={() => setSearchValue('')} />
        </Form.Group>
        {isFocused && searchValue.length > NUMBER.N2 && <div className="hover-box-wrap comment-box-wrap">
          <div className="hover-box">
            {selectedParamsList?.map((data: string, index: number) => {
              return (
                <div className="tab-left-part" key={index} onClick={() => onChangeHandler(data)}>
                  <div className="dropdown-wrapper shared-profile">
                    <div className="right-sec">
                      <p className="dropdown-des">
                        {data}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* if there is no param... */}
            {
              !selectedParamsList?.length && searchValue.length > NUMBER.N0 && <div className="tab-left-part">
                <div className="dropdown-wrapper shared-profile">
                  No Data Found!
                </div>
              </div>
            }
          </div>
        </div>}
      </div>
    </>
  );
}

export default SearchParameterInput;
