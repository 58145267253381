import React, { useRef, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { ADD_NEW_COMPANY_VALIDATION_SCHEMA } from '../../../utils';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ICompanyNameFormValues, LABELS, MESSAGES } from '../../../constants';
import { toast } from 'react-toastify';
import { useGetPermissionsQuery, useGetObjectListQuery, useSaveCompanyMutation, useGetDefaultPermissionsQuery } from '../../../redux/services/admin/companyManagementApis';
import ImageUpload from '../../auth/ImageUpload';
import PermissionTabs from './PermissionTabs';
import { useGetFormProjectTypeQuery } from '../../../redux/services/projectApis';
import ModalHoc from '../shared/ModalHoc';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import AccessObjectModal from './AccessObjectModal';
import CommonFieldsCompany from './CommonFieldsCompany';

const AddCompanyModal = ({ ShowAddCompanyModal, setShowAddCompanyModal }: any) => {
  const [saveCompany] = useSaveCompanyMutation();
  const { data: projectType, isLoading: loadingProjectType } = useGetFormProjectTypeQuery();
  const { data: permissionsOptions } = useGetPermissionsQuery();
  const { data: objectList } = useGetObjectListQuery();
  const { data: defaultPermission } = useGetDefaultPermissionsQuery();
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showObject, setShowObject] = useState(false);
  const [isPermissionChanged, setIsPermissionChanged] = useState(false);

  const handleImageUpload = (file: File) => {
    setImageFile(file);
  };

  const onModalClose = () => {
    hideModal();
    setShowAddCompanyModal(false);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const showConfirmationAlert = () => {
    setShowAlert(true);
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const showObjectModal = () => {
    setShowObject(true);
  };

  const hideObjectModal = () => {
    setShowObject(false);
  };

  const handleFormSubmit = () => {
    return async (values: ICompanyNameFormValues, { resetForm }: FormikHelpers<ICompanyNameFormValues>) => {
      if (
        !values.role_permissions.consumer.length &&
        !values.role_permissions.collaborator.length &&
        !values.role_permissions.superuser.length
      ) {
        toast.error(MESSAGES.ROLE_ERROR);
        return;
      }
      const formData = new FormData();
      values.email = values.email.toLowerCase();
      formData.append('company_data_json', JSON.stringify(values));
      formData.append('image', imageFile ?? '');
      try {
        saveCompany(formData)
          .then((res: any) => {
            if (res.error) {
              toast.error(res.error.data.detail || res.error.data.message || MESSAGES.SOMETHING_WENT_WRONG);
            } else {
              toast.success(MESSAGES.COMPANY_ADDED_SUCCESS);
              setShowAddCompanyModal(false);
              resetForm();
              hideModal();
              setIsPermissionChanged(false);
            }
          })
          .catch(() => {
            toast.error(MESSAGES.SOMETHING_WENT_WRONG);
          });
      } catch (error) { }
    };
  };

  const initialValues: ICompanyNameFormValues = {
    name: '',
    email: '',
    accessToProjectType: '',
    description: '',
    role_permissions: {
      consumer: defaultPermission?.consumer || [],
      collaborator: defaultPermission?.collaborator || [],
      superuser: defaultPermission?.superuser || []
    },
    accessToObjects: []
  };

  const closeModal = showModal ? hideModal : showObject ? hideObjectModal : isPermissionChanged ? showConfirmationAlert : onModalClose;

  const handleCancel = () => {
    if (showObject) {
      hideObjectModal();
    } else if (formikRef.current?.dirty) {
      showConfirmationAlert();
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      show={ShowAddCompanyModal}
      onHide={handleCancel}
      dialogClassName="modal-861 top-right-modal add-user-modal modal-dialog-centered"
      className="forget-modal setting-modal object-parameters-modal add-new-company-modal"
    >
      <Modal.Header closeButton className="" onClick={handleCancel}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">{showModal ? 'Add User Permissions' : showObject ? 'Add Object Access' : 'Add New Company'}</h4>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleFormSubmit()}
          innerRef={formikRef}
          validationSchema={ADD_NEW_COMPANY_VALIDATION_SCHEMA}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            setFieldError,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={`add-company-form-wrap ${(showModal || showObject) ? 'd-none' : ''}`}>
                <ImageUpload onImageUpload={handleImageUpload} setFieldValue={setFieldValue} imageType="company" />
                <div className="create-modal-right">
                  <div className="full-width-form">
                    <CommonFieldsCompany values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldError={setFieldError}
                      errors={errors}
                      touched={touched}
                      setIsPermissionChanged={setIsPermissionChanged}
                      setFieldValue={setFieldValue}
                      loadingProjectType={loadingProjectType}
                      setFieldTouched={setFieldTouched}
                      projectType={projectType}
                      showObjectModal={showObjectModal}
                    />
                  </div>
                </div>
              </div>
              <div className={`dsider-tab-wrapper admin-tab-wrap add-user-permission-tab ${showModal ? 'd-block' : 'd-none'}`}>
                <div className="dsider-tab-inner">
                  <PermissionTabs setFieldValue={setFieldValue} permissionsOptions={permissionsOptions || []} values={values} setFieldChanged={() => { }} />
                </div>
                <div className="setting-btn-wrap add-user-permission-footer">
                  <Button
                    type="button"
                    className="btn-no-outline btn btn-primary"
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button className="send-invite-btn" type="submit">
                    Send Invite
                  </Button>
                </div>
              </div>
              <div className={`dsider-tab-wrapper admin-tab-wrap  ${showObject ? 'd-block' : 'd-none'}`}>
                <div className="dsider-tab-inner">
                  <div className='access-objec-form-wrap'>
                    <AccessObjectModal data={objectList || {}} setFieldValue={setFieldValue} />
                  </div>
                  <div className="setting-btn-wrap access-object-footer">
                    <Button
                      type="button"
                      className="btn-no-outline btn btn-primary"
                      onClick={hideObjectModal}
                    >
                      Cancel
                    </Button>
                    <Button className="send-invite-btn" disabled={!values.accessToObjects.length} onClick={hideObjectModal}>
                      Update
                    </Button>
                  </div>
                </div>
              </div>
              {!showModal && !showObject && (
                <div className="delete-compnay-btn-wrap">
                  <div className="invite-btn-wrap">
                    <Button className="send-invite-btn" onClick={openModal}
                      // @ts-expect-error company error
                      disabled={(!values.email || errors.email) ?? !values.accessToProjectType ?? !values.description.length ?? !values.name ?? !values.accessToObjects.length}
                    >
                      Add User Permissions
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        <ModalHoc showModal={showAlert} setShowModal={hideConfirmationAlert}>
          <ConfirmationAlert
            showAlert={showAlert}
            title={LABELS.DISCARD_CHANGE}
            message={MESSAGES.DISCARD_COMPANY_PERMISSION_MESSAGE}
            cancleBtnText={LABELS.STAY}
            yesBtnText={LABELS.YES_DISCARD}
            onConfirm={onModalClose}
            onCancel={hideConfirmationAlert}
          />
        </ModalHoc>
      </Modal.Body>
    </Modal>
  );
};

export default AddCompanyModal;
