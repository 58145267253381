import React, { useState, useRef, useEffect } from 'react';
import {
  noDataLogo,
  whiteCircleRight,
  cubeAnimNew,
  tonnesLogoNew
} from '../../utils/icons';
import Header from '../../components/layout/Header';
import NoData from '../../components/shared/NoData';
import ScenarioCard from '../../components/dashboard/ScenarioCard';
import {
  GEO_THERMAL,
  IDashboardScenario,
  LABELS,
  MESSAGES,
  MY_DASHBOARD_GRAPH,
  NUMBER,
  SCENARIO_GRAPH_LIST,
  SCENARIO_TYPE_LABELS
} from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ISimulationCountRes, useGetSimulationCountQuery } from '../../redux/services/projectApis';
import { useGetDetailsMutation, useGetRecentScenariosQuery } from '../../redux/services/scenarioApis';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useGetLatestActivitiesQuery } from '../../redux/services/latestActivitiesApi';
import { useSimulationScenarioDataMutation } from '../../redux/services/evSimulationApis';
import {
  commonMarker, dashboardGraphGroupingObj, getDashboardEvGraph,
  getDashboardGraphOptionData, getGeoThermalDashboardGraphOptionData
} from '../../components/shared/Graph/GraphUtils';
import { useLazyGetMetricsDataQuery } from '../../redux/services/workbenchApis';
import { stopFullLoading } from '../../redux/SpinnerSlice';
import { getGeoThermalGraphSeries, getWorkbenchURL } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { updateLatestActivities } from '../../redux/slices/latestActivitiesSlice';
import LatestActivities from '../../components/dashboard/LatestActivities';

const initialValue = {
  baseline_scenario_count: NUMBER.N0,
  company_baseline_scenario_count: NUMBER.N0,
  total_abatement: NUMBER.N0,
  company_total_abatement: NUMBER.N0
};

// eslint-disable-next-line complexity
const Dashboard = () => {
  const userData = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [simationCountData, setSimationCountData] = useState<ISimulationCountRes>(initialValue);
  const [isSimuluationDone, setIsSimuluationDone] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [startYear, setStartYear] = useState<any>();
  const [endYear, setEndYear] = useState<any>();
  const [recentScenario, setRecentScenario] = useState('');
  const [graphDataLoading, setGraphDataLoading] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { data, isSuccess } = useGetSimulationCountQuery(userData.email);
  const { data: activityLog, refetch: activityRefetch, isFetching: isActivityFetching } = useGetLatestActivitiesQuery('', { refetchOnMountOrArgChange: true });
  const { data: recentScenarios, isFetching: isRecentScenarioFetching, isLoading, refetch } = useGetRecentScenariosQuery(userData.email, { refetchOnMountOrArgChange: true });
  const [simulationScenarioData] = useSimulationScenarioDataMutation();
  const [getScenarioDetails] = useGetDetailsMutation();
  const [getMetricsData] = useLazyGetMetricsDataQuery();

  const performSimulation = (payload: any) => {
    simulationScenarioData(payload)
      .then((res: any) => {
        if (res.data) {
          if (res.data?.load_profiles) {
            setStartYear(new Date(res.data?.load_profiles[NUMBER.N0].date_)?.getFullYear());
            setEndYear(new Date(res.data?.load_profiles[res.data?.load_profiles.length - NUMBER.N1].date_)?.getFullYear());
            setIsSimuluationDone(true);
            setGraphData(getDashboardEvGraph(res.data));
            setGraphDataLoading(false);
          } else {
            toast.error(res.data.message ?? MESSAGES.NO_SIMULATION_DATA_FOUND);
            setIsSimuluationDone(false);
            setGraphDataLoading(false);
          }
        } else {
          setIsSimuluationDone(false);
          setGraphDataLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!isRecentScenarioFetching && Array.isArray(recentScenarios)) {
      const scenario = recentScenarios[NUMBER.N0];
      setRecentScenario(scenario);
      if (scenario.Subproject_type === 'ev' && scenario?.metrics) {
        setGraphDataLoading(true);
        getScenarioDetails(scenario?.Scenario_id).then((res: any) => {
          const resData = res.data;
          const scenarioSimulationData = {
            scenario_id: resData.id,
            radius: resData.radius,
            lat: resData.lat,
            lng: resData.lng,
            start_year: resData.startYear,
            end_year: resData.endYear
          };
          if ('lat' in resData && 'lng' in resData && 'radius' in resData && 'startYear' in resData && 'endYear' in resData) {
            performSimulation(scenarioSimulationData);
          } else {
            setIsSimuluationDone(false);
            setGraphDataLoading(false);
          }
        });
      } else {
        getPowerMetricsData(scenario?.Scenario_id, scenario?.Subproject_type);
      }
    }
  }, [isRecentScenarioFetching]);

  const getPowerMetricsData = (scenarioId: string, scenarioType: string) => {
    setGraphDataLoading(true);
    getMetricsData({ scenarioId })
      .then((res: any) => {
        if (res?.status === 'rejected' || 'error' in res) {
          setGraphDataLoading(false);
          return;
        }
        const transformedData: any[] = [];
        if (res.data?.S3_URI_JSON) {
          fetch(res.data?.S3_URI_JSON)
            .then(async jsonData => await jsonData.json())
            .then((response) => {
              const resData = response.data;
              setStartYear(new Date(resData[NUMBER.N0][NUMBER.N0]).getFullYear());
              setEndYear(new Date(resData[resData.length - NUMBER.N1][NUMBER.N0]).getFullYear());
              for (let index = NUMBER.N0; index < resData.length; index++) {
                const obj = mapGraphDataWithColumns(response.columns, resData[index], scenarioType);
                transformedData.push(obj);
              }
            }).then(() => {
              dispatch(stopFullLoading());
              const seriesData = getGraphSeriesData(transformedData, MY_DASHBOARD_GRAPH[scenarioType]);
              const graphOptionData = getDashboardGraphOptionData(seriesData);
              setGraphData(graphOptionData);
              setGraphDataLoading(false);
              setIsSimuluationDone(true);
            });
        } else if (scenarioType === GEO_THERMAL) {
          const graph = SCENARIO_GRAPH_LIST[scenarioType][NUMBER.N0];
          const seriesData = getGeoThermalGraphSeries(res.data?.[graph.graphName], graph.secondaryYAxis as string);
          const graphOptionData = getGeoThermalDashboardGraphOptionData(seriesData, graph);
          setGraphData(graphOptionData);
          setGraphDataLoading(false);
          setIsSimuluationDone(true);
        }
      });
  };

  const mapGraphDataWithColumns = (columns: any, gData: any, scenarioType: string) => {
    return columns.reduce((acc: any, column: any, i: number) => {
      acc[column] = column === 'TimeUTC' ? gData[i] : { value: gData[i], label: (SCENARIO_TYPE_LABELS[scenarioType] as any)[column] };
      return acc;
    }, {});
  };

  const getGraphSeriesData = (sData: any[], graphKeys: any) => {
    if (graphKeys) {
      return Object.keys(graphKeys)
        .filter(key => (Object.prototype.hasOwnProperty.call(sData[NUMBER.N0], key)))
        .map((key: any, index: number) => ({
          name: (graphKeys as any)[key],
          color: index === NUMBER.N0 ? '#707374' : '#e8e9e9',
          type: 'column',
          data: sData.map((a: any) => [new Date(a.TimeUTC + 'Z').getTime(), a[key]?.value]),
          dataGrouping: dashboardGraphGroupingObj,
          marker: commonMarker
        }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSimationCountData(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isActivityFetching && activityLog) {
      dispatch(updateLatestActivities(Array.isArray(activityLog) ? activityLog : []));
    }
  }, [isActivityFetching]);


  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > NUMBER.N50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    if (divRef.current) {
      divRef.current.addEventListener('scroll', handleScroll);
    }
    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const navigateToWorkbench = (logData: any) => {
    const url = getWorkbenchURL(logData.subProjectID ?? logData.Scenario_id, logData.subProject_Type ?? logData.Subproject_type);
    navigate(url, {
      state: {
        navigateFrom: LABELS.DASHBOARD
      }
    });
  };

  return (
    <>
      <div className="main-login dashboard-main" ref={divRef}>
        <Header divScrolled={isScrolled} />
        <div className="container dashboard-container">
          <div className="main-login-wrapper">
            <div className="dashboard-main-wrapper">
              <div className="row">
                <div className="col-lg-9">
                  <div className="dashboard-left">
                    <div className="dashboard-header">
                      <h1>My Dashboard</h1>
                      <div className="dashboard-header-right">
                        <div className="dashboard-header-models">
                          <div className="cube-anim-wrapper">
                            <span className="dashboard-header-icon cube-anim-wrapper">
                              <img src={cubeAnimNew} alt=" cube logo" />
                            </span>
                          </div>
                          <div className="dashboard-header-text">
                            <span className="dashboard-header-des">
                              Baseline Scenario Count
                            </span>
                            <span className="dashboard-header-head">
                              Company: {simationCountData?.company_baseline_scenario_count}
                            </span>
                            <span className='my_count'>Me: {simationCountData?.baseline_scenario_count}</span>
                          </div>
                        </div>
                        <div className="dashboard-header-models dashboard-header-tonnes">
                          <span className="dashboard-header-icon">
                            <img src={tonnesLogoNew} alt=" tonnes logo" />
                          </span>
                          <div className="dashboard-header-text">
                            <span className="dashboard-header-des">
                              Carbon Abatement Value
                            </span>
                            <span className="dashboard-header-head">
                              Company: {Math.round(simationCountData?.company_total_abatement ?? simationCountData?.total_abatement as number)} Tonnes
                            </span>
                            <span className='my_count'>Me: {Math.round(simationCountData?.total_abatement as number)} Tonnes</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!recentScenarios?.length && !isLoading &&
                      <NoData
                        message={MESSAGES.NO_SIMULATION}
                        image={noDataLogo}
                        className="no-simulation-title"
                      />
                    }
                    {!isLoading && <div className="row">
                      <div className="col-md-12">
                        <div className="dashboard-graph">
                          {recentScenarios?.length > NUMBER.N0 && <div className="dashboard-graph-inner">
                            <>
                              {recentScenarios[NUMBER.N0]?.metrics && <div>
                                <span className="recent-simulation">
                                  Your Recent Simulation:
                                </span>
                                <h3 className="dashboard-graph-head" onClick={() => navigateToWorkbench(recentScenario)}>
                                  {recentScenarios[NUMBER.N0]?.Scenario_name ?? ''}
                                </h3>
                                <span className="chart-year">
                                  Simulation Chart {startYear ? `(${startYear}-${endYear})` : ''}
                                </span>
                                <span className="back-circle-logo" onClick={() => navigateToWorkbench(recentScenario)}>
                                  <img src={whiteCircleRight} alt="logo img" />
                                </span>
                              </div>}
                              {/* Graph Inline Loader */}
                              {graphDataLoading &&
                                <div className='dashboard-graph-data-loader'>
                                  <Spinner />
                                </div>}
                              {/* Dashboard Graph */}
                              {
                                (!graphDataLoading && isSimuluationDone) &&
                                <div className="graph-wrapper">
                                  {(recentScenarios?.length > NUMBER.N0 && graphData) &&
                                    <HighchartsReact highcharts={Highcharts} options={graphData} />
                                  }
                                </div>
                              }
                            </>

                            {(!isSimuluationDone && !graphDataLoading) &&
                              <NoData
                                message={MESSAGES.NO_SIMULATION}
                                image={noDataLogo}
                                className="no-simulation-title"
                              />
                            }
                          </div>}
                        </div>
                      </div>
                    </div>}
                    <div className="data-card-wrapper row">
                      {Array.isArray(recentScenarios) && recentScenarios.map((scenario: IDashboardScenario, index: number) => {
                        return (
                          <ScenarioCard
                            key={scenario.Scenario_id}
                            data={scenario}
                            className={scenario.Subproject_type === 'ev' ? `data-card-ev-${index + NUMBER.N1}` : `data-card-${index + NUMBER.N1} `}
                            refetchData={refetch}
                            refreshActivity={activityRefetch}
                            createdBy={scenario.Created_by}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* Latest Activities section starts  */}
                <div className="col-lg-3">
                  <LatestActivities isLoading={isActivityFetching} />
                </div >
              </div >
            </div >
          </div >
        </div >
      </div >
    </>
  );
};

export default Dashboard;
