import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ADD_NEW_PARAMETER_SCHEMA, ADD_NEW_PARAMETER_SCHEMA_LOCATION, hasFieldError } from '../../../utils';
import InputElement from '../../shared/InputElement';
import { DEFAULT_LAT_LNG, MESSAGES, NUMBER } from '../../../constants';
import Select from 'react-select';
import { useGetAllUnitsQuery } from '../../../redux/services/admin/objectManagementApis';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateSingleParameter } from '../../../redux/slices/adminSlice';

interface IAddNewParameter {
  ShowAddParameter: boolean
  setShowAddParameter: (data: boolean) => void
  properties: any
  setIsPermissionChanged: any
}

interface IInitialAddNewParameter {
  propertyName: string
  propertyValue: string
  propertyType: string
  propertyLabel: string
  unit: string
  description: string
  newParameter?: boolean
}

const AddNewParameter = ({ ShowAddParameter, setShowAddParameter, properties, setIsPermissionChanged }: IAddNewParameter) => {
  const { data: unitList, isLoading } = useGetAllUnitsQuery();
  const formikRef = useRef<FormikProps<any> | null>(null);
  const { singleParameter } = useAppSelector((state: any) => state.adminSlice);
  const dispatch = useAppDispatch();
  const initialFormValues: IInitialAddNewParameter = {
    propertyName: '',
    propertyValue: '',
    propertyType: 'number',
    propertyLabel: '',
    unit: '',
    description: ''
  };

  const newValue = (values: IInitialAddNewParameter) => {
    return {
      propertyName: values.propertyName,
      propertyType: values.propertyType,
      propertyLabel: values.propertyLabel,
      propertyValue: values.propertyValue,
      unit: values.unit,
      newParameter: values.newParameter,
      description: values.description
    };
  };

  const updateParameter = (values: IInitialAddNewParameter) => {
    const index = properties.findIndex(
      (item: any) => item.propertyName === singleParameter.propertyName
    );

    if (index !== -NUMBER.N1) {
      properties[index] = newValue(values);
    }
    toast.success(MESSAGES.PARAMETER_UPDATED_SUCCESS);
    formikRef.current?.resetForm();
    handleCancle();
  };

  const handleAddParameter = (values: IInitialAddNewParameter) => {
    const index = properties.findIndex(
      (item: any) => item.propertyName === values.propertyName
    );
    if (index !== -NUMBER.N1) {
      toast.error(MESSAGES.PARAMETER_ALREADY_EXISTS);
    } else {
      const newVals = { ...values, newParameter: true };
      properties.push(newValue(newVals));
      toast.success(MESSAGES.PARAMETER_ADDED_SUCCESS);
      formikRef.current?.resetForm();
      handleCancle();
    }
  };

  const handleFormSubmit = () => {
    return (values: IInitialAddNewParameter, { resetForm }: FormikHelpers<IInitialAddNewParameter>) => {
      setIsPermissionChanged(true);
      singleParameter.propertyName
        ? updateParameter(values)
        : handleAddParameter(values);
    };
  };

  const intialValue = singleParameter.propertyName ? singleParameter : initialFormValues;

  const handleCancle = () => {
    dispatch(updateSingleParameter({}));
    setShowAddParameter(false);
  };

  const isLocation = (data: any) => {
    return data === 'location';
  };

  return (
    <Modal
      show={ShowAddParameter}
      onHide={handleCancle}
      dialogClassName="modal-546 top-right-modal modal-dialog-centered input-output-modal object-layers-modal add-object-icon-modal add-new-parameter"
      className="forget-modal setting-modal"
    >
      <Modal.Header closeButton className="" onClick={handleCancle}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="modal-head modal-head-medium">{singleParameter.propertyName ? 'Edit ' : 'Add New '} Parameter</h4>
        <div className='objet-layer-main add-object-icon-main'>
          <div className='object-ul add-new-parameter-wrap'>
            <Formik
              initialValues={intialValue}
              enableReinitialize={true}
              onSubmit={handleFormSubmit()}
              validationSchema={isLocation(formikRef.current?.values.propertyType) ? ADD_NEW_PARAMETER_SCHEMA_LOCATION : ADD_NEW_PARAMETER_SCHEMA}
              innerRef={formikRef}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError, setFieldValue, setFieldTouched }) => (
                <Form onSubmit={handleSubmit} >
                  <div className="row">
                    <div className={`${singleParameter.propertyName && !singleParameter.newParameter && 'disabled'}`}>
                      <div className="col-md-12">
                        <InputElement
                          label="Property Name"
                          placeholder="Enter Property Name"
                          required={true}
                          type="text"
                          value={values?.propertyLabel}
                          name="propertyLabel"
                          data-testid="propertyLabel"
                          onChange={(event: any) => {
                            handleChange(event);
                            setFieldValue('propertyName', event.target.value.split(' ').join('_'));
                          }}
                          resetError={setFieldError}
                          onBlur={handleBlur}
                          hasError={hasFieldError(errors, touched, 'propertyLabel')}
                          maxLength={NUMBER.N25}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom-select-main select-radio-grey grey-border-select">
                        <Form.Label>
                          Units<sup>*</sup>
                        </Form.Label>
                        <Select
                          className={`custom-select-wrp ${hasFieldError(errors, touched, 'unit') ? 'error-field-select' : ''}`}
                          classNamePrefix="select"
                          value={unitList?.find((unit: any) => unit.value === values.unit) ?? null}
                          name="unit"
                          options={unitList}
                          placeholder="Select Unit"
                          onChange={(selectedOption) => {
                            setFieldValue('unit', selectedOption?.value);
                            setFieldValue('propertyType', isLocation(selectedOption?.value) ? 'location' : 'number');
                            setFieldValue('propertyValue', isLocation(selectedOption?.value) ? DEFAULT_LAT_LNG : '');
                          }}
                          closeMenuOnSelect={true}
                          onBlur={async () => await setFieldTouched('unit', true)}
                          isLoading={isLoading}
                          hideSelectedOptions={false}
                        />
                        {touched.unit && errors.unit && (
                          <span className="error-msg">{errors.unit}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <InputElement
                        label="Default Value"
                        placeholder="Enter Default value"
                        required={true}
                        type={isLocation(values.propertyType) ? 'text' : 'number'}
                        value={values?.propertyValue}
                        name="propertyValue"
                        data-testid="propertyValue"
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'propertyValue')}
                        maxLength={NUMBER.N25}
                        disabled={isLocation(values.propertyType)}
                        readOnly={isLocation(values.propertyType)}
                      />
                    </div>
                    <div className="col-md-12">
                      <InputElement
                        label="Property Description"
                        placeholder="Enter Property Description"
                        required={true}
                        type="text"
                        value={values?.description}
                        name="description"
                        data-testid="description"
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                        resetError={setFieldError}
                        onBlur={handleBlur}
                        hasError={hasFieldError(errors, touched, 'description')}
                        maxLength={NUMBER.N225}
                      />
                    </div>
                  </div>

                  <Modal.Footer>
                    <Button className="btn-no-outline" onClick={handleCancle}>
                      Cancel
                    </Button>
                    <Button className="primary" type="submit">Save</Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewParameter;
