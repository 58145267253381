import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import InputElement from '../../shared/InputElement';
import { FORM_NAME_VALIDATION_SCHEMA, hasFieldError } from '../../../utils';
import { INameFormValues, MESSAGES, NUMBER } from '../../../constants';
import { useUpdateUserMutation } from '../../../redux/services/admin/userManagementApis';
import { toast } from 'react-toastify';
import { updateSingleUser } from '../../../redux/slices/adminSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Button } from 'react-bootstrap';

interface IEditName {
  initialFormValues: INameFormValues
  setEditComponentName: (type: boolean) => void
}

const EditName = ({ initialFormValues, setEditComponentName }: IEditName) => {
  const [updateName] = useUpdateUserMutation();
  const dispatch = useAppDispatch();
  const { singleUser } = useAppSelector(state => state.adminSlice);

  const handleFormComponent = () => {
    setEditComponentName(false);
  };

  const handleFormSubmit = () => {
    return (values: INameFormValues, { resetForm }: FormikHelpers<INameFormValues>) => {
      updateName(values).unwrap().then((res: any) => {
        resetForm();
        dispatch(updateSingleUser({ ...singleUser, ...values, name: `${values.firstName} ${values.lastName}` }));
        handleFormComponent();
        toast.success(MESSAGES.USER_UPDATED_SUCCESS);
      }).catch(() => {
        handleFormComponent();
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      });
    };
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={true}
      onSubmit={handleFormSubmit()}
      validationSchema={FORM_NAME_VALIDATION_SCHEMA}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldError, setFieldValue, setFieldTouched }) => (
        <Form className="object-parameter-form add-uder-form" onSubmit={handleSubmit} >
          <h4 className="modal-head modal-head-medium">Edit Name</h4>
          <div className="row">
            <div className="col-md-12">
              <InputElement
                label='First Name'
                placeholder="Enter Name"
                required={true}
                type='text'
                value={values?.firstName}
                name='firstName'
                data-testid="firstName"
                onChange={handleChange}
                resetError={setFieldError}
                onBlur={handleBlur}
                hasError={hasFieldError(errors, touched, 'firstName')}
                className='component-input'
                maxLength={NUMBER.N25}
                autoComplete={true}
              />
            </div>
            <div className="col-md-12">
              <InputElement
                label='Last Name'
                placeholder="Enter Last Name"
                required={true}
                type='text'
                value={values?.lastName}
                name='lastName'
                data-testid="lastName"
                onChange={handleChange}
                resetError={setFieldError}
                onBlur={handleBlur}
                hasError={hasFieldError(errors, touched, 'lastName')}
                className='component-input'
                maxLength={NUMBER.N25}
                autoComplete={true}
              />
            </div>
          </div>
          <div className="setting-btn-wrap">
            <Button className="btn-no-outline" onClick={handleFormComponent}>
              Cancel
            </Button>
            <Button className="primary" type="submit">Update</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditName;
