import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authReducer';
import spinnerReducer from './SpinnerSlice';
import workbenchReducer from './workbenchSlice';
import objectListReducer from './slices/ObjeectListSlice';
import sharedUserListReducer from './slices/sharedUserSlice';
import scenarioDetailsReducer from './slices/scenarioDetailsSlice';
import simulationMetricsDataReducer from './slices/simulationMetricsDataSlice';
import latestActivitiesReducer from './slices/latestActivitiesSlice';
import WorkbenchSettingsReducer from './slices/settingsSlice';
import approvalSliceReducer from './slices/approvalSlice';
import layersSliceReducer from './slices/layersSlice';
import adminSliceReducer from './slices/adminSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { projectApis } from './services/projectApis';
import { onWebAuthApi } from './toolKitRTKQueries/webauth.query';
import { authApis } from './services/authApis';
import { scenarioApis } from './services/scenarioApis';
import { workbenchApis } from './services/workbenchApis';
import { settingsApis } from './services/settingsApis';
import { scenarioWithAIApis } from './services/scenarioWithAIApis';
import { evStationsApis } from './services/evStationsApis';
import { evSimulationApis } from './services/evSimulationApis';
import { latestActivitiesApi } from './services/latestActivitiesApi';
import workbenchReducerEV from './workbenchSliceEV';
import { simulationApis } from './services/simulationApi';
import { requestApprovalApi } from './services/requestApprovalApis';
import { userManagementApis } from './services/admin/userManagementApis';
import { companyManagementApis } from './services/admin/companyManagementApis';
import { wizardApis } from './services/wizardApis';
import { objectManagementsApis } from './services/admin/objectManagementApis';
import { monteCarloApi } from './services/monteCarloApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // allow Redux store across page reloads or app restarts
};

export const rootReducer = combineReducers({
  auth: authReducer,
  spinner: spinnerReducer,
  workbench: workbenchReducer,
  objectList: objectListReducer,
  scenarioDetails: scenarioDetailsReducer,
  simulationMetricsData: simulationMetricsDataReducer,
  latestActivities: latestActivitiesReducer,
  approvalData: approvalSliceReducer,
  layersData: layersSliceReducer,
  [projectApis.reducerPath]: projectApis.reducer,
  [scenarioApis.reducerPath]: scenarioApis.reducer,
  [authApis.reducerPath]: authApis.reducer,
  [workbenchApis.reducerPath]: workbenchApis.reducer,
  [settingsApis.reducerPath]: settingsApis.reducer,
  [scenarioWithAIApis.reducerPath]: scenarioWithAIApis.reducer,
  [evStationsApis.reducerPath]: evStationsApis.reducer,
  [evSimulationApis.reducerPath]: evSimulationApis.reducer,
  [simulationApis.reducerPath]: simulationApis.reducer,
  [wizardApis.reducerPath]: wizardApis.reducer,
  [latestActivitiesApi.reducerPath]: latestActivitiesApi.reducer,
  monteCarloApi: monteCarloApi.reducer,
  requestApprovalApi: requestApprovalApi.reducer,
  dsiderAuthentication: onWebAuthApi.reducer,
  sharedUserList: sharedUserListReducer,
  WorkbenchSettings: WorkbenchSettingsReducer,
  workBenchEV: workbenchReducerEV,
  adminSlice: adminSliceReducer,
  [userManagementApis.reducerPath]: userManagementApis.reducer,
  [companyManagementApis.reducerPath]: companyManagementApis.reducer,
  [objectManagementsApis.reducerPath]: objectManagementsApis.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
    onWebAuthApi.middleware,
    projectApis.middleware,
    authApis.middleware,
    scenarioApis.middleware,
    workbenchApis.middleware,
    settingsApis.middleware,
    scenarioWithAIApis.middleware,
    evStationsApis.middleware,
    evSimulationApis.middleware,
    simulationApis.middleware,
    monteCarloApi.middleware,
    latestActivitiesApi.middleware,
    requestApprovalApi.middleware,
    userManagementApis.middleware,
    companyManagementApis.middleware,
    wizardApis.middleware,
    objectManagementsApis.middleware
  ])
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
