import React from 'react';
import { locationSmallGreen, mapSmallRedLogo, smallCompititorLogo, aadtSmallLogo, formatIntegerOnly } from '../../utils';
import { NUMBER, Metric } from '../../constants';

interface EvChargingStationInfoProps {
  currentEVData: Metric | null
  chargingStationCountMetricData: Metric | null
  avgWeekDemandData: Metric | null
}
const EvChargingStationInfo: React.FC<EvChargingStationInfoProps> = ({
  currentEVData,
  chargingStationCountMetricData,
  avgWeekDemandData
}) => {
  const fleetSizeFinal = formatIntegerOnly(currentEVData?.value as number);
  const avgDailyDemand = avgWeekDemandData && typeof avgWeekDemandData.value === 'number'
    ? avgWeekDemandData.value / NUMBER.N7
    : 0;
  const avgDailyDemandFinal = formatIntegerOnly(avgDailyDemand);
  const currentChargingStation = formatIntegerOnly(chargingStationCountMetricData?.value as number);

  return (
    <div className="slider-inner-sec charging-stations-inner">
      <span className="graph-right-head">EV Charging Stations</span>
      <div className="current-stations proposed-station">
        <img src={locationSmallGreen} alt="logo" className="location-logo" />
        <span className="location-logo-des">Proposed Station</span>
      </div>

      <div className="current-stations">
        <img src={smallCompititorLogo} alt="logo" className="location-logo" />
        <span className="location-logo-des">Competitor Station</span>
      </div>

      <div className="current-stations">
        <img src={mapSmallRedLogo} alt="logo" className="location-logo" />
        <span className="location-logo-des">POI(s)</span>
      </div>

      <div className="current-stations">
        <img src={aadtSmallLogo} alt="logo" className="location-logo" />
        <span className="location-logo-des">AADT</span>
      </div>

      <div className="ev-station-status">
        <div className="tooltip-container">
          <h3 className="ev-station-value">{fleetSizeFinal || '-'}</h3>
          <div className="tooltip-text">{currentEVData?.tooltip}</div>
        </div>
        <p className="ev-station-des">Traffic Adjusted Fleet Size</p>
      </div>
      <div className="ev-station-status">
        <div className="tooltip-container">
          <h3 className="ev-station-value">{`${avgDailyDemandFinal ? avgDailyDemandFinal + ' kWh' : '-'}`}</h3>
          <div className="tooltip-text">Total average electricity demand at the station per day, measured in kWh.</div>
        </div>
        <p className="ev-station-des">Average Daily Power Demand</p>
      </div>
      <div className="ev-station-status">
        <div className="tooltip-container">
          <h3 className="ev-station-value">{currentChargingStation || '-'}</h3>
          <div className="tooltip-text">{chargingStationCountMetricData?.tooltip}</div>
        </div>
        <p className="ev-station-des">Current EV Charging Locations</p>
      </div>
    </div>
  );
};

export default EvChargingStationInfo;
