import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { IEditProfile } from '../../constants/interface';
import ImageUpload from '../../components/auth/ImageUpload';
import InputElement from '../../components/shared/InputElement';
import { MAX_LENGTH } from '../../constants/static';
import { hasFieldError } from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { EDIT_PROFILE_SCHEMA } from '../../utils/validation';
import { updateAuthState } from '../../redux/auth/authReducer';
import { useNavigate } from 'react-router-dom';
import { NUMBER } from '../../constants/numbers';
import { MESSAGES } from '../../constants/validationMessages';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from '../../redux/SpinnerSlice';
import { useGetUserDataQuery, useUpdateUserDataMutation } from '../../redux/services/authApis';
import { useSaveUserDataMutation } from '../../redux/services/admin/companyManagementApis';

const EditProfile = () => {
  const showLoader = useAppSelector(state => state.spinner.loading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: userDetails, isSuccess, refetch } = useGetUserDataQuery({});
  const [updateUserData] = useUpdateUserDataMutation();
  const [saveUserData, { isLoading }] = useSaveUserDataMutation();

  const values: IEditProfile = {
    firstName: '',
    lastName: '',
    company_name: '',
    company_logo: '',
    email: '' // just for type matching...
  };
  const [initialValues, setInitialValues] = useState<IEditProfile>(values);

  const handleClose = () => {
    !isLoading && navigate(-NUMBER.N1);
  };

  const handleFormSubmit = (values: IEditProfile) => {
    dispatch(startLoading(true));
    updateUserData({
      firstName: values.firstName,
      lastName: values.lastName
    }).then((res: any) => {
      if (res) {
        toast.success(MESSAGES.PROFILE_UPDATED);
        setTimeout(() => {
          handleClose();
        }, NUMBER.N1000);
      }
      dispatch(stopLoading(false));
    });
    dispatch(updateAuthState(values))
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleImageUpload = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file ?? '');
      saveUserData(formData).then(() => {
        refetch();
        toast.success(MESSAGES.PROFILE_PICTURE_UPDATED);
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.userName,
        company_logo: userDetails.image_url ?? '',
        company_name: userDetails.company_name
      });
    }
  }, [isSuccess]);

  return (
    <>
      <div className="change-pw-modal create-modal edit-profile">
        <div className="modal-dialog full-width-dialog">
          <div className="modal-content">
            <button className="close-btn" onClick={handleClose}>
              <span className="icon-close-grey-icon"></span>
            </button>
            <div className="modal-body">
              <div className="mb-46 modal-title h4">Edit Profile</div>
              <div className="create-modal-wrapper edit-profile-wrapper">
                <div className="container">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={EDIT_PROFILE_SCHEMA}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleFormSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      handleSubmit,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      touched,
                      setFieldError
                    }) => (
                      <Form
                        className="login-form signup-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="create-modal-left">
                              <ImageUpload
                                onImageUpload={handleImageUpload}
                                setFieldValue={setFieldValue}
                                companyLogo={values.company_logo}
                                isEditProfile={true}
                                isLoading={isLoading}
                                companyName={values.company_name}
                                logoTitle={'Profile Picture'}
                                imageType="company"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="create-modal-right">
                              {/* company name */}
                              <InputElement
                                label="First Name"
                                maxLength={MAX_LENGTH.name_length}
                                type="text"
                                value={values?.firstName}
                                name="firstName"
                                data-testid="firstName"
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                resetError={setFieldError}
                                hasError={hasFieldError(
                                  errors,
                                  touched,
                                  'firstName'
                                )}
                              />
                              {/* company description */}
                              <InputElement
                                label="Last Name"
                                maxLength={MAX_LENGTH.name_length}
                                type="text"
                                value={values?.lastName}
                                name="lastName"
                                data-testid="lastName"
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                resetError={setFieldError}
                                hasError={hasFieldError(
                                  errors,
                                  touched,
                                  'lastName'
                                )}
                              />
                              {/* email */}
                              <InputElement
                                label="Email"
                                className="disabled-field"
                                required={true}
                                readOnly={true}
                                type="text"
                                value={values?.email}
                                name="email"
                                data-testid="email"
                                onChange={handleChange}
                                resetError={setFieldError}
                                onBlur={handleBlur}
                                hasError={hasFieldError(
                                  errors,
                                  touched,
                                  'email'
                                )}
                              />
                              <InputElement
                                label="Company"
                                className="disabled-field"
                                required={true}
                                readOnly={true}
                                type="text"
                                value={values?.company_name}
                                name="company_name"
                                data-testid="company_name"
                                onChange={handleChange}
                                resetError={setFieldError}
                                onBlur={handleBlur}
                                hasError={hasFieldError(
                                  errors,
                                  touched,
                                  'company_name'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="sign-btn-wrapper">
                              <Button className="btn-no-outline">
                                Delete My Account
                              </Button>
                              <Button variant="primary" type="submit" disabled={showLoader || isLoading}>
                                {showLoader ? <Spinner /> : 'Update Profile'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
