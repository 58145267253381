import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { editIcon, falkensteinLogo, infoInputLogo, lastActiveDays } from '../../../utils';
import Select from 'react-select';
import { colourOptions } from '../../../utils/defaultData';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import UserAvatar from '../../approval-workflow/UserAvatar';
import { INameFormValues, MESSAGES, NUMBER } from '../../../constants';
import EditName from '../dashboard/EditName';
import ModalHoc from '../shared/ModalHoc';
import { useUpdateUserMutation } from '../../../redux/services/admin/userManagementApis';
import { updateSingleUser } from '../../../redux/slices/adminSlice';
import { toast } from 'react-toastify';
import PermissionModal from './PermissionModal';

const UserCompanyModal = ({ ShowUserCompany, setShowUserCompany, handleUserStatus, handleDeleteUser }: any) => {
  const { singleUser } = useAppSelector((state: any) => state.adminSlice);
  const [editComponentName, setEditComponentName] = useState(false);
  const [updateName] = useUpdateUserMutation();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const componentInput = useRef(null);

  const {
    name,
    email,
    scenarios_count,
    status,
    projects,
    simulations,
    imageUrl,
    company_description,
    company_name,
    firstName,
    lastName,
    roleType,
    permissions
  } = singleUser;

  const daysAgo = lastActiveDays(singleUser['last Session']);

  const showEditComponentName = () => {
    setEditComponentName(true);
    setTimeout(() => {
      if (componentInput.current) {
        ; (componentInput?.current as HTMLElement).focus();
      }
    });
  };

  const formIntialState: INameFormValues = {
    firstName,
    lastName,
    userName: email,
    role: roleType
  };

  const handleModal = () => {
    setShowUserCompany(false);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleFormSubmit = (values: INameFormValues) => {
    updateName(values)
      .unwrap()
      .then((res: any) => {
        dispatch(updateSingleUser({ ...singleUser, ...values, name: `${values.firstName} ${values.lastName}` }));
        setEditComponentName(false);
        toast.success(MESSAGES.USER_UPDATED_SUCCESS);
      });
  };

  const handleCloseEditName = () => {
    setEditComponentName(false);
  };
  const [ShowEditNameLast, setShowEditNameLast] = useState(false);

  const closeModal = editComponentName ? handleCloseEditName : handleModal;

  return (
    <>
      <Modal
        show={ShowUserCompany}
        onHide={closeModal}
        dialogClassName="modal-401 top-right-modal add-user-modal modal-dialog-centered user-compamy-modal"
        className="forget-modal setting-modal object-parameters-modal"
      >
        <Modal.Header closeButton className="" onClick={closeModal}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-left-part">
            <div className="dropdown-wrapper shared-profile user-name-email">
              {!editComponentName && name && (
                <UserAvatar
                  classname="user-icon orange"
                  firstname={name.split(' ')[NUMBER.N0] ?? ''}
                  lastname={name.split(' ')[NUMBER.N1] ?? ''}
                  imageUrl={imageUrl}
                />
              )}
              <div className="right-sec">
                {!editComponentName && (
                  <div className="name-last-edit">
                    <h3 className="dropdown-head extra-large" onClick={showEditComponentName}>
                      <span className="name-first-last">{name}</span>
                      <Button className="edit-icon-wrap btn-no-outline" onClick={showEditComponentName}>
                        <img src={editIcon} alt="edit icon" className="edit-icon" />
                      </Button>
                    </h3>
                  </div>
                )}
                {editComponentName && (
                  <EditName initialFormValues={formIntialState} setEditComponentName={setEditComponentName} />
                )}

                {!editComponentName && <p className="dropdown-des email extra-large">{email}</p>}
              </div>
            </div>
          </div>
          {!editComponentName && (
            <>
              {' '}
              <div className="user-card-main-wrap justify-content-center" onClick={handleCloseEditName}>
                <div className="user-info-card user-activity-card">
                  <h3 className="user-info-card-title">User Activity</h3>
                  <div className="user-card-body">
                    <div className="user-card-active">
                      <h2 className="user-card-sec-head">{projects}</h2>
                      <span className="user-status-des">Projects</span>
                    </div>
                    <div className="user-card-active">
                      <h2 className="user-card-sec-head">{scenarios_count}</h2>
                      <span className="user-status-des">Scenarios</span>
                    </div>
                    <div className="user-card-active user-card-inactive">
                      <h2 className="user-card-sec-head">{simulations}</h2>
                      <span className="user-status-des">Simulations</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-name-wrap user-company-status" onClick={handleCloseEditName}>
                <div className="compnay-logo-wrap">
                  <img src={falkensteinLogo} alt="logo" className="company-logo-img" />
                </div>
                <div className="company-des-wrap">
                  <h4 className="company-name-des">
                    Company: <span className="company-name-email">{company_name}</span>{' '}
                  </h4>
                  <p className="modal-head-des">{company_description}</p>
                </div>
              </div>
              <div className="custom-select-main" onClick={handleCloseEditName}>
                <Form.Label>User Role:</Form.Label>
                <Select
                  className="custom-select-wrp"
                  classNamePrefix="select"
                  defaultValue={colourOptions.find((option) => option.value === roleType)}
                  name="color"
                  options={colourOptions}
                  placeholder="User Role"
                  onChange={(selectedOption) => handleFormSubmit({ ...formIntialState, role: selectedOption?.value })}
                  isSearchable={false}
                />
                <div className="tooltip-container" onClick={openModal}>
                  <Button className="btn-no-outline info-btn" onClick={() => setShowEditNameLast(true)}>
                    <img src={infoInputLogo} alt="log" />
                  </Button>
                  <div className="tooltip-text">See Permission</div>
                </div>
              </div>
              <div className="delete-compnay-btn-wrap" onClick={handleCloseEditName}>
                <div className="delete-company-btn">
                  <div className="deactive-activity-wrap">
                    <Button
                      className={`btn-no-outline ${status === 'active' ? 'deactive-user' : 'deactive-user'}`}
                      onClick={handleUserStatus}
                    >
                      {status === 'active' ? 'Deactivate' : 'Activate'} User
                    </Button>
                    <span className="last-activity">Last active {daysAgo}</span>
                  </div>

                  <Button className="btn-no-outline delete-comp" onClick={handleDeleteUser}>
                    Delete User
                  </Button>
                </div>
              </div>
            </>
          )}

          <ModalHoc showModal={showModal} setShowModal={hideModal} permiDialogClass='permission-list-modal'>
            <PermissionModal permission={permissions} handleCross={hideModal} />
          </ModalHoc>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserCompanyModal;
