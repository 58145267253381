import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import GoogleMaps from './GoogleMaps';
import { DEFAULT_LOCATION } from '../../constants';

interface ILocationProps {
  lat?: string
  lng?: string
  title?: string
  showGoogleMaps: boolean
  setShowGoogleMaps: (show: boolean) => void
  saveMapLocation: (lat: string, lng: string) => void
}

function LocationModal(props: ILocationProps) {
  const { lat, lng, title, showGoogleMaps, setShowGoogleMaps, saveMapLocation } = props;
  const [locationLat, setLocationLat] = useState<string>(lat ?? DEFAULT_LOCATION.lat);
  const [locationLng, setLocationLng] = useState<string>(lng ?? DEFAULT_LOCATION.lng);

  const updateLocationProp = (newLat: string, newLng: string) => {
    setLocationLat(newLat);
    setLocationLng(newLng);
  };

  // function to handle location changes on manually setting lat and lng on google maps
  const updateManualLocationChange = (lat: string, lng: string) => {
    lat && setLocationLat(lat);
    lng && setLocationLng(lng);
  };

  useEffect(() => {
    updateLocationProp(lat ?? DEFAULT_LOCATION.lat, lng ?? DEFAULT_LOCATION.lng);

    return () => {
      updateLocationProp(DEFAULT_LOCATION.lat, DEFAULT_LOCATION.lng);
    };
  }, [lat, lng]);

  const handleCancle = () => {
    setShowGoogleMaps(false);
    // @ts-expect-error ignore this
    saveMapLocation(lat, lng);
    setLocationLat(lat ?? DEFAULT_LOCATION.lat);
    setLocationLng(lng ?? DEFAULT_LOCATION.lng);
  };

  return (
    <Modal
      show={showGoogleMaps}
      onHide={() => setShowGoogleMaps(false)}
      dialogClassName="modal-546 top-right-modal modal-dialog-centered"
      className="forget-modal setting-modal object-parameters-modal google-map-modal"
    >
      <Modal.Header
        closeButton
        className=""
        onClick={() => setShowGoogleMaps(false)}
      >
        <Modal.Title>{title ?? 'Select Location'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleMaps lat={locationLat} lng={locationLng} updateLocationProp={updateLocationProp} />
        <Form className="object-parameter-form">
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="form-group" controlId="latitude">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="number"
                  value={locationLat}
                  onChange={(e) => updateManualLocationChange(e.target.value, locationLng)}
                  autoComplete='off'
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group" controlId="longitude">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  value={locationLng}
                  onChange={(e) => updateManualLocationChange(locationLat, e.target.value)}
                  autoComplete='off'
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-no-outline" onClick={handleCancle}> Cancel </Button>
        <Button className="primary" onClick={() => {
          saveMapLocation(locationLat, locationLng);
          setShowGoogleMaps(false);
        }}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationModal;
