import React, { useState, useEffect } from 'react';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Column from './Column';
import { Button, Spinner } from 'react-bootstrap';
import { operationalEmpty } from '../../../utils';

interface IPriorityDraggableNodes {
  data: any
  hideModal: () => void
  handleForm: (values: any) => void
  loading: boolean
}

const DraggableNodes = (props: IPriorityDraggableNodes) => {
  const { data, hideModal, handleForm, loading } = props;
  const [supplyData, setSupplyData] = useState<any[]>([]);
  const [demandData, setDemandData] = useState<any[]>([]);

  useEffect(() => {
    if (data !== undefined) {
      setRenderData(data);
    }
  }, [data]);

  const setRenderData = (data: any) => {
    const newSupplyData: any[] = [];
    const newDemandData: any[] = [];
    const supplyCreatedData = data.prioritySupplier || undefined;
    const demanderCreatedData = data.priorityDemander || undefined;
    supplyCreatedData?.map((ele: any) => {
      newSupplyData.push({ id: `${(Object.values(ele))[0] as string}`, value: Object.keys(ele)[0], type: 'supply' });
    });
    demanderCreatedData?.map((ele: any) => {
      newDemandData.push({ id: `${Object.values(ele)[0] as string}`, value: Object.keys(ele)[0], type: 'demand' });
    });
    setSupplyData(newSupplyData);
    setDemandData(newDemandData);
  };

  const onDragSupplyEnd = (event: any) => {
    const { active, over } = event;
    if (active !== null && over !== null && active.id !== over.id) {
      setSupplyData((items) => {
        const activeIndex = items.findIndex((ele: any) => ele.value === active.id);
        const overIndex = items.findIndex((ele: any) => ele.value === over.id);
        [supplyData[activeIndex].id, supplyData[overIndex].id] = [supplyData[overIndex].id, supplyData[activeIndex].id];
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  const onDragDemandEnd = (event: any) => {
    const { active, over } = event;
    if (active !== null && over !== null && active.id !== over.id) {
      setDemandData((items) => {
        const activeIndex = items.findIndex((ele: any) => ele.value === active.id);
        const overIndex = items.findIndex((ele: any) => ele.value === over.id);
        [demandData[activeIndex].id, demandData[overIndex].id] = [demandData[overIndex].id, demandData[activeIndex].id];
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  const renderSaveChanges = () => {
    const supplierData: any[] = [];
    const demanderData: any[] = [];
    supplyData.map((ele, index) => {
      supplierData.push({ [ele.value]: index });
    });
    demandData.map((ele, index) => {
      demanderData.push({ [ele.value]: index });
    });
    handleForm({ prioritySupplier: supplierData, priorityDemander: demanderData });
  };

  return (
    <>
      {supplyData.length === 0 && demandData.length === 0 &&
        <div className="operational-empty-wrap">
          <span className='operational-empty-img'><img src={operationalEmpty} alt="log" /></span>
          <p className="operational-empty-des">Supply Priority & Demand Priority settings will appear here once the objects are added to the workbench.</p>
        </div>}
      <div className='operational-supply-wrap gutter-margin-20'>
        <DndContext onDragEnd={onDragSupplyEnd}>
          {supplyData.length > 0
            ? <>
              <SortableContext items={supplyData} strategy={verticalListSortingStrategy}>
                <p className='supply-head'>Supply</p>
                {supplyData.map((node: any) => <Column key={node.value} id={node.value} />)}
              </SortableContext>
            </> : <p></p>}
        </DndContext>
      </div>
      <div className='operational-supply-wrap'>
        <DndContext onDragEnd={onDragDemandEnd}>
          {demandData.length > 0
            ? <>
              <SortableContext items={demandData} strategy={verticalListSortingStrategy}>
                <p className='supply-head'>Demand</p>
                {demandData.map((node: any) => <Column key={node.value} id={node.value} />)}
              </SortableContext>
            </> : <p></p>}
        </DndContext>
      </div>
      {(supplyData.length > 0 || demandData.length > 0) &&
        <div className="setting-btn-wrap">
          <Button className="btn-no-outline" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="primary" type="submit" onClick={renderSaveChanges}>{loading ? <Spinner /> : 'Save Changes'}</Button>
        </div>}
    </>
  );
};

export default DraggableNodes;
