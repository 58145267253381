import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { editIcon } from '../../../utils';
import { useAppSelector } from '../../../redux/hooks';
import EditCompanyName from './EditCompanyName';
import ModalHoc from '../shared/ModalHoc';
import ConfirmationAlert from '../../shared/ConfirmationAlert';
import { LABELS, MESSAGES, NUMBER, PROJECT_TYPE_LABELS } from '../../../constants';
import UserAvatar from '../../approval-workflow/UserAvatar';

// eslint-disable-next-line complexity
const CompanyModal = ({ ShowCompanyModal, setShowCompanyModal, handleUserStatus, handleDeleteUser }: any) => {
  const companyData = useAppSelector((state) => state.adminSlice.singleCompany);
  const [editComponentName, setEditComponentName] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showObjModal, setShowObjModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isPermissionChanged, setIsPermissionChanged] = useState(false);
  const {
    'COMPANY NAME': COMPANY,
    email,
    noOfprojects,
    noOfscenarios,
    noOfsimulations,
    imageUrl,
    role_permissions,
    _id,
    description,
    accessToProjectType,
    noOfActiveUser,
    noOfInactiveUser,
    status,
    accessToObjects
  } = companyData;
  const componentInput = useRef(null);

  const formIntialState: any = {
    companyID: _id,
    name: COMPANY,
    description,
    accessToProjectType: accessToProjectType?.split(', ').map((permission: string) => ({
      label: PROJECT_TYPE_LABELS[permission],
      value: permission
    })),
    role_permissions,
    email,
    accessToObjects: accessToObjects ?? []
  };

  const showConfirmationAlert = () => {
    isPermissionChanged ? setShowAlert(true) : hideModal();
  };

  const hideConfirmationAlert = () => {
    setShowAlert(false);
  };

  const hideModal = () => {
    hideConfirmationAlert();
    setShowModal(false);
    setIsPermissionChanged(false);
    setEditComponentName(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openObjModal = () => {
    setShowObjModal(true);
  };

  const hideObjectModal = () => {
    setShowObjModal(false);
  };

  const onModalClose = () => {
    hideModal();
    setShowCompanyModal(false);
    hideObjectModal();
  };

  const showEditComponentName = () => {
    setEditComponentName(true);
    setTimeout(() => {
      if (componentInput.current) {
        ; (componentInput?.current as HTMLElement).focus();
      }
    });
  };
  const hideEditName = () => {
    setEditComponentName(false);
    setIsPermissionChanged(false);
  };

  const closeModal = showObjModal ? hideObjectModal : showModal || editComponentName ? showConfirmationAlert : onModalClose;

  return (
    <Modal
      show={ShowCompanyModal}
      onHide={closeModal}
      dialogClassName={`${editComponentName ? 'modal-861' : 'modal-589'} top-right-modal add-user-modal modal-dialog-centered company-info-modal`}
      className={`forget-modal setting-modal object-parameters-modal ${editComponentName && 'add-new-company-modal'}`}
    >
      <Modal.Header closeButton className="" onClick={closeModal}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {!showObjModal && (<>

          {!editComponentName && (
            <>
              {!showModal && (
                <>
                  <div className="company-name-wrap">
                    <div className="compnay-logo-wrap">
                      {imageUrl ? <img src={imageUrl} alt="logo" className="company-logo-img" />
                        : <UserAvatar
                          classname="user-icon-com"
                          firstname={COMPANY?.split(' ')[NUMBER.N0] ?? ''}
                          lastname={COMPANY?.split(' ')[NUMBER.N1] ?? ''}
                          imageUrl={''}
                        />}
                    </div>
                    <div className="company-des-wrap">
                      <h4 className="modal-head modal-head-medium" onClick={showEditComponentName}>
                        <span className="edit-name-last">
                          {COMPANY}
                          <Button className="edit-icon-wrap btn-no-outline" onClick={showEditComponentName}>
                            <img src={editIcon} alt="edit icon" className="edit-icon" />
                          </Button>
                        </span>
                      </h4>
                      <p className="modal-head-des">{email}</p>
                    </div>
                  </div>
                  <div className="user-card-main-wrap">
                    <div className="user-info-card">
                      <h3 className="user-info-card-title">Users Onboarded</h3>
                      <div className="user-card-body">
                        <div className="user-card-active">
                          <h2 className="user-card-sec-head">{noOfActiveUser}</h2>
                          <span className="user-status-des">Active</span>
                        </div>
                        <div className="user-card-active user-card-inactive">
                          <h2 className="user-card-sec-head">{noOfInactiveUser}</h2>
                          <span className="user-status-des">Inactive</span>
                        </div>
                      </div>
                    </div>
                    <div className="user-info-card user-activity-card">
                      <h3 className="user-info-card-title">Users Activity</h3>
                      <div className="user-card-body">
                        <div className="user-card-active">
                          <h2 className="user-card-sec-head">{noOfprojects}</h2>
                          <span className="user-status-des">Projects</span>
                        </div>
                        <div className="user-card-active">
                          <h2 className="user-card-sec-head">{noOfscenarios}</h2>
                          <span className="user-status-des">Scenarios</span>
                        </div>
                        <div className="user-card-active user-card-inactive">
                          <h2 className="user-card-sec-head">{noOfsimulations}</h2>
                          <span className="user-status-des">Simulations</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="delete-compnay-btn-wrap">
                    <div className="delete-company-btn">
                      <Button className="btn-no-outline edit-user-permission" onClick={openObjModal}>
                        Edit Object Access
                      </Button>
                      <Button className="btn-no-outline edit-user-permission" onClick={openModal}>
                        Edit User Permissions
                      </Button>
                      <Button
                        className={`btn-no-outline ${status === 'active' ? 'deactive-user' : 'deactive-user'}`}
                        onClick={handleUserStatus}
                      >
                        {status === 'active' ? 'Deactivate' : 'Activate'} Company
                      </Button>
                      <Button className="btn-no-outline delete-comp" onClick={handleDeleteUser}>
                        Delete Company
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {showModal && (
                <EditCompanyName
                  initialFormValues={formIntialState}
                  setEditComponentName={showConfirmationAlert}
                  hideModal={hideModal}
                  showPermission={true}
                  setIsPermissionChanged={setIsPermissionChanged}
                />
              )}
            </>
          )}
          {editComponentName && (
            <EditCompanyName
              initialFormValues={formIntialState}
              setEditComponentName={showConfirmationAlert}
              hideModal={hideEditName}
              showName={true}
              setIsPermissionChanged={setIsPermissionChanged}
              setShowObject={openObjModal}
            />
          )}
        </>)}
        {showObjModal && <EditCompanyName
          initialFormValues={formIntialState}
          setEditComponentName={showConfirmationAlert}
          hideModal={hideObjectModal}
          showObject={true}
          setIsPermissionChanged={setIsPermissionChanged}
          setShowObject={openObjModal}
        />}

        <ModalHoc showModal={showAlert} setShowModal={hideConfirmationAlert}>
          <ConfirmationAlert
            showAlert={showAlert}
            title={LABELS.DISCARD_CHANGE}
            message={MESSAGES.DISCARD_COMPANY_PERMISSION_MESSAGE}
            cancleBtnText={LABELS.STAY}
            yesBtnText={LABELS.YES_DISCARD}
            onConfirm={hideModal}
            onCancel={hideConfirmationAlert}
          />
        </ModalHoc>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyModal;
