import React, { useState } from 'react';
import SearchAndFilter from '../../shared/SearchAndFilter';
import { Button } from 'react-bootstrap';
import UserMenu from '../../shared/User/UserMenu';
import { useAppDispatch } from '../../../redux/hooks';
import { updateObjectSearch } from '../../../redux/slices/adminSlice';
import { useGetObjectListQuery } from '../../../redux/services/workbenchApis';
import AddNewObject from './AddNewObject';
import { formatAndRoundOffNumber } from '../../../utils';

const ObjectManagementHeader = () => {
  const [ShowAddObject, setShowAddObject] = useState(false);
  const dispatch = useAppDispatch();
  const handleSearch = (val: string) => {
    dispatch(updateObjectSearch(val));
  };
  const { data: objectListRes, isSuccess: isObjectListSuccess } = useGetObjectListQuery();

  const totalObject = isObjectListSuccess ? objectListRes?.data?.length : 0;

  return (
    <>
      <div className='admin-body-header'>
        <div className='admin-header-wrap'>
          <h2 className='admin-section-title'>All Objects ({formatAndRoundOffNumber(totalObject)})</h2>
          <div className='admim-profile-wrap'>
            <UserMenu />
          </div>
        </div>
        <div className='add-user-wrap'>
          <SearchAndFilter
            placeHolder="Search by name"
            onSearch={handleSearch}
            className="scenario-list-search"
          />
          <Button onClick={() => setShowAddObject(true)}>+ New Object</Button>
        </div>
      </div>
      <AddNewObject ShowAddObject={ShowAddObject} setShowAddObject={setShowAddObject}
      />
    </>
  );
};

export default ObjectManagementHeader;
