import { createApi } from '@reduxjs/toolkit/query/react';
import { interceptor } from '../../utils/interceptor';
import { ApiEndpoints, Methods } from '../../constants';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';
import { IWizardQuestionRes } from '../../pages/wizard/wizardInterface';

export const wizardApis = createApi({
  reducerPath: 'wizardApis',
  baseQuery: interceptor,
  tagTypes: ['Wizard'],
  endpoints: (builder) => ({
    getWizardQuestions: builder.query<IWizardQuestionRes[], string>({
      query: (type: string) => ({
        url: `${ApiEndpoints.GET_WIZARD_QUESTIONS}?project_type=${type}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
    })
  })
});

export const {
  useGetWizardQuestionsQuery
} = wizardApis;

