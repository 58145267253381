import React, { useEffect } from 'react';
import { useImageUpload } from '../../hooks/useImageUploader';
import { crossRedLogo, fileInputImg } from '../../utils/icons';
import { getInitials } from '../../utils';
import { Spinner } from 'react-bootstrap';

function ImageUpload(props: any) {
  const {
    companyName,
    companyLogo,
    setFieldValue,
    onImageUpload,
    isEditProfile,
    isLoading,
    imageType,
    setIsImageChanged,
    logoTitle = 'Company Logo'
  } = props;
  const { selectedImage, selectedFile, handleImageChange, resetImage, onExistingImage } = useImageUpload(imageType);

  useEffect(() => {
    typeof selectedFile === 'object' && onImageUpload(selectedFile, setFieldValue);
  }, [selectedFile]);

  const onImageFileChange = (event: any) => {
    setIsImageChanged?.(true);
    handleImageChange(event);
  };

  useEffect(() => {
    onExistingImage(companyLogo);
  }, [companyLogo]);

  return (
    <div className={'form-group file-input'}>
      <label className='form-label'>
        {logoTitle}
      </label>
      <div className="file-input-wrapper">
        <div className={`file-upload-img empty-img-bg ${(selectedImage && isEditProfile) ? 'border-none' : ''}`}>
          {isLoading &&
            <Spinner style={{ color: '#fff', zIndex: '9' }} />
          }
          {(selectedImage) &&
            <>
              <img
                src={selectedImage}
                alt="img upload image"
                className="file-main-img upload-main-img"
              />
              {!isEditProfile && <img
                src={crossRedLogo}
                alt="img cross logo"
                className="cross-logo"
                onClick={resetImage}
              />}
            </>
          }
          {(!selectedImage && !isEditProfile) && <img
            src={fileInputImg}
            alt="img main image"
            className="file-main-img empty-img"
          />}
          {(isEditProfile && !selectedImage) && <span className="user-icon large">{getInitials(companyName)}</span>}
        </div>
        <div className="file-input-text-wrapper">
          <div className="file-attachment-wrapper">
            <span className="file-input-attachment">
              + Upload Logo
            </span>
            <span className="file-input-des">
              (.jpg, .jpeg or .png of max {`${imageType === 'company' ? '4' : '10'}`} MB)
            </span>
          </div>
          <input
            type="file"
            id="company_logo"
            name="company_logo"
            accept=".jpg, .jpeg, .png"
            onClick={(event: any) => { event.target.value = null; }}
            onChange={(event) => {
              onImageFileChange(event);
            }}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
